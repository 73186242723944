import { GROUP_UPDATE_REQUEST, GROUP_UPDATE_SUCCESS, GROUP_UPDATE_FAIL, GROUP_UPDATE_RESET } from '../actions/types';

export default function groupUpdateReducer(state = {}, action) {
    switch (action.type) {
        case GROUP_UPDATE_REQUEST:
            return { loading: true }
        case GROUP_UPDATE_SUCCESS:
            return { loading: false, success: true, groupInfo: action.payload }
        case GROUP_UPDATE_FAIL:
            return { loading: false, error: action.payload };
        case GROUP_UPDATE_RESET:
            return {}
        default:
            return state
    }
}