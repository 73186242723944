import axios from 'axios';

import {

  UPDATE_PAGE_INDEX,
  UPDATE_PAGE_SIZE,

  ADD_SELECTED_ROWS,
  REMOVE_SELECTED_ROWS,
  RESET_SELECTED_ROWS,
  ADD_SELECTED_CONTACT_ROWS,
  UPDATE_SELECTED_CONTACT_ROWS,
  REMOVE_SELECTED_CONTACT_ROWS,
  RESET_SELECTED_CONTACT_ROWS,
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  AVAIL_NUMBERS_REQUEST,
  AVAIL_NUMBERS_SUCCESS,
  AVAIL_NUMBERS_FAIL,

  FETCH_USER,
  SET_USER,

  DELETE_USER,
  LIST_TEMPLATES_REQUEST,
  LIST_TEMPLATES_SUCCESS,
  LIST_TEMPLATES_FAIL,
  LIST_GROUPS,
  LIST_GROUP_CONTACTS,
  LIST_SHOW_GROUPS_REQUEST,
  LIST_SHOW_GROUPS_SUCCESS,
  LIST_SHOW_AND_HIDE_GROUPS,
  LIST_UNSUBS,
  LIST_GROUPS_EXCEPT_UNSUB,

  LIST_RESPONDERS_REQUEST,
  LIST_RESPONDERS_SUCCESS,
  LIST_RESPONDERS_FAILURE,
  LIST_CSVUPLOAD_REQUEST,
  LIST_CSVUPLOAD_SUCCESS,
  LIST_CSVUPLOAD_FAIL,
  LIST_NUMBERS,
  LIST_TOLL_FREE_NUMBERS_REQUEST,
  LIST_TOLL_FREE_NUMBERS_SUCCESS,
  LIST_TOLL_FREE_NUMBERS_FAIL,
  LIST_DLC_NUMBERS_REQUEST,
  LIST_DLC_NUMBERS_SUCCESS,
  LIST_DLC_NUMBERS_FAIL,

  LIST_CONTACTS_FOLLOW_UP_REQUEST,
  LIST_CONTACTS_FOLLOW_UP_SUCCESS,
  LIST_CONTACTS_FOLLOW_UP_FAILURE,

  LIST_CONTACTS_TOTAL,
  LIST_CONTACTS_TOTAL_CRM,
  LIST_BLOCKED,
  LIST_BLOCKED_REQUEST,
  LIST_BLOCKED_SUCCESS,
  LIST_BLOCKED_FAIL,
  LIST_KEYWORDS_REQUEST,
  LIST_KEYWORDS_SUCCESS,
  LIST_KEYWORDS_FAIL,
  LIST_PRIMARY_NUMBER,
  LIST_CALENDAR,
  LIST_PAUSED_CALENDAR,
  LIST_COMPLETED_CALENDAR,
  LIST_INVOICES,
  // ------------------------------------- Number  ------------------------------------- 
  NUMBER_CREATE_SUCCESS,
  NUMBER_CREATE_REQUEST,
  NUMBER_CREATE_FAIL,
  NUMBER_DETAILS_REQUEST,
  NUMBER_DETAILS_SUCCESS,
  NUMBER_DETAILS_FAIL,
  NUMBER_UPDATE_REQUEST,
  NUMBER_UPDATE_SUCCESS,
  NUMBER_UPDATE_FAIL,
  PRIMARY_NUMBER_UPDATE_REQUEST,
  PRIMARY_NUMBER_UPDATE_SUCCESS,
  PRIMARY_NUMBER_UPDATE_FAIL,
  NUMBER_DELETE_SUCCESS,
  NUMBER_DELETE_REQUEST,
  NUMBER_DELETE_FAIL,
  // ------------------------------------- Contact  ------------------------------------- 
  CONTACT_CREATE_SUCCESS,
  CONTACT_CREATE_REQUEST,
  CONTACT_CREATE_FAIL,
  CONTACT_DELETE_SUCCESS,
  CONTACT_DELETE_REQUEST,
  CONTACT_DELETE_FAIL,
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_FAIL,
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  BLOCKED_CONTACT_CREATE_SUCCESS,
  BLOCKED_CONTACT_CREATE_REQUEST,
  BLOCKED_CONTACT_CREATE_FAIL,
  BLOCKED_CONTACT_DELETE_SUCCESS,
  BLOCKED_CONTACT_DELETE_REQUEST,
  BLOCKED_CONTACT_DELETE_FAIL,
  // ------------------------------------- Csv-Upload  ------------------------------------- 
  CSVUPLOAD_CREATE_SUCCESS,
  CSVUPLOAD_CREATE_REQUEST,
  CSVUPLOAD_CREATE_FAIL,
  CSVUPLOAD_DELETE_SUCCESS,
  CSVUPLOAD_DELETE_REQUEST,
  CSVUPLOAD_DELETE_FAIL,
  // ------------------------------------- Template  ------------------------------------- 
  TEMPLATE_CREATE_SUCCESS,
  TEMPLATE_CREATE_REQUEST,
  TEMPLATE_CREATE_FAIL,
  TEMPLATE_DELETE_REQUEST,
  TEMPLATE_DELETE_SUCCESS,
  TEMPLATE_DELETE_FAIL,
  TEMPLATE_UPDATE_REQUEST,
  TEMPLATE_UPDATE_SUCCESS,
  TEMPLATE_UPDATE_FAIL,
  // ------------------------------------- Group  ------------------------------------- 
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_FAIL,
  GROUP_CREATE_RESET,
  GROUP_DELETE_REQUEST,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_FAIL,
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_SUCCESS,
  GROUP_DETAILS_FAIL,
  GROUP_DETAILS_TITLE_REQUEST,
  GROUP_DETAILS_TITLE_SUCCESS,
  GROUP_DETAILS_TITLE_FAIL,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_FAIL,
  GROUP_SHOW_HIDE_REQUEST,
  GROUP_SHOW_HIDE_SUCCESS,
  GROUP_SHOW_HIDE_FAIL,
  GROUP_MOVE_COPY_REQUEST,
  GROUP_MOVE_COPY_SUCCESS,
  GROUP_MOVE_COPY_FAIL,
  // ------------------------------------- Field  ------------------------------------- 
  LIST_FIELDS_REQUEST,
  LIST_FIELDS_SUCCESS,
  LIST_FIELDS_FAIL,

  FIELD_CREATE_REQUEST,
  FIELD_CREATE_SUCCESS,
  FIELD_CREATE_FAIL,

  FIELD_DELETE_REQUEST,
  FIELD_DELETE_SUCCESS,
  FIELD_DELETE_FAIL,

  FIELD_UPDATE_REQUEST,
  FIELD_UPDATE_SUCCESS,
  FIELD_UPDATE_FAIL,

  FIELD_DETAILS_REQUEST,
  FIELD_DETAILS_SUCCESS,
  FIELD_DETAILS_FAIL,
  // ------------------------------------- Keywords  ------------------------------------- 
  KEYWORD_CREATE_REQUEST,
  KEYWORD_CREATE_SUCCESS,
  KEYWORD_CREATE_FAIL,
  KEYWORD_UPDATE_REQUEST,
  KEYWORD_UPDATE_SUCCESS,
  KEYWORD_UPDATE_FAIL,
  KEYWORD_DELETE_REQUEST,
  KEYWORD_DELETE_SUCCESS,
  KEYWORD_DELETE_FAIL,
  // ------------------------------------- Flows  ------------------------------------- 
  RESPONDER_CREATE_REQUEST,
  RESPONDER_CREATE_SUCCESS,
  RESPONDER_CREATE_FAIL,
  RESPONDER_DELETE_REQUEST,
  RESPONDER_DELETE_SUCCESS,
  RESPONDER_DELETE_FAIL,
  RESPONDER_UPDATE_REQUEST,
  RESPONDER_UPDATE_SUCCESS,
  RESPONDER_UPDATE_FAIL,
  // ------------------------------------- Attach-file  ------------------------------------- 
  ATTACH_FILE_CREATE_REQUEST,
  ATTACH_FILE_CREATE_SUCCESS,
  ATTACH_FILE_CREATE_FAIL,
  // ------------------------------------- Attach-mms  ------------------------------------- 
  ATTACH_MMS_CREATE_REQUEST,
  ATTACH_MMS_CREATE_SUCCESS,
  ATTACH_MMS_CREATE_FAIL,
  PREVIEW_MMS_CREATE_REQUEST,
  PREVIEW_MMS_CREATE_SUCCESS,
  PREVIEW_MMS_CREATE_FAIL,
  // ------------------------------------- Schedule  ------------------------------------- 
  CALENDAR_CREATE_REQUEST,
  CALENDAR_CREATE_SUCCESS,
  CALENDAR_CREATE_FAIL,
  CALENDAR_CREATE_RESET,
  CALENDAR_DELETE_REQUEST,
  CALENDAR_DELETE_SUCCESS,
  CALENDAR_DELETE_FAIL,
  CALENDAR_UPDATE_REQUEST,
  CALENDAR_UPDATE_SUCCESS,
  CALENDAR_UPDATE_FAIL,
  CALENDAR_UPDATE_RESET,
  LIST_SENT_MESSAGES_REQUEST,
  LIST_SENT_MESSAGES_SUCCESS,
  LIST_SENT_MESSAGES_FAIL,
  LIST_SENT_MESSAGES_CREATE_REQUEST,
  LIST_SENT_MESSAGES_CREATE_SUCCESS,
  LIST_SENT_MESSAGES_CREATE_FAIL,
  LIST_RECEIVED_MESSAGES,
  LIST_RECEIVED_MESSAGES_REQUEST,
  LIST_RECEIVED_MESSAGES_SUCCESS,
  LIST_RECEIVED_MESSAGES_FAIL,

  LIST_RECEIVED_MESSAGES_CREATE_REQUEST,
  LIST_RECEIVED_MESSAGES_CREATE_SUCCESS,
  LIST_RECEIVED_MESSAGES_CREATE_FAIL,
  // ------------------------------------- User  ------------------------------------- 
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  // ------------------------------------- Account  ------------------------------------- 
  ACCOUNT_DELETE_REQUEST,
  ACCOUNT_DELETE_SUCCESS,
  ACCOUNT_DELETE_FAIL,
  UPDATE_PRIMARY_NUMBER,
  // ------------------------------------- Brand  ------------------------------------- 
  LIST_BRAND_REQUEST,
  LIST_BRAND_SUCCESS,
  LIST_BRAND_FAIL,

  BRAND_CREATE_REQUEST,
  BRAND_CREATE_SUCCESS,
  BRAND_CREATE_FAIL,

  BRAND_DELETE_REQUEST,
  BRAND_DELETE_SUCCESS,
  BRAND_DELETE_FAIL,

  BRAND_UPDATE_REQUEST,
  BRAND_UPDATE_SUCCESS,
  BRAND_UPDATE_FAIL,

  // ------------------------------------- Toll free verification ------------------------------------- 

  LIST_DLC_CAMPAIGN_SUCCESS,
  LIST_DLC_CAMPAIGN_REQUEST,
  LIST_DLC_CAMPAIGN_FAIL,

  DLC_CAMPAIGN_CREATE_REQUEST,
  DLC_CAMPAIGN_CREATE_SUCCESS,
  DLC_CAMPAIGN_CREATE_FAIL,

  DLC_CAMPAIGN_DELETE_REQUEST,
  DLC_CAMPAIGN_DELETE_SUCCESS,
  DLC_CAMPAIGN_DELETE_FAIL,

  DLC_CAMPAIGN_UPDATE_REQUEST,
  DLC_CAMPAIGN_UPDATE_SUCCESS,
  DLC_CAMPAIGN_UPDATE_FAIL,

  LIST_BILLING_DETAILS_FOLLOW_UP_REQUEST,
  LIST_BILLING_DETAILS_FOLLOW_UP_SUCCESS,
  LIST_BILLING_DETAILS_FOLLOW_UP_FAIL,


  BILLING_DETAILS_FOLLOW_UP_UPDATE_REQUEST,
  BILLING_DETAILS_FOLLOW_UP_UPDATE_SUCCESS,
  BILLING_DETAILS_FOLLOW_UP_UPDATE_FAIL,


  TOLL_FREE_VERIFICATION_CREATE_REQUEST,
  TOLL_FREE_VERIFICATION_CREATE_SUCCESS,
  TOLL_FREE_VERIFICATION_CREATE_FAIL,


  LIST_TOLL_FREE_VERIFICATION_REQUEST,
  LIST_TOLL_FREE_VERIFICATION_SUCCESS,
  LIST_TOLL_FREE_VERIFICATION_FAIL,


  TOLL_FREE_VERIFICATION_UPDATE_REQUEST,
  TOLL_FREE_VERIFICATION_UPDATE_SUCCESS,
  TOLL_FREE_VERIFICATION_UPDATE_FAIL,


  TOLL_FREE_VERIFICATION_DELETE_REQUEST,
  TOLL_FREE_VERIFICATION_DELETE_SUCCESS,
  TOLL_FREE_VERIFICATION_DELETE_FAIL,
  CLEAR_USER,

} from './types';


export const setUser = user => ({
  type: SET_USER,
  payload: user
});

//-----------------------------------------------------------------------Numbers----------------------------------------------------------------------
export const findANumber =
  (country, areaCode, numberType) => async dispatch => {
    try {
      dispatch({ type: AVAIL_NUMBERS_REQUEST });
      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/find-a-number`,
        method: 'POST',
        data: {
          country: country,
          areaCode: areaCode,
          numberType: numberType
        },
        withCredentials: true
      }).then(res =>
        dispatch({ type: AVAIL_NUMBERS_SUCCESS, payload: res.data })
      );
    } catch (error) {
      dispatch({
        type: AVAIL_NUMBERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const createNumber =
  (selectedNumber, country, numberType) => async dispatch => {
    try {
      dispatch({ type: NUMBER_CREATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/number/create`,
        method: 'POST',
        data: {
          selectedNumber: selectedNumber,
          country: country,
          type: numberType
        },
        withCredentials: true
      }).then(res => {
        dispatch({
          type: NUMBER_CREATE_SUCCESS,
          payload: res.data.newPhoneNumber
        });
      });
    } catch (error) {
      dispatch({
        type: NUMBER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const listNumbers = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number/create`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_NUMBERS, payload: res.data }));
  };
};

export const listTollFreeNumbers = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_TOLL_FREE_NUMBERS_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/toll-free-numbers`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: LIST_TOLL_FREE_NUMBERS_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_TOLL_FREE_NUMBERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const listDLCNumbers = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_DLC_NUMBERS_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/dlc-numbers`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: LIST_DLC_NUMBERS_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_DLC_NUMBERS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const listDLCCampaigns = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_DLC_CAMPAIGN_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/dlc-registration`,
        method: 'GET',
        withCredentials: true
      });

      dispatch({ type: LIST_DLC_CAMPAIGN_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_DLC_CAMPAIGN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const numberById = TempId => async dispatch => {
  try {
    dispatch({ type: NUMBER_DETAILS_REQUEST });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/number/${TempId}`)
      .then(res =>
        dispatch({ type: NUMBER_DETAILS_SUCCESS, payload: res.data })
      );
  } catch (error) {
    dispatch({
      type: NUMBER_DETAILS_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteNumber = tempId => async dispatch => {
  console.log(tempId);
  try {
    dispatch({ type: NUMBER_DELETE_REQUEST });

    axios({
      url: `${process.env.REACT_APP_API_URL}/api/number/${tempId.tempId}`,
      method: 'DELETE',
      withCredentials: true
    }).then(res =>
      dispatch({ type: NUMBER_DELETE_SUCCESS, payload: res.data })
    );
  } catch (error) {
    dispatch({
      type: NUMBER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleNumbers = isSelected => async dispatch => {
  try {
    dispatch({ type: NUMBER_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/numbers-remove`,
      method: 'POST',
      data: { isSelected },
      withCredentials: true
    });

    dispatch({ type: NUMBER_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: NUMBER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

// really just forwarding
export const updateNumber = (numberObject, id) => async dispatch => {
  console.log(id);
  try {
    dispatch({ type: NUMBER_UPDATE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number/${id}`,
      method: 'PUT',
      data: numberObject,
      withCredentials: true
    });

    dispatch({
      type: NUMBER_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: NUMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const editNickname = (nickName, tempId) => async dispatch => {
  try {
    dispatch({ type: NUMBER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number-nickname`,
      method: 'POST',
      data: { nickName, tempId },
      withCredentials: true
    }).then(res => {
      dispatch({ type: NUMBER_UPDATE_SUCCESS, payload: res.data });
    });
  } catch (error) {
    dispatch({
      type: NUMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const removeForwarding = tempId => async dispatch => {
  try {
    dispatch({ type: NUMBER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number-forward-remove`,
      method: 'POST',
      data: tempId,
      withCredentials: true
    }).then(res => {
      dispatch({ type: NUMBER_UPDATE_SUCCESS, payload: res.data });
    });
  } catch (error) {
    dispatch({
      type: NUMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listPrimaryNumbers = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number-primary`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_PRIMARY_NUMBER, payload: res.data }));
  };
};

export const makePrimaryNumber = id => async dispatch => {
  try {
    dispatch({ type: PRIMARY_NUMBER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/number-primary`,
      method: 'POST',
      data: {
        numberId: id
      },
      withCredentials: true
    }).then(res => {
      return (
        dispatch({ type: UPDATE_PRIMARY_NUMBER, payload: res.data }),
        dispatch({ type: PRIMARY_NUMBER_UPDATE_SUCCESS })
      );
    });
  } catch (error) {
    dispatch({
      type: PRIMARY_NUMBER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Templates----------------------------------------------------------------------

export const listTemplates = (options = {}) => {
  return async function (dispatch) {
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 10,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/templates?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_TEMPLATES_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_TEMPLATES_SUCCESS, payload: { templates: res.data.templates, totalTemplates: res.data.totalTemplates } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_TEMPLATES_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
};



export const createTemplate = template => async dispatch => {
  try {
    dispatch({ type: TEMPLATE_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/templates/create`,
      method: 'POST',
      data: template,
      withCredentials: true
    }).then(res => {
      dispatch({ type: TEMPLATE_CREATE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: TEMPLATE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteTemplate = id => async (dispatch, getState) => {
  console.log(id);
  try {
    dispatch({
      type: TEMPLATE_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/templates/${id}`);

    dispatch({
      type: TEMPLATE_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: TEMPLATE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateTemplate = (template, id) => async dispatch => {
  try {
    dispatch({ type: TEMPLATE_UPDATE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/templates/${id}`,
      method: 'PUT',
      data: template,
      withCredentials: true
    });

    dispatch({ type: TEMPLATE_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: TEMPLATE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleTemplates = isSelected => async dispatch => {
  try {
    dispatch({ type: TEMPLATE_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/templates-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({ type: TEMPLATE_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: TEMPLATE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Contacts----------------------------------------------------------------------

export const getContactsByIds = ids => {
  return async function (dispatch) {
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/api/contacts/byIds`,
        { ids },
        { withCredentials: true }
      )
      .then(res => {
        return res.data;
      })
      .catch(error => {
        console.log("error", error);
      });
  };
};


export const listContacts = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 25,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/contacts/create?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_CONTACTS_FOLLOW_UP_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_CONTACTS_FOLLOW_UP_SUCCESS, payload: { contacts: res.data.contacts, totalItems: res.data.totalItems } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_CONTACTS_FOLLOW_UP_FAILURE, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
}

export const listContactsTotal = (search, page = 1, limit = 25) => {
  return async function (dispatch) {
    // Add the query parameters to the URL
    const url = `${process.env.REACT_APP_API_URL}/api/contacts-total`;

    await axios({
      url,
      method: 'GET',
      withCredentials: true
    })
      .then(res => dispatch({ type: LIST_CONTACTS_TOTAL, payload: res.data }))
      .catch(error => {
        // Add error handling logic here if needed
      });
  };
};

export const listContactsTotalCrm = () => {
  return async function (dispatch) {
    // Add the query parameters to the URL
    const url = `${process.env.REACT_APP_API_URL}/api/contacts-total-crm`;

    await axios({
      url,
      method: 'GET',
      withCredentials: true
    })
      .then(res =>
        dispatch({ type: LIST_CONTACTS_TOTAL_CRM, payload: res.data })
      )
      .catch(error => {
        // Add error handling logic here if needed
      });
  };
};

export const createContact = contact => async dispatch => {
  try {
    dispatch({ type: CONTACT_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/contacts/create`,
      method: 'POST',
      data: contact,
      withCredentials: true
    }).then(res => {
      dispatch({ type: CONTACT_CREATE_SUCCESS, payload: res.data }); // updates our state with response object
    });
  } catch (error) {
    dispatch({
      type: CONTACT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteContact = id => async dispatch => {
  try {
    dispatch({ type: CONTACT_DELETE_REQUEST });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/contacts/${id}`, {
      withCredentials: true
    });

    dispatch({ type: CONTACT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CONTACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleContacts =
  (deleteIds) => async dispatch => {
    try {
      dispatch({ type: CONTACT_DELETE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/contacts-remove`,
        method: 'POST',
        data: { deleteIds: deleteIds },
        withCredentials: true
      });

      dispatch({ type: CONTACT_DELETE_SUCCESS });
    } catch (error) {
      dispatch({
        type: CONTACT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const deleteContactFromGroup =
  (selectedContactInfo, GroupId) => async dispatch => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/group-remove`,
        { data: { groupId: GroupId, selectedContacts: selectedContactInfo } },
        { withCredentials: true }
      );

      dispatch({
        type: CONTACT_DELETE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: CONTACT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const resubscribeContacts =
  (selectedContactInfo, GroupId) => async dispatch => {
    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/resubscribe`,
        method: 'POST',
        data: { groupId: GroupId, selectedContacts: selectedContactInfo },
        withCredentials: true
      });
      dispatch({
        type: CONTACT_DELETE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: CONTACT_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const unsubscribeContacts = blockContact => async dispatch => {
  try {
    dispatch({ type: CONTACT_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/unsubscribe`,
      method: 'POST',
      data: blockContact,
      withCredentials: true
    });
    dispatch({ type: CONTACT_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CONTACT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const contactById = ContactId => async dispatch => {
  try {
    dispatch({ type: CONTACT_DETAILS_REQUEST });
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/contacts/${ContactId}`,
      { withCredentials: true }
    );
    dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: response.data });
    return response.data;
  } catch (error) {
    dispatch({
      type: CONTACT_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
    console.log("error", error);
  }
};

export const contactArrayById = selectedContactsID => async dispatch => {
  try {
    dispatch({ type: CONTACT_DETAILS_REQUEST });
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/contact-details`,
        selectedContactsID,
        { withCredentials: true }
      )
      .then(res => dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: res }));
  } catch (error) {
    dispatch({
      type: CONTACT_DETAILS_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateContact = (contact, id) => async dispatch => {
  try {
    dispatch({ type: CONTACT_UPDATE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/contacts/${id}`,
      method: 'PUT',
      data: contact,
      withCredentials: true
    });
    dispatch({
      type: CONTACT_UPDATE_SUCCESS,
      payload: data
    });
    dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONTACT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const checkCustomerScoreAndUpdate = id => async dispatch => {
  try {
    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/check-score-and-update/${id}`,
      method: 'PUT',
      withCredentials: true
    });
    return data;
  } catch (error) {
    return {
      success: false,
      message: error.response?.data?.message || error.message
    };
  }
};

export const addContactProperty = (address, id) => async dispatch => {
  try {
    dispatch({ type: CONTACT_UPDATE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/contact-property/${id}`,
      method: 'PUT',
      data: address,
      withCredentials: true
    });

    dispatch({
      type: CONTACT_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: CONTACT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Blocked Contacts---------------------------------------------------------------------

export const listBlockedContacts = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 10,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/contacts-block?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_BLOCKED_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_BLOCKED_SUCCESS, payload: { contacts: res.data.contacts, totalItems: res.data.totalItems } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_BLOCKED_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
}

export const createBlockedContacts =
  (contactIds) => async dispatch => {
    try {
      dispatch({ type: BLOCKED_CONTACT_CREATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/contacts-block`,
        method: 'POST',
        data: contactIds,
        withCredentials: true
      });

      dispatch({ type: BLOCKED_CONTACT_CREATE_SUCCESS }); // updates our state with response object
    } catch (error) {
      dispatch({
        type: BLOCKED_CONTACT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const unBlockContacts = isSelected => async dispatch => {
  try {
    dispatch({ type: BLOCKED_CONTACT_DELETE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/contacts-unblock`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({
      type: BLOCKED_CONTACT_DELETE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: BLOCKED_CONTACT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Groups----------------------------------------------------------------------------------

export const listGroups = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_GROUPS, payload: res.data }));
  };
};

export const listGroupContacts =
  (options = {}) => async dispatch => {
    const {
      groupId,
      search = '',
      page = 1,
      limit = 10,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/group/${groupId}/contacts?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: GROUP_DETAILS_REQUEST })


    try {
      const res = await axios({
        url,
        method: "GET",
        withCredentials: true
      })
      dispatch({
        type: LIST_GROUP_CONTACTS,
        payload: res.data
      });
      return res.data; // Add this line
    } catch (error) {
      // handle error
    }
  };

export const listGroupContactsSearch =
  (groupId, page, pageSize, searchTerm = '') =>
    async dispatch => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/group/${groupId}/contacts-search?page=${page}&pageSize=${pageSize}&search=${searchTerm}`,
          { withCredentials: true }
        );
        dispatch({
          type: LIST_GROUP_CONTACTS,
          payload: res.data
        });
      } catch (error) {
        // handle error
      }
    };

export const listShowGroups =
  (options = {}) => async dispatch => {
    const {
      search = '',
      page = 1,
      limit = 25,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/groups?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_SHOW_GROUPS_REQUEST })


    try {
      const res = await axios({
        url,
        method: "GET",
        withCredentials: true
      })
      dispatch({
        type: LIST_SHOW_GROUPS_SUCCESS,
        payload: res.data
      });
      return res.data; // Add this line
    } catch (error) {
      // handle error
    }
  };

export const listShowAndHideGroups = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups`,
      method: 'GET',
      withCredentials: true
    }).then(res =>
      dispatch({ type: LIST_SHOW_AND_HIDE_GROUPS, payload: res.data })
    );
  };
};

export const listUnsubs = (search, page = 1, limit = 25) => {
  return async function (dispatch) {
    // Add the query parameters to the URL
    const url = `${process.env.REACT_APP_API_URL}/api/unsubscribers?search=${search}&page=${page}&limit=${limit}`;

    return axios({
      url,
      method: 'GET',
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_UNSUBS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  };
};

export const listGroupsExceptUnsub = () => {
  // except unsub && blocked === true
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups`,
      method: 'GET',
      withCredentials: true
    }).then(res =>
      dispatch({ type: LIST_GROUPS_EXCEPT_UNSUB, payload: res.data })
    );
  };
};

export const createGroup = group => async dispatch => {
  try {
    dispatch({ type: GROUP_CREATE_REQUEST });

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups`,
      method: 'POST',
      data: group,
      withCredentials: true
    });
    // this dispatch was not running because there was not response being sent from backend
    dispatch({ type: GROUP_CREATE_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({
      type: GROUP_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteGroup = id => async dispatch => {
  try {
    dispatch({ type: GROUP_DELETE_REQUEST });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/groups/${id}`, {
      withCredentials: true
    });

    dispatch({ type: GROUP_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: GROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateGroup = (group, id) => async dispatch => {
  console.log(group, id);
  try {
    dispatch({ type: GROUP_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups-title/${id}`,
      method: 'PUT',
      data: group,
      withCredentials: true
    });

    dispatch({
      type: GROUP_UPDATE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: GROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateGroupShowHide = (hide, id) => async dispatch => {
  try {
    dispatch({ type: GROUP_SHOW_HIDE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups-show-hide`,
      method: 'POST',
      data: { hide: hide, id: id },
      withCredentials: true
    });

    dispatch({
      type: GROUP_SHOW_HIDE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: GROUP_SHOW_HIDE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleGroups = isSelected => async dispatch => {
  try {
    dispatch({ type: GROUP_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({ type: GROUP_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: GROUP_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const addContactsToGroup = (groupId, selectedRows) => async dispatch => {
  try {
    dispatch({ type: GROUP_UPDATE_REQUEST });

    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/groups/${groupId}`,
        { groupId: groupId, selectedRows: selectedRows },
        { withCredentials: true }
      )
      .then(res => dispatch({ type: GROUP_UPDATE_SUCCESS, payload: res }));
  } catch (error) {
    dispatch({
      type: GROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const addContactsToMultipleGroups =
  (selectedContactRows, selectedGroups) => async dispatch => {
    try {
      dispatch({ type: GROUP_UPDATE_REQUEST });

      await axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/contacts/group-add`,
          { selectedContactRows, selectedGroups },
          { withCredentials: true }
        )
        .then(res => dispatch({ type: GROUP_UPDATE_SUCCESS, payload: res }));
    } catch (error) {
      dispatch({
        type: GROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const moveCopyContactsToGroup = changeGroups => async dispatch => {
  try {
    dispatch({ type: GROUP_MOVE_COPY_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/groups-move-copy`,
      method: 'POST',
      data: changeGroups,
      withCredentials: true
    });

    dispatch({ type: GROUP_MOVE_COPY_SUCCESS });
  } catch (error) {
    dispatch({
      type: GROUP_MOVE_COPY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const groupById = GroupId => async dispatch => {
  try {
    dispatch({ type: GROUP_DETAILS_REQUEST });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/groups/${GroupId}`, {
        withCredentials: true
      })
      .then(res =>
        dispatch({ type: GROUP_DETAILS_SUCCESS, payload: res.data })
      );
  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const groupByIdTitle = GroupId => async dispatch => {
  try {
    dispatch({ type: GROUP_DETAILS_TITLE_REQUEST });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/groups-title/${GroupId}`, {
        withCredentials: true
      })
      .then(res =>
        dispatch({ type: GROUP_DETAILS_TITLE_SUCCESS, payload: res })
      );
  } catch (error) {
    dispatch({
      type: GROUP_DETAILS_TITLE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//----------------------------------------------------------------------- Fields----------------------------------------------------------------------

export const createField = field => async dispatch => {
  try {
    dispatch({ type: FIELD_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/fields/create`,
      method: 'POST',
      data: field,
      withCredentials: true
    });
    // this dispatch was not running because there was not response being sent from backend
    dispatch({ type: FIELD_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: FIELD_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listFields = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 25,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/fields/create?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_FIELDS_REQUEST });

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_FIELDS_SUCCESS, payload: { fields: res.data.fields, totalItems: res.data.totalItems } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_FIELDS_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
}

export const deleteField = id => async dispatch => {
  try {
    dispatch({
      type: FIELD_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/fields/${id}`, {
      withCredentials: true
    });

    dispatch({
      type: FIELD_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: FIELD_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleFields = isSelected => async dispatch => {
  try {
    dispatch({ type: FIELD_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/fields-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({ type: FIELD_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: FIELD_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateField = (field, id) => async dispatch => {
  try {
    dispatch({
      type: FIELD_UPDATE_REQUEST
    });

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/fields/${id}`,
      { field: field, id: id },
      { withCredentials: true }
    );

    dispatch({
      type: FIELD_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: FIELD_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const fieldById = FieldId => async dispatch => {
  try {
    dispatch({ type: FIELD_DETAILS_REQUEST });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/fields/${FieldId}`, {
        withCredentials: true
      })
      .then(res => dispatch({ type: FIELD_DETAILS_SUCCESS, payload: res }));
  } catch (error) {
    dispatch({
      type: FIELD_DETAILS_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Keywords----------------------------------------------------------------------

export const createKeyword = keyword => async dispatch => {
  try {
    dispatch({ type: KEYWORD_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/keywords/create`,
      method: 'POST',
      data: keyword,
      withCredentials: true
    }).then(res => {
      dispatch({ type: KEYWORD_CREATE_SUCCESS, payload: res.data });
    });
    // this dispatch was not running because there was not response being sent from backend
  } catch (error) {
    dispatch({
      type: KEYWORD_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listKeywords = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 10,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/keywords?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_KEYWORDS_REQUEST });

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_KEYWORDS_SUCCESS, payload: { keywords: res.data.keywords, totalKeywords: res.data.totalKeywords } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_KEYWORDS_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
}

export const deleteMultipleKeywords = isSelected => async dispatch => {
  try {
    dispatch({ type: KEYWORD_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/keywords-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({ type: KEYWORD_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: KEYWORD_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateKeyword = (keyword, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: KEYWORD_UPDATE_REQUEST
    });

    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/keywords/${id}`,
      keyword,
      { withCredentials: true }
    );

    dispatch({
      type: KEYWORD_UPDATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: KEYWORD_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const keywordById = FieldId => async dispatch => {
  try {
    dispatch({ type: FIELD_DETAILS_REQUEST });
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/fields/${FieldId}`)
      .then(res => dispatch({ type: FIELD_DETAILS_SUCCESS, payload: res }));
  } catch (error) {
    dispatch({
      type: FIELD_DETAILS_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Csv Upload----------------------------------------------------------------------

export const listResponders = (options = {}) => {
  return async function (dispatch) {
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 25,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/responders?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_RESPONDERS_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_RESPONDERS_SUCCESS, payload: { responders: res.data.responders, totalItems: res.data.totalItems } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_RESPONDERS_FAILURE, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
};

export const createResponder = upload => async dispatch => {
  try {
    dispatch({ type: RESPONDER_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/responders/create`,
      method: 'POST',
      data: upload,
      withCredentials: true
    });

    dispatch({ type: RESPONDER_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: RESPONDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateResponder = (responder, id) => async dispatch => {
  try {
    dispatch({ type: RESPONDER_UPDATE_REQUEST });

    const { data } = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/responders/${id}`,
      method: 'PUT',
      data: responder,
      withCredentials: true
    });

    dispatch({
      type: RESPONDER_UPDATE_SUCCESS,
      payload: data
    });
    //dispatch({ type: CONTACT_DETAILS_SUCCESS, payload: data})
  } catch (error) {
    dispatch({
      type: RESPONDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleResponders = isSelected => async dispatch => {
  try {
    dispatch({ type: RESPONDER_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/responders-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({
      type: RESPONDER_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: RESPONDER_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//-----------------------------------------------------------------------Csv Upload----------------------------------------------------------------------


export const listFiles = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 25,
      // users,
      sortBy = 'created',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/files/create?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_CSVUPLOAD_REQUEST });

    return axios({
      url,
      method: "GET",
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_CSVUPLOAD_SUCCESS, payload: { files: res.data.files, totalItems: res.data.totalItems } });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_CSVUPLOAD_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  }
}

export const createCsv = upload => async dispatch => {
  try {
    dispatch({ type: CSVUPLOAD_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/files/create`,
      method: 'POST',
      data: upload,
      withCredentials: true
    });

    dispatch({ type: CSVUPLOAD_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CSVUPLOAD_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteFiles = id => async dispatch => {
  try {
    dispatch({
      type: CSVUPLOAD_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/files/${id}`, {
      withCredentials: true
    });

    dispatch({
      type: CSVUPLOAD_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: CSVUPLOAD_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleFiles = isSelected => async dispatch => {
  try {
    dispatch({ type: CSVUPLOAD_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/files-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({
      type: CSVUPLOAD_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: CSVUPLOAD_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const attachFile = (uploadFile, linkTitle) => async dispatch => {
  try {
    dispatch({ type: ATTACH_FILE_CREATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/attach-file`,
      method: 'POST',
      data: { uploadFile, linkTitle },
      withCredentials: true
    }).then(res =>
      dispatch({ type: ATTACH_FILE_CREATE_SUCCESS, payload: res })
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: ATTACH_FILE_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const attachMMS = uploadMMS => async dispatch => {
  try {
    // need to send with different headers? its always sending JSON // check bookmark to complete
    dispatch({ type: ATTACH_MMS_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/mms`,
      method: 'POST',
      data: uploadMMS,
      withCredentials: true
    }).then(res => dispatch({ type: ATTACH_MMS_CREATE_SUCCESS, payload: res }));
  } catch (error) {
    console.log(error);
    dispatch({
      type: ATTACH_MMS_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const previewMMS = data => async dispatch => {
  try {
    // need to send with different headers? its always sending JSON // check bookmark to complete
    dispatch({ type: PREVIEW_MMS_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/mms-preview`,
      method: 'POST',
      data: { uri: data },
      withCredentials: true
    }).then(res =>
      dispatch({ type: PREVIEW_MMS_CREATE_SUCCESS, payload: res })
    );
  } catch (error) {
    dispatch({
      type: PREVIEW_MMS_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};
//--------------------------------------------------------------------Calendar--------------------------------------------------------------------

export const listCalendar = () => async dispatch => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_CALENDAR, payload: res.data }));
  } catch (error) { }
};

export const listPausedCalendar = (options = {}) => async dispatch => {
  try {
    const {
      search = '',
      page = 1,
      limit = 25,
      users,
      sortBy = 'createdAt',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/calendar?`;

    // Append 'users' to the URL only if it's defined and not empty
    if (users) {
      url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    // Use the constructed URL in the axios request
    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_PAUSED_CALENDAR, payload: res.data }));
  } catch (error) {
    console.error("Error in listPausedCalendar:", error);
  }
};

export const listCompletedCalendar = (options = {}) => async dispatch => {
  try {

    const {
      search = '',
      page = 1,
      limit = 25,
      users,
      sortBy = 'createdAt',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/calendar?`;

    // Append 'users' to the URL only if it's defined and not empty
    if (users) {
      url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;


    await axios({
      url: url,
      method: 'GET',
      withCredentials: true
    }).then(res =>
      dispatch({ type: LIST_COMPLETED_CALENDAR, payload: res.data })
    );
  } catch (error) { }
};

export const calendarCreate = event => async dispatch => {
  try {
    dispatch({ type: CALENDAR_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar`,
      method: 'POST',
      data: { event },
      withCredentials: true
    });
    dispatch({ type: CALENDAR_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALENDAR_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const calendarDelete = id => async dispatch => {
  try {
    dispatch({
      type: CALENDAR_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/calendar/${id}`, {
      withCredentials: true
    });

    dispatch({
      type: CALENDAR_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: CALENDAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteMultipleCalendars = isSelected => async dispatch => {
  try {
    dispatch({ type: CALENDAR_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar-remove`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    });

    dispatch({ type: CALENDAR_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALENDAR_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const calendarUpdate = event => async dispatch => {
  try {
    dispatch({ type: CALENDAR_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar`,
      method: 'PUT',
      data: { event },
      withCredentials: true
    });
    dispatch({ type: CALENDAR_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALENDAR_UPDATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const calendarResume = event => async dispatch => {
  try {
    dispatch({ type: CALENDAR_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar-resume`,
      method: 'POST',
      data: { event },
      withCredentials: true
    }).then(res => {
      dispatch({ type: CALENDAR_UPDATE_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: CALENDAR_UPDATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const calendarPause = event => async dispatch => {
  try {
    dispatch({ type: CALENDAR_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar-pause`,
      method: 'POST',
      data: { event },
      withCredentials: true
    });
    dispatch({ type: CALENDAR_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALENDAR_UPDATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const resumeMultipleCalendars = isSelected => async dispatch => {
  try {
    dispatch({ type: CALENDAR_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calendar-update`,
      method: 'POST',
      data: isSelected,
      withCredentials: true
    }).then(res => {
      dispatch({ type: CALENDAR_UPDATE_SUCCESS });
    });

    dispatch({ type: CALENDAR_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALENDAR_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

//--------------------------------------------------------------------History--------------------------------------------------------------------

export const listSentMessages = (options = {}) => {
  return async function (dispatch) {
    const {
      search = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/history-sent?`;

    // Append query parameters
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_SENT_MESSAGES_REQUEST });

    const res = await axios({
      url: url,
      method: 'GET',
      withCredentials: true,
    });

    dispatch({ type: LIST_SENT_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};


export const listSentMessagesDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_SENT_MESSAGES_REQUEST });

    return axios({
      url: `${process.env.REACT_APP_API_URL}/api/history-sent-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    }).then(res => {
      dispatch({ type: LIST_SENT_MESSAGES_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_SENT_MESSAGES_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  };
};


export const listSentMessagesCustomDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_SENT_MESSAGES_REQUEST });

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/history-sent-custom-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    });

    dispatch({ type: LIST_SENT_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};


export const listReceivedMessages = (options = {}) => {
  return async function (dispatch) {
    const {
      search = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/history-received?`;

    // Append query parameters
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_RECEIVED_MESSAGES_REQUEST });

    const res = await axios({
      url: url,
      method: 'GET',
      withCredentials: true,
    });

    dispatch({ type: LIST_RECEIVED_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};



export const listReceivedMessagesDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_RECEIVED_MESSAGES_REQUEST });


    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/history-received-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    });

    dispatch({ type: LIST_RECEIVED_MESSAGES_SUCCESS, payload: res.data });

    return res.data; // Return res.data to access hasMoreData and totalItems
  };
};

export const listReceivedMessagesCustomDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_RECEIVED_MESSAGES_REQUEST });


    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/history-receive-custom-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    });

    dispatch({ type: LIST_RECEIVED_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};



export const listInvoices = () => async dispatch => {
  try {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/follow-up-invoices`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_INVOICES, payload: res.data }));
  } catch (error) { }
};
//--------------------------------------------------------------------Extra Functions--------------------------------------------------------------------

export const chargeUserForCall = () => async dispatch => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/update-user`,
      method: 'POST',
      data: {},
      withCredentials: true
    });

    dispatch({ type: USER_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteAccount = id => async dispatch => {
  try {
    dispatch({ type: ACCOUNT_DELETE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/account-delete/${id}`,
      method: 'DELETE',
      withCredentials: true
    }).then(res => {
      return dispatch({ type: ACCOUNT_DELETE_SUCCESS, payload: res.data });
    });
  } catch (error) {
    dispatch({
      type: ACCOUNT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const fetchUser = () => {
  return async function (dispatch) {
    // we need dispatch prop
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/current_user`, {
        withCredentials: true
      })
      .then(res => {
        dispatch({ type: FETCH_USER, payload: res.data });
      });
  };
};

export const logoutUser = navigate => {
  return async function (dispatch) {
    try {
      console.log('logout user ran');
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/logout`,
        { withCredentials: true }
      );
      if (response.data.logoutSuccess) {
        dispatch({ type: CLEAR_USER });
        navigate('/authentication/simple/login'); // Redirect to login page or home
      }
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };
};

export const deleteUser = id => {
  return async function (dispatch) {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/users/${id}`
    );
    dispatch({ type: DELETE_USER, payload: res.data });
  };
};

// sending stripe token to backend
export const handleCheckout = () => async dispatch => {
  await axios({
    url: `${process.env.REACT_APP_API_URL}/api/stripe`,
    method: 'POST',
    withCredentials: true
  }).then(res => dispatch({ type: FETCH_USER, payload: res }));
};

export const updateUser = (id, data) => async dispatch => {
  console.log("id", id)
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/account-update/${id}`,
      method: 'POST',
      data: data,
      withCredentials: true
    });

    dispatch({ type: USER_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateUserFirstLogin = (id, data) => async dispatch => {
  try {
    dispatch({ type: USER_UPDATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/account-update-first/${id}`,
      method: 'POST',
      data: data,
      withCredentials: true
    });

    dispatch({ type: USER_UPDATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: USER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const addSelectedContactsToGroup =
  (selectedIds, groupId) => async dispatch => {
    try {
      dispatch({ type: GROUP_UPDATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/groups/${groupId}/addContacts`,
        method: 'POST',
        data: { selectedIds },
        withCredentials: true
      });

      dispatch({ type: GROUP_UPDATE_SUCCESS });
    } catch (error) {
      dispatch({
        type: GROUP_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const fetchContactsRequest = (pageIndex, pageSize, searchQuery) => ({
  type: FETCH_CONTACTS_REQUEST,
  payload: { pageIndex, pageSize, searchQuery }
});

export const fetchContactsSuccess = (contacts, pageCount) => ({
  type: FETCH_CONTACTS_SUCCESS,
  payload: { contacts, pageCount }
});

export const fetchContactsFailure = error => ({
  type: FETCH_CONTACTS_FAILURE,
  payload: { error }
});

export const fetchContacts = (pageIndex, pageSize, searchQuery) => {
  return function (dispatch) {
    dispatch(fetchContactsRequest(pageIndex + 1, pageSize, searchQuery));
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/contacts/create?search=${searchQuery}&page=${pageIndex + 1
        }&limit=${pageSize} `,
        { withCredentials: true }
      )
      .then(response => {
        const contacts = response.data.contacts;
        const pageCount = response.data.pageCount;
        dispatch(fetchContactsSuccess(contacts, pageCount));
      })
      .catch(error => {
        dispatch(fetchContactsFailure(error));
      });
  };
};

export const createBrand = brand => async dispatch => {
  try {
    dispatch({ type: BRAND_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/brand/create`,
      method: 'POST',
      data: brand,
      withCredentials: true
    });
    // this dispatch was not running because there was not response being sent from backend
    dispatch({ type: BRAND_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: BRAND_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listBrand = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_BRAND_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/brand`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: LIST_BRAND_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_BRAND_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const deleteBrand = id => async dispatch => {
  try {
    dispatch({
      type: BRAND_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/brand/${id}`, {
      withCredentials: true
    });

    dispatch({
      type: BRAND_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: BRAND_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateBrand = (brand, id) => async dispatch => {
  try {
    dispatch({ type: BRAND_UPDATE_REQUEST });

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/brand/${id}`,
      method: 'PUT',
      data: brand,
      withCredentials: true
    });

    console.log("res.data", res.data)

    dispatch({
      type: BRAND_UPDATE_SUCCESS,
      payload: res.data
    });
  } catch (error) {
    dispatch({
      type: BRAND_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const createDLCRegistration = uploadInfo => async dispatch => {
  try {
    dispatch({ type: DLC_CAMPAIGN_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/dlc-registration`,
      method: 'POST',
      data: uploadInfo,
      withCredentials: true
    });

    dispatch({ type: DLC_CAMPAIGN_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DLC_CAMPAIGN_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listDLCRegistration = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_DLC_CAMPAIGN_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/dlc-registration`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: LIST_DLC_CAMPAIGN_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_DLC_CAMPAIGN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const deleteDLCRegistration = id => async dispatch => {
  try {
    dispatch({
      type: DLC_CAMPAIGN_DELETE_REQUEST
    });

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/dlc-registration/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: DLC_CAMPAIGN_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: DLC_CAMPAIGN_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateDLCRegistration =
  (dlcRegistration, id) => async dispatch => {
    try {
      dispatch({ type: DLC_CAMPAIGN_UPDATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/dlc-registration/${id}`,
        method: 'PUT',
        data: dlcRegistration,
        withCredentials: true
      });

      dispatch({
        type: DLC_CAMPAIGN_UPDATE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: DLC_CAMPAIGN_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const createTollFreeVerification = uploadInfo => async dispatch => {
  try {
    dispatch({ type: TOLL_FREE_VERIFICATION_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/toll-free-verification/create`,
      method: 'POST',
      data: uploadInfo,
      withCredentials: true
    });
    // this dispatch was not running because there was not response being sent from backend
    dispatch({ type: TOLL_FREE_VERIFICATION_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: TOLL_FREE_VERIFICATION_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listTollFreeVerification = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_TOLL_FREE_VERIFICATION_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/toll-free-verification`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({
        type: LIST_TOLL_FREE_VERIFICATION_SUCCESS,
        payload: res.data
      });
    } catch (error) {
      dispatch({
        type: LIST_TOLL_FREE_VERIFICATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const deleteTollFreeVerification = id => async dispatch => {
  try {
    dispatch({
      type: TOLL_FREE_VERIFICATION_DELETE_REQUEST
    });

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/toll-free-verification/${id}`,
      { withCredentials: true }
    );

    dispatch({
      type: TOLL_FREE_VERIFICATION_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: TOLL_FREE_VERIFICATION_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateTollFreeVerification =
  (tollFreeVerification, id) => async dispatch => {
    try {
      dispatch({ type: TOLL_FREE_VERIFICATION_UPDATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/toll-free-verification/${id}`,
        method: 'PUT',
        data: tollFreeVerification,
        withCredentials: true
      });

      dispatch({
        type: TOLL_FREE_VERIFICATION_UPDATE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: TOLL_FREE_VERIFICATION_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };




export const updatePageSize = (tableId, pageSize) => ({
  type: UPDATE_PAGE_SIZE,
  payload: { tableId, pageSize },
});

export const updatePageIndex = (tableId, pageIndex) => ({
  type: UPDATE_PAGE_INDEX,
  payload: { tableId, pageIndex },
});


export const addSelectedRows = (rowIds) => ({
  type: ADD_SELECTED_ROWS,
  payload: rowIds,
});

export const removeSelectedRows = (rowIds) => ({
  type: REMOVE_SELECTED_ROWS,
  payload: rowIds,
});

export const resetSelectedRows = () => ({
  type: RESET_SELECTED_ROWS,
});

export const updateSelectedContactRows = (selectedRowIds, selectedFlatRows) => ({
  type: UPDATE_SELECTED_CONTACT_ROWS,
  payload: { selectedRowIds, selectedFlatRows }
});

/**
 * Remove the specified rows from the global selection.
 *
 */

export const addSelectedContactRows = (rowIds) => ({
  type: ADD_SELECTED_CONTACT_ROWS,
  payload: rowIds,
});


export const removeSelectedContactRows = (ids) => ({
  type: REMOVE_SELECTED_CONTACT_ROWS,
  payload: ids
});

/**
 * Reset the global selected rows, clearing the selection entirely.
 */
export const resetSelectedContactRows = () => ({
  type: RESET_SELECTED_CONTACT_ROWS
});