
import { LIST_BLOCKED_REQUEST, LIST_BLOCKED_SUCCESS, LIST_BLOCKED_FAIL } from '../actions/types';


const initialState = {
  contacts: [],
  totalItems: 0,
  loading: false,
  error: null
};


export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_BLOCKED_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_BLOCKED_SUCCESS:
      const contacts = action.payload?.contacts
      const contactsWithNames = contacts?.map(el => {
        return { ...el, name: el.firstName + ' ' + el.lastName, key: el._id };
      });
      return {
        ...state,
        contacts: contactsWithNames,
        totalItems: action.payload.totalItems,
        loading: false
      }
    case LIST_BLOCKED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
