import React, { Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Modal, ModalBody, Button, Card, CardBody, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import Divider from 'components/common/Divider';
import {
  archiveChat,
  deleteChat,
  reOpenChat,
  updateChatUnread,
  updateChatRead,
  muteChat,
  unmuteChat
} from '../../../actions/chatActions';
import {
  createBlockedContacts,
  unsubscribeContacts
} from '../../../actions/index';
import {
  DELETE_CHAT,
  EDIT_CHAT,
  REMOVE_BLOCKED_CHAT,
  REMOVE_UNSUBSCRIBED_CHAT
} from '../../../actions/types';

const ChatSidebarDropdownAction = ({ contact, chat }) => {
  const dispatch = useDispatch();

  const [showDeleteConvoModal, setShowDeleteConvoModal] = useState(false);

  return (
    <Dropdown
      className="dropdown-active-trigger dropdown-chat"
      onClick={e => e.stopPropagation()}
    >
      <Dropdown.Toggle
        id="dropdown-button"
        className="hover-actions text-400 dropdown-caret-none fs-9 z-1 "
        variant="link"
        size="sm"
      >
        <FontAwesomeIcon icon="cog" transform="shrink-3 down-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="py-2 rounded-soft border">
        {chat?.mute === 'true' ? (
          <div>
            <Dropdown.Item
              onClick={() => {
                return (
                  dispatch({
                    type: EDIT_CHAT,
                    payload: { ...chat, mute: 'false' }
                  }),
                  dispatch(unmuteChat(chat))
                );
              }}
              className="cursor-pointer"
            >
              Unmute
            </Dropdown.Item>
          </div>
        ) : (
          <Dropdown.Item
            onClick={() => {
              return (
                dispatch({
                  type: EDIT_CHAT,
                  payload: { ...chat, mute: 'true' }
                }),
                dispatch(muteChat({ ...chat, mute: 'true' }))
              );
            }}
            className="cursor-pointer"
          >
            Mute
          </Dropdown.Item>
        )}
        <Dropdown.Divider />
        {chat?.state === 'inactive' ? (
          <div>
            <Dropdown.Item
              onClick={() => {
                return (
                  dispatch({
                    type: EDIT_CHAT,
                    payload: { ...chat, state: 'active' }
                  }),
                  dispatch(reOpenChat(chat))
                );
              }}
              className="cursor-pointer"
            >
              Re-open
            </Dropdown.Item>
          </div>
        ) : (
          <Dropdown.Item
            onClick={() => {
              return (
                dispatch({
                  type: EDIT_CHAT,
                  payload: { ...chat, state: 'inactive' }
                }),
                dispatch(archiveChat({ ...chat, state: 'inactive' }))
              );
            }}
            className="cursor-pointer"
          >
            Archive
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => {
            return setShowDeleteConvoModal(!showDeleteConvoModal);
          }}
          className="cursor-pointer text-danger"
        >
          Delete Conversation
        </Dropdown.Item>
        <Dropdown.Divider />
        {chat?.read === 'false' ? (
          <Dropdown.Item
            onClick={() => {
              return (
                dispatch({
                  type: EDIT_CHAT,
                  payload: { ...chat, read: 'true' }
                }),
                dispatch(updateChatRead({ ...chat, read: 'true' }))
              );
            }}
            className="cursor-pointer"
          >
            Mark as Read
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            onClick={() => {
              return (
                dispatch({
                  type: EDIT_CHAT,
                  payload: { ...chat, read: 'false' }
                }),
                dispatch(updateChatUnread({ ...chat, read: 'false' }))
              );
            }}
            className="cursor-pointer"
          >
            Mark as Unread
          </Dropdown.Item>
        )}

        <Dropdown.Item
          onClick={() => {
            if (contact) {
              return (
                dispatch({ type: REMOVE_UNSUBSCRIBED_CHAT, payload: chat }),
                dispatch(deleteChat(chat)),
                dispatch(unsubscribeContacts(contact))
              );
            } else {
              return (
                dispatch({ type: REMOVE_UNSUBSCRIBED_CHAT, payload: chat }),
                dispatch(deleteChat(chat)),
                dispatch(unsubscribeContacts(chat))
              );
            }
          }}
          className="cursor-pointer"
        >
          Unsubscribe
        </Dropdown.Item>

        <Dropdown.Item
          onClick={() => {
            return (
              dispatch({ type: REMOVE_BLOCKED_CHAT, payload: chat }),
              dispatch(createBlockedContacts([contact._id]))
            );
          }}
          className="cursor-pointer"
        >
          Block Messages
        </Dropdown.Item>
      </Dropdown.Menu>

      <Fragment>
        <Modal
          isOpen={showDeleteConvoModal}
          centered
          toggle={() => setShowDeleteConvoModal(!showDeleteConvoModal)}
        >
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Label>Would you like to delete this Conversation?</Label>
                <Divider></Divider>
                <Button
                  onClick={() => {
                    return (
                      dispatch({ type: DELETE_CHAT, payload: { chat } }), // dispatch a read action to reducer
                      dispatch(deleteChat(chat)),
                      setShowDeleteConvoModal(!showDeleteConvoModal)
                    );
                  }}
                  block
                  className="btn-tertiary border-dark text-danger"
                >
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    setShowDeleteConvoModal(!showDeleteConvoModal);
                  }}
                  block
                  className="btn-tertiary border-dark text-primary mt-2"
                >
                  Cancel
                </Button>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </Fragment>
    </Dropdown>
  );
};

export default ChatSidebarDropdownAction;
