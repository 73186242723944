import { LIST_UNSUBS } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_UNSUBS:
      const contacts = action.payload;
      return contacts?.map(el => {
        return { ...el, name: el.firstName + ' ' + el.lastName, key: el._id };
      });
    default:
      return state;
  }
}
