import {
  LIST_KEYWORDS_REQUEST,
  LIST_KEYWORDS_SUCCESS,
  LIST_KEYWORDS_FAIL
} from '../actions/types';

const initialState = {
  keywords: [],
  totalItems: 0,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_KEYWORDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_KEYWORDS_SUCCESS:
      return {
        ...state,
        keywords: action.payload.keywords,
        totalKeywords: action.payload.totalKeywords,
        loading: false
      };
    case LIST_KEYWORDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}