import { SET_EMAIL, SET_PASS } from '../actions/types';

const initialState = {
    email: '',
    password: ''
}

export default function emailAuthFlowReducer(state = initialState, action) {
    switch (action.type) {
      case SET_EMAIL: {
        return {
          ...state,
          email: action.payload
        };
      }
      case SET_PASS: {
        return {
          ...state,
          password: action.payload
        };
      }
      default:
        return state;
    }
  }