import {
  LIST_TRANSACTIONS_REQUEST,
  LIST_TRANSACTIONS_SUCCESS,
  LIST_TRANSACTIONS_FAIL
} from '../actions/types';

const initialState = {
  transactions: [],
  totalItems: 0,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_TRANSACTIONS_SUCCESS:
      const add_idForTable = action?.payload?.transactions?.map((transaction) => {
        return {
          ...transaction,
          _id: transaction?.id
        }
      })
      return {
        ...state,
        transactions: add_idForTable,
        totalTransactions: action.payload.totalTransactions,
        loading: false
      };
    case LIST_TRANSACTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}