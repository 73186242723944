export default [
    {
        question: 'Whats included with the Agency Plan?',
        answer:
            'Agency plan assigns you a dedicated team rep that will source all your best lead sources and compile them into a list for you to use. We will also handle all your compliance needs.'
    },
    {
        question:
            'How many messages can you send per credit?',
        answer:
            'You can send 1 message per credit. If you are sending a message to multiple recipients, each recipient will be charged 1 credit.'
    },
    {
        question: 'Do credits rollover?',
        answer:
            'Yes, credits never expire. You can use them whenever you want.'
    },
    {
        question: 'Do I lose my number if I cancel?',
        answer:
            'Your numbers require a monthly fee to stay active, If you cancel your subscription, you will lose your number after 30 days if you dont keep enough in your account to renew it.'
    },
    {
        question:
            'How long does it take to receive credits?',
        answer:
            'Credits are deposited instantly after payment.'
    },
    {
        question:
            'How do I ensure everything is compliant?',
        answer:
            'Staying complaint is simple. We have built in safeguards to ensure you are always compliant once a campaign has been approved. Agency plan handles all the compliance for you as a plug and play option.'
    }
];
