import { UPDATE_SELECTED_SENT_ROWS, REMOVE_SELECTED_SENT_ROWS, RESET_SELECTED_SENT_ROWS } from '../actions/types';

export default function (state = [], action) {
    switch (action.type) {
        case UPDATE_SELECTED_SENT_ROWS:
            // Assuming action.payload.selectedFlatRows is an array
            if (!state.length) {
                return action.payload.selectedFlatRows.map(el => ({
                    id: el?.original?.name?._id ?? '', // Default to empty string if _id is undefined
                    original: el?.original?.name ?? {} // Default to empty object if name is undefined
                }));
            } else {
                const matched = Object.keys(action.payload.selectedRowIds).map(
                    selRow => {
                        const foundRow = state.find(el => el.id === selRow);
                        if (foundRow) {
                            return foundRow;
                        } else {
                            const newRow = action.payload.selectedFlatRows.find(
                                el => el?.original?.name?._id === selRow
                            );
                            if (newRow) {
                                return {
                                    id: newRow?.id ?? '', // Default to empty string if id is undefined
                                    original: {
                                        _id: newRow?.original?.name?._id ?? '',
                                        firstName: newRow?.original?.name?.firstName ?? '',
                                        lastName: newRow?.original?.name?.lastName ?? '',
                                        phone_number: newRow?.original?.name?.phone_number ?? ''
                                    }
                                };
                            }
                        }
                    }
                );

                return matched.filter(row => row !== undefined);
            }

        case REMOVE_SELECTED_SENT_ROWS:
            return state.filter(el => {
                return !action.payload.includes(el.id);
            });

        case RESET_SELECTED_SENT_ROWS:
            return [];

        default:
            return state;
    }
}
