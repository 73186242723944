import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Col, Row, Modal, Alert, Form, Spinner } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import axios from 'axios';
import visaIcon from '../../../assets/img/icons/visa.png';
import mastercardIcon from '../../../assets/img/icons/master-card.png';
import amexIcon from '../../../assets/img/icons/amex.png';
import discoverIcon from '../../../assets/img/icons/discover.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard as faSolidCreditCard } from '@fortawesome/free-solid-svg-icons';
import SelectPaymentMethod from './SelectPaymentMethod';
import { listFollowUpBillingDetails, cancelFollowUpSubscription } from 'actions/followUpBillingActions';
import { fetchTeamDetails } from 'actions/teamActions';
import { useAppContext } from 'providers/AppProvider';
import { SUBSCRIPTION_CANCEL_RESET } from 'actions/types';

const ManageSubscription = () => {
  const dispatch = useDispatch();
  const subscribeCancel = useSelector((state) => state.followUpSubscriptionCancel);
  const { success: successCancel, loading: cancelLoading, error: cancelError } = subscribeCancel;
  const { config: { isDark } } = useAppContext();

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showUpdatePaymentModal, setShowUpdatePaymentModal] = useState(false); // For payment method modal
  const [remainingDays, setRemainingDays] = useState(0);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null); // Store the payment method
  const [hasUpdatedPaymentMethod, setHasUpdatedPaymentMethod] = useState(false)
  const [loadingPaymentMethod, setLoadingPaymentMethod] = useState(false); // Loading state for payment method

  const followUpBillingDetails = useSelector((state) => state.followUpBillingDetails?.billingDetails[0]);

  // Fetch the current payment method from the server
  const fetchPaymentMethod = async () => {
    setLoadingPaymentMethod(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/customer-subscription-payment-method-fup`,
        {}, // If you need to pass any data, add here
        { withCredentials: true }
      );
      setCurrentPaymentMethod(data.paymentMethod); // Set the fetched payment method
    } catch (error) {
      console.error('Error fetching payment method:', error);
    } finally {
      setLoadingPaymentMethod(false);
      setHasUpdatedPaymentMethod(false); // Reset to false after fetching
    }
  };

  useEffect(() => {
    if (successCancel) {
      setShowCancelModal(false);
      dispatch(listFollowUpBillingDetails());
      dispatch(fetchTeamDetails());
      dispatch({ type: SUBSCRIPTION_CANCEL_RESET })
    }
  }, [successCancel])

  // Effect to run once on initial load, and again only when hasUpdatedPaymentMethod is true
  useEffect(() => {
    if (hasUpdatedPaymentMethod || currentPaymentMethod === null) {
      fetchPaymentMethod();
    }
  }, [hasUpdatedPaymentMethod]);

  useEffect(() => {
    if (followUpBillingDetails?.nextBillingDate) {
      const now = new Date();
      const nextBillingDate = new Date(followUpBillingDetails.nextBillingDate);
      const diffTime = nextBillingDate - now;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setRemainingDays(diffDays > 0 ? diffDays : 0);
    } else {
      setRemainingDays(0);
    }
  }, [followUpBillingDetails]);

  const handleCancelSubscription = () => {
    dispatch(cancelFollowUpSubscription(followUpBillingDetails?.authnetSubscriptionId));
  };

  // Open the modal for updating payment method
  const handleUpdatePaymentMethod = () => {
    setShowUpdatePaymentModal(true);
  };


  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem',
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff',
  };

  return (
    <Card className="my-3 shadow-sm">
      {loadingPaymentMethod && (
        <div style={overlayStyle}>
          <Spinner animation="border" role="status" style={spinnerStyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <FalconCardHeader
        title="Manage Subscription"
        light
        titleTag="h4"
        className="bg-light py-3"
      />
      <Card.Body>
        <h5>Plan Details</h5>
        {followUpBillingDetails && followUpBillingDetails?.subscriptionType !== "payg" ? (
          <div>
            <p className="fs-9 mb-1">
              <strong>Current Plan:</strong>{' '}
              {followUpBillingDetails.subscriptionType.replace('_', ' ')}
            </p>
            <p className="mb-1">
              <strong>Status:</strong>{' '}
              {followUpBillingDetails.subscriptionStatus === 'active' ? (
                <span className="text-success">Active</span>
              ) : followUpBillingDetails.subscriptionStatus === 'cancelled' ? (
                <span className="text-danger">Cancelled</span>
              ) : (
                <span className="text-muted">{followUpBillingDetails.subscriptionStatus}</span>
              )}
            </p>

            {/* Display pending plan change if it exists */}
            {followUpBillingDetails?.pendingPlanChange && (
              <Alert variant="info" className="mt-3">
                <p className="mb-1">
                  <strong>Upcoming Plan Change:</strong>
                </p>
                <p className="mb-1">
                  Your plan will change from{' '}
                  <strong>{followUpBillingDetails.pendingPlanChange.oldPlan.replace('_', ' ')}</strong>{' '}
                  to{' '}
                  <strong>{followUpBillingDetails.pendingPlanChange.newPlan.replace('_', ' ')}</strong>{' '}
                  on{' '}
                  {new Date(followUpBillingDetails.nextBillingDate).toLocaleDateString()}.
                </p>
                <p className="mb-0">
                  You have {remainingDays} days remaining until the change takes effect.
                </p>
              </Alert>
            )}


            {(followUpBillingDetails.subscriptionStatus !== 'cancelled' && followUpBillingDetails.subscriptionStatus !== "cancellation_requested") && (
              <p className={`mb-1 text-${isDark ? "white" : "black"}`}>
                <strong>Next Billing Date:</strong>{' '}
                {new Date(followUpBillingDetails.nextBillingDate).toLocaleDateString()} (
                {remainingDays} days remaining)
              </p>
            )}
            {(followUpBillingDetails.subscriptionStatus === 'cancelled' || followUpBillingDetails.subscriptionStatus === 'cancellation_requested') && (
              <p className="mb-1">
                Your subscription will remain active until{' '}
                {new Date(followUpBillingDetails.nextBillingDate).toLocaleDateString()}. You have{' '}
                {remainingDays} days remaining.
              </p>
            )}

            <div>
              {currentPaymentMethod && (
                <div className="mb-4">
                  <p className="mb-1 mt-4">
                    <strong>Card on File:</strong>{' '}

                  </p>
                  <Row className="gx-0">
                    <Col xs={12} sm={10} md={6} lg={12}>
                      {/* Display the current payment method */}
                      <Card className="border mb-3 p-3">
                        <div className="d-flex align-items-center">
                          {/* Display appropriate card icon based on the card type */}
                          {(() => {
                            switch (currentPaymentMethod.cardType.toLowerCase()) {
                              case 'visa':
                                return <img src={visaIcon} alt="Visa" className="payform-card-icon me-2" />;
                              case 'mastercard':
                                return <img src={mastercardIcon} alt="MasterCard" className="payform-card-icon me-2" />;
                              case 'american express':
                              case 'amex':
                                return <img src={amexIcon} alt="American Express" className="payform-card-icon me-2" />;
                              case 'discover':
                                return <img src={discoverIcon} alt="Discover" className="payform-card-icon me-2" />;
                              default:
                                return <FontAwesomeIcon icon={faSolidCreditCard} size="2x" className="me-2" />;
                            }
                          })()}
                          <span>
                            <strong>{currentPaymentMethod.cardType}</strong> •••• {currentPaymentMethod.last4}
                          </span>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <Button className="btn-falcon-primary" size="sm" onClick={handleUpdatePaymentMethod}>
                        Update Payment Method
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
              <div className="mt-6">
                {followUpBillingDetails?.subscriptionStatus === 'active' && (
                  <Button
                    className="btn-falcon-danger"
                    size="sm"
                    onClick={() => setShowCancelModal(true)}
                  >
                    Cancel Plan
                  </Button>
                )}
                <Button
                  variant="primary"
                  className="mx-2"
                  size="sm"
                  as={Link}
                  to="/follow-up/monthly-plans"
                >
                  Update Plan
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <p>Pay as you go. Get discounts on monthly.</p>
            <Button variant="primary" size="sm" as={Link} to="/follow-up/monthly-plans">
              Choose a Plan
            </Button>
          </div>
        )}




        {/* Modal for updating payment method */}
        <Modal show={showUpdatePaymentModal} onHide={() => setShowUpdatePaymentModal(false)} centered>
          <Modal.Header closeButton></Modal.Header>
          <SelectPaymentMethod setHasUpdatedPaymentMethod={setHasUpdatedPaymentMethod} currentPaymentMethod={currentPaymentMethod} setShowUpdatePaymentModal={setShowUpdatePaymentModal} />
        </Modal>





        {/* Cancellation Confirmation Modal */}
        <Modal show={showCancelModal} backdrop={cancelLoading ? 'static' : true}
          onHide={() => setShowCancelModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Cancellation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {cancelLoading && (
              <div style={overlayStyle}>
                <Spinner animation="border" role="status" style={spinnerStyle}>
                  <span className="visually-hidden">Cancelling...</span>
                </Spinner>
              </div>
            )}
            <p>
              Are you sure you want to cancel your subscription? You will continue to have access
              until{' '}
              {followUpBillingDetails &&
                new Date(followUpBillingDetails.nextBillingDate).toLocaleDateString()}.
            </p>
            <Alert variant="warning">
              <strong>Note:</strong> You can re-subscribe at any time before your current
              subscription ends.
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={cancelLoading} variant="secondary" onClick={() => setShowCancelModal(false)}>
              Close
            </Button>
            <Button variant="danger" onClick={handleCancelSubscription} disabled={cancelLoading}>
              {cancelLoading ? 'Processing...' : 'Confirm Cancellation'}
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Success or Error Messages */}
        {successCancel && (
          <Alert variant="success" className="mt-3">
            Your subscription has been cancelled successfully.
          </Alert>
        )}
        {cancelError && (
          <Alert variant="danger" className="mt-3">
            {cancelError}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default ManageSubscription;
