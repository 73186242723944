import {
  CRM_SUBSCRIPTION_CREATE_REQUEST,
  CRM_SUBSCRIPTION_CREATE_FAIL,
  CRM_SUBSCRIPTION_CREATE_SUCCESS
} from '../actions/types';

export default function cmrSubscriptionCreateReducer(state = {}, action) {
  switch (action.type) {
    case CRM_SUBSCRIPTION_CREATE_REQUEST:
      return { loading: true };
    case CRM_SUBSCRIPTION_CREATE_SUCCESS:
      return { loading: false, crmSubscription: action.payload };
    case CRM_SUBSCRIPTION_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
