import {
  ESTIMATE_UPDATE_REQUEST,
  ESTIMATE_UPDATE_SUCCESS,
  ESTIMATE_UPDATE_FAIL,
  ESTIMATE_UPDATE_RESET
} from '../actions/types';

export default function estimateUpdateCustomerReducer(state = {}, action) {
  switch (action.type) {
    case ESTIMATE_UPDATE_REQUEST:
      return { loading: true };
    case ESTIMATE_UPDATE_SUCCESS:
      return { loading: false, success: true, estimateInfo: action.payload };
    case ESTIMATE_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case ESTIMATE_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
