import React, { useEffect, Fragment, useState, useCallback, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import Moment from 'react-moment';
import AdvanceContactsTable from 'components/common/contactsTable/AdvanceContactsTable';
import AdvanceContactsTableFooter from 'components/common/contactsTable/AdvanceContactsTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceContactsTableWrapper from 'components/common/contactsTable/AdvanceContactsTableWrapper';
import ContactEditModal from './ContactEditModal';
import GroupContactChoose from './GroupContactChoose';
import MoveCopy from './MoveCopy';
import Select from 'react-select';
import { listFields, groupById, listGroupContacts, updateContact, updateGroup, createBlockedContacts, deleteMultipleContacts, unsubscribeContacts } from 'actions/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import AppContext from 'context/Context';
import { ADD_COMPOSE_GROUP, ADD_COMPOSE_CONTACT, RESET_GROUP_DETAILS, SET_CONTACTS, FIELD_CREATE_RESET, RESET_SELECTED_CONTACT_ROWS, GROUP_MOVE_COPY_RESET, CONTACT_DELETE_RESET } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';

const GroupEdit = ({ tempGroupId, showEditGroupModal, setShowEditGroupModal }) => {

  const { id } = useParams(); // Use useParams hook to get the ID from the URL

  const { isDark } = useContext(AppContext)

  const navigate = useNavigate();

  const windowSize = useWindowDimensions()

  const GroupId = tempGroupId || id

  //--------------------------------------------------------------------------State-------------------------------------------------------------------------

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [chooseImportModal, setChooseImportModal] = useState(false);
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false);
  const [showGroupEditModal, setShowGroupEditModal] = useState(false);
  const [showMoveModal, setShowMoveModal] = useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
  const [changeText, setChangeText] = useState();
  const [contact, setContact] = useState({});
  const [blockContact, setBlockContact] = useState()
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [filterString, setFilterString] = useState('');
  const [selectedRows, setSelectedRows] = useState();
  const [tempContact, setTempContact] = useState();
  const [rowDelete, setRowDelete] = useState();
  const [updateGroupEdit, setUpdateGroupEdit] = useState();
  const [tempRowData, setTempRowData] = useState(null); // Add this state




  const dispatch = useDispatch();

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);
  const fieldCreate = useSelector((state) => state.fieldCreate);
  const { success: successFieldCreate } = fieldCreate;
  const conversations = useSelector((state) => state.conversations)



  const contactCreate = useSelector((state) => state.createContact)
  const { success: successCreate } = contactCreate

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDeleteContact } = contactDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const groupDetails = useSelector((state) => state.groupDetails)
  const { contacts: contacts, loading: loadingContacts, success: successGet } = groupDetails


  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successUpdate } = groupUpdate

  const groupMoveCopy = useSelector((state) => state.groupMoveCopy)
  const { success: successMoveCopy } = groupMoveCopy

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success: successContactUpdate } = contactUpdate

  const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: 150,  // set your desired minimum width
      backgroundColor: isDark ? 'dark' : 'light',
      borderColor: isDark ? 'dark' : 'light',
    }),
    input: (base) => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#2c7be5',
      fontWeight: "bold",
    }),
    option: (base, state) => ({
      ...base,
      color: '#2c7be5', // 
      fontWeight: "bold",
      pointerEvents: 'none', // This line disables the interactions
    }),
    singleValue: (base) => ({ // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: "bold",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      zIndex: 1000  // Update this value to be higher than the z-index of your table footer
    }),
    menuList: base => ({
      ...base,
    })

  };

  const openGroups = []
  groups.map((g) => { if (!tempContact?.group.filter((cg) => cg === g._id)[0]) { openGroups.push(g) } })

  const openContactUpdateModal = (rowData) => {
    setContact(rowData)
    setShowContactUpdateModal(!showContactUpdateModal);
  };

  useEffect(() => {
    if (tempRowData && fields.length) {
      const contactFields = fields?.map((field) => ({
        _id: field._id,
        title: field.title,
        value: tempRowData.fields.find(f => f._id === field._id)?.value || "",
      }));
      setContact({ ...tempRowData, fields: contactFields });
      setShowContactUpdateModal(true);
      setTempRowData(null);
    }
  }, [tempRowData, fields]);


  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData) => {
    const { firstName } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{firstName}</h5>
        </Media>
      </Media>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { lastName } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{lastName}</h5>
        </Media>
      </Media>
    );
  };

  const phoneFormatter = (rowData) => {
    const { phone_number } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{phone_number}</h5>
        </Media>
      </Media>
    );
  };

  const groupFormatter = (rowData) => {
    const { group } = rowData.row.original

    // Map the groups to the format react-select expects
    const groupOptions = group.map(({ _id, title }) => ({
      value: _id,
      label: title,
    }));

    const placeholder = groupOptions.length > 0 ? groupOptions[0].label : '';

    return (
      <Select
        isSearchable={false}
        options={groupOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  const customFieldFormatter = (rowData, header) => {
    const fieldValue = rowData.row.original.fields.find(field => field.title === header)?.value;
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{fieldValue || ''}</h5>
        </Media>
      </Media>
    );
  };


  const actionFormatter = (rowData) => {
    const { firstName, lastName, phone_number, _id } = rowData.row.original;
    const composeContact = { firstName, lastName, phoneNumber: phone_number, _id };

    const items = [
      {
        label: 'Send SMS',
        icon: 'sms',
        action: () => {
          dispatch({
            type: SET_CONTACTS,
            payload: [{ id: _id, original: { firstName, lastName, phone_number, _id } }],
          });
          navigate('/');
        },
      },
      {
        label: 'Edit',
        icon: 'edit',
        action: () => {
          openContactUpdateModal(rowData.row.original);
        },
      },
      {
        label: 'Block',
        icon: 'ban',
        action: () => {
          setShowBlockModal(true);
          setBlockContact({ _id, phoneNumber: phone_number });
        },
      },
      {
        label: 'Unsubscribe',
        icon: 'minus',
        action: () => {
          setShowUnsubscribeModal(true);
          setBlockContact(composeContact);
        },
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([_id]);
          setShowDeleteModal(true);
        },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };



  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const [activeColumns, setActiveColumns] = useState([

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter,
      index: 0
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter,
      index: 1
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter,
      index: 2
    },
    {
      accessor: (data) => {
        const groups = []
        data.group.map((g) => { groups.push(g.title) })
        return groups
      },
      Header: 'Group',
      Cell: groupFormatter,
      index: 3
    },
    {
      accessor: 'phone_type',
      Header: 'Type',
      index: 4
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter,
      index: 5
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right",

    }
  ]);




  const defaultColumns = [

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      Header: "Full Name",
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
      accessor: (data) => {
        const groups = []
        data.group.map((g) => { groups.push(g.title) })
        return groups
      },
      Header: 'Group',
      width: 200, // replace with your desired width
      minWidth: 100,
      Cell: groupFormatter
    },
    {
      accessor: 'phone_type',
      Header: 'Type'
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    }
  ]



  const [columns, setColumns] = useState(defaultColumns);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);


  // Use another useEffect to watch for changes in the fields state and perform the mapping
  useEffect(() => {
    if (fields.length) {
      // Map the fields to the column format
      const fieldColumns = fields.map((field, index) => ({
        accessor: field.title,
        Header: field.title,
        index: defaultColumns.length - 1 + index, // ensure index is before last
        Cell: (rowData) => customFieldFormatter(rowData, field.title), // Use the custom formatter
      }));

      // Split defaultColumns into all but last, and last
      const defaultColumnsWithoutLast = defaultColumns.slice(0, defaultColumns.length - 1);
      const lastColumn = defaultColumns[defaultColumns.length - 1];

      // Combine default columns without last, new fields, and last column
      const combinedColumns = [...defaultColumnsWithoutLast, ...fieldColumns, lastColumn];
      setColumns(combinedColumns);
      setFilteredColumns(combinedColumns);
    }
  }, [fields]); // Only re-run this effect if fields changes

  useEffect(() => {
    dispatch(listFields());
    dispatch({ type: FIELD_CREATE_RESET });
  }, [dispatch, successFieldCreate]);



  useEffect(() => {
    const filtered = columns.filter((column) =>
      column.Header.toLowerCase().startsWith(filterString.toLowerCase())
    );
    setFilteredColumns(filtered);
  }, [filterString, columns]);





  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------

  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }


  const closeBtn = (
    <button className="btn-close bg-light" onClick={() => { setChooseImportModal(!setChooseImportModal) }}>

    </button>
  );

  const closeMoveBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowMoveModal(!showMoveModal) }}>

    </button>
  );

  const closeGroupEditBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowGroupEditModal(!showGroupEditModal) }}>

    </button>
  );

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------


  const handleFilter = (column) => {
    const columnIsActive = activeColumns.find(col => col.Header === column.Header);

    if (columnIsActive) {
      // if the column is active, remove it
      setActiveColumns(activeColumns.filter(col => col.Header !== column.Header));
    } else {
      // else, add the column back to activeColumns, ensuring it's placed just before the last column ('actions')

      // find the original position of the column in activeColumns
      const originalIndex = activeColumns.findIndex(col => col.Header === column.Header);

      let newColumn;
      if (column.Header === "Group") {
        newColumn = {
          accessor: (data) => {
            const groups = [];
            data.group.forEach(g => groups.push(g.title));
            return groups;
          },
          Header: column.Header,
          Cell: groupFormatter,
        };
      } else if (column.Header === "Created") {
        newColumn = {
          accessor: 'created',
          Header: 'Created',
          Cell: createdFormatter,
        };
      } else if (fields?.some(field => field.title === column.Header)) {
        // Check if the column is a custom field
        newColumn = {
          accessor: column.accessor,
          Header: column.Header,
          Cell: (rowData) => customFieldFormatter(rowData, column.Header) // Use the custom formatter
        }
      } else {
        newColumn = {
          accessor: column.accessor,
          Header: column.Header
        };
      }

      let newActiveColumns = [
        ...activeColumns.slice(0, originalIndex),
        newColumn,
        ...activeColumns.slice(originalIndex, activeColumns.length - 1) // slice until just before the last column
      ];

      // if the column to be added back is not 'actions', then append 'actions' back
      if (newColumn.accessor !== 'actions') {
        newActiveColumns.push(activeColumns[activeColumns.length - 1]); // add the last column ('actions') back
      }

      setActiveColumns(newActiveColumns);
    }
  };




  useEffect(() => {
    dispatch(groupById(GroupId))
    if (successMoveCopy) {
      dispatch({ type: RESET_SELECTED_CONTACT_ROWS })
      dispatch({ type: GROUP_MOVE_COPY_RESET })
    }
  }, [successGet, successUpdate, successCreate, successContactUpdate, successBlockedCreate, successMoveCopy])

  useEffect(() => {
    if (successDeleteContact) {
      dispatch(groupById(GroupId))
      dispatch({ type: RESET_SELECTED_CONTACT_ROWS })
      dispatch({ type: CONTACT_DELETE_RESET })
    }

  }, [successDeleteContact])


  useEffect(() => {

    return () => {
      dispatch({ type: RESET_GROUP_DETAILS })
    }
  }, [])




  const handleSearch = (value) => {
    dispatch(
      listGroupContacts({ groupId: GroupId, search: value, page: 1, limit: 25 })
    );
  };



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listGroupContacts({ groupId: GroupId, search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])



  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };





  return (
    <Fragment>
      <h2 className="d-flex justify-content-start my-2 mx-2" >{groupDetails?.group?.title}</h2>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-3">
              {windowSize?.width <= 650 && (
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle transform="shrink-3 down-2" className="btn-tertiary border-1 border-dark mx-1" size="sm" >
                      <FontAwesomeIcon icon="chevron-down" className="fs--1" />{` Actions`}
                    </DropdownToggle>
                    <DropdownMenu className="mr-5">
                      <DropdownItem onClick={() => { setChooseImportModal(true) }}><FontAwesomeIcon icon="plus" className="fs--1 falcon-success" />{` Contact`}</DropdownItem>
                      <DropdownItem onClick={() => { if (!reduxSelectedRows?.length) { return dispatch({ type: ADD_COMPOSE_GROUP, payload: { groupCompose: selectedRows } }) } else { return setShowEditGroupModal(!showEditGroupModal), dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return el }) }) } }} tag={Link} to=""><FontAwesomeIcon icon="sms" className="fs--1" />{` Send SMS`}</DropdownItem>
                      <DropdownItem disabled={!reduxSelectedRows.length} onClick={() => { return setShowMoveModal(!showMoveModal) }}><FontAwesomeIcon icon="copy" className="fs--1" />{` Move/Copy`}</DropdownItem>
                      <DropdownItem onClick={() => { setShowGroupEditModal(!showGroupEditModal) }}><FontAwesomeIcon icon="edit" className="fs--1" />{` Edit`}</DropdownItem>
                      <DropdownItem disabled={!reduxSelectedRows.length} onClick={() => { setShowDeleteModal(!showDeleteModal) }} className="text-danger"><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}



              {windowSize?.width > 650 && (
                <div>
                  <ButtonIcon icon="plus" onClick={() => { setChooseImportModal(true) }} transform="shrink-3 down-1" className="btn-tertiary border-dark mx-1" size="sm" >
                    Contacts
                  </ButtonIcon>
                  <ButtonIcon icon="sms" tag={Link} to="/" onClick={() => {
                    if (!reduxSelectedRows?.length) {
                      return dispatch({ type: ADD_COMPOSE_GROUP, payload: { groupCompose: selectedRows } })
                    } else if (setShowEditGroupModal) {
                      return setShowEditGroupModal(!showEditGroupModal),
                        dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return el }) })
                    } else {
                      dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return el }) })
                    }
                  }}
                    transform="shrink-3 down-2"
                    className="btn-tertiary border-dark mx-1"
                    size="sm" >
                    SMS
                  </ButtonIcon>
                  <ButtonIcon icon="copy" onClick={() => { return setShowMoveModal(!showMoveModal) }} disabled={!reduxSelectedRows.length} transform="shrink-3" className="btn-tertiary border-dark mx-1" size="sm" >
                    Move/Copy
                  </ButtonIcon>
                  <ButtonIcon icon="edit" onClick={() => { setShowGroupEditModal(!showGroupEditModal) }} disabled={!reduxSelectedRows} transform="shrink-3 down-1" className="btn-tertiary border-dark mx-1" size="sm" >
                    Edit
                  </ButtonIcon>
                  <ButtonIcon onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!reduxSelectedRows.length} transform="shrink-3" className="btn-tertiary border-dark mx-1 text-danger" size="sm" >
                    Delete
                  </ButtonIcon>
                </div>
              )}

              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="ms-2 btn-tertiary border-dark text-warning mx-1" transform="shrink-3 down-2" size="sm">
                    <FontAwesomeIcon icon="filter" className="fs--1" />
                  </DropdownToggle>
                  <DropdownMenu className="p-2" end>
                    <div>
                      <Input className="mb-2" value={filterString} onChange={(e) => setFilterString(e.target.value)} />
                    </div>
                    {filteredColumns.map((column, index) => (
                      <div key={index}>
                        <FormGroup className='cursor-pointer' switch>
                          <Input
                            type="switch"
                            role="switch"
                            id={index}
                            style={{ transform: 'scale(1.2)', marginRight: "5px" }} // Adjust scale and margin as needed
                            onChange={() => { handleFilter(column) }}
                            checked={activeColumns?.some((col) => col.Header === column?.Header)}
                          />
                          <h5 className="fs-10" >{column?.Header}</h5>
                        </FormGroup>
                      </div>
                    ))
                    }
                    <Button block className="btn-tertiary border-dark text-primary" >Add Field</Button>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>

            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingContacts && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceContactsTableWrapper
            columns={activeColumns}
            data={contacts}
            fetchData={fetchData}
            total={groupDetails?.totalContacts}
            pageCount={1}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={() => { }}
          >

            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  placeholder="Search contacts..."
                  onSearch={handleSearch} table />
              </Col>
            </Row>

            <AdvanceContactsTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold overflow-hidden'
              }}
              isSelected={reduxSelectedRows}
              total={groupDetails?.totalContacts}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceContactsTableFooter
                rowCount={groupDetails.totalContacts}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceContactsTableWrapper>

        </CardBody>
      </Card>


      <Modal size="md" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
        <ModalHeader toggle={toggleSave} className=" border-bottom-0">
          <ButtonIcon
            onClick={() => { return console.log("contact", contact), dispatch(updateContact(contact, contact?._id)), setShowContactUpdateModal(!showContactUpdateModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <ContactEditModal
                contact={contact}
                changeText={changeText}
                setContact={setContact}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>




      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="d-flex justify-content-center" >Contacts will be permenantly deleted</Label>
              <Divider></Divider>
              <Button
                block
                onClick={() => {
                  if (rowDelete?.length) {
                    return dispatch(deleteMultipleContacts(rowDelete)),
                      setShowDeleteModal(!showDeleteModal)
                  } else {
                    return dispatch(deleteMultipleContacts(reduxSelectedRows?.map((el) => { return el.original._id }))),
                      setShowDeleteModal(!showDeleteModal)
                  }
                }}
                className="btn-tertiary border-dark text-danger">Delete</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Blocked Modal-----------------------------------------
      }
      <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="text-center">You will not recieve phone calls or messages from people on the block list.</Label>
              <Divider></Divider>
              <Button onClick={() => {
                return dispatch(createBlockedContacts({ contacts: [{ item: { _id: blockContact._id, phone_number: blockContact.phoneNumber } }] })), // data is in this format just to match for action, could just create a "singleBlockContact but didnt feel like it"
                  setShowBlockModal(!showBlockModal)
              }}
                block
                className="btn-tertiary border-dark text-danger">Block Contact</Button>
              <Button onClick={() => { setShowBlockModal(!showBlockModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Unsubscribe Modal---------------------------------------
      }
      <Modal isOpen={showUnsubscribeModal} centered toggle={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="text-center">Contact will be added to Unsubscribers group. If you Resubscribe contact they will go back to their original group</Label>
              <Divider />
              <Button onClick={() => {
                return dispatch(unsubscribeContacts(blockContact)),
                  setShowUnsubscribeModal(false)
              }}
                block
                className="btn-tertiary border-dark text-danger">Unsubscribe</Button>
              <Button onClick={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal isOpen={showGroupEditModal} centered toggle={() => setShowGroupEditModal(!showGroupEditModal)}>
        <ModalHeader close={closeGroupEditBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Update Group
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label for="title">Group Name</Label>
              <Input defaultValue={groupDetails?.group?.title} value={updateGroupEdit?.title?.value} onChange={(e) => { setUpdateGroupEdit({ ...updateGroupEdit, title: e.target.value }) }} className="mb-3" name="title" id="title" />
              <Button block color="primary" className="mb-3" onClick={() => { return dispatch(updateGroup({ title: updateGroupEdit?.title, _id: groupDetails?.group?._id })), setShowGroupEditModal(!showGroupEditModal) }}>Save</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Divider></Divider>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal) }} block className="text-danger" color="light">Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="text-primary" color="light" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal isOpen={showMoveModal} centered toggle={() => setShowMoveModal(!showMoveModal)}>
        <ModalHeader close={closeMoveBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Move Contacts
        </ModalHeader>
        <ModalBody className="p-1">
          <MoveCopy isSelected={reduxSelectedRows} groupDetails={groupDetails?.group?._id} showMoveModal={showMoveModal} setShowMoveModal={setShowMoveModal} />
        </ModalBody>
      </Modal>


      <Modal isOpen={chooseImportModal} centered toggle={() => setChooseImportModal(!setChooseImportModal)}>
        <ModalHeader close={closeBtn} className="text-center d-flex flex-between-center border-bottom-0">
          How would you like to Import?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <GroupContactChoose chooseImportModal={chooseImportModal} setChooseImportModal={setChooseImportModal} GroupId={GroupId} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </Fragment>
  );
};


export default GroupEdit;