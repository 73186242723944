import React, { useEffect, useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Message from './Message';
import { isIterableArray } from 'helpers/utils';
import { ChatContext } from 'context/Context';
import SimpleBarReact from 'simplebar-react';
import Moment from 'react-moment';

const ChatContentBody = ({ chat }) => {
  let lastDate = null;
  const messagesEndRef = useRef();
  //const scrollAreaRef = useRef();
  //console.log("messageEndRef", messagesEndRef.current)
  //console.log("scrollAreaRef", scrollAreaRef.current)
  const { chatMessages, activeChatId, scrollToBottom, setScrollToBottom } =
    useContext(ChatContext);

  const contents = chatMessages?.find(({ id }) => id === chat?._id);

  const numbers = useSelector(state => state.numbers);

  useEffect(() => {
    if (scrollToBottom) {
      setTimeout(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 500);
      setScrollToBottom(false);
    }
  }, [scrollToBottom]);

  const monthOptions = { month: 'long' };

  return (
    <div className="chat-content-body" style={{ display: 'inherit' }}>
      <SimpleBarReact style={{ height: '100%' }}>
        <div className="chat-content-scroll-area">
          {numbers.length ? (
            <div>

              {isIterableArray(contents?.content) &&
                contents?.content.map(
                  (
                    {
                      message,
                      chatId,
                      messageSid,
                      callSid,
                      numSegments,
                      startTime,
                      endTime,
                      mediaUri,
                      contentType,
                      time,
                      contactPhone,
                      status,
                      to,
                      from,
                      direction
                    },
                    index
                  ) => {
                    const newTime = new Date(time);
                    const date =
                      new Intl.DateTimeFormat('en-US', monthOptions).format(
                        newTime.getMonth()
                      ) +
                      ' ' +
                      newTime.getDate() +
                      ', ' +
                      newTime.getFullYear();

                    return (
                      <div key={index}>
                        {lastDate !== date && (
                          <div className="text-center fs--2 text-500">
                            {<Moment format="MM/DD/YYYY" date={time} />}
                          </div>
                        )}
                        {(() => {
                          lastDate = date;
                        })()}

                        <Message
                          message={message}
                          messageSid={messageSid}
                          numSegments={numSegments}
                          callSid={callSid}
                          startTime={startTime}
                          endTime={endTime}
                          chatId={chatId}
                          mediaUri={mediaUri}
                          contentType={contentType}
                          contactPhone={contactPhone}
                          time={time}
                          status={status}
                          to={to}
                          from={from}
                          direction={direction}
                        />
                      </div>
                    );
                  }
                )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div ref={messagesEndRef} />
      </SimpleBarReact>
    </div>
  );
};

ChatContentBody.propTypes = {
  isOpenThreadInfo: PropTypes.bool
};
ChatContentBody.defaultProps = {
  isOpenThreadInfo: false
};
export default ChatContentBody;
