import {
  CONTACT_UPDATE_REQUEST,
  CONTACT_UPDATE_SUCCESS,
  CONTACT_UPDATE_FAIL,
  CONTACT_UPDATE_RESET
} from '../actions/types';

export default function contactUpdateReducer(state = {}, action) {
  switch (action.type) {
    case CONTACT_UPDATE_REQUEST:
      return { loading: true };
    case CONTACT_UPDATE_SUCCESS:
      return { loading: false, success: true, contactInfo: action.payload };
    case CONTACT_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
