import {
  UPDATE_SELECTED_CONTACT_ROWS,
  REMOVE_SELECTED_CONTACT_ROWS,
  RESET_SELECTED_CONTACT_ROWS
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_SELECTED_CONTACT_ROWS:
      if (!state.length) {
        return action.payload.selectedFlatRows.map(el => {
          return {
            id: el.id,
            original: {
              _id: el.original._id,
              firstName: el.original.firstName,
              lastName: el.original.lastName,
              phone_number: el.original.phone_number
            }
          };
        });
      } else {
        const matched = Object.keys(action.payload.selectedRowIds).map(
          selRow => {
            const foundRow = state.find(el => el.id === selRow);
            if (foundRow) {
              return foundRow;
            } else {
              const newRow = action.payload.selectedFlatRows.find(
                el => el.id === selRow
              );
              if (newRow) {
                return {
                  id: newRow.id,
                  original: {
                    _id: newRow.original._id,
                    firstName: newRow.original.firstName,
                    lastName: newRow.original.lastName,
                    phone_number: newRow.original.phone_number
                  }
                };
              }
            }
          }
        );

        // Filter out any undefined values before returning
        return matched.filter(row => row !== undefined);
      }

    case REMOVE_SELECTED_CONTACT_ROWS:
      return state.filter(el => {
        return !action.payload.includes(el.id);
      });

    case RESET_SELECTED_CONTACT_ROWS:
      return [];

    default:
      return state;
  }
}
