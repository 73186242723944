import React, { Fragment, useEffect, useContext } from 'react';
import { Row, Col, Button, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Divider from 'components/common/Divider';
import Lottie from 'lottie-react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import animationData from 'components/authentication/wizard/lottie/celebration.json';
import { ResponderUploadContext } from 'context/Context';
import { createResponder, updateResponder } from 'actions/index';
import { ATTACH_FILE_CREATE_RESET } from 'actions/types';

const ImportSuccess = () => {
  const dispatch = useDispatch();

  const responderDetails = useSelector(state => state.responderDetails);

  const { setStep, upload, setUpload, setUserFile } = useContext(
    ResponderUploadContext
  );

  useEffect(() => {
    if (responderDetails[0]?.responder) {
      dispatch(updateResponder(upload, responderDetails[0]?.responder?._id));
    } else {
      dispatch(createResponder(upload));
      //dispatch({type: ATTACH_FILE_CREATE_RESET})
    }
  }, []);

  const defaultOptions = {
    loop: 1,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const emptyData = () => {
    setStep(1);
    setUserFile();
    setUpload({
      responseType: 'textMessage'
    });
  };

  return (
    <div>
      <Col>
        <Row>
          <Card className="w-100">
            <CardBody>
              <Fragment>
                <div className="wizard-lottie-wrapper">
                  <div className="wizard-lottie mx-auto">
                    <Lottie animationData={animationData} loop={1} />
                  </div>
                </div>
                <h4 className="mb-1 mt-3 text-center">
                  {responderDetails[0]?.responder
                    ? 'Your Responder has been Updated'
                    : 'Your responder is created!'}
                </h4>
                <p className="fs-0 text-center"></p>
                <Col className="text-center">
                  <Button
                    color="primary"
                    tag={Link}
                    to="/follow-up/responders"
                    className="my-3 text-white"
                  >
                    Go to Responders
                  </Button>
                </Col>
              </Fragment>
              <Col className="text-center">
                <Divider />
                <Button
                  color="falcon-primary"
                  className={classNames('px-5 my-3')}
                  onClick={emptyData}
                >
                  Start Over
                </Button>
              </Col>
            </CardBody>
          </Card>
        </Row>
      </Col>
    </div>
  );
};

export default ImportSuccess;
