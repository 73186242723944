import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import SingleOrListForm from './SingleOrListForm';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { listShowGroups, createGroup, deleteMultipleGroups, updateGroup } from 'actions/index';
import { SET_GROUPS, ADD_COMPOSE_GROUP, GROUP_DELETE_RESET, GROUP_UPDATE_RESET, BLOCKED_CONTACT_DELETE_RESET, GROUP_MOVE_COPY_RESET, GROUP_SHOW_HIDE_RESET, CONTACT_DELETE_RESET, BLOCKED_CONTACT_CREATE_RESET, CONTACT_CREATE_RESET, GROUP_CREATE_RESET } from 'actions/types';
import HiddenGroups from './HiddenGroups';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ActionPopover from 'components/common/ActionPopover';
import CustomerForm from 'components/customers/CustomerForm';

const GroupsTable = () => {

  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const windowSize = useWindowDimensions();

  const groupsNotHidden = useSelector(state => state.groups)
  const { groups: groups, loading: loadingGroups, totalGroups: totalGroups } = groupsNotHidden;



  const groupCreate = useSelector((state) => state.groupCreate)
  const { success: successCreate } = groupCreate

  const groupDelete = useSelector((state) => state.groupDelete)
  const { success: successDelete } = groupDelete

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successUpdate } = groupUpdate

  const groupShowHideCreate = useSelector((state) => state.groupShowHideCreate)
  const { success: successShowHide } = groupShowHideCreate

  const contactCreate = useSelector((state) => state.createContact)
  const { success: successContactCreate } = contactCreate

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successContactDelete } = contactDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success: successBlockedDelete } = blockedContactDelete

  const groupMoveCopy = useSelector((state) => state.groupMoveCopy)
  const { success: successMoveCopy } = groupMoveCopy

  const [isSelected, setIsSelected] = useState([]);
  const [selectedGroupRows, setSelectedGroupRows] = useState([])

  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setSelectedGroupRows(selectedFlatRows), setRowDelete([]) },
    [setIsSelected]);



  const [showGroupModal, setShowGroupModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false);
  const [showHiddenGroupsModal, setShowHiddenGroupsModal] = useState(false);
  const [showSingleOrListModal, setShowSingleOrListModal] = useState(false)
  const [showRenameModal, setShowRenameModal] = useState(false)
  const [tempGroup, setTempGroup] = useState()
  const [rowDelete, setRowDelete] = useState()

  const [group, setGroup] = useState({
    title: ''
  })

  const [openPopoverId, setOpenPopoverId] = useState(null);



  const closeRenameBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowRenameModal(!showRenameModal) }}>
    </button>
  );

  const closeNewGroupBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowGroupModal(!showGroupModal) }}>
    </button>
  );

  const closeBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowSingleOrListModal(!showSingleOrListModal) }}>
    </button>
  );

  const closeContactBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowContactModal(!showContactModal) }}>
    </button>
  );


  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const nameFormatter = (rowData, { avatar }) => {
    const { title, _id } = rowData.row.original
    return (
      <Link to={title === "unsubscribers" ? `/follow-up/unsubscribers/${_id}` : `/follow-up/group/${_id}`}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9">{title}</h5>
          </Media>
        </Media>
      </Link>
    );
  };



  const contactsFormatter = (rowData) => {
    const { _id, contactsCount } = rowData.row.original
    return (
      <Link to={`/follow-up/group/${_id}`}>
        <Media body className="ml-2 mt-1">
          {contactsCount < 1 ?
            <SubtleBadge bg="danger" pill className="fs-9">
              {contactsCount}
              <FontAwesomeIcon icon="user-plus" transform="shrink-1" className=" ms-1" />
            </SubtleBadge> :
            <SubtleBadge bg="primary" pill className="fs-9">
              {contactsCount}
              <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
            </SubtleBadge>
          }
        </Media>
      </Link>
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  const actionFormatter = (rowData) => {
    const { title, _id } = rowData.row.original;
    const items = [
      {
        label: 'Text this group',
        icon: 'sms',
        action: () => { return dispatch({ type: SET_GROUPS, payload: [rowData.row.original] }), navigate(`/`) },
      },
      {
        label: 'Group details',
        icon: 'eye',
        action: () => { navigate(`/follow-up/group/${_id}`) },
      },
      {
        label: 'Rename',
        icon: 'edit',
        action: () => {
          setShowRenameModal(!showRenameModal);
          setTempGroup(rowData.row.original);
        },
      },
      {
        label: 'Hide group',
        icon: 'minus',
        action: () => {
          setTempGroup(rowData.row.original);
          dispatch(updateGroup({ hide: true, _id }));
        },
      },
      {
        label: 'Delete group',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([_id]);
          setShowDeleteModal(!showDeleteModal);
        },
      },
    ];

    if (title === 'unsubscribers') {
      items.length = 0;
      items.push({
        label: 'See details',
        icon: 'eye',
        action: () => <Link to={`/follow-up/unsubscribers/${_id}`} />,
      });
    }

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
          isOpen={openPopoverId === _id}
          toggle={() => setOpenPopoverId(openPopoverId === _id ? null : _id)}
        />

      </div>
    );
  };

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [

    {
      accessor: 'title',
      Header: 'Group Name',
      Cell: nameFormatter
    },
    {
      accessor: 'contacts',
      Header: '# of Contacts',
      Cell: contactsFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right",

    }
  ];



  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------


  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(createGroup(group))
    setShowContactModal(false)
  };

  const handleChange = e => {
    setGroup({ ...group, [e.target.name]: e.target.value })
  }

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------



  const fetchData = useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      // Determine the sorting column and order
      const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'createdAt'; // Default to 'createdAt' if no sort
      const sortDirection = sortBy && sortBy.length > 0 && sortBy[0].desc ? -1 : 1; // -1 for descending, 1 for ascending

      dispatch(
        listShowGroups({
          search: '',
          page: pageIndex + 1,
          limit: pageSize,
          sortBy: sortColumn,
          sortOrder: sortDirection
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (successCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: GROUP_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: GROUP_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successDelete, fetchData, dispatch]);

  useEffect(() => {
    if (successContactCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: CONTACT_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successContactCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successBlockedCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: BLOCKED_CONTACT_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successBlockedCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successContactDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: CONTACT_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successContactDelete, fetchData, dispatch]);

  useEffect(() => {
    if (successBlockedDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: BLOCKED_CONTACT_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successBlockedDelete, fetchData, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: GROUP_UPDATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successUpdate, fetchData, dispatch]);

  useEffect(() => {
    if (successMoveCopy) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: GROUP_MOVE_COPY_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successMoveCopy, fetchData, dispatch]);

  useEffect(() => {
    if (successShowHide) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: GROUP_SHOW_HIDE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successShowHide, fetchData, dispatch]);




  const handleSearch = (value) => {
    dispatch(
      listShowGroups({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };







  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2 mx-2" >
              {windowSize?.width < 500 ? (
                <div>

                  <UncontrolledDropdown>
                    <DropdownToggle transform="shrink-3 down-2" className="btn-tertiary border-1 border-dark mx-1" size="sm" >
                      <FontAwesomeIcon icon="chevron-down" className="fs--1" />{` Actions`}
                    </DropdownToggle>
                    <DropdownMenu className="mr-5">
                      <DropdownItem onClick={() => { setShowContactModal(true) }} className={isSelected?.length ? "d-none" : ""} ><FontAwesomeIcon icon="plus" className="fs--1 falcon-success" />{` Contact`}</DropdownItem>
                      <DropdownItem onClick={() => { return dispatch({ type: ADD_COMPOSE_GROUP, payload: { groupCompose: selectedGroupRows?.map((el) => { return el.original }) } }) }} className={isSelected?.length ? "" : "d-none"} ><FontAwesomeIcon icon="paper-plane" className="fs--1" />{` Send SMS`}</DropdownItem>
                      <DropdownItem onClick={(() => setShowGroupModal(true))} ><FontAwesomeIcon icon="plus" className="fs--1" />{` Group`}</DropdownItem>
                      <DropdownItem onClick={() => { }} tag={Link} to="/follow-up/contacts-import" className={isSelected?.length ? "d-none" : ""} ><FontAwesomeIcon icon="upload" className="fs--1 falcon-success" />{` Import`}</DropdownItem>
                      <DropdownItem disabled={!isSelected.length} onClick={() => { setShowDeleteModal(!showDeleteModal) }} className={isSelected?.length ? "text-danger" : "d-none"} ><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>


                </div>

              ) : (
                <div>

                  <ButtonIcon className={isSelected?.length ? "d-none" : "btn-tertiary border-dark mx-1"} onClick={(() => setShowGroupModal(true))} icon="plus" transform="shrink-1" size="sm">
                    Group
                  </ButtonIcon>
                  <ButtonIcon className={isSelected?.length ? "d-none" : "btn-tertiary border-dark mx-1"} onClick={() => { setShowContactModal(true) }} icon="user-plus" transform="shrink-1" size="sm" >
                    Contact
                  </ButtonIcon>
                  <ButtonIcon className={isSelected?.length ? "d-none" : "btn-tertiary border-dark mx-1"} tag={Link} to="/follow-up/contacts-import" icon="upload" transform="shrink-1" size="sm">
                    Import
                  </ButtonIcon>

                  <ButtonIcon className={isSelected?.length ? "btn-tertiary border-dark mx-1" : "d-none"} icon="sms" iconClassName="text-primary" tag={Link} to="/" onClick={() => { return dispatch({ type: SET_GROUPS, payload: selectedGroupRows?.map((el) => { return el.original }) }) }} disabled={!isSelected.length} transform="shrink-3" size="sm" >
                    Send SMS
                  </ButtonIcon>
                  <ButtonIcon className={isSelected?.length ? "btn-tertiary text-danger border-dark mx-1" : "d-none"} onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!isSelected.length} transform="shrink-3" size="sm" >
                    Delete
                  </ButtonIcon>
                </div>
              )}
              <div>
                <ButtonIcon className="ms-2 btn-tertiary border-dark text-warning" onClick={() => { setShowHiddenGroupsModal(!showHiddenGroupsModal) }} icon="filter" transform="shrink-3" size="sm" >

                </ButtonIcon>
              </div>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingGroups && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={groups}
            fetchData={fetchData}
            total={totalGroups}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >

            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox onSearch={handleSearch} table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-9 fw-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3">
              <AdvanceTableFooter
                rowCount={totalGroups}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>


      {
        //////////////----------------- MODALS ALL BELOW------------------------------

        // show Contact Modal
      }
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader className=" d-flex flex-between-center border-bottom-0" close={closeContactBtn}>Create contact
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // show Group Modal
      }
      <Modal isOpen={showGroupModal} centered toggle={() => setShowGroupModal(!showGroupModal)}>
        <ModalHeader close={closeNewGroupBtn} className="text-center d-flex flex-between-center border-bottom-0">Create a new group</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal">
              <Form onSubmit={handleSubmit}>
                <Label for="title">Group Name:</Label>
                <Input value={group?.title?.value} onChange={handleChange} className="mb-3" name="title" id="title" />
                <ModalFooter>
                  <Button block disabled={group?.title?.length < 1 || group?.title === "unsubscribers"} onClick={() => { setShowGroupModal(false) }} className="btn-tertiary border-dark text-primary" type="submit">Save</Button>
                </ModalFooter>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // show Rename Modal
      }
      <Modal isOpen={showRenameModal} centered toggle={() => setShowRenameModal(!showRenameModal)}>
        <ModalHeader close={closeRenameBtn} className="text-center d-flex flex-between-center border-bottom-0">Rename group</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">

              <Label for="title">Group Name:</Label>
              <Input defaultValue={tempGroup?.title} value={group?.title?.value} onChange={handleChange} className="mb-3" name="title" id="title" />
              <Button block onClick={() => { return setShowRenameModal(false), dispatch(updateGroup({ title: group.title, _id: tempGroup._id })) }} className="btn-tertiary border-dark text-primary" >Save</Button>

            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // show Sing or list Modal
      }
      <Modal isOpen={showSingleOrListModal} centered toggle={() => setShowSingleOrListModal(!showSingleOrListModal)}>
        <ModalHeader close={closeBtn} className="text-center d-flex flex-between-center border-bottom-0">
          How would you like to Add?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <SingleOrListForm showSingleOrListModal={showSingleOrListModal} setShowSingleOrListModal={setShowSingleOrListModal} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // show Delete Modal
      }
      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(deleteMultipleGroups(rowDelete)), // need to add convos
                    setShowDeleteModal(!showDeleteModal)
                } else {
                  return dispatch(deleteMultipleGroups(isSelected)),
                    setShowDeleteModal(!showDeleteModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger">Delete groups</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Hidden groups modal
      }
      <Modal isOpen={showHiddenGroupsModal} centered toggle={() => setShowHiddenGroupsModal(!showHiddenGroupsModal)}>
        <ModalHeader toggle={() => setShowHiddenGroupsModal(!showHiddenGroupsModal)} className="d-flex justify-content-center" >Hidden groups</ModalHeader>
        <ModalBody className="p-0">
          <HiddenGroups />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};


export default GroupsTable;