// ModalManager.js
import React, { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Modal } from 'reactstrap';
import NewUserWizardLayout from 'components/dashboard/new-user-wizard/NewUserWizardLayout';
import { useSelector } from 'react-redux';

const ModalManager = ({ children }) => {
    const [showFirstLoginModal, setShowFirstLoginModal] = useState(false);
    const auth = useSelector((state) => state.auth?.user)
    const companyDetails = useSelector((state) => state.companyDetails)
    const trustProBillingDetails = useSelector((state) => state.trustProBillingDetails?.billingDetails[0])
    const location = useLocation();

    useEffect(() => {
        if (shouldShowModal()) {
            setShowFirstLoginModal(true);
        }
    }, [auth, companyDetails, trustProBillingDetails, location.pathname]);


    const shouldShowModal = () => {
        const pathsToShowModal = [
            '/estimates',
            '/estimate/:id',
            '/estimate-create',
            '/invoices',
            '/invoice/:id',
            '/invoice-create',
        ];
        const currentPath = location.pathname;

        // Use matchPath to check if currentPath matches any pattern
        const matchesPath = pathsToShowModal.some((pathPattern) =>
            matchPath({ path: pathPattern, end: true }, currentPath)
        );

        return (
            matchesPath &&
            auth?.isFirstLogin &&
            !auth?.teamId &&
            (!companyDetails[0]?.companyName || !companyDetails[0]?.phoneNumber)
        );
    };

    return (
        <>
            {children}
            {showFirstLoginModal && (
                <Modal
                    size='lg'
                    backdrop='static'
                    isOpen={showFirstLoginModal}
                    centered
                    toggle={() => setShowFirstLoginModal(false)}
                >
                    <NewUserWizardLayout
                        setShowFirstLoginModal={setShowFirstLoginModal}
                    />
                </Modal>
            )}
        </>
    );
};

export default ModalManager;
