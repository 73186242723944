import {
  UPDATE_RECEIPT_OTHER_REQUEST,
  UPDATE_RECEIPT_OTHER_SUCCESS,
  UPDATE_RECEIPT_OTHER_FAIL,
  UPDATE_RECEIPT_OTHER_RESET
} from '../actions/types';

export default function receiptOtherUpdateReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_RECEIPT_OTHER_REQUEST:
      return { loading: true };
    case UPDATE_RECEIPT_OTHER_SUCCESS:
      return { loading: false, success: true, receipt: action.payload };
    case UPDATE_RECEIPT_OTHER_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_RECEIPT_OTHER_RESET:
      return {};
    default:
      return state;
  }
}
