import React from 'react';
import ImporterForm from './ImporterForm';
import TollFreeWizardProvider from './TollFreeWizardProvider';

const TollFreeWizardLayout = () => {
  return (
    <TollFreeWizardProvider>
      <ImporterForm />
    </TollFreeWizardProvider>
  );
};

export default TollFreeWizardLayout;
