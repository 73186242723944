import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  CardTitle,
  FormText,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import CardSummary from 'components/common/CardSummary';
import CustomCardSummary from 'components/common/CustomCardSummary';
import Loader from 'components/common/Loader';
import { createBulkSendMessage } from 'actions/bulkSendActions';
import { listGroupContacts } from 'actions/index';
import { useAppContext } from 'providers/AppProvider';

const BulkTextSending = ({ recipients, fields }) => {
  const dispatch = useDispatch();
  const { config: { isDark } } = useAppContext();
  const createBulk = useSelector(state => state.createBulk);
  const [fiftyContacts, setFiftyContacts] = useState([]);

  const bulkSendCreate = useSelector(state => state.bulkSendCreate);
  const { success: successBulkSendCreate, info: infoBulkSendCreate } =
    bulkSendCreate;

  useEffect(() => {
    const numberId = JSON.parse(createBulk?.messageDetails?.bulkNumber)._id;
    dispatch(createBulkSendMessage({ ...createBulk, bulkNumber: numberId }));
  }, []);

  const [currentMessage, setCurrentMessage] = useState({ index: 0 });
  const [fieldWithDefaultValues, setFieldWithDefaultValues] = useState();
  const plainText = createBulk.messageContents.plainText;

  useEffect(() => {
    const OurFields = fields.map(el => {
      return { name: el.title, id: el._id };
    });
    setFieldWithDefaultValues([
      { name: 'First name', id: '700' },
      { name: 'Last name', id: '701' },
      { name: 'Company name', id: '702' },
      { name: 'Phone', id: '703' },
      { name: 'Email', id: '704' },
      ...OurFields
    ]);
  }, [fields]);

  useEffect(() => {
    const fetchContacts = async () => {
      let totalContacts = [];
      const groups = createBulk?.messageDetails?.groups;
      for (let group of groups) {
        if (totalContacts.length >= 50) {
          break;
        }
        if (group.contactsCount > 50) {
          // if group has 5,000 contacts just get first 50
          const contacts = await dispatch(listGroupContacts({
            groupId: group._id,
            page: 1,
            limit: 50
          }));
          totalContacts.push(...contacts.contacts);
        } else {
          const contacts = await dispatch(listGroupContacts({
            groupId: group._id,
            page: 1,
            limit: 50
          }));
          totalContacts.push(...contacts.contacts);
        }
      }

      if (totalContacts.length < 50) {
        const remaining = 50 - totalContacts.length;
        const contactObj = createBulk?.messageDetails?.contacts.map(
          contact => contact.original
        );
        const individualContacts = contactObj.slice(0, remaining);
        totalContacts.push(...individualContacts);
      }

      totalContacts = totalContacts.slice(0, 50);
      setFiftyContacts(totalContacts);
    };

    fetchContacts();
  }, []);
  //----------------------------------------------------------get mentions from state with default values--------------------------------------------------------------------------------------------
  const getMentionFromId = fieldWithDefaultValues?.filter(el =>
    createBulk?.messageContents?.mentions?.some(o2 => el.id === o2.mentionId)
  );
  //----------------------------------------------------------Reformat mentions to match firstName instead of First Name--------------------------------------------------------------------------------------------
  const mentionReformat = getMentionFromId?.map(el => {
    if (el.name === 'First name') {
      return { name: 'firstName', id: el.id };
    } else if (el.name === 'Last name') {
      return { name: 'lastName', id: el.id };
    } else if (el.name === 'Company name') {
      return { name: 'company', id: el.id };
    } else if (el.name === 'Phone') {
      return { name: 'phone_number', id: el.id };
    } else if (el.name === 'Email') {
      return { name: 'email', id: el.id };
    }
    return { name: el.name, id: el.id };
  });

  const mentionForCurlys = getMentionFromId?.map(el => {
    if (el.name === 'First name') {
      return { [el.name]: '{firstName}' };
    } else if (el.name === 'Last name') {
      return { [el.name]: '{lastName}' };
    } else if (el.name === 'Company name') {
      return { [el.name]: '{company}' };
    } else if (el.name === 'Phone') {
      return { [el.name]: '{phone_number}' };
    } else if (el.name === 'Email') {
      return { [el.name]: '{email}' };
    }
    return { [el.name]: `{${el.name}}` };
  });

  let mentionArr = [];
  if (mentionForCurlys) {
    mentionArr.push(Object.assign({}, ...mentionForCurlys));
  }

  //----------------------------------------------------------Create mentions with contacts value--------------------------------------------------------------------------------------------
  function valueByKey(obj, key) {
    let result = obj[key];

    if (result) {
      return result;
    }
    Object.keys(obj).forEach(function (k) {
      if (typeof obj[k] == 'object') {
        result = valueByKey(obj[k], key);
      }
    });
    return result;
  }

  const customContactValues = fiftyContacts?.map(contact => {
    const contactNoPsids = {
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      blocked: contact?.blocked,
      created: contact?.created,
      fields: contact?.fields,
      phone_number: contact?.phone_number,
      _id: contact?._id
    };

    let result = {};
    const thru = mentionReformat?.forEach(mention => {
      result[mention.name] = valueByKey(contactNoPsids, mention.name);
    });

    return result;
  });

  function renderPlainText(plainText, obj) {
    let result = plainText;

    for (let [key, value] of Object.entries(obj)) {
      if (value === undefined) {
        result = result.replace(new RegExp(`{${key}}`, 'g'), '');
      } else {
        result = result.replace(new RegExp(`{${key}}`, 'g'), value);
      }
    }

    return result;
  }

  let resultsObj;
  if (mentionArr) {
    resultsObj = mentionArr?.map(mentionValues =>
      renderPlainText(plainText, mentionValues)
    );
  }

  const formattedText = resultsObj[0]?.toString();

  let mergeStringWithContactValues;
  if (formattedText) {
    mergeStringWithContactValues = customContactValues?.map(contactValues =>
      renderPlainText(formattedText, contactValues)
    );
  }
  const finalMessages = mergeStringWithContactValues?.map(el => {
    return { message: el };
  });

  const nextMessage = () => {
    setCurrentMessage({
      index: (currentMessage.index + 1) % finalMessages?.length
    });
  };

  const previousMessage = () => {
    setCurrentMessage({
      index:
        currentMessage.index === 0
          ? (currentMessage.index = finalMessages?.length - 1)
          : (currentMessage.index - 1) % finalMessages?.length
    });
  };


  console.log("createBulk?.messageDetails?.bulkNumber", createBulk?.messageDetails?.bulkNumber)

  return (
    <div>
      <Card>
        <CardBody>
          {!successBulkSendCreate ? (
            <div>
              <Row>
                <Col className="col-4">
                  <Label>Sending Messages...</Label>
                </Col>
                <Col className="col-8">
                  <Loader />
                </Col>
              </Row>
            </div>
          ) : (
            <div classname="card-deck">
              <CustomCardSummary
                to="#!"
                rate="BulkText complete"
                color="info"
                className="me-5"
              >
                <SubtleBadge bg="success" pill className="fs--1">
                  Success
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-1"
                    className="ms-1"
                  />
                </SubtleBadge>
              </CustomCardSummary>
            </div>
          )}
          <Row>
            <Col className="col-4">
              <Label>Message Preview</Label>
            </Col>

            <Col className="col-md-12 col-lg-5">
              <div
                className={classNames(
                  'p-2 rounded compose-preview-message',
                  {
                    'bg-200': false,
                    'bg-primary text-white': true
                  }
                )}
              >
                {
                  //(message || message?.text) && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: `${finalMessages
                          ? finalMessages[`${parseInt(currentMessage?.index)}`]
                            ?.message
                          : ''
                        }`
                    }}
                  />
                }
              </div>
              <Row className="mt-1">
                <Button
                  onClick={() => {
                    return previousMessage();
                  }}
                  color="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-5 ms-5"
                  id="previous"
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
                <Button
                  onClick={() => {
                    return nextMessage();
                  }}
                  color="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1"
                  id="next"
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
                <UncontrolledTooltip placement="bottom" target="previous">
                  Previous
                </UncontrolledTooltip>
                <UncontrolledTooltip placement="bottom" target="next">
                  Next
                </UncontrolledTooltip>
              </Row>
            </Col>
          </Row>

          <Card className="mt-2">
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row className="mt-2">
                    <Col xs={{ size: 12, order: 0 }} sm={{ size: 4, order: 0 }}>
                      <Label>To:</Label>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
                      {createBulk?.messageDetails?.contacts?.length ? (
                        <SubtleBadge
                          bg="primary"
                          className="m-2 p-0"
                          style={{ display: 'inline-flex', width: 'auto' }}
                        >
                          <Card
                            className="m-1"
                            style={{ width: 'fit-content' }}
                          >
                            <CardBody
                              style={{
                                padding: '.25rem 1rem',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <FontAwesomeIcon icon="user" className="mx-2" />
                              <CardTitle tag="h6" className="m-0">
                                Contacts
                              </CardTitle>
                              <SubtleBadge
                                bg="secondary"
                                className="ms-2"
                              >
                                {createBulk?.messageDetails?.contacts?.length}
                              </SubtleBadge>

                            </CardBody>
                          </Card>
                        </SubtleBadge>
                      ) : (
                        ''
                      )}
                      {createBulk?.messageDetails?.groups.map(group => {
                        return (
                          <div key={group?._id}>
                            <SubtleBadge
                              bg="success"
                              className="m-2 p-0"
                              style={{ display: 'inline-flex', width: 'auto' }}
                            >
                              <Card
                                className="m-1"
                                style={{ width: 'fit-content' }}
                              >
                                <CardBody
                                  style={{
                                    padding: '.25rem 1rem',
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon="users"
                                    className="mx-2"
                                  />
                                  <CardTitle tag="h6" className="m-0">
                                    {group?.title}
                                  </CardTitle>
                                  <SubtleBadge
                                    bg="secondary"
                                    className="ms-2"
                                  >

                                    {group?.contactsCount}

                                  </SubtleBadge>
                                </CardBody>
                              </Card>
                            </SubtleBadge>
                          </div>
                        );
                      })}
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
          <Card className="mt-2">
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={{ size: 12, order: 0 }} sm={{ size: 4, order: 0 }}>
                      <FormText color="muted">From:</FormText>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
                      <div className="d-flex align-items-center">
                        <div className="d-flex flex-column">
                          <div className="d-flex align-items-center">
                            <FontAwesomeIcon icon="phone" className="me-2" />
                            <p className="mb-0">
                              <strong>
                                {
                                  JSON.parse(
                                    createBulk?.messageDetails?.bulkNumber
                                  ).phoneNumber
                                }
                              </strong>
                            </p>
                          </div>
                          {JSON.parse(createBulk?.messageDetails?.bulkNumber).nickName && (
                            <p className="mb-0 text-muted">
                              (
                              {
                                JSON.parse(createBulk?.messageDetails?.bulkNumber).nickName
                              }
                              )
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>

          <Card className="mt-2">
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={{ size: 12, order: 0 }} sm={{ size: 4, order: 0 }}>
                      <FormText color="muted">Message Length:</FormText>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon="comment-alt" className="me-2" />
                        <p className="mb-0">
                          {`${finalMessages
                              ? finalMessages[
                                `${parseInt(currentMessage?.index)}`
                              ]?.message?.length
                              : ''
                            } characters`}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
          <Card className="mt-2">
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={{ size: 12, order: 0 }} sm={{ size: 4, order: 0 }}>
                      <FormText color="muted">Segments:</FormText>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon="list" className="me-2" />
                        <p className="mb-0 text-center text-sm-left">
                          <strong>
                            {finalMessages
                              ? Math.ceil(
                                finalMessages[
                                  `${parseInt(currentMessage?.index)}`
                                ]?.message?.length / 153
                              )
                              : ''}
                          </strong>{' '}
                          {finalMessages &&
                            Math.ceil(
                              finalMessages[`${parseInt(currentMessage?.index)}`]
                                ?.message?.length / 153
                            ) < 2
                            ? 'message'
                            : 'messages'}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
          <Card className="mt-2">
            <CardBody>
              <ListGroup>
                <ListGroupItem>
                  <Row>
                    <Col xs={{ size: 12, order: 0 }} sm={{ size: 4, order: 0 }}>
                      <FormText
                        color="muted"
                        className="text-center text-sm-left"
                      >
                        Credit cost:
                      </FormText>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
                      <div className="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <p className="mb-0 text-center text-sm-left">
                          {`${finalMessages
                              ? Math.ceil(
                                finalMessages[
                                  `${parseInt(currentMessage?.index)}`
                                ]?.message?.length / 153
                              ) *
                              1 *
                              recipients
                              : ''
                            }`}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </CardBody>
          </Card>
          <hr />

          <div className="card-deck">
            <CardSummary
              title=""
              rate="Go to campaigns"
              to="/follow-up/campaign"
              color="warning"
              className="me-5"
            >
              <h4>Campaigns</h4>
            </CardSummary>
            <CardSummary
              title=""
              rate="Go to chat"
              tag={Link}
              to="/follow-up/chat"
              color="info"
            >
              <h4>Chat</h4>
            </CardSummary>
            <CardSummary
              title=""
              rate="Sent Recieved"
              tag={Link}
              to="/follow-up/history"
              color="success"
            >
              <h4>History</h4>
            </CardSummary>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default BulkTextSending;
