import React, {
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
  useRef
} from 'react';
import {
  Col,
  Row,
  Label,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Overlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  convertFromRaw,
  Modifier,
  RichUtils,
  convertToRaw,
  ContentState,
  EditorState
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/mention/lib/plugin.css'; //MentionCSS
import '@draft-js-plugins/emoji/lib/plugin.css'; // Emoji CSS
import '@draft-js-plugins/linkify/lib/plugin.css'; // Linkify CSS
import mentionsStyles from '../../compose/MentionsStyles.module.scss';
import { ChatContext } from '../../../context/Context';
import draftToHtml from 'draftjs-to-html';
import ChatPreviewMessage from './ChatPreviewMessage';
import TemplateTableV3 from '../../templates/TemplateTableV3';
import LinkOrMMS from './LinkOrMMS';
import FalconLightBox from '../../common/FalconLightBox';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { createChatMessages } from '../../../actions/chatActions';
import {
  RESET_COMPOSE_GROUP,
  ATTACH_MMS_RESET,
  ADD_CHAT_MESSAGE
} from '../../../actions/types';
import EmojiStyles from './EmojiStyles.module.scss';
import TemplatesIcon from 'components/icons/TemplatesIcon';
import AttachFileIcon from 'components/icons/AttachFileIcon';
import EyeIcon from 'components/icons/EyeIcon';
import EmojiIcon from 'components/icons/EmojiIcon';


const emojiTheme = {
  ...defaultTheme,
  emojiSelect: EmojiStyles.emojiSelect,
  emojiSelectButton: EmojiStyles.emojiSelectButton,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectPopover: EmojiStyles.emojiSelectPopover,
  emojiSelectPopoverGroupItem: EmojiStyles.emojiSelectPopoverGroupItem,

};


const MessageTextArea = ({ chat }) => {
  const { primaryNumber } = useContext(ChatContext);
  const messageTextRef = useRef(null);
  const dispatch = useDispatch();
  const [bulkMessage, setBulkMessage] = useState({
    messageContents: '',
    bulkNumber: ''
  });
  const [showFileModal, setShowFileModal] = useState(false);
  const [uploadMMS, setUploadMMS] = useState();
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState();
  const [messageLength, setMessageLength] = useState('');
  const [suggestions, setSuggestions] = useState([{ name: '{First name}' }]);
  const [fieldWithDefaultValues, setFieldWithDefaultValues] = useState();
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [fromChat, setFromChat] = useState(true);
  const [showNoChat, setShowNoChat] = useState(false);
  const [show, setShow] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const target = useRef(null);

  const handleToggle = () => setShow(!show);
  const handleClose = () => setShow(false);
  //------------------REDUX----------------------------------------------------------
  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
  const compose = useSelector(state => state.compose);
  const numberPrimary = useSelector(state => state.primaryNumber);
  const conversationCreate = useSelector(state => state.conversationCreate);
  const attachFileCreate = useSelector(state => state.attachFileCreate);
  const { success: successAttachFile } = attachFileCreate;

  const attachMMSCreate = useSelector(state => state.attachMMSCreate);
  const { success: successAttachMMS } = attachMMSCreate;

  const windowWidth = useWindowDimensions().width;

  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState);
  const FinalMessageText = markup.replace(
    /<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g,
    ' '
  );
  const contentState = editorState.getCurrentContent();
  const len = contentState.getPlainText(' ');
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  const contactNoPsids = {
    firstName: chat?.contact?.firstName,
    lastName: chat?.contact?.lastName,
    blocked: chat?.contact?.blocked,
    created: chat?.contact?.created,
    fields: chat?.contact?.fields,
    phone_number: chat?.contact?.phone_number,
    _id: chat?.contact?._id
  };

  const toggleTemplate = () => {
    return setShowTemplateModal(!showTemplateModal), setFromChat(false);
  };

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  const insertText = textToInsert => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    let newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      textToInsert
    );
    const textToInsertSelection = currentSelection.set(
      'focusOffset',
      currentSelection.getFocusOffset() + textToInsert?.length
    );
    let inlineStyles = editorState.getCurrentInlineStyle();
    inlineStyles.forEach(
      inLineStyle =>
      (newContent = Modifier.applyInlineStyle(
        newContent,
        textToInsertSelection,
        inLineStyle
      ))
    );
    let newState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );
    newState = EditorState.forceSelection(
      newState,
      textToInsertSelection.set(
        'anchorOffset',
        textToInsertSelection.getAnchorOffset() + textToInsert.length
      )
    );

    return newState;
  };
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    // Inserts link into Message draft-js as text
    if (successAttachFile) {
      setEditorState(insertText(attachFileCreate?.payload?.tempUrl));
    }
  }, [successAttachFile]);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    if (compose[0]?.composeTemplate) {
      let contentState;
      if (!compose[0]?.composeTemplate.body.entityMap) {
        contentState = {
          entityMap: {},
          blocks: compose[0]?.composeTemplate.body.blocks
        };
        setEditorState(
          EditorState.createWithContent(convertFromRaw(contentState))
        );
      } else {
        contentState = {
          entityMap: compose[0]?.composeTemplate?.body?.entityMap,
          blocks: compose[0]?.composeTemplate.body.blocks
        };
        setEditorState(
          EditorState.createWithContent(convertFromRaw(contentState))
        );
      }
    }
    dispatch({ type: RESET_COMPOSE_GROUP });
  }, [compose.length]);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const OurFieldsSug = fields.map(el => {
      return { name: `{${el.title}}`, id: el._id };
    }); // adding brackets to set suggestions state used for mentions suggestions in actaul editor
    const OurFields = fields.map(el => {
      return { name: el.title, id: el._id };
    }); // no brackets this is for changing suggestions to contact values in renderPlainText function
    setSuggestions([
      { name: '{First name}', id: '700' },
      { name: '{Last name}', id: '701' },
      { name: '{Company name}', id: '702' },
      { name: '{Phone}', id: '703' },
      { name: '{Email}', id: '704' },
      ...OurFieldsSug
    ]);
    setFieldWithDefaultValues([
      { name: 'First name', id: '700' },
      { name: 'Last name', id: '701' },
      { name: 'Company name', id: '702' },
      { name: 'Phone', id: '703' },
      { name: 'Email', id: '704' },
      ...OurFields
    ]);
  }, [fields]);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    // <-------- setting message length
    if (len) {
      setMessageLength(len.length);
    } else {
      setMessageLength(0);
    }
    const rawsContentState = convertToRaw(editorState.getCurrentContent());
    const obj = rawsContentState?.entityMap;
    const mentionIds = [];
    Object.keys(obj).forEach(key => {
      // the name of the current key.
      if (obj[key].data.mention) {
        mentionIds.push({ key: key, mentionId: obj[key].data.mention.id });
      }
    });
    setBulkMessage({
      ...bulkMessage,
      messageContents: {
        plainText: FinalMessageText,
        mentions: mentionIds,
        mentionLocations: rawsContentState?.blocks?.map(el => el.entityRanges)
      }
    });
  }, [editorState]);

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggleTemplate}></button>
  );

  const closeLinkOrMMSBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowFileModal(!showFileModal);
      }}
    ></button>
  );

  const closePreviewBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowPreviewModal(!showPreviewModal);
      }}
    ></button>
  );

  const closeNoChatBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowNoChat(!showNoChat);
      }}
    ></button>
  );
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  const removeSelectedBlocksStyle = editorState => {
    const newContentState = RichUtils.tryToRemoveBlockStyle(editorState);
    if (newContentState) {
      return EditorState.push(
        editorState,
        newContentState,
        'change-block-type'
      );
    }
    return editorState;
  };

  const getResetEditorState = editorState => {
    const blocks = editorState.getCurrentContent().getBlockMap().toList();
    const updatedSelection = editorState.getSelection().merge({
      anchorKey: blocks.first().get('key'),
      anchorOffset: 0,
      focusKey: blocks.last().get('key'),
      focusOffset: blocks.last().getLength()
    });
    const newContentState = Modifier.removeRange(
      editorState.getCurrentContent(),
      updatedSelection,
      'forward'
    );

    const newState = EditorState.push(
      editorState,
      newContentState,
      'remove-range'
    );
    return removeSelectedBlocksStyle(newState);
  };

  const handleMessageSubmit = e => {
    e.preventDefault();
    const plainText = bulkMessage.messageContents.plainText;

    //------------------------      -------get mentions from state with default values----------------------      -------------------------------------------------
    const getMentionFromId = fieldWithDefaultValues?.filter(el =>
      bulkMessage?.messageContents?.mentions?.some(o2 => el.id === o2.mentionId)
    );
    //------------------------      ---------------------------Reformat mentions to match firstName instead of First Name--------------     ---------------------------------------------------------------------

    const mentionReformat = getMentionFromId?.map(el => {
      if (el.name === 'First name') {
        return { name: 'firstName', id: el.id };
      } else if (el.name === 'Last name') {
        return { name: 'lastName', id: el.id };
      } else if (el.name === 'Company name') {
        return { name: 'company', id: el.id };
      } else if (el.name === 'Phone') {
        return { name: 'phone_number', id: el.id };
      } else if (el.name === 'Email') {
        return { name: 'email', id: el.id };
      }
      return { name: el.name, id: el.id };
    });

    //--------------------      -----Create a mentionForCurlys so we can find curlys in created message----------------------     ---------------------------------------------------
    const mentionForCurlys = getMentionFromId?.map(el => {
      if (el.name === 'First name') {
        return { [el.name]: '{firstName}' };
      } else if (el.name === 'Last name') {
        return { [el.name]: '{lastName}' };
      } else if (el.name === 'Company name') {
        return { [el.name]: '{company}' };
      } else if (el.name === 'Phone') {
        return { [el.name]: '{phone_number}' };
      } else if (el.name === 'Email') {
        return { [el.name]: '{email}' };
      }
      return { [el.name]: `{${el.name}}` };
    });
    //----------------      ---if MentionForCurlys is true , doing this just to make sure we dont get undefined if takes too long-------------      ------------------------------------------
    let mentionArr = [];
    if (mentionForCurlys) {
      mentionArr.push(Object.assign({}, ...mentionForCurlys));
    }
    //-----------------       -------------------------------Create mentions with contacts value------------------------------      -------------------------------------------------
    function valueByKey(obj, key) {
      let result = obj[key];
      if (result) {
        // if object has key
        return result; // return object
      }
      Object.keys(obj).forEach(function (k) {
        if (typeof obj[k] == 'object') {
          // if type of object equals object
          result = valueByKey(obj[k], key);
        }
      });
      return result;
    }

    //------------------      --------------------converting "firstName" to "ABRAM" etc.. {lastName: 'BASSE', email: undefined}-----------      -----------------------------------------------------------------------
    const customContactValues = () => {
      let result = {};
      const thru = mentionReformat?.forEach(mention => {
        result[mention.name] = valueByKey(contactNoPsids, mention.name);
      });
      return result;
    };

    //---------------------       --------------------------------------------------------------------------------------------------------------      --------
    function renderPlainText(plainText, obj) {
      let result = plainText;
      for (let [key, value] of Object.entries(obj)) {
        if (value === undefined) {
          result = result.replace(new RegExp(`{${key}}`, 'g'), '');
        } else {
          result = result.replace(new RegExp(`{${key}}`, 'g'), value);
        }
      }
      return result;
    }
    //-----------------------------------------------------------------------------------------------------------------------------------------------------------
    let resultsObj;
    if (mentionArr) {
      resultsObj = mentionArr?.map(mentionValues =>
        renderPlainText(plainText, mentionValues)
      );
    }
    const formattedText = resultsObj[0]?.toString();

    let mergeStringWithContactValues;

    if (formattedText) {
      mergeStringWithContactValues = renderPlainText(
        formattedText,
        customContactValues()
      );
    }

    const finalMessage = mergeStringWithContactValues;

    if (!conversationCreate?.loading && finalMessage.length > 3 && image) {
      let newMessage = {
        chatId: chat?._id,
        to: chat?.phone,
        from: primaryNumber[0]?.phoneNumber,
        mediaSid: attachMMSCreate?.payload,
        body: finalMessage,
        direction: 'outbound-api'
      };

      if (finalMessage) {
        // if true
        return (
          dispatch({ type: ADD_CHAT_MESSAGE, payload: { newMessage } }),
          dispatch(createChatMessages(newMessage)),
          removeSelectedBlocksStyle(editorState),
          setEditorState(getResetEditorState(editorState)),
          setImage(),
          dispatch({ type: ATTACH_MMS_RESET })
        );
      }
    } else if (!conversationCreate?.loading && image) {
      let newMessage = {
        chatId: chat?._id,
        to: chat?.phone,
        from: primaryNumber[0]?.phoneNumber,
        mediaSid: attachMMSCreate?.payload,
        direction: 'outbound-api'
      };

      if (finalMessage) {
        // if true
        return (
          dispatch({ type: ADD_CHAT_MESSAGE, payload: { newMessage } }),
          dispatch(createChatMessages(newMessage)),
          removeSelectedBlocksStyle(editorState),
          setEditorState(getResetEditorState(editorState)),
          setImage(),
          dispatch({ type: ATTACH_MMS_RESET })
        );
      }
    } else if (!conversationCreate?.loading && !image) {
      let newMessage = {
        chatId: chat?._id,
        from: primaryNumber[0]?.phoneNumber,
        to: chat?.phone,
        body: finalMessage,
        direction: 'outbound-api'
      };

      if (finalMessage) {
        // if true
        return (
          dispatch({ type: ADD_CHAT_MESSAGE, payload: { newMessage } }),
          dispatch(createChatMessages(newMessage)),
          removeSelectedBlocksStyle(editorState),
          setEditorState(getResetEditorState(editorState)),
          setImage(),
          dispatch({ type: ATTACH_MMS_RESET })
        );
      }
      document.querySelector(
        '.card-chat-pane'
      ).style.height = `calc(100% - 2rem)`;
    }
  };
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  const handlePreview = e => {
    const rawsContentState = convertToRaw(editorState.getCurrentContent());
    const obj = rawsContentState?.entityMap;
    const mentionIds = [];
    Object.keys(obj).forEach(key => {
      // the name of the current key.
      if (obj[key].data.mention) {
        mentionIds.push({ key: key, mentionId: obj[key].data.mention.id });
      }
    });
    setBulkMessage({
      ...bulkMessage,
      messageContents: {
        plainText: FinalMessageText,
        mentions: mentionIds,
        mentionLocations: rawsContentState?.blocks?.map(el => el.entityRanges)
      }
    });
    setShowPreviewModal(!showPreviewModal);
  };
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  function Entry(props) {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>

          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>

            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //defaultTheme.emojiSelectPopover = defaultTheme.emojiSelectPopover + "emojiSelectPopover"
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      //mentionPrefix: '@',
      supportWhitespace: true,
    });
    const emojiPlugin = createEmojiPlugin({
      selectButtonContent: <EmojiIcon width={30} height={30} />, // Set size as needed
      theme: emojiTheme,
      useNativeArt: true,
    });
    const linkifyPlugin = createLinkifyPlugin()

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin];
    return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
  }, []);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  const onOpenChange = useCallback(_open => {
    setOpen(_open);
  }, []);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  const onSearchChange = useCallback(({ value }) => { }, []);

  useEffect(() => {
    if (!attachMMSCreate?.payload) {
    } else {
      setImage(attachMMSCreate?.payload);
    }
  }, [successAttachMMS]);

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div>
      <Form className="chat-editor-area" onSubmit={handleMessageSubmit}>
        <div
          style={{ maxHeight: '10em', overflow: 'auto' }}
          className={
            'form-control outline-none resize-none rounded-0 border-0 emojiarea-editor'
          }
          onClick={() => {
            messageTextRef.current.focus();
          }}
        >
          {attachFileCreate?.loading && (
            <div>
              <div className="d-flex justify-content-start"></div>
              <Button color="transparent" size="sm" className="">
                Inserting Link...
              </Button>
            </div>
          )}
          {attachMMSCreate?.loading && (
            <div>
              <div className="d-flex justify-content-start"></div>
              <Button color="transparent" size="sm" className="">
                Attaching File...
              </Button>
            </div>
          )}
          {image && !attachMMSCreate?.loading ? (
            <div>
              <div className="d-flex justify-content-start">
                {image && (
                  <FalconLightBox imgSrc={image}>
                    <img
                      src={image}
                      className="img-fluid rounded ml-3 mb-3"
                      width={50}
                      height={50}
                      alt=""
                    />
                  </FalconLightBox>
                )}
                <Col className="mb-1">
                  <Button
                    color="transparent"
                    size="sm"
                    className=""
                    onClick={() => {
                      return dispatch({ type: ATTACH_MMS_RESET }), setImage('');
                    }}
                  >
                    Remove
                  </Button>
                </Col>
              </div>
            </div>
          ) : (
            ''
          )}
          <Editor
            editorKey={'editor'}
            currentContent={ContentState}
            editorState={editorState}
            onChange={setEditorState}
            plugins={plugins}
            ref={messageTextRef}
            placeholder={
              image && attachMMSCreate?.payload
                ? 'Add comment or Send'
                : 'Text Message'
            }
          />
          <EmojiSuggestions />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            onAddMention={e => {
              // get the mention object selected
            }}
            entryComponent={Entry}
          />
        </div>

        {windowWidth < 700 ? (
          <div className="d-flex justify-content-start" >
            {showEmojiPicker && (
              <div className="emoji-picker-container">
                <EmojiSelect
                  closeOnEmojiSelect
                  onEmojiSelect={() => setShowEmojiPicker(false)}
                  theme={emojiTheme}
                />
              </div>
            )}

            <div>
              <div
                ref={target}
                size="sm"
                className={'btn-tertiary text-success cursor-pointer py-1 cursor-pointer px-3 hover-shadow'}
                onClick={handleToggle}
              >
                <FontAwesomeIcon transform="grow-7" icon="plus" />
              </div>

              <Overlay
                show={show}
                target={target.current}
                placement="top-start"
                containerPadding={20}
                rootClose
                onHide={handleClose}
              >
                <Popover id="popover-chat">
                  <Popover.Body className="body-bg rounded p-3">
                    <Row className="text-center">
                      {/* Emoji Option */}
                      <Col xs={6} className="mb-3">
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() => { return setShow(!show), setShowEmojiPicker(!showEmojiPicker) }}
                        >
                          <div style={{ fontSize: '24px' }}>
                            <EmojiIcon width={35} height={35} />
                          </div>
                          <div className="mt-2" style={{ fontSize: '14px' }}>Emoji</div>
                        </div>
                      </Col>


                      {/* Attach File Option */}
                      <Col xs={6} className="mb-3">
                        <Button
                          color="link"
                          className="text-decoration-none"
                          onClick={() => {
                            setShow(false);
                            setShowFileModal(!showFileModal);
                          }}
                        >
                          <AttachFileIcon width={35} height={35} style={{ marginRight: '12px' }} />
                          <div className="mt-2" style={{ fontSize: '14px' }}>Attach File</div>

                        </Button>
                      </Col>

                      {/* Template Option */}
                      <Col xs={6}>
                        <Button
                          color="link"
                          className="text-decoration-none"
                          onClick={() => {
                            setShow(false);
                            setFromChat(true);
                            setShowTemplateModal(!showTemplateModal);
                          }}
                        >
                          <TemplatesIcon width={35} height={35} style={{ marginRight: '12px' }} />
                          <div className="mt-2" style={{ fontSize: '14px' }}>Template</div>
                        </Button>
                      </Col>

                      {/* Preview Option */}
                      <Col xs={6}>
                        <Button
                          color="link"
                          className="text-decoration-none"
                          onClick={() => {
                            if (chat) {
                              setShow(false);
                              handlePreview();
                            }
                          }}
                        >
                          <EyeIcon width={35} height={35} style={{ marginRight: '12px' }} />
                          <div className="mt-2" style={{ fontSize: '14px' }}>Preview</div>
                        </Button>
                      </Col>
                    </Row>
                  </Popover.Body>
                </Popover>
              </Overlay>
            </div>


            <div className="ms-auto ">
              <Button
                size="sm"
                className={image && chat || messageLength > 0 && chat ? 'btn-tertiary text-primary cursor-pointer' : 'btn-tertiary cursor-pointer'}
                disabled={!numberPrimary.length || !chat || !image && messageLength < 1}
                type="submit"
              ><FontAwesomeIcon transform="grow-7" icon="arrow-circle-up"></FontAwesomeIcon></Button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-start border" >
            <EmojiSelect closeOnEmojiSelect />
            <Label className="mb-0 p-1 cursor-pointer">
              <AttachFileIcon className="me-2" onClick={() => { return setShowFileModal(!showFileModal) }} />
            </Label>
            <Label className="mb-0 p-1 cursor-pointer">
              <TemplatesIcon className="me-2" onClick={() => { return setFromChat(true), setShowTemplateModal(!showTemplateModal) }} />
            </Label>
            <Label className="mb-0 p-1 cursor-pointer">
              <EyeIcon className="me-1" onClick={() => { if (chat) { return handlePreview() } else { return "" } }} />
            </Label>

            <div className="ms-auto ps-2">
              <Button
                size="sm"
                className={image && chat || messageLength > 0 && chat ? 'btn-tertiary text-primary cursor-pointer fs-9' : 'btn-tertiary cursor-pointer fs-9'}
                disabled={!numberPrimary.length || !chat || !image && messageLength < 1}
                type="submit"
              >Send</Button>
            </div>
          </div>
        )}
      </Form>
      <Modal
        isOpen={showFileModal}
        centered
        toggle={() => {
          setShowFileModal(!showFileModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            setShowFileModal(!showFileModal);
          }}
          close={closeLinkOrMMSBtn}
        >
          Link or MMS
        </ModalHeader>
        <ModalBody className="p-0">
          <LinkOrMMS
            showFileModal={showFileModal}
            setShowFileModal={setShowFileModal}
            uploadMMS={uploadMMS}
            setUploadMMS={setUploadMMS}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showPreviewModal}
        centered
        toggle={() => {
          return setShowPreviewModal(!showPreviewModal);
        }}
      >
        <ModalHeader close={closePreviewBtn}>Preview</ModalHeader>
        <ModalBody className="p-0">
          <ChatPreviewMessage
            bulkMessage={bulkMessage}
            chat={chat}
            numberPrimary={numberPrimary}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showNoChat}
        centered
        toggle={() => {
          return setShowNoChat(!showNoChat);
        }}
      >
        <ModalHeader close={closeNoChatBtn}>Please select a chat</ModalHeader>
        <ModalBody className="p-0">
          <Label>Select a chat</Label>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showTemplateModal}
        centered
        toggle={() => {
          return setFromChat(false), setShowTemplateModal(!showTemplateModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            return setFromChat(false), setShowTemplateModal(!showTemplateModal);
          }}
          close={closeBtn}
        >
          Templates
        </ModalHeader>
        <ModalBody className="p-0">
          <TemplateTableV3
            fromChat={fromChat}
            showTemplateModal={showTemplateModal}
            setShowTemplateModal={setShowTemplateModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

MessageTextArea.propTypes = {};

export default MessageTextArea;
