import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import paths from 'routes/paths';
import { useSelector } from 'react-redux';
import { useAppContext } from 'providers/AppProvider';

const ProfileDropdown = () => {
  // add src to avatar for photo
  const companyDetails = useSelector(state => state.companyDetails);
  const auth = useSelector(state => state.auth.user);

  const { setConfig } = useAppContext();

  const handleClick = () => {
    setConfig('showSettingPanel', true);
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        onClick={handleClick}
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar
          src={auth?.image}
          name={auth.firstName ? auth.firstName : auth.username.slice(0, 1)}
        />
      </Dropdown.Toggle>
    </Dropdown>
  );
};

export default ProfileDropdown;
