import {
  TOLL_FREE_VERIFICATION_DELETE_REQUEST,
  TOLL_FREE_VERIFICATION_DELETE_SUCCESS,
  TOLL_FREE_VERIFICATION_DELETE_FAIL
} from '../actions/types';

export default function tollFreeVerificationDeleteReducer(state = {}, action) {
  switch (action.type) {
    case TOLL_FREE_VERIFICATION_DELETE_REQUEST:
      return { loading: true };
    case TOLL_FREE_VERIFICATION_DELETE_SUCCESS:
      return { loading: false, success: true };
    case TOLL_FREE_VERIFICATION_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
