import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Row, Col, Label, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { listFields } from '../../../actions/index';

const ChatPreviewMessage = ({ bulkMessage, chat, numberPrimary }) => {
  const { contact } = chat;
  const contactNoPsids = {
    firstName: contact.firstName,
    lastName: contact.lastName,
    blocked: contact.blocked,
    created: contact.created,
    fields: contact.fields,
    phone_number: contact.phone_number,
    _id: contact._id
  };

  const dispatch = useDispatch();
  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
  const [fieldWithDefaultValues, setFieldWithDefaultValues] = useState();
  const plainText = bulkMessage.messageContents.plainText;

  useEffect(() => {
    dispatch(listFields());
  }, []);

  useEffect(() => {
    const OurFields = fields.map(el => {
      return { name: el.title, id: el._id };
    });
    setFieldWithDefaultValues([
      { name: 'First name', id: '700' },
      { name: 'Last name', id: '701' },
      { name: 'Company name', id: '702' },
      { name: 'Phone', id: '703' },
      { name: 'Email', id: '704' },
      ...OurFields
    ]);
  }, [fields]);

  //----------------------------------------------------------get mentions from state with default values--------------------------------------------------------------------------------------------
  const getMentionFromId = fieldWithDefaultValues?.filter(el =>
    bulkMessage?.messageContents?.mentions?.some(o2 => el.id === o2.mentionId)
  );
  //----------------------------------------------------------Reformat mentions to match firstName instead of First Name--------------------------------------------------------------------------------------------

  const mentionReformat = getMentionFromId?.map(el => {
    if (el.name === 'First name') {
      return { name: 'firstName', id: el.id };
    } else if (el.name === 'Last name') {
      return { name: 'lastName', id: el.id };
    } else if (el.name === 'Company name') {
      return { name: 'company', id: el.id };
    } else if (el.name === 'Phone') {
      return { name: 'phone_number', id: el.id };
    } else if (el.name === 'Email') {
      return { name: 'email', id: el.id };
    }
    return { name: el.name, id: el.id };
  });

  //-------------------------------Create a mentionForCurlys so we can find curlys in created message-----------------------------------------------------------------------------------
  const mentionForCurlys = getMentionFromId?.map(el => {
    if (el.name === 'First name') {
      return { [el.name]: '{firstName}' };
    } else if (el.name === 'Last name') {
      return { [el.name]: '{lastName}' };
    } else if (el.name === 'Company name') {
      return { [el.name]: '{company}' };
    } else if (el.name === 'Phone') {
      return { [el.name]: '{phone_number}' };
    } else if (el.name === 'Email') {
      return { [el.name]: '{email}' };
    }
    return { [el.name]: `{${el.name}}` };
  });

  //-------------------------if MentionForCurlys is true , doing this just to make sure we dont get undefined if takes too long--------------------------------------------------------------
  let mentionArr = [];
  if (mentionForCurlys) {
    mentionArr.push(Object.assign({}, ...mentionForCurlys));
  }

  //----------------------------------------------------------Create mentions with contacts value--------------------------------------------------------------------------------------------
  function valueByKey(obj, key, isField = false) {
    if (isField && Array.isArray(obj.fields)) {
      const field = obj.fields.find(f => f._id.toString() === key);
      if (field) return field.value;
    }
    return obj[key];
  }

  //-----------------------------------------------converting "firstName" to "ABRAM" etc.. {lastName: 'BASSE', email: undefined}------------------------------------------------------------------------------------------
  const customContactValues = () => {
    let result = {};
    mentionReformat?.forEach(mention => {
      if (
        mention.name === 'firstName' ||
        mention.name === 'lastName' ||
        mention.name === 'blocked' ||
        mention.name === 'created' ||
        mention.name === 'phone_number'
      ) {
        result[mention.name] = valueByKey(contactNoPsids, mention.name);
      } else {
        result[mention.name] = valueByKey(contactNoPsids, mention.id, true);
      }
    });

    return result;
  };

  function renderPlainText(plainText, obj) {
    let result = plainText;

    for (let [key, value] of Object.entries(obj)) {
      if (value === undefined) {
        result = result.replace(new RegExp(`{${key}}`, 'g'), '');
      } else {
        result = result.replace(new RegExp(`{${key}}`, 'g'), value);
      }
    }

    return result;
  }

  let resultsObj;
  if (mentionArr) {
    resultsObj = mentionArr?.map(mentionValues =>
      renderPlainText(plainText, mentionValues)
    );
  }

  const formattedText = resultsObj[0]?.toString();

  let mergeStringWithContactValues;
  if (formattedText) {
    mergeStringWithContactValues = renderPlainText(
      formattedText,
      customContactValues()
    );
  }

  const finalMessage = mergeStringWithContactValues;

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-4">
              <Label>Message Preview</Label>
            </Col>
            <Col className="col-8">
              <div
                className={classNames('p-2 rounded-2 chat-message', {
                  'bg-200': false,
                  'bg-primary text-white': true
                })}
              >
                {
                  //(message || message?.text) && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: `${finalMessage ? finalMessage : ''}`
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>To:</Label>
            </Col>
            <Col className="col-8">
              {chat?.contact ? (
                <Badge color="soft-primary" pill className="fs-9">
                  {chat?.contact?.firstName + ' ' + chat?.contact?.lastName}
                  <FontAwesomeIcon
                    icon="user"
                    transform="shrink-1"
                    className=" ms-1"
                  />
                </Badge>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>From:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs-9">
                {numberPrimary[0]?.phoneNumber}
              </Badge>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Message Length:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs-9">
                {`${finalMessage ? finalMessage?.length : ''} characters`}
              </Badge>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Segments:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs-9">
                {`Message will be sent in ${
                  finalMessage ? Math.ceil(finalMessage?.length / 153) : ''
                } ${
                  finalMessage && Math.ceil(finalMessage?.length / 153) < 2
                    ? 'part'
                    : 'parts'
                }`}
              </Badge>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Total cost:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs-9">
                {`Cost to send $${
                  finalMessage
                    ? (
                        Math.ceil(finalMessage?.length / 153) *
                        0.04 *
                        1
                      ).toFixed(2)
                    : ''
                }`}
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default ChatPreviewMessage;
