import { combineReducers } from 'redux';
import authReducer from './authReducer';
import templateReducer from './templateReducer';
import profileReducer from './profileReducer';
import contactsReducer from './contactsReducer';
import contactsTotalReducer from './contactsTotalReducer';
import availNumbersReducer from './availNumberReducer';
import contactDeleteReducer from './contactDeleteReducer';
import templateDeleteReducer from './templateDeleteReducer';
import contactCreateReducer from './contactCreateReducer';
import groupCreateReducer from './groupCreateReducer';
import groupDeleteReducer from './groupDeleteReducer';
import groupReducer from './groupReducer.js';
import fieldCreateReducer from './fieldCreateReducer';
import fieldReducer from './fieldReducer';
import fieldDeleteReducer from './fieldDeleteReducer';
import fieldUpdateReducer from './fieldUpdateReducer';
import fieldDetailsReducer from './fieldDetailsReducer';
import templateCreateReducer from './templateCreateReducer';
import csvUploadCreateReducer from './csvUploadCreateReducer';
import csvUploadDeleteReducer from './csvUploadDeleteReducer';
import csvUploadReducer from './csvUploadReducer';
import groupDetailsReducer from './groupDetailsReducer';
import contactDetailsReducer from './contactDetailsReducer';
import contactUpdateReducer from './contactUpdateReducer';
import numbersReducer from './numbersReducer';
import numberDetailsReducer from './numberDetailsReducer';
import numberCreateReducer from './numberCreateReducer';
import numberDeleteReducer from './numberDeleteReducer';
import numberUpdateReducer from './numberUpdateReducer';
import groupDetailsTitleReducer from './groupDetailsTitleReducer';
import primaryNumberReducer from './primaryNumberReducer';
import primaryNumberUpdateReducer from './primaryNumberUpdateReducer';
import creditCreateReducer from './creditCreateReducer';
import groupUpdateReducer from './groupUpdateReducer';
import blockedReducer from './blockedReducer';
import blockedContactCreateReducer from './blockedContactCreateReducer';
import blockedContactDeleteReducer from './blockedContactDeleteReducer';
import attachFileCreateReducer from './attachFileCreateReducer';
import templateUpdateReducer from './templateUpdateReducer';
import stateForDivReducer from './stateForDivReducer';
import calendarReducer from './calendarReducer';
import calendarCreateReducer from './calendarCreateReducer';
import calendarDeleteReducer from './calendarDeleteReducer';
import calendarUpdateReducer from './calendarUpdateReducer';
import keywordsReducer from './keywordsReducer';
import keywordCreateReducer from './keywordCreateReducer';
import keywordDeleteReducer from './keywordDeleteReducer';
import keywordDetailsReducer from './keywordDetailsReducer';
import composeReducer from './composeReducer';
import tabReduxReducer from './tabReduxReducer';
import attachMMSCreateReducer from './attachMMSCreateReducer';
import responderReducer from './responderReducer';
import responderDetailsReducer from './responderDetailsReducer';
import responderDeleteReducer from './responderDeleteReducer';
import responderUpdateReducer from './responderUpdateReducer';
import unSubsReducer from './unSubsReducer';
import sentMessagesReducer from './sentMessagesReducer';
import receivedMessagesReducer from './receivedMessagesReducer';
import groupMoveCopyReducer from './groupMoveCopyReducer';
import groupShowHideReducer from './groupShowHideReducer';
import groupShowHideCreateReducer from './groupShowHideCreateReducer'
import calendarPauseReducer from './calendarPauseReducer';
import eventsReducer from './eventsReducer';
import eventsPluginReducer from './eventsPluginReducer';
import subscribeCancelReducer from './subscribeCancelReducer';
import invoiceWarRoomReducer from './invoiceWarRoomReducer';
import bulkTextReducer from './bulkTextReducer';
import planUpdateStateReducer from './planUpdateReducer';
import cardSecretReducer from './cardSecretReducer';
import confirmIntentReducer from './confirmIntentReducer';
import paymentMethodDeleteReducer from './paymentMethodDeleteReducer';
import paymentMethodCreateReducer from './paymentMethodCreateReducer';
import tokenCreateReducer from './tokenCreateReducer';
import callsReducer from './callsReducer';
import previewMediaReducer from './previewMediaReducer';
import chatsReducer from './chatsReducer';
import chatCreateReducer from './chatCreateReducer';
import chatDeleteReducer from './chatDeleteReducer';
import chatUpdateReducer from './chatUpdateReducer';
import chatMessagesReducer from './chatMessagesReducer';
import chatMessagesDeleteReducer from './chatMessagesDeleteReducer';
import sentHistoryDeleteReducer from './sentHistoryDeleteReducer';
import activeChatReducer from './activeChatReducer';
import selectedRowsReducer from './selectedRowsReducer';
import selectedGroupRowsReducer from './selectedGroupRowsReducer';
import bulkSendCreateReducer from './bulkSendCreateReducer';
import bulkMessageReducer from './bulkMessageReducer';
import receivedHistoryDeleteReducer from './receivedHIstoryDeleteReducer';
import bulkTextDetailsReducer from './bulkTextDetailsReducer';
import bulkTextDeliveredReducer from './bulkTextDeliveredReducer';
import bulkTextSentReducer from './bulkTextSentReducer';
import bulkTextFailedReducer from './bulkTextFailedReducer';
import bulkSendDeleteReducer from './bulkSendDeleteReducer';
import callsDeleteReducer from './callsDeleteReducer';
import forgotPasswordCreateReducer from './forgotPasswordCreateReducer';
import responderMessagesReducer from './responderMessagesReducer';
import listResponderMessagesCreateReducer from './responderMessagesCreateReducer';
import calendarCompleteReducer from './calendarCompleteReducer';
import userUpdateReducer from './userUpdateReducer';
import keywordUpdateReducer from './keywordUpdateReducer';
import merchantDetailsReducer from './merchantDetailsReducer';
import customerProfileReducer from './customerProfileReducer';
import subscriptionDetailsReducer from './subscriptionDetailsReducer';
import accountDeleteReducer from './accountDeleteReducer';
import paginationReducer from './paginationReducer';
import contactsTableReducer from './contactsTableReducer';
import contactsTableRowReducer from './contactsTableRowReducer';
import createBulkReducer from './createBulkReducer';
import ServiceReducer from './ServiceReducer';
import ServiceCreateReducer from './ServiceCreateReducer';
import ServiceDeleteReducer from './ServiceDeleteReducer';
import ServiceUpdateReducer from './ServiceUpdateReducer';
import MaterialCreateReducer from './materialCreateReducer';
import MaterialDeleteReducer from './materialDeleteReducer';
import MaterialUpdateReducer from './materialUpdateReducer';
import itemReducer from './itemReducer';
import itemCreateReducer from './itemCreateReducer';
import itemDeleteReducer from './itemDeleteReducer';
import itemUpdateReducer from './itemUpdateReducer';
import itemsTotalReducer from './itemsTotalReducer';
import itemDetailsReducer from './itemDetailsReducer';
import pdfReducer from './pdfReducer';
import pdfUpdateReducer from './pdfUpdateReducer';
import companyDetailsReducer from './companyDetailsReducer';
import companyDetailsUpdateReducer from './companyDetailsUpdateReducer';
import attachLogoCreateReducer from './attachLogoCreateReducer';
import companyLogoReducer from './companyLogoReducer';
import paySettingsReducer from './paySettingsReducer';
import paySettingsUpdateReducer from './paySettingsUpdateReducer';
import estimateReducer from './estimateReducer';
import taxIdUpdateReducer from './taxIdUpdateReducer';
import taxRatesReducer from './taxRatesReducer';
import estimateCreateReducer from './estimateCreateReducer';
import estimatesTableReducer from './estimateTableReducer';
import estimateStatusUpdateReducer from './estimateStatusUpdateReducer';
import emailReducer from './emailReducer';
import tagsReducer from './tagsReducer.js';
import tagCreateReducer from './tagsCreateReducer.js';
import tagDeleteReducer from './tagsDeleteReducer.js';
import tagUpdateReducer from './tagsUpdateReducer.js';
import customerPayMethodReducer from './customerPayMethodReducer.js';
import customerPayMethodCreateReducer from './customerPayMethodCreateReducer.js';
import customerPayMethodDeleteReducer from './customerPayMethodDeleteReducer.js';
import propertyDetailsUpdateReducer from './propertyDetailsUpdateReducer.js';
import propertyDetailsReducer from './propertyDetailsReducer.js';
import customerEstimatesReducer from './customerEstimatesReducer.js';
import estimateDeleteReducer from './estimateDeleteReducer.js';
import estimateActionReducer from './estimateActionReducer.js';
import estimateUpdateCustomerReducer from './estimateUpdateCustomerReducer.js';

import invoiceReducer from './invoiceReducer.js';
import invoiceCreateReducer from './invoiceCreateReducer.js';
import invoiceDeleteReducer from './invoiceDeleteReducer.js';
import invoiceUpdateReducer from './invoiceUpdateReducer.js';
import invoiceUpdateCustomerReducer from './invoiceUpdateCustomerReducer.js';
import invoiceTableReducer from './invoiceTableReducer.js';
import invoiceActionReducer from './invoiceActionReducer.js';
import emailInvoiceReducer from './emailInvoiceReducer.js';
import customerInvoicesReducer from './customerInvoicesReducer.js';
import bankPayoutsReducer from './bankPayoutsReducer.js';
import transactionsReducer from './transactionsReducer.js';
import { teamDetailsReducer } from './teamDetailsReducer.js';
import { teamUpdateReducer } from './teamUpdateReducer.js';
import { teamCreateReducer } from './teamCreateReducer.js'
import cmrSubscriptionDetailsReducer from './crmSubscriptionDetailsReducer.js';
import cmrSubscriptionCreateReducer from './crmSubscriptionCreateReducer.js';
import teamMemberDeleteReducer from './teamMemberDeleteReducer.js';
import { teamFilterReducer } from './teamFilterReducer.js';
import contactsTotalCrmReducer from './contactsTotalCrmReducer.js';
import voicemailReducer from './voicemailReducer';
import voicemailDeleteReducer from './voicemailDeleteReducer';
import voicemailCreateReducer from './voicemailCreateReducer';
import brandReducer from './brandReducer';
import brandCreateReducer from './brandCreateReducer';
import brandDeleteReducer from './brandDeleteReducer';
import brandUpdateReducer from './brandUpdateReducer';
import DLCCampaignReducer from './DLCCampaignReducer';
import DLCCampaignCreateReducer from './DLCCampaignCreateReducer';
import DLCCampaignUpdateReducer from './DLCCampaignUpdateReducer';
import DLCCampaignDeleteReducer from './DLCCampaignDeleteReducer';
import tollFreeNumbersReducer from './tollFreeNumbersReducer';

import tollFreeVerificationReducer from './tollFreeVerificationReducer';
import tollFreeVerificationCreateReducer from './tollFreeVerificationCreateReducer';
import tollFreeVerificationUpdateReducer from './tollFreeVerificationUpdateReducer';
import tollFreeVerificationDeleteReducer from './tollFreeVerificationDeleteReducer';
import DLCNumbersReducer from './DLCNumbersReducer';
import materialsReducer from './materialsReducer';
import taxRatesUpdateReducer from './taxRatesUpdateReducer';
import taxRatesCreateReducer from './taxRatesCreateReducer';
import taxRatesDeleteReducer from './taxRatesDeleteReducer';
import receiptOtherCreateReducer from './receiptOtherCreateReducer';
import estimateReceiptsReducer from './estimateReceiptsReducer';
import receiptOtherDeleteReducer from './receiptOtherDeleteReducer';
import receiptOtherUpdateReducer from './receiptOtherUpdateReducer';
import collectDepositCreateReducer from './collectDepositCreateReducer';
import receiptUploadReducer from './receiptUploadReducer';
import { customerReceiptsReducer } from './customerReceiptsReducer';
import trustProBillingDetailsReducer from './trustProBillingDetailsReducer';
import customerScoreCreditsReducer from './customerScoreCreditsReducer';
import customerReviewsReducer from './customerReviewsReducer';
import customerReviewCreateReducer from './customerReviewCreateReducer';
import customerReviewDetailsReducer from './customerReviewDetailsReducer';
import updateCustomerReviewReducer from './customerReviewUpdateReducer';
import inviteMemberReducer from './inviteMemberReducer';
import contactsFollowUpReducer from './contactsFollowUpReducer';
import followUpBillingDetailsReducer from './followUpBillingDetailsReducer';
import customerProfileFollowUpReducer from './customerProfileFollowUpReducer';
import merchantDetailsFollowUpReducer from './merchantDetailsFollowUpReducer';
import followUpSubscribeCancelReducer from './followUpCancelSubscriptionReducer';
import uiReducer from './uiReducer';
import emailAuthFlowReducer from './emailAuthFlowReducer';
import passwordResetReducer from './passwordResetReducer';
import selectedSentRowsReducer from './selectedSentRowsReducer';
import selectedAddBlockedRows from './selectedAddBlockedRows';
import responderMessagesDeleteReducer from './responderMessagesDeleteReducer';


export default combineReducers({
   passwordReset: passwordResetReducer,
   inviteMember: inviteMemberReducer,
   contactsTable: contactsTableReducer,
   auth: authReducer,
   profile: profileReducer,
   compose: composeReducer,
   tabRedux: tabReduxReducer,
   creditCreate: creditCreateReducer,
   stateForDiv: stateForDivReducer,

   chats: chatsReducer,
   accountDelete: accountDeleteReducer,
   activeChatId: activeChatReducer,
   chatCreate: chatCreateReducer,
   chatDelete: chatDeleteReducer,
   chatUpdate: chatUpdateReducer,
   chatMessages: chatMessagesReducer,
   chatMessageDelete: chatMessagesDeleteReducer,

   calls: callsReducer,
   voicemails: voicemailReducer,
   voicemailDelete: voicemailDeleteReducer,
   voicemailCreate: voicemailCreateReducer,
   callsDelete: callsDeleteReducer,
   sentMessages: sentMessagesReducer,
   sentHistoryDelete: sentHistoryDeleteReducer,
   receivedMessages: receivedMessagesReducer,
   receivedHistoryDelete: receivedHistoryDeleteReducer,
   attachFileCreate: attachFileCreateReducer,
   attachMMSCreate: attachMMSCreateReducer,
   previewMedia: previewMediaReducer,



   blockedContacts: blockedReducer,
   blockedContactCreate: blockedContactCreateReducer,
   blockedContactDelete: blockedContactDeleteReducer,
   contacts: contactsReducer,
   contactsFollowUp: contactsFollowUpReducer,
   contactsTotal: contactsTotalReducer,
   createContact: contactCreateReducer,
   deleteContact: contactDeleteReducer,
   updateContact: contactUpdateReducer,
   contactDetails: contactDetailsReducer,


   groups: groupReducer,
   groupCreate: groupCreateReducer,
   groupDelete: groupDeleteReducer,
   groupDetails: groupDetailsReducer,
   groupTitle: groupDetailsTitleReducer,
   groupUpdate: groupUpdateReducer,
   groupMoveCopy: groupMoveCopyReducer,
   groupsShowHide: groupShowHideReducer,
   groupShowHideCreate: groupShowHideCreateReducer,
   unSubs: unSubsReducer,

   csvUpload: csvUploadReducer,
   csvUploadCreate: csvUploadCreateReducer,
   csvUploadDelete: csvUploadDeleteReducer,


   templates: templateReducer,
   createTemplate: templateCreateReducer,
   updateTemplate: templateUpdateReducer,
   deleteTemplate: templateDeleteReducer,

   keywords: keywordsReducer,
   keywordCreate: keywordCreateReducer,
   keywordUpdate: keywordUpdateReducer,
   keywordDelete: keywordDeleteReducer,
   keywordDetails: keywordDetailsReducer,

   responders: responderReducer,
   responderDetails: responderDetailsReducer,
   responderDelete: responderDeleteReducer,
   responderUpdate: responderUpdateReducer,

   responderMessagesDelete: responderMessagesDeleteReducer,

   fields: fieldReducer,
   fieldCreate: fieldCreateReducer,
   fieldDelete: fieldDeleteReducer,
   fieldUpdate: fieldUpdateReducer,
   fieldDetails: fieldDetailsReducer,


   numbers: numbersReducer,
   numberDetails: numberDetailsReducer,
   availNumbers: availNumbersReducer,
   createNumber: numberCreateReducer,
   deleteNumber: numberDeleteReducer,
   updateNumber: numberUpdateReducer,

   primaryNumber: primaryNumberReducer,
   primaryNumberUpdate: primaryNumberUpdateReducer,


   campaignEvents: eventsReducer,
   eventsPlugin: eventsPluginReducer,
   calendar: calendarReducer,
   pausedCalendar: calendarPauseReducer,
   completedCalendar: calendarCompleteReducer,
   calendarCreate: calendarCreateReducer,
   calendarDelete: calendarDeleteReducer,
   calendarUpdate: calendarUpdateReducer,

   customerProfileFollowUp: customerProfileFollowUpReducer,
   merchantDetailsFollowUp: merchantDetailsFollowUpReducer,

   tokenCreate: tokenCreateReducer,

   cardSecret: cardSecretReducer,
   confirmIntent: confirmIntentReducer,
   planUpdateState: planUpdateStateReducer,
   subscribeCancel: subscribeCancelReducer,
   paymentMethodDelete: paymentMethodDeleteReducer,
   paymentMethodCreate: paymentMethodCreateReducer,
   invoicesWarRoom: invoiceWarRoomReducer,
   bulkText: bulkTextReducer,

   selectedRows: selectedRowsReducer,
   selectedGroupRows: selectedGroupRowsReducer,
   selectedSentRows: selectedSentRowsReducer,

   bulkSendCreate: bulkSendCreateReducer,
   bulkSendDelete: bulkSendDeleteReducer,


   bulkMessageReducer: bulkMessageReducer,

   bulkTextDetails: bulkTextDetailsReducer,
   bulkTextDetailsDelivered: bulkTextDeliveredReducer,
   bulkTextDetailsSent: bulkTextSentReducer,
   bulkTextDetailsFailed: bulkTextFailedReducer,

   forgotPasswordCreate: forgotPasswordCreateReducer,

   responderMessages: responderMessagesReducer,
   responderMessagesFetch: listResponderMessagesCreateReducer,

   userUpdate: userUpdateReducer,

   merchantDetails: merchantDetailsReducer,
   customerProfile: customerProfileReducer,
   subscriptionDetails: subscriptionDetailsReducer,
   paginationRedux: paginationReducer,
   contactsTableRow: contactsTableRowReducer,


   createBulk: createBulkReducer,

   services: ServiceReducer,
   serviceCreate: ServiceCreateReducer,
   serviceDelete: ServiceDeleteReducer,
   serviceUpdate: ServiceUpdateReducer,

   materials: materialsReducer,
   materialCreate: MaterialCreateReducer,
   materialDelete: MaterialDeleteReducer,
   materialUpdate: MaterialUpdateReducer,


   items: itemReducer,
   itemDetails: itemDetailsReducer,
   itemsTotal: itemsTotalReducer,
   itemCreate: itemCreateReducer,
   itemDelete: itemDeleteReducer,
   itemUpdate: itemUpdateReducer,

   userPDF: pdfReducer,
   pdfUpdate: pdfUpdateReducer,

   userPaySettings: paySettingsReducer,
   paySettingsUpdate: paySettingsUpdateReducer,

   companyDetails: companyDetailsReducer,
   companyDetailsUpdate: companyDetailsUpdateReducer,

   companyLogo: companyLogoReducer,
   attachLogoCreate: attachLogoCreateReducer,
   taxIdUpdate: taxIdUpdateReducer,
   taxRates: taxRatesReducer,
   taxRateUpdate: taxRatesUpdateReducer,
   taxRateCreate: taxRatesCreateReducer,
   taxRateDelete: taxRatesDeleteReducer,

   estimateDetails: estimateReducer,
   estimateCreate: estimateCreateReducer,
   estimateStatusUpdate: estimateStatusUpdateReducer,
   estimateUpdateCustomer: estimateUpdateCustomerReducer,
   estimateDelete: estimateDeleteReducer,
   estimates: estimatesTableReducer,
   customerEstimates: customerEstimatesReducer,
   estimateAction: estimateActionReducer,
   email: emailReducer,
   emailAuthFlow: emailAuthFlowReducer,

   tags: tagsReducer,
   tagsCreate: tagCreateReducer,
   tagsDelete: tagDeleteReducer,
   tagsUpdate: tagUpdateReducer,

   customerPayMethods: customerPayMethodReducer,
   customerPayMethodCreate: customerPayMethodCreateReducer,
   customerPayMethodDelete: customerPayMethodDeleteReducer,
   bankPayouts: bankPayoutsReducer,
   transactions: transactionsReducer,

   propertyDetails: propertyDetailsReducer,
   propertyDetailsUpdate: propertyDetailsUpdateReducer,
   //------Invoices----------------------
   invoiceDetails: invoiceReducer,
   invoiceCreate: invoiceCreateReducer,
   invoiceUpdate: invoiceUpdateReducer,
   invoiceUpdateCustomer: invoiceUpdateCustomerReducer,
   invoiceDelete: invoiceDeleteReducer,
   invoices: invoiceTableReducer,
   customerInvoices: customerInvoicesReducer,
   invoiceAction: invoiceActionReducer,
   emailInvoice: emailInvoiceReducer,

   teamDetails: teamDetailsReducer,
   teamUpdate: teamUpdateReducer,
   teamCreate: teamCreateReducer,
   teamMemberDelete: teamMemberDeleteReducer,
   teamFilter: teamFilterReducer,

   crmSubscriptionDetails: cmrSubscriptionDetailsReducer,
   crmSubscriptionCreate: cmrSubscriptionCreateReducer,

   contactsTotalCrm: contactsTotalCrmReducer,

   brand: brandReducer,
   brandCreate: brandCreateReducer,
   brandDelete: brandDeleteReducer,
   brandUpdate: brandUpdateReducer,

   tollFreeNumbers: tollFreeNumbersReducer,
   tollFreeVerification: tollFreeVerificationReducer,
   tollFreeVerificationCreate: tollFreeVerificationCreateReducer,
   tollFreeVerificationUpdate: tollFreeVerificationUpdateReducer,
   tollFreeVerificationDelete: tollFreeVerificationDeleteReducer,


   DLCNumbers: DLCNumbersReducer,
   DLCCampaigns: DLCCampaignReducer,
   DLCCampaignCreate: DLCCampaignCreateReducer,
   DLCCampaignUpdate: DLCCampaignUpdateReducer,
   DLCCampaignDelete: DLCCampaignDeleteReducer,

   followUpBillingDetails: followUpBillingDetailsReducer,


   receiptOtherCreate: receiptOtherCreateReducer,
   receiptOtherDelete: receiptOtherDeleteReducer,
   receiptOtherUpdate: receiptOtherUpdateReducer,
   estimateReceipts: estimateReceiptsReducer,
   collectDepositCreate: collectDepositCreateReducer,

   customerReceipts: customerReceiptsReducer,
   receiptUpload: receiptUploadReducer,
   trustProBillingDetails: trustProBillingDetailsReducer,
   userScoreCredits: customerScoreCreditsReducer,

   customerReviews: customerReviewsReducer,
   customerReviewDetails: customerReviewDetailsReducer,
   customerReviewCreate: customerReviewCreateReducer,
   customerReviewUpdate: updateCustomerReviewReducer,

   followUpSubscriptionCancel: followUpSubscribeCancelReducer,

   ui: uiReducer,

   selectedAddBlockedRows: selectedAddBlockedRows


});