
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Nav, Navbar } from 'reactstrap';
import { navbarAccountBreakPoint } from 'config';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import { useSelector } from 'react-redux';

const NavbarVertical = ({ isNavbarVerticalCollapsed }) => {

  const auth = useSelector((state) => state.auth)


  const OwnerAdminRoutes = [
    {
      name: 'Settings',
      to: '/follow-up/account-settings'
    },
    {
      name: 'Billing',
      to: '/follow-up/billing'
    },
    {
      name: 'Plans',
      to: '/follow-up/monthly-plans'
    }]


  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  return (
    <Navbar
      expand={navbarAccountBreakPoint}
      light
    >
      <Nav navbar vertical>
        <NavbarVerticalMenu routes={OwnerAdminRoutes} />
      </Nav>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};

export default NavbarVertical;
