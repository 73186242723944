import React from 'react';
import PasswordResetForm from 'components/authentication/PasswordResetForm';

const PasswordReset = () => (
  <div className="text-center">
    <h5>Reset Password</h5>
    <PasswordResetForm />
  </div>
);

export default PasswordReset;


//<Section className="py-0">
//      <Row className="flex-center min-vh-100 py-6">
//        <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
//          <Logo />
//          <Outlet />
//        </Col>
//      </Row>
//    </Section>