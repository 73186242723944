import React, { useState, Fragment } from 'react';
import {
  Row,
  Card,
  CardBody,
  Label,
  Input,
  Form,
  FormGroup,
  Button
} from 'reactstrap';
import Section from '../common/Section';

const HelpModal = () => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    window.open(
      `mailto:support@trustpro.io?subject=${subject}&body=${message}`
    );
  };

  return (
    <Fragment>
      <Section className="text-center">
        <Row className=" d-flex justify-content-center mt-6">
          <Card>
            <CardBody>
              <div>
                <h1>Help and Support</h1>
                <p>
                  If you need assistance, please send us an email at
                  support@bulkText.com.
                </p>

                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                    <Label for="email">Your Email Address:</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="subject">Subject:</Label>
                    <Input
                      type="text"
                      name="subject"
                      id="subject"
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <FormGroup>
                    <Label for="message">Message:</Label>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      required
                    />
                  </FormGroup>

                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </Form>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Section>
    </Fragment>
  );
};

export default HelpModal;
