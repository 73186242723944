// reducers/itemsReducer.js

import {
  LIST_ITEMS_REQUEST,
  LIST_ITEMS_SUCCESS,
  LIST_ITEMS_FAIL,
  LIST_ITEMS_TOTAL,
  INSERT_UPDATED_ITEM,
  // ... other action types
} from '../actions/types';

const initialState = {
  items: [],
  total: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function itemsReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LIST_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.payload,
      };
    case LIST_ITEMS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LIST_ITEMS_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case INSERT_UPDATED_ITEM:
      const updatedState = state.items.map(item => {
        if (item?._id === action.payload?._id) {
          return action.payload;
        } else {
          return item;
        }
      });
      return { ...state, loading: false, items: updatedState };
    //  case DELETE_MULTIPLE_ITEMS_REQUEST:
    //    return {
    //      ...state,
    //      deleting: true,
    //      deleteError: null,
    //    };
    //  case DELETE_MULTIPLE_ITEMS_SUCCESS:
    //    return {
    //      ...state,
    //      deleting: false,
    //      items: state.items.filter(item => !action.payload.deletedIds.includes(item._id)),
    //      total: state.total - action.payload.deletedCount,
    //    };
    //  case DELETE_MULTIPLE_ITEMS_FAIL:
    //    return {
    //      ...state,
    //      deleting: false,
    //      deleteError: action.payload,
    //    };
    // ... other cases
    default:
      return state;
  }
};
