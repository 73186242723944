import { FETCH_USER, UPDATE_USER_CREDIT, UPDATE_USER_WITH_TEAM, SET_USER, CLEAR_USER, UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP } from '../actions/types';

const initialState = {
    user: null,
    isVerified: false,
    loading: true,
    credits: 0
};

export default function (state = initialState, action) {
    switch (action.type) {
        case FETCH_USER:
            return {
                ...state,
                user: action.payload || false,
                isVerified: !!action.payload,
                loading: false
            };

        case UPDATE_USER_CREDIT:
            return {
                ...state,
                credits: state.credits += action.payload
            };

        case SET_USER:
            return {
                ...state,
                user: action.payload,
                isVerified: true,
                loading: false
            };
        case UPDATE_USER_WITH_TEAM:
            return {
                ...state,
                user: action.payload,
            }
        case UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP:
            return {
                ...state,
                user: {
                    ...state.user,
                    customerProfileFollowUp: action.payload
                }
            };
        case CLEAR_USER:
            return initialState
        default:
            return state;
    }
}
