import React, { useState } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Keywords from './Keywords';
import RespondersTable from './RespondersTable';
import ResponderHistory from './ResponderHistory';

const InstaResponders = () => {
  const [activeTab, setActiveTab] = useState('1');

  const usersKeywords = useSelector(state => state.keywords);
  const { totalKeywords: totalKeywords } = usersKeywords;

  const autoResponders = useSelector(state => state.responders)
  const { totalItems: totalItems } = autoResponders;


  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav className="justify-content-start flex-column flex-md-row" tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');

            }}
          >
            <span className={`mx-1 fs-10`}>
              Responders{' '}
              <span className={`badge-subtle-primary fs--2 rounded px-1`}>
                {totalItems}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <span className={`mx-1 fs-10`}>
              Keywords{' '}
              <span className={`badge-subtle-primary fs--2 rounded px-1`}>
                {totalKeywords}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              toggle('3');
            }}
          >
            <span className={`mx-1 fs-10`}>History </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <RespondersTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <Keywords />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <ResponderHistory />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default InstaResponders;
