import React, { useContext, useState } from 'react';
import { Nav } from 'reactstrap';
import NewMessageIcon from './NewMessageIcon';
import ChatContact from './ChatContact';
import ChatThread from './ChatThread';
import ChatContactsSearch from './ChatContactsSearch';
import { ChatContext } from '../../../context/Context';
import { isIterableArray } from '../../../helpers/utils';
import SidebarOpenArchive from './SidebarOpenArchive';
import SimpleBarReact from 'simplebar-react';
import { useAppContext } from 'providers/AppProvider';

const ChatSidebar = () => {
  const { searchTerm, searchHandler, searchResults, chats, primaryNumber } =
    useContext(ChatContext);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [isConvoStateActive, setIsConvoStateActive] = useState('open');

  const {
    config: { theme }
  } = useAppContext();

  return (
    <div
      className={
        theme === 'light'
          ? ' bg-light chat-sidebar rounded-left'
          : ' bg-dark chat-sidebar rounded-left'
      }
    >
      <NewMessageIcon
        showMessageModal={showMessageModal}
        setShowMessageModal={setShowMessageModal}
      />
      <ChatContactsSearch
        searchTerm={searchTerm}
        searchHandler={searchHandler}
      />
      <div className="contacts-list">
        <SimpleBarReact style={{ height: '100%', minWidth: '65px' }}>
          <SidebarOpenArchive setIsConvoStateActive={setIsConvoStateActive} />
          <Nav className="border-0 mb-6">
            {searchTerm?.length
              ? isIterableArray(searchResults) &&
                searchResults?.map(contact => (
                  <ChatContact
                    showMessageModal={showMessageModal}
                    setShowMessageModal={setShowMessageModal}
                    contact={contact}
                    primaryNumber={primaryNumber}
                    key={contact._id}
                    //  chatCreate={chatCreate}
                  />
                ))
              : ''}

            {chats?.map((chat, index) =>
              isConvoStateActive === 'open' &&
              chat?.state === 'active' &&
              !chat?.contact?.blocked ? (
                <ChatThread chat={chat} key={index} />
              ) : isConvoStateActive === 'closed' &&
                chat?.state === 'inactive' &&
                !chat?.contact?.blocked ? (
                <ChatThread chat={chat} key={index} />
              ) : (
                <div key={index}></div>
              )
            )}

            {!chats.filter(chat => chat.state !== 'active')[0] &&
              isConvoStateActive === 'closed' && (
                <div>
                  <h5 className="my-3 mx-3">no chats in archive</h5>
                </div>
              )}
          </Nav>
        </SimpleBarReact>
      </div>
    </div>
  );
};

export default ChatSidebar;
