import React from 'react';
import { Col, Row } from 'reactstrap';
import ImporterForm from './ImporterForm';
import Section from 'components/common/Section';
import CsvWizardProvider from './CsvWizardProvider';

const CsvWizardLayout = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-4">
        <Col>
          <CsvWizardProvider>
            <ImporterForm />
          </CsvWizardProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default CsvWizardLayout;
