import React, { useRef } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChatContactsSearch = ({ searchTerm, searchHandler }) => {
  const inputEl = useRef('');

  const getSearchTerm = () => {
    searchHandler(inputEl.current.value);
  };

  return (
    <Form className="border-0 contacts-search-wrapper mt-2" onSubmit={() => {}}>
      <FormGroup className="mb-0 position-relative d-lg-block w-100 h-100">
        <Input
          innerRef={inputEl}
          className="chat-contacts-search h-100"
          value={searchTerm}
          onChange={getSearchTerm}
          placeholder="Search contacts ..."
          bsSize="sm"
        />
        <FontAwesomeIcon icon="search" className="contacts-search-icon" />
      </FormGroup>
    </Form>
  );
};

export default ChatContactsSearch;
