import {
  LIST_CUSTOMER_REVIEWS_REQUEST,
  LIST_CUSTOMER_REVIEWS_SUCCESS,
  LIST_CUSTOMER_REVIEWS_FAIL,
  LIST_CUSTOMER_REVIEWS_RESET
} from '../actions/types';

const initialState = {
  customerReviews: {},
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_CUSTOMER_REVIEWS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_CUSTOMER_REVIEWS_SUCCESS:
      return {
        ...state,
        customerReviews: action.payload,
        success: true,
        loading: false
      };
    case LIST_CUSTOMER_REVIEWS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case LIST_CUSTOMER_REVIEWS_RESET:
      return initialState;
    default:
      return state;
  }
}
