import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Label, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import FalconCardHeader from '../common/FalconCardHeader';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useAppContext } from 'providers/AppProvider';
import momentt from 'moment-timezone';
import { updateUser, fetchUser } from 'actions/index';
import { USER_UPDATE_RESET } from 'actions/types';
import { fetchTeamDetails } from 'actions/teamActions';

const SettingsProfile = () => {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth?.user);
  const animatedComponents = makeAnimated();

  const [profileValues, setProfileValues] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [timezones, setTimezones] = useState();

  const userUpdate = useSelector(state => state.userUpdate);
  const { loading: loadingUpdate, success: successUpdate } = userUpdate;

  const { isDark } = useAppContext();

  useEffect(() => {
    setProfileValues({ ...profileValues, timezone: auth.timezone });
  }, [auth]);

  const handleProfileSettings = e => {
    e.preventDefault();
    dispatch(updateUser(auth._id, profileValues));
  };

  useEffect(() => {
    if (!successUpdate) return;
    toast(
      <Fragment>
        <strong>Account updated</strong>
      </Fragment>
    );
    setIsDisabled(true);
    dispatch(fetchUser());
    dispatch(fetchTeamDetails());
    dispatch({ type: USER_UPDATE_RESET });
  }, [successUpdate]);

  useEffect(() => {
    const timeZones = momentt.tz.names();
    const offsetTmz = [];

    for (var i in timeZones) {
      offsetTmz.push({
        UTC:
          ' (GMT' + momentt.tz(timeZones[i]).format('Z') + ') ' + timeZones[i],
        zoneTitle: timeZones[i]
      });
    }

    setTimezones(offsetTmz);
  }, []);

  //-------------------------------------------------------------------------React-select-styles------------------------------------------------------------------------

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      // match with the menu
      borderColor: isDark ? 'dark' : 'light'
      // Removes weird border around container
    }),
    input: (base, state) => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),

    placeholder: (base, state) => ({
      ...base,

      color: '#2c7be5',
      fontWeight: 'bold'
    }),
    option: (base, state) => ({
      ...base,
      color: '#d8e2ef', //
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: state.isFocused ? '#2c7be5' : '#0b1727'
    }),
    singleValue: (base, state) => ({
      // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: 'bold'
    }),
    menu: (base, state) => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      background: isDark ? 'dark' : 'light',
      // kill the gap
      marginTop: 15
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      background: isDark ? 'dark' : 'light'
    })
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };


  return (
    <Card className="mb-3">
      {loadingUpdate && (
        <div style={overlayStyle}>
          <Spinner animation="border" role="status" style={spinnerStyle}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      )}
      <FalconCardHeader title="Profile Settings" light={false} />
      <Card.Body>
        <Form onSubmit={handleProfileSettings}>
          <Row>

            <Col lg={6}>
              <Label>Timezone</Label>
              <Select
                name="timezone"
                required={true}
                className="basic-single"
                classNamePrefix="select"
                styles={customStyles}
                components={animatedComponents}
                closeMenuOnSelect={true}
                options={timezones}
                defaultValue={{ UTC: auth.timezone }}
                getOptionLabel={({ UTC }) => UTC}
                getOptionValue={({ zoneTitle }) => zoneTitle}
                onChange={timezone => {
                  setProfileValues({
                    ...profileValues,
                    timezone: timezone.zoneTitle
                  });
                  setIsDisabled(false);
                }}
                placeholder="Select Timezone"
                isSearchable={true}
              />
            </Col>
            <Col xs={12} className="d-flex justify-content-start mt-3">
              <Button disabled={isDisabled} variant="primary" type="submit">
                Update
              </Button>
            </Col>
          </Row>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SettingsProfile;
