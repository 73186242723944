import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThreeForm from './StepThreeForm';
import StepFourForm from './StepFourForm';
import { DLCUploadContext } from 'context/Context';
import WizardModal from 'components/wizard/WizardModal';
import { listBrand } from 'actions';
const ImporterForm = () => {
  const dispatch = useDispatch();
  const { upload, setUpload, step, setStep } = useContext(DLCUploadContext); // this is basically creating these props to be
  const { register, handleSubmit, errors, watch } = useForm();
  const [checked, setChecked] = useState(false);

  const onSubmitData = data => {
    setUpload({ ...upload, ...data });
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  useEffect(() => {
    dispatch(listBrand());
  }, []);

  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card
        tag={Form}
        onSubmit={handleSubmit(onSubmitData)}
        className="theme-wizard"
      >
        <CardHeader>
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('fw-semi-bold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="hashtag" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Number</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semi-bold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="bullhorn" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Brand</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semi-bold', {
                  'done  cursor-pointer': step > 2
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="flag" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Campaign</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semi-bold', {
                  'done  cursor-pointer': step > 3
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="list-alt" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Summary</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 fw-normal px-md-6 pt-4 pb-3">
          {step === 1 && (
            <StepOneForm
              setStep={setStep}
              register={register}
              errors={errors}
              watch={watch}
              upload={upload}
              setUpload={setUpload}
            />
          )}
          {step === 2 && (
            <StepTwoForm
              step={step}
              setStep={setStep}
              register={register}
              errors={errors}
              watch={watch}
              upload={upload}
              checked={checked}
              setChecked={setChecked}
            />
          )}
          {step === 3 && (
            <StepThreeForm
              register={register}
              errors={errors}
              watch={watch}
              upload={upload}
              checked={checked}
              setChecked={setChecked}
            />
          )}
          {step === 4 && <StepFourForm checked={checked} upload={upload} />}
        </CardBody>
        <CardFooter className={classNames('px-md-6 d-flex')}></CardFooter>
      </Card>
    </Fragment>
  );
};

export default ImporterForm;
