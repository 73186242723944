import {
  LIST_ESTIMATE_RECEIPTS,
  LIST_ESTIMATE_RECEIPTS_FAIL,
  LIST_ESTIMATE_RECEIPTS_RESET
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_ESTIMATE_RECEIPTS:
      return action.payload;
    case LIST_ESTIMATE_RECEIPTS_FAIL:
      return { error: action.payload };
    case LIST_ESTIMATE_RECEIPTS_RESET:
      return [];
    default:
      return state;
  }
}
