import {
  CUSTOMER_PAY_METHOD_DELETE_REQUEST,
  CUSTOMER_PAY_METHOD_DELETE_SUCCESS,
  CUSTOMER_PAY_METHOD_DELETE_FAIL,
  CUSTOMER_PAY_METHOD_DELETE_RESET
} from '../actions/types';

export default function customerPayMethodDeleteReducer(state = {}, action) {
  switch (action.type) {
    case CUSTOMER_PAY_METHOD_DELETE_REQUEST:
      return { loading: true };
    case CUSTOMER_PAY_METHOD_DELETE_SUCCESS:
      return { loading: false, success: true };
    case CUSTOMER_PAY_METHOD_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_PAY_METHOD_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
