import { CONTACT_DELETE_REQUEST, CONTACT_DELETE_SUCCESS, CONTACT_DELETE_FAIL, CONTACT_DELETE_RESET } from '../actions/types';

export default function contactDeleteReducer(state = {}, action) {
    switch (action.type) {
        case CONTACT_DELETE_REQUEST:
            return { loading: true }
        case CONTACT_DELETE_SUCCESS:
            return { loading: false, success: true }
        case CONTACT_DELETE_FAIL:
            return { loading: false, error: action.payload }
        case CONTACT_DELETE_RESET:
            return {}
        default:
            return state
    }
}