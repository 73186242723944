import React, { useContext, useState, Fragment } from 'react';
import { Card, CardBody, CardHeader, CardFooter, Form, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import StepThreeForm from './StepThreeForm';
import ImportSuccess from './ImportSuccess';
import AppContext, { CsvUploadContext } from 'context/Context';
import WizardModal from 'components/wizard/WizardModal';
import ButtonIcon from 'components/common/ButtonIcon';

const ImporterForm = () => {

  const { isRTL } = useContext(AppContext);
  const { upload, setUpload, step, setStep, userFile, setUserFile } = useContext(CsvUploadContext); // this is basically creating these props to be
  const { register, errors, watch } = useForm();
  const [checked, setChecked] = useState(false)

  const nextStep = () => {
    if (step === 3 && !upload?.hasNumbers && upload?.selectHeader.filter(header => header.customHeader === "Phone")[0]) {
      setStep(2);
    } else {
      setUpload({ ...upload });
      setStep(step + 1);
    }
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  console.log("upload", upload)


  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card className="theme-wizard">
        <CardHeader >
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="users" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Upload contacts</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="cog" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Choose settings</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step > 2
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="equals" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Match fields</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step > 3
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="thumbs-up" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Import results</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 fw-normal px-md-6 pt-4 pb-3">
          {step === 1 && <StepOneForm register={register} errors={errors} watch={watch} userFile={userFile} setUserFile={setUserFile} />}
          {step === 2 && <StepTwoForm register={register} errors={errors} watch={watch} userFile={userFile} checked={checked} setChecked={setChecked} />}
          {step === 3 && <StepThreeForm register={register} errors={errors} watch={watch} userFile={userFile} checked={checked} setChecked={setChecked} />}
          {step === 4 && <ImportSuccess checked={checked} userFile={userFile} />}
        </CardBody>
        <CardFooter className={classNames('px-md-6 d-flex')}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-3 fw-semibold', { 'd-none': step === 1 || step === 4 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
          </ButtonIcon>

          <ButtonIcon
            disabled={step === 3 && !upload?.selectHeader.filter((header) => header.customHeader === "First name")[0] ||
              !userFile && upload?.uploadType === "files" ||
              step === 2 && !upload?.group?.length ||
              step === 2 && upload?.countryNumbers === 'diffCountries' ||
              upload?.hasNumbers === true && step === 3 && !upload?.selectHeader.filter((header) => header.customHeader === "Phone")[0]
            }
            color="primary"
            onClick={nextStep}
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            className={classNames("ms-auto px-3", { 'd-none': step === 4 })}
          >
          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default ImporterForm;