import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import RegistrationForm from 'components/authentication/RegistrationForm';
import paths from 'routes/paths';

const Registration = () => {
  const location = useLocation(); // Use useLocation to access passed state

  // Extract teamId and email from location.state
  const { teamId, teamName, email } = location.state || {}; // If location.state is undefined, default to an empty object

  return (
    <>
      {teamId ? (
        <div>
          <Row className="align-items-center mb-2">
            <Col xs={12}>
              <h5 id="modalLabel">Team Invite</h5>
            </Col>
            <Col xs={12} className="d-flex justify-content-end">
              <p className="fs-10 text-600 mb-0">
                Have an account? <Link to={paths.simpleLogin}>Login</Link>
              </p>
            </Col>
          </Row>
        </div>
      ) : (
        <div>
          <Col>
            <h5 id="modalLabel">Register</h5>
          </Col>
          <Col xs="auto">
            <p className="fs-10 text-600 mb-0">
              Have an account? <Link to={paths.simpleLogin}>Login</Link>
            </p>
          </Col>
        </div>
      )}
      <RegistrationForm />
    </>
  );
};

export default Registration;
