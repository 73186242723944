import {
  COMPANY_DETAILS_UPDATE_REQUEST,
  COMPANY_DETAILS_UPDATE_SUCCESS,
  COMPANY_DETAILS_UPDATE_FAIL,
  COMPANY_DETAILS_UPDATE_RESET
} from '../actions/types';

export default function companyDetailsUpdateReducer(state = {}, action) {
  switch (action.type) {
    case COMPANY_DETAILS_UPDATE_REQUEST:
      return { loading: true };
    case COMPANY_DETAILS_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        COMPANY_DETAILSInfo: action.payload
      };
    case COMPANY_DETAILS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case COMPANY_DETAILS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
