import { MERCHANT_DETAILS_FOLLOW_UP_REQUEST, MERCHANT_DETAILS_FOLLOW_UP_SUCCESS, MERCHANT_DETAILS_FOLLOW_UP_FAIL, MERCHANT_DETAILS_FOLLOW_UP_RESET } from '../actions/types';

export default function merchantDetailsFollowUpReducer(state = {}, action) {
    switch (action.type) {
        case MERCHANT_DETAILS_FOLLOW_UP_REQUEST:
            return { loading: true }
        case MERCHANT_DETAILS_FOLLOW_UP_SUCCESS:
            return { loading: false, data: action.payload }
        case MERCHANT_DETAILS_FOLLOW_UP_FAIL:
            return { loading: false, error: action.payload }
        case MERCHANT_DETAILS_FOLLOW_UP_RESET:
            return { loading: false, state: {} }
        default:
            return state
    }
}