import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Media,
  Modal,
  ModalBody,
  FormGroup,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Spinner
} from 'reactstrap';
import Divider from 'components/common/Divider';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceContactsTable from 'components/common/contactsTable/AdvanceContactsTable';
import AdvanceContactsTableFooter from 'components/common/contactsTable/AdvanceContactsTableFooter';
import AdvanceContactsTableSearchBox from 'components/common/contactsTable/AdvanceContactsTableSearchBox';
import AdvanceContactsTableWrapper from 'components/common/contactsTable/AdvanceContactsTableWrapper';
import ContactEditModal from './ContactEditModal';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { listContacts, listContactsTotal, deleteMultipleContacts, updateContact, createBlockedContacts, unsubscribeContacts, createField, listFields } from 'actions/index';
import { SET_CONTACTS, FIELD_CREATE_RESET, GROUP_UPDATE_RESET, REMOVE_SELECTED_CONTACT_ROWS, RESET_SELECTED_CONTACT_ROWS } from 'actions/types';
import AddContactModal from './AddContactModal';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useAppContext } from 'providers/AppProvider';
import CustomerForm from 'components/customers/CustomerForm';
import ActionPopover from 'components/common/ActionPopover';


const ContactsTableCustom = () => {

  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { config: { isDark } } = useAppContext();
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  const contactsFollowUp = useSelector((state) => state.contactsFollowUp)
  const { contacts: contacts, loading: loadingContacts, error: errorContacts } = contactsFollowUp
  const contactsTotal = useSelector((state) => state.contactsTotal)
  const { fields } = useSelector((state) => state.fields);
  const windowSize = useWindowDimensions()

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDelete } = contactDelete

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successGroupUpdate } = groupUpdate


  const [showContactModal, setShowContactModal] = useState(false)
  const [showContactUpdateModal, setShowContactUpdateModal] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
  const [showRemovingContactsModal, setShowRemovingContactsModal] = useState(false)
  const [showFieldModal, setShowFieldModal] = useState(false)
  const [changeText, setChangeText] = useState();
  const [contact, setContact] = useState({});
  const [field, setField] = useState({
    title: ''
  })

  const [rowDelete, setRowDelete] = useState()
  const [blockContact, setBlockContact] = useState()
  const [showChooseGroupModal, setShowChooseGroupModal] = useState(false)
  const [tempRowData, setTempRowData] = useState(null); // Add this state

  // Function to handle opening the modal with the correct contact
  const openContactUpdateModal = (rowData) => {
    setContact(rowData)
    setShowContactUpdateModal(!showContactUpdateModal);
  };

  useEffect(() => {
    if (tempRowData && fields.length) {
      const contactFields = fields.map((field) => ({
        _id: field._id,
        title: field.title,
        value: tempRowData.fields.find(f => f._id === field._id)?.value || "",
      }));
      setContact({ ...tempRowData, fields: contactFields });
      setShowContactUpdateModal(true);
      setTempRowData(null);
    }
  }, [tempRowData, fields]);



  const closeCreateBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowFieldModal(!showFieldModal) }}>
    </button>
  );


  const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: 150,  // set your desired minimum width
      backgroundColor: isDark ? 'dark' : 'light',
      borderColor: isDark ? 'dark' : 'light',
    }),
    input: (base) => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#2c7be5',
      fontWeight: "bold",
    }),
    option: (base, state) => ({
      ...base,
      color: '#2c7be5', // 
      fontWeight: "bold",
      pointerEvents: 'none', // This line disables the interactions
    }),
    singleValue: (base) => ({ // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: "bold",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      zIndex: 1000  // Update this value to be higher than the z-index of your table footer
    }),
    menuList: base => ({
      ...base,
    })

  };

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, { avatar }) => {
    const { firstName } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{firstName}</h5>
        </Media>
      </Media>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { lastName } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{lastName}</h5>
        </Media>
      </Media>
    );
  };

  const phoneFormatter = (rowData) => {
    const { phone_number } = rowData.row.original
    return (
      <Media onClick={() => { return openContactUpdateModal(rowData.row.original) }}
        tag={Flex} align="center">
        <Media body className="ms-2 cursor-pointer">
          <h5 className="mb-0 fs-9">{phone_number}</h5>
        </Media>
      </Media>
    );
  };

  const groupFormatter = (rowData) => {
    const { group } = rowData.row.original

    // Map the groups to the format react-select expects
    const groupOptions = group.map(({ _id, title }) => ({
      value: _id,
      label: title,
    }));

    const placeholder = groupOptions.length > 0 ? groupOptions[0].label : '';

    return (
      <Select
        isSearchable={false}
        options={groupOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  const customFieldFormatter = (rowData, header) => {
    const fieldValue = rowData.row.original.fields.find(field => field.title === header)?.value;
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{fieldValue || ''}</h5>
        </Media>
      </Media>
    );
  };

  // Inside ContactsTableCustom.js
  const actionFormatter = (rowData) => {
    const { firstName, lastName, phone_number, _id } = rowData.row.original;
    const composeContact = { firstName, lastName, phoneNumber: phone_number, _id };

    const items = [
      {
        label: 'Send SMS',
        icon: 'sms',
        action: () => {
          dispatch({
            type: SET_CONTACTS,
            payload: [{ id: _id, original: { firstName, lastName, phone_number, _id } }],
          });
          navigate('/');
        },
      },
      {
        label: 'Edit',
        icon: 'edit',
        action: () => {
          openContactUpdateModal(rowData.row.original);
        },
      },
      {
        label: 'Block',
        icon: 'ban',
        action: () => {
          setShowBlockModal(true);
          setBlockContact({ _id, phoneNumber: phone_number });
        },
      },
      {
        label: 'Unsubscribe',
        icon: 'minus',
        action: () => {
          setShowUnsubscribeModal(true);
          setBlockContact(composeContact);
        },
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([_id]);
          setShowDeleteModal(true);
        },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const [activeColumns, setActiveColumns] = useState([

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter,
      index: 0
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter,
      index: 1
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter,
      index: 2
    },
    {
      accessor: (data) => {
        const groups = []
        data?.group?.map((g) => { groups.push(g?.title) })
        return groups
      },
      Header: 'Group',
      Cell: groupFormatter,
      index: 3
    },
    {
      accessor: 'phone_type',
      Header: 'Type',
      index: 4
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter,
      index: 5
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right",
    }
  ]);




  const defaultColumns = [

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      Header: "Full Name",
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
      accessor: (data) => {
        const groups = []
        data.group.map((g) => { groups.push(g.title) })
        return groups
      },
      Header: 'Group',
      width: 200, // replace with your desired width
      minWidth: 100,
      Cell: groupFormatter
    },
    {
      accessor: 'phone_type',
      Header: 'Type'
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    }
  ]


  const [filterString, setFilterString] = useState('');
  const [columns, setColumns] = useState(defaultColumns);
  const [filteredColumns, setFilteredColumns] = useState(defaultColumns);

  const fieldCreate = useSelector((state) => state.fieldCreate);
  const { success: successFieldCreate } = fieldCreate;


  // Use another useEffect to watch for changes in the fields state and perform the mapping
  useEffect(() => {
    if (fields.length) {
      // Map the fields to the column format
      const fieldColumns = fields.map((field, index) => ({
        accessor: field.title,
        Header: field.title,
        index: defaultColumns.length - 1 + index, // ensure index is before last
        Cell: (rowData) => customFieldFormatter(rowData, field.title), // Use the custom formatter
      }));

      // Split defaultColumns into all but last, and last
      const defaultColumnsWithoutLast = defaultColumns.slice(0, defaultColumns.length - 1);
      const lastColumn = defaultColumns[defaultColumns.length - 1];

      // Combine default columns without last, new fields, and last column
      const combinedColumns = [...defaultColumnsWithoutLast, ...fieldColumns, lastColumn];
      setColumns(combinedColumns);
      setFilteredColumns(combinedColumns);
    }
  }, [fields]); // Only re-run this effect if fields changes

  useEffect(() => {
    if (successFieldCreate) {
      dispatch(listFields());
      dispatch({ type: FIELD_CREATE_RESET });

    }
  }, [dispatch, successFieldCreate]);



  useEffect(() => {
    const filtered = columns.filter((column) =>
      column.Header.toLowerCase().startsWith(filterString.toLowerCase())
    );
    setFilteredColumns(filtered);
  }, [filterString, columns]);





  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------


  const toggle = () => { setShowContactModal(!showContactModal) }

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}>
    </button>
  );



  useEffect(() => {
    dispatch(listContactsTotal())
  }, [])


  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }

  useEffect(() => {
    setShowRemovingContactsModal(contactDelete?.loading)
  }, [contactDelete])



  useEffect(() => {
    if (successGroupUpdate) {
      toast(
        <Fragment>
          <strong>Group Updated</strong>
        </Fragment>,
        {
          autoCLose: 1000
        }
      );
      dispatch({ type: GROUP_UPDATE_RESET })
    }
  }, [successGroupUpdate]);




  const handleFilter = (column) => {
    const columnIsActive = activeColumns.find(col => col.Header === column.Header);

    if (columnIsActive) {
      // if the column is active, remove it
      setActiveColumns(activeColumns.filter(col => col.Header !== column.Header));
    } else {
      // else, add the column back to activeColumns, ensuring it's placed just before the last column ('actions')

      // find the original position of the column in activeColumns
      const originalIndex = activeColumns.findIndex(col => col.Header === column.Header);

      let newColumn;
      if (column.Header === "Group") {
        newColumn = {
          accessor: (data) => {
            const groups = [];
            data.group.forEach(g => groups.push(g.title));
            return groups;
          },
          Header: column.Header,
          Cell: groupFormatter,
        };
      } else if (column.Header === "Created") {
        newColumn = {
          accessor: 'created',
          Header: 'Created',
          Cell: createdFormatter,
        };
      } else if (fields?.some(field => field.title === column.Header)) {
        // Check if the column is a custom field
        newColumn = {
          accessor: column.accessor,
          Header: column.Header,
          Cell: (rowData) => customFieldFormatter(rowData, column.Header) // Use the custom formatter
        }
      } else {
        newColumn = {
          accessor: column.accessor,
          Header: column.Header
        };
      }

      let newActiveColumns = [
        ...activeColumns.slice(0, originalIndex),
        newColumn,
        ...activeColumns.slice(originalIndex, activeColumns.length - 1) // slice until just before the last column
      ];

      // if the column to be added back is not 'actions', then append 'actions' back
      if (newColumn.accessor !== 'actions') {
        newActiveColumns.push(activeColumns[activeColumns.length - 1]); // add the last column ('actions') back
      }

      setActiveColumns(newActiveColumns);
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(createField(field))
    setShowFieldModal(false)
  };


  const handleChange = e => {
    setField({ ...field, [e.target.name]: e.target.value })
  }


  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listContacts({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  // useEffect(() => {
  //   if (successDelete) {
  //     dispatch(listContacts("", pageIndex + 1, pageSize));
  //     dispatch({ type: CONTACT_DELETE_RESET })
  //   }
  // }, [successDelete])


  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };


  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2 mx-2" >
              {windowSize?.width < 500 ? (
                <div>
                  <UncontrolledDropdown>
                    <DropdownToggle transform="shrink-3 down-2" className="btn-tertiary border-1 border-dark mx-1" size="sm" >
                      <FontAwesomeIcon icon="chevron-down" className="fs--1" />{` Actions`}
                    </DropdownToggle>
                    <DropdownMenu className="mr-5">
                      <DropdownItem onClick={() => { setShowContactModal(true) }} className={reduxSelectedRows?.length ? "d-none" : ""} ><FontAwesomeIcon icon="plus" className="fs--1 falcon-success" />{` Contact`}</DropdownItem>
                      <DropdownItem onClick={() => { }} tag={Link} to="/follow-up/contacts-import" className={reduxSelectedRows?.length ? "d-none" : ""} ><FontAwesomeIcon icon="upload" className="fs--1 falcon-success" />{` Import`}</DropdownItem>
                      <DropdownItem onClick={() => { dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return el.original }) }) }} tag={Link} to="/" className={reduxSelectedRows?.length ? "" : "d-none"} ><FontAwesomeIcon icon="paper-plane" className="fs--1" />{` Send SMS`}</DropdownItem>
                      <DropdownItem onClick={() => { return setShowChooseGroupModal(!showChooseGroupModal) }} disabled={!reduxSelectedRows.length} className={reduxSelectedRows?.length ? "" : "d-none"}><FontAwesomeIcon icon="plus" className="fs--1" />{` Add to group`}</DropdownItem>
                      <DropdownItem disabled={!reduxSelectedRows.length} onClick={() => { setShowDeleteModal(!showDeleteModal) }} className={reduxSelectedRows?.length ? "text-danger" : "d-none"} ><FontAwesomeIcon icon="trash" className="fs--1" />{` Delete`}</DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              ) : (
                <div>
                  <ButtonIcon className={reduxSelectedRows?.length ? "d-none" : "btn-tertiary border-dark mx-1"} onClick={(() => setShowContactModal(true))} icon="user-plus" transform="shrink-1" size="sm">
                    Contact
                  </ButtonIcon>
                  <ButtonIcon className={reduxSelectedRows?.length ? "d-none" : "btn-tertiary border-dark mx-1"} tag={Link} to="/follow-up/contacts-import" icon="upload" transform="shrink-1" size="sm">
                    Import
                  </ButtonIcon>
                  <ButtonIcon className={reduxSelectedRows?.length ? "btn-tertiary border-dark mx-1" : "d-none"} icon="sms" iconClassName="text-primary" tag={Link} to="/" onClick={() => { dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return { id: el.original._id, original: el.original } }) }) }} disabled={!reduxSelectedRows.length} transform="shrink-3" size="sm" >
                    Send SMS
                  </ButtonIcon>
                  <ButtonIcon className={reduxSelectedRows?.length ? "btn-tertiary border-dark mx-1" : "d-none"} icon="users" onClick={() => { return setShowChooseGroupModal(!showChooseGroupModal) }} disabled={!reduxSelectedRows.length} transform="shrink-3" size="sm" >
                    Add to group
                  </ButtonIcon>
                  <ButtonIcon className={reduxSelectedRows?.length ? "btn-tertiary border-dark text-danger mx-1" : "d-none"} onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!reduxSelectedRows.length} transform="shrink-1" size="sm" >
                    Delete
                  </ButtonIcon>
                </div>
              )}

              <div>
                <UncontrolledDropdown>
                  <DropdownToggle className="ms-2 btn-tertiary border-dark text-warning mx-1" transform="shrink-3 down-2" size="sm">
                    <FontAwesomeIcon icon="filter" className="fs--1" />
                  </DropdownToggle>
                  <DropdownMenu className="p-2" end>
                    <div>
                      <Input className="mb-2" value={filterString} onChange={(e) => setFilterString(e.target.value)} />
                    </div>
                    {filteredColumns.map((column, index) => (
                      <div key={index}>
                        <FormGroup className='cursor-pointer' switch>
                          <Input
                            type="switch"
                            role="switch"
                            id={index}
                            style={{ transform: 'scale(1.2)', marginRight: "5px" }} // Adjust scale and margin as needed
                            onChange={() => { handleFilter(column) }}
                            checked={activeColumns?.some((col) => col.Header === column?.Header)}
                          />
                          <h5 className="fs-10" >{column?.Header}</h5>
                        </FormGroup>
                      </div>
                    ))
                    }
                    <Button onClick={(e) => { return e.preventDefault(), setShowFieldModal(!showFieldModal) }} block className="btn-tertiary border-dark text-primary" >Add Field</Button>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingContacts && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )
          }
          <AdvanceContactsTableWrapper
            columns={activeColumns}
            data={contacts}
            fetchData={fetchData}
            total={contactsTotal}
            pageCount={1}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={() => { }}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceContactsTableSearchBox table />
              </Col>
            </Row>
            <AdvanceContactsTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap h-25"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold'
              }}
              isSelected={reduxSelectedRows}
              contactsTotal={contactsTotal}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceContactsTableFooter
                rowCount={contactsTotal}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceContactsTableWrapper>
        </CardBody>
      </Card>

      {
        //////////////----------------- MODALS ALL BELOW------------------------------

        // show Contact Modal
      }
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={toggle} className="d-flex flex-between-center border-bottom-0" close={closeBtn}>
          Create contact
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Contact Update Modal
      }
      <AddContactModal selectedContactRows={reduxSelectedRows?.map((el) => { return el.original._id })} showChooseGroupModal={showChooseGroupModal} setShowChooseGroupModal={setShowChooseGroupModal} />
      <Modal size="md" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
        <ModalHeader toggle={toggleSave} className=" border-bottom-0">
          <ButtonIcon
            onClick={() => { return console.log("contact", contact), dispatch(updateContact(contact, contact?._id)), setShowContactUpdateModal(!showContactUpdateModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <ContactEditModal
                contact={contact}
                changeText={changeText}
                setContact={setContact}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Save Modal
      }
      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Divider></Divider>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal) }} block className="btn-tertiary border-dark text-danger" >Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        //  console.log("redux rows", reduxSelectedRows)
        // Delete contact modal
      }
      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(deleteMultipleContacts(rowDelete)),
                    dispatch({ type: REMOVE_SELECTED_CONTACT_ROWS, payload: rowDelete }), // ['thiasf'] array of strings // need to add chat to delete chat
                    setShowDeleteModal(!showDeleteModal)
                } else {
                  const deleteIds = reduxSelectedRows.map((el) => { return el.original._id })
                  return dispatch(deleteMultipleContacts(deleteIds)),
                    dispatch({ type: RESET_SELECTED_CONTACT_ROWS, payload: deleteIds }), // need to add chat as second param to delete
                    setShowDeleteModal(!showDeleteModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger"
              >Delete</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Blocked Modal-----------------------------------------
      }
      <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">You will not recieve phone calls or messages from people on the block list.</Label>
              <Divider></Divider>
              <Button onClick={() => {
                return dispatch(createBlockedContacts([blockContact._id])), // data is in this format just to match for action, could just create a "singleBlockContact but didnt feel like it"
                  setShowBlockModal(!showBlockModal)
              }}
                block
                className="btn-tertiary border-dark text-danger">Block Contact</Button>
              <Button onClick={() => { setShowBlockModal(!showBlockModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Unsubscribe Modal---------------------------------------
      }
      <Modal isOpen={showUnsubscribeModal} centered toggle={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }}>
        <ModalHeader toggle={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                return dispatch(unsubscribeContacts(blockContact)),
                  setShowUnsubscribeModal(false)
              }}
                block
                className="btn-tertiary border-dark text-danger">Unsubscribe contact</Button>
              <Button onClick={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Removing Contacts (large) Modal---------------------------------------
      }
      <Modal
        isOpen={showRemovingContactsModal}
        centered
        toggle={() => setShowRemovingContactsModal(!showRemovingContactsModal)}
      >
        <ModalHeader
          toggle={() => setShowRemovingContactsModal(!showRemovingContactsModal)}
          className="d-flex justify-content-center"
        ></ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 fw-normal p-4">
              <Label className="text-center">
                Your contacts are being deleted; this may take a minute.
              </Label>
              <Divider />
              <div className="d-flex justify-content-center my-3">
                <Spinner />
              </div>
              <Button
                onClick={() => setShowRemovingContactsModal(!showRemovingContactsModal)}
                block
                className="btn-tertiary border"
              >
                Run in background
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // CREATE Field MODAL---------------------------------------
      }
      <Modal isOpen={showFieldModal} centered toggle={() => setShowFieldModal(!showFieldModal)}>
        <ModalHeader close={closeCreateBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Create a new Field
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Form onSubmit={handleSubmit}>
                <Label for="title">Field Name:</Label>
                <Input value={field.title.value} onChange={handleChange} className="mb-3" name="title" id="title" />
                <Button block disabled={field?.title?.length < 1} onClick={() => { setShowFieldModal(false) }} color="primary" className="mb-3" type="submit">Save</Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>

  );
};


export default ContactsTableCustom;