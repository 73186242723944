import React from 'react';
import ImporterForm from './ImporterForm';
import NewUserWizardProvider from './NewUserWizardProvider';

const NewUserWizardLayout = ({ setShowFirstLoginModal }) => {
  return (
    <NewUserWizardProvider>
      <ImporterForm setShowFirstLoginModal={setShowFirstLoginModal} />
    </NewUserWizardProvider>
  );
};

export default NewUserWizardLayout;
