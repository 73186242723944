import { PLAN_UPDATE_STATE, PLAN_UPDATE_STATE_RESET } from '../actions/types';

export default function planUpdateReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case PLAN_UPDATE_STATE:
      return payload;

    case PLAN_UPDATE_STATE_RESET:
      return {};

    default:
      return state;
  }
}
