// reducers/teamFilterReducer.js
import {
  UPDATE_TEAM_FILTER,
  FETCH_FILTERED_DATA,
  RESET_FETCH_FILTERED_DATA
} from '../actions/types';

const initialState = {
  teamMemberPreferences: {} // Key: memberId, Value: { viewCustomers, viewEstimates, viewInvoices }
};

export const teamFilterReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TEAM_FILTER:
      console.log('action.payload UPDATE_TEAM_FILTER', action.payload);
      return {
        ...state,
        teamMemberPreferences: action.payload // Update with the new preferences
      };
    case FETCH_FILTERED_DATA:
      return {
        ...state,
        fetchFreshData: true
      };
    case RESET_FETCH_FILTERED_DATA:
      return {
        ...state,
        fetchFreshData: false
      };
    default:
      return state;
  }
};
