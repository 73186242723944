import {
  MERCHANT_DETAILS_REQUEST,
  MERCHANT_DETAILS_SUCCESS,
  MERCHANT_DETAILS_FAIL,
  MERCHANT_DETAILS_RESET
} from '../actions/types';

export default function merchantDetailsReducer(state = {}, action) {
  switch (action.type) {
    case MERCHANT_DETAILS_REQUEST:
      return { loading: true };
    case MERCHANT_DETAILS_SUCCESS:
      return { loading: false, data: action.payload };
    case MERCHANT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case MERCHANT_DETAILS_RESET:
      return { loading: false, state: {} };
    default:
      return state;
  }
}
