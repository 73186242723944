import {
  CREATE_TEAM_REQUEST,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  CREATE_TEAM_RESET
} from '../actions/types';

const initialTeamCreateState = {
  team: null,
  loading: false,
  error: null
};

export const teamCreateReducer = (state = initialTeamCreateState, action) => {
  switch (action.type) {
    case CREATE_TEAM_REQUEST:
      return {
        ...state,
        loading: true
      };
    case CREATE_TEAM_SUCCESS:
      return {
        ...state,
        team: action.payload,
        loading: false,
        error: null
      };
    case CREATE_TEAM_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case CREATE_TEAM_RESET:
      return {
        ...initialTeamCreateState
      };
    default:
      return state;
  }
};
