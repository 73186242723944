import { LIST_ESTIMATES, LIST_ESTIMATES_FAILED } from '../actions/types';

// Initial state for the estimates table
const initialState = {
  estimates: [],
  pagination: {
    currentPage: 1,
    limit: 25,
    totalPages: 0,
    totalItems: 0
  },
  error: null,
  loading: false
};

// The estimates table reducer
const estimatesTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ESTIMATES:
      console.log("action.payload", action.payload);
      return {
        ...state,
        loading: false,
        estimates: action.payload.estimates.map(el => {
          return { ...el, key: el._id };
        }),
        totalEstimates: action.payload.totalEstimates,
        error: null
      };
    case LIST_ESTIMATES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        estimates: [],
      };
    default:
      return state; // Always return the current state by default
  }
};

export default estimatesTableReducer;
