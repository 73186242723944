import React from 'react';
import PropTypes from 'prop-types';

const LastMessage = ({ lastMessage, chat }) => {
  const name = chat?.contact?.firstName
    ? chat?.contact?.firstName
    : chat?.contact?.phone_number;

  const lastMassagePreview =
    lastMessage?.messageType === 'attachment'
      ? `${name} sent ${lastMessage?.attachment}`
      : lastMessage;

  if (lastMessage) {
    if (lastMessage.senderUserId === 3) {
      return `You: ${lastMassagePreview[0]}`;
    }

    if (chat.type === 'group') {
      return (
        <div
          className="chat-contact-content"
          dangerouslySetInnerHTML={{
            __html: `${name}: ${lastMassagePreview}`
          }}
        />
      );
    }

    return (
      <div
        className="chat-contact-content"
        dangerouslySetInnerHTML={{ __html: lastMassagePreview }}
      />
    );
  }

  return <div>Say hi to your new friend</div>;
};
LastMessage.propTypes = {
  chat: PropTypes.object.isRequired,
  lastMessage: PropTypes.object
};

export default LastMessage;
