import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom'; // React Router v6 import
import { useDispatch } from 'react-redux';
import { Button, Form, FloatingLabel, Alert } from 'react-bootstrap';  // Use React Bootstrap components
import axios from 'axios';
import PasswordChecklist from 'react-password-checklist';
import { SET_EMAIL, SET_PASS } from 'actions/types';


const RegistrationForm = () => {

  const dispatch = useDispatch();
  const location = useLocation(); // Use useLocation to access passed state

  // Extract teamId and email from location.state
  const { teamId, teamName, email } = location.state || {}; // If location.state is undefined, default to an empty object

  const [customerSignup, setCustomerSignUp] = useState({
    username: email || '', // Use the email from location.state or an empty string
    password: '',
    confirm_password: ''
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false)
  const [emailError, setEmailError] = useState("")
  const [showPasswordCheck, setShowPasswordCheck] = useState(false)
  const [userExistsMessage, setUserExistsMessage] = useState("")

  useEffect(() => {
    // If the email prop changes and the username in state is empty, update it
    if (email && !customerSignup.username) {
      setCustomerSignUp(prevState => ({ ...prevState, username: email }));
    }
  }, [email, customerSignup.username]);

  // Handler
  const handleSubmit = e => {

    e.preventDefault()

    const redirectToConfirmMail = (email) => {
      const encodedEmail = encodeURIComponent(email);
      window.location.href = `${process.env.REACT_APP_LANDING_URL}/confirm-mail?email=${encodedEmail}`;
    };

    if (!validateEmail(customerSignup.username)) {
      setEmailError("Email address is invalid.");
    } else {
      return setEmailError(null),
        setLoading(!loading),
        setIsDisabled(true),
        axios({
          method: "POST",
          data: {
            username: customerSignup.username,
            password: customerSignup.password,
            platform: 'web',
            teamId: teamId ? teamId : ""
          },
          url: `${process.env.REACT_APP_API_URL}/api/auth/localRegister`
        }).then((res) => {
          if (res.error) {
            //window.location.href = "http://localhost:3000/authentication/basic/register"
          } else if (res.data.existMessage) {
            return setUserExistsMessage("A user already exists with that email"),
              setLoading(!loading)
          } else if (res.data.existNotVerified) {
            return dispatch({ type: SET_EMAIL, payload: customerSignup.username }),
              dispatch({ type: SET_PASS, payload: customerSignup.password }),
              redirectToConfirmMail(customerSignup.username);
          } else {
            return dispatch({ type: SET_EMAIL, payload: customerSignup.username }),
              dispatch({ type: SET_PASS, payload: customerSignup.password }),
              redirectToConfirmMail(customerSignup.username);
          }
        })
    }
  }


  const handleChange = e => {
    setCustomerSignUp({ ...customerSignup, [e.target.name]: e.target.value })
    setEmailError(null)
    setUserExistsMessage(null)
    setLoading(false)

  }


  useEffect(() => {
    setIsDisabled(!customerSignup.username || !customerSignup.password || !customerSignup.confirm_password || customerSignup.password !== customerSignup.confirm_password);
  }, [customerSignup.username, customerSignup.password, customerSignup.confirm_password]);



  const validateEmail = email => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };




  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
            <Form.Control
              placeholder="Email address"
              name="username"
              readOnly={teamId ? true : false}
              className={emailError ? "border-danger" : ""}
              value={customerSignup?.username}
              onChange={handleChange}
              type="email"
            />
          </FloatingLabel>
          {emailError && (
            <p className="text-danger">Invalid Email format</p>
          )}
        </Form.Group>
        <Form.Group controlId="password">
          <FloatingLabel controlId="floatingPassword" label="Password" className="mb-3">
            <Form.Control
              type="password"
              placeholder="Password"
              name="password"
              value={customerSignup.password}
              onChange={handleChange}
              onFocus={() => setShowPasswordCheck(true)}
            />
          </FloatingLabel>
        </Form.Group>
        <Form.Group controlId="confirmPassword">
          <FloatingLabel controlId="floatingConfirmPassword" label="Confirm Password" className="mb-3">
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              name="confirm_password"
              value={customerSignup.confirm_password}
              onChange={handleChange}
              onFocus={() => setShowPasswordCheck(true)}
            />
          </FloatingLabel>
        </Form.Group>
        {showPasswordCheck && (
          <div className="form-row">
            <PasswordChecklist
              className='fs-9'
              rules={["minLength", "number", "match"]}
              invalidColor='#e45771'
              minLength={7}
              value={customerSignup?.password}
              valueAgain={customerSignup?.confirm_password}
            />
          </div>
        )}
        {userExistsMessage && (
          <Alert variant="danger" className="mt-2">
            A user with that email already exists.
          </Alert>
        )}
        <Button variant="primary" type="submit" className="mt-3 w-100" disabled={isDisabled}>
          {loading && !userExistsMessage ? "Creating Account..." : teamId ? "Submit" : "Create FREE Account"}
        </Button>
        {teamId ? (
          <p className="fs-9 text-800 fw-bold text-center mt-2">
            {`Invite from ${teamName}`}
          </p>
        ) : (
          <p className="fs--1 text-500 text-center mt-2">
            {"Cancel anytime. No credit card required."}
          </p>
        )}
      </Form>
    </>
  );
};

RegistrationForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

RegistrationForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default RegistrationForm;
