import React, { useContext, useEffect, Fragment, useState, useCallback } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Spinner
} from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from '../common/Flex';
import Moment from 'react-moment';
import AdvanceContactsTable from '../common/contactsTable/AdvanceContactsTable';
import AdvanceContactsTableFooter from '../common/contactsTable/AdvanceContactsTableFooter';
import AdvanceContactsTableSearchBox from '../common/contactsTable/AdvanceContactsTableSearchBox';
import AdvanceContactsTableWrapper from '../common/contactsTable/AdvanceContactsTableWrapper';
import { listContacts, listContactsTotal, addContactsToGroup } from '../../actions/index';
import Select from 'react-select';
import AppContext from '../../context/Context';

const AddContactsToGroup = ({ GroupId, showUnsubUserModal, setShowUnsubUserModal, showExistingUserModal, setShowExistingUserModal, chooseImportModal, setChooseImportModal }) => {


  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch();
  const { isDark } = useContext(AppContext)

  const contactsFollowUp = useSelector((state) => state.contactsFollowUp)
  const { contacts: contacts, loading: loadingContacts, totalItems: totalItems } = contactsFollowUp
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDelete } = contactDelete

  const [isSelected, setIsSelected] = useState(false);

  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })) },
    [setIsSelected]);


  const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: 150,  // set your desired minimum width
      backgroundColor: isDark ? 'dark' : 'light',
      borderColor: isDark ? 'dark' : 'light',
    }),
    input: (base) => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#2c7be5',
      fontWeight: "bold",
    }),
    option: (base, state) => ({
      ...base,
      color: '#2c7be5', // 
      fontWeight: "bold",
      pointerEvents: 'none', // This line disables the interactions
    }),
    singleValue: (base) => ({ // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: "bold",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      zIndex: 1000  // Update this value to be higher than the z-index of your table footer
    }),
    menuList: base => ({
      ...base,
    })

  };

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, { avatar }) => {
    const { firstName } = rowData.row.original
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{firstName}</h5>
        </Media>
      </Media>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { lastName, _id } = rowData.row.original
    return (
      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-9">{lastName}</h5>
      </Media>
    );
  };

  const phoneFormatter = (rowData) => {
    const { _id, phone_number } = rowData.row.original
    return (
      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-9">{phone_number}</h5>
      </Media>
    );
  };

  const groupFormatter = (rowData) => {
    const { group } = rowData.row.original

    // Map the groups to the format react-select expects
    const groupOptions = group.map(({ _id, title }) => ({
      value: _id,
      label: title,
    }));

    const placeholder = groupOptions.length > 0 ? groupOptions[0].label : '';

    return (
      <Select
        isSearchable={false}
        options={groupOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'group',
      Header: 'Group',

      Cell: groupFormatter
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
      accessor: 'phone_type',
      Header: 'Type',
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    }
  ];

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------



  useEffect(() => {
    dispatch(listContactsTotal())


  }, [])


  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listContacts({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])


  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };


  return (

    <Card className="mb-3">
      <CardHeader>
        <div className="d-flex justify-content-end">
          <ButtonIcon
            icon="plus"
            onClick={() => {
              const contactsToMoveIds = reduxSelectedRows.map((el) => { return el.original._id })
              return dispatch(addContactsToGroup(GroupId, contactsToMoveIds)),
                showExistingUserModal ? (setShowExistingUserModal(!showExistingUserModal), setChooseImportModal(!chooseImportModal)) :
                  setShowUnsubUserModal(!showUnsubUserModal)
            }}
            transform="shrink-3"
            className="btn-tertiary border-dark mx-2"
            size="sm">
            {`Add ${reduxSelectedRows?.length} Contacts`}
          </ButtonIcon>
          <ButtonIcon
            tag={Link}
            to="/follow-up/contacts-import"
            icon="users"
            transform="shrink-3"
            size="sm"
            className="btn-tertiary border-dark mx-2"
          >
            Import Contacts
          </ButtonIcon>
        </div>
      </CardHeader>
      <CardBody className="p-0">
        {loadingContacts && (
          <div style={overlayStyle}>
            <Spinner animation="border" role="status" style={spinnerStyle}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )
        }
        <AdvanceContactsTableWrapper
          columns={columns}
          data={contacts}
          fetchData={fetchData}
          total={totalItems}
          pageCount={1}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={25}
          selectionCallback={selectionCallback}
        >
          <Row>
            <Col md={8} lg={6}>
              <AdvanceContactsTableSearchBox table />
            </Col>
          </Row>
          <AdvanceContactsTable
            table
            headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
            rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap h-25"
            tableProps={{
              striped: true,
              className: 'fs--1 fw-bold'
            }}
            isSelected={reduxSelectedRows}
          />
          <div className="mt-3 mb-2 pe-2 ps-3 ">
            <AdvanceContactsTableFooter
              rowCount={totalItems}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceContactsTableWrapper>
      </CardBody>

    </Card>

  );
};


export default AddContactsToGroup;