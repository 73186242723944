import React, { useState } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MsgContent from '../message/MsgContent';

const NewMessageIcon = () => {
  const [showMessageModal, setShowMessageModal] = useState(false);
  const toggle = () => {
    setShowMessageModal(!showMessageModal);
  };
  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}></button>
  );
  return (
    <div className="contacts-search-wrapper d-flex justify-content-end">
      <Row>
        <Button
          color="falcon-primary"
          className=" bg-transparent shadow-none border-0"
          onClick={() => {
            setShowMessageModal(!showMessageModal);
          }}
          size="lg"
        >
          <FontAwesomeIcon transform="grow-3" icon="edit" />
        </Button>
      </Row>

      <Modal
        isOpen={showMessageModal}
        centered
        toggle={() => setShowMessageModal(!showMessageModal)}
      >
        <ModalHeader
          toggle={toggle}
          className="d-flex flex-between-center border-bottom-0"
          close={closeBtn}
        >
          New Message
        </ModalHeader>
        <ModalBody className="p-0">
          <MsgContent
            showMessageModal={showMessageModal}
            setShowMessageModal={setShowMessageModal}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default NewMessageIcon;
