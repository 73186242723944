import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  UncontrolledTooltip
} from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  listDLCCampaigns,
  deleteDLCRegistration
} from 'actions/index';
import { DLC_CAMPAIGN_DELETE_RESET } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';

const DLCCampaignsTable = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNotAllowedModal, setShowNotAllowedModal] = useState(false);
  const [rejectedReason, setRejectedReason] = useState('');
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [deletingCampaignId, setDeletingCampaignId] = useState(null);

  const [isSelected, setIsSelected] = useState(false);

  const closeNotAllowedBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => setShowNotAllowedModal(!showNotAllowedModal)}
    ></button>
  );


  const { DLCCampaigns, loading: DLCCampaignsLoading } = useSelector(state => state.DLCCampaigns);

  const deleteCampaignState = useSelector(state => state.DLCCampaignDelete);
  const { success: successDelete, loading: loadingDelete, error: deleteError } = deleteCampaignState;

  // This effect closes the modal after a successful delete
  useEffect(() => {
    if (successDelete) {
      setShowCancelModal(false);
      setDeletingCampaignId(null);
      dispatch({ type: DLC_CAMPAIGN_DELETE_RESET });
      // re-fetch the campaigns if not automatically done
      dispatch(listDLCCampaigns());
    }
  }, [successDelete, dispatch]);

  console.log("DLCCampaigns", DLCCampaigns);

  const numberCreate = useSelector(state => state.createNumber);
  const { success: successCreate } = numberCreate;

  const numberUpdate = useSelector(state => state.updateNumber);
  const { success: successUpdate } = numberUpdate;

  const titleFormatter = rowData => {
    const { scheduledToDelete, campaignTitle } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-9">
            <strong>{campaignTitle}</strong>
          </h5>
          {scheduledToDelete && (
            <h5 className="mb-0 fs-9" style={{ color: 'red' }}>
              <strong>Scheduled Deletion</strong>
            </h5>
          )}
        </Media>
      </Media>
    );
  };




  const useCaseFormatter = rowData => {
    const { usAppToPersonUseCase } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-10">{`${usAppToPersonUseCase}`}</h5>
        </Media>
      </Media>
    );
  };

  const renewalFormatter = rowData => {
    const { renewalDate } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-10 mt-2">
            <Moment format="MM/DD/YYYY" date={renewalDate} />
          </h5>
        </Media>
      </Media>
    );
  };

  const registrationFormatter = rowData => {
    const {
      _id,
      campaignStatus } = rowData.row.original;

    return (
      <Media className="cursor-pointer" tag={Flex} align="center">
        <Media body className="ms-2 mt-1">
          {campaignStatus === 'IN_PROGRESS' ? (
            <div>
              <Button disabled={true} className="btn-warning fs-10">
                In progress
              </Button>
            </div>
          ) : campaignStatus === 'VERIFIED' ? (
            <div>
              <Button disabled={true} className="btn-success fs-10">
                Verified
              </Button>
            </div>
          ) : campaignStatus === 'FAILED' ? (
            <div>
              <Button
                onClick={() => {
                  navigate('/follow-up/compliance/toll-free', {
                    state: {
                      fromTollFreeTable: true,
                      tollFreePhoneData: rowData.row.original,
                      step: 2,
                      campaignStatus: 'TWILIO_REJECTED'
                    }
                  });
                }}
                className="btn-danger fs-10"
              >
                Retry
              </Button>
            </div>
          ) : (
            <div></div>
          )}
        </Media>
      </Media>
    );
  };

  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;
    const items = [
      {
        label: 'Edit Campaign',
        icon: 'edit',
        action: () => { }
      },
      {
        label: 'Cancel Campaign',
        icon: 'trash',
        color: 'danger',
        action: () => handleRequestCancelCampaign(_id)
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };



  const columns = [
    {
      accessor: 'campaignTitle',
      Header: 'Title',
      Cell: titleFormatter
    },
    {
      accessor: 'usAppToPersonUseCase',
      Header: 'Use case',
      Cell: useCaseFormatter
    },
    {
      accessor: 'renewal-date',
      Header: 'Renewal date',
      Cell: renewalFormatter
    },
    {
      accessor: 'campaignStatus',
      Header: 'Status',
      Cell: registrationFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right'
    }
  ];


  useEffect(() => {
    if (successDelete) {
      setShowCancelModal(false);
      dispatch({ type: DLC_CAMPAIGN_DELETE_RESET });
    }
  }, [successDelete]);

  useEffect(() => {
    dispatch(listDLCCampaigns());
  }, [successCreate, successDelete, successUpdate]);

  const canCreateMoreCampaigns = (DLCCampaigns?.length || 0) < 5;


  // We'll handle "Cancel Campaign" by storing that doc's _id and opening the confirm modal
  const handleRequestCancelCampaign = _id => {
    setDeletingCampaignId(_id);
    setShowCancelModal(true);
  };

  // The final step: confirm deletion
  const handleConfirmDelete = () => {
    if (!deletingCampaignId) return;
    // Dispatch the action to delete
    dispatch(deleteDLCRegistration(deletingCampaignId));
  };


  return (
    <div>
      <h2 className="d-flex justify-content-start my-2 mx-2">Campaigns</h2>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <Fragment>
              <ButtonGroup className="mt-2">
                <div id="newCampaignTooltip">
                  <ButtonIcon
                    icon="plus"
                    onClick={() => {
                      navigate('/follow-up/compliance/10dlc', {
                        state: { fromDLCTable: true, step: 2 }
                      });
                    }}
                    transform="shrink-3"
                    className="btn-tertiary border-dark"
                    size="sm"
                    disabled={!canCreateMoreCampaigns}
                  >
                    New campaign
                  </ButtonIcon>
                </div>
                {!canCreateMoreCampaigns && (
                  <UncontrolledTooltip
                    placement="top"
                    target="newCampaignTooltip"
                  >
                    You have reached the maximum of 5 campaigns.
                  </UncontrolledTooltip>
                )}
              </ButtonGroup>
            </Fragment>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {DLCCampaignsLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              <Card>
                <AdvanceTableWrapper
                  columns={columns}
                  data={DLCCampaigns}
                  sortable
                  pagination
                  perPage={12}
                >

                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      className: 'fs--1 fw-bold overflow-hidden'
                    }}
                  />
                </AdvanceTableWrapper>
              </Card>
            </Fragment>
          )}
        </CardBody>
      </Card>


      {
        //------------------------------Edit Not allowd Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal
        isOpen={showNotAllowedModal}
        centered
        toggle={() => setShowNotAllowedModal(!showNotAllowedModal)}
      >
        <ModalHeader
          className="text-center d-flex flex-between-center border-bottom-0"
          close={closeNotAllowedBtn}
        >
          Rejected by The Campaign Registry
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4 text-center">
              <h5 className="text-danger">
                Your Toll-Free Number Verification was Rejected
              </h5>
              <p>
                Unfortunately, your toll-free number verification request was
                rejected by The Campaign Registry.
              </p>
              <p>
                <strong>Reason for Rejection:</strong> {rejectedReason}
              </p>
              <p>
                Due to the regulations from The Campaign Registry, this phone
                number cannot be resubmitted for verification. We understand
                this is inconvenient, but this is beyond our control and is
                dictated by regulatory compliance.
              </p>
              <p>
                To continue using our services, please acquire a new phone
                number and try the verification process again.
              </p>
              <p>
                For more information, visit{' '}
                <a
                  href="https://www.campaignregistry.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  The Campaign Registry
                </a>
                .
              </p>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      {/* Cancel (delete) campaign modal */}
      <Modal
        isOpen={showCancelModal}
        centered
        toggle={() => setShowCancelModal(!showCancelModal)}
      >
        <ModalHeader className="text-center">
          Delete Campaign
        </ModalHeader>
        <ModalBody>
          <Card className="border-0">
            <CardBody className="text-center">
              <p>
                <strong>Warning:</strong> Deleting this campaign will
                permanently remove it from your account. This action cannot be
                undone.
              </p>
              {deleteError && (
                <p className="text-danger">
                  Error: {deleteError}
                </p>
              )}
              <div className="d-flex justify-content-center">
                <Button
                  color="danger"
                  onClick={handleConfirmDelete}
                  disabled={loadingDelete}
                  className="me-2"
                >
                  {loadingDelete ? <Spinner size="sm" /> : 'Delete Campaign'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setShowCancelModal(false);
                    setDeletingCampaignId(null);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </div>
  );
};

export default DLCCampaignsTable;
