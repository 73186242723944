import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Row, Col, FloatingLabel } from 'react-bootstrap'; // React Bootstrap
import { Link, useNavigate } from 'react-router-dom'; // React Router v6
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { setUser } from 'actions';
import { SET_EMAIL } from 'actions/types';
import axios from 'axios';


const LoginForm = ({ hasLabel, layout }) => {
  // State
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState(''); // Simplified state
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate(); // React Router v6 navigation
  const dispatch = useDispatch();

  useEffect(() => {
    setIsDisabled(!email || !password); // Enable button when both fields are filled
  }, [email, password]);


  const handleSubmit = async (e) => {
    e.preventDefault();


    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/api/auth/local-login`,
      data: {
        username: email,  // Use email directly
        password: password
      },
      withCredentials: true
    }).then((res) => {

      const closeButtonStyles = {
        fontSize: '18px', // Increase the size of the close button
        cursor: 'pointer',
      };

      const CustomCloseButton = ({ closeToast }) => (
        <div onClick={closeToast} style={closeButtonStyles}>×</div>
      );

      if (res.data.code === "INCORRECT_USERNAME") {
        return toast(<Fragment>
          <strong>{res.data.message}</strong>
        </Fragment>, {
          position: "bottom-left",
          bodyClassName: 'fw-bold',
          closeButton: CustomCloseButton, // Custom close button component with close functionality
          onOpen: () => { return navigate('/authentication/simple/register', { state: { email } }) }
        })

      } else if (res.data.code === "USE_GOOGLE_SIGNIN") {
        return toast.error(res.data.message, {
          className: "bg-dark",
          position: 'bottom-left',
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 2000
        });

      } else if (res.data.code === "INCORRECT_PASSWORD") {
        return toast.error(res.data.message, {
          className: "bg-dark",
          position: 'bottom-left',
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 2000
        });

      } else if (res.data.code === "USER_NOT_VERIFIED") {
        return toast.error(res.data.message, {
          className: "bg-dark",
          position: 'bottom-left',
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 2000
        }),
        dispatch({ type: SET_EMAIL, payload: email }),
        navigate('/confirm-mail');

      } else if (res.data.code === "TEAM_INACTIVE") {
        return toast.error(res.data.message, {
          className: "bg-dark",
          position: 'bottom-left',
          closeOnClick: true,
          pauseOnHover: true,
          autoClose: 2000
        });
      } else {
        dispatch(setUser(res.data.user));
        navigate('/'); // Navigate to the dashboard
        toast(<Fragment>
          <strong>✅ Welcome</strong>
        </Fragment>, {
          autoClose: 2000,
          position: "bottom-left",
          bodyClassName: 'fw-bold',
          closeButton: CustomCloseButton, // Custom close button component with close functionality
        })
      }
    });
  };



  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-2" controlId="email">
        <FloatingLabel controlId="floatingInput" label="Email address" className="mb-2">
          <Form.Control // React Bootstrap input
            type="email"
            placeholder={!hasLabel ? 'Email address' : ''}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FloatingLabel>
      </Form.Group>
      <Form.Group controlId="password">
        <FloatingLabel controlId="floatingInput" label="Password" className="mb-3">
          <Form.Control // React Bootstrap input
            type="password"
            placeholder={!hasLabel ? 'Password' : ''}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FloatingLabel>
      </Form.Group>

      <Row className="justify-content-between align-items-center my-3">
        <Col xs="auto">
          <Link className="fs--1" to={`/authentication/simple/forgot-password`}>
            Forget Password?
          </Link>
        </Col>
      </Row>

      <Button variant="primary" type="submit" className="mt-3 w-100" disabled={isDisabled}>
        Log in
      </Button>

    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
