import React, { useEffect, useRef } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import axios from 'axios';
import WaveSurfer from 'wavesurfer.js';

const VoicemailPlayer = ({ voicemail, isSelected, onSelect }) => {
  const wavesurfer = useRef(null);
  const { url, title } = voicemail;

  useEffect(() => {
    if (url) {
      axios
        .get(url, { responseType: 'blob' })
        .then(response => {
          const audioObjectUrl = URL.createObjectURL(response.data);
          wavesurfer.current.load(audioObjectUrl);
        })
        .catch(error => console.error('Error fetching voicemail:', error));
    }

    wavesurfer.current = WaveSurfer.create({
      container: `#waveform-${voicemail._id}`,
      waveColor: 'violet',
      barHeight: 25,
      barGap: 5,
      height: 30,
      fillParent: true,

      progressColor: 'purple'
    });

    return () => {
      wavesurfer.current.destroy();
    };
  }, [url, voicemail._id]);

  const handlePlay = () => {
    wavesurfer.current.play();
  };

  const handleStop = () => {
    wavesurfer.current.stop();
  };

  return (
    <Card className={`voicemail-card ${isSelected ? 'selected' : ''}`}>
      <CardBody>
        <h5 className="text-center">{title}</h5>
        <div
          id={`waveform-${voicemail._id}`}
          className="waveform-container"
        ></div>
        <div className="text-center mt-2">
          <Button
            className="btn-tertiary border-dark text-success me-2"
            onClick={handlePlay}
          >
            Play
          </Button>
          <Button
            className="btn-tertiary border-dark text-danger"
            onClick={handleStop}
          >
            Stop
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default VoicemailPlayer;
