import React, { useEffect, createContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import socketio from 'socket.io-client';

export const SocketContext = createContext();

const SOCKET_URL = `${process.env.REACT_APP_API_URL}`;

let socket;

const initializeSocket = userId => {
  if (!socket) {
    socket = socketio.connect(SOCKET_URL, {
      query: { userId: userId ? userId : '' }
    });
  }
  return socket;
};

const SocketProvider = ({ children }) => {
  const user = useSelector(state => state.auth.user);
  const socketRef = useRef();

  useEffect(() => {
    if (user && user._id) {
      socketRef.current = initializeSocket(user._id);

      socketRef.current.on('messageFromBackend', message => {
      });
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, [user]);

  return (
    <SocketContext.Provider value={socketRef.current}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
