import React, { useState } from 'react';
import { Button, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Divider from '../common/Divider';
import makeAnimated from 'react-select/animated';
import { moveCopyContactsToGroup } from '../../actions/index';

const MoveCopy = ({
  isSelected,
  groupDetails,
  showMoveModal,
  setShowMoveModal
}) => {
  const dispatch = useDispatch();
  const contactIds = isSelected?.map(contact => contact.id);
  const [changeGroups, setChangeGroups] = useState({
    action: 'move',
    contactIds: contactIds,
    currentGroup: groupDetails
  });

  console.log('changeGroups', changeGroups);

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;

  const animatedComponents = makeAnimated();

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: 'light',
      // match with the menu
      borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
      // Overwrittes the different states of border
      borderColor: state.isFocused ? 'primary' : 'light',
      // Removes weird border around container
      boxShadow: state.isFocused ? null : null,
      '&:hover': {
        // Overwrittes the different states of border
        borderColor: state.isFocused ? 'blue' : '#2c7be5'
      }
    }),

    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      // kill the gap
      marginTop: 0,
      zIndex: 999
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      color: 'f9fafd'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
            ? data.color
            : isFocused,
        color: '232e3c'
      };
    }
  };

  return (
    <div>
      <Card>
        <CardBody className="fs--1 font-weight-normal p-4">
          <h5 className="text-center">Select groups</h5>
          <Divider className="mt-4 fs-2">
            Select Groups<strong style={{ color: 'red' }}> *</strong>
          </Divider>
          <Select
            name="group"
            required={true}
            className="mb-3"
            styles={customStyles}
            components={animatedComponents}
            closeMenuOnSelect={true}
            options={groups}
            getOptionLabel={({ title }) => title}
            getOptionValue={({ _id }) => _id}
            onChange={_id => setChangeGroups({ ...changeGroups, group: _id })}
            isMulti
            placeholder="select Groups"
            isSearchable={true}
          />
          <h5 className="mb-1 text-center">Move or Copy?</h5>
          <Divider className="mt-4 fs-2">
            Select action<strong style={{ color: 'red' }}> *</strong>
          </Divider>
          <FormGroup check>
            <Label check>
              <Input
                type="radio"
                id="move"
                value="move"
                name="move"
                checked={changeGroups?.action === 'move'}
                onChange={() => {
                  setChangeGroups({ ...changeGroups, action: 'move' });
                }}

              />
              <strong>Move contacts</strong>
              <p>
                Chosen contacts will be removed from the current group and moved
                to the selected groups.
              </p>
            </Label>
          </FormGroup>

          <FormGroup check className="my-2">
            <Label check>
              <Input
                type="radio"
                id="copy"
                value="copy"
                name="copy"
                checked={changeGroups?.action === 'copy'}
                onChange={() => {
                  setChangeGroups({ ...changeGroups, action: 'copy' });
                }}
              />
              <strong>Copy contacts</strong>
              <p>
                Chosen contacts remain in the current group and get copied to
                the selected groups.
              </p>
            </Label>
          </FormGroup>
          <Button
            block
            disabled={!changeGroups?.group}
            className="btn btn-tertiary border-dark rounded-pill btn-lg mt-4 fs-0 py-2"
            onClick={() => {
              return (
                setShowMoveModal(!showMoveModal),
                dispatch(moveCopyContactsToGroup(changeGroups))
              );
            }}
          >
            {changeGroups?.action === 'copy'
              ? 'Copy'
              : changeGroups?.action === 'move'
                ? 'Move'
                : ''}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default MoveCopy;
