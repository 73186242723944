// reducers/itemsReducer.js

import {
  LIST_RESPONDERS_REQUEST,
  LIST_RESPONDERS_SUCCESS,
  LIST_RESPONDERS_FAILURE,
  LIST_ITEMS_TOTAL,
  INSERT_UPDATED_RESPONDER,
  // ... other action types
} from '../actions/types';

const initialState = {
  responders: [],
  totalItems: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function responderReducer(state = initialState, action) {
  switch (action.type) {
    case LIST_RESPONDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LIST_RESPONDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        responders: action.payload.responders,
        totalItems: action.payload.totalItems
      };
    case LIST_RESPONDERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    //    case LIST_RESPONDERS_TOTAL:
    //      return {
    //        ...state,
    //        total: action.payload,
    //      };
    //    case INSERT_UPDATED_RESPONDERS:
    //      const updatedState = state.responders.map(responder => {
    //        if (responder?._id === action.payload?._id) {
    //          return action.payload;
    //        } else {
    //          return responder;
    //        }
    //      });
    //      return { ...state, loading: false, responders: updatedState };
    default:
      return state;
  }
};
