// StepTwoForm.js

import React, { Fragment, useContext } from 'react';
import { FormGroup, Input, Label, Form } from 'reactstrap';
import { NewUserUploadContext } from '../../../context/Context';

const StepTwoForm = () => {
  const { upload } = useContext(NewUserUploadContext);
  const { handleInputChange } = useContext(NewUserUploadContext);

  return (
    <Fragment>
      <h4 className="mb-1 text-center font-weight-bold">Welcome, {upload.companyName}</h4>
      <h6 className="mb-1 text-center">Let's set up your business</h6>
      <Form className="container mt-5 p-5 border rounded" style={{ maxWidth: '500px' }}>
        {/* Remove the company name field if already captured */}
        <FormGroup>
          <Label for="teamSize">How many team members (including yourself)?</Label>
          <Input
            type="select"
            id="teamSize"
            name="teamSize"
            value={upload.teamSize || ''}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            required
          >
            <option value="">Please select</option>
            <option value="Single">Single</option>
            <option value="2-5">2-5 people</option>
            <option value="5-10">5-10 people</option>
            <option value="10+">10+ people</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="annualRevenue">What's your estimated annual revenue?</Label>
          <Input
            type="select"
            id="annualRevenue"
            name="annualRevenue"
            value={upload.annualRevenue || ''}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            required
          >
            <option value="">Please select</option>
            <option value="0-50">$0 - $50,000</option>
            <option value="50-150">$50,000 - $150,000</option>
            <option value="150-500">$150,000 - $500,000</option>
            <option value="500+">$500,000+</option>
            <option value="prefer-not-to-share">Prefer not to share</option>
          </Input>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default StepTwoForm;
