import {
  LIST_BULK_SEND_SENT_MESSAGES,
  BULK_SEND_DETAILS_RESET
} from '../actions/types';

// DELIVERED REDUCER
// OVERALL REDUCER
export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_BULK_SEND_SENT_MESSAGES:
      console.log('bulk send sent payload', action.payload);
      return action.payload;

    case BULK_SEND_DETAILS_RESET:
      return [];

    default:
      return state;
  }
}
