import React, { useState } from 'react';
import { CsvUploadContext } from 'context/Context';

const CsvWizardProvider = ({ children }) => {
  const [upload, setUpload] = useState({
    countryNumbers: 'sameCountries',
    hasNumbers: true, // this is for importing via customers
    uploadType: 'files',
    landlineFilter: false,
    excludeFirstRow: false,
    customer_crm: false,
    selectCountry: "US",
    selectHeader: [{ customHeader: 'Do not Import', HeaderIndex: 0 }],
    group: []
  });

  const [step, setStep] = useState(1);
  const [userFile, setUserFile] = useState();

  const handleInputChange = ({ value, name }) => {
    return setUpload({ ...upload, [name]: value });
  };

  const value = {
    upload,
    setUpload,
    step,
    setStep,
    userFile,
    setUserFile,
    handleInputChange
  };
  return (
    <CsvUploadContext.Provider value={value}>
      {children}
    </CsvUploadContext.Provider>
  );
};

export default CsvWizardProvider;
