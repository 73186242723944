import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Button, Col, ListGroup, ListGroupItem, FormGroup, Row } from 'reactstrap';
import { Form } from 'react-bootstrap'
import { addContactProperty } from 'actions';
import 'react-phone-input-2/lib/style.css';
import usePlacesAutocomplete from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { CONTACT_UPDATE_RESET } from '../../actions/types';
import { useAppContext } from 'providers/AppProvider';



const NewProperty = ({ contact, setContact, setShowAddPropertyModal }) => {

  const dispatch = useDispatch()
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  const { config: { isDark } } = useAppContext();



  // State
  const [address, setAddress] = useState({
    street: '',
    street2: '',
    city: '',
    state: '',
    country: 'United States',
    postalCode: '',
    isValidStreet: true,
    isValidCity: true,
    isValidState: true,
    isValidPostalCode: true
  });



  const usStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];

  const canadaProvincesAndTerritories = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
  const statesAndProvinces = [...usStates, ...canadaProvincesAndTerritories];
  const countries = ["United States", "Canada"]

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success: successContactUpdate } = contactUpdate

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });


  const handleSelect = (suggestion) => async () => {
    // First, set the address state with the basic information from the autocomplete suggestion
    const selectedState = suggestion.terms[3]?.value;
    setValue(suggestion.description, false);
    setAddress({
      street: suggestion.terms[0]?.value + " " + suggestion.terms[1]?.value,
      city: suggestion.terms[2]?.value,
      state: selectedState
    });

    // Clear the autocomplete suggestions
    clearSuggestions();

    // Call the server-side proxy endpoint to get the postal code using the place_id
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getPostalCode`, {
        withCredentials: true,
        params: { placeId: suggestion.place_id }
      });

      // Update the contact state with the postal code from the response
      setAddress((currentAddress) => {
        return {
          ...currentAddress,
          postalCode: response.data.postalCode,
        }
      });
    } catch (error) {
      console.error('Error fetching postal code:', error);
      toast.error('Failed to fetch postal code information');
    }
  };



  const renderSuggestions = () => (
    <div className={`autocomplete-dropdown ${isDark ? 'dark-mode' : ''}`}>
      <ListGroup variant="flush" className="suggestions-list" role="listbox" aria-labelledby="address-input">
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <ListGroupItem
              action
              className="suggestion-item"
              key={place_id}
              onClick={handleSelect(suggestion)}
              role="option"
              aria-selected="false"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3 suggestion-icon" />
              <div className="suggestion-text">
                <span className="main-text">{main_text}</span>
                <br />
                <small className="secondary-text">{secondary_text}</small>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <div className="powered-by-google">
        <small>Powered by Google</small>
      </div>
    </div>
  );



  useEffect(() => {
    autocompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      { types: ['address'] }
    );

    autocompleteRef.current.setFields(['address_components', 'formatted_address']);

    autocompleteRef.current.addListener('place_changed', async () => {
      const place = await autocompleteRef.current.getPlace();
      const addressObject = place.address_components;
      const address = place.formatted_address;

      if (addressObject) {
        const city = addressObject.find((component) => component.types.includes('locality'))?.long_name || '';
        const state = addressObject.find((component) => component.types.includes('administrative_area_level_1'))?.short_name || '';
        const zip = addressObject.find((component) => component.types.includes('postal_code'))?.long_name || '';

        setAddress({
          ...address,
          street: address,
          city: city,
          state: state,
          postalCode: zip
        });
      }
    });

    return () => {
      window.google.maps.event.clearInstanceListeners(autocompleteRef.current);
    };
  }, [address]);



  useEffect(() => {
    if (contactUpdate?.error) {

      dispatch({ type: CONTACT_UPDATE_RESET })
    } else if (successContactUpdate) {


      dispatch({ type: CONTACT_UPDATE_RESET })
      setShowAddPropertyModal(false)
    }
  }, [successContactUpdate])


  return (
    <div className="p-4">
      <Form>
        <Row>
          <Col xs={12}>
            <div ref={ref}>
              <FormGroup className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="street"
                  name="street"
                  placeholder="Street"
                  disabled={!ready}
                  onChange={(e) => {
                    setValue(e.target.value);
                    setAddress({ ...address, street: e.target.value });
                  }}
                  value={address.street}
                />
                <label htmlFor="street">Street</label>
              </FormGroup>
              {status === "OK" && <ul>{renderSuggestions()}</ul>}
            </div>
          </Col>
          <Col xs={12}>
            <FormGroup className="form-floating">
              <input
                type="text"
                className="form-control"
                id="street2"
                name="street2"
                placeholder="Street 2"
                onChange={(event) => {
                  setAddress({ ...address, street2: event.target.value });
                }}
                value={address.street2}
              />
              <label htmlFor="street2">Street 2</label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup className="form-floating">
              <input
                type="text"
                className="form-control"
                id="city"
                name="city"
                placeholder="City"
                onChange={(event) => {
                  setAddress({ ...address, city: event.target.value });
                }}
                value={address.city}
              />
              <label htmlFor="city">City</label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup className="form-floating">
              <select
                className="form-control"
                id="state"
                onChange={(event) => {
                  setAddress({ ...address, state: event.target.value });
                }}
                value={address.state}
              >
                {statesAndProvinces.map((state) => (
                  <option key={state} value={state}>
                    {state}
                  </option>
                ))}
              </select>
              <label htmlFor="state">State</label>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup className="form-floating">
              <input
                type="text"
                className="form-control"
                id="postalCode"
                name="postalCode"
                placeholder="Postal code"
                onChange={(e) => {
                  setAddress({ ...address, postalCode: e.target.value });
                }}
                value={address.postalCode}
                required
              />
              <label htmlFor="postalCode">Postal code</label>
            </FormGroup>
            <FormGroup className="mb-3 w-60 form-floating">
              <select
                className="form-control"
                id="country"
                onChange={(event) => {
                  setAddress({ ...address, country: event.target.value });
                }}
                value={address.country}
              >
                {countries.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
              <label htmlFor="country">Country</label>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Button
            block
            color="primary"
            onClick={() => {
              if (setContact) {
                 return setContact({ ...contact, addresses: [...contact.addresses, address] }),
                  dispatch(addContactProperty(address, contact?._id))
              } else {
                dispatch(addContactProperty(address, contact?._id))
              }
            }}
            disabled={contactUpdate?.loading}
          >
            {contactUpdate?.loading ? 'Saving...' : 'Save'}
          </Button>
        </FormGroup>
      </Form>
    </div>
  );
};

NewProperty.propTypes = {

  hasLabel: PropTypes.bool
};

NewProperty.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default NewProperty;