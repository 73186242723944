import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Label, Media } from 'reactstrap';
import Moment from 'react-moment';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';
import classNames from 'classnames';
import VoicemailPlayer from 'components/services/VoicemailPlayer'; // Update this import path as needed

const CallDetails = ({ callDetails }) => {


  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  };

  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hrs.toString().padStart(2, '0')}:${mins
      .toString()
      .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const cost = callDetails?.duration > 0 ? Math.ceil(callDetails?.duration / 60) : 0;


  return (
    <div>
      <Card>
        <CardBody>
          {/* Call Preview */}
          <Row className="mb-3">
            <Col xs="12">
              <div
                className={classNames(
                  'p-2 rounded-2 compose-preview-message',
                  'bg-primary text-white'
                )}
              >
                {callDetails?.duration === 0 || callDetails?.duration == null ? (
                  // Duration is zero or undefined, show 00:00:00
                  <p className="mb-0 fw-light">00:00:00</p>
                ) : callDetails?.duration > 0 && callDetails?.recordingUrl ? (
                  // There is a duration above zero and we have a recording URL
                  <VoicemailPlayer
                    voicemail={{
                      url: callDetails.recordingUrl,
                      title: 'Call Recording',
                      _id: callDetails._id || 'unique_call_id'
                    }}
                  />
                ) : (
                  // Duration is above zero but no recording URL, revert to original UI
                  <>
                    <p className="mb-0 fw-light">
                      {callDetails.direction === 'outbound-dial'
                        ? 'Outgoing call'
                        : 'Incoming call'}{' '}
                      {callDetails.direction === 'outbound-dial' ? 'to' : 'from'}{' '}
                      {formatPhoneNumber(
                        callDetails.direction === 'outbound-dial'
                          ? callDetails.to
                          : callDetails.from
                      )}
                    </p>
                    <p className="mb-0 fw-light">
                      Duration:{' '}
                      <Moment
                        format="hh:mm:ss"
                        duration={callDetails?.startTime}
                        date={callDetails?.endTime}
                      />
                    </p>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {/* To */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">To:</Label>
            </Col>
            <Col xs="8">
              <h6 className="mb-0 fw-normal">{formatPhoneNumber(callDetails.to)}</h6>
            </Col>
          </Row>

          {/* From */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">From:</Label>
            </Col>
            <Col xs="8">
              <h6 className="mb-0 fw-normal">{formatPhoneNumber(callDetails.from)}</h6>
            </Col>
          </Row>

          {/* Direction */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Direction:</Label>
            </Col>
            <Col xs="8">
              <Media className="align-items-center">
                {callDetails.direction === 'outbound-dial' ? (
                  <SubtleBadge bg="primary" pill className="fs-9">
                    <FontAwesomeIcon
                      icon="arrow-right"
                      transform="shrink-1"
                      className="me-1"
                    />
                    Outgoing
                  </SubtleBadge>
                ) : (
                  <SubtleBadge bg="warning" pill className="fs-9">
                    <FontAwesomeIcon
                      icon="arrow-left"
                      transform="shrink-1"
                      className="me-1"
                    />
                    Incoming
                  </SubtleBadge>
                )}
              </Media>
            </Col>
          </Row>

          {/* Time */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Time:</Label>
            </Col>
            <Col xs="8">
              <h6 className="mb-0 fw-normal">
                <Moment format="MM/DD/YYYY hh:mm A" date={callDetails?.createdAt} />
              </h6>
            </Col>
          </Row>

          {/* Duration */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Duration:</Label>
            </Col>
            <Col xs="8">
              <Media tag={Flex} align="center">
                <Media body className="ml-2">
                  <h5 className="mb-0 fs--1 mt-2">
                    {callDetails?.duration ? (
                      <div>{formatDuration(callDetails?.duration)}</div>
                    ) : (
                      <div>00:00:00</div>
                    )}
                  </h5>
                </Media>
              </Media>
            </Col>
          </Row>

          {/* Cost */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Cost:</Label>
            </Col>
            <Col xs="8">
              <SubtleBadge bg="info" pill className="fs-9">
                {cost > 0 ? `${cost} ${cost === 1 ? 'credit' : 'credits'}` : 'No cost'}
              </SubtleBadge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default CallDetails;
