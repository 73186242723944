import React, { useState, useEffect, Fragment } from 'react';
import {
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Col,
  Row,
  Button,
  FormGroup,
  FormFeedback,
  ListGroup,
  ListGroupItem,
  Spinner
} from 'reactstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useAcceptJs } from 'react-acceptjs';
import { getCustomerProfileFollowUp, getMerchantDetailsFollowUp } from 'actions/followUpBillingActions';
import { listFollowUpBillingDetails } from 'actions/followUpBillingActions';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from "react-cool-onclickoutside";
import { useAppContext } from 'providers/AppProvider';
import visaIcon from '../../../assets/img/icons/visa.png';
import mastercardIcon from '../../../assets/img/icons/master-card.png';
import amexIcon from '../../../assets/img/icons/amex.png';
import discoverIcon from '../../../assets/img/icons/discover.png';
import TermsAndConditions from 'components/terms/TermsAndConditions';
import PaygPlanSelector from './PaygPlanSelector';

import { faCreditCard as faSolidCreditCard } from '@fortawesome/free-solid-svg-icons';

import CardInput from 'components/accountCRM/pricing/CardInput';
import { UPDATE_USER_CREDIT, UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP } from 'actions/types';
import { fetchUser } from 'actions';

const Subscription = ({
  plan,
  setPlan,
  isYearly,
  setShowChargeModal,
  processing,
  setProcessing
}) => {
  // *** NEW: Crypto-only flag ***
  // For now we only accept crypto (Bitcoin, XRP, Solana, USDT/stablecoins).
  // When you are ready to re-enable credit cards, simply set this to false.
  const cryptoOnly = true;


  // Original code below remains unchanged for future use
  const {
    config: { isDark }
  } = useAppContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const merchantDetails = useSelector(state => state.merchantDetailsFollowUp);
  const customerProfile = useSelector(state => state.customerProfileFollowUp);
  const followUpBillingDetails = useSelector(state => state.followUpBillingDetails?.billingDetails[0]);

  const currentUser = useSelector(state => state?.auth?.user);
  const { loading: loadingCustomerProfile, paymentProfiles, success: successCustomerProfile, error: errorCustomerProfile } = customerProfile;

  const [authData, setAuthData] = useState({});
  const [failedMessage, setFailedMessage] = useState('Maybe try a different card');
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);

  const [nonceResponse, setNonceResponse] = useState();
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [cardData, setCardData] = useState({
    cardNumber: '',
    month: '',
    year: '',
    cardCode: '',
  });
  const [cardToUse, setCardToUse] = useState('showCardForm');
  const [profileValues, setProfileValues] = useState({});
  const [failedCard, setFailedCard] = useState(false);
  const [errors, setErrors] = useState({});

  const environment = 'SANDBOX';

  const { dispatchData } = useAcceptJs({ environment, authData });

  const ProfileValueChange = e => {
    setProfileValues({ ...profileValues, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(getMerchantDetailsFollowUp());
    if (currentUser?.customerProfileFollowUp) {
      dispatch(getCustomerProfileFollowUp());
    }
  }, [dispatch, currentUser?.customerProfileFollowUp]);

  useEffect(() => {
    if (merchantDetails?.data?.publicClientKey) {
      setAuthData({
        apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_ID_FOLLOW_UP,
        clientKey: merchantDetails?.data?.publicClientKey,
      });
    }
  }, [merchantDetails]);

  useEffect(() => {
    if (failedCard) {
      toast.error(failedMessage);
      setFailedCard(false);
      setFailedMessage('');
    }
  }, [failedCard, failedMessage]);

  const dispatchCredits = (creditsStr) => {
    dispatch({ type: UPDATE_USER_CREDIT, payload: parseInt(creditsStr) });
  };

  const handleFailure = () => {
    setFailedCard(true);
    setProcessing(false);
    setShowChargeModal(false);
  };

  const createTransaction = async (customerPaymentProfileId, planId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/charge-customer-profile-fup`,
        { customerPaymentProfileId, planId },
        { withCredentials: true }
      );
      return res.data;
    } catch (error) {
      console.error('Error creating transaction:', error);
      throw error;
    }
  };

  const createSubscription = async (customerPaymentProfileId, planId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/create-subscription-from-profile-fup`,
        { paymentProfileId: customerPaymentProfileId, planId },
        { withCredentials: true }
      );
      return res.data;
    } catch (error) {
      console.error('Error creating subscription:', error);
      throw error;
    }
  };

  const upgradeSubscription = async (customerPaymentProfileId, planId) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/upgrade-subscription-fup`,
        { paymentProfileId: customerPaymentProfileId, planId },
        { withCredentials: true }
      );
      return res.data;
    } catch (error) {
      console.error('Error creating subscription:', error);
      throw error;
    }
  };

  const createTransactionAndSubscription = async (customerPaymentProfileId, plan) => {
    try {
      const planId = plan?.planId;
      const transactionResult = await createTransaction(customerPaymentProfileId, planId);

      if (transactionResult.response.success) {
        if (plan?.type !== "payg") {
          await new Promise(resolve => setTimeout(resolve, 10000));
          const subscriptionResult = await createSubscription(customerPaymentProfileId, planId);

          if (
            subscriptionResult.response.messages.resultCode === 'Ok' &&
            subscriptionResult.response.subscriptionId
          ) {
            dispatchCredits(plan?.credits);
            setSubscriptionSuccess(true);
            setProcessing(false);
            setShowChargeModal(false);
            dispatch(listFollowUpBillingDetails());
            toast.success("Subscription created successfully!");
            navigate('/');
          } else {
            console.error("Subscription failed:", subscriptionResult.response.messages.message[0].text);
            setFailedCard(true);
            setProcessing(false);
            setShowChargeModal(false);
          }
        } else {
          setSubscriptionSuccess(true);
          setProcessing(false);
          setShowChargeModal(false);
          toast.success("Payment successful! Credits have been added.");
        }
      } else {
        console.error("Transaction failed with code:", transactionResult.response.code);
        setFailedCard(true);
        setProcessing(false);
        setShowChargeModal(false);
      }
    } catch (error) {
      console.error('Error in transaction and subscription:', error);
      handleFailure();
    }
  };

  const handlePaymentProfileCreation = async () => {
    setProcessing(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/create-cust-pay-profile-fup`,
        { nonceResponse, billingDetails: profileValues },
        { withCredentials: true }
      );

      const { messages, customerPaymentProfileId } = response.data.response;

      if (
        messages.message[0].code === 'I00001' &&
        customerPaymentProfileId
      ) {
        if (plan.type === "payg") {
          await new Promise(resolve => setTimeout(resolve, 2000));
          const transactionResponse = await createTransaction(customerPaymentProfileId, plan?.planId);

          if (transactionResponse?.response?.success) {
            dispatchCredits(plan?.credits);
            setSubscriptionSuccess(true);
            setProcessing(false);
            setShowChargeModal(false);
            toast.success("Payment successful! Credits have been added.");
          } else {
            console.warn("Transaction unsuccessful:", transactionResponse.response.code);
            handleFailure();
          }
        } else {
          const isSubscriptionActive = followUpBillingDetails?.subscriptionStatus === "active";
          const isPlanUpgrade = plan?.monthlyPrice > followUpBillingDetails?.amount;
          if (isSubscriptionActive && isPlanUpgrade) {
            setProcessing(true);
            await createTransactionAndUpdateSubscription(customerPaymentProfileId);
          } else {
            await createTransactionAndSubscription(customerPaymentProfileId, plan);
          }
        }
      } else {
        console.warn("Unexpected response structure or unsuccessful status:", response.data.response);
        handleFailure();
      }
    } catch (error) {
      console.error('Error creating payment profile:', error);
      handleFailure();
    }
  };

  const handleCustomerProfileCreation = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/create-cust-profile-fup`,
        {},
        { withCredentials: true }
      );

      if (response.data.customerProfileId) {
        dispatch({ type: UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP, payload: response.data.customerProfileId });
      } else {
        setFailedCard(true);
        setProcessing(false);
        setShowChargeModal(false);
      }
    } catch (error) {
      console.error('Error creating customer profile:', error);
      setFailedCard(true);
      setProcessing(false);
      setShowChargeModal(false);
    }
  };

  const executeProfileCreations = async () => {
    await handleCustomerProfileCreation();
    await new Promise(resolve => setTimeout(resolve, 3000));
    await handlePaymentProfileCreation();
  };

  const handlePaymentProfileCreationWithProfileCheck = async () => {
    if (!currentUser?.customerProfileFollowUp) {
      await executeProfileCreations();
    } else {
      await handlePaymentProfileCreation();
    }
  };

  const createTransactionAndUpdateSubscription = async (newPayProfileId) => {
    try {
      const customerPaymentProfileId = cardToUse === 'showCardForm' ? newPayProfileId : cardToUse;
      const planId = plan?.planId;

      const transactionResult = await createTransaction(customerPaymentProfileId, planId);

      if (transactionResult.response.success) {
        toast.success("Transaction successful!");
        const updateSubscriptionResult = await upgradeSubscription(customerPaymentProfileId, planId);

        if (updateSubscriptionResult.success) {
          toast.success("Subscription updated successfully!");
          setSubscriptionSuccess(true);
          setProcessing(false);
          setShowChargeModal(false);
          dispatch(listFollowUpBillingDetails());
          navigate('/');
        } else {
          console.error("Subscription update failed:", updateSubscriptionResult.error);
          toast.error("Transaction succeeded, but failed to update subscription.");
          setProcessing(false);
          setShowChargeModal(false);
        }
      } else {
        console.error("Transaction failed with code:", transactionResult.response.code);
        toast.error("Transaction failed. Please try again.");
        setFailedCard(true);
        setProcessing(false);
        setShowChargeModal(false);
      }
    } catch (error) {
      console.error('Error in transaction and subscription:', error);
      toast.error('An error occurred while processing your payment.');
      setFailedCard(true);
      setProcessing(false);
      setShowChargeModal(false);
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    const newErrors = {};
    if (!profileValues.street1 && cardToUse === "showCardForm") {
      newErrors.street1 = 'Street is required';
    }
    if (!profileValues.city && cardToUse === "showCardForm") {
      newErrors.city = 'City is required';
    }
    if (!profileValues.state && cardToUse === "showCardForm") {
      newErrors.state = 'State is required';
    }
    if (!profileValues.zip && cardToUse === "showCardForm") {
      newErrors.zip = 'ZIP Code is required';
    }
    if (!profileValues.country && cardToUse === "showCardForm") {
      newErrors.country = 'Country is required';
    }
    if (!cardData.cardNumber && cardToUse === "showCardForm") {
      newErrors.cardNumber = 'Card Number is required';
    }
    if ((!cardData.month || !cardData.year) && cardToUse === "showCardForm") {
      newErrors.expDate = 'Expiration Date is required';
    }
    if (!cardData.cardCode && cardToUse === "showCardForm") {
      newErrors.cardCode = 'CVC is required';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({});

    try {
      if (cardToUse === 'showCardForm') {
        setProcessing(true);
        const response = await dispatchData({ cardData, billingDetails: profileValues });
        setNonceResponse(response);
      } else {
        setProcessing(true);
        if (plan.type === "payg") {
          const transactionResponse = await createTransaction(cardToUse, plan?.planId);

          if (transactionResponse.response.success) {
            dispatchCredits(plan?.credits);
            setSubscriptionSuccess(true);
            setProcessing(false);
            setShowChargeModal(false);
            toast.success("Payment successful! Credits have been added.");
            dispatch(listFollowUpBillingDetails());
            dispatch(fetchUser());
            if (window.location.pathname !== "/follow-up/compliance/10dlc") {
              navigate("/");
            }
          } else {
            console.warn("Transaction unsuccessful:", transactionResponse.response.code);
            handleFailure();
          }
        } else {
          const isSubscriptionActive = followUpBillingDetails?.subscriptionStatus === "active";
          const isPlanUpgrade = plan?.monthlyPrice > followUpBillingDetails?.amount;
          if (isSubscriptionActive && isPlanUpgrade) {
            setProcessing(true);
            await createTransactionAndUpdateSubscription();
          } else {
            await createTransactionAndSubscription(cardToUse, plan);
          }
        }
      }
    } catch (err) {
      console.error('Error in onSubmit:', err);
      setFailedCard(true);
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (nonceResponse) {
      handlePaymentProfileCreationWithProfileCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonceResponse]);

  useEffect(() => {
    if (subscriptionSuccess) {
      const timer = setTimeout(() => {
        navigate('/');
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [subscriptionSuccess, navigate]);

  const closeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => setShowTermsModal(!showTermsModal)}
    >
    </button>
  );

  const calculateDueDate = () => {
    const dueDate = new Date();
    if (isYearly) {
      dueDate.setFullYear(dueDate.getFullYear() + 1);
    } else {
      dueDate.setMonth(dueDate.getMonth() + 1);
    }
    return dueDate.toLocaleDateString();
  };

  const formattedDueDate = calculateDueDate();

  const usStates = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
    'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
    'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
    'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
    'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
  ];
  const canadaProvincesAndTerritories = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
  const statesAndProvinces = [...usStates, ...canadaProvincesAndTerritories];

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleSelect = (suggestion) => async () => {
    clearSuggestions();

    const main_text = suggestion.structured_formatting.main_text;
    const secondary_text = suggestion.structured_formatting.secondary_text;

    const secondaryParts = secondary_text.split(',').map(part => part.trim());

    let city = '';
    let state = '';
    let country = '';

    if (secondaryParts.length >= 1) {
      city = secondaryParts[0];
    }
    if (secondaryParts.length >= 2) {
      state = secondaryParts[1];
    }
    if (secondaryParts.length >= 3) {
      country = secondaryParts[2];
    }

    setProfileValues((prevValues) => ({
      ...prevValues,
      street1: main_text,
      city: city,
      state: state,
      country: country,
    }));

    setValue(main_text, false);

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getPostalCode`, {
        withCredentials: true,
        params: { placeId: suggestion.place_id }
      });

      const zip = response.data.postalCode;

      setProfileValues((prevValues) => ({
        ...prevValues,
        zip: zip,
      }));

    } catch (error) {
      console.error('Error fetching postal code:', error);
      toast.error('Failed to fetch postal code information');
    }
  };

  const renderSuggestions = () => (
    <div className={`autocomplete-dropdown ${isDark ? 'dark-mode' : ''}`}>
      <ListGroup variant="flush" className="suggestions-list" role="listbox" aria-labelledby="address-input">
        {data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion;

          return (
            <ListGroupItem
              action
              className="suggestion-item"
              key={place_id}
              onClick={handleSelect(suggestion)}
              role="option"
              aria-selected="false"
            >
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3 suggestion-icon" />
              <div className="suggestion-text">
                <span className="main-text">{main_text}</span>
                <br />
                <small className="secondary-text">{secondary_text}</small>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <div className="powered-by-google">
        <small>Powered by Google</small>
      </div>
    </div>
  );

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem',
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff',
  };

  if (cryptoOnly) {
    return (
      <Fragment>
        <Card className="h-100">
          <CardBody className="text-center">
            <h2>Crypto Payments Only</h2>
            <p>
              At this time we only accept cryptocurrency payments (Bitcoin, XRP, Solana, USDT, or any stablecoin).
              To proceed with your purchase, please email{' '}
              <a href="mailto:support@bulktext.com">support@bulktext.com</a> and ask to pay using crypto.
            </p>
          </CardBody>
        </Card>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {/* Loading Overlay */}
      <Card className="h-100">
        {(processing || loadingCustomerProfile || subscriptionSuccess) && (
          <div style={overlayStyle}>
            {subscriptionSuccess ? (
              <div className="text-center">
                {plan?.type === "payg" ? (
                  <div>
                    <h2>Payment Successful!</h2>
                    <p>Credits have been added to your account.</p>
                  </div>
                ) : (
                  <div>
                    <h2>Subscription Successful!</h2>
                    <p>Your subscription has been activated.</p>
                  </div>
                )}
              </div>
            ) : (
              <div className="text-center">
                <Spinner animation="border" role="status" style={spinnerStyle}>
                  <span className="visually-hidden">
                    {processing ? 'Processing...' : 'Loading...'}
                  </span>
                </Spinner>
              </div>
            )}
          </div>
        )}
        <CardBody>
          {paymentProfiles?.length >= 1 && (
            <div>
              <FalconCardHeader title="Choose Payment Method" />
              <Row className="gx-0 ps-2 mb-3">
                <Col>
                  {paymentProfiles.map(el => {
                    const cardType = el.payment?.creditCard?.cardType || '';
                    const cardNumber = el.payment?.creditCard?.cardNumber || '';
                    const last4 = cardNumber.slice(-4);
                    const cardTypeKey = cardType.toLowerCase();

                    let cardIconElement;
                    if (cardTypeKey.includes('visa')) {
                      cardIconElement = <img src={visaIcon} alt="Visa" className="payform-card-icon me-2" />;
                    } else if (cardTypeKey.includes('mastercard')) {
                      cardIconElement = <img src={mastercardIcon} alt="MasterCard" className="payform-card-icon me-2" />;
                    } else if (cardTypeKey.includes('american express') || cardTypeKey.includes('amex')) {
                      cardIconElement = <img src={amexIcon} alt="American Express" className="payform-card-icon me-2" />;
                    } else if (cardTypeKey.includes('discover')) {
                      cardIconElement = <img src={discoverIcon} alt="Discover" className="payform-card-icon me-2" />;
                    } else {
                      cardIconElement = <FontAwesomeIcon icon={faSolidCreditCard} size="2x" className="me-2" />;
                    }

                    return (
                      <Card className="border my-2 pt-2 ps-2" key={el.customerPaymentProfileId}>
                        <FormGroup check>
                          <Input
                            type="radio"
                            id={el.customerPaymentProfileId}
                            checked={cardToUse === el.customerPaymentProfileId}
                            onChange={() => setCardToUse(el.customerPaymentProfileId)}
                            value={el.customerPaymentProfileId}
                            name="paymentMethod"
                          />
                          <Label
                            for={el.customerPaymentProfileId}
                            check
                            className="d-flex align-items-center"
                          >
                            {cardIconElement}
                            <span>
                              <strong>{cardType}</strong> •••• {last4}
                            </span>
                          </Label>
                        </FormGroup>
                      </Card>
                    );
                  })}
                </Col>
              </Row>
              <Row className="mx-4 mb-3">
                <Col>
                  <FormGroup check>
                    <Input
                      type="radio"
                      id="showCardForm"
                      checked={cardToUse === 'showCardForm'}
                      onChange={() => setCardToUse('showCardForm')}
                      value="showCardForm"
                      name="paymentMethod"
                    />
                    <Label for="showCardForm" check>
                      <strong>Credit Card</strong>
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          )}
          {cardToUse === 'showCardForm' && (
            <Row className="gx-0 mb-2">
              <Col sm={12}>
                {/* Name on Card */}
                <FormGroup floating>
                  <Input
                    type="text"
                    id="nameOnCard"
                    placeholder="Name on Card"
                    value={profileValues.nameOnCard || ''}
                    name="nameOnCard"
                    onChange={ProfileValueChange}
                    invalid={!!errors.nameOnCard}
                  />
                  <Label for="nameOnCard">Name on Card</Label>
                  {errors.nameOnCard && (
                    <FormFeedback>{errors.nameOnCard}</FormFeedback>
                  )}
                </FormGroup>

                {/* Card Input Component */}
                <CardInput
                  cardData={cardData}
                  setCardData={setCardData}
                  errors={errors}
                />

                {/* Billing Details */}
                <Row className="mt-3">
                  <Col xs={12} lg={8}>
                    <div ref={ref} style={{ position: 'relative' }}>
                      <FormGroup floating>
                        <Input
                          type="text"
                          id="billingStreet1"
                          placeholder="Street 1"
                          value={value}
                          name="street1"
                          onChange={(e) => {
                            setValue(e.target.value);
                            ProfileValueChange(e);
                          }}
                          invalid={!!errors.street1}
                        />
                        <Label for="billingStreet1">Street 1</Label>
                        {errors.street1 && (
                          <FormFeedback>{errors.street1}</FormFeedback>
                        )}
                      </FormGroup>
                      {status === 'OK' && renderSuggestions()}
                    </div>
                  </Col>
                  <Col xs={12} lg={4}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        id="billingStreet2"
                        placeholder="Street 2"
                        value={profileValues.street2 || ''}
                        name="street2"
                        onChange={ProfileValueChange}
                        invalid={!!errors.street2}
                      />
                      <Label for="billingStreet2">Unit #</Label>
                      {errors.street2 && (
                        <FormFeedback>{errors.street2}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        id="billingCity"
                        placeholder="City"
                        value={profileValues.city || ''}
                        name="city"
                        onChange={ProfileValueChange}
                        invalid={!!errors.city}
                      />
                      <Label for="billingCity">City</Label>
                      {errors.city && (
                        <FormFeedback>{errors.city}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup floating>
                      <Input
                        type="select"
                        id="billingState"
                        placeholder="State"
                        value={profileValues.state || ''}
                        name="state"
                        onChange={ProfileValueChange}
                        invalid={!!errors.state}
                      >
                        <option value="" disabled>Select State</option>
                        {statesAndProvinces.map(stateCode => (
                          <option key={stateCode} value={stateCode}>{stateCode}</option>
                        ))}
                      </Input>
                      <Label for="billingState">State</Label>
                      {errors.state && (
                        <FormFeedback>{errors.state}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup floating>
                      <Input
                        type="text"
                        id="billingZip"
                        placeholder="ZIP Code"
                        value={profileValues.zip || ''}
                        name="zip"
                        onChange={ProfileValueChange}
                        invalid={!!errors.zip}
                      />
                      <Label for="billingZip">ZIP Code</Label>
                      {errors.zip && (
                        <FormFeedback>{errors.zip}</FormFeedback>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup floating>
                  <Input
                    type="text"
                    id="billingCountry"
                    placeholder="Country"
                    value={profileValues.country || ''}
                    name="country"
                    onChange={ProfileValueChange}
                    invalid={!!errors.country}
                  />
                  <Label for="billingCountry">Country</Label>
                  {errors.country && (
                    <FormFeedback>{errors.country}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          )}
          <div className="border-dashed border-bottom mb-2"></div>
          <Row>
            <Col xs={12}>
              <div>
                {plan?.type === "payg" ? (
                  <div className="container mt-5">
                    <PaygPlanSelector plan={plan} setPlan={setPlan} isDark={isDark} isYearly={isYearly} />
                  </div>
                ) : (
                  <div>
                    <p className={`mb-1 text-start fs-7 fw-medium text-${isDark ? "light" : "dark"}`}>Plan details</p>
                    <div className="d-flex justify-content-between">
                      <p className={`fs-9 fw-medium text-${isDark ? "light" : "dark"}`}>{`${plan?.type.charAt(0).toUpperCase() + plan?.type.slice(1)} Plan`}</p>
                      <span className={`fs-10 fw-medium text-${isDark ? "light" : "dark"}`}>
                        ${plan?.monthlyPrice?.toFixed(2)} <small>monthly</small>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="border-dashed border-bottom mb-2"></div>
              <div>
                <p className={`mb-1 text-start fs-7 fw-medium text-${isDark ? "light" : "dark"}`}>Totals</p>
                <div className="d-flex justify-content-between">
                  <p className={`fs-9 fw-medium text-${isDark ? "light" : "dark"}`}>Taxes</p>
                  <span className={`fs-10 fw-medium text-${isDark ? "light" : "dark"}`}>
                    $0.00
                  </span>
                </div>
              </div>
              <div className="border-dashed border-bottom mb-2"></div>
              <div>
                <p className={`mb-1 text-start fs-7 fw-semibold text-${isDark ? "light" : "dark"}`}>{plan?.type === "payg" ? "One Time" : "Plan Total"}</p>
                <div className="d-flex justify-content-between">
                  <p className={`fs-9 fw-medium ${isDark ? "white" : "black"}`}>
                    {plan?.type === "payg" ? "Due Today" : `Due starting ${formattedDueDate}`}
                  </p>
                  <span className={`fs-10 fw-medium text-${isDark ? "light" : "dark"}`}>
                    {plan?.type === "payg" ? `$${(plan?.amount / 100).toFixed(2)}` : plan?.monthlyPrice?.toFixed(2)}{' '}
                    {plan?.type !== "payg" && <small>{isYearly ? 'per year' : 'per month'}</small>}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <p className={`fs-7 fw-bold text-${isDark ? "light" : "dark"}`}>
                  Due today
                </p>
                <span className={`fs-6 fw-bold text-${isDark ? "light" : "dark"}`}>
                  {plan?.type === "payg" ? `$${(plan?.amount / 100).toFixed(2)}` : plan?.monthlyPrice?.toFixed(2)}
                </span>
              </div>
              <Button
                onClick={(e) => { onSubmit(e) }}
                block
                color="primary"
                className="mt-3 px-5"
                disabled={
                  processing ||
                  (cardToUse === 'showCardForm' &&
                    (!profileValues.nameOnCard ||
                      !profileValues.street1 ||
                      !profileValues.city ||
                      !profileValues.state ||
                      !profileValues.zip ||
                      !profileValues.country ||
                      !cardData.cardNumber ||
                      !cardData.cardCode ||
                      !cardData.month ||
                      !cardData.year))
                }
              >
                {processing ? 'Processing' : 'Pay Now'}
              </Button>

              <p className="fs-9 mt-3 mb-0">
                By clicking <strong>Pay Now</strong> you agree to the{' '}
                <Link to="#!" onClick={() => setShowTermsModal(!showTermsModal)}>
                  Terms &amp; Conditions
                </Link>
              </p>
            </Col>
          </Row>
          <hr />

          {plan?.type === "payg" ? (
            <div>
              <p className="fs-9 text-center text-600">
                Once you purchase, credits will be added to your account. <strong className={`text-${isDark ? "white" : "black"}`}>Discounts</strong> available on{' '}
                <Link className="fw-bold" style={{ textDecoration: "underline" }} to="/follow-up/monthly-plans">
                  monthly plans
                </Link>.
              </p>
            </div>
          ) : (
            <p className="fs-9 text-center text-600">
              Once you purchase, your plan will be active. You can edit your subscription in your account settings later on if you like.
            </p>
          )}
        </CardBody>
      </Card>

      {/* Terms Modal */}
      <Modal
        size="md"
        isOpen={showTermsModal}
        centered
        toggle={() => setShowTermsModal(!showTermsModal)}
      >
        <ModalHeader
          toggle={() => setShowTermsModal(!showTermsModal)}
          className="bg-light d-flex flex-between-center border-bottom-0"
          close={closeBtn}
        />
        <ModalBody className="p-0">
          <Row>
            <Col className="text-center">
              <div>
                <h4 className="mb-1">Terms and Conditions</h4>
                <TermsAndConditions />
              </div>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Subscription;
