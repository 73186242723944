import {
  LIST_BANK_PAYOUTS_REQUEST,
  LIST_BANK_PAYOUTS_SUCCESS,
  LIST_BANK_PAYOUTS_FAIL
} from '../actions/types';

const initialState = {
  bankPayouts: [],
  totalItems: 0,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_BANK_PAYOUTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_BANK_PAYOUTS_SUCCESS:
      const add_idForTable = action?.payload?.bankPayouts?.map((bankPayout) => {
        return {
          ...bankPayout,
          _id: bankPayout?.id
        }
      })
      return {
        ...state,
        bankPayouts: add_idForTable,
        totalBankPayouts: action.payload.totalBankPayouts,
        loading: false
      };
    case LIST_BANK_PAYOUTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}