import {
  UPDATE_SERVICE_REQUEST,
  UPDATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_FAIL,
  UPDATE_SERVICE_RESET
} from '../actions/types';

export default function serviceUpdateReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_SERVICE_REQUEST:
      return { loading: true };
    case UPDATE_SERVICE_SUCCESS:
      return { loading: false, success: true, serviceInfo: action.payload };
    case UPDATE_SERVICE_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_SERVICE_RESET:
      return {};
    default:
      return state;
  }
}
