import {
  LIST_GROUPS,
  LIST_SHOW_GROUPS_REQUEST,
  LIST_SHOW_GROUPS_SUCCESS,
  LIST_SHOW_GROUPS_FAIL,
  LIST_GROUPS_EXCEPT_UNSUB
} from '../actions/types';

const initialState = {
  groups: [],
  total: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function (state = initialState, action) {
  switch (action.type) {

    case LIST_SHOW_GROUPS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    // when we logout, this action.payload is an empty string so lets do || false
    // show all groups
    case LIST_GROUPS:
      const unSubGroup = action.payload.groups?.filter(
        group => group.title === 'unsubscribers' && !group.hide
      )[0];
      const allOtherGroups = action.payload.groups?.filter(
        group => group.title !== 'unsubscribers' && !group.hide
      );
      if (unSubGroup) {
        allOtherGroups.push(unSubGroup);
      }
      return { groups: allOtherGroups, loading: false, totalGroups: action.payload?.totalGroups };
    // this is to show groups NOT hidden
    case LIST_SHOW_GROUPS_SUCCESS:
      const unSubGroupShow = action.payload?.groups?.filter(
        group => group.title === 'unsubscribers' && !group.hide
      )[0]; // grabbing unsub group OBJECT
      const allOtherGroupsShow = action.payload?.groups?.filter(
        group => group.title !== 'unsubscribers' && !group.hide
      ); // grabbing all other groups

      // Since we no longer have `contacts` directly in the group, we don't filter them anymore
      // Assuming `contactsCount` in the group is the count of non-blocked, non-unsubscribed contacts
      const removeUnsubContacts = allOtherGroupsShow.map(g => {
        return {
          title: g.title,
          created: g.created,
          hide: g.hide,
          _id: g._id,
          contactsCount: g.contactsCount // Instead of `contacts`, we now have `contactsCount`
        };
      });

      if (unSubGroupShow) {
        removeUnsubContacts.push(unSubGroupShow); // putting unsub at end for table to show unsub as last group
      }

      return { groups: removeUnsubContacts, loading: false, totalGroups: action.payload?.totalGroups };

    // this is for compose to show groups except unsubscriber group
    case LIST_GROUPS_EXCEPT_UNSUB:
      const groupsWithShowAndUnsubscribersGroupRemoved = action.payload.groups?.filter(
        group => group.title !== 'unsubscribers' && !group.hide
      ); // filter out unsubscribers group,
      const groups = groupsWithShowAndUnsubscribersGroupRemoved.map(g => {
        // map over groups
        return {
          title: g.title,
          created: g.created,
          hide: g.hide,
          _id: g._id,
          contactsCount: g.contactsCount
        };
      });
      return { groups: groups, loading: false, totalGroups: action.payload?.totalGroups };
    default:
      return state;
  }
}
