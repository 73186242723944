import React from 'react';
import { Col, Row, Card, CardBody } from 'reactstrap';

import TabContacts from './TabContacts.js';

const ContactsComp = () => {
  return (
    <div>
      <Col>
        <Row>
          <TabContacts className="cursor-pointer" />
        </Row>
      </Col>
    </div>
  );
};

export default ContactsComp;
