import {
    DELETE_RESPONDER_MESSAGES_REQUEST,
    DELETE_RESPONDER_MESSAGES_SUCCESS,
    DELETE_RESPONDER_MESSAGES_FAIL,
    DELETE_RESPONDER_MESSAGES_RESET
} from '../actions/types';

export default function responderMessagesDeleteReducer(state = {}, action) {
    switch (action.type) {
        case DELETE_RESPONDER_MESSAGES_REQUEST:
            return { loading: true };
        case DELETE_RESPONDER_MESSAGES_SUCCESS:
            return { loading: false, success: true };
        case DELETE_RESPONDER_MESSAGES_FAIL:
            return { loading: false, error: action.payload };
        case DELETE_RESPONDER_MESSAGES_RESET:
            return {};
        default:
            return state;
    }
}
