import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InsertLink from './InsertLink';
import InsertMMS from './InsertMMS';
import InsertVideo from './InsertVideo';

const ButtonModal = ({ icon, title, limit, ModalComponent, modalProps }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        onClick={() => setShowModal(!showModal)}
        className=" btn-tertiary text-center w-100 h-100 d-flex flex-column justify-content-center align-items-center p-3"
      >
        <FontAwesomeIcon icon={icon} className="fs-4 mb-2" />
        <h5 className="mb-1">{title}</h5>
        <h6>{limit}</h6>
      </Button>

      <Modal
        isOpen={showModal}
        centered
        toggle={() => setShowModal(!showModal)}
      >
        <ModalHeader toggle={() => setShowModal(!showModal)}>
          {title}
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <ModalComponent
                {...modalProps}
                showModal={showModal}
                setShowModal={setShowModal}
              />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
};

const LinkOrMMS = ({
  showFileModal,
  setShowFileModal,
  uploadMMS,
  setUploadMMS
}) => {
  return (
    <Row className="justify-content-center mx-2 my-2">
      <Col xs={12} sm={4} className="mb-4">
        <ButtonModal
          icon="link"
          title="Insert download link"
          limit="10 MB limit"
          ModalComponent={InsertLink}
          modalProps={{ showFileModal, setShowFileModal }}
        />
      </Col>
      <Col xs={12} sm={4} className="mb-4">
        <ButtonModal
          icon="image"
          title="Send MMS"
          limit="3.5 MB limit"
          ModalComponent={InsertMMS}
          modalProps={{
            showFileModal,
            setShowFileModal,
            uploadMMS,
            setUploadMMS
          }}
        />
      </Col>
      <Col xs={12} sm={4} className="mb-4">
        <ButtonModal
          icon="video"
          title="Send Video"
          limit="50 MB limit"
          ModalComponent={InsertVideo}
          modalProps={{ showFileModal, setShowFileModal }}
        />
      </Col>
    </Row>
  );
};

export default LinkOrMMS;
