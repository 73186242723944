import { LIST_FOLLOW_UP_BILLING_DETAILS_REQUEST, LIST_FOLLOW_UP_BILLING_DETAILS_SUCCESS, LIST_FOLLOW_UP_BILLING_DETAILS_FAIL, LIST_FOLLOW_UP_BILLING_DETAILS_RESET } from '../actions/types';

const initialState = {
    billingDetails: [],
    loading: false,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_FOLLOW_UP_BILLING_DETAILS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LIST_FOLLOW_UP_BILLING_DETAILS_SUCCESS:
            return {
                ...state,
                billingDetails: action.payload,
                loading: false
            };
        case LIST_FOLLOW_UP_BILLING_DETAILS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        case LIST_FOLLOW_UP_BILLING_DETAILS_RESET:
            return initialState
        default:
            return state;
    }
}