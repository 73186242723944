import React, { useState } from 'react';
import { NewUserUploadContext } from '../../../context/Context';

const NewUserWizardProvider = ({ children }) => {
  const [upload, setUpload] = useState({
    fullName: '',
    phoneNumber: '',
    industry: '',
    companyName: '',
    companyWebsite: '',
    teamSize: '',
    receiveSMS: false
  });

  const [step, setStep] = useState(1);

  const [userFile, setUserFile] = useState();

  const handleInputChange = ({ value, name }) =>
    setUpload({ ...upload, [name]: value });

  const value = {
    upload,
    setUpload,
    step,
    setStep,
    userFile,
    setUserFile,
    handleInputChange
  };
  return (
    <NewUserUploadContext.Provider value={value}>
      {children}
    </NewUserUploadContext.Provider>
  );
};

export default NewUserWizardProvider;
