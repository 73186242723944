import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Label, Media } from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Details = ({
  from,
  to,
  direction,
  startTime,
  numSegments,
  endTime,
  callSid,
  status
}) => {
  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  const [cost, setCost] = useState();

  useEffect(() => {
    const startMillTime = new Date(startTime);
    const endMillTime = new Date(endTime);
    const millistart = startMillTime.getTime();
    const milliEnd = endMillTime.getTime();

    setCost(Math.ceil((milliEnd - millistart) / 60000));
  });

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-4">
              <Label>From</Label>
            </Col>
            <Col className="col-8">
              <SubtleBadge bg="primary" pill className="fs--1">
                {formatPhoneNumber(from)}
              </SubtleBadge>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-4">
              <Label>To:</Label>
            </Col>
            <Col className="col-8">
              <SubtleBadge bg="warning" pill className="fs--1">
                {formatPhoneNumber(to)}
              </SubtleBadge>
            </Col>
          </Row>
          {callSid && (
            <Row className="mt-2">
              <Col className="col-4">
                <Label>Direction:</Label>
              </Col>
              <Col className="col-8">
                <div>
                  <Media body className="ms-2 mt-1">
                    {direction === 'outbound-dial' ? (
                      <SubtleBadge bg="primary" pill className="fs-10">
                        <FontAwesomeIcon
                          icon="arrow-right"
                          transform="shrink-1"
                        />
                        {`  Outgoing`}
                      </SubtleBadge>
                    ) : (
                      <SubtleBadge bg="warning" pill className="fs-10">
                        <FontAwesomeIcon
                          icon="arrow-left"
                          transform="shrink-1"
                        />
                        {`  Incoming`}
                      </SubtleBadge>
                    )}
                  </Media>
                </div>
              </Col>
            </Row>
          )}
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Time:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs-9 mt-2">
                <Moment format="MM/DD/YYYY hh:mm A" date={startTime} />
              </h5>
            </Col>
          </Row>
          {callSid && (
            <Row className="mt-2">
              <Col className="col-4">
                <Label>Duration:</Label>
              </Col>
              <Col className="col-8">
                <h5 className="mb-0 fs-9 mt-2">
                  <Moment
                    format="hh:mm:ss"
                    duration={startTime}
                    date={endTime}
                  />
                </h5>
              </Col>
            </Row>
          )}
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Status</Label>
            </Col>
            <Col>
              <div className="d-flex ms-2 mt-1">
                {status === 'received' ||
                status === 'delivered' ||
                status === 'completed' ? (
                  <SubtleBadge color="soft-success" pill className="fs-10">
                    <FontAwesomeIcon
                      icon="check"
                      transform="shrink-1"
                      className=" me-1"
                    />
                    {status}
                  </SubtleBadge>
                ) : status === 'sent' ? (
                  <SubtleBadge color="soft-info" pill className="fs-10">
                    <FontAwesomeIcon
                      icon="circle"
                      transform="shrink-1"
                      className=" me-1"
                    />
                    {status}
                  </SubtleBadge>
                ) : (
                  <SubtleBadge bg="danger" pill className="fs-10">
                    <FontAwesomeIcon
                      icon="times"
                      transform="shrink-1"
                      className=" me-1"
                    />
                    {status}
                  </SubtleBadge>
                )}
              </div>
            </Col>
          </Row>
          {numSegments && (
            <Row className="mt-2">
              <Col className="col-4">
                <Label>Parts</Label>
              </Col>
              <Col>
                <div className="ms-2 mt-1">
                  <SubtleBadge bg="success" pill className="fs--1">
                    {numSegments}
                  </SubtleBadge>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Details;
