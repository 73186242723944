import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import NavbarTop from 'components/navbar/top/NavbarTop';
import NavbarVertical from 'components/navbar/vertical/NavbarVertical';
import Footer from 'components/footer/Footer';
import SocketProvider from 'providers/SocketProvider';
import ModalAuth from 'components/authentication/modal/ModalAuth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from 'actions/index';
import { listGroups, listFields } from 'actions/index';
import { CREATE_BULK_INITIAL_STATE } from 'actions/types';
import { useAppContext } from 'providers/AppProvider';
import LoadingScreen from 'components/common/LoadingScreen';
import { listFollowUpBillingDetails } from 'actions/followUpBillingActions';

const MainLayout = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes('kanban');

  useEffect(() => {
    const loadData = async () => {
      if (auth?.user?.isVerified) {
        // Fetch default data when no team
        await Promise.all([
          dispatch(listFields()),
          dispatch(listGroups()),
          dispatch(listFollowUpBillingDetails()),
        ]);
      }
      setLoading(false);
    };

    loadData();
  }, [auth?.user?.isVerified, dispatch]);



  const {
    config: { isFluid, navbarPosition }, isNavbarVisible
  } = useAppContext();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, [hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (auth?.user?.timezone) {
      dispatch({
        type: CREATE_BULK_INITIAL_STATE,
        payload: {
          timezone: auth.user.timezone,
        }
      });
    }
  }, [auth.loading, dispatch, auth.user.timezone]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className={isFluid ? 'container-fluid' : 'container'}>
      {(navbarPosition === 'vertical' || navbarPosition === 'combo') && isNavbarVisible && (
        <NavbarVertical />
      )}
      <SocketProvider>
        <div className={classNames('content', { 'pb-0': isKanban })}>
          {isNavbarVisible && <NavbarTop />}
          <Outlet />
          {!isKanban && <Footer />}
        </div>
      </SocketProvider>
      <ModalAuth />
    </div>
  );
};

export default MainLayout;
