import { ADD_EVENT, RESET_EVENT } from '../actions/types';

export default function eventReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_EVENT:
      return [...state, payload];

    case RESET_EVENT:
      return [];

    default:
      return state;
  }
}
