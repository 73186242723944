import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Flex from 'components/common/Flex';
import LoginForm from 'components/authentication/LoginForm';
import paths from 'routes/paths';
import { Alert } from 'react-bootstrap';

const Login = () => {
  const location = useLocation();
  const { teamId } = location.state || {};

  return (
    <>
      {teamId && (
        <Alert color="info">
          <h4 className="alert-heading">Your now a member!</h4>
          <p>
            You have been added to a Team. Log in to check out your account.
          </p>
        </Alert>
      )}
      <Flex justifyContent="between" alignItems="center" className="mb-2">
        <h5>Log in</h5>
        <p className="fs-10 text-600 mb-0">
          or <Link to={paths.simpleRegister}>Create an account</Link>
        </p>
      </Flex>
      <LoginForm />
    </>
  );
};

export default Login;
