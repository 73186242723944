import { LIST_INVOICES, LIST_INVOICES_FAILED } from '../actions/types';

// Initial state for the invoices table
const initialState = {
  invoices: [],
  pagination: {
    currentPage: 1,
    limit: 25,
    totalPages: 0,
    totalItems: 0
  },
  error: null,
  loading: false
};

// The invoices table reducer
const invoicesTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_INVOICES:
      console.log("action.payload", action.payload)
      return {
        ...state,
        loading: false,
        invoices: action.payload.invoices.map(el => {
          return { ...el, key: el._id };
        }),
        totalInvoices: action.payload.totalInvoices,
        error: null
      };
    case LIST_INVOICES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        invoices: [],
      };
    default:
      return state; // Always return the current state by default
  }
};

export default invoicesTableReducer;
