import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  deleteMultipleResponders,
  listResponders,
  updateResponder
} from 'actions/index';
import { ADD_RESPONDER, RESET_RESPONDER } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';

const RespondersTable = () => {
  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const autoResponders = useSelector(state => state.responders)
  const { responders: responders, loading: loadingResponders, totalItems: totalItems } = autoResponders;

  const responderDelete = useSelector(state => state.responderDelete);
  const { success: successDelete } = responderDelete;

  const responderUpdate = useSelector(state => state.responderUpdate);
  const { success: successUpdate } = responderUpdate;

  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [rowDelete, setRowDelete] = useState();

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original._id;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const titleFormatter = rowData => {
    const { title } = rowData.row.original;

    return (
      <Link
        onClick={() => {
          return dispatch({
            type: ADD_RESPONDER,
            payload: { responder: rowData.row.original }
          });
        }}
        tag={Link}
        to="/follow-up/responder-upload"
      >
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9">{title}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const keywordFormatter = rowData => {
    const { keyword } = rowData.row.original;
    return (
      <Media body className="ms-2">
        {keyword.map((el, i) => (
          <SubtleBadge key={i} bg="success" pill className="fs-9">
            {el.title}
          </SubtleBadge>
        ))}
      </Media>
    );
  };

  const typeFormatter = rowData => {
    const { responseType } = rowData.row.original;
    return (
      <Media body className="ms-2">
        <SubtleBadge bg="primary" pill className="fs-9">
          {responseType}
        </SubtleBadge>
      </Media>
    );
  };

  const statusFormatter = rowData => {
    const { isActive } = rowData.row.original;
    return (
      <Media body className="ms-2">
        {isActive === 'active' ? (
          <SubtleBadge bg="primary" pill className="fs-9">
            {isActive}
          </SubtleBadge>
        ) : (
          <SubtleBadge bg="warning" pill className="fs-9">
            {isActive}
          </SubtleBadge>
        )}
      </Media>
    );
  };

  const createdFormatter = rowData => {
    const { created } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">
            <Moment format="MM/DD/YYYY" date={created} />
          </h5>
        </Media>
      </Media>
    );
  };



  const actionFormatter = (rowData) => {
    const { _id, isActive } = rowData.row.original;

    const items = [
      {
        label: 'Edit',
        icon: 'edit',
        action: () => {
          return dispatch({
            type: ADD_RESPONDER,
            payload: { responder: rowData.row.original }
          }),
            navigate('/follow-up/responder-upload');
        }
      },
      {
        label: isActive === 'active' ? 'Pause' : 'Resume',
        icon: isActive === 'active' ? 'pause' : 'play',
        action: () => {
          if (isActive === 'active') {
            return dispatch(
              updateResponder(
                { ...rowData.row.original, isActive: 'paused' },
                _id
              )
            );
          } else {
            return dispatch(
              updateResponder(
                { ...rowData.row.original, isActive: 'active' },
                _id
              )
            );
          }
        }
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          return (
            setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)
          );
        }
      }
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
        />
      </div>
    );
  };


  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      Cell: titleFormatter
    },
    {
      accessor: 'keyword',
      Header: 'Keywords',
      Cell: keywordFormatter
    },
    {
      accessor: 'responseType',
      Header: 'Type',
      Cell: typeFormatter
    },
    {
      accessor: 'isActive',
      Header: 'Status',
      Cell: statusFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right'
    }
  ];

  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  const handleSearch = (value) => {
    dispatch(listResponders({ search: value, page: 1, limit: 50 }));
  };




  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    console.log("fetchData called")
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listResponders({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])


  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };



  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Card className="mb-3">
      <CardHeader>
        <div className="d-flex justify-content-end">
          <ButtonGroup className="mt-2">
            <ButtonIcon
              icon="plus"
              tag={Link}
              to="/follow-up/responder-upload"
              transform="shrink-3"
              className="btn-tertiary border-dark"
              size="sm"
            >
              Responder
            </ButtonIcon>
            <ButtonIcon
              icon="trash"
              onClick={() => {
                return setShowDeleteModal(!showDeleteModal);
              }}
              disabled={!isSelected.length}
              transform="shrink-3"
              className="btn-tertiary border-dark text-danger ms-2"
              size="sm"
            >
              Delete
            </ButtonIcon>
            <Modal
              isOpen={showDeleteModal}
              centered
              toggle={() => setShowDeleteModal(!showDeleteModal)}
            >
              <ModalHeader className="d-flex justify-content-center">
                Are you sure?
              </ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fs-9 fw-normal p-4">
                    <Button
                      onClick={() => {
                        if (rowDelete?.length) {
                          dispatch(deleteMultipleResponders(rowDelete));
                        } else {
                          dispatch(deleteMultipleResponders(isSelected));
                        }
                        setShowDeleteModal(!showDeleteModal);
                      }}
                      block
                      className="btn-tertiary border-dark text-danger"
                    >
                      Delete Responder
                    </Button>
                    <Button
                      onClick={() => {
                        return (
                          setShowDeleteModal(!showDeleteModal), setRowDelete([])
                        );
                      }}
                      block
                      className="btn-tertiary border mt-2"
                    >
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>
          </ButtonGroup>
        </div>
      </CardHeader>

      <CardBody className="p-0">
        {loadingResponders && (
          <div style={overlayStyle}>
            <Spinner animation="border" role="status" style={spinnerStyle}>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}
        <AdvanceTableWrapper
          columns={columns}
          data={responders}
          fetchData={fetchData}
          total={totalItems}
          sortable
          pagination
          perPage={10}
          selection
          selectionColumnWidth={25}
          selectionCallback={selectionCallback}
        >
          <Row>
            <Col md={8} lg={6}>
              <AdvanceTableSearchBox table
                placeholder="Search responders..."
                onSearch={handleSearch}
              />
            </Col>
          </Row>

          <AdvanceTable
            table
            headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
            rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs-9 fw-bold overflow-hidden'
            }}
          />
          <div className="mt-3 mb-2 pe-2 ps-3 ">
            <AdvanceTableFooter
              rowCount={totalItems}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </CardBody>
    </Card>
  );
};

export default RespondersTable;
