import { GROUP_CREATE_REQUEST, GROUP_CREATE_SUCCESS, GROUP_CREATE_FAIL, GROUP_CREATE_RESET } from '../actions/types';

export default function groupCreateReducer(state = {}, action) {
    switch (action.type) {
        case GROUP_CREATE_REQUEST:
            return { loading: true }
        case GROUP_CREATE_SUCCESS:
            return { loading: false, success: true, createdGroup: action.payload }
        case GROUP_CREATE_FAIL:
            return { loading: false, error: action.payload }
        case GROUP_CREATE_RESET:
            return {}
        default:
            return state
    }
}