import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Label, Card, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddContactsToGroup from './AddContactsToGroup';
import CustomerForm from 'components/customers/CustomerForm';

const GroupContactChoose = ({ GroupId, chooseImportModal, setChooseImportModal }) => {

  // State
  const [showContactModal, setShowContactModal] = useState(false);
  const [showExistingUserModal, setShowExistingUserModal] = useState(false);
  // Handler

  const closeContactBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowContactModal(!showContactModal) }}>
    </button>
  );

  const closeExistingBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowExistingUserModal(!showExistingUserModal) }}>
    </button>
  );

  return (
    <Fragment>
      <ButtonGroup className="btn-group-toggle btn-block">
        <Button onClick={() => { setShowContactModal(!showContactModal) }} className="btn-tertiary border-dark">
          <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="user-plus" className="mt-3 fs-4" />
            <h6 className="mt-2">Create a new contact</h6>
          </Label>
        </Button>

        <Button tag={Link} to="/follow-up/contacts-import" className="btn-tertiary border-dark">
          <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="file-alt" className="fs-4 mt-3" />
            <h6 className="mt-2" >Import from CSV</h6>
          </Label>
        </Button>

        <Button onClick={() => { setShowExistingUserModal(!showExistingUserModal) }} className="btn-tertiary border-dark">
          <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="users" className="fs-4 mt-3" />
            <h6 className="mt-2" >Add from existing</h6>
          </Label>
        </Button>

      </ButtonGroup>


      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={() => { setShowContactModal(!showContactModal) }} close={closeContactBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Create a new contact
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} groupId={GroupId} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal size="lg" isOpen={showExistingUserModal} centered toggle={() => setShowExistingUserModal(!showExistingUserModal)}>
        <ModalHeader toggle={() => { setShowExistingUserModal(!showExistingUserModal) }} close={closeExistingBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Choose from existing contacts
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-1">
              <AddContactsToGroup chooseImportModal={chooseImportModal} setChooseImportModal={setChooseImportModal} showExistingUserModal={showExistingUserModal} setShowExistingUserModal={setShowExistingUserModal} GroupId={GroupId} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </Fragment>


  );
};

GroupContactChoose.propTypes = {

  hasLabel: PropTypes.bool
};

GroupContactChoose.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default GroupContactChoose;