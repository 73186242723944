import React, { Fragment, useRef, useContext, useEffect, useState } from 'react';
import { Button, Nav, Card, Input, CardBody, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Scrollbar from 'react-scrollbars-custom';
import ChatContact from '../sidebar/ChatContact';
import CustomerForm from 'components/customers/CustomerForm';
import AppContext, { ChatContext } from '../../../context/Context';
import { isIterableArray } from '../../../helpers/utils';

const MsgContent = ({ showMessageModal, setShowMessageModal }) => {
  const { isRTL } = useContext(AppContext);
  const { threads, searchTerm, conversations, searchHandler, searchResults, primaryNumber } = useContext(ChatContext);
  const [showContactModal, setShowContactModal] = useState();

  const contactCreate = useSelector((state) => state.createContact);
  const { success: successContactCreate } = contactCreate;

  useEffect(() => {
    if (contactCreate.newContact) {
      const { blocked, created, fields, firstName, group, lastName, phone_number, _id } = contactCreate.newContact;
      searchResults.push({
        blocked,
        created,
        fields,
        firstName,
        group,
        lastName,
        name: `${firstName} ${lastName}`,
        phone_number,
        _id
      });
    }
  }, [successContactCreate, searchResults]);

  const inputEl = useRef("");

  return (
    <Fragment>
      <Card className="card-chat p-0">
        <Scrollbar
          style={{
            height: '100%',
            width: '100%'
          }}
          rtl={isRTL}
          noScrollX
          trackYProps={{
            renderer(props) {
              const { elementRef, ...restProps } = props;
              return <span {...restProps} ref={elementRef} className="TrackY" />;
            }
          }}
        >
          <div className="mx-2 my-2 border-0 contacts-search-wrapper d-flex justify-content-between">
            <Input
              innerRef={inputEl}
              value={searchTerm}
              onChange={(e) => searchHandler(e.target.value)} // Corrected line
              placeholder="To"
              className="p-2"
              bsSize="sm"
            />
            <Button onClick={() => setShowContactModal(!showContactModal)} className="ms-2 p-2 btn-tertiary border-dark text-primary" size="sm" id="call-tooltip">
              <FontAwesomeIcon icon="plus" />
            </Button>
          </div>
          <Nav className="border-0">
            {
              // push new contact
              isIterableArray(searchResults) && searchResults.map((contact) =>
                <ChatContact
                  showMessageModal={showMessageModal}
                  setShowMessageModal={setShowMessageModal}
                  contact={contact}
                  conversations={conversations}
                  primaryNumber={primaryNumber}
                  key={contact._id}
                />)
            }
          </Nav>
        </Scrollbar>
      </Card>
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={() => setShowContactModal(!showContactModal)} className="d-flex flex-between-center border-bottom-0">Add New Contact</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default MsgContent;
