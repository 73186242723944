import {
  DELETE_RECEIVED_MESSAGES_CREATE_REQUEST,
  DELETE_RECEIVED_MESSAGES_CREATE_SUCCESS,
  DELETE_RECEIVED_MESSAGES_CREATE_FAIL
} from '../actions/types';

export default function receivedHistoryDeleteReducer(state = {}, action) {
  switch (action.type) {
    case DELETE_RECEIVED_MESSAGES_CREATE_REQUEST:
      return { loading: true };
    case DELETE_RECEIVED_MESSAGES_CREATE_SUCCESS:
      return { loading: false, success: true };
    case DELETE_RECEIVED_MESSAGES_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
