import {
  NUMBER_UPDATE_REQUEST,
  NUMBER_UPDATE_SUCCESS,
  NUMBER_UPDATE_FAIL,
  NUMBER_UPDATE_RESET
} from '../actions/types';

export default function numberUpdateReducer(state = {}, action) {
  switch (action.type) {
    case NUMBER_UPDATE_REQUEST:
      return { loading: true };
    case NUMBER_UPDATE_SUCCESS:
      return { loading: false, success: true, contactInfo: action.payload };
    case NUMBER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case NUMBER_UPDATE_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
