import React, { useEffect, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters,
} from 'react-table';
import { Input } from 'reactstrap'
import { useSticky } from 'react-table-sticky'
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_SELECTED_CONTACT_ROWS, UPDATE_SELECTED_ADD_BLOCKED_ROWS } from 'actions/types';
import { listContactsTotal } from 'actions';



const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="form-check">
        <Input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
      </div>
    );
  }
);


const AdvanceContactsTableWrapper = ({
  children,
  columns,
  fetchData,
  sortable,
  selection,
  selectionColumnWidth,
  pagination,
  data,
  perPage = 10,
  total,
  isAddBlockedTable
}) => {

  const selectedContactsTableRowIds = useSelector((state) => state.contactsTableRow.selectedRowIds || {});
  const selectedAddBlockedTableRowIds = useSelector((state) => state.selectedAddBlockedRows.selectedRowIds || {});

  let rowIds
  if (isAddBlockedTable) {
    rowIds = selectedAddBlockedTableRowIds
  } else {
    rowIds = selectedContactsTableRowIds
  }

  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {

    const count = preFilteredRows?.length

    return (
      <Input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }

  const defaultColumn = useMemo(
    () => ({
      Filter: DefaultColumnFilter,

    }),
    []
  )

  // Use perPage (initial page size) to compute initial pageCount
  const initialPageSize = pagination ? perPage : data.length;
  const initialPageCount = Math.ceil(total / initialPageSize);




  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    selectedFlatRows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, filters, sortBy },
    setGlobalFilter,

  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortBy: !sortable,
      manualPagination: true,
      manualSortBy: true,
      autoResetPage: false,
      initialState: {
        pageSize: pagination ? perPage : data.length,
        selectedRowIds: rowIds, // Initialize with Redux selections
      },
      pageCount: initialPageCount, // Pass initial pageCount here,
      autoResetSelectedRows: false,
      getRowId: row => row._id

    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
              return (
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                /> //getToggleAllPageRowsSelectedProps was getToggleAllRowsSelectedProps
              );
            },
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                {row?.original?.title === 'unsubscribers' ? (
                  ''
                ) : (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                )}
              </div>
            )
          },

          ...columns
        ]);
      }
    }
  );

  const dispatch = useDispatch()
  const contactCreate = useSelector((state) => state.createContact)
  const { success: successCreate } = contactCreate

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDelete } = contactDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success: successUpdate } = contactUpdate

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successUpdateGroup } = groupUpdate


  // Listen for changes in pagination and use the state to fetch our new data
  useEffect(() => {
    fetchData({ pageIndex, pageSize, sortBy }) // this is listContacts in action creator
    dispatch(listContactsTotal())
  }, [pageIndex, pageSize, sortBy, successCreate, successDelete, successBlockedCreate, successUpdate, successUpdateGroup])

  useEffect(() => {
    // When sortBy changes, reset the pageIndex to 0
    gotoPage(0);
  }, [sortBy, gotoPage]);



  useEffect(() => {
    if (isAddBlockedTable) {
      dispatch({ type: UPDATE_SELECTED_ADD_BLOCKED_ROWS, payload: { selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows } })
    } else {
      dispatch({ type: UPDATE_SELECTED_CONTACT_ROWS, payload: { selectedRowIds: selectedRowIds, selectedFlatRows: selectedFlatRows } })
    }
  }, [selectedRowIds])


  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            data,
            total,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            setPageSize,
            pageIndex,
            pageSize,
            pageCount: Math.ceil(total / pageSize),
            selectedRowIds,
            selectedFlatRows,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  return (
    <>{recursiveMap(children)}</>
  );
};

export default AdvanceContactsTableWrapper;