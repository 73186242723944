import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import AddBlockedTable from './AddBlockedTable';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import Divider from 'components/common/Divider';
import SubtleBadge from 'components/common/SubtleBadge';
import ContactEditModal from './ContactEditModal';
import { listBlockedContacts, deleteMultipleContacts, unBlockContacts, updateContact } from 'actions/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { BLOCKED_CONTACT_CREATE_RESET, BLOCKED_CONTACT_DELETE_RESET, RESET_SELECTED_ADD_BLOCKED_ROWS } from 'actions/types';



const Blocked = () => {

  const windowSize = useWindowDimensions()
  const dispatch = useDispatch();
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [showEditBlockedModal, setShowEditBlockedModal] = useState(false);
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [changeText, setChangeText] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [contact, setContact] = useState({});
  const [rowDelete, setRowDelete] = useState()
  const [tempContact, setTempContact] = useState()
  const [blocked, setBlocked] = useState({
    phoneNumber: '',
    country: ''
  })
  const [rowBlock, setRowBlock] = useState()

  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setRowDelete([]) },
    [setIsSelected]);


  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const allBlocked = useSelector((state) => state.blockedContacts)
  const { contacts: blockedContacts, loading: loadingBlockedContacts, totalItems: totalBlockedItems } = allBlocked
  const conversations = useSelector((state) => state.conversations)


  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success: successBlockedDelete } = blockedContactDelete

  const contactCreate = useSelector((state) => state.createContact)
  const { success: successCreate } = contactCreate

  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDelete } = contactDelete

  const reduxSelectedRows = useSelector((state) => state.selectedRows)



  useEffect(() => {
    dispatch(listBlockedContacts())

    setRowBlock([])
  }, [successBlockedCreate, successBlockedDelete, successDelete])

  const openGroups = []
  groups?.map((g) => { if (!tempContact?.group.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })

  const contactFields = fields?.map((field) => {
    return { title: field.title, body: tempContact?.fields[field.title] }
  })


  const toggle = () => {
    setShowBlockedModal(!showBlockedModal)
  }

  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }

  const handleEdit = () => {
    setShowEditBlockedModal(!showEditBlockedModal)
  }

  const handleChange = e => {
    setBlocked({ ...blocked, [e.target.name]: e.target.value })
  }



  const firstNameFormatter = (rowData, { avatar }) => {
    const { firstName, _id } = rowData.row.original
    return (
      <Link to={`/follow-up/contact/${_id}`}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2 mt-2">
            <h5 className="mb-0 fs-9">{firstName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { lastName, _id } = rowData.row.original
    return (
      <Link to={`/follow-up/contact/${_id}`}>
        <Media body className="ms-2" tag={Flex} align="center">
          <h5 className="mt-2 fs-9">{lastName}</h5>
        </Media>
      </Link>
    );
  };

  const numberFormatter = (rowData, { avatar }) => {
    const { phone_number } = rowData.row.original

    return (

      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-1">
          <SubtleBadge pill className="fs-9">
            {phone_number}
            <FontAwesomeIcon icon="ban" transform="shrink-1" className="ms-1" />
          </SubtleBadge>
        </Media>
      </Media>
    );
  };

  const dateBlockedFormatter = (rowData) => {
    const { blockedDate } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={blockedDate} /></h5>
        </Media>
      </Media>
    )
  };

  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original
    return (
      // Control your row with this id // there was a : after {id} now its a ,
      <div className="d-flex justify-content-end">
        <UncontrolledDropdown>
          <DropdownToggle size="sm" className="text-600 bg-light border-dark">
            <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
          </DropdownToggle>
          <DropdownMenu className="border py-2 me-6">
            <DropdownItem onClick={() => { return setTempContact(rowData.row.original), setShowContactUpdateModal(!showContactUpdateModal) }} ><FontAwesomeIcon icon="edit" />{` Edit contact`}</DropdownItem>
            <DropdownItem onClick={() => { return setRowBlock([_id]), setShowUnblockModal(!showUnblockModal) }} ><FontAwesomeIcon icon="ban" />{` Unblock contact`}</DropdownItem>
            <DropdownItem onClick={() => { return setRowDelete([_id]), setShowDeleteModal(!showDeleteModal) }} className="text-danger"><FontAwesomeIcon icon="trash" />{` Delete contact`}</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

    )
  };



  const columns = [
    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter

    },
    {
      accessor: 'phone_number',
      Header: 'Phone number',
      Cell: numberFormatter

    },
    {
      accessor: 'blockedDate',
      Header: 'Date blocked',
      Cell: dateBlockedFormatter

    },
    {
      accessor: 'Country',
      Header: 'Country',

    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: windowSize.width < 650 ? "right" : undefined,

    }
  ];






  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listBlockedContacts({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])


  useEffect(() => {
    if (successBlockedCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: BLOCKED_CONTACT_CREATE_RESET }); // Replace with your actual action to reset successUpdate
      dispatch({ type: RESET_SELECTED_ADD_BLOCKED_ROWS })
    }
  }, [successBlockedCreate, fetchData, dispatch]);


  useEffect(() => {
    if (successBlockedDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: BLOCKED_CONTACT_DELETE_RESET }); // Replace with your actual action to reset successUpdate
      dispatch({ type: RESET_SELECTED_ADD_BLOCKED_ROWS })
    }
  }, [successBlockedDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listBlockedContacts({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };





  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon icon="plus" onClick={() => { setShowBlockedModal(true) }} transform="shrink-2" className="btn-tertiary border-dark mx-1" size="sm">
                Add blocked number
              </ButtonIcon>

              <ButtonIcon icon="ban" disabled={!isSelected.length} onClick={() => { return setShowUnblockModal(!showUnblockModal) }} transform="shrink-2" className="btn-tertiary border-dark text-danger mx-1" size="sm" >
                Unblock
              </ButtonIcon>

            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingBlockedContacts && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={blockedContacts}
            fetchData={fetchData}
            total={totalBlockedItems}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}

          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  onSearch={handleSearch}
                  placeholder="Search contacts..."
                  table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 font-weight-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalBlockedItems}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>





      <Modal size='lg' backdrop="static" isOpen={showBlockedModal} centered toggle={() => setShowBlockedModal(!showBlockedModal)}>
        <ModalHeader toggle={toggle} className="text-center d-flex flex-between-center border-bottom-0">
          Add blocked number
        </ModalHeader>
        <ModalBody className="p-0">

          <AddBlockedTable />

        </ModalBody>
      </Modal>




      <Modal isOpen={showEditBlockedModal} centered toggle={() => setShowEditBlockedModal(!showEditBlockedModal)}>
        <ModalHeader className="text-center d-flex flex-between-center border-bottom-0">
          Update blocked number
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Form onSubmit={handleEdit}>
                <Label for="title">Phone number:</Label> {
                }
                <Input value={blocked.phoneNumber.value} onChange={handleChange} className="mb-3" name="title" id="title" />

                <Button block className="btn-tertiary border-dark text-primary" type="submit">Save</Button>
              </Form>
              <Button block onClick={() => { setShowEditBlockedModal(false) }}>Close</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // Unblock contact modal ----------------------------------
      }

      <Modal isOpen={showUnblockModal} centered toggle={() => setShowUnblockModal(!showUnblockModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label className="center">You will be able to recieve phone calls and messages from this contact</Label>
              <Divider></Divider>
              <Button onClick={() => {
                if (rowBlock?.length) {
                  return dispatch(unBlockContacts(rowBlock)),
                    setShowUnblockModal(!showUnblockModal)
                } else {
                  const IdsToBlock = reduxSelectedRows.map((el) => { return el.original._id })
                  return dispatch(unBlockContacts(IdsToBlock)),
                    setShowUnblockModal(!showUnblockModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger" >Unblock</Button>
              <Button onClick={() => { setShowUnblockModal(!showUnblockModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // update Contact Modal
      }
      <Modal size="lg" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
        <ModalHeader toggle={toggleSave} className="border-bottom-0">
          <ButtonIcon
            onClick={() => { return dispatch(updateContact(contact, tempContact._id)), setShowContactUpdateModal(!showContactUpdateModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <ContactEditModal
                contact={contact}
                ContactId={tempContact?._id}
                changeText={changeText}
                setContact={setContact}
                openGroups={openGroups}
                successCreate={successCreate}
                contactGroups={tempContact?.group}
                contactFields={contactFields}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Divider></Divider>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal) }} block className="btn-tertiary border-dark text-danger">Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // delete modal
      }
      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 font-weight-normal p-4">
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(deleteMultipleContacts(rowDelete, conversations)),
                    setShowDeleteModal(!showDeleteModal)
                } else {
                  return dispatch(deleteMultipleContacts(isSelected, conversations)),
                    setShowDeleteModal(!showDeleteModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger">Delete contacts</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

    </Fragment>
  );
};



export default Blocked;
