import {
  LIST_SENT_MESSAGES_REQUEST,
  LIST_SENT_MESSAGES_SUCCESS,
  LIST_SENT_MESSAGES_FAIL,
} from '../actions/types';

const initialState = {
  messages: [],
  totalMessages: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};


export default function sentMessagesReducer(state = initialState, action) {
  switch (action.type) {

    case LIST_SENT_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SENT_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload?.messages,
        loading: false,
        totalMessages: action.payload?.totalMessages
      };

    case LIST_SENT_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

