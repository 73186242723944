import React, { useEffect, Fragment, useState, useRef, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { useSelector, useDispatch } from 'react-redux';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTableWrapper from '../common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import MessageDetails from './MessageDetails';
import TemplateCreator from 'components/templates/TemplateCreator';
import ContactEditModal from 'components/contacts/ContactEditModal';
import { toast } from 'react-toastify';
import { DateTime } from "luxon";
import { listReceivedMessages, listReceivedMessagesDateRange, listReceivedMessagesCustomDateRange, contactById } from 'actions/index';
import { createChat } from 'actions/chatActions';
import { deleteBulkSend } from 'actions/bulkSendActions';
import ActionPopover from 'components/common/ActionPopover';
import { UPDATE_ACTIVE_CHAT, TEMPLATE_CREATE_RESET, SET_CONTACTS } from 'actions/types';
import CustomerForm from 'components/customers/CustomerForm';
import { updateContact } from 'actions/index';
import DatePicker from 'react-datepicker';

const ReceivedTable = ({ fromHistory }) => {

  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const usersReceivedMessages = useSelector((state) => state.receivedMessages)
  const { messages: receivedMessages, loading: loadingReceivedMessages, totalMessages: totalMessages } = usersReceivedMessages
  const chats = useSelector((state) => state.chats)
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);

  const primaryNumber = useSelector((state) => state.primaryNumber)

  const reduxSelectedRows = useSelector((state) => state.selectedReceivedRows)

  const contactCreate = useSelector((state) => state.createContact)
  const { success: successCreate } = contactCreate

  const sentHistoryDelete = useSelector((state) => state.sentHistoryDelete)
  const { success: successDelete } = sentHistoryDelete

  const bulkSendDelete = useSelector((state) => state.bulkSendDelete)
  const { success: successBulkSendDelete } = bulkSendDelete

  const templateCreate = useSelector((state) => state.createTemplate)
  const { success: successTemplateCreate } = templateCreate

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone)

  const isFirstRender = useRef(true);



  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDeleteBulkSendModal, setShowDeleteBulkSendModal] = useState(false)
  const [showMessageDetailModal, setShowMessageDetailModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [message, setMessage] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [dateRange, setDateRange] = useState("All messages")
  const [customDateModal, setCustomDateModal] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [datesObj, setDatesObj] = useState({ start: "", end: "" })
  const [temporaryId, setTemporaryId] = useState()
  const [newContactInfo, setNewContactInfo] = useState()
  const [changeText, setChangeText] = useState();
  const [contact, setContact] = useState({});
  const [openPopoverId, setOpenPopoverId] = useState(null);
  // Pagination state
  const pageSize = useSelector(state => state.paginationRedux.pageSize);



  const openContactUpdateModal = async (contactId) => {
    if (!fieldsLoading) {
      const contactData = await dispatch(contactById(contactId));
      const contactFields = fields?.map((field) => ({
        _id: field._id,
        title: field.title,
        value: contactData?.fields?.find(f => f._id === field._id)?.value || "",
      }));

      setContact({ ...contactData, fields: contactFields });
      setShowUpdateContactModal(true);
    } else {
      toast.error('Please wait. Loading');
    }
  };


  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { if (fr.original.sid) { return fr.original.sid } else { return { messageIds: fr.original.messageIds, _id: fr.original._id } } })), setRowDelete([]) },
    [setIsSelected]);



  const closeTemplateBtn = (<button className="btn-close bg-light" onClick={() => { setShowTemplateModal(!showTemplateModal) }}></button>);
  const closeDateRangeBtn = (<button className="btn-close bg-light" onClick={() => { setCustomDateModal(!customDateModal) }}></button>);
  const closeContactBtn = (<button className="btn-close bg-light" onClick={() => { return setShowContactModal(!showContactModal) }}></button>);
  const closeMsgDetailsBtn = (<button className="btn-close bg-light" onClick={() => { setShowMessageDetailModal(!showMessageDetailModal) }}></button>);

  const openGroups = []
  groups.map((g) => { if (!contact?.group?.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })
  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const fromFormatter = (rowData) => {
    const { from, name, messageIds } = rowData.row.original
    return (
      <div>

        <Media
          tag={Flex} align="center">
          <Media body className="ms-2 cursor-pointer">
            <h5 className="mb-0 fs-9">{name?.firstName && name?.lastName ? name?.firstName + " " + name?.lastName : name?.firstName ? name?.firstName : from}</h5>
          </Media>
        </Media>

      </div>
    );
  };

  const bodyFormatter = (rowData) => {
    const { body, numMedia, messageBody } = rowData.row.original;
    const text = messageBody || body || '';

    const truncatedText = text.length > 20 ? text.substring(0, 20) + '...' : text;

    return (
      <Link to="#!" onClick={() => { if (!messageBody) { setMessage(rowData.row.original); setShowMessageDetailModal(true); } }}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2 mt-1">
            {numMedia === "1" ? (
              <SubtleBadge color="primary" pill className="fs-9">
                MMS message
                <FontAwesomeIcon icon="image" transform="shrink-1" className=" ms-1" />
              </SubtleBadge>
            ) : (
              <h5 className="mb-0 fs-9 fw-light">{truncatedText}</h5>
            )}
          </Media>
        </Media>
      </Link>
    );
  };


  const statusFormatter = (rowData) => {
    const { status, messageIds, messageBody, errorMessage } = rowData.row.original
    let percentage
    if (messageIds) {
      const received = messageIds.filter((el) => el.status === "received")
      percentage = (received.length / messageIds.length) * 100
    }
    return (
      <div>
        {messageIds ? (
          <div>
            <Media body className="ms-2 mt-1">
              <SubtleBadge bg="success" pill className="fs-9">
                {`${percentage.toFixed(1)}% received`}
                <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
              </SubtleBadge>
            </Media>
          </div>
        ) : (
          <Link to="#!" onClick={() => { if (!messageBody) { setMessage(rowData.row.original); setShowMessageDetailModal(true); } }}>
            <Media body className="ms-2 mt-1">
              {status === "received" ?
                <SubtleBadge bg="success" pill className="fs-9">
                  {status}
                  <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
                </SubtleBadge> : status === "failed" ?
                  <SubtleBadge bg="danger" pill className="fs-9">
                    {status}
                    <FontAwesomeIcon icon="times" transform="shrink-1" className="ms-1" />
                  </SubtleBadge> : status !== "received" && !errorMessage ?
                    <SubtleBadge bg="info" pill className="fs-9">
                      {status}
                      <FontAwesomeIcon icon="times" transform="shrink-1" className="ms-1" />
                    </SubtleBadge> : errorMessage ?
                      <SubtleBadge bg="danger" pill className="fs-9">
                        {errorMessage}
                        <FontAwesomeIcon icon="times" transform="shrink-1" className="ms-1" />
                      </SubtleBadge> : ""
              }
            </Media>

          </Link>
        )}
      </div>
    );
  };

  const dateSentFormatter = (rowData) => {
    const { dateSent } = rowData.row.original;

    return (
      <div>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9 mt-2">
              <Moment format="MM/DD/YY" date={dateSent} />
            </h5>
            <h6 className="mb-0 fs--1 mt-1 text-muted">
              <Moment format="hh:mm A" date={dateSent} />
            </h6>
          </Media>
        </Media>
      </div>
    );
  };




  const actionFormatter = (rowData) => {
    const { from, name, body, sid, _id } = rowData.row.original;
    const composeContact = {
      id: name ? name._id : "",
      original: {
        firstName: name ? name.firstName : from,
        lastName: name ? name.lastName : "",
        phone_number: name ? name.phone_number : from,
        _id: name ? name._id : ""
      }
    };

    const items = [
      {
        label: 'Go to chat',
        icon: 'sms',
        action: async () => {
          const formatFrom = from.replace(/\D/g, '');
          const doesExist = chats.filter((chat) => chat.phone === formatFrom)[0];

          if (doesExist) {
            dispatch({ type: UPDATE_ACTIVE_CHAT, payload: doesExist._id });
          } else {
            const contact = { phone_number: formatFrom };

            const newChatId = await dispatch(createChat(contact, primaryNumber));
            if (newChatId) {
              dispatch({ type: UPDATE_ACTIVE_CHAT, payload: newChatId });
            }
          }
          navigate('/follow-up/chat');
        }
      },
      {
        label: !name ? 'Create contact' : 'Contact details',
        icon: !name ? 'user-plus' : 'user',
        action: () => {
          if (!name) {
            setNewContactInfo(from);
            setShowContactModal(!showContactModal);
          } else {
            openContactUpdateModal(name?._id); // this is contact._id
          }
        },
        className: !name ? 'text-success' : ''
      },
      {
        label: 'Send SMS to this contact',
        icon: 'reply',
        action: () => {
          dispatch({ type: SET_CONTACTS, payload: [composeContact] });
          navigate('/');
        }
      },
      {
        label: 'Message Details',
        icon: 'eye',
        color: 'info',
        action: () => {
          setMessage(rowData.row.original);
          setShowMessageDetailModal(!showMessageDetailModal);
        }
      },
      {
        label: 'Save as new Template',
        icon: ['fab', 'trello'],
        action: () => {
          setTemporaryId({ body: body, _id: _id });
          setShowTemplateModal(!showTemplateModal);
        }
      },
      {
        label: 'Delete Message',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([sid]);
          setShowDeleteModal(!showDeleteModal);
        },
        className: 'text-danger'
      }
    ].filter(Boolean);

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={sid}
          items={items}
          isOpen={openPopoverId === sid}
          toggle={() => setOpenPopoverId(openPopoverId === sid ? null : sid)}
        />
      </div>
    );
  };










  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'dateSent',
      Header: 'Sent time',
      Cell: dateSentFormatter,
    },
    {
      accessor: 'to',
      Header: 'To',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden',
        },
      },
      Cell: fromFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden',
        },
      },
    },
    {
      accessor: 'body',
      Header: 'Body',
      Cell: bodyFormatter,

    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: statusFormatter,
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',
    },
  ];


  //--------------------------------------------------------------------------Handlers------------------------------------------------------------------------------------





  const handleDateChange = target => {
    let name = target.name
    const selectedDateISO = DateTime.fromISO(target.value)
    const timeObj = { year: selectedDateISO.c.year, month: selectedDateISO.c.month, day: selectedDateISO.c.day, hour: selectedDateISO.c.hour, minute: selectedDateISO.c.minute }
    let TzdateTime = DateTime.fromObject(timeObj, { zone: timezone }).toISO()
    setDatesObj({ ...datesObj, [name]: TzdateTime })
  };



  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  useEffect(() => {
    setRowDelete([])
  }, [])


  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (dateRange !== "All messages" && dateRange !== "custom") {
      dispatch(listReceivedMessagesDateRange({
        dateRange,
        page: 1,
        limit: pageSize,
        sortBy: 'dateSent',
        sortOrder: -1,
      }));
    } else if (dateRange === "All messages") {
      dispatch(listReceivedMessages({
        search: "",
        limit: pageSize,
        sortBy: "dateSent",
        sortOrder: -1,
      }));
    }
  }, [dateRange, successCreate, successDelete]);




  useEffect(() => {
    if (successTemplateCreate) {
      toast(
        <Fragment>
          <strong>Template Created</strong>
        </Fragment>,
        {
          autoCLose: 2000
        }
      );
      dispatch({ type: TEMPLATE_CREATE_RESET })
    }
  }, [successTemplateCreate]);


  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowUpdateContactModal(!showUpdateContactModal)

  }


  const handleSearch = (value) => {

    dispatch(listReceivedMessages({ search: value, page: 1, limit: pageSize }));

  };




  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'dateSent'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listReceivedMessages({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])



  const years = Array.from(
    new Array(101),
    (_, index) => new Date().getFullYear() - 100 + index
  );

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];


  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    setIsMobileDevice(mobile);
  }, []);




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };




  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon onClick={() => { dispatch({ type: SET_CONTACTS, payload: reduxSelectedRows.map((el) => { return { id: el.name._id, original: el.name } }) }) }} icon="sms" disabled={!isSelected.length} transform="shrink-3" className="btn-tertiary border-dark" size="sm">
                Send SMS
              </ButtonIcon>
              <ButtonIcon icon="trash" onClick={() => { return setShowDeleteBulkSendModal(!showDeleteBulkSendModal) }} disabled={!isSelected.length} transform="shrink-1" className="btn-tertiary border-dark text-danger mx-2" size="sm" >
                Delete
              </ButtonIcon>

            </ButtonGroup>
            <Dropdown isOpen={isOpen} toggle={() => { setIsOpen(!isOpen) }}>
              <DropdownToggle className='btn-tertiary border-dark fs-10 mt-2' color="falcon-warning" > <FontAwesomeIcon icon="calendar-alt" transform="shrink-3" className="me-1" />Date Range</DropdownToggle>
              <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="All messages">{`All messages`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Today">{`Today`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Yesterday">{`Yesterday`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This week">{`This week`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This month">{`This month`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last month">{`Last month`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last 2 months">{`Last 2 months`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange("custom"), setCustomDateModal(!customDateModal) }}>{`Custom date range`}</DropdownItem>


              </DropdownMenu>
            </Dropdown>
          </div>
        </CardHeader>
        <Fragment>

        </Fragment>
        <CardBody className="p-0">
          {loadingReceivedMessages && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}

          <AdvanceTableWrapper
            columns={columns}
            data={receivedMessages}
            fetchData={fetchData}
            total={totalMessages}
            pageCount={1}
            sortable
            pagination
            perPage={10}
            selection
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox table
                  placeholder="Search messages..."
                  onSearch={handleSearch}
                />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                className: 'fs-9 fw-bold overflow-hidden'
              }}
            />

            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalMessages}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>



      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 fw-normal p-4">
              <Button onClick={() => { if (rowDelete?.length) { dispatch(deleteBulkSend(rowDelete)) } else { dispatch(deleteBulkSend(isSelected)) } setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-danger">Delete Message</Button>
              <Button onClick={() => { return setShowDeleteModal(!showDeleteModal), setRowDelete([]) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      {
        // Message Details Modal
      }
      <Modal isOpen={showMessageDetailModal} centered toggle={() => setShowMessageDetailModal(!showMessageDetailModal)}>
        <ModalHeader className="d-flex justify-content-center" close={closeMsgDetailsBtn} >Message Details</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 fw-normal p-4">
              <MessageDetails message={message} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // create contact modal
      }
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={() => { return setShowContactModal(!showContactModal) }} className="d-flex flex-between-center border-bottom-0" close={closeContactBtn}>
          New Contact
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} newContactInfo={newContactInfo} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // edit contact modal
      }
      <Modal isOpen={showUpdateContactModal} centered toggle={() => setShowUpdateContactModal(!showUpdateContactModal)}>
        <ModalHeader toggle={toggleSave} className="border-bottom-0">
          <ButtonIcon
            onClick={() => { return dispatch(updateContact(contact, contact?._id)), setShowUpdateContactModal(!showUpdateContactModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <ContactEditModal
                contact={contact}
                changeText={changeText}
                setContact={setContact}
                openGroups={openGroups}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // show template modal
      }
      <Modal isOpen={showTemplateModal} centered toggle={() => { return setShowTemplateModal(!showTemplateModal) }}>
        <ModalHeader close={closeTemplateBtn}>Design your template</ModalHeader>
        <ModalBody className="p-0">
          <TemplateCreator temporaryId={temporaryId} showTemplateModal={showTemplateModal} setShowTemplateModal={setShowTemplateModal} fromHistory={fromHistory} />
        </ModalBody>
      </Modal>
      {
        // custom date range modal
      }
      <Modal isOpen={customDateModal} centered toggle={() => setCustomDateModal(!customDateModal)}>
        <ModalHeader toggle={() => setCustomDateModal(!customDateModal)}>Set Date Range</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputGroup>
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                      handleDateChange({
                        name: 'start',
                        value: date.toISOString(),
                      });

                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select Start Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>


            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputGroup >
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  autocomplete="off"
                  selected={endDate}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                      handleDateChange({
                        name: 'end',
                        value: date.toISOString(),
                      });
                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select End Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>



            <div className="d-flex justify-content-end mt-4">
              <Button
                color="secondary"
                onClick={() => setCustomDateModal(!customDateModal)}
                className="me-2"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(listReceivedMessagesCustomDateRange({
                    dateRange: { start: startDate, end: endDate },
                    page: 1,
                    limit: pageSize,
                    sortBy: 'dateSent',
                    sortOrder: -1,
                  }));
                  setCustomDateModal(!customDateModal);
                }}
                disabled={!datesObj.start || !datesObj.end}
              >
                Search
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>


      {
        // Save Modal
      }
      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowUpdateContactModal(!showUpdateContactModal) }} block className="btn-tertiary border-dark text-danger" >Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};


export default ReceivedTable;
