import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  forwardRef
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from 'components/common/Divider';
import Select from 'react-select';
import Moment from 'react-moment';
import momentt from 'moment-timezone';
import { ResponderUploadContext } from 'context/Context';
import makeAnimated from 'react-select/animated';
import { toast } from 'react-toastify';
import { listGroups, createGroup } from 'actions/index';
import DatePicker from 'react-datepicker';
import AppContext from 'context/Context';
import { GROUP_CREATE_RESET } from 'actions/types';

const StepTwoForm = ({ register, errors, weekDays }) => {
  const { upload, setUpload, handleInputChange } = useContext(
    ResponderUploadContext
  );
  console.log("updload in step2Form", upload)
  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [newGroup, setNewGroup] = useState({
    title: ''
  });
  const dayNames = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ];
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  const responderDetails = useSelector(state => state.responderDetails);
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const auth = useSelector(state => state.auth);
  const groupCreate = useSelector(state => state.groupCreate);
  const { success: successCreate } = groupCreate;

  const { isDark } = useContext(AppContext);

  const closeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setCreateGroupModal(!createGroupModal);
      }}
    ></button>
  );

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      // match with the menu
      borderColor: isDark ? 'dark' : 'light'
      // Removes weird border around container
    }),
    input: base => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: base => ({
      ...base,
      color: '#2c7be5',
      fontWeight: 'bold'
    }),
    option: (base, state) => ({
      ...base,
      color: '#d8e2ef', //
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: state.isFocused ? '#2c7be5' : '#0b1727'
    }),
    singleValue: base => ({
      // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: 'bold'
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      background: isDark ? 'dark' : 'light',
      // kill the gap
      marginTop: 15,
      zIndex: 999
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option

      background: isDark ? 'dark' : 'light'
    })
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createGroup(newGroup));
    setCreateGroupModal(false);
  };

  const handleChange = e => {
    setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    dispatch(listGroups());
  }, [successCreate]);

  useEffect(() => {
    if (!successCreate) {
      return;
    } else {
      toast(
        <Fragment>
          <strong>Group Created</strong>
        </Fragment>
      );
      dispatch({ type: GROUP_CREATE_RESET });
    }
  }, [successCreate]);


  return (
    <Fragment>
      <h5 className="mb-1 text-center">
        When this keyword is triggered, Should we add the contact who replied to
        a group?
      </h5>
      <Divider className="mt-4 fs-2">
        <strong style={{ color: 'green' }}>(Add to Group)</strong>
      </Divider>

      <Select
        name="groupAdd"
        required={true}
        className="mb-3"
        styles={customStyles}
        defaultValue={
          responderDetails?.length
            ? responderDetails[0]?.responder?.group?.map(el => {
              return { title: el.title, _id: el._id };
            })
            : ''
        }
        components={animatedComponents}
        closeMenuOnSelect={true}
        options={groups}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ _id }) => _id}
        onChange={group => {
          setUpload({ ...upload, group: group === null ? [] : group });
        }}
        isMulti
        placeholder="select Groups"
        isSearchable={true}
        errors={errors}
      />

      <Button
        onClick={() => {
          return setCreateGroupModal(!createGroupModal);
        }}
        className="btn-tertiary border-dark mb-3"
      >
        <FontAwesomeIcon icon="users" />
        {`+ New group`}
      </Button>

      <h5 className="mb-1 text-center">
        When this keyword is triggered, Should we remove the contact from a
        group?
      </h5>
      <Divider className="mt-4 fs-2">
        <strong style={{ color: 'red' }}>(Remove from Group)</strong>
      </Divider>

      <Select
        name="groupRemove"
        className="mb-3"
        styles={customStyles}
        defaultValue={
          responderDetails?.length
            ? responderDetails[0]?.responder?.groupRemove?.map(el => {
              return { title: el.title, _id: el._id };
            })
            : ''
        }
        components={animatedComponents}
        closeMenuOnSelect={true}
        options={groups}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ _id }) => _id}
        onChange={group =>
          setUpload({ ...upload, groupRemove: group === null ? [] : group })
        }
        isMulti
        placeholder="select Groups"
        isSearchable={true}
      />

      <h5 className="mb-1 mt-2 text-center">
        When do you want this responder to be active?
      </h5>
      <Divider className="mt-4 fs-2">
        Select Timeframe<strong style={{ color: 'red' }}> *</strong>
      </Divider>

      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="alwaysOn"
            checked={upload?.responseTime === 'alwaysOn'}
            onClick={({ target }) => {
              handleInputChange(target);
            }}
            {...register('responseTime')}
            value="alwaysOn"
            name="responseTime"
            errors={errors}
          />
          <div>
            <strong>Always On</strong>
            <FontAwesomeIcon icon="sms" transform="down-1 right-4" />
            <p>Responder will be active 24/7</p>
          </div>
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="scheduleResponder"
            checked={upload?.responseTime === 'scheduleResponder'}
            onClick={({ target }) => {
              handleInputChange(target);
            }}
            {...register('responseTime')}
            value="scheduleResponder"
            name="responseTime"
            errors={errors}
          />
          <div>
            <strong>Schedule responder</strong>
            <FontAwesomeIcon icon="bullhorn" transform="down-1 right-4" />
            <p>Set specific times for responder to be active</p>
          </div>
        </Label>
      </FormGroup>
      <div>
        {upload?.responseTime === 'scheduleResponder' &&
          dayNames?.map((el, i) => {
            // Input for Select Start time=============
            const ExampleCustomInputStart = forwardRef(
              ({ value, onClick }, ref) => (
                <Button
                  disabled={!upload?.responderDay?.includes(el)}
                  className={'btn-tertiary border-dark w-100'}
                  onClick={onClick}
                  ref={ref}
                >
                  {upload?.weekDays[el]?.start ? (
                    <Moment
                      format="hh:mm a"
                      trim
                      date={upload?.weekDays[el]?.dpTimeStart}
                    ></Moment>
                  ) : responderDetails[0]?.responder?.weekDays[el] ? (
                    <Moment
                      format="hh:mm a"
                      trim
                      date={
                        responderDetails[0]?.responder?.weekDays[el]
                          ? responderDetails[0]?.responder?.weekDays[el]
                            .dpTimeStart
                          : ''
                      }
                    ></Moment>
                  ) : (
                    'Start time'
                  )}
                </Button>
              )
            );

            // Input for Select End time=============
            const ExampleCustomInputEnd = forwardRef(
              ({ value, onClick }, ref) => (
                <Button
                  disabled={!upload?.responderDay?.includes(el)}
                  className={'btn-tertiary border-dark w-100'}
                  onClick={onClick}
                  ref={ref}
                >
                  {upload?.weekDays[el]?.end ? (
                    <Moment
                      format="hh:mm a"
                      trim
                      date={upload?.weekDays[el]?.dpTimeEnd}
                    ></Moment>
                  ) : responderDetails[0]?.responder?.weekDays[el] ? (
                    <Moment
                      format="hh:mm a"
                      trim
                      date={
                        responderDetails[0]?.responder?.weekDays[el]
                          ? responderDetails[0]?.responder?.weekDays[el]
                            .dpTimeEnd
                          : ''
                      }
                    ></Moment>
                  ) : (
                    'End time'
                  )}
                </Button>
              )
            );

            return (
              <div key={i} className="mb-2">
                <Card>
                  <CardBody>
                    <Divider className="mt-4 fs-2">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          role="switch"
                          id={el}
                          style={{
                            transform: 'scale(1.5)',
                            marginRight: '20px'
                          }} // Adjust scale and margin as needed
                          onClick={({ target }) => {
                            if (upload?.responderDay?.includes(target.value)) {
                              setUpload({
                                ...upload,
                                responderDay: upload?.responderDay.filter(
                                  el => {
                                    return el !== target.value;
                                  }
                                )
                              });
                            } else {
                              setUpload({
                                ...upload,
                                responderDay: [
                                  ...upload?.responderDay,
                                  target.value
                                ]
                              });
                            }
                          }}
                          checked={
                            upload?.responderDay?.includes(el) ? true : false
                          }
                          value={el}
                          {...register('responderDay')}
                          name="responderDay"
                          errors={errors}
                        />
                        <Label>
                          {
                            <strong>
                              {el.slice(0, 1).toUpperCase() +
                                el.slice(1, el.length)}
                            </strong>
                          }
                        </Label>
                      </FormGroup>
                    </Divider>
                    <Row>
                      <Col className="col-8 col-md-10">
                        {upload?.responderDay?.includes(el) && (
                          <FormGroup switch>
                            <Input
                              type="switch"
                              role="switch"
                              id={`allDay-${el}`}
                              style={{
                                transform: 'scale(1.5)',
                                marginRight: '20px'
                              }} // Adjust scale and margin as needed
                              onClick={({ target }) => {
                                for (let key in weekDays) {
                                  if (key === target.value) {
                                    if (upload?.weekDays[el]?.allDay) {
                                      setUpload({
                                        ...upload,
                                        weekDays: {
                                          ...upload.weekDays,
                                          [el]: { allDay: false }
                                        }
                                      });
                                    } else {
                                      setUpload({
                                        ...upload,
                                        weekDays: {
                                          ...upload.weekDays,
                                          [el]: { allDay: true }
                                        }
                                      });
                                      //setWeekDays({...weekDays, [el]: { ...weekDays[el], allDay: true}})
                                    }
                                  } else if (
                                    !Object.hasOwn(weekDays, target.value)
                                  ) {
                                    setUpload({
                                      ...upload,
                                      [el]: { allDay: true }
                                    });
                                    // setWeekDays({...weekDays, [el]: { allDay: true}})
                                  }
                                }
                              }}
                              checked={upload?.weekDays[el]?.allDay}
                              value={el}
                              name="allDay"
                              errors={errors}
                            />
                            <Label>all day</Label>
                          </FormGroup>
                        )}
                      </Col>
                    </Row>

                    {upload?.responderDay?.includes(el) &&
                      !upload?.weekDays[el]?.allDay && (
                        <Row className="flex-column flex-sm-row">
                          <Col name="portal_column_start" className="col-xs-4">
                            <Label>Start</Label>
                            <DatePicker
                              portalId="portal_column_start" // this fixed the bleedthrough
                              showPopperArrow={false}
                              onChange={date => {
                                const newD = new Date(date);
                                const applyOffset = newD.setTime(
                                  newD.getTime() -
                                  newD.getTimezoneOffset() * 60_000
                                );
                                const actualTime = new Date(applyOffset)
                                  .toISOString()
                                  .replace('Z', ''); // THIS RETURNS TIME WITH ZERO OFFSET ISOSTRING, REMVOING LOCAL TIMEZONE OFFSET
                                const toTz = momentt
                                  .tz(actualTime, auth.timezone)
                                  .format(); // ADDS THE CUSTOM CHOSEN TIMEZONE OFFSET
                                const getUTCTime = momentt.utc(toTz).format(); // FORMATS TO STRING

                                // return //setWeekDays({...weekDays, [el]: {...weekDays[el], start : getUTCTime, dpTimeStart: date }}),
                                return setUpload({
                                  ...upload,
                                  weekDays: {
                                    ...upload.weekDays,
                                    [el]: {
                                      ...upload.weekDays[el],
                                      start: getUTCTime,
                                      dpTimeStart: date
                                    }
                                  }
                                });
                              }}
                              customInput={<ExampleCustomInputStart />}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </Col>

                          <Col name="portal_column_end" className="col-xs-4 ">
                            <Label>End</Label>
                            <DatePicker
                              portalId="portal_column_end"
                              showPopperArrow={false}
                              onChange={date => {
                                const newD = new Date(date);
                                const applyOffset = newD.setTime(
                                  newD.getTime() -
                                  newD.getTimezoneOffset() * 60_000
                                );
                                const actualTime = new Date(applyOffset)
                                  .toISOString()
                                  .replace('Z', ''); // THIS RETURNS TIME WITH ZERO OFFSET ISOSTRING, REMVOING LOCAL TIMEZONE OFFSET
                                const toTz = momentt
                                  .tz(actualTime, auth.timezone)
                                  .format(); // ADDS THE CUSTOM CHOSEN TIMEZONE OFFSET
                                const getUTCTime = momentt.utc(toTz).format(); // FORMATS TO STRING

                                //return setWeekDays({...weekDays, [el]: {...weekDays[el], end : getUTCTime, dpTimeEnd: date }})
                                return setUpload({
                                  ...upload,
                                  weekDays: {
                                    ...upload.weekDays,
                                    [el]: {
                                      ...upload.weekDays[el],
                                      end: getUTCTime,
                                      dpTimeEnd: date
                                    }
                                  }
                                });
                              }}
                              customInput={<ExampleCustomInputEnd />}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={5}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                            />
                          </Col>
                        </Row>
                      )}
                  </CardBody>
                </Card>
              </div>
            );
          })}
      </div>

      <Modal
        isOpen={createGroupModal}
        centered
        toggle={() => {
          return setCreateGroupModal(!createGroupModal);
        }}
      >
        <ModalHeader
          toggle={() => {
            return setCreateGroupModal(!createGroupModal);
          }}
          className="d-flex flex-between-center border-bottom-0"
          close={closeBtn}
        >
          Create group
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Form onSubmit={handleSubmit}>
                <Label for="title">Group Name:</Label>
                <Input
                  value={newGroup.title.value}
                  onChange={handleChange}
                  className="mb-3"
                  name="title"
                  id="title"
                />
                <Button
                  block
                  onClick={handleSubmit}
                  color="primary"
                  className="mb-3"
                >
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default StepTwoForm;
