import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row, Alert, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { useDispatch, useSelector } from 'react-redux';
import DLCNumbersTable from './DLCNumbersTable';
import DLCCampaignsTable from './DLCCampaignsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FaqDLC from './FaqDLC';
import { Link } from 'react-router-dom';

const DLCRegistration = () => {
  const { brand, loading: brandLoading } = useSelector(state => state.brand);
  const currentUser = useSelector(state => state.auth.user);
  const { numbers, loading: numbersLoading } = useSelector(state => state.DLCNumbers);

  console.log("brand", brand);
  console.log("currentUser", currentUser);

  // Safely get the first brand object
  const primaryBrand = Array.isArray(brand) && brand.length > 0 ? brand[0] : null;

  const [isBrandModalOpen, setIsBrandModalOpen] = useState(false);
  const toggleBrandModal = () => setIsBrandModalOpen(!isBrandModalOpen);


  const brandErrors = primaryBrand?.brandErrors || [];


  // This function re-renders brand errors as Alerts
  const renderBrandErrors = () => {
    if (!primaryBrand?.brandErrors?.length) return null;
    return (
      <div className="mt-3">
        <h6 className="fw-bold">Brand Errors</h6>
        {primaryBrand.brandErrors.map((err, idx) => {
          const alertColor = err.code >= 500 ? 'danger' : 'warning';
          return (
            <Alert key={idx} color={alertColor} className="soft-alert mb-2">
              <h6 className="alert-heading">
                Step: {err.step || 'N/A'}
              </h6>
              <p className="mb-1">
                <strong>Message:</strong> {err.message || 'No message'}
              </p>
              {err.reason && (
                <p className="mb-0">
                  <strong>Reason:</strong> {err.reason}
                </p>
              )}
            </Alert>
          );
        })}
      </div>
    );
  };

  return (
    <div>
      {!numbers?.length ? (
        <div>
          <Card className="mb-4">
            <CardHeader className="text-center">
              <FontAwesomeIcon icon="phone-alt" size="2x" />
              <h4 className="mt-2">Get Started with Your 10DLC Number</h4>
            </CardHeader>
            <CardBody className="text-center">
              <Row>
                <Col xs={12}>
                  <p className="lead">
                    Increase customer trust and engagement by using a phone
                    number with a local area code. Customers are more likely to
                    respond to and trust messages from familiar area codes.
                  </p>
                  <p className="lead">
                    Get a number today and start benefiting from improved
                    deliverability and compliance with messaging regulations.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    tag={Link}
                    to="/follow-up/compliance/10dlc"
                    color="primary"
                    size="lg"
                  >
                    Get a Number
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      ) : (
        <div>
          {/* BRAND DETAILS CARD */}
          {primaryBrand ? (
            <Card className="border-1 mb-3 px-3">
              <CardHeader className="fw-bold pb-0">
                Brand details
              </CardHeader>
              <CardBody>
                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                  <div style={{ display: 'inline-flex' }}>
                    <div
                      key="name"
                      style={{
                        flex: '0 0 auto',
                        minWidth: '120px',
                        marginRight: '16px'
                      }}
                    >
                      <h6 className="fw-bold">Name</h6>
                      <p>{primaryBrand.businessName}</p>
                    </div>

                    <div
                      key="created"
                      style={{
                        flex: '0 0 auto',
                        minWidth: '120px',
                        marginRight: '16px'
                      }}
                    >
                      <h6 className="fw-bold">Created</h6>
                      <p>
                        {primaryBrand.createdAt
                          ? new Date(primaryBrand.createdAt).toLocaleDateString(
                            'en-US',
                            {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            }
                          )
                          : ''}
                      </p>
                    </div>

                    <div
                      key="brandSid"
                      style={{
                        flex: '0 0 auto',
                        minWidth: '120px',
                        marginRight: '16px'
                      }}
                    >
                      <h6 className="fw-bold">Brand SID</h6>
                      <p>{primaryBrand.twilioBrandSid || 'N/A'}</p>
                    </div>

                    <div
                      key="status"
                      style={{
                        flex: '0 0 auto',
                        minWidth: '120px',
                        marginRight: '16px'
                      }}
                    >
                      <h6 className="fw-bold">Status</h6>
                      <p>{primaryBrand.status || 'PENDING'}</p>
                    </div>

                    <div
                      key="actions"
                      style={{
                        flex: '0 0 auto',
                        minWidth: '120px',
                        marginRight: '16px'
                      }}
                    >
                      <ButtonIcon
                        icon="eye"
                        transform="shrink-3"
                        className="btn-tertiary border-dark"
                        size="sm"
                        onClick={toggleBrandModal}
                      >
                        View Details
                      </ButtonIcon>
                    </div>
                  </div>
                </div>
                {/* Possibly show brand errors inline, or only in the modal */}
                {renderBrandErrors()}
              </CardBody>
            </Card>
          ) : (
            <Card className="mb-3">
              <CardBody>
                <p className="lead">
                  It looks like you haven't set up a Brand yet.
                </p>
                <Button tag={Link} to="/follow-up/compliance/10dlc" color="primary">
                  Create Brand
                </Button>
              </CardBody>
            </Card>
          )}


          <DLCCampaignsTable />

          <DLCNumbersTable />
        </div>
      )}

      <Card
        style={{
          border: '1px solid rgba(0,0,0,.125)',
          borderRadius: '0.25rem'
        }}
      >
        <CardBody style={{ textAlign: 'center' }}></CardBody>
        <FaqDLC />

        {/* -- The Modal to show brand details more thoroughly -- */}
        <Modal isOpen={isBrandModalOpen} toggle={toggleBrandModal}>
          <ModalHeader toggle={toggleBrandModal}>Brand Information</ModalHeader>
          <ModalBody>
            {primaryBrand ? (
              <div>
                <Row className="mb-2">
                  <Col xs={12}>
                    <strong>Business Name:</strong> {primaryBrand.businessName}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={6}>
                    <strong>Business Type:</strong> {primaryBrand.businessType}
                  </Col>
                  <Col xs={6}>
                    <strong>Company Type:</strong> {primaryBrand.companyType || 'N/A'}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={6}>
                    <strong>Industry:</strong> {primaryBrand.industry}
                  </Col>
                  <Col xs={6}>
                    <strong>Website:</strong> {primaryBrand.businessWebsite}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={12}>
                    <strong>Registration #:</strong>{" "}
                    {primaryBrand.businessRegistrationNumber || 'N/A'}
                  </Col>
                </Row>
                <hr />
                <h6>Contact Details</h6>
                <Row className="mb-2">
                  <Col xs={6}>
                    <strong>Contact Name:</strong> {" "}
                    {primaryBrand.contactDetails?.businessContactFirstName}{" "}
                    {primaryBrand.contactDetails?.businessContactLastName}
                  </Col>
                  <Col xs={6}>
                    <strong>Phone:</strong> {" "}
                    {primaryBrand.contactDetails?.businessContactPhone}
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col xs={12}>
                    <strong>Email:</strong> {" "}
                    {primaryBrand.contactDetails?.businessContactEmail}
                  </Col>
                </Row>
                <hr />
                <h6>Address</h6>
                <Row>
                  <Col xs={12}>
                    {primaryBrand.address?.businessStreet}, {primaryBrand.address?.businessCity},{" "}
                    {primaryBrand.address?.businessStateProvinceRegion}{" "}
                    {primaryBrand.address?.businessPostalCode},{" "}
                    {primaryBrand.address?.businessCountry}
                  </Col>
                </Row>
                <hr />
                <Row className="mb-2">
                  <Col xs={6}>
                    <strong>Status:</strong> {primaryBrand.status}
                  </Col>
                  <Col xs={6}>
                    <strong>Brand Score:</strong> {primaryBrand.brandScore || 'N/A'}
                  </Col>
                </Row>

                {/* Render brand errors if any */}
                {primaryBrand.brandErrors?.length > 0 && (
                  <div className="mt-3">
                    <h6 className="fw-bold">Brand Errors</h6>
                    {primaryBrand.brandErrors.map((err, idx) => {
                      const alertColor = err.code >= 500 ? 'danger' : 'warning';
                      return (
                        <Alert key={idx} color={alertColor} className="soft-alert mb-2">
                          <h6 className="alert-heading">
                            Step: {err.step || 'N/A'}
                          </h6>
                          <p className="mb-1">
                            <strong>Message:</strong> {err.message || 'No message'}
                          </p>
                          {err.reason && (
                            <p className="mb-0">
                              <strong>Reason:</strong> {err.reason}
                            </p>
                          )}
                        </Alert>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <p>No brand details found.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleBrandModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </Card>
    </div>
  );
};

export default DLCRegistration;
