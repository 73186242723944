import {
  TAG_CREATE_REQUEST,
  TAG_CREATE_SUCCESS,
  TAG_CREATE_FAIL,
  TAG_CREATE_RESET
} from '../actions/types';

export default function tagCreateReducer(state = {}, action) {
  switch (action.type) {
    case TAG_CREATE_REQUEST:
      return { loading: true };
    case TAG_CREATE_SUCCESS:
      return { loading: false, success: true };
    case TAG_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case TAG_CREATE_RESET:
      return {};
    default:
      return state;
  }
}
