import {
  ITEM_DETAILS_REQUEST,
  ITEM_DETAILS_FAIL,
  ITEM_DETAILS_SUCCESS
} from '../actions/types';

export default function itemDetailsReducer(state = { item: {} }, action) {
  switch (action.type) {
    case ITEM_DETAILS_REQUEST:
      return { loading: true };
    case ITEM_DETAILS_SUCCESS:
      return { loading: false, success: true, item: action.payload };
    case ITEM_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
