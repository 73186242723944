import {
  PROPERTY_DETAILS_REQUEST,
  PROPERTY_DETAILS_FAIL,
  PROPERTY_DETAILS_SUCCESS
} from '../actions/types';

export default function propertyDetailsReducer(
  state = { property: {} },
  action
) {
  switch (action.type) {
    case PROPERTY_DETAILS_REQUEST:
      return { loading: true };
    case PROPERTY_DETAILS_SUCCESS:
      return { loading: false, success: true, propertyInfo: action.payload };
    case PROPERTY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
