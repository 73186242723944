import {
  EMAIL_REQUEST,
  EMAIL_SUCCESS,
  EMAIL_FAIL,
  EMAIL_RESET
} from '../actions/types';

export default function emailReducer(state = {}, action) {
  switch (action.type) {
    case EMAIL_REQUEST:
      return {
        ...state,
        [action.meta.type]: { loading: true }
      };
    case EMAIL_SUCCESS:
      return {
        ...state,
        [action.meta.type]: {
          loading: false,
          success: true,
          info: action.payload
        }
      };
    case EMAIL_FAIL:
      return {
        ...state,
        [action.meta.type]: { loading: false, error: action.payload }
      };
    case EMAIL_RESET:
      return {
        ...state,
        [action.meta.type]: {}
      };
    default:
      return state;
  }
}
