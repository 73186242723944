import React, { Fragment, useState, useCallback, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { listFields, deleteField, createField, updateField, deleteMultipleFields, fieldById } from 'actions/index';
import { FIELD_CREATE_RESET, FIELD_DELETE_RESET, FIELD_UPDATE_RESET } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';


const CustomFields = () => {


  const dispatch = useDispatch();
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isSelected, setIsSelected] = useState([]);
  const [rowDelete, setRowDelete] = useState()
  const [tempTitle, setTempTitle] = useState();
  const [openPopoverId, setOpenPopoverId] = useState(null);
  const [field, setField] = useState({
    title: ''
  })

  const { fields, loading: fieldsLoading, totalItems: totalItems } = useSelector((state) => state.fields);

  const fieldCreate = useSelector((state) => state.fieldCreate)
  const { success: successCreate } = fieldCreate

  const fieldDelete = useSelector((state) => state.fieldDelete)
  const { success: successDelete } = fieldDelete

  const fieldDetails = useSelector((state) => state.fieldDetails)
  const { success: successGet } = fieldDetails

  const fieldUpdate = useSelector((state) => state.fieldUpdate)
  const { success: successUpdate } = fieldUpdate

  const closeEditBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowEditModal(!showEditModal) }}>

    </button>
  );

  const closeCreateBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowFieldModal(!showFieldModal) }}>

    </button>
  );


  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setRowDelete([]) },
    [setIsSelected]);




  const nameFormatter = (rowData, { avatar }) => {
    const { title, _id } = rowData.row.original

    return (
      <Link to="#" onClick={() => { return dispatch(fieldById(_id)), setTempTitle(rowData.row.original), setShowEditModal(true) }}>
        <Media tag={Flex} align="center">
          <Media body className="ml-2">
            <h5 className="mb-0 fs-9">{title}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };


  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;
    const items = [
      {
        label: 'Edit',
        icon: 'edit',
        action: () => { return setTempTitle(rowData.row.original), setShowEditModal(!showEditModal) },
      },
      {
        label: 'Delete',
        icon: 'trash',
        action: () => { return setRowDelete([_id]), setShowDeleteModal(!showDeleteModal) },
      }
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
          isOpen={openPopoverId === _id}
          toggle={() => setOpenPopoverId(openPopoverId === _id ? null : _id)}
        />
      </div>
    );
  };




  const columns = [
    {
      accessor: 'title',
      Header: 'Field name',
      Cell: nameFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right",

    }
  ];





  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(createField(field))
    setShowFieldModal(false)
  };

  const handleDelete = (_id) => {
    if (window.confirm("Are you sure?")) {
      dispatch(deleteField(_id))
    }
  }

  const handleDeleteMulti = () => {
    dispatch(deleteMultipleFields(isSelected))
    setShowDeleteModal(!showDeleteModal)
  }

  const handleChange = e => {
    setField({ ...field, [e.target.name]: e.target.value })
  }



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listFields({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  useEffect(() => {
    if (successCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: FIELD_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: FIELD_UPDATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successUpdate, fetchData, dispatch]);

  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: FIELD_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listFields({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };



  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon icon="plus" className="btn-tertiary border-dark mx-1" onClick={() => { setShowFieldModal(true) }} transform="shrink-3" size="sm">
                New Field
              </ButtonIcon>
              <ButtonIcon onClick={() => { setShowDeleteModal(!showDeleteModal) }} icon="trash" disabled={!isSelected.length} transform="shrink-3" className="btn-tertiary border-dark text-danger mx-1" size="sm" >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {fieldsLoading && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={fields}
            fetchData={fetchData}
            total={totalItems}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox onSearch={handleSearch} placeholder="Search fields..." table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-9 font-weight-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalItems}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>


      <Modal isOpen={showFieldModal} centered toggle={() => setShowFieldModal(!showFieldModal)}>
        <ModalHeader close={closeCreateBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Create a new Field
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Form onSubmit={handleSubmit}>
                <Label for="title">Field Name:</Label>
                <Input value={field.title.value} onChange={handleChange} className="mb-3" name="title" id="title" />
                <Button block disabled={field?.title?.length < 1} onClick={() => { setShowFieldModal(false) }} className="btn-tertiary border-dark text-primary" type="submit">Save</Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal isOpen={showEditModal} centered toggle={() => setShowEditModal(!showEditModal)}>
        <ModalHeader close={closeEditBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Update Field
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label for="title">Field Name</Label>
              <Input defaultValue={tempTitle?.title} value={field.title.value} onChange={handleChange} className="mb-3" name="title" id="title" />
              <Button block className="btn-tertiary border-dark text-primary" onClick={() => { return dispatch(updateField(field, tempTitle._id)), setShowEditModal(!showEditModal) }}>Save</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Button onClick={() => { if (rowDelete?.length) { dispatch(deleteMultipleFields(rowDelete)) } else { dispatch(deleteMultipleFields(isSelected)) } setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-danger" >Delete fields</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};


export default CustomFields;
