import axios from 'axios';

import {
 
  LIST_COMPANY_DETAILS,
  COMPANY_DETAILS_UPDATE_REQUEST,
  COMPANY_DETAILS_UPDATE_SUCCESS,
  COMPANY_DETAILS_UPDATE_FAIL,
  LIST_COMPANY_LOGO,
  LOGO_ERROR,
  ATTACH_LOGO_CREATE_REQUEST,
  ATTACH_LOGO_CREATE_SUCCESS,
  ATTACH_LOGO_CREATE_FAIL,
  LIST_PAY_SETTINGS
 
} from './types';



export const listCompanyDetails = () => {
  return async function (dispatch) {
    // Add the query parameters to the URL
    const url = `${process.env.REACT_APP_API_URL}/api/company-details`;

    return axios({
      url,
      method: 'GET',
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_COMPANY_DETAILS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};

export const updateCompanyDetails =
  (companyDetails, companyDetailsId) => async dispatch => {
    try {
      dispatch({ type: COMPANY_DETAILS_UPDATE_REQUEST });

      await axios({
        url: `${process.env.REACT_APP_API_URL}/api/company-details/${companyDetailsId}`,
        method: 'PUT',
        data: { companyDetails },
        withCredentials: true
      });

      dispatch({ type: COMPANY_DETAILS_UPDATE_SUCCESS });
    } catch (error) {
      dispatch({
        type: COMPANY_DETAILS_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };

export const listCompanyLogo = () => {
  return async function (dispatch) {
    const url = `${process.env.REACT_APP_API_URL}/api/logo`;

    try {
      const res = await axios({
        url,
        method: 'GET',
        withCredentials: true
      });

      dispatch({ type: LIST_COMPANY_LOGO, payload: res.data });
      return res.data; // Resolve promise with data
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          // Handle 404 error - Logo not found
          console.log('Logo not found, using default logo.');
          // Dispatch action with null payload or handle accordingly
          dispatch({ type: LIST_COMPANY_LOGO, payload: null });
          return null;
        } else {
          // Handle other HTTP errors
          console.error('Error fetching logo:', error.response.status);
          // Optionally, dispatch an error action
          dispatch({ type: LOGO_ERROR, payload: error.response.status });
          return null;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error('No response received:', error.request);
        // Optionally, dispatch an error action
        dispatch({ type: LOGO_ERROR, payload: 'No response received' });
        return null;
      } else {
        // Something happened in setting up the request
        console.error('Error', error.message);
        // Optionally, dispatch an error action
        dispatch({ type: LOGO_ERROR, payload: error.message });
        return null;
      }
    }
  };
};

export const attachLogo = uploadLogo => async dispatch => {
  try {
    // need to send with different headers? its always sending JSON // check bookmark to complete
    dispatch({ type: ATTACH_LOGO_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/logo`,
      method: 'POST',
      data: uploadLogo,
      withCredentials: true
    }).then(res =>
      dispatch({ type: ATTACH_LOGO_CREATE_SUCCESS, payload: res })
    );
  } catch (error) {
    console.log(error);
    dispatch({
      type: ATTACH_LOGO_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};



export const listPaySettings = () => {
  return async function (dispatch) {
    // Add the query parameters to the URL
    const url = `${process.env.REACT_APP_API_URL}/api/user-pay-settings`;

    return axios({
      url,
      method: 'GET',
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_PAY_SETTINGS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};



