import {
  EDIT_CHAT,
  LIST_CHATS,
  ADD_CHAT,
  DELETE_CHAT,
  CHAT_SWAP_INFO,
  REMOVE_BLOCKED_CHAT,
  REMOVE_UNSUBSCRIBED_CHAT
} from '../actions/types';

export default function (state = [], action) {
  const { payload } = action;
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_CHATS:
      return action.payload.filter(obj => {
        if (obj.contact.UnsubscribeDate) {
          return Object.keys(obj.contact.UnsubscribeDate).length === 0;
        } else {
          return obj;
        }
      });

    case REMOVE_BLOCKED_CHAT:
      return state.filter(el => {
        return el._id !== payload._id;
      });

    case REMOVE_UNSUBSCRIBED_CHAT:
      return state.filter(el => {
        return el._id !== payload._id;
      });

    case ADD_CHAT:
      return [payload, ...state];

    case EDIT_CHAT:
      return state.map(chat => (chat._id === payload._id ? payload : chat));

    case CHAT_SWAP_INFO:
      return state.map(chat =>
        chat.phone === payload.phone
          ? {
              ...chat,
              ...payload
            }
          : chat
      );

    case DELETE_CHAT:
      return state.filter(chat => chat._id !== payload.chat._id);

    default:
      return state;
  }
}
