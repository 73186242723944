import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import MessageDetails from './MessageDetails';
import TemplateCreator from 'components/templates/TemplateCreator';
import { deleteSentHistoryMessages } from 'actions/historyActions';
import { createChat } from 'actions/chatActions';
import { UPDATE_ACTIVE_CHAT, ADD_CHAT, SET_CONTACTS } from 'actions/types';
import CustomerForm from 'components/customers/CustomerForm';
import ActionPopover from 'components/common/ActionPopover';
import { listBulkSendDeliveredMessages } from 'actions/bulkSendActions';
import { useNavigate } from 'react-router-dom';

const DeliveredTable = ({ bulkDetails }) => {

  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const chats = useSelector((state) => state.chats)

  const primaryNumber = useSelector((state) => state.primaryNumber)
  const bulkTextDetailsDelivered = useSelector((state) => state.bulkTextDetailsDelivered)
  const { messages: deliveredMessages, loading: deliveredMessagesLoading, totalMessages: totalMessages } = bulkTextDetailsDelivered


  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMessageDetailModal, setShowMessageDetailModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false)
  const [changeText, setChangeText] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [message, setMessage] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [temporaryId, setTemporaryId] = useState()
  const [newContactInfo, setNewContactInfo] = useState()


  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }





  const closeTemplateBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowTemplateModal(!showTemplateModal) }}>
    </button>
  );

  const closeContactBtn = (
    <button className="btn-close bg-light" onClick={() => { return setShowContactModal(!showContactModal) }}>
    </button>
  );

  const closeMsgDetailsBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowMessageDetailModal(!showMessageDetailModal) }}>
    </button>
  );



  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }



  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------


  const toFormatter = (rowData) => {
    const { to, name } = rowData.row.original
    return (
      <div>
        <Media
          tag={Flex} align="center">
          <Media body className="ms-2 cursor-pointer">
            <h5 className="mb-0 fs-9">{name?.firstName && name?.lastName ? name?.firstName + " " + name?.lastName : name?.firstName ? name?.firstName : formatPhoneNumber(to)}</h5>
          </Media>
        </Media>
      </div>
    );
  };


  const fromFormatter = (rowData) => {
    const { from, name } = rowData.row.original
    return (
      <div>
        <Media
          tag={Flex} align="center">
          <Media body className="ms-2 cursor-pointer">
            <h5 className="mb-0 fs-9">{formatPhoneNumber(from)}</h5>
          </Media>
        </Media>
      </div>
    );
  };


  const bodyFormatter = (rowData) => {
    const { body, numMedia, messageBody } = rowData.row.original;
    const text = messageBody || body || '';

    const truncatedText = text.length > 20 ? text.substring(0, 20) + '...' : text;

    return (
      <Link to="#!" onClick={() => { if (!messageBody) { setMessage(rowData.row.original); setShowMessageDetailModal(true); } }}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2 mt-1">
            {numMedia === "1" ? (
              <SubtleBadge color="primary" pill className="fs-9">
                MMS message
                <FontAwesomeIcon icon="image" transform="shrink-1" className=" ms-1" />
              </SubtleBadge>
            ) : (
              <h5 className="mb-0 fs-9 fw-light">{truncatedText}</h5>
            )}
          </Media>
        </Media>
      </Link>
    );
  };


  const statusFormatter = (rowData) => {
    const { status, messageBody } = rowData.row.original
    return (
      <div>
        <Link to="#!" onClick={() => { if (!messageBody) { setMessage(rowData.row.original); setShowMessageDetailModal(true); } }}>
          <Media body className="ms-2 mt-1">
            <SubtleBadge bg="success" pill className="fs-9">
              {status}
              <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
            </SubtleBadge>
          </Media>

        </Link>

      </div>
    );
  };

  const dateSentFormatter = (rowData) => {
    const { dateSent } = rowData.row.original;

    return (
      <div>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9 mt-2">
              <Moment format="MM/DD/YY" date={dateSent} />
            </h5>
            <h6 className="mb-0 fs--1 mt-1 text-muted">
              <Moment format="hh:mm A" date={dateSent} />
            </h6>
          </Media>
        </Media>
      </div>
    );
  };


  const actionFormatter = (rowData) => {
    const { to, name, body, sid, _id, messageIds } = rowData.row.original;
    const composeContact = {
      id: name ? name._id : "",
      original: {
        firstName: name ? name.firstName : to,
        lastName: name ? name.lastName : "",
        phone_number: name ? name.phone_number : to,
        _id: name ? name._id : ""
      }
    };

    const items = [
      !messageIds && {
        label: 'Go to chat',
        icon: 'sms',
        action: async () => {
          const formatTo = to.replace(/\D/g, '');
          const doesExist = chats.filter((chat) => chat.phone === formatTo)[0];

          if (doesExist) {
            dispatch({ type: UPDATE_ACTIVE_CHAT, payload: doesExist._id });
          } else {
            const contact = { phone_number: formatTo };

            const newChatId = await dispatch(createChat(contact, primaryNumber));
            if (newChatId) {
              dispatch({ type: UPDATE_ACTIVE_CHAT, payload: newChatId });
            }
          }
          navigate('/follow-up/chat');
        }
      },
      !messageIds && {
        label: !name ? 'Create contact' : 'Contact details',
        icon: !name ? 'user-plus' : 'user',
        action: () => {
          if (!name) {
            setNewContactInfo(to);
            setShowContactModal(!showContactModal);
          } else {
            //openContactUpdateModal(name?._id); // this is contact._id
          }
        },
        className: !name ? 'text-success' : ''
      },
      !messageIds && {
        label: 'Send SMS to this contact',
        icon: 'reply',
        action: () => {
          dispatch({ type: SET_CONTACTS, payload: [composeContact] });
          navigate('/');
        }
      },
      !messageIds && {
        label: 'Message Details',
        icon: 'eye',
        color: 'info',
        action: () => {
          setMessage(rowData.row.original);
          setShowMessageDetailModal(!showMessageDetailModal);
        }
      },
      !messageIds && {
        label: 'Save as new Template',
        icon: ['fab', 'trello'],
        action: () => {
          setTemporaryId({ body: body, _id: _id });
          setShowTemplateModal(!showTemplateModal);
        }
      },
      !messageIds && {
        label: 'Delete Message',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([sid]);
          setShowDeleteModal(!showDeleteModal);
        },
        className: 'text-danger'
      }
    ].filter(Boolean);

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={sid}
          items={items}
        />
      </div>
    );
  };




  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'to',
      Header: 'To',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: toFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'from',
      Header: 'From',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: fromFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'fullName',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'body',
      Header: 'Body',
      headerProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
      Cell: bodyFormatter,
      cellProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: statusFormatter
    },
    {
      accessor: 'dateSent',
      Header: 'Sent time',
      Cell: dateSentFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',
    },
  ];

  //--------------------------------------------------------------------------Handlers------------------------------------------------------------------------------------

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'dateSent'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listBulkSendDeliveredMessages({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection, bulkDetails: bulkDetails }));

  }, [dispatch])


  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  useEffect(() => {
    setRowDelete([])
  }, [])



  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };

  const selectionCallback = useCallback((selectedFlatRows) => {
    return setIsSelected(selectedFlatRows.map((fr) => {
      return fr.original.sid
    })),
      setRowDelete([])
  },
    [setIsSelected]);


  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Card className="mb-3">
      <FalconCardHeader title="" light={false}>
        <Fragment>
          <ButtonGroup className="mt-2">
            <ButtonIcon icon="sms" transform="shrink-3" disabled={!isSelected.length} color="falcon-info" size="sm">
              Send SMS
            </ButtonIcon>
            <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) }} disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fs--1 fw-normal p-4">
                    <Button onClick={() => { if (rowDelete?.length) { dispatch(deleteSentHistoryMessages(rowDelete)) } else { dispatch(deleteSentHistoryMessages(isSelected)) } setShowDeleteModal(!showDeleteModal) }} block className="text-danger" color="light">Delete Messages</Button>
                    <Button onClick={() => { return setShowDeleteModal(!showDeleteModal), setRowDelete([]) }} block className="text-primary" color="light" >Cancel</Button>
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>

            {
              // Message Details Modal
            }
            <Modal isOpen={showMessageDetailModal} centered toggle={() => setShowMessageDetailModal(!showMessageDetailModal)}>
              <ModalHeader className="d-flex justify-content-center" close={closeMsgDetailsBtn} >Message Details</ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fs--1 fw-normal p-4">
                    <MessageDetails message={message} />
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>

            {
              // create contact modal
            }
            <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
              <ModalHeader toggle={() => { return setShowContactModal(!showContactModal) }} className="bg-light d-flex flex-between-center border-bottom-0" close={closeContactBtn}>
                How would you like to Add?
              </ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="p-2">
                    <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} newContactInfo={newContactInfo} />
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>


            {
              // show template modal
            }
            <Modal isOpen={showTemplateModal} centered toggle={() => { return setShowTemplateModal(!showTemplateModal) }}>
              <ModalHeader close={closeTemplateBtn}>Design your template</ModalHeader>
              <ModalBody className="p-0">
                <TemplateCreator temporaryId={temporaryId} setTemporaryId={setTemporaryId} />
              </ModalBody>
            </Modal>
          </ButtonGroup>
        </Fragment>
      </FalconCardHeader>
      <CardBody className="p-0">


        <Fragment>
          <Card>

            {deliveredMessagesLoading && (
              <div style={overlayStyle}>
                <Spinner animation="border" role="status" style={spinnerStyle}>
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            )}

            <AdvanceTableWrapper
              columns={columns}
              data={deliveredMessages}
              fetchData={fetchData}
              total={totalMessages}
              pageCount={1}
              sortable
              pagination
              perPage={10}
              selection
              selectionCallback={selectionCallback}

            >
              <Row className="flex-start-center mb-3 ms-1">
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox onSearch={() => { }} table />
                </Col>
              </Row>

              <AdvanceTable
                table
                headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                tableProps={{
                  className: 'fs-9 fw-bold overflow-hidden'
                }}
              />

              <div className="mt-3 mb-2 pe-2 ps-3 ">
                <AdvanceTableFooter
                  rowCount={totalMessages}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>

          </Card>
        </Fragment>

      </CardBody>
    </Card>
  );
};


export default DeliveredTable;
