import React, { useEffect, useContext, useState } from 'react';
import { Button, Spinner } from 'reactstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from 'actions';
import animationData from '../../authentication/wizard/lottie/verifyEmail.json';
import { NewUserUploadContext } from '../../../context/Context';
import { listCompanyDetails } from 'actions/crmActions';
import { fetchTeamDetails } from 'actions/teamActions';

const ImportSuccess = ({ setShowFirstLoginModal }) => {
  const dispatch = useDispatch();
  const { upload } = useContext(NewUserUploadContext);
  const [loading, setLoading] = useState(false);

  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    setLoading(true);
    axios({
      method: 'POST',
      data: {
        companyName: upload.companyName,
        websiteUrl: upload.companyWebsite,
        phoneNumber: upload.phoneNumber,
        teamSize: upload.teamSize,
        annualRevenue: upload.annualRevenue,
      },
      url: `${process.env.REACT_APP_API_URL}/api/account-update-first/${auth?._id}`,
      withCredentials: true,
    })
      .then((res) => {
        if (res.error) {
          console.log('error', res.error);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [auth, upload]);

  const handleContinue = () => {
    dispatch(fetchUser());
    dispatch(listCompanyDetails());
    dispatch(fetchTeamDetails());
    setShowFirstLoginModal(false);
  };

  return (
    <div className="text-center p-4">
      {loading ? (
        <div>
          <h5>Setting up your account...</h5>
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <h4 className="mb-3 font-weight-bold">You're all set!</h4>
          <p className="mb-4">Your account has been successfully set up.</p>
          <div className="d-flex justify-content-center mb-4">
            <Lottie
              animationData={animationData}
              style={{ height: 200, width: 200 }}
              loop={false}
            />
          </div>
          <Button color="primary" onClick={handleContinue}>
            Go to Dashboard
          </Button>
        </>
      )}
    </div>
  );
};

export default ImportSuccess;
