import { ADD_RESPONDER, RESET_RESPONDER } from '../actions/types';

export default function responderDetailsReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_RESPONDER:
      console.log('ADD_RESPONDER state', state);
      console.log('ADD_RESPONDER payload', payload);
      return [...state, payload];

    case RESET_RESPONDER:
      return [];

    default:
      return state;
  }
}
