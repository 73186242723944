import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ChatContext } from '../../context/Context';
import {
  listContacts,
  listFields,
  listPrimaryNumbers,
} from '../../actions/index';
import { listChats } from '../../actions/chatActions';

const ChatProvider = ({ children }) => {
  const dispatch = useDispatch()


  //----------------------------------------------------------------------LOCAL STATE--------------------------------------------------------------------------------  
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [searchTerm, setSearchTerm] = useState("") // we are creating the state then passing it down via value object
  const [searchResults, setSearchResults] = useState([])
  const [selContact, setSelContact] = useState('')
  const [activeConversationId, setActiveConversationId] = useState()


  //------------------------------------------------------------------------------REDUX STATE--------------------------------------------------------------------------


  const contactsFollowUp = useSelector((state) => state.contactsFollowUp)
  const { contacts: contacts } = contactsFollowUp
  const numbers = useSelector((state) => state.numbers)
  const primaryNumber = useSelector((state) => state.primaryNumber)
  const chats = useSelector((state) => state.chats)
  const chatMessages = useSelector((state) => state.chatMessages)
  const activeChatId = useSelector((state) => state.activeChatId)





  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const contactCreate = useSelector((state) => state.createContact)
  const { success: successContactCreate } = contactCreate

  const updatePrimaryNumber = useSelector((state) => state.primaryNumberUpdate)
  const { success: successUpdatePrimaryNumber } = updatePrimaryNumber


  //------------------------------------------------------------------------------USE EFFECT--------------------------------------------------------------------------

  useEffect(() => {
    dispatch(listFields())
    dispatch(listChats())
    dispatch(listContacts({ search: "", page: 1, limit: 30 }))
  }, [successBlockedCreate, successContactCreate]);

  useEffect(() => {
    dispatch(listPrimaryNumbers())
  }, [successBlockedCreate, successUpdatePrimaryNumber])



  //------------------------------------------------------------------------------FUNCTIONS TO PASS DOWN--------------------------------------------------------------------------
  const searchHandler = (searchTerm) => {
    setSearchTerm(searchTerm);

    if (searchTerm !== "") {
      dispatch(listContacts({ search: searchTerm, page: 1, limit: 30 }))
        .then((data) => {
          setSearchResults(data?.contacts?.splice(0, 30));
        })
        .catch((error) => {
          console.error('Error fetching contacts:', error);

        });
    }
  };


  //------------------------------------------------------------------------------VALUE OBJECT--------------------------------------------------------------------------

  const value = {
    contacts, // users
    selContact,
    setSelContact,
    chats, // threads
    chatMessages,
    primaryNumber,
    searchTerm,
    setSearchTerm,
    searchHandler,
    searchResults,
    activeChatId,
    activeConversationId,
    setActiveConversationId,
    scrollToBottom,
    setScrollToBottom
  };


  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};

ChatProvider.propTypes = { children: PropTypes.node.isRequired };

export default ChatProvider;
