import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'; // Import Link

const ButtonIcon = ({
  icon,
  iconAlign,
  iconClassName,
  transform,
  children,
  to,
  ...rest
}) => {
  // If 'to' prop is provided, render button as a Link
  if (to) {
    return (
      <Link to={to}>
        <Button {...rest}>
          {iconAlign === 'right' && children}
          <FontAwesomeIcon
            icon={icon}
            className={classNames(iconClassName, {
              'me-1': children && iconAlign === 'left',
              'ms-1': children && iconAlign === 'right'
            })}
            transform={transform}
          />
          {iconAlign === 'left' && children}
        </Button>
      </Link>
    );
  }

  // Otherwise, render a regular button
  return (
    <Button {...rest}>
      {iconAlign === 'right' && children}
      <FontAwesomeIcon
        icon={icon}
        className={classNames(iconClassName, {
          'me-1': children && iconAlign === 'left',
          'ms-1': children && iconAlign === 'right'
        })}
        transform={transform}
      />
      {iconAlign === 'left' && children}
    </Button>
  );
};

ButtonIcon.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  children: PropTypes.any,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  iconClassName: PropTypes.string,
  transform: PropTypes.string,
  to: PropTypes.string // Add 'to' propType
};

ButtonIcon.defaultProps = {
  iconAlign: 'left'
};

export default ButtonIcon;
