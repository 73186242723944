import axios from 'axios';
import {

    MERCHANT_DETAILS_FOLLOW_UP_REQUEST,
    MERCHANT_DETAILS_FOLLOW_UP_SUCCESS,
    MERCHANT_DETAILS_FOLLOW_UP_FAIL,
    MERCHANT_DETAILS_FOLLOW_UP_RESET,

    FOLLOW_UP_SUBSCRIPTION_CANCEL_REQUEST,
    FOLLOW_UP_SUBSCRIPTION_CANCEL_SUCCESS,
    FOLLOW_UP_SUBSCRIPTION_CANCEL_FAIL,

    SUBSCRIPTION_CANCEL_REQUEST,
    SUBSCRIPTION_CANCEL_SUCCESS,
    SUBSCRIPTION_CANCEL_FAIL,

    PAYMENT_METHOD_DELETE_REQUEST,
    PAYMENT_METHOD_DELETE_SUCCESS,
    PAYMENT_METHOD_DELETE_FAIL,

    DEFAULT_PAY_METHOD_CREATE_REQUEST,
    DEFAULT_PAY_METHOD_CREATE_SUCCESS,
    DEFAULT_PAY_METHOD_CREATE_FAIL,

    CARD_SECRET_REQUEST,
    CARD_SECRET_SUCCESS,
    CARD_SECRET_FAIL,

    CUSTOMER_PROFILE_REQUEST,
    CUSTOMER_PROFILE_SUCCESS,
    CUSTOMER_PROFILE_FAIL,

    SUBSCRIPTION_DETAILS_REQUEST,
    SUBSCRIPTION_DETAILS_SUCCESS,
    SUBSCRIPTION_DETAILS_FAIL,

    CREATE_COLLECT_DEPOSIT_REQUEST,
    CREATE_COLLECT_DEPOSIT_FAIL,
    CREATE_COLLECT_DEPOSIT_SUCCESS,

    CREATE_COLLECT_PAYMENT_REQUEST,
    CREATE_COLLECT_PAYMENT_FAIL,
    CREATE_COLLECT_PAYMENT_SUCCESS,

    CUSTOMER_PROFILE_FOLLOW_UP_REQUEST,
    CUSTOMER_PROFILE_FOLLOW_UP_SUCCESS,
    CUSTOMER_PROFILE_FOLLOW_UP_FAIL,

    LIST_FOLLOW_UP_BILLING_DETAILS_REQUEST,
    LIST_FOLLOW_UP_BILLING_DETAILS_SUCCESS,
    LIST_FOLLOW_UP_BILLING_DETAILS_FAIL

} from './types';




export const listFollowUpBillingDetails = () => {
    return async function (dispatch) {
        dispatch({ type: LIST_FOLLOW_UP_BILLING_DETAILS_REQUEST });
        try {
            const res = await axios({
                url: `${process.env.REACT_APP_API_URL}/api/follow-up-billing-details`,
                method: "GET",
                withCredentials: true
            });
            dispatch({ type: LIST_FOLLOW_UP_BILLING_DETAILS_SUCCESS, payload: res.data });
        } catch (error) {
            dispatch({
                type: LIST_FOLLOW_UP_BILLING_DETAILS_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message
            });
        }
    }
}





export const getCustomerProfileFollowUp = (plan) => async (dispatch) => {

    try {
        dispatch({ type: CUSTOMER_PROFILE_FOLLOW_UP_REQUEST })

        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/get-customer-profile-fup`,
            method: "GET",
            withCredentials: true
        }).then((res) => { dispatch({ type: CUSTOMER_PROFILE_FOLLOW_UP_SUCCESS, payload: res.data }) })


    } catch (error) {
        dispatch({
            type: CUSTOMER_PROFILE_FOLLOW_UP_FAIL,
            payload: error.message
        })

    }
}






export const getMerchantDetailsFollowUp = () => async (dispatch) => {
    try {
        dispatch({ type: MERCHANT_DETAILS_FOLLOW_UP_REQUEST })

        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/get-merchant-details-fup`,
            method: "POST",
            data: {},
            withCredentials: true
        }).then((res) => { dispatch({ type: MERCHANT_DETAILS_FOLLOW_UP_SUCCESS, payload: res.data }) })


    } catch (error) {
        dispatch({
            type: MERCHANT_DETAILS_FOLLOW_UP_FAIL,
            payload:
                error.message && error.response.data.message
                    ? error.response.data.message :
                    error.message
        })

    }
}



export const cancelFollowUpSubscription = (subscriptionId) => async (dispatch) => {

    try {
        dispatch({ type: FOLLOW_UP_SUBSCRIPTION_CANCEL_REQUEST })

        await axios({
            url: `${process.env.REACT_APP_API_URL}/api/cancel-subscription-fup`,
            method: "POST",
            data: { subscriptionId },
            withCredentials: true
        }).then((res) => { dispatch({ type: FOLLOW_UP_SUBSCRIPTION_CANCEL_SUCCESS, payload: res.data }) })


    } catch (error) {
        dispatch({
            type: FOLLOW_UP_SUBSCRIPTION_CANCEL_FAIL,
            payload: error.message
        })

    }
}