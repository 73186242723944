import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import Loader from 'components/common/Loader';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import GroupEdit from '../contacts/GroupEdit';
import useWindowDimensions from 'hooks/useWindowDimensions';
import {
  listGroupsExceptUnsub,
  createGroup,
  updateGroup,
  deleteMultipleGroups
} from 'actions/index';
import {
  SET_GROUPS,
  ADD_COMPOSE_GROUP,
  GROUP_CREATE_RESET
} from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';

const AddGroupToTable = ({
  groupsModal,
  setGroupsModal,
  recipients,
  setRecipients,
  fromCompose
}) => {
  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch();
  const windowSize = useWindowDimensions();

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const selectedGroupRows = useSelector(state => state.selectedGroupRows);
  const createBulk = useSelector(state => state.createBulk);

  const contactDelete = useSelector(state => state.deleteContact);
  const { success: successDelete } = contactDelete;

  const groupUpdate = useSelector(state => state.groupUpdate);
  const { success: successUpdate } = groupUpdate;

  const groupCreate = useSelector(state => state.groupCreate);
  const { success: successCreate } = groupCreate;

  const groupDelete = useSelector(state => state.groupDelete);
  const { success: successGroupDelete } = groupDelete;

  const [showEditGroupModal, setShowEditGroupModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tempGroup, setTempGroup] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [tempGroupId, setTempGroupId] = useState();
  const [groupTitle, setGroupTitle] = useState();
  const [rowDelete, setRowDelete] = useState();
  const [activePopoverId, setActivePopoverId] = useState(null);


  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original._id;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [newGroup, setNewGroup] = useState({ title: '' });
  const [group, setGroup] = useState({ title: '' });

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const titleFormatter = (rowData, { avatar }) => {
    const { title, contacts, _id } = rowData.row.original;
    const groupCompose = { title: title, contacts: contacts, _id: _id };
    return (
      <Media
        className="cursor-pointer"
        tag={Flex}
        align="center"
        onClick={() => {
          return (
            setIsSelected(_id),
            dispatch({
              type: ADD_COMPOSE_GROUP,
              payload: { groupCompose: groupCompose }
            }),
            setGroupsModal(!groupsModal)
          );
        }}
      >
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{title}</h5>
        </Media>
      </Media>
    );
  };

  const contactsFormatter = rowData => {
    const { contactsCount } = rowData.row.original;
    return (
      <Media body className="ms-2 mt-1">
        {contactsCount < 1 ? (
          <SubtleBadge color="soft-danger" pill className="fs-9">
            {contactsCount}
            <FontAwesomeIcon
              icon="user-plus"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        ) : (
          <SubtleBadge color="soft-success" pill className="fs-9">
            {contactsCount}
            <FontAwesomeIcon
              icon="check"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        )}
      </Media>
    );
  };

  const createdFormatter = rowData => {
    const { created } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2">
            <Moment format="MM/DD/YYYY" date={created} />
          </h5>
        </Media>
      </Media>
    );
  };

  // Inside ContactsTableCustom.js
  const actionFormatter = (rowData) => {
    const { title, _id } = rowData.row.original;
    const items = [

      {
        label: 'Text this group',
        icon: 'sms',
        action: () => {
          return (
            setIsSelected(_id),
            dispatch({
              type: SET_GROUPS,
              payload: [rowData.row.original]
            }),
            setGroupsModal(!groupsModal)
          );
        },
      },
      {
        label: 'Group details',
        icon: 'eye',
        action: () => {
          return (
            setTempGroupId(_id),
            setGroupTitle(title),
            setShowEditGroupModal(!showEditGroupModal)
          );
        },
      },
      {
        label: 'Rename',
        icon: 'edit',
        action: () => {
          return (
            setShowRenameModal(!showRenameModal),
            setTempGroup(rowData.row.original)
          );
        },
      },
      {
        label: "Hide group",
        icon: "minus",
        action: () => {
          return (
            setTempGroup(rowData.row.original),
            dispatch(updateGroup({ hide: true, _id: _id }))
          );
        }
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          return (
            setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)
          );
        },
      },

    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} activePopoverId={activePopoverId}
          setActivePopoverId={setActivePopoverId} />
      </div>
    );
  };


  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      Cell: titleFormatter
    },
    {
      accessor: 'contacts',
      Header: '# of contacts',
      Cell: contactsFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right"
    }
  ];

  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------

  const closeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowEditGroupModal(!showEditGroupModal);
      }}
    ></button>
  );

  const closeCreateGroupBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setCreateGroupModal(!createGroupModal);
      }}
    ></button>
  );

  const closeRenameBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowRenameModal(!showRenameModal);
      }}
    ></button>
  );

  const addGroupsToDiv = async () => {
    let groupsToSend = []; // creating these "let" variables is needed to update the react state after the mapping is complete. this is best practice
    let newToGroups = [...createBulk.messageDetails.groups]; // best practices
    let newRecipients = recipients; // best practices approved

    selectedGroupRows.forEach(group => {
      groupsToSend.push(group.original); // push entire group object into array for bulk message

      let match = newToGroups.find(el => el?._id === group?.original?._id);
      if (!match) {
        newToGroups.push(group.original);
        newRecipients += group.original.contactsCount;
      }
    });

    dispatch({ type: SET_GROUPS, payload: newToGroups });
    setRecipients(newRecipients);
  };

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------
  useEffect(() => {
    if (!successCreate) return;
    toast(
      <Fragment>
        <strong>Group Created</strong>
      </Fragment>
    );
    dispatch({ type: GROUP_CREATE_RESET });
  }, [successCreate]);

  useEffect(() => {
    dispatch(listGroupsExceptUnsub());
  }, [successDelete, successCreate, successGroupDelete, successUpdate]);

  const handleGroupSubmit = e => {
    e.preventDefault();
    dispatch(createGroup(newGroup));
    setCreateGroupModal(!createGroupModal);
  };

  const handleGroupChange = e => {
    setGroup({ ...group, [e.target.name]: e.target.value });
  };

  return (
    <Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon
                onClick={() => {
                  return addGroupsToDiv(), setGroupsModal(!groupsModal);
                }}
                icon="plus"
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark mx-2"
                size="sm"
              >
                {`Add ${selectedGroupRows.length} Groups`}
              </ButtonIcon>
              <ButtonIcon
                onClick={() => {
                  return setCreateGroupModal(!createGroupModal);
                }}
                icon="plus"
                transform="shrink-3"
                className="btn-tertiary border-dark mx-2"
                size="sm"
              >
                New Group
              </ButtonIcon>
              <ButtonIcon
                icon="trash"
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal);
                }}
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark mx-2 text-danger"
                size="sm"
              >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>

        <CardBody className="p-0">
          {groups?.loading || groupDelete?.loading ? (
            <div>
              <Loader />
            </div>
          ) : (
            <Fragment>
              <Card>
                <AdvanceTableWrapper
                  columns={columns}
                  data={groups}
                  sortable
                  pagination
                  perPage={50}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                >
                  <Row>
                    <Col md={8} lg={6}>
                      <AdvanceTableSearchBox table />
                    </Col>
                  </Row>

                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 fw-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pe-2 ps-3 ">
                    <AdvanceTableFooter
                      rowCount={groups?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
              </Card>
            </Fragment>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={createGroupModal}
        centered
        toggle={() => setCreateGroupModal(!createGroupModal)}
      >
        <ModalHeader
          toggle={() => {
            return setCreateGroupModal(!createGroupModal);
          }}
          className="d-flex flex-between-center border-bottom-0"
          close={closeCreateGroupBtn}
        >
          Let's give the group a name
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Form onSubmit={handleGroupSubmit}>
                <Label for="title">Group Name:</Label>
                <Input
                  value={newGroup.title.value}
                  onChange={e => {
                    setNewGroup({
                      ...newGroup,
                      [e.target.name]: e.target.value
                    });
                  }}
                  className="mb-3"
                  name="title"
                  id="title"
                />
                <Button
                  block
                  onClick={e => {
                    return (
                      e.preventDefault(),
                      dispatch(createGroup(newGroup)),
                      setCreateGroupModal(false)
                    );
                  }}
                  disabled={!newGroup.title}
                  className="text-primary btn-tertiary border-dark my-2"
                >
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showRenameModal}
        centered
        toggle={() => setShowRenameModal(!showRenameModal)}
      >
        <ModalHeader
          close={closeRenameBtn}
          className="text-center d-flex flex-between-center border-bottom-0"
        >
          Rename group
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label for="title">Group Name:</Label>
              <Input
                defaultValue={tempGroup?.title}
                value={group.title.value}
                onChange={handleGroupChange}
                className="mb-3"
                name="title"
                id="title"
              />
              <Button
                block
                onClick={() => {
                  return (
                    setShowRenameModal(false),
                    dispatch(
                      updateGroup({ title: group.title, _id: tempGroup._id })
                    )
                  );
                }}
                className="text-primary btn-tertiary border-dark my-2"
              >
                Save
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    return (
                      dispatch(deleteMultipleGroups(rowDelete)),
                      setShowDeleteModal(!showDeleteModal)
                    );
                  } else {
                    const deleteIds = selectedGroupRows.map(el => {
                      return el.original._id;
                    });
                    return (
                      dispatch(deleteMultipleGroups(deleteIds)),
                      setShowDeleteModal(!showDeleteModal)
                    );
                  }
                }}
                block
                className="text-danger btn-tertiary border-dark my-2"
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="text-primary btn-tertiary border-dark my-2"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // group Edit Modal NewGroupEdit
      }
      <Modal
        size="lg"
        isOpen={showEditGroupModal}
        centered
        toggle={() => setShowEditGroupModal(!showEditGroupModal)}
      >
        <ModalHeader
          className="d-flex flex-between-center border-bottom-0"
          close={closeBtn}
        >
        </ModalHeader>
        <ModalBody className="p-0">
          <GroupEdit
            tempGroupId={tempGroupId}
            groupTitle={groupTitle}
            showEditGroupModal={showEditGroupModal}
            setShowEditGroupModal={setShowEditGroupModal}
            fromCompose={fromCompose}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddGroupToTable;
