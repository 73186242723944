import React from 'react';
import { Card, CardBody, Row, Col, Label } from 'reactstrap';
import classNames from 'classnames';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';

const Overview = ({ bulkDetails }) => {

  const bulkTextDetailsDelivered = useSelector((state) => state.bulkTextDetailsDelivered)
  const { messages: deliveredMessages, loading: deliveredMessagesLoading, totalMessages: totalMessages } = bulkTextDetailsDelivered


  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }


  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-4">
              <Label>Message Preview</Label>
            </Col>
            <Col className="col-8">
              <div
                className={classNames(
                  'p-2 rounded-2 compose-preview-message',
                  {
                    'bg-200': false,
                    'bg-primary text-white': true
                  }
                )}
              >
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: `${bulkDetails?.messageBody}`
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>To:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs-9">{`${bulkDetails?.messageIds?.length} contacts`}</h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>From:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs-9">{`${formatPhoneNumber(bulkDetails?.from)}`}</h5>
              <h5 className="mb-0 fs-9">{`${bulkDetails?.nickName ? bulkDetails?.nickName : ''}`}</h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Date Sent:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs-9 mt-2">
                <Moment
                  format="MM/DD/YYYY hh:mm A"
                  date={bulkDetails?.dateSent}
                />
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Total cost:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs-9">{`${totalMessages * 0.04}`}</h5>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default Overview;
