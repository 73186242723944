import {
  LIST_BULK_SEND_FAILED_MESSAGES_REQUEST,
  LIST_BULK_SEND_FAILED_MESSAGES_SUCCESS,
  LIST_BULK_SEND_FAILED_MESSAGES_FAIL,
  LIST_BULK_SEND_FAILED_MESSAGES_RESET,
} from '../actions/types';

const initialState = {
  messages: [],
  totalMessages: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function bulkTextFailedReducer(state = initialState, action) {
  switch (action.type) {

    case LIST_BULK_SEND_FAILED_MESSAGES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_BULK_SEND_FAILED_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload?.messages,
        loading: false,
        totalMessages: action.payload?.totalMessages
      };

    case LIST_BULK_SEND_FAILED_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case LIST_BULK_SEND_FAILED_MESSAGES_RESET:
      return {
        ...state,
        messages: [],
        totalMessages: 0,
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

