import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { addContactsToMultipleGroups } from '../../actions';

const AddContactModal = ({
  selectedContactRows,
  showChooseGroupModal,
  setShowChooseGroupModal
}) => {
  const dispatch = useDispatch();

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;

  const [selectedGroups, setSelectedGroups] = useState([]);

  const animatedComponents = makeAnimated();

  return (
    <Modal
      isOpen={showChooseGroupModal}
      toggle={() => {
        setShowChooseGroupModal(!showChooseGroupModal);
      }}
    >
      <ModalHeader
        toggle={() => {
          setShowChooseGroupModal(!showChooseGroupModal);
        }}
      >
        Add to group
      </ModalHeader>
      <ModalBody>
        <Card>
          <CardHeader>Groups</CardHeader>
          <CardBody>
            <Select
              name="group"
              required={true}
              className="mb-3"
              components={animatedComponents}
              closeMenuOnSelect={true}
              options={groups}
              getOptionLabel={({ title }) => title}
              getOptionValue={({ _id }) => _id}
              onChange={props => {
                setSelectedGroups(
                  props?.map(el => {
                    return el?._id;
                  }) ?? []
                );
              }}
              isMulti
              placeholder="Select Group..."
              isSearchable={true}
            />
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={!selectedGroups?.length}
          className="btn-tertiary border-dark text-primary"
          onClick={() => {
            return (
              dispatch(
                addContactsToMultipleGroups(selectedContactRows, selectedGroups)
              ),
              setShowChooseGroupModal(!showChooseGroupModal)
            );
          }}
        >
          Add Contact
        </Button>
        <Button
          className="btn-tertiary border-dark text-danger"
          onClick={() => {
            return setShowChooseGroupModal(!showChooseGroupModal);
          }}
        >
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddContactModal;
