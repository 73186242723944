import {
  ADD_COMPOSE_CONTACT,
  ADD_COMPOSE_GROUP,
  ADD_COMPOSE_TEMPLATE,
  RESET_COMPOSE_GROUP
} from '../actions/types';

export default function composeReducer(state = [], action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_COMPOSE_GROUP:
      console.log('compose group payload', payload);
      const noUnSub = { groupCompose: payload.groupCompose };

      return [...state, noUnSub];

    case ADD_COMPOSE_CONTACT:
      console.log('compose contact payload', payload);
      return [...state, payload];

    case ADD_COMPOSE_TEMPLATE:
      return [...state, payload];

    case RESET_COMPOSE_GROUP:
      return [];

    default:
      return state;
  }
}

//    case 'ADD':
//      if (!payload) {
//        console.error('payload is required!');
//        return state;
//      }
//      if (state.find(item => item.id === payload.id)) {
//        toast(<span className="text-warning">Item already exists in the list!</span>);
//        return state;
//      }
//      if (isAddToStart) {
//        return [payload, ...state];
//      }
//      return [...state, payload];
//
//
//
//    case 'REMOVE':
//      if (id !== 0 && !id) {
//        console.error('id is required!');
//        return state;
//      }
//      return state.filter(item => item.id !== id);
//
//
//
//    case 'EDIT':
//      if (id !== 0 && !id) {
//        console.error('id is required!');
//        return state;
//      }
//      if (isUpdatedStart) {
//        const filteredState = state.filter(item => item.id !== id);
//        return [payload, ...filteredState];
//      }
//      return state.map(item => (item.id === id ? payload : item));
//
//
//
//    case 'SORT':
//      if (!sortBy || !order) {
//        console.error('sortBy and order, both are required!');
//        return state;
//      }
//      return orderBy(state, sortBy, order);
