import {
  PAY_SETTINGS_UPDATE_REQUEST,
  PAY_SETTINGS_UPDATE_SUCCESS,
  PAY_SETTINGS_UPDATE_FAIL,
  PAY_SETTINGS_UPDATE_RESET
} from '../actions/types';

export default function paySettingsUpdateReducer(
  state = { loading: false },
  action
) {
  switch (action.type) {
    case PAY_SETTINGS_UPDATE_REQUEST:
      return { loading: true };
    case PAY_SETTINGS_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PAY_SETTINGS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PAY_SETTINGS_UPDATE_RESET:
      return { loading: false };
    default:
      return state;
  }
}
