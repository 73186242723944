import {
  GROUP_DETAILS_REQUEST,
  GROUP_DETAILS_FAIL,
  GROUP_DETAILS_SUCCESS,
  LIST_GROUP_CONTACTS,
  RESET_GROUP_DETAILS
} from '../actions/types';

const initialState = {
  contacts: [],
  totalContacts: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function groupDetailsReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case GROUP_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GROUP_DETAILS_SUCCESS:
      const contactsAddingFullName = action.payload.contacts.map(el => {
        return { ...el, name: el.firstName + ' ' + el.lastName, key: el._id };
      });
      return { ...state, ...action.payload, loading: false, contacts: contactsAddingFullName };
    case GROUP_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case LIST_GROUP_CONTACTS:
      const updatedContacts = action.payload.contacts.map(el => {
        return { ...el, name: el.firstName + ' ' + el.lastName, key: el._id };
      });
      return {
        ...state,
        ...action.payload,
        contacts: updatedContacts,
        loading: false,
        totalContacts: action.payload.totalContacts
      };
    case RESET_GROUP_DETAILS:
      return initialState;
    default:
      return state;
  }
}
