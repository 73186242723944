import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Offcanvas, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Nav, NavItem, Collapse } from 'reactstrap';
import Avatar from 'components/common/Avatar';
import { logoutUser } from 'actions';
import { useAppContext } from 'providers/AppProvider';

const SettingsPanel = () => {
  const {
    config: { showSettingPanel },
    setConfig
  } = useAppContext();

  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth.user);
  const closeSidebar = () => setConfig('showSettingPanel', false);
  const [followUpOpen, setFollowUpOpen] = useState(false); // State to control the collapse

  const navigate = useNavigate();

  const isFollowUpPage = () => {
    const currentUrl = window.location.href; // Get the current URL
    return currentUrl.includes('follow-up'); // Check if the URL contains 'follow-up'
  };
  // Call the function to get the boolean value
  const isFollowUp = isFollowUpPage();

  return (
    <Offcanvas
      show={showSettingPanel}
      onHide={closeSidebar}
      placement="end"
      style={{
        maxWidth: '18rem',
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
      className="border-0"
    >
      <div
        className="px-1"
        style={{ display: 'flex', flexDirection: 'column', flex: 1 }}
      >
        <Nav vertical style={{ flex: 1, overflowY: 'auto' }}>
          <div
            className="border-bottom border-1"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '16px 8px'
            }}
          >
            <Row style={{ flex: 1 }}>
              <Col xs={2}>
                <Avatar
                  src={auth?.image}
                  name={
                    auth?.firstName
                      ? auth?.firstName
                      : auth?.username?.slice(0, 1)
                  }
                  size="xl"
                />
              </Col>
              <Col xs={8}>
                <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
                  <div style={{ overflow: 'hidden', marginTop: '7px' }}>
                    <h6
                      className="mb-0 fs-9"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {auth?.firstName && auth?.lastName
                        ? `${auth?.firstName} ${auth?.lastName}`
                        : auth?.firstName && !auth?.lastName
                          ? auth?.firstName
                          : auth?.username}
                    </h6>
                  </div>
                </div>
              </Col>
              <Col xs={2}>
                <Button
                  variant="link"
                  onClick={closeSidebar}
                  style={{ padding: 0 }}
                >
                  <FontAwesomeIcon
                    className="mt-2"
                    transform="grow-3"
                    icon="times"
                  />
                </Button>
              </Col>
            </Row>
          </div>
                  <NavItem
                    onClick={() => {
                      navigate('/follow-up/account-settings');
                      closeSidebar();
                    }}
                    className="ms-2 hover-primary hover-shadow cursor-pointer fs-9 d-flex align-items-center font-sans-serif p-2 mt-2"
                  >
                    <h5 className="fs-9 d-flex align-items-center">Settings</h5>
                  </NavItem>
                  <NavItem
                    onClick={() => {
                      navigate('/follow-up/billing');
                      closeSidebar();
                    }}
                    className="ms-4 hover-primary hover-shadow cursor-pointer fs-9 d-flex align-items-center font-sans-serif p-2"
                  >
                    <h5 className="fs-9 d-flex align-items-center">
                      Account & Billing{' '}
                    </h5>
                  </NavItem>
                <hr />
          <NavItem
            onClick={() => {
              return dispatch(logoutUser(navigate)), closeSidebar();
            }}
            className="ms-2 pt-4 text-danger hover-shadow cursor-pointer fs-9 d-flex align-items-center font-sans-serif p-2"
          >
            <FontAwesomeIcon
              transform="shrink-3"
              icon="arrow-right"
              className="me-2 mb-2"
            />
            <h5 className="fs-9 d-flex align-items-center text-danger">
              Log out{' '}
            </h5>
          </NavItem>
        </Nav>
      </div>
    </Offcanvas>
  );
};

export default SettingsPanel;
