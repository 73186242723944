import axios from 'axios';

import {
  //-------------------------------------------------Chat---------------------------------------------------------------------------------------------
  ADD_CHAT,
  LIST_CHATS,
  CHAT_CREATE_REQUEST,
  CHAT_CREATE_SUCCESS,
  CHAT_CREATE_FAIL,
  CHAT_DELETE_REQUEST,
  CHAT_DELETE_SUCCESS,
  CHAT_DELETE_FAIL,
  CHAT_UPDATE_REQUEST,
  CHAT_UPDATE_SUCCESS,
  CHAT_UPDATE_FAIL,
  CHAT_SWAP_INFO,
  LIST_CHAT_MESSAGES,
  FETCH_CHAT_MESSAGES_REQUEST,
  FETCH_CHAT_MESSAGES_SUCCESS,
  LIST_CHAT_MESSAGES_CREATE,
  CHAT_MESSAGES_CREATE_REQUEST,
  CHAT_MESSAGES_CREATE_SUCCESS,
  CHAT_MESSAGES_CREATE_FAIL,
  CHAT_MESSAGES_DELETE_REQUEST,
  CHAT_MESSAGES_DELETE_SUCCESS,
  CHAT_MESSAGES_DELETE_FAIL,
  UPDATE_ACTIVE_CHAT
} from './types';

export const listChats = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chats`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_CHATS, payload: res.data }));
  };
};

export const createChat = (contact, primaryNumber) => async dispatch => {
  try {
    dispatch({ type: CHAT_CREATE_REQUEST });

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/chats`,
      {
        contact,
        primaryNumber
      },
      {
        withCredentials: true
      }
    );

    const chatData = {
      ...data,
      contact: {
        _id: contact._id,
        firstName: contact.firstName,
        lastName: contact.lastName,
        phone_number: contact.phone_number
      }
    };

    dispatch({ type: CHAT_CREATE_SUCCESS, payload: chatData });
    dispatch({ type: ADD_CHAT, payload: chatData });
    dispatch({ type: CHAT_SWAP_INFO, payload: chatData });
    dispatch({ type: UPDATE_ACTIVE_CHAT, payload: chatData._id });

    return chatData._id; // return the new chat ID
  } catch (error) {
    dispatch({
      type: CHAT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateChatUnread = chat => async dispatch => {
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-update-unread`,
      method: 'POST',
      data: chat,
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS, payload: res.data }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const updateChatRead = chat => async dispatch => {
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-update-read`,
      method: 'POST',
      data: chat,
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS, payload: res.data }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const archiveChat = chat => async dispatch => {
  console.log('archiveChat', chat);
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-archive`,
      method: 'POST',
      data: chat, //   <--- clicking on convo from app
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const reOpenChat = chat => async dispatch => {
  console.log('archiveChat', chat);
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-reopen`,
      method: 'POST',
      data: chat, //   <--- clicking on convo from app
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const muteChat = chat => async dispatch => {
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-mute`,
      method: 'POST',
      data: chat,
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS, payload: res.data }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const unmuteChat = chat => async dispatch => {
  try {
    dispatch({ type: CHAT_UPDATE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-unmute`,
      method: 'POST',
      data: chat,
      withCredentials: true
    }).then(res => dispatch({ type: CHAT_UPDATE_SUCCESS, payload: res.data }));
  } catch (error) {
    dispatch({
      type: CHAT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteChat = chat => async dispatch => {
  try {
    dispatch({ type: CHAT_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-delete`,
      method: 'POST',
      data: { chat },
      withCredentials: true
    });

    dispatch({ type: CHAT_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CHAT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listChatMessages = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_CHAT_MESSAGES_CREATE });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-messages`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_CHAT_MESSAGES, payload: res }));
  };
};

export const listEachChatMessages = chat => {
  return async function (dispatch) {
    dispatch({ type: FETCH_CHAT_MESSAGES_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-each-messages`,
      method: 'POST',
      data: { chat },
      withCredentials: true
    }).then(res => {
      return (
        dispatch({ type: LIST_CHAT_MESSAGES, payload: res }),
        dispatch({ type: FETCH_CHAT_MESSAGES_SUCCESS })
      );
    });
  };
};

export const createChatMessages = newMessage => async dispatch => {
  try {
    dispatch({ type: CHAT_MESSAGES_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-messages`,
      method: 'POST',
      data: { newMessage },
      withCredentials: true
    });
    dispatch({ type: CHAT_MESSAGES_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CHAT_MESSAGES_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const deleteChatMessages = message => async dispatch => {
  try {
    dispatch({ type: CHAT_MESSAGES_DELETE_REQUEST });
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/chat-messages`,
      method: 'DELETE',
      data: { message },
      withCredentials: true
    });
    dispatch({ type: CHAT_MESSAGES_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CHAT_MESSAGES_DELETE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};
