import {
  DELETE_MATERIAL_REQUEST,
  DELETE_MATERIAL_SUCCESS,
  DELETE_MATERIAL_FAIL
} from '../actions/types';

export default function deleteCategoryReducer(state = {}, action) {
  switch (action.type) {
    case DELETE_MATERIAL_REQUEST:
      return { loading: true };
    case DELETE_MATERIAL_SUCCESS:
      return { loading: false, success: true };
    case DELETE_MATERIAL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
