import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import animationData from './lottie/warning-light.json';

const WizardModal = ({ toggle, modal, setModal }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Modal
      show={modal}
      onHide={toggle}
      centered
      size="md"
      style={{ width: '400px' }}
    >
      <Modal.Body className="p-4 position-relative">
        <Button
          className="text-danger position-absolute top-0 end-0 mr-2 mt-1 p-0 bg-transparent border-0"
          onClick={() => setModal(!modal)}
          style={{ zIndex: 1 }}
        >
          <FontAwesomeIcon icon={['fas', 'times']} />
        </Button>
        <div className="d-flex align-items-center justify-content-center">
          <Lottie options={defaultOptions} style={{ width: '100px' }} />
          <div className="ms-3">
            You don't have access to <br />
            the link. Please try again.
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WizardModal;
