import {
  CREATE_INVOICE_REQUEST,
  CREATE_INVOICE_SUCCESS,
  CREATE_INVOICE_FAIL,
  CREATE_INVOICE_RESET
} from '../actions/types';

export default function invoiceCreateReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_INVOICE_REQUEST:
      return { loading: true };
    case CREATE_INVOICE_SUCCESS:
      return { loading: false, success: true, newlyCreatedInvoiceId: action.payload };
    case CREATE_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_INVOICE_RESET:
      return {};
    default:
      return state;
  }
}
