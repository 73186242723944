import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Label, Badge } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Loader from '../common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconLightBox from '../common/FalconLightBox';
import { previewMMS } from '../../actions/index';

const ResponderMessageDetails = ({ message }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();

  const previewMedia = useSelector(state => state.previewMedia);
  const { success: successPreviewMedia } = previewMedia;
  useEffect(() => {
    if (message?.numMedia === '1') {
      dispatch(previewMMS(message?.mediaSubresourceUri));
    }
  }, []);

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  useEffect(() => {
    if (previewMedia?.payload) {
      setImage(Buffer.from(previewMedia?.payload, 'binary').toString('base64'));
    }
  }, [successPreviewMedia]);

  return (
    <div>
      <Card>
        <CardBody>
          <Row>
            <Col className="col-4">
              <Label>Incoming Message</Label>
            </Col>
            <Col className="col-8">
              <div
                className={classNames('p-2 rounded-soft chat-message', {
                  'bg-200': false,
                  'bg-primary text-white': true
                })}
              >
                {
                  //(message || message?.text) && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: `${message?.incomingMessageBody}`
                    }}
                  />
                }
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="col-4">
              <Label>Responders reply</Label>
            </Col>
            <Col className="col-8">
              {message.numMedia === '1' ? (
                <>
                  <div
                    className={classNames('p-2 rounded-soft chat-message', {
                      'bg-200': false,
                      'bg-soft-primary text-white': true
                    })}
                  >
                    {!previewMedia?.loading ? (
                      <FalconLightBox imgSrc={`data:image/*;base64,${image}`}>
                        <img
                          src={`data:image/*;base64,${image}`}
                          className="img-fluid rounded ml-2"
                          width={100}
                          height={100}
                          alt=""
                        />
                      </FalconLightBox>
                    ) : (
                      <div>
                        <Loader />
                      </div>
                    )}
                    {message?.body && (
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{ __html: message?.body }}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div
                  className={classNames('p-2 rounded-soft chat-message', {
                    'bg-200': false,
                    'bg-primary text-white': true
                  })}
                >
                  {
                    //(message || message?.text) && (
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: `${message?.body}` }}
                    />
                  }
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-2">
            <Col className="col-4">
              <Label>To:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs--1">
                {message?.name?.firstName
                  ? message?.name?.firstName + ' ' + message?.name?.lastName
                  : message?.to}
              </Badge>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>From:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs--1 mt-2">
                {formatPhoneNumber(message?.from)}
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Sent time:</Label>
            </Col>
            <Col className="col-8">
              <h5 className="mb-0 fs--1 mt-2">
                <Moment format="MM/DD/YYYY hh:mm A" date={message?.dateSent} />
              </h5>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Status:</Label>
            </Col>
            <Col className="col-8">
              {message?.status === 'delivered' ? (
                <Badge color="soft-success" pill className="fs--1">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-1"
                    className=" ml-1"
                  />
                </Badge>
              ) : message?.status === 'received' ? (
                <Badge color="soft-success" pill className="fs--1">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-1"
                    className=" ml-1"
                  />
                </Badge>
              ) : message?.errorMessage ? (
                <Badge color="soft-danger" pill className="fs--1">
                  {message?.errorMessage}
                  <FontAwesomeIcon
                    icon="times"
                    transform="shrink-1"
                    className=" ml-1"
                  />
                </Badge>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Segments:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs--1">
                {message?.numSegments}
              </Badge>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col className="col-4">
              <Label>Total cost:</Label>
            </Col>
            <Col className="col-8">
              <Badge color="soft-info" pill className="fs--1">
                {`${
                  message?.numMedia === '1' && message?.numSegments === '1'
                    ? '1.5'
                    : message?.numMedia === '1' &&
                      parseInt(message?.numSegments > 1)
                    ? (1.5 + parseInt(message?.numSegments)).toString()
                    : message?.numSegments
                } credits`}
              </Badge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default ResponderMessageDetails;
