import {
  DLC_CAMPAIGN_DELETE_REQUEST,
  DLC_CAMPAIGN_DELETE_SUCCESS,
  DLC_CAMPAIGN_DELETE_FAIL,
  DLC_CAMPAIGN_DELETE_RESET
} from '../actions/types';

export default function DLCCampaignDeleteReducer(state = {}, action) {
  switch (action.type) {
    case DLC_CAMPAIGN_DELETE_REQUEST:
      return { loading: true };
    case DLC_CAMPAIGN_DELETE_SUCCESS:
      return { loading: false, success: true };
    case DLC_CAMPAIGN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case DLC_CAMPAIGN_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
