import {
  DELETE_ITEM_REQUEST,
  DELETE_ITEM_SUCCESS,
  DELETE_ITEM_FAIL
} from '../actions/types';

export default function deleteItemReducer(state = {}, action) {
  switch (action.type) {
    case DELETE_ITEM_REQUEST:
      return { loading: true };
    case DELETE_ITEM_SUCCESS:
      return { loading: false, success: true };
    case DELETE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
