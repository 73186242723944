import React, { useEffect, useRef, useState, Fragment } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  UncontrolledTooltip
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import rrulePlugin from '@fullcalendar/rrule';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarEventModal from './CalendarEventModal';
import ScheduleBulkText from './ScheduleBulkText';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getPageName } from 'helpers/utils';
import ScheduleTimev2 from 'components/compose/ScheduleTimev2';
import { listCalendar, listFields } from '../../actions/index';
import { UPDATE_MONTH_VIEW, CALENDAR_UPDATE_RESET } from '../../actions/types';
import { useAppContext } from 'providers/AppProvider';

//                                                  showTimeModal and set are for compose
const Calendar = ({
  showCalendar,
  setShowCalendar,
  recipients,
  showDetailModal,
  setShowDetailModal,
  eventId,
  setEventId,
  calendarView,
  setCalendarView,
  showTimeModal,
  setShowTimeModal,
  isOpenScheduleModal,
  setIsOpenScheduleModal
}) => {
  const calendarRef = useRef();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [calendarApi, setCalendarApi] = useState({});
  const [title, setTitle] = useState('');
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState(false);
  const [addScheduleStartDate, setAddScheduleStartDate] = useState();
  const [addScheduleEndDate, setAddScheduleEndDate] = useState();
  const [fromCalendar, setFromCalendar] = useState(true);
  const [showViewModal, setShowViewModal] = useState(false);
  const [view, setView] = useState('Month View');
  const onMsgCreateForm = getPageName('sms-compose');
  const calendar = useSelector(state => state.calendar);


  const {
    config: { isDark }
  } = useAppContext();

  //----------------------------Redux state
  const calendarCreate = useSelector(state => state.calendarCreate);
  const { success: successCreate } = calendarCreate;

  const calendarDelete = useSelector(state => state.calendarDelete);
  const { success: successDelete } = calendarDelete;

  const calendarUpdate = useSelector(state => state.calendarUpdate);
  const { success: successUpdate } = calendarUpdate;

  const buttonText = {
    today: 'Today',
    month: 'Month view',
    week: 'week',
    day: 'day',
    listWeek: 'list view',
    listYear: 'year'
  };

  const eventTimeFormat = {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: true,
    meridiem: true
  };

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View',
    'Year View'
  ];
  const composeViewName = ['Month View', 'Week View', 'Day View'];

  const views = {
    week: {
      eventLimit: 3
    }
  };

  useEffect(() => {
    if (successUpdate) {
      toast(
        <Fragment>
          <strong>Campaign Updated</strong>
        </Fragment>
      );
      dispatch({ type: CALENDAR_UPDATE_RESET });
    }
  }, [successUpdate]);

  useEffect(() => {
    if (currentFilter === 'Month View') {
      dispatch({ type: UPDATE_MONTH_VIEW });
    }
  }, [currentFilter]);

  useEffect(() => {
    if (auth?.user?.timezone) {
      setCalendarApi(calendarRef.current.getApi());
    }
  }, [auth]);

  useEffect(() => {
    // dispatch(listContacts("", 1, 100))
    dispatch(listCalendar());
    dispatch(listFields());
  }, [successCreate, successDelete, successUpdate]);

  const handleFilter = filter => {
    !onMsgCreateForm ? setCurrentFilter(filter) : setCalendarView(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('listYear');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleEventClick = info => {
    if (info.event.url) {
      window.open(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      //console.log("info", info)
      setModalEventContent(info);
      setIsOpenModal(true);
    }
  };

  const closeTimeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowTimeModal(!showTimeModal);
      }}
    ></button>
  );

  return (
    <>
      <Card className="mb-3">
        <CardHeader>
          <Row className="align-items-center g-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <UncontrolledTooltip placement="bottom" target="previous">
                Previous
              </UncontrolledTooltip>
              <UncontrolledTooltip placement="bottom" target="next">
                Next
              </UncontrolledTooltip>
              <Button
                onClick={() => {
                  calendarApi.prev();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                id="previous"
              >
                <FontAwesomeIcon icon="arrow-left" />
              </Button>
              <Button
                onClick={() => {
                  calendarApi.next();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
                color="link"
                className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1"
                id="next"
              >
                <FontAwesomeIcon icon="arrow-right" />
              </Button>
            </Col>
            {!auth?.user?.timezone ? (
              <Col xs="auto" md="auto" className="order-md-2">
                <h4 className="mb-0 fs-9 calendar-title">Loading...</h4>
              </Col>
            ) : (
              <Col xs="auto" md="auto" className="order-md-2">
                <h4 className="mb-0 fs-8 calendar-title">
                  {title ||
                    `${calendarApi.currentDataManager?.data?.viewTitle}`}
                </h4>
              </Col>
            )}

            <Col
              xs
              md={!onMsgCreateForm ? 'auto' : ''}
              className="d-flex justify-content-end order-md-3"
            >
              <Button
                size="sm"
                className="btn-tertiary border-dark text-primary ms-1"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col md="auto" className="d-md-none">
              <hr />
            </Col>
            {!onMsgCreateForm && (
              <Col xs="auto" className="d-flex order-md-0">
                <Button
                  className="btn-tertiary border"
                  size="sm"
                  onClick={() => {
                    return setIsOpenScheduleModal(true), setFromCalendar(true);
                  }}
                >
                  <FontAwesomeIcon icon="plus" className="me-1" /> New
                </Button>
              </Col>
            )}
            {!onMsgCreateForm && (
              <Col className="d-flex justify-content-end order-md-2">
                <Button
                  className="btn-tertiary border-dark text-primary"
                  onClick={() => {
                    setShowViewModal(!showViewModal);
                  }}
                  size="sm"
                  color="falcon-primary"
                >
                  {view}
                  <FontAwesomeIcon className="ms-2" icon="sort" />
                </Button>
              </Col>
            )}
          </Row>
        </CardHeader>

        <div>
          <CardBody className="p-0">
            <FullCalendar
              ref={calendarRef}
              headerToolbar={false}
              plugins={[
                rrulePlugin,
                interactionPlugin,
                dayGridPlugin,
                timeGridPlugin,
                bootstrapPlugin,
                listPlugin,
                momentTimezonePlugin
              ]}
              timeZone={auth?.user?.timezone}
              initialView="dayGridMonth"
              themeSystem="bootstrap"
              dayMaxEvents={2}
              height={800}
              stickyHeaderDates={false}
              selectable
              selectMirror
              select={info => {
                const newD = new Date(info.start);
                const getOffset = (timeZone = 'UTC', date = newD) => {
                  const utcDate = new Date(
                    date.toLocaleString('en-US', { timeZone: 'UTC' })
                  );
                  const tzDate = new Date(
                    date.toLocaleString('en-US', { timeZone })
                  );
                  return (tzDate.getTime() - utcDate.getTime()) / 6e4;
                };
                // remove the accounts chosen timezone offset
                const rmvCusOffset = newD.setTime(
                  newD.getTime() + getOffset(auth?.user?.timezone) * 60_000
                );
                const cusDD = new Date(rmvCusOffset);
                // add the local offset whole point of this is to create a new Date object and pass it to the react-datepicker. it needs a date object to work
                const addLocalOffset = cusDD.setTime(
                  cusDD.getTime() + new Date().getTimezoneOffset() * 60_000
                );

                if (new Date(addLocalOffset) < moment().subtract(1, 'day')) {
                  // if one day in the past, select future date
                  toast(
                    <Fragment>
                      <strong>Select Future date</strong>
                    </Fragment>
                  );
                } else if (onMsgCreateForm) {
                  return (
                    calendarView === 'Month View'
                      ? setShowTimeModal(!showTimeModal)
                      : '',
                    setAddScheduleStartDate(new Date(addLocalOffset))
                  );
                } else {
                  setAddScheduleStartDate(new Date(addLocalOffset));
                  setIsOpenScheduleModal(true);
                }
              }}
              views={views}
              eventTimeFormat={eventTimeFormat}
              eventClick={handleEventClick}
              events={calendar}
              eventStartEditable={false}
              buttonText={buttonText}
              eventContent={(eventInfo) => {
                const event = eventInfo.event.extendedProps; // Access custom properties
                const isCompactView = ['dayGridMonth', 'timeGridWeek', 'timeGridDay'].includes(
                  eventInfo.view.type
                ); // Check if in month, week, or day view

                // Shared styles
                const eventStyles = {
                  container: {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: isCompactView ? '4px 6px' : '8px',
                    borderRadius: '4px',
                    border: '1px solid #ddd',
                    fontSize: isCompactView ? '0.8rem' : '0.9rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: isCompactView ? '150px' : '200px',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                  },
                  title: {
                    fontWeight: 'bold',
                    fontSize: isCompactView ? '0.9rem' : '1rem',
                    color: isDark ? '#e0e0e0' : '#555',
                    marginBottom: '2px',
                  },
                  time: {
                    fontSize: '0.7rem',
                    color: isDark ? '#e0e0e0' : '#555',
                    marginBottom: '2px',
                  },
                  detail: {
                    fontSize: '0.7rem',
                    color: isDark ? '#e0e0e0' : '#555',
                  },
                };

                if (isCompactView) {
                  // Compact view for month, week, or day
                  return (
                    <div style={eventStyles.container}>
                      <div style={eventStyles.title}>{eventInfo.event.title || 'No Title'}</div>
                      <div style={eventStyles.time}>{eventInfo.timeText}</div>
                      {event.messageDetails?.contacts?.length > 0 && (
                        <div style={eventStyles.detail}>
                          {event.messageDetails.contacts.length} Recipient
                          {event.messageDetails.contacts.length > 1 ? 's' : ''}
                        </div>
                      )}
                      {event.messageDetails?.groups?.length > 0 && (
                        <div style={eventStyles.detail}>
                          {event.messageDetails.groups.length} Group
                          {event.messageDetails.groups.length > 1 ? 's' : ''}
                        </div>
                      )}
                    </div>
                  );
                } else {
                  // Detailed view for list or yearly
                  return (
                    <div style={eventStyles.container}>
                      <div style={eventStyles.title}>{eventInfo.event.title || 'Untitled Campaign'}</div>
                      <div style={eventStyles.time}>
                        Scheduled: {new Date(eventInfo.event.start).toLocaleString()}
                      </div>
                      {event.messageDetails?.contacts?.length > 0 && (
                        <div style={eventStyles.detail}>
                          Recipients: {event.messageDetails.contacts.length} Contact(s)
                        </div>
                      )}
                      {event.messageDetails?.groups?.length > 0 && (
                        <div style={eventStyles.detail}>
                          Groups: {event.messageDetails.groups.length}
                        </div>
                      )}
                      {event.messageContents?.plainText && (
                        <div style={eventStyles.detail}>
                          Message: {event.messageContents.plainText.length} characters
                        </div>
                      )}
                      {event.messageDetails?.bulkNumber && (
                        <div style={eventStyles.detail}>
                          Sender: {JSON.parse(event.messageDetails.bulkNumber)?.phoneNumber}
                        </div>
                      )}
                      {event.repeatCampaign !== 'noRepeat' && (
                        <div style={eventStyles.detail}>Repeats: {event.repeatCampaign}</div>
                      )}
                    </div>
                  );
                }
              }}


            />
          </CardBody>
        </div>
      </Card>
      {
        // addStart date modal---------------------------------------
      }
      <Modal
        size={'lg'}
        isOpen={showTimeModal}
        centered
        toggle={() => {
          setShowTimeModal(!showTimeModal);
        }}
      >
        <ModalHeader close={closeTimeBtn}>What time should it run?</ModalHeader>
        <ModalBody className="p-0">
          <ScheduleTimev2
            showTimeModal={showTimeModal}
            setShowTimeModal={setShowTimeModal}
            recipients={recipients}
            showCalendar={showCalendar}
            setShowCalendar={setShowCalendar}
            composeViewName={composeViewName}
            addScheduleStartDate={addScheduleStartDate}
          />
        </ModalBody>
      </Modal>

      {!onMsgCreateForm && (
        <Modal
          isOpen={showViewModal}
          centered
          toggle={() => {
            setShowViewModal(!showViewModal);
          }}
        >
          <ModalHeader
            toggle={() => {
              setShowViewModal(!showViewModal);
            }}
            className="d-flex justify-content-center"
          >
            Calendar view
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 fw-normal p-4">
                {viewName.map((el, i) => (
                  <Button
                    key={i}
                    onClick={() => {
                      return (
                        handleFilter(el), setView(el), setShowViewModal(false)
                      );
                    }}
                    block
                    className="btn-tertiary border-dark mb-1"
                  >
                    {el}
                  </Button>
                ))}
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      )}

      <ScheduleBulkText
        eventId={eventId}
        setEventId={setEventId}
        fromCalendar={fromCalendar}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
        addScheduleStartDate={addScheduleStartDate}
        setAddScheduleStartDate={setAddScheduleStartDate}
        addScheduleEndDate={addScheduleEndDate}
        setAddScheduleEndDate={setAddScheduleEndDate}
        modalEventContent={modalEventContent}
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        showDetailModal={showDetailModal}
        setShowDetailModal={setShowDetailModal}
      />

      <CalendarEventModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        modalEventContent={modalEventContent}
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
      />
    </>
  );
};

export default Calendar;
