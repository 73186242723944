import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Button,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { DLCUploadContext } from '../../../context/Context';
import { useDispatch, useSelector } from 'react-redux';
import { listBrand } from 'actions';
import { BRAND_CREATE_RESET, BRAND_UPDATE_RESET } from 'actions/types';
import { Alert } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';

const StepTwoForm = () => {
  const {
    upload,
    setUpload,
    formattedEIN,
    setFormattedEIN,
    step,
    setStep,
    handleInputChange
  } = useContext(DLCUploadContext);
  console.log('formattedEIn in setpTwoForm', formattedEIN);

  const dispatch = useDispatch();

  const {
    config: { isDark }
  } = useAppContext();

  const { brand, loading: brandLoading } = useSelector(state => state.brand);
  const brandCreate = useSelector(state => state.brandCreate);
  const { loading: brandCreateLoading, success: brandCreateSuccess } =
    brandCreate;
  const brandUpdate = useSelector(state => state.brandUpdate);
  const { loading: brandUpdateLoading, success: brandUpdateSuccess } =
    brandUpdate;

  useEffect(() => {
    if (upload?.businessRegistrationNumber) {
      setFormattedEIN(formatEIN(upload?.businessRegistrationNumber));
      setUpload(prev => ({
        ...prev,
        businessRegistrationNumber: upload?.businessRegistrationNumber
      }));
    }
  }, []);

  const [websiteError, setWebsiteError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [validateInputs, setValidateInputs] = useState(false);

  const validateWebsite = url => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(url);
  };

  const validatePhoneNumber = phone => {
    const pattern = new RegExp('^\\+?[1-9]\\d{1,14}$'); // E.164 format
    return pattern.test(phone);
  };

  const handleWebsiteChange = ({ target }) => {
    setUpload({ ...upload, businessWebsite: target.value });
    if (!validateWebsite(target.value)) {
      setWebsiteError('Please enter a valid website URL.');
    } else {
      setWebsiteError('');
    }
  };

  const handlePhoneChange = (phoneValue) => {
    console.log('phoneValue', phoneValue);
    setUpload({ ...upload, businessContactPhone: phoneValue });

    if (!validatePhoneNumber(phoneValue)) {
      setPhoneError('Please enter a valid phone number.');
    } else {
      setPhoneError('');
    }
  };


  useEffect(() => {
    dispatch(listBrand());
  }, [brandCreateSuccess, brandUpdateSuccess]);

  const handleNext = () => {
    setValidateInputs(true);
    let valid = true;
    if (!upload?.businessName) valid = false;
    if (!upload?.businessType) valid = false;
    if (!upload?.industry) valid = false;
    if (!upload?.businessWebsite || !!websiteError) valid = false;
    if (!upload?.businessStreet) valid = false;
    if (!upload?.businessCity) valid = false;
    if (!upload?.businessPostalCode) valid = false;
    if (!upload?.businessStateProvinceRegion) valid = false;
    if (!upload?.businessCountry) valid = false;
    if (!upload?.businessContactFirstName) valid = false;
    if (!upload?.businessContactLastName) valid = false;
    if (!upload?.businessContactPhone || !!phoneError) valid = false;
    if (!upload?.businessContactEmail) valid = false;
    if (!upload?.businessRegistrationNumber) valid = false;

    if (valid) {
      // proceed to the next step
      setStep(step + 1);
    } else {
      // trigger validation errors
      setWebsiteError(
        !upload?.businessWebsite ? 'Please enter a valid website URL.' : ''
      );
      setPhoneError(
        !upload?.businessContactPhone
          ? 'Please enter a valid phone number.'
          : ''
      );
    }
  };

  useEffect(() => {
    if (!brandCreateLoading && brandCreateSuccess) {
      setStep(step + 1);
      dispatch({ type: BRAND_CREATE_RESET });
    }
  }, [brandCreate]);

  useEffect(() => {
    if (!brandUpdateLoading && brandUpdateSuccess) {
      setUpload({
        ...upload,
        businessName: brandUpdate?.brand?.businessName,
        businessType: brandUpdate?.brand?.businessType,
        industry: brandUpdate?.brand?.industry,
        businessWebsite: brandUpdate?.brand?.businessWebsite,
        businessStreet: brandUpdate?.brand?.address.businessStreet,
        businessCity: brandUpdate?.brand?.address.businessCity,
        businessPostalCode: brandUpdate?.brand?.address.businessPostalCode,
        businessStateProvinceRegion:
          brandUpdate?.brand?.address.businessStateProvinceRegion,
        businessCountry: brandUpdate?.brand?.address.businessCountry,
        businessContactFirstName:
          brandUpdate?.brand?.contactDetails.businessContactFirstName,
        businessContactLastName:
          brandUpdate?.brand?.contactDetails.businessContactLastName,
        businessContactPhone:
          brandUpdate?.brand?.contactDetails.businessContactPhone,
        businessContactEmail:
          brandUpdate?.brand?.contactDetails.businessContactEmail,
        businessRegistrationNumber:
          brandUpdate?.brand?.businessRegistrationNumber
      });
      setFormattedEIN(
        formatEIN(brandUpdate?.brand?.businessRegistrationNumber)
      );
      setStep(step + 1);
      dispatch({ type: BRAND_UPDATE_RESET });
    }
  }, [brandUpdate]);

  const verticalArray = [
    { dbTitle: 'AGRICULTURE', title: 'Agriculture' },
    { dbTitle: 'AUTOMOTIVE', title: 'Automotive' },
    { dbTitle: 'BANKING', title: 'Banking' },
    { dbTitle: 'CONSTRUCTION', title: 'Construction' },
    { dbTitle: 'CONSUMER', title: 'Consumer' },
    { dbTitle: 'EDUCATION', title: 'Education' },
    { dbTitle: 'ELECTRONICS', title: 'Electronics' },
    { dbTitle: 'ENGINEERING', title: 'Engineering' },
    { dbTitle: 'ENERGY', title: 'Energy' },
    {
      dbTitle: 'FAST_MOVING_CONSUMER_GOODS',
      title: 'Fast Moving Consumer Goods'
    },
    { dbTitle: 'FINANCIAL', title: 'Financial' },
    { dbTitle: 'FINTECH', title: 'Fintech' },
    { dbTitle: 'FOOD_AND_BEVERAGE', title: 'Food and Beverage' },
    { dbTitle: 'GOVERNMENT', title: 'Government' },
    { dbTitle: 'HEALTHCARE', title: 'Healthcare' },
    { dbTitle: 'HOSPITALITY', title: 'Hospitality' },
    { dbTitle: 'INSURANCE', title: 'Insurance' },
    { dbTitle: 'JEWELRY', title: 'Jewelry' },
    { dbTitle: 'LEGAL', title: 'Legal' },
    { dbTitle: 'MANUFACTURING', title: 'Manufacturing' },
    { dbTitle: 'MEDIA', title: 'Media' },
    { dbTitle: 'NOT_FOR_PROFIT', title: 'Not for Profit' },
    { dbTitle: 'OIL_AND_GAS', title: 'Oil and Gas' },
    { dbTitle: 'ONLINE', title: 'Online' },
    { dbTitle: 'PROFESSIONAL_SERVICES', title: 'Professional Services' },
    { dbTitle: 'RAW_MATERIALS', title: 'Raw Materials' },
    { dbTitle: 'REAL_ESTATE', title: 'Real Estate' },
    { dbTitle: 'RELIGION', title: 'Religion' },
    { dbTitle: 'RETAIL', title: 'Retail' },
    { dbTitle: 'TECHNOLOGY', title: 'Technology' },
    { dbTitle: 'TELECOMMUNICATIONS', title: 'Telecommunications' },
    { dbTitle: 'TRANSPORTATION', title: 'Transportation' },
    { dbTitle: 'TRAVEL', title: 'Travel' }
  ];

  const stockExchanges = {
    AMEX: 'NYSE American (AMEX)',
    AMX: 'Amsterdam Stock Exchange (AMX)',
    ASX: 'Australian Securities Exchange (ASX)',
    B3: 'B3 - Brasil Bolsa Balcão (B3)',
    BME: 'Bolsa de Madrid (BME)',
    BSE: 'Bombay Stock Exchange (BSE)',
    FRA: 'Frankfurt Stock Exchange (FRA)',
    ICEX: 'Iceland Stock Exchange (ICEX)',
    JPX: 'Japan Exchange Group (JPX)',
    JSE: 'Johannesburg Stock Exchange (JSE)',
    KRX: 'Korea Exchange (KRX)',
    LON: 'London Stock Exchange (LON)',
    NASDAQ: 'NASDAQ Stock Market (NASDAQ)',
    NONE: 'None',
    NYSE: 'New York Stock Exchange (NYSE)',
    NSE: 'National Stock Exchange of India (NSE)',
    OMX: 'Nasdaq Nordic (OMX)',
    OTHER: 'Other',
    SEHK: 'Hong Kong Stock Exchange (SEHK)',
    SGX: 'Singapore Exchange (SGX)',
    SSE: 'Shanghai Stock Exchange (SSE)',
    STO: 'Stockholm Stock Exchange (STO)',
    SWX: 'Swiss Exchange (SWX)',
    SZSE: 'Shenzhen Stock Exchange (SZSE)',
    TSX: 'Toronto Stock Exchange (TSX)',
    TWSE: 'Taiwan Stock Exchange (TWSE)',
    VSE: 'Vienna Stock Exchange (VSE)'
  };

  const formatEIN = (digits) => {
    // digits is something like "123456789" (0-9 digits only).
    // If length > 2, insert a dash after first 2.
    if (digits.length > 2) {
      return digits.slice(0, 2) + '-' + digits.slice(2, 9);
    }
    return digits;
  };

  const isEINValid = ein => {
    const einRegex = /^\d{2}-\d{7}$/;
    return einRegex.test(ein);
  };

  const handleEINChange = (e) => {
    // 1. Strip all non-digits:
    let rawDigits = e.target.value.replace(/\D/g, ''); // e.g. "1234567891"

    // 2. Truncate to max 9 digits
    rawDigits = rawDigits.slice(0, 9); // e.g. "123456789"

    // 3. Format for UI
    const formattedValue = formatEIN(rawDigits); // => "12-3456789"

    // 4. Update your local state for display
    setFormattedEIN(formattedValue);

    // 5. Store the raw 9 digits in the upload object
    setUpload({
      ...upload,
      businessRegistrationNumber: rawDigits // e.g. "123456789"
    });
  };


  return (
    <Fragment>
      <h5 className=" mb-3">Organization details</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            Legal organization name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessName}
            invalid={validateInputs && !upload?.businessName}
            className="mb-3"
            name="businessName"
          ></Input>

          <Label>
            Business type<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessType}
            invalid={validateInputs && !upload?.businessType}
            name="businessType"
            type="select"
            className="mb-3"
          >
            <option value="" disabled selected>
              Select
            </option>
            <option value="Partnership">Partnership</option>
            <option value="Corporation">Corporation</option>
            <option value="Co-operative">Co-operative</option>
            <option value="Non-profit corporation">Non profit</option>
          </Input>

          <Label>
            Company type<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.companyType}
            invalid={validateInputs && !upload?.companyType}
            name="companyType"
            type="select"
            className="mb-3"
          >
            <option value="" disabled selected>
              Select option
            </option>
            <option value="private">Private</option>
            <option value="public">Public</option>
            <option value="non_profit">non_profit</option>
          </Input>

          {upload?.companyType === 'public' && (
            <div>
              <Label>
                Stock exchange<strong className="text-danger">*</strong>
              </Label>
              <Input
                onChange={handleInputChange}
                value={upload?.stock_exchange}
                invalid={validateInputs && !upload?.stock_exchange}
                name="stock_exchange"
                type="select"
                className="mb-3"
              >
                <option value="" disabled selected>
                  Select
                </option>
                {Object.entries(stockExchanges).map(([value, label]) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Input>

              <Label>
                Stock ticker<strong className="text-danger">*</strong>
              </Label>
              <Input
                onChange={handleInputChange}
                value={upload?.stock_ticker}
                invalid={validateInputs && !upload?.stock_ticker}
                className="mb-3"
                name="stock_ticker"
              ></Input>
            </div>
          )}
        </Col>
        <Col xs={12} md={6}>
          <Label>
            Vertical type / Industry<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.industry}
            invalid={validateInputs && !upload?.industry}
            name="industry"
            type="select"
            className="mb-3"
          >
            <option value="" disabled selected>
              Select
            </option>
            {verticalArray.map((vertical, index) => (
              <option key={index} value={vertical.dbTitle}>
                {vertical.title}
              </option>
            ))}
          </Input>

          <Label>
            Organization website<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleWebsiteChange}
            value={upload?.businessWebsite}
            className="mb-3"
            name="businessWebsite"
            invalid={
              validateInputs && (!!websiteError || !upload?.businessWebsite)
            }
          />
          <FormFeedback>{websiteError}</FormFeedback>

          <Label>
            Tax Number / EIN<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleEINChange}
            value={formattedEIN}
            className="mb-3"
            name="businessRegistrationNumber"
            invalid={
              validateInputs && !isEINValid(upload?.businessRegistrationNumber)
            }
          />
          <FormFeedback>EIN must be in the format 11-1111111</FormFeedback>
        </Col>
      </Row>
      <h5 className=" mb-3 mt-4">Organization address</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            Address / street<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessStreet}
            invalid={validateInputs && !upload?.businessStreet}
            className="mb-3"
            name="businessStreet"
          ></Input>

          <Label>
            City<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessCity}
            invalid={validateInputs && !upload?.businessCity}
            className="mb-3"
            name="businessCity"
          ></Input>

          <Label>
            Postal code<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessPostalCode}
            invalid={validateInputs && !upload?.businessPostalCode}
            className="mb-3"
            name="businessPostalCode"
          ></Input>
        </Col>
        <Col xs={12} md={6}>
          <Label>
            State / Region<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessStateProvinceRegion}
            invalid={validateInputs && !upload?.businessStateProvinceRegion}
            className="mb-3"
            name="businessStateProvinceRegion"
          ></Input>

          <Label>
            Country<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessCountry}
            invalid={validateInputs && !upload?.businessCountry}
            name="businessCountry"
            type="select"
            className="mb-3"
          >
            <option value="" disabled selected>
              Select
            </option>
            <option key={1} value="US">
              United States
            </option>
            <option key={2} value="CA">
              Canada
            </option>
          </Input>
        </Col>
      </Row>

      <h5 className=" mb-3 mt-4">Organization contact details</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            First name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactFirstName}
            invalid={validateInputs && !upload?.businessContactFirstName}
            className="mb-3"
            name="businessContactFirstName"
          ></Input>

          <Label>
            Last name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactLastName}
            invalid={validateInputs && !upload?.businessContactLastName}
            className="mb-3"
            name="businessContactLastName"
          ></Input>
        </Col>
        <Col xs={12} md={6}>
          <Label>
            Phone number<strong className="text-danger">*</strong>
          </Label>
          <div className="flex-grow-1 mb-3">
            <PhoneInput
              country="us"
              countryCodeEditable={false}
              dropdownClass="rounded"
              preferredCountries={['us', 'ca', 'mx', 'fr', 'it', 'br', 'co', 'gr']}
              limitMaxLength={true}
              enableSearch={true}
              name="businessContactPhone"
              value={upload?.businessContactPhone}
              onChange={(phoneValue) => handlePhoneChange(phoneValue)}
              inputStyle={{ width: '100%', height: '55px' }}
              inputClass={`w-100 bg-${isDark ? 'dark' : 'light'}`}
            />
          </div>
          <FormFeedback>{phoneError}</FormFeedback>

          <Label>
            Email address<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactEmail}
            invalid={validateInputs && !upload?.businessContactEmail}
            className="mb-3"
            name="businessContactEmail"
          ></Input>
        </Col>
      </Row>
      <Row>
        <Row>
          <Col xs={12} className=" mt-4">
            <Alert color="info">
              Brand registration costs $5 You will be forward to payment in the
              last step.
            </Alert>
          </Col>
        </Row>

        <div className="d-flex justify-content-end mt-3">
          <Button onClick={handleNext} color="primary">
            {brandCreateLoading || brandUpdateLoading ? <Spinner /> : 'Next'}
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};

export default StepTwoForm;
