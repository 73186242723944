import {
  KEYWORD_UPDATE_REQUEST,
  KEYWORD_UPDATE_SUCCESS,
  KEYWORD_UPDATE_FAIL,
  KEYWORD_UPDATE_RESET
} from '../actions/types';

export default function keywordUpdateReducer(state = {}, action) {
  switch (action.type) {
    case KEYWORD_UPDATE_REQUEST:
      return { loading: true };
    case KEYWORD_UPDATE_SUCCESS:
      return { loading: false, success: true, updatedKeyword: action.payload };
    case KEYWORD_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case KEYWORD_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
