import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useMemo
} from 'react';
import {
  Input,
  Table,
  Card,
  CardBody,
  Form,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  FormGroup
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CsvUploadContext } from 'context/Context';
import { listFields, createField } from 'actions/index';
import { toast } from 'react-toastify';

const StepThreeForm = ({ register, errors }) => {
  const { upload, setUpload, step } = useContext(CsvUploadContext);
  const { handleInputChange } = useContext(CsvUploadContext);

  const [exclude, setExclude] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [field, setField] = useState({
    title: ''
  });

  const dispatch = useDispatch();
  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);

  const fieldCreate = useSelector(state => state.fieldCreate);
  const { success: successCreate } = fieldCreate;

  const fileContacts = useMemo(() => upload?.contacts[0], []);

  const TableRow = ({ data, index }) => {
    if (typeof data !== 'string') {
      return (
        <tr
          className={
            exclude && index === 0
              ? 'text-danger font-weight-bold align-middle'
              : 'align-middle'
          }
        >
          {data.map((customer, index) => {
            return (
              <td className="text-nowrap" style={{ minWidth: 155 }} key={index}>
                {customer}
              </td>
            );
          })}
        </tr>
      );
    } else {
      return (
        <tr
          className={
            exclude && index === 0
              ? 'text-danger font-weight-bold align-middle'
              : 'align-middle'
          }
        >
          <td className="text-nowrap" style={{ minWidth: 155 }} key={index}>
            {data}
          </td>
        </tr>
      );
    }
  };

  useEffect(() => {
    dispatch(listFields());
  }, [successCreate]);

  useEffect(() => {
    if (!successCreate) return;
    toast(
      <Fragment>
        <strong>Field Created</strong>
      </Fragment>
    );
  }, [successCreate]);

  const handleFieldChange = e => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const handleFieldSubmit = () => {
    dispatch(createField(field));
    setShowFieldModal(!showFieldModal);
  };

  return (
    <Fragment>
      <p className="text-center">
        <strong>
          This is a preview of the imported data. Please match the columns below
          to the data fields. Choose correct columns for your data.
        </strong>
      </p>
      <Table responsive bordered striped hover>
        <thead>
          <tr>
            {upload?.uploadType === 'files'
              ? fileContacts.map((contact, index) => (
                <th
                  className="bg-primary px-1 pt-2 pb-2 overflow-hidden"
                  scope="col"
                  key={index}
                >
                  <Input
                    type="select"
                    className="fs--1"
                    name="selectHeader"
                    id={`selectHeader${index}`}
                    onChange={({ target }) => {
                      if (target.value === '+ Create new') {
                        document.getElementById(
                          `selectHeader${index}`
                        ).value = ' ';
                        setShowFieldModal(!showFieldModal);
                      } else {
                        setUpload({
                          ...upload,
                          selectHeader: [
                            ...upload.selectHeader.filter(
                              ({ value, HeaderIndex }) =>
                                value != target.value && index != HeaderIndex
                            ),
                            {
                              customHeader: target.value,
                              HeaderIndex: fileContacts.indexOf(contact)
                            }
                          ]
                        });
                      }
                    }}
                  >
                    <option>Do not Import</option>
                    <option>First name</option>
                    <option>Last name</option>
                    <option>Phone</option>
                    <option>Email</option>
                    <option>Company</option>
                    {fields.map((field, index) => (
                      <option key={index}>{field.title}</option>
                    ))}
                    <option>+ Create new</option>
                  </Input>
                </th>
              ))
              : upload?.contacts?.map((contact, index) => (
                <th
                  className="bg-primary pr-1 pl-1 pt-2 pb-2 overflow-hidden"
                  scope="col"
                  key={index}
                >
                  <Input
                    type="select"
                    className="fs--1"
                    name="selectHeader"
                    id={`selectHeader${index}`}
                    onChange={({ target }) => {
                      if (target.value === '+ Create new') {
                        document.getElementById(
                          `selectHeader${index}`
                        ).value = ' ';
                        setShowFieldModal(!showFieldModal);
                      } else {
                        setUpload({
                          ...upload,
                          selectHeader: [
                            ...upload.selectHeader.filter(
                              ({ value, HeaderIndex }) =>
                                value != target.value && index != HeaderIndex
                            ),
                            {
                              customHeader: target.value,
                              HeaderIndex: fileContacts.indexOf(contact)
                            }
                          ]
                        });
                      }
                    }}
                  >
                    <option>Do not Import</option>
                    <option>First name</option>
                    <option>Last name</option>
                    <option>Phone</option>
                    <option>Email</option>
                    <option>Company</option>
                    {fields.map((field, index) => (
                      <option key={index}>{field.title}</option>
                    ))}
                    <option>+ Create new</option>
                  </Input>
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {upload.contacts.slice(0, 10).map((customer, index) => (
            <TableRow data={customer} key={index} index={index} />
          ))}
        </tbody>
      </Table>

      {
        //---------------------------------------Create Field modal---------------------------------------------------------------------------------------
      }
      <Modal
        isOpen={showFieldModal}
        centered
        toggle={() => setShowFieldModal(!showFieldModal)}
      >
        <ModalHeader className="text-center bg-light d-flex flex-between-center border-bottom-0">
          Create a new Field
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Form>
                <Label for="title">Field Name:</Label>
                <Input
                  value={field.title.value}
                  onChange={handleFieldChange}
                  className="mb-3"
                  name="title"
                  id="title"
                />

                <Button
                  block
                  onClick={() => {
                    return handleFieldSubmit(), setShowFieldModal(false);
                  }}
                  color="primary"
                  className="mb-3"
                >
                  Save
                </Button>
              </Form>
              <Button
                block
                onClick={() => {
                  setShowFieldModal(false);
                }}
              >
                Close
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        //------------------------------------------------------------------------------------------------------------------------------------------------
      }

      <FormGroup switch>
        <Input
          type="switch"
          role="switch"
          id="excludeFirstRow"
          style={{ transform: 'scale(1.2)', marginRight: '5px' }} // Adjust scale and margin as needed
          onChange={({ target }) => {
            setExclude(!exclude);
            handleInputChange(target);
          }}
          checked={exclude}
          value={exclude}
          name="excludeFirstRow"
          errors={errors}
        />
        <Label>{`Exclude the first row (useful if your first row includes column names or labels).`}</Label>
      </FormGroup>

      {step === 3 &&
        !upload.selectHeader.filter(
          header => header.customHeader === 'Phone'
        )[0] &&
        upload.hasNumbers === true && (
          <div>
            <div className="mb-2 p-3 border-solid border-2x border-300 bg-soft-danger rounded-soft text-center text-danger cursor-pointer">
              {`Your list must contain `}
              <strong>Phone</strong>
              {` as a selected header`}
            </div>
          </div>
        )}
    </Fragment>
  );
};

export default StepThreeForm;
