import {
  CREATE_MATERIAL_REQUEST,
  CREATE_MATERIAL_SUCCESS,
  CREATE_MATERIAL_FAIL,
  CREATE_MATERIAL_RESET
} from '../actions/types';

export default function createMaterialReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_MATERIAL_REQUEST:
      return { loading: true };
    case CREATE_MATERIAL_SUCCESS:
      return { loading: false, success: true, newMaterial: action.payload };
    case CREATE_MATERIAL_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_MATERIAL_RESET:
      return {};
    default:
      return state;
  }
}
