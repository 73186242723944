import { ADD_CONTENT, RESET_BULK_TEXT_MESSAGE_CONTENT } from '../actions/types';

export default function (state = {}, action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case ADD_CONTENT:
      return action.payload;
    case RESET_BULK_TEXT_MESSAGE_CONTENT:
      return {};
    default:
      return state;
  }
}
