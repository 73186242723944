import { LIST_TAX_RATES } from '../actions/types';

export default function taxRatesReducer(state = [], action) {
  const { type } = action;

  switch (type) {
    case LIST_TAX_RATES:
      return action.payload;

    default:
      return state;
  }
}
