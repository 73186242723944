import React, { useEffect } from 'react';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { Button, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThemeControlDropdown from './ThemeControlDropdown';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { useSelector, useDispatch } from 'react-redux';
import TeamFilterDropdown from './TeamFilterDropdown';

const TopNavRightSideNavItem = () => {
  const navigate = useNavigate();

  const currentUser = useSelector((state) => state.auth.user);
  const windowWidth = useWindowDimensions().width;

  const isFollowUpPage = () => {
    const currentUrl = window.location.href; // Get the current URL
    return currentUrl.includes('follow-up'); // Check if the URL contains 'follow-up'
  };

  // Call the function to get the boolean value
  const isFollowUp = isFollowUpPage();

  // Helper function to format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(amount);
  };


  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center"
      as="ul"
    >
      {currentUser?.teamId && <TeamFilterDropdown />}

      {isFollowUp && windowWidth > 991 && (
        (!currentUser?.teamId || currentUser?.teamDetails?.role === "team_lead") && (
          <div className="d-flex align-items-center">
            <p className="me-2 mt-3 fs-10">
              {`Credits: ${((currentUser?.credits ?? 0) * 0.04).toFixed(2)}`}
            </p>
            <Button
              size="sm"
              onClick={() => { navigate('/follow-up/monthly-plans'); }}
              variant="btn-tertiary border text-primary hover-primary mx-2"
            >
              <FontAwesomeIcon icon="shopping-cart" className="me-2" />
              Buy credit
            </Button>
          </div>
        )
      )}

      <ThemeControlDropdown />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
