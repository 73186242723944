
import {
  UPDATE_SELECTED_GROUP_ROWS,
  REMOVE_SELECTED_GROUP_ROWS,
  RESET_SELECTED_GROUP_ROWS
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_SELECTED_GROUP_ROWS:
      if (!state.length) {
        return action.payload.selectedFlatRows.map(el => {
          return {
            id: el.id,
            original: el.original
          };
        });
      } else {
        const matched = Object.keys(action.payload.selectedRowIds).map(
          selRow => {
            const foundRow = state.find(el => el.id === selRow);
            if (foundRow) {
              return foundRow;
            } else {
              const newRow = action.payload.selectedFlatRows.find(
                el => el.id === selRow
              );
              if (newRow) {
                return {
                  id: newRow.id,
                  original: newRow.original
                };
              }
            }
          }
        );

        // Filter out any undefined values before returning
        return matched.filter(row => row !== undefined);
      }

    case REMOVE_SELECTED_GROUP_ROWS:
      return state.filter(el => {
        return !action.payload.includes(el.id);
      });

    case RESET_SELECTED_GROUP_ROWS:
      return [];

    default:
      return state;
  }
}

