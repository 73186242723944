import {
  CUSTOMER_REVIEW_DETAILS_REQUEST,
  CUSTOMER_REVIEW_DETAILS_FAIL,
  CUSTOMER_REVIEW_DETAILS_SUCCESS,
  CUSTOMER_REVIEW_DETAILS_RESET
} from '../actions/types';

export default function customerReviewDetailsReducer(
  state = { contact: {} },
  action
) {
  switch (action.type) {
    case CUSTOMER_REVIEW_DETAILS_REQUEST:
      return { loading: true };
    case CUSTOMER_REVIEW_DETAILS_SUCCESS:
      return { loading: false, success: true, review: action.payload };
    case CUSTOMER_REVIEW_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CUSTOMER_REVIEW_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}
