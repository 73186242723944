import React, { Fragment, useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import NumbersTable from './NumbersTable.js';
import { toast } from 'react-toastify';
import DLCRegistration from './DLCRegistration.js';
import TollFreeRegistration from './TollFreeRegistration.js';
import {
  listNumbers,
  listDLCNumbers,
  listContactsTotal
} from '../../actions/index.js';
import { listVoicemails } from 'actions/callActions.js';
import { listBrand, listDLCCampaigns } from '../../actions/index.js';
import {
  ADD_TAB,
  RESET_COMPOSE_GROUP,
  CONTACT_CREATE_RESET
} from '../../actions/types';

const Numbers = () => {
  const tabRedux = useSelector(state => state.tabRedux);
  const [activeTab, setActiveTab] = useState(tabRedux);
  const [tabName, setTabName] = useState('Numbers');
  const dispatch = useDispatch();

  const contactCreate = useSelector(state => state.createContact);
  const { success: successCreateContact } = contactCreate;

  useEffect(() => {
    dispatch(listVoicemails());
    dispatch(listNumbers());
    dispatch(listBrand());
    dispatch(listDLCCampaigns())
    dispatch(listDLCNumbers());
    dispatch(listContactsTotal());
    dispatch({ type: RESET_COMPOSE_GROUP });
  }, []);

  useEffect(() => {
    if (successCreateContact) {
      toast(
        <Fragment>
          <strong>Contact Created</strong>
        </Fragment>
      );
      dispatch({ type: CONTACT_CREATE_RESET });
    }
  }, [successCreateContact]);

  const toggle = tab => {
    if (activeTab !== tab) {
      return dispatch({ type: ADD_TAB, payload: tab }), setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav className="justify-content-start flex-column block flex-md-row" tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({
              'border-bottom bg-soft-primary': activeTab === '1',
              active: activeTab === '1'
            })}
            onClick={() => {
              toggle('1');
              setTabName('Numbers');
            }}
          >
            <span className={`mx-1 fs-10`}>Numbers </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({
              'border-bottom bg-soft-primary': activeTab === '2',
              active: activeTab === '2'
            })}
            onClick={() => {
              toggle('2');
              setTabName('10 DLC Registration');
            }}
          >
            <span className={`mx-1 fs-10`}>10 DLC Registration </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({
              'border-bottom bg-soft-primary': activeTab === '3',
              active: activeTab === '3'
            })}
            onClick={() => {
              toggle('3');
              setTabName('Toll Free Registration');
            }}
          >
            <span className={`mx-1 fs-10`}>Toll Free Registration </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <NumbersTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <DLCRegistration />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <TollFreeRegistration />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Numbers;
