import React, { useEffect, Fragment, useState } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import FalconCardHeader from 'components/common/FalconCardHeader';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import MessageDetails from './MessageDetails';
import ContactEdit from 'components/contacts/ContactEdit';
import CustomerForm from 'components/customers/CustomerForm';
import TemplateCreator from 'components/templates/TemplateCreator';
import { deleteSentHistoryMessages } from 'actions/historyActions';
import { createChat } from 'actions/chatActions';
import { UPDATE_ACTIVE_CHAT, ADD_CHAT, ADD_COMPOSE_CONTACT } from 'actions/types';

const Sent = ({ activeTab }) => {

  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();

  const chats = useSelector((state) => state.chats)

  const primaryNumber = useSelector((state) => state.primaryNumber)
  const bulkTextDetailsSent = useSelector((state) => state.bulkTextDetailsSent)

  const [isSelected, setIsSelected] = useState(false);
  const [maxWidth, setMaxWidth] = useState(getWidth())
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMessageDetailModal, setShowMessageDetailModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showEditContactModal, setShowEditContactModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [message, setMessage] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [temporaryId, setTemporaryId] = useState()
  const [newContactInfo, setNewContactInfo] = useState()




  const closeTemplateBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowTemplateModal(!showTemplateModal) }}>
    </button>
  );

  const closeContactBtn = (
    <button className="btn-close bg-light" onClick={() => { return setShowContactModal(!showContactModal) }}>
    </button>
  );

  const closeMsgDetailsBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowMessageDetailModal(!showMessageDetailModal) }}>
    </button>
  );



  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }



  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const toFormatter = (rowData) => {
    const { to, name } = rowData.row.original
    return (
      <div>
        <Media body className=" mt-1">
          <Badge color="soft-success" pill className="fw-9">{name?.firstName ? name?.firstName + " " + name?.lastName : formatPhoneNumber(to)}</Badge>
        </Media>
      </div>
    );
  };

  const fromFormatter = (rowData) => {
    const { from, name } = rowData.row.original
    return (
      <Media body className=" mt-1">
        <Badge color="soft-success" pill className="fw-9">{formatPhoneNumber(from)}</Badge>
      </Media>
    );
  };

  const bodyFormatter = (rowData) => {
    const { body, numMedia, messageBody } = rowData.row.original // messageBody will ONLY be on bulkSend objects
    return (
      <Link to="#!" onClick={() => { if (!messageBody) { return setMessage(rowData.row.original), setShowMessageDetailModal(!showMessageDetailModal) } else { return console.log("this ran") } }}>
        <Media tag={Flex} align="center">
          <Media body className=" mt-1">
            {numMedia === "1" ? (
              <Badge color="soft-primary" pill className="fw-9">
                MMS message
                <FontAwesomeIcon icon="image" transform="shrink-1" className=" ms-1" />
              </Badge>
            ) : (
              <h5 className="mb-0 fw-9 mt-2">{messageBody ? messageBody : body}</h5>
            )}
          </Media>
        </Media>
      </Link>
    );
  };

  const statusFormatter = (rowData) => {
    const { status, messageIds } = rowData.row.original
    let percentage
    if (messageIds) {
      const delivered = messageIds.filter((el) => el.status === "delivered")
      percentage = (delivered.length / messageIds.length) * 100
    }
    return (
      <div>
        {messageIds ? (
          <div>
            <Media body className=" mt-1">
              <Badge color="soft-success" pill className="fw-9">
                {`${percentage.toFixed(1)}% delivered`}
                <FontAwesomeIcon icon="check" transform="shrink-1" className=" ms-1" />
              </Badge>
            </Media>
          </div>
        ) : (
          <Media body className=" mt-1">
            {status === "delivered" ?
              <Badge color="soft-success" pill className="fw-9">
                {status}
                <FontAwesomeIcon icon="check" transform="shrink-1" className=" ms-1" />
              </Badge> : status === "failed" ?
                <Badge color="soft-danger" pill className="fw-9">
                  {status}
                  <FontAwesomeIcon icon="times" transform="shrink-1" className=" ms-1" />
                </Badge> : ""
            }
          </Media>
        )}
      </div>
    );
  };

  const dateSentFormatter = (rowData) => {
    const { dateSent } = rowData.row.original

    return (
      <div>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fw-9 mt-2"><Moment format="MM/DD/YYYY hh:mm A" date={dateSent} /></h5>
          </Media>
        </Media>

      </div>
    )
  };

  const actionFormatter = (rowData) => {
    const { to, name, body, sid, messageIds } = rowData.row.original

    const composeContact = { firstName: name ? name.firstName : to, lastName: name ? name.lastName : "", phoneNumber: name ? name.phone_number : to, _id: name ? name._id : "" }
    return (


      <UncontrolledDropdown>
        <DropdownToggle onClick={() => { console.log("this ran") }} color="link" size="sm" className="text-600 btn-reveal">
          <FontAwesomeIcon icon="ellipsis-h" className="fw-9" />
        </DropdownToggle>
        <DropdownMenu className="border py-2 me-7 me-xxl-8">
          <div>
            <DropdownItem onClick={() => {
              const formatTo = to.replace(/\D/g, '')
              const doesExist = chats.filter((chat) => chat.phone === formatTo)[0]
              if (doesExist) {
                dispatch({ type: UPDATE_ACTIVE_CHAT, payload: doesExist._id })
              } else {
                const date = new Date().toISOString()
                const contact = {
                  phone_number: formatTo
                }
                return dispatch(createChat(contact, primaryNumber)),
                  dispatch({
                    type: ADD_CHAT, payload: {
                      _id: "123123",
                      phone: contact.phone_number,
                      dateUpdated: date,
                      dateCreated: date,
                      read: "true",
                      state: "active",
                      mute: "false",
                      contact: name ? {
                        _id: name._id,
                        firstName: name.firstName,
                        lastName: name.lastName,
                        phone_number: name.phone_number
                      } : { contact_not_created: "true" }
                    }
                  }),
                  dispatch({ type: UPDATE_ACTIVE_CHAT, payload: "123123" })
              }
            }
            }
              tag={Link}
              to="/chat"
            >
              <FontAwesomeIcon icon="sms" className="fw-9" />{` Go to chat`}</DropdownItem>


            <DropdownItem onClick={() => {
              if (!name) {
                return setNewContactInfo(to),
                  setShowContactModal(!showContactModal)
              }
              else {
                return setNewContactInfo(name._id),
                  setShowEditContactModal(!showEditContactModal)
              }
            }}
              className={!name ? "text-success" : ""} >
              <FontAwesomeIcon icon={!name ? "user-plus" : "user"} className="fw-9" />{!name ? " Create contact" : " Contact details"}
            </DropdownItem>
            <DropdownItem onClick={() => { return setMessage(rowData.row.original), setShowMessageDetailModal(!showMessageDetailModal) }} ><FontAwesomeIcon icon="eye" className="fw-9" />{` Message Details`}</DropdownItem>
            <DropdownItem onClick={() => { return dispatch({ type: ADD_COMPOSE_CONTACT, payload: { contactCompose: composeContact } }) }} tag={Link} to="/pages/sms-compose" ><FontAwesomeIcon icon="reply" className="fw-9" />{` Send SMS to this contact`}</DropdownItem>
          </div>


        </DropdownMenu>
      </UncontrolledDropdown>

    )
  };

  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'to',
      Header: 'To',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: toFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'from',
      Header: 'From',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: fromFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'fullName',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'body',
      Header: 'Body',
      headerProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
      Cell: bodyFormatter,
      cellProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: statusFormatter
    },
    {
      accessor: 'dateSent',
      Header: 'Sent time',
      Cell: dateSentFormatter
    },
    {
      accessor: 'actions',
      Header: 'Actions', // Added Header
      Cell: actionFormatter,
      sticky: 'right',
    },
  ];

  //--------------------------------------------------------------------------Handlers------------------------------------------------------------------------------------

  function getWidth() {
    const body = document.querySelector("body")
    const width = parseFloat(window.getComputedStyle(body).width)
    if (width >= 1540) {
      return 40
    } else if (width >= 1400) {
      return 40
    } else if (width >= 1200) {
      return 40
    } else if (width >= 992) {
      return 60
    } else if (width >= 768) {
      return 60
    } else if (width >= 576) {
      return 60
    }
    return 60
  }



  window.addEventListener("resize", () => setMaxWidth(getWidth()))




  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  useEffect(() => {
    setRowDelete([])
  }, [])
  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Card className="mb-3">
      <FalconCardHeader title="" light={false}>
        <Fragment>
          <ButtonGroup className="mt-2">
            <ButtonIcon icon="sms" transform="shrink-3" disabled={!isSelected.length} color="falcon-info" size="sm">
              Send SMS
            </ButtonIcon>
            <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) }} disabled={!isSelected.length} transform="shrink-3" color="falcon-danger" size="sm" >
              Delete
            </ButtonIcon>
            <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
              <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fw-9 fw-normal p-4">
                    <Button onClick={() => { if (rowDelete?.length) { dispatch(deleteSentHistoryMessages(rowDelete)) } else { dispatch(deleteSentHistoryMessages(isSelected)) } setShowDeleteModal(!showDeleteModal) }} block className="text-danger" color="light">Delete Messages</Button>
                    <Button onClick={() => { return setShowDeleteModal(!showDeleteModal), setRowDelete([]) }} block className="text-primary" color="light" >Cancel</Button>
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>

            {
              // Message Details Modal
            }
            <Modal isOpen={showMessageDetailModal} centered toggle={() => setShowMessageDetailModal(!showMessageDetailModal)}>
              <ModalHeader className="d-flex justify-content-center" close={closeMsgDetailsBtn} >Message Details</ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fw-9 fw-normal p-4">
                    <MessageDetails message={message} />
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>

            {
              // create contact modal
            }
            <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
              <ModalHeader toggle={() => { return setShowContactModal(!showContactModal) }} className="bg-light d-flex flex-between-center border-bottom-0" close={closeContactBtn}>
                How would you like to Add?
              </ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="p-2">
                    <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} newContactInfo={newContactInfo} />
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>

            {
              // edit contact modal
            }
            <Modal isOpen={showEditContactModal} centered toggle={() => setShowEditContactModal(!showEditContactModal)}>
              <ModalHeader toggle={() => { return setShowEditContactModal(!showEditContactModal) }} className="bg-light d-flex flex-between-center border-bottom-0">
              </ModalHeader>
              <ModalBody className="p-0">
                <Card>
                  <CardBody className="fw-9 fw-normal p-4">
                    <ContactEdit sendingSelContactId={newContactInfo} />
                  </CardBody>
                </Card>
              </ModalBody>
            </Modal>
            {
              // show template modal
            }
            <Modal isOpen={showTemplateModal} centered toggle={() => { return setShowTemplateModal(!showTemplateModal) }}>
              <ModalHeader close={closeTemplateBtn}>Design your template</ModalHeader>
              <ModalBody className="p-0">
                <TemplateCreator temporaryId={temporaryId} setTemporaryId={setTemporaryId} />
              </ModalBody>
            </Modal>
          </ButtonGroup>
        </Fragment>
      </FalconCardHeader>
      <CardBody className="p-0">


        <Fragment>
          <Card>

            <AdvanceTableWrapper
              columns={columns}
              data={bulkTextDetailsSent}
              sortable
              pagination
              perPage={12}
              // selection
              //  selectionColumnWidth={25}
              selectionCallback={() => { }}

            >
              <Row className="flex-start-center mb-3 ms-1">
                <Col xs="auto" sm={6} lg={4}>
                  <AdvanceTableSearchBox onSearch={() => { }} table />
                </Col>
              </Row>

              <AdvanceTable
                table
                headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: 'fw-9 fw-bold overflow-hidden'
                }}
              />
              <div className="mt-3 mb-2 pe-2 ps-3 ">
                <AdvanceTableFooter
                  rowCount={bulkTextDetailsSent?.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>

          </Card>
        </Fragment>

      </CardBody>
    </Card>
  );
};


export default Sent;
