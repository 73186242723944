import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SocketContext } from 'providers/SocketProvider';
import { fetchUser } from 'actions';
import { Modal } from 'reactstrap';
import { USER_UPDATE_RESET } from 'actions/types';
import NewUserWizardLayout from './new-user-wizard/NewUserWizardLayout';
import { fetchTeamDetails } from 'actions/teamActions';
import ComposeMsg from 'components/compose/ComposeMsg';


const Dashboard = () => {


  const [showFirstLoginModal, setShowFirstLoginModal] = useState(false)
  const auth = useSelector((state) => state.auth?.user)
  const companyDetails = useSelector((state) => state.companyDetails)
  const trustProBillingDetails = useSelector((state) => state.trustProBillingDetails?.billingDetails[0])

  const successUpdate = useSelector((state) => state.userUpdate)
  const { success: successUserUpdate } = successUpdate

  const socket = useContext(SocketContext);

  const dispatch = useDispatch()


  useEffect(() => {
    if (socket) {
      socket.on('updateUserCredits', (user) => {
      });

      // Cleanup on unmount
      return () => {
        socket.off('updateUserCredits');
      };
    }
  }, [socket, auth]);


  useEffect(() => {

    if ((auth?.isFirstLogin && !auth?.teamId && trustProBillingDetails?.subscriptionType !== 'payg') && (!companyDetails[0]?.companyName || !companyDetails[0]?.phoneNumber)) {
      // if user has not completed this form yet, and are not on a team, and they are subscribed but are missing company details companyName or companyDetails phoneNumber
      setShowFirstLoginModal(!showFirstLoginModal)
    }
  }, [auth?.loading])

  useEffect(() => {

    if (successUserUpdate) {
      dispatch(fetchUser())
      dispatch(fetchTeamDetails())
      dispatch({ type: USER_UPDATE_RESET })
    }
  }, [successUserUpdate])

  return (
    <Fragment>
      <Modal size='lg' backdrop="static" isOpen={showFirstLoginModal} centered toggle={() => setShowFirstLoginModal(!showFirstLoginModal)}>
        <NewUserWizardLayout showFirstLoginModal={showFirstLoginModal} setShowFirstLoginModal={setShowFirstLoginModal} />
      </Modal>

      <ComposeMsg />


    </Fragment>
  );
};

export default Dashboard;
