import {
  LIST_USER_SCORE_CREDITS_REQUEST,
  LIST_USER_SCORE_CREDITS_SUCCESS,
  LIST_USER_SCORE_CREDITS_FAIL
} from '../actions/types';

const initialState = {
  credits: [],
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_USER_SCORE_CREDITS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_USER_SCORE_CREDITS_SUCCESS:
      return {
        ...state,
        credits: action.payload,
        loading: false
      };
    case LIST_USER_SCORE_CREDITS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
