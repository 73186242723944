import { LIST_COMPLETED_CALENDAR } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_COMPLETED_CALENDAR:
      return action.payload.filter(el => {
        return el.isActive === 'completed';
      });

    default:
      return state;
  }
}
