import { LIST_CONTACTS_TOTAL } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_CONTACTS_TOTAL:
      //    const contacts = action.payload.filter((el) => el.blocked === false && !el?.group.some((group) => group?.title === "unsubscribers"))
      //   return contacts?.map((el) => { return {...el, name: el.firstName + " " + el.lastName }})
      return action.payload;
    default:
      return state;
  }
}
