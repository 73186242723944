import {
  CREATE_SERVICE_REQUEST,
  CREATE_SERVICE_SUCCESS,
  CREATE_SERVICE_FAIL,
  CREATE_SERVICE_RESET
} from '../actions/types';

export default function serviceCreateReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_SERVICE_REQUEST:
      return { loading: true };
    case CREATE_SERVICE_SUCCESS:
      return { loading: false, success: true, newService: action.payload };
    case CREATE_SERVICE_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_SERVICE_RESET:
      return {};
    default:
      return state;
  }
}
