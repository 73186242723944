import React, { useEffect, useMemo } from 'react';
import { Input } from 'reactstrap';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useGlobalFilter,
  useFilters
} from 'react-table';
import { useSticky } from 'react-table-sticky';
import { getPageName, getGroupPageName } from 'helpers/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  UPDATE_SELECTED_ROWS,
  UPDATE_SELECTED_GROUP_ROWS,
  UPDATE_SELECTED_SENT_ROWS
} from 'actions/types';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="form-check">
        <Input
          type="checkbox"
          className="form-check-input"
          ref={resolvedRef}
          {...rest}
        />
      </div>
    );
  }
);

const AdvanceTableWrapper = ({
  children,
  columns,
  data,
  sortable,
  selection,
  selectionColumnWidth,
  selectionCallback,
  fetchData,
  pagination,
  perPage = 10,
  total
}) => {
  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter }
  }) => {
    const count = preFilteredRows?.length;

    return (
      <Input
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value || undefined)}
        placeholder={`Search ${count} records...`}
      />
    );
  };

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
      // And also our default editable cell
    }),
    []
  );


  // Use perPage (initial page size) to compute initial pageCount
  const initialPageSize = pagination ? perPage : data?.length;
  const initialPageCount = Math.ceil(total / initialPageSize);

  const {
    getTableProps,
    headers,
    page,
    prepareRow,
    selectedFlatRows,
    flatRows,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    setPageSize,
    gotoPage,
    pageCount,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter, filters, sortBy },
    setGlobalFilter
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      disableSortBy: !sortable,
      manualPagination: true,
      autoResetPage: false,
      manualSortBy: true,
      initialState: { pageSize: pagination ? perPage : data?.length },
      pageCount: initialPageCount, // Pass initial pageCount here,
      autoResetSelectedRows: false,
      getRowId: row => row._id
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
    hooks => {
      if (selection) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => {
              return (
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                /> //getToggleAllPageRowsSelectedProps was getToggleAllRowsSelectedProps
              );
            },
            headerProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            cellProps: {
              style: {
                maxWidth: selectionColumnWidth
              }
            },
            Cell: ({ row }) => (
              <div>
                {row?.original?.title === 'unsubscribers' ? (
                  ''
                ) : (
                  <div>
                    <IndeterminateCheckbox
                      {...row.getToggleRowSelectedProps()}
                    />
                  </div>
                )}
              </div>
            )
          },

          ...columns
        ]);
      }
    }
  );

  const isSMSCompose = getPageName('sms-compose');
  const isContactsPage = getPageName('contacts');
  const isDashboard = getPageName('dashboard');
  const isGroup = getGroupPageName('group');
  const isUnsubscribers = getGroupPageName('unsubscribers');
  const isNumbers = getPageName('numbers');
  const isHistory = getPageName('history');
  const isCampaign = getPageName('campaign');
  const dispatch = useDispatch();
  const selectedRows = useSelector(state => state.selectedRows);

  useEffect(() => {
    if (isContactsPage || isNumbers || isHistory) {
      dispatch({
        type: UPDATE_SELECTED_ROWS,
        payload: {
          selectedRowIds: selectedRowIds,
          selectedFlatRows: selectedFlatRows
        }
      });
      dispatch({
        type: UPDATE_SELECTED_SENT_ROWS,
        payload: {
          selectedRowIds: selectedRowIds,
          selectedFlatRows: selectedFlatRows
        }
      });
    }

    if (
      isSMSCompose ||
      isDashboard ||
      isGroup ||
      isUnsubscribers ||
      isCampaign
    ) {
      dispatch({
        type: UPDATE_SELECTED_ROWS,
        payload: {
          selectedRowIds: selectedRowIds,
          selectedFlatRows: selectedFlatRows
        }
      });
    }

    dispatch({
      type: UPDATE_SELECTED_ROWS,
      payload: {
        selectedRowIds: selectedRowIds,
        selectedFlatRows: selectedFlatRows
      }
    });
    dispatch({
      type: UPDATE_SELECTED_GROUP_ROWS,
      payload: {
        selectedRowIds: selectedRowIds,
        selectedFlatRows: selectedFlatRows
      }
    });
  }, [selectedRowIds]);

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize, sortBy });
    }
  }, [pageIndex, pageSize, sortBy]); // Include sortBy here


  useEffect(() => {
    if (selectionCallback) {
      selectionCallback(selectedFlatRows);
    }
  }, [selectedRows]);


  useEffect(() => {
    // When sortBy changes, reset the pageIndex to 0
    gotoPage(0);
  }, [sortBy, gotoPage]);



  const recursiveMap = children => {
    return React.Children.map(children, child => {
      if (child.props?.children) {
        return React.cloneElement(child, {
          children: recursiveMap(child.props.children)
        });
      } else {
        if (child.props?.table) {
          return React.cloneElement(child, {
            ...child.props,
            getTableProps,
            headers,
            page,
            data,
            prepareRow,
            canPreviousPage,
            canNextPage,
            nextPage,
            previousPage,
            gotoPage,
            pageCount,
            pageIndex,
            selectedRowIds,
            selectedFlatRows,
            pageSize,
            setPageSize,
            globalFilter,
            setGlobalFilter
          });
        } else {
          return child;
        }
      }
    });
  };

  return <>{recursiveMap(children)}</>;
};

export default AdvanceTableWrapper;
