import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import moment from 'moment';

const AccountOverview = () => {

    const auth = useSelector((state) => state.auth?.user);
    const followUpBillingDetails = useSelector((state) => state.followUpBillingDetails?.billingDetails[0])

    // Function to format the date
    const formatDate = (date) => {
        return date ? moment(date).format("MMM Do, YYYY") : 'N/A';
    };

    return (
        <Card className="shadow-sm">
            <FalconCardHeader title="Overview" titleTag="h4" className="py-3"></FalconCardHeader>
            <Card.Body>
                <Row className="align-items-center mb-4">
                    <Col xs={7}>
                        <h6 className="text-muted mb-1">Joined</h6>
                        <h5 className="mb-0">{formatDate(auth?.signupDate)}</h5>
                    </Col>
                    <Col xs={5} className="text-end">
                        <h6 className="text-muted mb-1">Account Status</h6>
                        <h5 className={`mb-0 ${(followUpBillingDetails?.subscriptionStatus === 'active' || followUpBillingDetails?.subscriptionType === 'payg') ? 'text-success' : 'text-danger'}`}>
                            {followUpBillingDetails?.subscriptionType === 'payg' ? "Pay as you go" : followUpBillingDetails?.subscriptionStatus}
                        </h5>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col xs={7}>
                        <h6 className="text-muted mb-1">Plan</h6>
                        <h5 className="mb-0">{followUpBillingDetails?.subscriptionType}</h5>
                    </Col>
                    <Col xs={5} className="text-end">
                        <h6 className="text-muted mb-1">Next Billing Date</h6>
                        <h5 className="mb-0">{formatDate(followUpBillingDetails?.nextBillingDate)}</h5>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default AccountOverview;
