import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { useAppContext } from 'providers/AppProvider';

const SidebarOpenArchive = ({ setIsConvoStateActive }) => {
  const {
    config: { theme }
  } = useAppContext();

  return (
    <div
      className={
        theme === 'light'
          ? 'd-flex justify-content-between w-100 bg-light rounded'
          : 'd-flex justify-content-between w-100 bg-dark rounded'
      }
    >
      <Button
        onClick={() => {
          setIsConvoStateActive('open');
        }}
        size="sm"
        className="btn-tertiary border-dark text-primary"
      >
        Open
      </Button>
      <Button
        onClick={() => {
          setIsConvoStateActive('closed');
        }}
        size="sm"
        className="btn-tertiary border-dark text-primary"
      >
        Archive
      </Button>
    </div>
  );
};

export default SidebarOpenArchive;
