import {
  INVOICE_STATUS_UPDATE_REQUEST,
  INVOICE_STATUS_UPDATE_SUCCESS,
  INVOICE_STATUS_UPDATE_FAIL,
  INVOICE_STATUS_UPDATE_RESET
} from '../actions/types';

export default function invoiceUpdateReducer(state = {}, action) {
  switch (action.type) {
    case INVOICE_STATUS_UPDATE_REQUEST:
      return { loading: true };
    case INVOICE_STATUS_UPDATE_SUCCESS:
      return { loading: false, success: true, invoiceInfo: action.payload };
    case INVOICE_STATUS_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case INVOICE_STATUS_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
