import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, CardHeader, CardText, Row, Button, Card, CardBody, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import { getCustomerProfileFollowUp } from 'actions/followUpBillingActions';
import { listFollowUpBillingDetails } from 'actions/followUpBillingActions';
import { toast } from 'react-toastify';
import axios from 'axios';
import NavbarVertical from './navbar/NavbarVertical';
import useWindowDimensions from 'hooks/useWindowDimensions';
import MonthlyPlansFaq from './MonthlyPlansFaq';
import { useAppContext } from 'providers/AppProvider';
import Subscription from './billing/Subscription';
import DowngradeModal from 'components/accountCRM/pricing/DowngradeModal';


const MonthlyPlans = () => {

  const {
    config: { theme, isDark }
  } = useAppContext();

  const dispatch = useDispatch()
  const navigate = useNavigate();
  const windowWidth = useWindowDimensions().width

  const [showChargeModal, setShowChargeModal] = useState(false)
  const [plan, setPlan] = useState()
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false)
  const [isDowngrading, setIsDowngrading] = useState(false); // New state for downgrade processing
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null); // Store the payment method
  const [showDowngradeModal, setShowDowngradeModal] = useState(false)
  const [processing, setProcessing] = useState(false); // New processing state
  const currentUser = useSelector((state) => state.auth?.user)
  const followUpBillingDetails = useSelector((state) => state.followUpBillingDetails?.billingDetails[0])
  console.log("followUpBillingDetails", followUpBillingDetails)


  const tiers = [
    { name: 'Starter', planId: "1L52AiBdl1ADYaNeD4ymHaza", monthlyPrice: 99, texts: "2,000+ texts / mo", cardText: "$0.048 / text", value: "99", type: "starter", credits: 2084, priceKey: "1L52AiBdl1ADYaNeD4ymHaza", color: '#2B2D42', discount: '-2% discount', features: ['All features included'] },
    { name: 'Standard', planId: "1Y23AiBdl2ImaXeC4ioJesu", monthlyPrice: 199, texts: "4,400+ texts / mo", cardText: "$0.045 / text", value: "199", type: "standard", credits: 4445, priceKey: "1Y23AiBdl2ImaXeC4ioJesu", color: '#8D99AE', discount: '-9% discount', features: ['All features included'] },
    { name: 'Premium', planId: "1E29AiBdl1IJLaNeI8ymjJob", monthlyPrice: 499, texts: "11,600+ texts / mo", cardText: "$0.043 / text", value: "499", type: "premium", credits: 11628, priceKey: "1E29AiBdl1IJLaNeI8ymjJob", color: '#D90429', discount: '-13% discount', features: ['All features included'] },
    { name: 'Business', planId: "1E29AiBdl1IJLa656348ymesff", monthlyPrice: 999, texts: "25,000+ texts / mo", cardText: "$0.04 / text", value: "999", type: "business", credits: 25000, priceKey: "1E29AiBdl1IJLaNeI8ymesff", color: '#33C3FF', discount: '-19% discount', features: ['All features included'] },
    { name: 'Enterprise', planId: "1E29AiBdl1IJLaNeI8ymesff", monthlyPrice: 1999, texts: "50,000+ texts / mo", cardText: "$0.37 / text", value: "1999", type: "enterprise", credits: 50000, priceKey: "1E29AiBdl1IJLaNeI8ymesff", color: '#FFC133', discount: '-25% discount', features: ['All features included'] }
  ];

  useEffect(() => {
    if (currentUser?.customerProfileFollowUp) {
      dispatch(getCustomerProfileFollowUp())
    }
  }, [currentUser])

  const fetchPaymentMethod = async () => {
    console.log("fetchPaymentMethod is running")
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/customer-subscription-payment-method-fup`,
        {}, // If you need to pass any data, add here
        { withCredentials: true }
      );
      setCurrentPaymentMethod(data.paymentMethod); // Set the fetched payment method
    } catch (error) {
      console.error('Error fetching payment method:', error);
    }
  };

  // Effect to run once on initial load, and again only when hasUpdatedPaymentMethod is true
  useEffect(() => {
    if (currentPaymentMethod === null && currentUser?.customerProfileFollowUp) {
      fetchPaymentMethod();
    }
  }, [currentUser]);

  console.log("currentPaymentMethod", currentPaymentMethod)


  // Close button handler that respects the processing state
  const handleCloseModal = () => {
    if (!processing) {
      setShowChargeModal(false);
    }
  };


  const closeBtnA = (
    <button className="btn-close bg-light" onClick={handleCloseModal}>
    </button>
  );

  // SelectPaymentMethod.js

  const handleDowngradeConfirm = async () => {
    if (!plan?.planId) {
      toast.error('No plan selected for downgrade.');
      return;
    }

    setIsDowngrading(true); // Start processing

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/downgrade-subscription-fup`,
        {
          planId: plan?.planId
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success(response.data.message || 'Subscription downgraded successfully.');
        setShowDowngradeModal(false);
        dispatch(listFollowUpBillingDetails()); // Ensure this action is correctly imported and implemented
        navigate('/follow-up/billing')

      } else {
        toast.error(response.data.message || 'Failed to downgrade subscription.');
      }
    } catch (error) {
      console.error('Error downgrading subscription:', error);
      const errorMessage = error.response?.data?.message || 'An unexpected error occurred.';
      toast.error(errorMessage);
    } finally {
      setIsDowngrading(false); // End processing
    }
  };


  const handlePlanSelection = (tier) => {
    // Determine the actual price of the selected tier
    const actualPrice = tier?.monthlyPrice;

    // Calculate if the selected tier is a downgrade
    const isDowngrade =
      followUpBillingDetails?.subscriptionStatus === 'active' &&
      followUpBillingDetails?.subscriptionType !== tier?.type &&
      followUpBillingDetails?.amount > actualPrice;

    if (isDowngrade) {
      setShowDowngradeModal(true); // Show downgrade modal
      setPlan(tier);
    } else {
      if (followUpBillingDetails?.subscriptionType === tier?.type) return; // do nothing when clicking on current plan
      setShowChargeModal(!showChargeModal);
      setPlan(tier);
    }
  };


  const handleToggle = () => {
    if (!isDowngrading) {
      setShowDowngradeModal(!showDowngradeModal);
    }
  };

  return (

    <Fragment>
      <Row>
        {windowWidth >= 992 && (
          <Col lg={2}>
            <NavbarVertical isNavbarVerticalCollapsed={isNavbarVerticalCollapsed} setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed} />
          </Col>
        )}
        <Col lg={10}>
          <Row className="my-2 me-2 d-flex justify-content-between">
            {windowWidth <= 992 ? (
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle size="md" className="fw-bold btn-reveal btn">{`Settings `}
                    <FontAwesomeIcon icon="chevron-down" className="fs--1" />
                  </DropdownToggle>
                  <DropdownMenu className="border py-2 me-6 me-xxl-8">
                    <DropdownItem tag={Link} to="/follow-up/account-settings" className="fw-bold">Settings</DropdownItem>
                    <DropdownItem tag={Link} to="/follow-up/billing" className="fw-bold">Billing</DropdownItem>
                    <DropdownItem tag={Link} to="/follow-up/monthly-plans" className="fw-bold" >Monthly plans</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            ) : (
              <div>
                <h2 className="d-flex justify-content-start" >Monthly plans</h2>
              </div>
            )}
          </Row>
          <Row className="d-flex flex-wrap g-0">
            {/* Pay as you go tier */}
            <Col md={6} lg={3} className="d-flex px-1" key={'payg'}>
              <Card className="text-center my-2 flex-fill" style={{ borderTop: `5px solid #1B512D` }}>
                <CardHeader className={theme === "light" ? "text-dark fw-bold fs-8" : "text-light fw-bold fs-8"}>Pay as you go</CardHeader>
                <CardBody>
                  <h5 className="fw-bold fs-7 m-0">$20</h5>
                  <p className="m-0 fw-medium">One Time</p>
                  <CardText className="text-success mt-2 mb-0 fw-bold">-2% discount</CardText>
                  <CardText className="fw-bold m-0">500+ texts</CardText>
                  <CardText className="fw-thin m-0">$0.048 / text</CardText>
                  {['All features included'].map((feature, index) => (
                    <CardText className="fw-thin" key={index}>{feature}</CardText>
                  ))}
                  <Button
                    className="mt-4"
                    color={"primary"}
                    onClick={() => {
                      setShowChargeModal(!showChargeModal);
                      setPlan({
                        name: 'payg',
                        type: 'payg',
                        amount: 2000,
                        value: "20",
                        label: "$20 ",
                        message: "$20",
                        credits: "500",
                        priceKey: "4G92AiBdl1ADYaNeD4ymOJob"

                      })
                    }}
                  >
                    Pay Now
                  </Button>
                </CardBody>
              </Card>
            </Col>



            {tiers.map(tier => (
              <Col md={6} lg={3} className="d-flex px-1" key={tier.type}>
                <Card className="text-center my-2 flex-fill" style={{ borderTop: `5px solid ${tier.color}`, borderRadius: '12px', boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' }}>
                  <CardHeader className={theme === "light" ? "text-dark fw-bold fs-8" : "text-light fw-bold fs-8"} style={{ backgroundColor: 'transparent', borderBottom: 'none', padding: '1rem 0.5rem' }}>
                    {tier.name}
                  </CardHeader>
                  <CardBody>
                    <h5 className="fw-bold fs-7 m-0">${tier.monthlyPrice}</h5>
                    <p className="m-0 fw-medium" style={{ color: theme === "light" ? "#6c757d" : "#adb5bd" }}>USD per month</p>
                    <CardText className="text-success mt-2 mb-0 fw-bold">{tier.discount}</CardText>
                    <CardText className="fw-bold m-0">{tier.texts}</CardText>
                    <CardText className="fw-thin m-0">{tier.cardText}</CardText>
                    {tier.features.map((feature, index) => (
                      <CardText className="fw-thin" key={index}>{feature}</CardText>
                    ))}
                    <Button
                      className="mt-4"
                      color={plan?.value === tier.value ? "success" : "primary"}
                      onClick={() => handlePlanSelection(tier)}
                      style={{
                        borderRadius: '8px',
                        padding: '0.75rem 1.5rem',
                        fontSize: '0.875rem',
                        fontWeight: '600',
                        transition: 'all 0.3s ease',
                        boxShadow: plan?.value === tier.value ? '0 4px 8px rgba(0, 123, 255, 0.2)' : '0 4px 12px rgba(0, 123, 255, 0.2)',
                        background: plan?.value === tier.value || followUpBillingDetails?.subscriptionType === tier?.type
                          ? 'linear-gradient(45deg, #28a745, #218838)' // Success gradient for selected
                          : 'linear-gradient(45deg, #007bff, #0056b3)' // Primary gradient
                      }}
                      onMouseEnter={e => {
                        e.target.style.transform = 'translateY(-2px)';
                        e.target.style.boxShadow = plan?.value === tier.value
                          ? '0 6px 16px rgba(40, 167, 69, 0.3)'
                          : '0 6px 20px rgba(0, 123, 255, 0.3)';
                      }}
                      onMouseLeave={e => {
                        e.target.style.transform = 'translateY(0)';
                        e.target.style.boxShadow = plan?.value === tier.value
                          ? '0 4px 8px rgba(40, 167, 69, 0.2)'
                          : '0 4px 12px rgba(0, 123, 255, 0.2)';
                      }}
                    >
                      {followUpBillingDetails?.subscriptionType === tier?.type ?
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <FontAwesomeIcon icon="check" className="btn-tertiary text-white me-2" />
                          Current Plan
                        </div> :
                        plan?.value === tier.value ?
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <FontAwesomeIcon icon="check" className="btn-tertiary text-white me-2" />
                            Selected
                          </div> :
                          "Select"}
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}

          </Row>
          <MonthlyPlansFaq />
        </Col>
      </Row>


      <Modal
        size="md"
        isOpen={showChargeModal}
        centered
        toggle={handleCloseModal}
        backdrop={processing ? 'static' : true}
        keyboard={!processing}
      >
        <ModalHeader close={closeBtnA}>Review & Pay</ModalHeader>
        <ModalBody className="p-0">
          <Subscription
            togglePlan={() => { }}
            isYearly={false}
            showChargeModal={showChargeModal}
            setShowChargeModal={setShowChargeModal}
            plan={plan}
            setPlan={setPlan}
            processing={processing} // Pass processing state
            setProcessing={setProcessing} // Pass setProcessing function
          />
        </ModalBody>
      </Modal>



      {/* Modal for Downgrades */}
      <DowngradeModal
        isProcessing={isDowngrading}
        isOpen={showDowngradeModal}
        toggle={handleToggle}
        onConfirm={handleDowngradeConfirm}
      />

    </Fragment>

  )
}

export default MonthlyPlans

