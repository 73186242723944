import {
  LIST_CALLS_REQUEST,
  LIST_CALLS_SUCCESS,
  LIST_CALLS_FAIL,
} from '../actions/types';

const initialState = {
  calls: [],
  totalCalls: 0,
  loading: false,
  error: null,
  // Optional: separate states for deletion if needed
  deleting: false,
  deleteError: null,
  // ... other state properties
};

export default function (state = initialState, action) {
  switch (action.type) {

    case LIST_CALLS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CALLS_SUCCESS:
      return {
        ...state,
        calls: action.payload?.calls,
        loading: false,
        totalCalls: action.payload?.totalCalls
      };

    case LIST_CALLS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}

