import {
  ESTIMATE_DELETE_REQUEST,
  ESTIMATE_DELETE_SUCCESS,
  ESTIMATE_DELETE_FAIL,
  ESTIMATE_DELETE_RESET
} from '../actions/types';

export default function estimateDeleteReducer(state = {}, action) {
  switch (action.type) {
    case ESTIMATE_DELETE_REQUEST:
      return { loading: true };
    case ESTIMATE_DELETE_SUCCESS:
      return { loading: false, success: true };
    case ESTIMATE_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case ESTIMATE_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
