import {
    FOLLOW_UP_SUBSCRIPTION_CANCEL_REQUEST,
    FOLLOW_UP_SUBSCRIPTION_CANCEL_SUCCESS,
    FOLLOW_UP_SUBSCRIPTION_CANCEL_FAIL,
    FOLLOW_UP_SUBSCRIPTION_CANCEL_RESET
} from '../actions/types';

export default function followUpSubscribeCancelReducer(state = {}, action) {
    switch (action.type) {
        case FOLLOW_UP_SUBSCRIPTION_CANCEL_REQUEST:
            return { loading: true }
        case FOLLOW_UP_SUBSCRIPTION_CANCEL_SUCCESS:
            return { loading: false, success: true, message: action.payload }
        case FOLLOW_UP_SUBSCRIPTION_CANCEL_FAIL:
            return { loading: false, error: action.payload }
        case FOLLOW_UP_SUBSCRIPTION_CANCEL_RESET:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}