import axios from 'axios';

import {
  DELETE_SENT_MESSAGES_CREATE_REQUEST,
  DELETE_SENT_MESSAGES_CREATE_SUCCESS,
  DELETE_SENT_MESSAGES_CREATE_FAIL,
  DELETE_RECEIVED_MESSAGES_CREATE_REQUEST,
  DELETE_RECEIVED_MESSAGES_CREATE_SUCCESS,
  DELETE_RECEIVED_MESSAGES_CREATE_FAIL,
  LIST_RESPONDER_MESSAGES,
  LIST_RESPONDER_MESSAGES_REQUEST,
  LIST_RESPONDER_MESSAGES_SUCCESS,
  LIST_RESPONDER_MESSAGES_FAIL,

  DELETE_RESPONDER_MESSAGES_REQUEST,
  DELETE_RESPONDER_MESSAGES_SUCCESS,
  DELETE_RESPONDER_MESSAGES_FAIL,
} from './types';





export const deleteSentHistoryMessages = isSelected => async dispatch => {
  try {
    dispatch({ type: DELETE_SENT_MESSAGES_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/sent-history-delete`,
      method: 'POST',
      data: { isSelected },
      withCredentials: true
    });

    dispatch({ type: DELETE_SENT_MESSAGES_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_SENT_MESSAGES_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};





export const deleteReceivedHistoryMessages = isSelected => async dispatch => {
  try {
    dispatch({ type: DELETE_RECEIVED_MESSAGES_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/received-history-delete`,
      method: 'POST',
      data: { isSelected },
      withCredentials: true
    });

    dispatch({ type: DELETE_RECEIVED_MESSAGES_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_RECEIVED_MESSAGES_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};



export const deleteResponderMessages = isSelected => async dispatch => {
  try {
    dispatch({ type: DELETE_RESPONDER_MESSAGES_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/responder-messages`,
      method: 'DELETE',
      data: { isSelected },
      withCredentials: true
    });

    dispatch({ type: DELETE_RESPONDER_MESSAGES_SUCCESS });
  } catch (error) {
    dispatch({
      type: DELETE_RESPONDER_MESSAGES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};








export const listResponderMessages = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 10,
      // users,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/responder-messages?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_RESPONDER_MESSAGES_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    }).then(res => {
      dispatch({ type: LIST_RESPONDER_MESSAGES_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_RESPONDER_MESSAGES_FAIL, payload: error })
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  }
}











export const listResponderMessagesDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 10,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_RESPONDER_MESSAGES_REQUEST });

    return axios({
      url: `${process.env.REACT_APP_API_URL}/api/responder-messages-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    }).then(res => {
      dispatch({ type: LIST_RESPONDER_MESSAGES_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_RESPONDER_MESSAGES_FAIL, payload: error })
        // Add error handling logic here if needed
        console.log("error", error); // If there is an error, the promise is rejected
      });
  };
};













export const listResponderMessagesCustomDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 10,
      sortBy = 'dateSent',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_RESPONDER_MESSAGES_REQUEST })

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/responder-messages-custom-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    }).then(res => {
      dispatch({ type: LIST_RESPONDER_MESSAGES_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_RESPONDER_MESSAGES_FAIL, payload: error })
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};