import React, { Fragment, useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import ContactsTableCustom from './ContactsTableCustom';
import GroupsTable from './GroupsTable';
import { toast } from 'react-toastify';
import ImportFiles from './ImportFiles';
import CustomFieldsTable from './CustomFieldsTable';
import Blocked from './Blocked';
import { listNumbers, listContactsTotal } from '../../actions/index.js';
import {
  ADD_TAB,
  RESET_COMPOSE_GROUP,
  CONTACT_CREATE_RESET
} from '../../actions/types';

const TabContacts = () => {

  const tabRedux = useSelector(state => state.tabRedux);
  const [activeTab, setActiveTab] = useState(tabRedux);
  const dispatch = useDispatch();

  const groupReducer = useSelector(state => state.groups);
  const { totalGroups: totalGroups } = groupReducer;

  const contactsTotal = useSelector(state => state.contactsTotal);
  const contactCreate = useSelector(state => state.createContact);
  const { success: successCreateContact } = contactCreate;

  const { totalItems: totalFields } = useSelector(state => state.fields);

  const csvUpload = useSelector(state => state.csvUpload);
  const { totalItems: totalFiles } = csvUpload;

  const blockedContacts = useSelector(state => state.blockedContacts);
  const { totalItems: totalBlocked } = blockedContacts;

  useEffect(() => {
    dispatch(listNumbers());
    dispatch(listContactsTotal());
    dispatch({ type: RESET_COMPOSE_GROUP });
  }, []);

  useEffect(() => {
    if (successCreateContact) {
      toast(
        <Fragment>
          <strong>Contact Created</strong>
        </Fragment>
      );
      dispatch({ type: CONTACT_CREATE_RESET });
    }
  }, [successCreateContact]);

  const toggle = tab => {
    if (activeTab !== tab) {
      return dispatch({ type: ADD_TAB, payload: tab }), setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav
        className="justify-content-center flex-column block flex-md-row"
        tabs
      >
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            <span className="mx-1 fs-10">
              Groups
              <span className={`badge-subtle-success fs--2 rounded px-1 ms-2`}>
                {totalGroups}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <span className="mx-1 fs-10">
              Contacts
              <span className={`badge-subtle-primary fs--2 rounded px-1 ms-2`}>
                {contactsTotal}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              toggle('3');
            }}
          >
            <span className="mx-1 fs-10">
              Custom Fields
              <span className={`badge-subtle-primary fs--2 rounded px-1 ms-2`}>
                {totalFields}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => {
              toggle('4');
            }}
          >
            <span className="mx-1 fs-10">
              Import
              <span className={`badge-subtle-primary fs--2 rounded px-1 ms-2`}>
                {totalFiles}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => {
              toggle('5');
            }}
          >
            <span className="mx-1 fs-10">
              Blocked
              <span className={`badge-subtle-danger fs--2 rounded px-1 ms-2`}>
                {totalBlocked}
              </span>
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <GroupsTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <ContactsTableCustom contactsTotal={contactsTotal} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <CustomFieldsTable />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col>
              <ImportFiles />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="5">
          <Row>
            <Col>
              <Blocked />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TabContacts;
