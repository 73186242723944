import {
  EMAIL_INVOICE_REQUEST,
  EMAIL_INVOICE_SUCCESS,
  EMAIL_INVOICE_FAIL,
  EMAIL_INVOICE_RESET
} from '../actions/types';

export default function emailInvoiceReducer(state = {}, action) {
  switch (action.type) {
    case EMAIL_INVOICE_REQUEST:
      return { loading: true };
    case EMAIL_INVOICE_SUCCESS:
      return { loading: false, success: true, info: action.payload };
    case EMAIL_INVOICE_FAIL:
      return { loading: false, error: action.payload };
    case EMAIL_INVOICE_RESET:
      return {};
    default:
      return state;
  }
}
