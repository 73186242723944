import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import Flex from 'components/common/Flex';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  listShowAndHideGroups,
  deleteMultipleGroups,
  updateGroupShowHide
} from 'actions/index';
import SubtleBadge from 'components/common/SubtleBadge';

const HiddenGroups = () => {
  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return setIsSelected(
        selectedFlatRows.map(fr => {
          return fr.original._id;
        })
      );
    },
    [setIsSelected]
  );

  const dispatch = useDispatch();

  const groupsShowHide = useSelector(state => state.groupsShowHide); // using group reducer but with unSubs type for redux to filter and return only unsub group

  const groupShowHideCreate = useSelector(state => state.groupShowHideCreate);
  const { success: successShowHide } = groupShowHideCreate;

  const groupDelete = useSelector(state => state.groupDelete);
  const { success: successDelete } = groupDelete;

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const nameFormatter = rowData => {
    const { title } = rowData.row.original;
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{title}</h5>
        </Media>
      </Media>
    );
  };

  const contactsFormatter = rowData => {
    const { contactsCount } = rowData.row.original;

    return (
      <Media body className="ms-2 mt-1">
        {contactsCount < 1 ? (
          <SubtleBadge bg="danger" pill className="text-dark">
            {contactsCount}
            <FontAwesomeIcon
              icon="user-plus"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        ) : (
          <SubtleBadge bg="success" pill className="text-dark">
            {contactsCount}
            <FontAwesomeIcon
              icon="check"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        )}
      </Media>
    );
  };

  const hideFormatter = rowData => {
    const { hide } = rowData.row.original;
    return (
      <Media body className="ms-2 mt-1">
        {hide === true ? (
          <SubtleBadge bg="danger" className="text-dark" pill>
            Hidden
            <FontAwesomeIcon
              icon="eye-slash"
              transform="shrink-1"
              className=" text-danger ms-1"
            />
          </SubtleBadge>
        ) : (
          <SubtleBadge bg="success" className="text-dark" pill>
            Visible
            <FontAwesomeIcon
              icon="eye"
              transform="shrink-1"
              className="text-success ms-1"
            />
          </SubtleBadge>
        )}
      </Media>
    );
  };

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    {
      accessor: 'title',
      Header: 'Group Name',
      Cell: nameFormatter
    },
    {
      accessor: 'hide',
      Header: 'Hide/Unhide',
      Cell: hideFormatter
    },
    {
      accessor: 'contacts',
      Header: '# of Contacts',
      Cell: contactsFormatter
    }
  ];

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------

  useEffect(() => {
    dispatch(listShowAndHideGroups());
  }, [successShowHide, successDelete]);

  return (
    <Card>
      <CardBody className="p-0">
        <Fragment>
          <Card>
            <AdvanceTableWrapper
              columns={columns}
              data={groupsShowHide}
              sortable
              pagination
              perPage={12}
              selection
              selectionColumnWidth={25}
              selectionCallback={selectionCallback}
            >
              <Row className="flex-start-center my-2 ms-1">
                <div>
                  <Col>
                    <AdvanceTableSearchBox table />
                  </Col>
                </div>
              </Row>

              <Fragment>
                <Row>
                  <Col className="ms-4 mb-2">
                    <ButtonIcon
                      onClick={() => {
                        return dispatch(
                          updateGroupShowHide({ hide: true, _id: isSelected })
                        );
                      }}
                      icon="eye-slash"
                      iconClassName="text-danger"
                      disabled={!isSelected.length}
                      transform="shrink-3 down-1"
                      className="btn-tertiary border-dark"
                      size="sm"
                    >
                      Hide
                    </ButtonIcon>
                    <ButtonIcon
                      onClick={() => {
                        return dispatch(
                          updateGroupShowHide({ hide: false, _id: isSelected })
                        );
                      }}
                      icon="eye"
                      disabled={!isSelected.length}
                      transform="shrink-3 down-1"
                      className="btn-tertiary border-dark mx-1"
                      iconClassName="text-success"
                      size="sm"
                    >
                      Show
                    </ButtonIcon>
                    <ButtonIcon
                      onClick={() => {
                        setShowDeleteModal(!showDeleteModal);
                      }}
                      icon="trash"
                      disabled={!isSelected.length}
                      transform="shrink-3"
                      className="btn-tertiary border-dark text-danger mx-1"
                      size="sm"
                    >
                      Delete
                    </ButtonIcon>
                  </Col>
                </Row>
                <Modal
                  isOpen={showDeleteModal}
                  centered
                  toggle={() => setShowDeleteModal(!showDeleteModal)}
                >
                  <ModalHeader className="d-flex justify-content-center">
                    Are you sure?
                  </ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4">
                        <Button
                          onClick={() => {
                            return (
                              dispatch(deleteMultipleGroups(isSelected)),
                              setShowDeleteModal(!showDeleteModal)
                            );
                          }}
                          block
                          className="text-danger"
                          color="light"
                        >
                          Delete groups
                        </Button>
                        <Button
                          onClick={() => {
                            setShowDeleteModal(!showDeleteModal);
                          }}
                          block
                          className="text-primary"
                          color="light"
                        >
                          Cancel
                        </Button>
                      </CardBody>
                    </Card>
                  </ModalBody>
                </Modal>
              </Fragment>

              <AdvanceTable
                table
                headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                tableProps={{
                  striped: true,
                  className: 'fs--1 font-weight-bold overflow-hidden'
                }}
              />
              <div className="mt-3 mb-2 pe-2 ps-3 ">
                <AdvanceTableFooter
                  rowCount={groupsShowHide?.length}
                  table
                  rowInfo
                  navButtons
                  rowsPerPageSelection
                />
              </div>
            </AdvanceTableWrapper>
          </Card>
        </Fragment>
      </CardBody>
    </Card>
  );
};

export default HiddenGroups;
