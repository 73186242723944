// BillingHistory.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import ButtonIcon from 'components/common/ButtonIcon';
import { Card, Row, Col, Spinner, Alert, Table } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';

const BillingHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const limit = 10; // Transactions per page
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hasMore, setHasMore] = useState(false); // To determine if there's a next page

    const fetchBillingInfo = async (currentPage) => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/api/customer-transactions-fup?page=${currentPage}&limit=${limit}`,
                { withCredentials: true }
            );

            setTransactions(response.data.transactions);
            setHasMore(response.data.hasMore);
        } catch (error) {
            console.error('Error fetching billing info:', error);
            setError('Failed to fetch billing information');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBillingInfo(page);
    }, [page]);

    const handlePrevPage = () => {
        if (page > 1) setPage(page - 1);
    };

    const handleNextPage = () => {
        if (hasMore) setPage(page + 1);
    };


    const Transactions = ({ transactions }) => {
        if (transactions?.length === 0) return <p>No recent transactions.</p>;

        return (
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Amount</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {transactions?.map((transaction) => (
                        <tr key={transaction.id}>
                            <td>${parseFloat(transaction.settleAmount).toFixed(2)}</td>
                            <td>{moment(transaction.submitTimeUTC).format("MMM Do, YYYY")}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    };


    return (
        <Card className="my-3 shadow-sm">
            <FalconCardHeader title="Billing History" light titleTag="h4" className="bg-light py-3" />
            <Card.Body>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <>
                        <Row className="align-items-center mb-3">
                            <Col xs={12}>
                                <h5 className="mb-2">Recent Transactions</h5>
                                <Transactions transactions={transactions} />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} className="d-flex justify-content-between">
                                <ButtonIcon icon="chevron-left" className={`${page === 1 ? 'd-none' : 'btn-falcon-primary'}`} onClick={handlePrevPage} disabled={page === 1}>
                                </ButtonIcon>
                                <span>Page {page}</span>
                                <ButtonIcon icon="chevron-right" className={`${!hasMore ? 'd-none' : 'btn-falcon-primary'}`} onClick={handleNextPage} disabled={!hasMore}>

                                </ButtonIcon>
                            </Col>
                        </Row>
                    </>
                )}
            </Card.Body>
        </Card>
    );
};

export default BillingHistory;
