import {
  CRM_SUBSCRIPTION_DETAILS_REQUEST,
  CRM_SUBSCRIPTION_DETAILS_FAIL,
  CRM_SUBSCRIPTION_DETAILS_SUCCESS
} from '../actions/types';

export default function cmrSubscriptionDetailsReducer(
  state = { field: {} },
  action
) {
  switch (action.type) {
    case CRM_SUBSCRIPTION_DETAILS_REQUEST:
      return { loading: true };
    case CRM_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        loading: false,
        crmSubscription: action.payload.response.crmSubscription
      };
    case CRM_SUBSCRIPTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
