import {
  INVITE_MEMBER_REQUEST,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  INVITE_MEMBER_RESET
} from '../actions/types';

const initialInviteMemberState = {
  success: false,
  loading: false,
  error: null
};

export const inviteMemberReducer = (
  state = initialInviteMemberState,
  action
) => {
  switch (action.type) {
    case INVITE_MEMBER_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case INVITE_MEMBER_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: null
      };
    case INVITE_MEMBER_FAILURE:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload
      };
    case INVITE_MEMBER_RESET:
      return initialInviteMemberState;
    // You might want to handle additional cases, like a loading state
    default:
      return state;
  }
};

export default inviteMemberReducer;
