import {
  FORGOT_PASSWORD_CREATE_REQUEST,
  FORGOT_PASSWORD_CREATE_SUCCESS,
  FORGOT_PASSWORD_CREATE_FAIL
} from '../actions/types';

export default function forgotPasswordCreateReducer(state = {}, action) {
  switch (action.type) {
    case FORGOT_PASSWORD_CREATE_REQUEST:
      return { loading: true };
    case FORGOT_PASSWORD_CREATE_SUCCESS:
      return { loading: false, success: true };
    case FORGOT_PASSWORD_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
