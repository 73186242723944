import {
  LIST_CUSTOMER_RECEIPTS_REQUEST,
  LIST_CUSTOMER_RECEIPTS_SUCCESS,
  LIST_CUSTOMER_RECEIPTS_FAIL,
  UPDATE_RECEIPTS
} from '../actions/types';

const initialState = {
  loading: false,
  receipts: [],
  error: null
};

export const customerReceiptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CUSTOMER_RECEIPTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_CUSTOMER_RECEIPTS_SUCCESS:
      return {
        ...state,
        loading: false,
        receipts: action?.payload,
        error: null
      };
    case LIST_CUSTOMER_RECEIPTS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    case UPDATE_RECEIPTS:
      return {
        ...state,
        receipts: action.payload // Update the receipts array with the new one
      };
    default:
      return state;
  }
};
