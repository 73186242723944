import { LIST_CONTACTS_FOLLOW_UP_REQUEST, LIST_CONTACTS_FOLLOW_UP_SUCCESS, LIST_CONTACTS_FOLLOW_UP_FAILURE } from '../actions/types';

const initialState = {
    contacts: [],
    totalItems: 0,
    loading: false,
    error: null,
    // Optional: separate states for deletion if needed
    deleting: false,
    deleteError: null,
    // ... other state properties
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LIST_CONTACTS_FOLLOW_UP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case LIST_CONTACTS_FOLLOW_UP_SUCCESS:
            const contacts = action.payload.contacts.filter(
                el =>
                    el.blocked === false &&
                    !el?.group.some(group => group?.title === 'unsubscribers')
            );
            const contactsArr = contacts?.map(el => {
                return { ...el, name: el.firstName + ' ' + el.lastName, key: el._id };
            });
            return {
                ...state,
                loading: false,
                contacts: contactsArr,
                totalItems: action.payload.totalItems
            };

        case LIST_CONTACTS_FOLLOW_UP_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
}
