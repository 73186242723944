import {
  CONTACT_DETAILS_REQUEST,
  CONTACT_DETAILS_FAIL,
  CONTACT_DETAILS_SUCCESS,
  CONTACT_DETAILS_RESET
} from '../actions/types';

export default function contactDetailsReducer(state = { contact: {} }, action) {
  switch (action.type) {
    case CONTACT_DETAILS_REQUEST:
      return { loading: true };
    case CONTACT_DETAILS_SUCCESS:
      return { loading: false, success: true, contact: action.payload };
    case CONTACT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    case CONTACT_DETAILS_RESET:
      return {};
    default:
      return state;
  }
}
