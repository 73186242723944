import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router-dom';
import { updateDBPassword } from 'actions/passwordActions';
import { UPDATE_PASSWORD_RESET } from 'actions/types';
import ReactPasswordChecklist from 'react-password-checklist';

const PasswordResetForm = ({ hasLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const passwordReset = useSelector(state => state.passwordReset);
  const { loading: loadingReset, success: successReset, error: errorReset } = passwordReset;
  const { ident: id } = useParams();  // Retrieve 'ident' from the URL as 'id'


  console.log("we made it to password reset form");

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    dispatch(updateDBPassword(id, password));
  };

  useEffect(() => {
    if (password === '' || confirmPassword === '') return setIsDisabled(true);

    setIsDisabled(password !== confirmPassword);
  }, [password, confirmPassword]);

  useEffect(() => {
    if (successReset) {
      toast.success('Login with your new password', {
        theme: 'colored'
      });
      navigate('/authentication/simple/login');
      dispatch({ type: UPDATE_PASSWORD_RESET })
    } else if (errorReset) {
      toast.error('An error occurred. Please try again later.');
      dispatch({ type: UPDATE_PASSWORD_RESET })
    }
  }, [successReset, errorReset]);

  return (
    <Form
      className={classNames('mt-3', { 'text-left': hasLabel })}
      onSubmit={handleSubmit}
    >
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>New Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'New Password' : ''}
          value={password}
          name="password"
          onChange={({ target }) => setPassword(target.value)}
          type="password"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Confirm Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Confirm Password' : ''}
          value={confirmPassword}
          name="confirmPassword"
          onChange={({ target }) => {
            return (
              setConfirmPassword(target.value), setPasswordAgain(target.value)
            );
          }}
          type="password"
        />
      </Form.Group>
      <ReactPasswordChecklist
        rules={['minLength', 'specialChar', 'number', 'capital', 'match']}
        minLength={5}
        value={password}
        valueAgain={passwordAgain}
        onChange={isValid => {
          console.log('isValid', isValid);
        }}
      />

      <Button type="submit" className="w-100" disabled={isDisabled}>
        Set password
      </Button>
    </Form>
  );
};

PasswordResetForm.propTypes = {
  hasLabel: PropTypes.bool
};

export default PasswordResetForm;
