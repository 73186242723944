import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import CardSummary from 'components/common/CardSummary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import FailedImports from './FailedImports';
import { deleteMultipleFiles, listFiles } from 'actions/index';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { CSVUPLOAD_CREATE_RESET, CSVUPLOAD_DELETE_RESET } from 'actions/types';
const ImportFiles = () => {
  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const windowSize = useWindowDimensions();

  const csvUpload = useSelector(state => state.csvUpload);
  const { files: files, loading: loadingFiles, totalItems: totalFiles } = csvUpload;

  const csvUploadCreate = useSelector(state => state.csvUploadCreate);
  const { success: successCreate } = csvUploadCreate; // successDelete becomes something we can use in useEffect

  const csvUploadDelete = useSelector(state => state.csvUploadDelete);
  const { success: successDelete } = csvUploadDelete;

  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFileDetailsModal, setShowFileDetailsModal] = useState(false);
  const [showFailedModal, setShowFailedModal] = useState(false);
  const [fileDetails, setFileDetails] = useState();
  const [rowDelete, setRowDelete] = useState();

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original._id;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  const closeFileBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowFileDetailsModal(!showFileDetailsModal);
      }}
    ></button>
  );

  const closeFailedBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowFailedModal(!showFailedModal);
      }}
    ></button>
  );

  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const nameFormatter = rowData => {
    const { file_name } = rowData.row.original;
    return (
      <Link to="#!">
        <Media
          onClick={() => {
            return (
              setFileDetails(rowData.row.original),
              setShowFileDetailsModal(!showFileDetailsModal)
            );
          }}
          tag={Flex}
          align="center"
        >
          <Media body className="ml-2">
            <h5 className="mb-0 fs-9 mb-1">{file_name}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const importedFormatter = rowData => {
    const { contacts } = rowData.row.original;
    return (
      <Media body className="ms-2 mt-1">
        {contacts.length < 1 ? (
          <SubtleBadge color="soft-danger" pill className="fs-9 mb-1">
            {contacts.length}
            <FontAwesomeIcon
              icon="user-plus"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        ) : (
          <SubtleBadge color="soft-success" pill className="fs-9 mb-1">
            {contacts.length}
            <FontAwesomeIcon
              icon="check"
              transform="shrink-1"
              className=" ms-1"
            />
          </SubtleBadge>
        )}
      </Media>
    );
  };

  const createdFormatter = rowData => {
    const { created } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mb-1">
            <Moment format="MM/DD/YYYY" date={created} />
          </h5>
        </Media>
      </Media>
    );
  };

  const actionFormatter = rowData => {
    const { _id } = rowData.row.original;
    return (
      // Control your row with this id // there was a : after {id} now its a ,
      <div className="d-flex justify-content-end">
        <UncontrolledDropdown>
          <DropdownToggle
            color="link"
            size="sm"
            className="text-600 bg-light border-dark"
          >
            <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
          </DropdownToggle>
          <DropdownMenu className="border py-2 me-6">
            <DropdownItem
              onClick={() => {
                return (
                  setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)
                );
              }}
              className="text-danger"
            >
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  };

  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'file_name',
      Header: 'File name',
      Cell: nameFormatter
    },
    {
      accessor: 'contacts',
      Header: 'Imported',
      Cell: importedFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: windowSize.width < 650 ? 'right' : undefined
    }
  ];


  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listFiles({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  useEffect(() => {
    if (successCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: CSVUPLOAD_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successCreate, fetchData, dispatch]);


  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: CSVUPLOAD_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listFiles({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };



  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon
                icon="plus"
                tag={Link}
                to="/follow-up/contacts-import"
                transform="shrink-3"
                className="btn-tertiary border-dark mx-1"
                size="sm"
              >
                Import Contacts
              </ButtonIcon>
              <ButtonIcon
                icon="trash"
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal);
                }}
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark text-danger mx-1"
                size="sm"
              >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingFiles && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={files}
            fetchData={fetchData}
            total={totalFiles}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  onSearch={handleSearch}
                  placeholder="Search files..."
                  table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 font-weight-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalFiles}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>

      {
        // delete modal
      }
      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    dispatch(deleteMultipleFiles(rowDelete));
                  } else {
                    dispatch(deleteMultipleFiles(isSelected));
                  }
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="btn-tertiary border-dark text-danger"
              >
                Delete Files
              </Button>
              <Button
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal), setRowDelete([]);
                }}
                block
                className="btn-tertiary border-dark text-primary mt-2"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // file details modal
      }
      <Modal
        size="lg"
        isOpen={showFileDetailsModal}
        centered
        toggle={() => setShowFileDetailsModal(!showFileDetailsModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center"
          close={closeFileBtn}
        >
          Import Details
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <div className="card-deck">
                <CardSummary title="" rate="" to="#!" color="info">
                  {fileDetails?.contacts?.length + ' Contacts imported'}
                </CardSummary>
                <CardSummary
                  title=""
                  rate=""
                  click={() => {
                    return setShowFailedModal(!showFailedModal);
                  }}
                  color="danger"
                  className="mr-5"
                >
                  {fileDetails?.failed?.length + ' Failed'}
                </CardSummary>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // failed imports modal
      }
      <Modal
        size="lg"
        isOpen={showFailedModal}
        centered
        toggle={() => setShowFailedModal(!showFailedModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center"
          close={closeFailedBtn}
        >
          Failed Imports
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-1">
              <FailedImports
                failed={fileDetails?.failed}
                duplicates={fileDetails?.duplicates}
              />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

//{isSelected ? (
//  <InputGroup size="sm" className="input-group input-group-sm">
//    <CustomInput type="select" id="bulk-select">
//      <option>Bulk actions</option>
//      <option value="Delete">Delete</option>
//      <option value="Archive">Archive</option>
//    </CustomInput>
//    <Button color="falcon-default" size="sm" className="ml-2">
//      Apply
//    </Button>
//  </InputGroup>
//) : (

export default ImportFiles;
