import React, { Fragment, useState, useRef, useEffect } from 'react';
import { Col, Button, Media, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { attachFile } from '../../../actions/index';
import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';

const InsertVideo = ({ setShowModal, setShowFileModal }) => {
  const dispatch = useDispatch();
  const InputFile = useRef(null);
  const [userFile, setUserFile] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [highlighted, setHighlighted] = useState(false);

  const isFirstRender = useRef(true);

  const attachFileCreate = useSelector(state => state.attachFileCreate);

  // After attachFileCreate.loading changes, this will run.
  useEffect(() => {
    // On first render, don't do anything
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // On subsequent renders, close the modal when attachFileCreate.loading is false
    if (!attachFileCreate.loading) {
      setShowModal(false);
      setShowFileModal(false);
      setUploadFile(null);
    }
  }, [attachFileCreate.loading]);

  const convertToBase64 = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const manualFileUpload = async e => {
    e.preventDefault();
    if (e.target.files[0].name) {
      setUserFile(e.target.files[0].name);
      const file = e.target.files[0];
      const convertedFile = await convertToBase64(file);
      setUploadFile({
        image: convertedFile,
        imageName: file.name,
        type: file.type
      });
    }
  };

  const onButtonClick = e => {
    e.preventDefault();
    InputFile.current.click();
  };

  return (
    <>
      <Card>
        <CardBody className="fs--1 font-weight-normal p-4">
          <h4 className="mb-1 mt-3 text-center"></h4>
          <div className="mb-2">
            <input
              type="file"
              onChange={e => manualFileUpload(e)}
              accept=".mp4, .mov, .avi"
              ref={InputFile}
              className="d-none"
            />
            <div
              className={`dropzone-area ${highlighted ? ' border-800' : ''}`}
              onClick={e => onButtonClick(e)}
              onDragEnter={() => {
                setHighlighted(true);
              }}
              onDragLeave={() => {
                setHighlighted(false);
              }}
              onDragOver={e => {
                e.preventDefault();
              }}
              onDrop={e => {
                e.preventDefault();
              }}
            >
              <Fragment>
                <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                  <img src={cloudUpload} alt="" width={25} className="mr-2" />
                  <Media>
                    <p className="fs-0 mb-0 text-700">
                      {userFile ? userFile : 'Upload your Video'}
                    </p>
                  </Media>
                </Media>
                <p className="mb-0 w-75 mx-auto text-500">
                  Supported video formats: .mp4, .mov, .avi
                </p>
              </Fragment>
            </div>
          </div>
          <p className="fs-0 text-center">
            A clickable hyperlink will be included in your SMS where recipients
            can view / download your video.
          </p>
          <Col className="text-center">
            <Button
              disabled={!uploadFile}
              color="primary"
              onClick={() => {
                return dispatch(attachFile(uploadFile));
              }}
              className="my-3 text-white"
            >
              {attachFileCreate.loading ? '...processing' : 'Attach Video'}
            </Button>
          </Col>
        </CardBody>
      </Card>
    </>
  );
};

export default InsertVideo;
