import {
  CREATE_ESTIMATE_REQUEST,
  CREATE_ESTIMATE_SUCCESS,
  CREATE_ESTIMATE_FAIL,
  CREATE_ESTIMATE_RESET
} from '../actions/types';

export default function estimateCreateReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_ESTIMATE_REQUEST:
      return { loading: true };
    case CREATE_ESTIMATE_SUCCESS:
      return { loading: false, success: true, newlyCreatedEstimateId: action.payload };
    case CREATE_ESTIMATE_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_ESTIMATE_RESET:
      return {};
    default:
      return state;
  }
}
