import {
  LOAD_INVOICE, UPDATE_INVOICE, CLEAR_INVOICE, INVOICE_DETAILS_REQUEST,
  INVOICE_DETAILS_SUCCESS,
  INVOICE_DETAILS_FAIL
} from '../actions/types';

const initialState = {
  customer: null, // Customer details
  invoiceNumber: null,
  lineItems: [
    { name: '', description: null, qty: null, unitPrice: null, total: null }
  ],
  clientMessage: '',
  subTotal: 0,
  discount: 0,
  taxName: '',
  taxRate: 0,
  total: 0,
  invoiceBalance: 0,
  internalNotes: '',
  attachments: [],
  requiredDeposit: 0,
  discountValue: 0,
  discountType: 'percentage',
  status: 'Draft',
  paymentDue: 'Upon receipt',
  invoiceSubject: 'For services rendered',
  deposits: [],
  notesAndAttachments: [],
  // ... [other fields from the invoice schema]
  isLoading: false, // To track loading state
  error: null // To track any errors
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_INVOICE:
      return {
        ...state,
        ...action.payload, // Spread the invoice details into the state
        isLoading: false
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        ...action.payload // Spread the updated fields into the state
      };
    case CLEAR_INVOICE:
      return initialState; // Reset to the initial state
    case INVOICE_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
      };
    case INVOICE_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default invoiceReducer;
