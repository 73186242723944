import {
  TAG_UPDATE_REQUEST,
  TAG_UPDATE_SUCCESS,
  TAG_UPDATE_FAIL
} from '../actions/types';

export default function tagUpdateReducer(state = {}, action) {
  switch (action.type) {
    case TAG_UPDATE_REQUEST:
      return { loading: true };
    case TAG_UPDATE_SUCCESS:
      return { loading: false, success: true, TagInfo: action.payload };
    case TAG_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
