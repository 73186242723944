import React, { Fragment, useEffect, useState, createRef } from 'react';
import { useLocation } from 'react-router-dom'; // React Router v6 import
import axios from 'axios';
import { Button, Card, Form, Container, Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section'; // Assuming this is still being used
import Logo from 'components/common/Logo'; // Assuming this is still being used
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faRedo, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import envelope from 'assets/img/illustrations/envelope.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { SET_EMAIL } from '../../actions/types';

const ConfirmLinkContent = () => {
  const { email, password } = useSelector(state => state.emailAuthFlow);
  
  const dispatch = useDispatch();
  
  const userEmail = email || "your email";
  
  const [showInput, setShowInput] = useState(false);
  const [counter, setCounter] = useState(0);
  const [newEmail, setNewEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const [userExistsMessage, setUserExistsMessage] = useState('');
  const recaptchaRef = createRef();

  const validateEmail = ema => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(ema.toLowerCase());
  };

  const handleSubmit = event => {
    event.preventDefault();
    // Execute the reCAPTCHA when the form is submitted
    resendEmail();
    //recaptchaRef.current.execute();
  };

  let timer;

  const updateCount = () => {
    timer =
      !timer &&
      setInterval(() => {
        setCounter(prevCount => prevCount - 1);
      }, 1000);

    if (counter < 1) clearInterval(timer);
  };

  useEffect(() => {
    updateCount();

    return () => clearInterval(timer);
  }, [counter]);

  const resendEmail = e => {
    //console.log("resendEmail ran, this only runs after successful recaptcha")
    if (newEmail !== userEmail) {
      // if they have edited the email....
    }
    if (!validateEmail(newEmail)) {
      // validateEmail from newEmail state which is copy of redux state
      // Input on page also has newEmail as its value, so when its edited ex... emailchanged, it updates the newEmail state,
      // therefore calling validateEmail at beginning of function will always validate the correct sending email
      setShowInput(true);
      setEmailErr(true);
    } else {
      console.log('email is valid');
      // check if we have edited email
      //execute captcha
      // if success set Counter to 30 and disable resend button
      // once counter is reset, enable button again

      setCounter(30);
      setShowInput(false);
      axios({
        method: 'POST',
        data: {
          username: newEmail,
          password: password
        },
        url: `${process.env.REACT_APP_API_URL}/api/auth/localRegister`
      }).then(res => {
        if (res.error) {
          //window.location.href = "http://localhost:3000/authentication/basic/register"
        } else {
          return (
            setShowSuccessMsg(true),
            dispatch({ type: SET_EMAIL, payload: newEmail })
          );
        }
      });
    }
  };

  const onReCAPTCHAChange = captchaCode => {
    // If the reCAPTCHA code is null or undefined indicating that
    // the reCAPTCHA was expired then return early
    if (!captchaCode) {
      return;
    }
    // Else reCAPTCHA was executed successfully so proceed with the
    // alert
    resendEmail();
    // Reset the reCAPTCHA so that it can be executed again if user
    // submits another email.
    recaptchaRef.current.reset();
  };

  return (
    <Fragment>
      <Section className="py-0">
        <Container>
          {' '}
          {/* Added Container for layout */}
          <Row className="flex-center min-vh-100 py-6">
            <Col sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
              <Logo />
              <Card>
                <Card.Body className="fs--1 fw-normal p-5">
                <div>
                  <img
                    className="d-block mx-auto mb-4"
                    src={envelope}
                    alt="sent"
                    width={70}
                  />
                  <h4>Please check your email!</h4>
                  <p>
                    An email has been sent to <strong>{userEmail}</strong>.
                    Please click on the included link to verify your email
                    address.
                  </p>
                </div>
                  <Row>
                    <Col className="d-flex justify-content-between">
                      <Button
                        onClick={() => {
                          setShowInput(!showInput);
                        }}
                        variant="primary"
                        size="sm"
                        className="mt-3"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          transform="shrink-4 down-1"
                          className="mr-1"
                        />
                        Edit your email
                      </Button>

                      <Button
                        onClick={handleSubmit}
                        disabled={counter > 0}
                        variant="primary"
                        size="sm"
                        className="mt-3 ml-2"
                      >
                        <FontAwesomeIcon
                          icon={faRedo}
                          transform="shrink-4 down-1"
                          className="mr-1"
                        />
                        Resend Email
                      </Button>
                    </Col>
                  </Row>
                  {showInput && (
                    <div>
                      <Row className="d-flex justify-content-center mt-4">
                        <Col>
                          <Form.Label>Enter your Email address</Form.Label>
                          <Form.Control
                            placeholder="Email address"
                            name="username"
                            value={newEmail}
                            className={emailErr ? 'border-danger' : ''}
                            onChange={e => {
                              return (
                                setNewEmail(e.target.value), setEmailErr(false)
                              );
                            }}
                            type="email"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                  {showSuccessMsg && counter > 0 && (
                    <div>
                      <Row className="mt-4 bg-soft-success rounded">
                        <Col>
                          <FontAwesomeIcon
                            icon={faCheck}
                            transform="shrink-1 down-1"
                            className="mr-1"
                          />
                          <Form.Label className="mt-2">
                            Your Email has been sent!
                          </Form.Label>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {emailErr && (
                    <div>
                      <Row className="mt-4 bg-soft-danger rounded">
                        <Col>
                          <FontAwesomeIcon
                            icon={faTimes}
                            transform="shrink-1 down-1"
                            className="mr-1"
                          />
                          <Form.Label className="mt-2">
                            Invalid Email format
                          </Form.Label>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {userExistsMessage && (
                    <div>
                      <Row className="mt-4 bg-soft-danger rounded">
                        <Col>
                          <FontAwesomeIcon
                            icon={faTimes}
                            transform="shrink-1 down-1"
                            className="mr-1"
                          />
                          <Form.Label className="mt-2">
                            A User already Exists with that Email
                          </Form.Label>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {counter > 0 && (
                    <div>
                      <Row className="mt-4 bg-soft-success rounded">
                        <Col>
                          <Form.Label className="mt-2">{`You can send again in ${counter} seconds`}</Form.Label>
                        </Col>
                      </Row>
                    </div>
                  )}
                  <Row>
                    <Col>
                      <ReCAPTCHA
                        className="mb-4"
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_SITE_KEY} // had to add this .env and .gitignore the env.  process.env.REACT_PUBLIC_RECAPTCHA_SITE_KEY when setting in heroku was returning undefined
                        onChange={onReCAPTCHAChange}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Section>
    </Fragment>
  );
};

export default ConfirmLinkContent;
