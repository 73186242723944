import {
  FIELD_DETAILS_REQUEST,
  FIELD_DETAILS_FAIL,
  FIELD_DETAILS_SUCCESS
} from '../actions/types';

export default function fieldDetailsReducer(state = { field: {} }, action) {
  switch (action.type) {
    case FIELD_DETAILS_REQUEST:
      return { loading: true };
    case FIELD_DETAILS_SUCCESS:
      return { loading: false, field: action.payload };
    case FIELD_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
