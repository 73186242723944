import React, { useContext, useEffect } from 'react';
import { NavLink, Media } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import LastMessage from './LastMessage';
import Avatar from '../../common/Avatar';
import Flex from '../../common/Flex';
import ChatSidebarDropdownAction from './ChatSidebarDropdownAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChatContext } from '../../../context/Context';
import classNames from 'classnames';
import { updateChatRead, listEachChatMessages } from 'actions/chatActions';
import { EDIT_CHAT, UPDATE_ACTIVE_CHAT } from 'actions/types';
import Moment from 'react-moment';
import avatarImage from 'assets/img/team/avatar.png'


const ChatThread = ({ chat }) => {
  const dispatch = useDispatch();

  const { activeChatId } = useContext(ChatContext);

  const chatMessages = useSelector(state => state.chatMessages);

  const lastMessage = chat?.lastMessage;

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  // this useEffect is triggered for message history - go to chat option in action creator
  useEffect(() => {
    if (
      activeChatId === chat._id &&
      !chatMessages.some(chatM => chatM.id === chat._id)
    ) {
      dispatch(listEachChatMessages(chat));
    }
  }, [activeChatId]);

  const handleChatClick = async () => {
    dispatch({ type: UPDATE_ACTIVE_CHAT, payload: chat._id });
    if (!chatMessages.some(chatM => chatM.id === chat._id)) {
      dispatch(listEachChatMessages(chat));
    }
    if (chat?.read === 'false') {
      return (
        dispatch({ type: EDIT_CHAT, payload: { ...chat, read: 'true' } }),
        dispatch(updateChatRead({ ...chat, read: 'true' })),
        (document.getElementsByClassName('chat-sidebar')[0].style.left =
          '-100%')
      );
    } else {
      document.getElementsByClassName('chat-sidebar')[0].style.left = '-100%'; // change the css
    }
  };

  return (
    <NavLink
      className={classNames('chat-contact hover-actions-trigger p-3', {
        'unread-message': chat?.read === 'false',
        'read-message': chat?.read === 'true',
        active: chat._id === activeChatId // need to set this
      })}
      onClick={handleChatClick}
    >
      <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction contact={chat.contact} chat={chat} />
      </div>
      <Flex>
        <div
          className={
            chat?.read === 'false' ? 'mb-1 me-2 b-0 r-0 hover-hide' : 'd-none'
          }
        >
          <FontAwesomeIcon
            icon="circle"
            transform="shrink-5 down-4"
            className={chat?.read === 'false' ? 'text-primary' : 'd-none'}
          />
        </div>
        <Avatar
          className="online"
          src={avatarImage}
          icon={!chat?.contact?.firstName ? 'user' : ''}
          name={chat?.contact?.firstName ? chat?.contact?.firstName : ''}
          size="xl"
        />
        <div className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block">
          <Flex justifyContent="between">
            <h6 className="mb-0 chat-contact-title mt-2">
              {chat?.contact?.firstName && chat?.contact?.lastName
                ? chat?.contact?.firstName + ' ' + chat?.contact?.lastName
                : chat?.contact?.firstName
                  ? chat?.contact?.firstName
                  : formatPhoneNumber(chat?.phone)}
            </h6>
            <span className="message-time fs-11">
              {!!lastMessage && (
                <Moment format="ddd" date={chat?.dateUpdated} />
              )}
            </span>
          </Flex>

          <div className="d-flex align-items-center">
            <div className="chat-contact-content pe-3">
              <LastMessage lastMessage={lastMessage} chat={chat} />
            </div>
            {chat.mute === 'true' && (
              <FontAwesomeIcon
                icon="microphone-slash"
                transform="shrink-3 down-4"
              />
            )}
          </div>
        </div>
      </Flex>
    </NavLink>
  );
};

export default ChatThread;
