// ActionPopover.js
import React, { useState, useRef } from 'react';
import { Overlay, Popover, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppContext } from 'providers/AppProvider';

const ActionPopover = ({ id, items, activePopoverId, setActivePopoverId }) => {
  const {
    config: { isDark },
  } = useAppContext();

  // Use local state only if the caller did not supply a global handler.
  const [localShow, setLocalShow] = useState(false);
  const show = typeof activePopoverId === 'undefined' ? localShow : activePopoverId === id;
  const target = useRef(null);

  const togglePopover = () => {
    if (typeof activePopoverId !== 'undefined' && setActivePopoverId) {
      // If clicking the same popover, close it; otherwise, open this one.
      setActivePopoverId(activePopoverId === id ? null : id);
    } else {
      setLocalShow(!localShow);
    }
  };

  const handleClick = (action) => {
    action();
    if (typeof activePopoverId !== 'undefined' && setActivePopoverId) {
      setActivePopoverId(null);
    } else {
      setLocalShow(false);
    }
  };

  return (
    <>
      <Button
        ref={target}
        size="sm"
        className="text-600 bg-light border-dark"
        onClick={togglePopover}
      >
        <FontAwesomeIcon icon="ellipsis-h" className="fs--1" />
      </Button>

      <Overlay
        show={show}
        target={target.current}
        placement="left-end"
        containerPadding={20}
        rootClose
        onHide={() => {
          if (typeof activePopoverId !== 'undefined' && setActivePopoverId) {
            setActivePopoverId(null);
          } else {
            setLocalShow(false);
          }
        }}
      >
        <Popover id={`popover-${id}`}>
          <Popover.Body className="body-bg rounded border border-2 border-warning">
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => handleClick(item.action)}
                className={`text-${isDark ? 'white' : 'black'} popover-item cursor-pointer fw-semibold my-3 border-bottom hover-primary`}
              >
                <FontAwesomeIcon className={`text-${item.color} fs-9 me-2`} icon={item.icon} />
                {item.label}
              </div>
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

export default ActionPopover;
