import React, { useState, useContext } from 'react';
import { TabContent, TabPane } from 'reactstrap';
import ChatContentHeader from './ChatContentHeader';
import MessageTextArea from './MessageTextArea';
import ChatContentBody from './ChatContentBody';
import { ChatContext } from '../../../context/Context';

const ChatContent = ({
  chatNumber,
  setChatNumber,
  showCall,
  setShowCall,
  contactToCall,
  setContactToCall,
  callStatus,
  setCallStatus,
  setTime
}) => {
  const { selContact, chats, activeChatId } = useContext(ChatContext);

  const chat = chats.find(({ _id }) => _id === activeChatId);

  return (
    <TabContent className="card-chat-content">
      <TabPane className="card-chat-pane">
        <ChatContentHeader
          chat={chat}
          chatNumber={chatNumber}
          setChatNumber={setChatNumber}
          showCall={showCall}
          setShowCall={setShowCall}
          contactToCall={contactToCall}
          setContactToCall={setContactToCall}
          callStatus={callStatus}
          setCallStatus={setCallStatus}
          setTime={setTime}
        />
        <ChatContentBody chat={chat} />
      </TabPane>
      <MessageTextArea chat={chat} selContact={selContact} />
    </TabContent>
  );
};

export default ChatContent;
