import {
  GROUP_SHOW_HIDE_REQUEST,
  GROUP_SHOW_HIDE_SUCCESS,
  GROUP_SHOW_HIDE_FAIL,
  GROUP_SHOW_HIDE_RESET
} from '../actions/types';

export default function groupShowHideCreateReducer(state = {}, action) {
  switch (action.type) {
    case GROUP_SHOW_HIDE_REQUEST:
      return { loading: true };
    case GROUP_SHOW_HIDE_SUCCESS:
      return { loading: false, success: true };
    case GROUP_SHOW_HIDE_FAIL:
      return { loading: false, error: action.payload };
    case GROUP_SHOW_HIDE_RESET:
      return {};
    default:
      return state;
  }
}
