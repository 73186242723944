import React, { Fragment } from 'react';
import Section from './Section';
import { Col, Row, Card, CardBody, Button } from 'reactstrap';
import Logo from './Logo';
import Lottie from 'lottie-react';
import animationData from 'components/authentication/wizard/lottie/processingLoader.json';

const LoadingScreen = () => {
  return (
    <Section>
      <Fragment>
        <Row className="d-flex justify-content-center">
          <Col xs={10} sm={8} md={6}>
            <Logo />
            <div className="wizard-lottie-wrapper">
              <div className="wizard-lottie mx-auto">
                <Lottie size={10} animationData={animationData} loop={99} />
              </div>
            </div>
            <h4 className="text-center">Creating your environment</h4>
          </Col>
        </Row>
      </Fragment>
    </Section>
  );
};

export default LoadingScreen;
