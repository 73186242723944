import {
  CREATE_ITEM_REQUEST,
  CREATE_ITEM_SUCCESS,
  CREATE_ITEM_FAIL,
  CREATE_ITEM_RESET
} from '../actions/types';

export default function createItemReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_ITEM_REQUEST:
      return { loading: true };
    case CREATE_ITEM_SUCCESS:
      return { loading: false, success: true, newItem: action.payload };
    case CREATE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_ITEM_RESET:
      return {};
    default:
      return state;
  }
}
