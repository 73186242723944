// src/components/common/AdvanceTableSearchBox.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncDebounce } from 'react-table';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AdvanceTableSearchBox = ({ placeholder = 'Search...', onSearch }) => {
  const [value, setValue] = useState('');

  const handleSearch = useAsyncDebounce((searchValue) => {
    onSearch(searchValue);
  }, 200);

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    handleSearch(value);
  };

  return (
    <InputGroup className="pe-3 ps-2 mb-2">
      <InputGroupText className="bg-soft-secondary border-0">
        <FontAwesomeIcon icon="search" />
      </InputGroupText>
      <Input
        value={value}
        onChange={handleChange}
        size="md"
        id="search"
        placeholder={placeholder}
        type="search"
        autoComplete="off"
      />
    </InputGroup>
  );
};

AdvanceTableSearchBox.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired, // Make onSearch a required prop
};

export default AdvanceTableSearchBox;
