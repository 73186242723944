import React, { useState } from 'react';
import {
  Alert,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VirtualNumberSelection = () => {
  const navigate = useNavigate();

  const [numberType, setNumberType] = useState('tollFree');
  const [country, setCountry] = useState('United States');

  const handleNumberTypeChange = e => {
    setNumberType(e.target.value);
  };

  const handleCountryChange = e => {
    setCountry(e.target.value);
  };

  return (
    <div>
      <Alert color="warning">
        TrustPro numbers will NOT work for SMS verification or login purposes
        (bank accounts, social media accounts etc.). You can only receive text
        messages from your customers and staff using their mobile phones.
      </Alert>
      <Card className="shadow-lg">
        <CardBody>
          <h2 className="text-center">Choose your TrustPro virtual number</h2>
          <p className="text-center ">
            Get your SMS number for text messaging with your customers, staff
            and partners.
          </p>
          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={10} md={8}>
              <Form>
                <FormGroup>
                  <Label for="countrySelect">Country</Label>
                  <Input
                    type="select"
                    name="country"
                    id="countrySelect"
                    value={country}
                    onChange={handleCountryChange}
                  >
                    <option>United States</option>
                    <option>Canada</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for="numberType">Number type</Label>
                  <div>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="numberType"
                          value="tollFree"
                          checked={numberType === 'tollFree'}
                          onChange={handleNumberTypeChange}
                        />
                        Toll-free number
                      </Label>
                    </FormGroup>
                    <FormGroup check inline>
                      <Label check>
                        <Input
                          type="radio"
                          name="numberType"
                          value="10dlc"
                          checked={numberType === '10dlc'}
                          onChange={handleNumberTypeChange}
                        />
                        Local 10DLC number
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <div className="mt-4">
                  <h6>Getting started</h6>
                  <ul className="list-unstyled">
                    {numberType === 'tollFree' ? (
                      <>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Tax ID (EIN number) <b>not</b> required
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Brand registration: <b>Free</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Campaign registration: <b>Free</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Number cost: <b>$10 per month</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Traffic verified with the <b>SMS aggregator</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Approval period <b>3 - 5 days</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          2-way SMS, MMS and voice supported
                        </li>
                      </>
                    ) : (
                      <>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Tax ID (EIN number) <b>required</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Brand registration + vetting: <b>$65 one time</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Campaign registration: <b>$35 per quarter</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Number cost: <b>$10 per month</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Traffic whitelisted with the <b>Mobile operators</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          Approval period <b>up to 5 weeks</b>
                        </li>
                        <li className="py-2">
                          <FontAwesomeIcon
                            icon="check"
                            className="text-success me-2"
                          />{' '}
                          2-way SMS, MMS and voice supported
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={() => {
                      navigate('/follow-up/numbers');
                    }}
                    className="bg-transparent text-primary border-0 shadow-none"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      if (numberType === 'tollFree') {
                        navigate('/follow-up/compliance/toll-free');
                      } else if (numberType === '10dlc') {
                        navigate('/follow-up/compliance/10dlc');
                      }
                    }}
                    color="primary"
                  >
                    Continue
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default VirtualNumberSelection;
