import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button
} from 'reactstrap';
import Details from './Details';
import PropTypes from 'prop-types';
import MessageCreateForm from '../../compose/MessageCreateForm';
import { deleteChatMessages } from 'actions/chatActions';
import { deleteCalls } from 'actions/callActions';
import {
  RESET_BULK_TEXT_MESSAGE_CONTENT,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_CALL
} from 'actions/types';

const ChatMessageOptions = ({
  chatId,
  messageSid,
  message,
  mediaUri,
  callSid,
  from,
  to,
  direction,
  numSegments,
  startTime,
  endTime,
  status
}) => {
  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showForwardModal, setShowForwardModal] = useState(false);
  const [forwardState, setForwardState] = useState(message);
  const dispatch = useDispatch();

  const closeDeleteBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowDeleteMessageModal(!showDeleteMessageModal);
      }}
    ></button>
  );

  const closeDetailsBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowDetailsModal(!showDetailsModal);
      }}
    ></button>
  );

  const closeFwdBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return (
          dispatch({ type: RESET_BULK_TEXT_MESSAGE_CONTENT }),
          setShowForwardModal(!showForwardModal)
        );
      }}
    ></button>
  );

  return (
    <div>
      <ul className="hover-actions position-relative list-inline mb-0 text-400 mx-2">
        <li
          onClick={() => {
            setShowDetailsModal(!showDetailsModal);
          }}
          className="list-inline-item cursor-pointer chat-option-hover"
          id={`details-${messageSid}`}
        >
          <FontAwesomeIcon icon="exclamation-circle" />
          <UncontrolledTooltip placement="top" target={`details-${messageSid}`}>
            Details
          </UncontrolledTooltip>
        </li>
        {!callSid && !mediaUri && (
          <li
            onClick={() => {
              setShowForwardModal(!showForwardModal);
            }}
            className="list-inline-item cursor-pointer chat-option-hover"
            id={`share-${messageSid}`}
          >
            <FontAwesomeIcon icon="share" />
            <UncontrolledTooltip placement="top" target={`share-${messageSid}`}>
              Forward
            </UncontrolledTooltip>
          </li>
        )}
        <li
          onClick={() => {
            return setShowDeleteMessageModal(!showDeleteMessageModal);
          }}
          className="list-inline-item cursor-pointer chat-option-hover"
          id={`delete-${messageSid}`}
        >
          <FontAwesomeIcon icon="trash-alt" />
          <UncontrolledTooltip placement="top" target={`delete-${messageSid}`}>
            Remove
          </UncontrolledTooltip>
        </li>
      </ul>






      <div>
        <Modal
          isOpen={showDeleteMessageModal}
          centered
          toggle={() => setShowDeleteMessageModal(!showDeleteMessageModal)}
        >
          <ModalHeader close={closeDeleteBtn}>
            Confirm Deletion
          </ModalHeader>

          <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                if (callSid) {
                  return (
                    dispatch({
                      type: DELETE_CHAT_CALL,
                      payload: { callSid, chatId }
                    }),
                    dispatch(deleteCalls([callSid])),
                    setShowDeleteMessageModal(!showDeleteMessageModal)
                  );
                } else {
                  return (
                    dispatch({
                      type: DELETE_CHAT_MESSAGE,
                      payload: { messageSid, chatId }
                    }),
                    dispatch(deleteChatMessages(messageSid)),
                    setShowDeleteMessageModal(!showDeleteMessageModal)
                  );
                }
              }}
            >
              Delete
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                setShowDeleteMessageModal(!showDeleteMessageModal);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={showDetailsModal}
          centered
          toggle={() => setShowDetailsModal(!showDetailsModal)}
        >
          <ModalHeader close={closeDetailsBtn}>
            {callSid ? 'Call details' : 'Message details'}
          </ModalHeader>
          <ModalBody className="p-1">
            <Details
              from={from}
              to={to}
              direction={direction}
              numSegments={numSegments}
              startTime={startTime}
              endTime={endTime}
              callSid={callSid}
              message={message}
              status={status}
            />
          </ModalBody>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={showForwardModal}
          centered
          toggle={() => {
            setShowForwardModal(!showForwardModal);
          }}
        >
          <ModalHeader close={closeFwdBtn}>Forward message</ModalHeader>
          <ModalBody className="p-1">
            <MessageCreateForm forwardState={forwardState} />
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

ChatMessageOptions.propTypes = {
  id: PropTypes.string
};

export default ChatMessageOptions;
