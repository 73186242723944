import { CUSTOMER_PROFILE_FOLLOW_UP_REQUEST, CUSTOMER_PROFILE_FOLLOW_UP_SUCCESS, CUSTOMER_PROFILE_FOLLOW_UP_FAIL } from '../actions/types';

const initialState = {
    paymentProfiles: [],
    loading: false,
    error: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CUSTOMER_PROFILE_FOLLOW_UP_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case CUSTOMER_PROFILE_FOLLOW_UP_SUCCESS:
            return {
                ...state,
                paymentProfiles: action.payload.response.profile.paymentProfiles,
                loading: false
            };
        case CUSTOMER_PROFILE_FOLLOW_UP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}