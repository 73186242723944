import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  listGroups,
  listFields,
  createField
} from '../../actions/index';
import {
  Badge,
  Button,
  Card,
  CardTitle,
  Col,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Divider from '../common/Divider';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { GROUP_CREATE_RESET } from 'actions/types';
import ButtonIcon from 'components/common/ButtonIcon';
import { useAppContext } from 'providers/AppProvider';
import { createGroup } from '../../actions/index';
import NewProperty from 'components/customers/NewProperty';

const ContactEditModal = ({
  contact,
  setContact,
  setChangeText,
  groupId,
}) => {
  console.log("contact in contactEditModal", contact)
  const dispatch = useDispatch();
  const {
    config: { isDark }
  } = useAppContext();

  const [originalContactState, setOriginalContactState] = useState(contact);
  // New state variables for address handling
  const [showAddPropertyModal, setShowAddPropertyModal] = useState(false);


  const closeBtnC = (
    <button className="btn-close bg-light" onClick={() => { return setShowAddPropertyModal(!showAddPropertyModal) }}>
    </button>
  );

  // ...existing functions

  const handleAddAddress = () => {
    setShowAddPropertyModal(true);
  };

  const handleEditAddress = (index) => {
    setShowAddPropertyModal(true);
  };


  useEffect(() => {
    if (contact) {
      setOriginalContactState(contact)
    }
  }, []);

  useEffect(() => {
    if (contact !== originalContactState) {
      setChangeText(true)
    } else {
      setChangeText(false)
    }
  }, [contact]);

  const [currentGroups, setCurrentGroups] = useState({
    groups: [...contact?.group]
  })

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;

  const openGroups = []
  groups.map((g) => { if (!contact?.group?.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })


  const [showGroupModal, setShowGroupModal] = useState(false);
  const [group, setGroup] = useState({
    title: ''
  })
  const [field, setField] = useState({ title: '' });
  const [editGroups, setEditGroups] = useState(openGroups)
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false); // New state for phone number error
  const [showFieldModal, setShowFieldModal] = useState(false);

  const toggleUseCompanyName = () => {
    setContact(prevContact => ({
      ...prevContact,
      useCompanyName: !prevContact.useCompanyName
    }));
  };


  const closeFieldBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowFieldModal(!showFieldModal);
      }}
    ></button>
  );

  const handleChange = e => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleFieldChange = e => {
    setField({ ...field, [e.target.name]: e.target.value });
  };

  const { fields, loading: fieldsLoading } = useSelector(
    state => state.fields
  );
  const contactCreate = useSelector(state => state.createContact);
  const {
    loading: loadingCreate,
    success: successCreate,
    error: errorCreate,
    newContact: newContact
  } = contactCreate;

  const groupCreate = useSelector(state => state.groupCreate);
  const { success: successCreateGroup } = groupCreate;
  const fieldCreate = useSelector(state => state.fieldCreate);
  const { success: successFieldCreate } = fieldCreate;

  useEffect(() => {
    if (groupId) {
      const incomingGroup = groups.filter(g => g._id === groupId);
      setContact({ ...contact, group: incomingGroup });
    }
  }, []);

  useEffect(() => {
    if (successCreateGroup) {
      const closeButtonStyles = {
        fontSize: '18px',
        cursor: 'pointer'
      };

      const CustomCloseButton = ({ closeToast }) => (
        <div onClick={closeToast} style={closeButtonStyles}>
          ×
        </div>
      );
      toast(
        <Fragment>
          <strong>✅ Group created</strong>
        </Fragment>,
        {
          position: 'top-center',
          bodyClassName: 'fw-bold',
          closeButton: CustomCloseButton
        }
      );
      const newGroup = {
        _id: groupCreate.createdGroup.createdGroup._id,
        title: groupCreate.createdGroup.createdGroup.title,
        hide: groupCreate.createdGroup.createdGroup.hide,
        created: groupCreate.createdGroup.createdGroup.created,
        contactsCount: groupCreate.createdGroup.createdGroup.contactsCount
      };
      const addCreatedGroup = [...contact.group, newGroup];
      setContact({ ...contact, group: addCreatedGroup });
      dispatch({ type: GROUP_CREATE_RESET })
    }
  }, [successCreateGroup]);

  useEffect(() => {
    dispatch(listGroups());
  }, [contact.phoneNumber, contact.phoneType, successCreateGroup]);

  useEffect(() => {
    dispatch(listFields());
  }, [successFieldCreate]);


  const handleEmailChange = (index, field, value) => {
    const newEmails = [...contact.emails];
    newEmails[index][field] = value;

    // Ensure only one primary email
    if (field === 'primary' && value === true) {
      newEmails.forEach((email, idx) => {
        if (idx !== index) {
          email.primary = false;
        }
      });
    }

    setContact({ ...contact, emails: newEmails });
  };

  const handlePhoneChange = (index, field, value) => {
    const newPhones = [...contact.phones];
    newPhones[index][field] = value;

    // Ensure only one primary phone number
    if (field === 'primary' && value === true) {
      newPhones.forEach((phone, idx) => {
        if (idx !== index) {
          phone.primary = false;
        }
      });
    }

    setContact({ ...contact, phones: newPhones });
  };

  const addEmailField = () => {
    setContact({
      ...contact,
      emails: [
        ...contact.emails,
        { email: '', type: 'Main', primary: false }
      ]
    });
  };

  const addPhoneField = () => {
    setContact({
      ...contact,
      phones: [
        ...contact.phones,
        { number: '', type: 'Mobile', primary: false }
      ]
    });
  };

  const removeEmailField = index => {
    const newEmails = contact.emails.filter((_, idx) => idx !== index);
    setContact({ ...contact, emails: newEmails });
  };

  const removePhoneField = index => {
    const newPhones = contact.phones.filter((_, idx) => idx !== index);
    setContact({ ...contact, phones: newPhones });
  };

  // Helper functions to render form sections
  const renderNameFields = () => (
    <>
      {nameError && (
        <div className="mx-2 my-2">
          <p className="error bg-soft-danger my-2 ml-2" block>
            Please add first name, last name, or company
          </p>
        </div>
      )}
      <FormGroup floating>
        <Input
          id="firstName"
          name="firstName"
          placeholder="First Name"
          value={contact.firstName}
          onChange={handleChange}
          autoComplete="off"
        />
        <Label for="firstName">First Name</Label>
      </FormGroup>

      <FormGroup floating>
        <Input
          id="lastName"
          name="lastName"
          placeholder="Last Name"
          value={contact.lastName}
          onChange={handleChange}
          autoComplete="off"
        />
        <Label for="lastName">Last Name</Label>
      </FormGroup>

      <FormGroup floating>
        <Input
          id="company"
          name="company"
          placeholder="Company"
          value={contact.company}
          onChange={handleChange}
          autoComplete="off"
        />
        <Label for="company">Company</Label>
      </FormGroup>

      <FormGroup className="mb-4" check>
        <Label check>
          <Input
            style={{ width: '20px', height: '20px' }}
            className="me-2"
            type="checkbox"
            onChange={toggleUseCompanyName}
            checked={contact.useCompanyName}
          />{' '}
          Use Company Name as Primary
        </Label>
      </FormGroup>
    </>
  );

  const renderEmailInputs = () => (
    <>
      <h6>Emails</h6>
      {contact?.emails?.map((email, index) => (
        <Fragment key={index}>
          <div className="d-flex flex-column me-2">
            <FormGroup check className="mb-0">
              <Label check>
                <Input
                  style={{ width: '15px', height: '15px' }}
                  type="checkbox"
                  checked={email.primary}
                  onChange={e =>
                    handleEmailChange(index, 'primary', e.target.checked)
                  }
                />{' '}
                Primary
              </Label>
            </FormGroup>
          </div>
          <div className="d-flex align-items-start">
            <div>
              <FormGroup floating>
                <Input
                  type="select"
                  id={`emailType-${index}`}
                  value={email.type}
                  onChange={e =>
                    handleEmailChange(index, 'type', e.target.value)
                  }
                >
                  <option value="main">Main</option>
                  <option value="work">Work</option>
                  <option value="personal">Personal</option>
                  <option value="other">Other</option>
                </Input>
                <Label for={`emailType-${index}`}>Type</Label>
              </FormGroup>
            </div>
            <div className="flex-grow-1">
              <FormGroup floating>
                <Input
                  id={`email-${index}`}
                  type="email"
                  placeholder="Email"
                  value={email.email}
                  onChange={e =>
                    handleEmailChange(index, 'email', e.target.value)
                  }
                  autoComplete="off"
                />
                <Label for={`email-${index}`}>Email</Label>
              </FormGroup>
            </div>
            {index > 0 && (
              <Button
                size="sm"
                color="danger"
                className="ms-2 mt-2 p-2"
                onClick={() => removeEmailField(index)}
              >
                <FontAwesomeIcon transform="grow-5" icon="trash-alt" />
              </Button>
            )}
          </div>
        </Fragment>
      ))}
      <ButtonIcon
        icon="plus"
        className="btn-deepPrimary border mb-4"
        size="sm"
        onClick={addEmailField}
      >
        Email Address
      </ButtonIcon>
    </>
  );

  const handleGroupSubmit = (e) => {
    e.preventDefault()
    dispatch(createGroup(group))
    setShowGroupModal(false)
    setEditGroups([...editGroups, group])
  };

  const handleGroupChange = e => {
    setGroup({ ...group, [e.target.name]: e.target.value })
  }



  // Function to handle the removal logic
  const handleGroupRemove = (groupId) => {
    // Ensure proper filtering by group id
    const updatedGroups = currentGroups.groups.filter((group) => group._id !== groupId);

    // Find the removed group in currentGroups to add it back to editGroups
    const removedGroup = currentGroups.groups.find((group) => group._id === groupId);

    // Update currentGroups state
    setCurrentGroups({
      groups: updatedGroups,
    });

    // Update contact state to reflect the removed group
    setContact({
      ...contact,
      groups: updatedGroups,
    });

    // Add the removed group back to editGroups for selection in the dropdown
    if (removedGroup) {
      setEditGroups([...editGroups, removedGroup]);
    }
  };

  // Function to handle adding a group from the dropdown
  const handleGroupAdd = (group) => {
    // Check if the group is already in currentGroups
    const isAlreadyInGroup = currentGroups.groups.some(
      (currentGroup) => currentGroup._id === group._id
    );

    if (!isAlreadyInGroup) {
      // Update currentGroups and contact to add the selected group
      const updatedGroups = [...currentGroups.groups, group];

      setCurrentGroups({
        groups: updatedGroups,
      });

      setContact({
        ...contact,
        groups: updatedGroups,
      });

      // Remove the added group from editGroups
      const updatedEditGroups = editGroups.filter((editGroup) => editGroup._id !== group._id);
      setEditGroups(updatedEditGroups);
    }
  };

  const renderGroupInputs = () => (
    <FormGroup>
      <Card
        className="border-1"
        style={{
          borderRadius: '10px',
          boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
          border: 'none',
        }}
      >
        <Row className="mb-2">
          <Col className="mt-2 d-flex justify-content-between align-items-center">
            <Label
              className="mt-1 ms-3"
              style={{ fontSize: '1.1rem', fontWeight: '500' }}
            >
              Current Groups
            </Label>
            <UncontrolledDropdown direction="left" className="d-inline-block">
              <DropdownToggle
                color="primary"
                size="sm"
                className="me-2 border-0"
                style={{
                  backgroundColor: '#007bff',
                  borderRadius: '50%',
                  width: '35px',
                  height: '35px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: '0px 4px 12px rgba(0, 123, 255, 0.4)',
                  transition: 'transform 0.2s ease',
                }}
              >
                <FontAwesomeIcon icon="chevron-down" style={{ color: '#fff' }} />
              </DropdownToggle>
              <DropdownMenu
                style={{
                  boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
                  borderRadius: '8px',
                  padding: '0.5rem',
                }}
              >
                <DropdownItem
                  onClick={() => setShowGroupModal(true)}
                  style={{
                    padding: '0.75rem 1.25rem',
                    fontSize: '0.95rem',
                    color: '#007bff',
                    fontWeight: '500',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease',
                  }}
                >
                  + Create New
                </DropdownItem>
                {editGroups?.map((group, index) => (
                  <DropdownItem
                    key={index}
                    onClick={() => handleGroupAdd(group)}
                    style={{
                      padding: '0.75rem 1.25rem',
                      fontSize: '0.95rem',
                      fontWeight: '500',
                      cursor: 'pointer',
                      borderRadius: '5px',
                      transition: 'background-color 0.3s ease',
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.backgroundColor = '#9da9bb';
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.backgroundColor = 'transparent';
                    }}
                  >
                    {group?.title}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        </Row>
        <Row className="mb-3">
          {currentGroups?.groups.map((group) => (
            <div className="mx-2">
              <Badge color="success" className="m-2 p-0" style={{ display: 'inline-flex', width: 'auto' }}>
                <Card className="m-1" style={{ width: 'fit-content' }}>
                  <CardBody style={{ padding: '.25rem 1rem', display: 'flex', alignItems: 'center' }}>
                    <Button
                      className="btn-tertiary me-2"
                      size="sm"
                      onClick={() => handleGroupRemove(group._id)}
                    >
                      <FontAwesomeIcon transform="grow-5" icon="times" style={{ color: '#ff0000' }}  // Ensure this is properly passed
                      />
                    </Button>
                    <CardTitle tag="h5" className="m-0">{group.title}</CardTitle>
                    <Badge color="secondary" className="ms-2">{group.contactsCount}</Badge>
                  </CardBody>
                </Card>
              </Badge>
            </div>
          ))}
        </Row>
      </Card>
    </FormGroup>
  );


  const renderPhoneInputs = () => (
    <>
      {phoneError && (
        <div className="mx-2 my-2">
          <p className="error bg-soft-danger my-2 ml-2" block>
            Please add at least one phone number
          </p>
        </div>
      )}
      <h6>Phone Numbers</h6>
      {contact?.phones?.map((phone, index) => (
        <Fragment key={index}>
          <div className="d-flex flex-column me-2">
            <FormGroup check className="mb-0">
              <Label check>
                <Input
                  style={{ width: '15px', height: '15px' }}
                  type="checkbox"
                  checked={phone.primary}
                  onChange={e =>
                    handlePhoneChange(index, 'primary', e.target.checked)
                  }
                />{' '}
                Primary
              </Label>
            </FormGroup>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <FormGroup floating>
                <Input
                  type="select"
                  id={`phoneType-${index}`}
                  value={phone.type}
                  onChange={e =>
                    handlePhoneChange(index, 'type', e.target.value)
                  }
                >
                  <option value="mobile">Mobile</option>
                  <option value="work">Work</option>
                  <option value="home">Home</option>
                  <option value="other">Other</option>
                </Input>
                <Label for={`phoneType-${index}`}>Type</Label>
              </FormGroup>
            </div>
            <div className="flex-grow-1 me-2">
              <FormGroup>
                <PhoneInput
                  country="us"
                  isValid={(value, country) => {
                    if (value.match(/12345/)) {
                      return (
                        'Invalid value: ' + value + ', ' + country.name
                      );
                    } else if (value.match(/1234/)) {
                      return false;
                    } else {
                      return true;
                    }
                  }}
                  countryCodeEditable={false}
                  dropdownClass="rounded"
                  preferredCountries={[
                    'us',
                    'ca',
                    'mx',
                    'fr',
                    'it',
                    'br',
                    'co',
                    'it',
                    'gr'
                  ]}
                  limitMaxLength={true}
                  enableSearch={true}
                  value={phone.number}
                  onChange={value =>
                    handlePhoneChange(index, 'number', value)
                  }
                  inputStyle={{ width: '100%', height: '55px' }}
                  inputClass={`w-100 bg-${isDark ? 'dark' : 'light'
                    }`}
                />

              </FormGroup>
            </div>
            {index > 0 && (
              <Button
                size="sm"
                color="danger"
                className="ms-2 p-2 mb-2"
                onClick={() => removePhoneField(index)}
              >
                <FontAwesomeIcon transform="grow-5" icon="trash-alt" />
              </Button>
            )}
          </div>
        </Fragment>
      ))}
      <ButtonIcon
        icon="plus"
        className="btn-deepPrimary border mb-4"
        size="sm"
        onClick={addPhoneField}
      >
        Phone Number
      </ButtonIcon>
    </>
  );

  const renderAddressFields = () => (
    <>
      <ButtonIcon
        icon="plus"
        className="btn-deepPrimary border mb-2"
        size="sm"
        onClick={handleAddAddress}
      >
        Add Address
      </ButtonIcon>

      {contact.addresses.map((address, index) => (
        <Card
          key={index}
          className="mb-2"
          onClick={() => handleEditAddress(index)}
          style={{ cursor: 'pointer' }}
        >
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6>{address.street}</h6>
                <p>{`${address.city}, ${address.state} ${address.postalCode}`}</p>
              </div>
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </CardBody>
        </Card>
      ))}

      {showAddPropertyModal && renderAddAddressModal()}
    </>
  );

  const renderAddAddressModal = () => (
    <Modal size="md" isOpen={showAddPropertyModal} centered toggle={() => setShowAddPropertyModal(!showAddPropertyModal)}>
      <ModalHeader close={closeBtnC}>{`New Property for ${contact?.firstName + " " + contact?.lastName}`}</ModalHeader>
      <ModalBody className="p-0">
        <NewProperty
          showAddPropertyModal={showAddPropertyModal}
          setShowAddPropertyModal={setShowAddPropertyModal}
          contact={contact}
          setContact={setContact}
        />
      </ModalBody>
    </Modal>
  );

  const renderCustomFields = () => (
    <>
      <Divider className="mt-4">
        Custom field values unique to this contact
      </Divider>
      <div>
        <ButtonIcon
          onClick={() => setShowFieldModal(!showFieldModal)}
          className="btn-deepPrimary border mb-2"
          size="sm"
          icon="plus"
        >
          {` New Field`}
        </ButtonIcon>
        <Modal
          isOpen={showFieldModal}
          centered
          toggle={() => setShowFieldModal(!showFieldModal)}
        >
          <ModalHeader
            close={closeFieldBtn}
            className="text-center d-flex flex-between-center border-bottom-0"
          >
            Create a new Field
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs-9 font-weight-normal p-4">
                <Label for="title">Field Name:</Label>
                <Input
                  value={field.title.value}
                  onChange={handleFieldChange}
                  className="mb-3"
                  name="title"
                  id="title"
                />
                <Button
                  block
                  disabled={field?.title?.length < 1}
                  onClick={() => {
                    dispatch(createField(field));
                    setShowFieldModal(!showFieldModal);
                  }}
                  color="primary"
                  className="mb-3"
                >
                  Save
                </Button>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </div>
      {fields.map(field => {
        // Find the corresponding value in contact.fields
        const contactField = contact.fields.find(f => f._id === field._id);
        const value = contactField ? contactField.value : '';

        return (
          <FormGroup floating key={field._id}>
            <Input
              id={`field-${field._id}`}
              name={`field-${field._id}`}
              onChange={e => {
                const value = e.target.value;

                setContact(prevContact => {
                  const existingFieldIndex = prevContact.fields.findIndex(
                    f => f._id === field._id
                  );

                  let updatedFields;
                  if (existingFieldIndex >= 0) {
                    // Field exists, update it
                    updatedFields = prevContact.fields.map(f =>
                      f._id === field._id ? { ...f, value } : f
                    );
                  } else {
                    // Field doesn't exist, add it
                    updatedFields = [
                      ...prevContact.fields,
                      { _id: field._id, value },
                    ];
                  }

                  return {
                    ...prevContact,
                    fields: updatedFields,
                  };
                });
              }}

              autoComplete="off"
              value={value}
            />
            <Label for={`field-${field._id}`}>{field.title}</Label>
          </FormGroup>
        );
      })}

    </>
  );

  return (
    <>
      <Form>
        <>
          {renderPhoneInputs()}
          {renderNameFields()}
          {renderEmailInputs()}
          {renderGroupInputs()}
        </>
        {renderAddressFields()}
        {renderCustomFields()}
      </Form>

      <Modal isOpen={showGroupModal} centered toggle={() => setShowGroupModal(!showGroupModal)}>
        <ModalHeader className="text-center d-flex flex-between-center border-bottom-0">
          Create a new group
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Form onSubmit={handleGroupSubmit}>
                <Label for="title">Group Name:</Label>
                <Input value={group.title.value} onChange={handleGroupChange} className="mb-3" name="title" id="title" />
                <Button block onClick={() => { setShowGroupModal(false) }} color="primary" className="mb-3" type="submit">Save</Button>
              </Form>
              <Button block onClick={() => { setShowGroupModal(false) }}>Close</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </>
  );
};



export default ContactEditModal;
