import React, { useState, Fragment, useContext } from 'react';
import { FormGroup, Input, Label, Form, FormFeedback } from 'reactstrap';
import { NewUserUploadContext } from '../../../context/Context';
import PhoneInput from 'react-phone-input-2';
import { useAppContext } from 'providers/AppProvider';

const StepOneForm = () => {

  const { upload, setUpload } = useContext(NewUserUploadContext);
  const { handleInputChange } = useContext(NewUserUploadContext);
  const [companyWebsiteValid, setCompanyWebsiteValid] = useState(null); // null means untouched

  // Function to validate website URL
  const validateWebsite = value => {
    // Regular expression to match 'www.something.something' format
    const pattern = /^www\.[^\s]+\.[^\s]+$/;
    return pattern.test(value);
  };


  const handleWebsiteChange = ({ target }) => {
    const value = target.value;
    handleInputChange(target);

    // Validate the website URL
    if (value === '') {
      // Empty value, consider invalid
      setCompanyWebsiteValid(false);
    } else if (validateWebsite(value)) {
      setCompanyWebsiteValid(true);
    } else {
      setCompanyWebsiteValid(false);
    }
  };


  const { config: { isDark } } = useAppContext();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit form logic goes here...
    console.log(upload);
  };

  const handlePhoneNumberChange = (value) => {

    let phoneNumber = value;

    // Remove any non-numeric characters
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Update state
    setUpload(prevState => ({
      ...prevState,
      phoneNumber: phoneNumber,
    }));
  };


  return (
    <Fragment>
      <h4 className="mb-1 text-center fw-bold">Your account is now active</h4>
      <h6 className="mb-1 text-center">Let's get you started</h6>
      <Form onSubmit={handleSubmit} className="container mt-5 p-5 border rounded" style={{ maxWidth: '500px' }}>
        <FormGroup floating>
          <Input
            type="text"
            id="companyName"
            name="companyName"
            value={upload.companyName}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            required
          />
          <Label for="companyName">Company name</Label>
        </FormGroup>

        <FormGroup floating>
          <Input
            type="text"
            id="companyWebsite"
            name="companyWebsite"
            value={upload.companyWebsite}
            onChange={handleWebsiteChange}
            onBlur={handleWebsiteChange}
            valid={companyWebsiteValid === true}
            invalid={companyWebsiteValid === false}
            required
          />
          <Label for="companyWebsite">Company website</Label>
          <FormFeedback>Please enter a valid website URL.</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>Enter a Number</Label>
          <PhoneInput
            country="us"
            isValid={(value, country) => {
              if (value.match(/12345/)) {
                return 'Invalid value: ' + value + ', ' + country.name;
              } else if (value.match(/1234/)) {
                return false;
              } else {
                return true;
              }
            }}
            inputStyle={{ width: '100%', height: '55px' }}
            inputClass={`w-100 bg-${isDark ? "dark" : "light"}`}
            countryCodeEditable={false}
            dropdownClass="rounded"
            preferredCountries={['us', 'ca', 'mx', 'fr', 'it', 'br', 'co', 'it', 'gr']}
            limitMaxLength={true}
            enableSearch={true}
            name="phoneNumber"
            value={upload.phoneNumber}
            onChange={handlePhoneNumberChange}
          />
        </FormGroup>

        <FormGroup>
          <Label for="industry">Choose your industry</Label>
          <Input
            type="select"
            id="industry"
            name="industry"
            value={upload.industry || ''}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            required
          >
            <option value="">Please select</option>
            {/* Agriculture, Forestry, Fishing and Hunting */}
            <option value="agriculture">Agriculture</option>
            <option value="forestry">Forestry</option>
            <option value="fishing">Fishing</option>
            <option value="hunting">Hunting</option>

            {/* Mining, Quarrying, and Oil and Gas Extraction */}
            <option value="mining">Mining</option>
            <option value="oil_and_gas">Oil and Gas Extraction</option>
            <option value="quarrying">Quarrying</option>

            {/* Utilities */}
            <option value="utilities">Utilities</option>

            {/* Construction */}
            <option value="construction">Construction</option>

            {/* Manufacturing */}
            <option value="manufacturing">Manufacturing</option>
            <option value="food_manufacturing">Food Manufacturing</option>
            <option value="beverage_tobacco_manufacturing">Beverage and Tobacco Manufacturing</option>
            <option value="textile_mills">Textile Mills</option>
            <option value="apparel_manufacturing">Apparel Manufacturing</option>
            <option value="paper_manufacturing">Paper Manufacturing</option>
            <option value="chemical_manufacturing">Chemical Manufacturing</option>
            <option value="plastics_rubber_manufacturing">Plastics and Rubber Products Manufacturing</option>
            <option value="metal_manufacturing">Primary Metal Manufacturing</option>
            <option value="machinery_manufacturing">Machinery Manufacturing</option>
            <option value="computer_electronics_manufacturing">Computer and Electronic Product Manufacturing</option>
            <option value="transportation_equipment_manufacturing">Transportation Equipment Manufacturing</option
            >

            {/* Wholesale Trade */}
            <option value="wholesale_trade">Wholesale Trade</option>

            {/* Retail Trade */}
            <option value="retail_trade">Retail Trade</option>
            <option value="automotive_retail">Automotive Retail</option>
            <option value="furniture_retail">Furniture and Home Furnishings Retail</option>
            <option value="electronics_retail">Electronics and Appliance Retail</option>
            <option value="grocery_retail">Grocery and Food Retail</option>
            <option value="clothing_retail">Clothing and Accessories Retail</option>
            <option value="general_merchandise_retail">General Merchandise Retail</option>

            {/* Transportation and Warehousing */}
            <option value="transportation">Transportation</option>
            <option value="warehousing">Warehousing</option>
            <option value="logistics">Logistics</option>
            <option value="courier_services">Courier and Express Delivery Services</option>

            {/* Information */}
            <option value="information">Information</option>
            <option value="publishing">Publishing Industries</option>
            <option value="motion_picture_sound">Motion Picture and Sound Recording</option>
            <option value="broadcasting">Broadcasting</option>
            <option value="telecommunications">Telecommunications</option>
            <option value="data_processing">Data Processing, Hosting, and Related Services</option>
            <option value="internet_publishing">Internet Publishing and Web Search Portals</option>

            {/* Finance and Insurance */}
            <option value="finance">Finance</option>
            <option value="insurance">Insurance</option>
            <option value="banking">Banking</option>
            <option value="investment">Investment Services</option>
            <option value="real_estate">Real Estate</option>

            {/* Professional, Scientific, and Technical Services */}
            <option value="professional_services">Professional Services</option>
            <option value="legal_services">Legal Services</option>
            <option value="accounting">Accounting Services</option>
            <option value="architectural_engineering">Architectural and Engineering Services</option>
            <option value="computer_design_services">Computer Systems Design Services</option>
            <option value="management_consulting">Management Consulting Services</option>
            <option value="scientific_research">Scientific Research and Development</option>
            <option value="advertising">Advertising and Public Relations</option>

            {/* Administrative and Support Services */}
            <option value="administrative_services">Administrative Services</option>
            <option value="employment_services">Employment Services</option>
            <option value="business_support_services">Business Support Services</option>
            <option value="travel_services">Travel Arrangement and Reservation Services</option>
            <option value="investigation_security_services">Investigation and Security Services</option>
            <option value="services_to_buildings_dwellings">Services to Buildings and Dwellings</option>
            <option value="waste_management">Waste Management and Remediation Services</option>

            {/* Educational Services */}
            <option value="educational_services">Educational Services</option>
            <option value="schools">Schools</option>
            <option value="colleges_universities">Colleges and Universities</option>
            <option value="training_centers">Training Centers</option>

            {/* Health Care and Social Assistance */}
            <option value="health_care">Health Care</option>
            <option value="hospitals">Hospitals</option>
            <option value="physicians">Physicians' Offices</option>
            <option value="dentists">Dentists' Offices</option>
            <option value="outpatient_care_centers">Outpatient Care Centers</option>
            <option value="home_health_care_services">Home Health Care Services</option>
            <option value="nursing_residential_care">Nursing and Residential Care Facilities</option>
            <option value="social_assistance">Social Assistance</option>

            {/* Arts, Entertainment, and Recreation */}
            <option value="arts_entertainment">Arts and Entertainment</option>
            <option value="performing_arts">Performing Arts Companies</option>
            <option value="spectator_sports">Spectator Sports</option>
            <option value="museums_historical_sites">Museums and Historical Sites</option>
            <option value="amusement_gambling">Amusement, Gambling, and Recreation Industries</option>

            {/* Accommodation and Food Services */}
            <option value="accommodation">Accommodation</option>
            <option value="hotels">Hotels and Motels</option>
            <option value="food_services">Food Services</option>
            <option value="restaurants">Restaurants</option>
            <option value="bars">Bars and Nightclubs</option>
            <option value="catering_services">Catering Services</option>

            {/* Other Services (except Public Administration) */}
            <option value="other_services">Other Services</option>
            <option value="repair_maintenance">Repair and Maintenance</option>
            <option value="personal_services">Personal and Laundry Services</option>
            <option value="religious_organizations">Religious Organizations</option>
            <option value="civic_social_organizations">Civic and Social Organizations</option>

            {/* Public Administration */}
            <option value="public_administration">Public Administration</option>
            <option value="government">Government</option>
            <option value="international_organizations">International Organizations</option>

            {/* Technology and Software */}
            <option value="software_development">Software Development</option>
            <option value="information_technology">Information Technology Services</option>
            <option value="cybersecurity">Cybersecurity</option>
            <option value="artificial_intelligence">Artificial Intelligence</option>
            <option value="blockchain">Blockchain</option>
            <option value="biotechnology">Biotechnology</option>

            {/* Energy and Utilities */}
            <option value="renewable_energy">Renewable Energy</option>
            <option value="electric_power">Electric Power Generation</option>
            <option value="water_supply">Water Supply and Irrigation Systems</option>
            <option value="natural_gas_distribution">Natural Gas Distribution</option>

            {/* Transportation Equipment Manufacturing */}
            <option value="aerospace_defense">Aerospace and Defense</option>
            <option value="automotive">Automotive Manufacturing</option>
            <option value="shipbuilding">Shipbuilding</option>
            <option value="railroad_equipment">Railroad Equipment Manufacturing</option>

            {/* Media and Communications */}
            <option value="media">Media</option>
            <option value="news_agencies">News Agencies</option>
            <option value="public_relations">Public Relations</option>
            <option value="film_production">Film Production</option>
            <option value="music_industry">Music Industry</option>

            {/* Financial Services */}
            <option value="accounting_services">Accounting Services</option>
            <option value="tax_preparation">Tax Preparation Services</option>
            <option value="financial_planning">Financial Planning</option>
            <option value="mortgage_brokers">Mortgage Brokers</option>

            {/* Real Estate */}
            <option value="real_estate_development">Real Estate Development</option>
            <option value="property_management">Property Management</option>
            <option value="real_estate_agents">Real Estate Agents and Brokers</option>
            <option value="appraisal_services">Appraisal Services</option>

            {/* Agriculture Technology */}
            <option value="agritech">AgriTech</option>
            <option value="hydroponics">Hydroponics</option>
            <option value="aquaculture">Aquaculture</option>

            {/* Environmental Services */}
            <option value="environmental_services">Environmental Services</option>
            <option value="recycling_waste_management">Recycling and Waste Management</option>
            <option value="environmental_consulting">Environmental Consulting</option>

            {/* Hospitality */}
            <option value="event_management">Event Management</option>
            <option value="travel_agencies">Travel Agencies</option>
            <option value="tourism">Tourism</option>
            <option value="hospitality_management">Hospitality Management</option>

            {/* Non-Profit Organizations */}
            <option value="non_profit">Non-Profit Organizations</option>
            <option value="charities">Charities</option>
            <option value="foundations">Foundations</option>
            <option value="advocacy_groups">Advocacy Groups</option>

            {/* Education Technology */}
            <option value="edtech">EdTech</option>
            <option value="e_learning">E-Learning</option>
            <option value="educational_consulting">Educational Consulting</option>

            {/* Human Resources and Staffing */}
            <option value="human_resources">Human Resources</option>
            <option value="staffing_recruiting">Staffing and Recruiting</option>
            <option value="professional_training">Professional Training</option>

            {/* Sports and Recreation */}
            <option value="sports_management">Sports Management</option>
            <option value="fitness_centers">Fitness Centers</option>
            <option value="outdoor_recreation">Outdoor Recreation</option>

            {/* Beauty and Personal Care */}
            <option value="beauty_salon">Beauty Salons</option>
            <option value="spa_services">Spa Services</option>
            <option value="cosmetics">Cosmetics</option>
            <option value="personal_care_products">Personal Care Products</option>

            {/* Retail and E-commerce */}
            <option value="ecommerce">E-commerce</option>
            <option value="online_marketplaces">Online Marketplaces</option>
            <option value="fashion_retail">Fashion Retail</option>
            <option value="specialty_retail">Specialty Retail</option>

            {/* Food and Beverage */}
            <option value="food_beverage">Food and Beverage</option>
            <option value="food_production">Food Production</option>
            <option value="beverage_production">Beverage Production</option>
            <option value="food_delivery">Food Delivery Services</option>

            {/* Logistics and Supply Chain */}
            <option value="supply_chain_management">Supply Chain Management</option>
            <option value="freight_forwarding">Freight Forwarding</option>
            <option value="inventory_management">Inventory Management</option>

            {/* Consulting Services */}
            <option value="business_consulting">Business Consulting</option>
            <option value="strategy_consulting">Strategy Consulting</option>
            <option value="marketing_consulting">Marketing Consulting</option>
            <option value="hr_consulting">HR Consulting</option>

            {/* Aerospace */}
            <option value="space_technology">Space Technology</option>
            <option value="satellite_services">Satellite Services</option>
            <option value="aerospace_research">Aerospace Research</option>

            {/* Telecommunications */}
            <option value="wireless_communications">Wireless Communications</option>
            <option value="internet_service_providers">Internet Service Providers</option>
            <option value="network_infrastructure">Network Infrastructure</option>

            {/* Pharmaceuticals */}
            <option value="pharmaceuticals">Pharmaceuticals</option>
            <option value="medical_devices">Medical Devices</option>
            <option value="biopharmaceuticals">Biopharmaceuticals</option>

            {/* Insurance */}
            <option value="life_insurance">Life Insurance</option>
            <option value="health_insurance">Health Insurance</option>
            <option value="property_casualty_insurance">Property and Casualty Insurance</option>

            {/* Automotive Services */}
            <option value="auto_repair">Auto Repair</option>
            <option value="car_dealerships">Car Dealerships</option>
            <option value="auto_parts">Auto Parts Retail</option>
            <option value="vehicle_rental">Vehicle Rental Services</option>

            {/* Defense and Security */}
            <option value="defense">Defense</option>
            <option value="private_security">Private Security</option>
            <option value="cybersecurity_services">Cybersecurity Services</option>

            {/* Biotechnology */}
            <option value="genomics">Genomics</option>
            <option value="biomedical_research">Biomedical Research</option>
            <option value="biomanufacturing">Biomanufacturing</option>

            {/* Art and Design */}
            <option value="graphic_design">Graphic Design</option>
            <option value="interior_design">Interior Design</option>
            <option value="industrial_design">Industrial Design</option>
            <option value="fashion_design">Fashion Design</option>

            {/* Other */}
            <option value="other">Other</option>
          </Input>
        </FormGroup>
      </Form>
    </Fragment>
  );
};

export default StepOneForm;
