import { LIST_COMPANY_LOGO, LOGO_ERROR } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_COMPANY_LOGO:
      return action.payload;
    case LOGO_ERROR:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
}
