import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Alert,
  Card,
  Col,
  Row,
  CardBody,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Button
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { findANumber, createNumber } from 'actions/index';
import { NUMBER_CREATE_RESET, AVAIL_NUMBERS_RESET } from 'actions/types';
import animationData from 'components/authentication/wizard/lottie/celebration.json';
import animationDanger from 'components/authentication/wizard/lottie/warning-light.json';
import numberCountries from 'data/billing/numberCountries';
import { DLCUploadContext } from 'context/Context';
import { fetchUser } from 'actions/index';
import { fetchTeamDetails } from 'actions/teamActions';

const StepOneForm = () => {
  const dispatch = useDispatch();
  const [showNumberModal, setShowNumberModal] = useState(false);
  const [showAddCreditModal, setShowAddCreditModal] = useState(false);
  const [showSwitchFlowModal, setShowSwitchFlowModal] = useState(false);
  const [selectedNumber, setSelectedNumber] = useState('');
  const [country, setCountry] = useState(numberCountries[0]);
  const [areaCode, setAreaCode] = useState('');
  const [numberType, setNumberType] = useState('10dlc');

  const { upload, setUpload, setStep } = useContext(DLCUploadContext);

  const location = useLocation();
  const { step } = location.state || {};

  useEffect(() => {
    //we are sending the step from the numbers table registration formatter, this is not the step from the provider
    if (step) {
      setStep(step);
    }
  }, []);

  const handleNumberTypeChange = e => {
    setNumberType(e.target.value);
  };

  const auth = useSelector(state => state.auth);

  const availNumbers = useSelector(state => state.availNumbers);
  const { success: successGet, error: errorFail } = availNumbers;

  const numberCreate = useSelector(state => state.createNumber);
  const {
    loading: numberCreateLoading,
    success: numberCreateSuccess,
    error: numberCreateError
  } = numberCreate;

  useEffect(() => {
    if (numberCreateSuccess) {
      setUpload({
        ...upload,
        dlcPhoneNumberSid: numberCreate?.newPhoneNumber?.sid,
        dlcPhoneNumber: numberCreate?.newPhoneNumber?.phoneNumber
      });
      dispatch(fetchUser());
      dispatch(fetchTeamDetails())
      dispatch({ type: NUMBER_CREATE_RESET });
      dispatch({ type: AVAIL_NUMBERS_RESET });
      setStep(2);
    }
  }, [numberCreateSuccess]);

  useEffect(() => {
    dispatch(findANumber(country.countryCode, areaCode, numberType));
  }, [successGet, areaCode, country, numberType]);

  const handleAreaCodeChange = async event => {
    const { value } = event;
    setAreaCode(value);
  };

  const handleCountryChange = async event => {
    const obj = numberCountries.find(
      country => country.countryCode === event.target.value
    );
    setAreaCode(''); // emptying the areaCode state so dispatch with areaCode doesnt mess up finding coutnry numbers
    setCountry(obj);
  };

  const toggle = () => {
    return (
      setShowNumberModal(!showNumberModal),
      dispatch({ type: NUMBER_CREATE_RESET })
    );
  };

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}></button>
  );

  const closeSwitchBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setNumberType('10dlc'), setShowSwitchFlowModal(false);
      }}
    ></button>
  );

  useEffect(() => {
    if (numberType !== '10dlc') {
      setShowSwitchFlowModal(true);
    }
  }, [numberType]);

  return (
    <Row className="d-flex justify-content-center">
      <Col xs={12} md={10} lg={8}>
        {numberCreateError && (
          <Alert color="warning">
            There was an error when attempting to add the number. Please try
            again or contact us at support@TrustPro.io
          </Alert>
        )}
        <h3 className="text-center">Choose your virtual number</h3>
        <p className="text-center ">
          Please choose the area code and the desired virtual number for your
          business.
        </p>
        <span className="fs-8">Select country</span>
        <div className="mb-2"></div>

        <Input
          type="select"
          id="country"
          name="country"
          className="mb-3"
          defaultValue={country[0]}
          value={country.countryCode}
          onChange={handleCountryChange}
        >
          {numberCountries.map(({ countryCode, name }) => (
            <Fragment key={countryCode}>
              <option key={countryCode} value={countryCode}>
                {name}
              </option>
            </Fragment>
          ))}
        </Input>

        <FormGroup>
          <Label for="numberType">Number type</Label>
          <div>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="numberType"
                  value="tollFree"
                  checked={numberType === 'tollFree'}
                  onChange={handleNumberTypeChange}
                />
                Toll-free number
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="radio"
                  name="numberType"
                  value="10dlc"
                  checked={numberType === '10dlc'}
                  onChange={handleNumberTypeChange}
                />
                Local 10DLC number
              </Label>
            </FormGroup>
          </div>
        </FormGroup>

        <hr />

        <span className="fs-8">Select area code</span>
        <div className="mb-2"></div>

        <Select
          name="areacodes"
          isSearchable
          options={country?.areaCodes}
          onChange={handleAreaCodeChange}
        />

        {availNumbers.loading ? (
          <div className="my-4">
            <Row>
              <Col className="d-flex justify-content-center">
                <Spinner />
              </Col>
            </Row>
          </div>
        ) : availNumbers.length > 0 ? (
          <div className="px-2">
            <hr />

            <span className="fs-8">
              Available Numbers
              <Select
                name="available-numbers"
                options={availNumbers}
                onChange={setSelectedNumber}
              />
            </span>

            <hr />
            <h5 className="d-flex justify-content-between">
              <span>Price per month</span>
              <span>{`$${(country?.type?.local?.credits * 0.04).toFixed(
                2
              )} per month`}</span>
            </h5>
            <p className="fs--1 text-600">
              We will deduct this amount from your account balance each month.
              If your account balance becomes too low to renew the dedicated
              number, we leave right to cancel it permanently. You can cancel
              this number at any time.
            </p>
            <div className="d-flex justify-content-between">
              <Button className="bg-transparent text-primary border-0 shadow-none">
                Back
              </Button>
              <Button
                disabled={!selectedNumber}
                onClick={() => {
                  if (auth?.user?.credits < country?.type?.local?.credits) {
                    setShowAddCreditModal(!showAddCreditModal);
                  } else {
                    return dispatch(
                      createNumber(selectedNumber.value, country, numberType)
                    );
                  }
                }}
                color="primary"
              >
                {numberCreateLoading ? (
                  <Spinner />
                ) : (
                  <div>{`Activate number ($${(
                    country?.type?.local?.credits * 0.04
                  ).toFixed(2)})`}</div>
                )}
              </Button>
            </div>
          </div>
        ) : !availNumbers?.length ? (
          <div>
            <hr />
            <p className="fs--1 text-600 text-center">
              We are currently out of numbers for that area code.
            </p>
          </div>
        ) : (
          <div></div>
        )}

        <Modal
          isOpen={showNumberModal}
          centered
          toggle={() => setShowNumberModal(!showNumberModal)}
        >
          <ModalHeader
            toggle={toggle}
            className="flex-between-center border-bottom-0"
            close={closeBtn}
          >
            New phone number added
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 fw-normal p-4">
                <Fragment>
                  <Card className="theme-wizard">
                    <CardBody className="fs--1 fw-normal px-md-6 pt-4 pb-3">
                      <Row>
                        <Col className="text-center">
                          <div className="wizard-lottie-wrapper">
                            <div className="wizard-lottie mx-auto">
                              <Lottie animationData={animationData} loop={30} />
                            </div>
                          </div>
                          <h4 className="mb-1">Success!</h4>
                          <p className="fs-0">Your new number awaits</p>
                          <Button
                            tag={Link}
                            color="primary"
                            to="/follow-up/numbers"
                            className="px-5 my-3 text-white"
                          >
                            Go to sender settings
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Fragment>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        {
          //----------------Add credit modal
        }
        <Modal
          isOpen={showAddCreditModal}
          centered
          toggle={() => setShowAddCreditModal(!showAddCreditModal)}
        >
          <ModalHeader className="flex-between-center border-bottom-0">
            Sending ability low
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 fw-normal p-4">
                <Fragment>
                  <Card className="theme-wizard">
                    <CardBody className="fs--1 fw-normal px-md-6 pt-4 pb-3">
                      <Row>
                        <Col className="text-center">
                          <div className="wizard-lottie-wrapper">
                            <div className="wizard-lottie mx-auto">
                              <Lottie
                                animationData={animationDanger}
                                loop={30}
                              />
                            </div>
                          </div>
                          <h4 className="mb-1">Sending ability low!</h4>
                          <p className="fs-0">You need to add some credits</p>
                          <Button
                            tag={Link}
                            color="primary"
                            to="/follow-up/monthly-plans"
                            className="px-5 my-3 text-white"
                          >
                            {'>'}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Fragment>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>

        {
          //----------------Switch flow modal
        }
        <Modal
          isOpen={showSwitchFlowModal}
          centered
          toggle={() => setShowSwitchFlowModal(!showSwitchFlowModal)}
        >
          <ModalHeader
            className="flex-between-center border-bottom-0"
            close={closeSwitchBtn}
          >
            Switch to the Toll free number flow
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 fw-normal p-4">
                <Row>
                  <Col className="text-center">
                    <p className="fs-0">
                      Are you sure that you want to move away from the 10DLC
                      registration flow, and instead get started with a Toll
                      free number? All your progress will be restarted.{' '}
                    </p>
                    <div className="d-flex justify-content-between">
                      <Button
                        onClick={() => {
                          return setNumberType('10dlc'), setShowSwitchFlowModal(false);
                        }}
                        className="bg-transparent text-primary border-0 my-3 text-primary shadow-none"
                      >
                        Stay on 10DLC
                      </Button>
                      <Button
                        tag={Link}
                        color="primary"
                        to="/follow-up/compliance/toll-free"
                        className="my-3 text-white"
                      >
                        Continue with Toll free
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>

        {availNumbers.error && (
          <div>
            <Card className="text-center">
              <CardBody className="p-5">
                <p className="lead mt-4 text-800 text-sans-serif fw-semibold">
                  It looks like {country?.name} numbers are a little backed up.
                </p>
                <hr />
                <p>
                  Some regions limit their available numbers, We will go through
                  the web and try to find some that are available. Try again
                  here soon or
                  <a href="mailto:info@exmaple.com" className="ml-1">
                    contact us
                  </a>
                  .
                </p>
                <Link className="btn btn-primary btn-sm mt-3" to="/">
                  <FontAwesomeIcon icon="home" className="mr-2" />
                  Take me home
                </Link>
              </CardBody>
            </Card>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default StepOneForm;
