import {
  LIST_CHAT_MESSAGES,
  ADD_CHAT_MESSAGE,
  ADD_INCOMING_CHAT_MESSAGE,
  DELETE_CHAT_MESSAGE,
  DELETE_CHAT_CALL,
  UPDATE_MESSAGE_STATUS
} from '../actions/types';

export default function (state = [], action) {
  const { payload } = action;
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case LIST_CHAT_MESSAGES:
      return state.filter(chat => {
        return chat.id === payload.data[0].id;
      })[0]
        ? state
        : [...state, payload.data[0]];

    case ADD_CHAT_MESSAGE:
      return state.map(chat =>
        chat.id === payload.newMessage.chatId
          ? {
            id: chat.id,
            content: [
              ...chat.content,
              {
                chatId: payload.newMessage.chatId,
                contactPhone: payload.newMessage.to,
                direction: 'outbound-api',
                from: payload.newMessage.from,
                message: payload.newMessage.body,
                messageSid: '123',
                media: payload.newMessage.mediaSid
                  ? payload.newMessage.mediaSid
                  : '',
                numMedia: payload.newMessage.mediaSid ? '1' : '0',
                status: 'sent',
                subresourceUri: '123',
                time: new Date(),
                to: '+' + payload.newMessage.to
              }
            ]
          }
          : chat
      );

    case ADD_INCOMING_CHAT_MESSAGE:
      return state.map(chat =>
        chat.id === payload.chatId &&
          !chat.content.filter(el => {
            return el.messageSid === payload.messageSid;
          })[0]
          ? {
            id: chat.id,
            content: [
              ...chat.content,
              {
                chatId: payload.chatId,
                contactPhone: payload.contactPhone,
                direction: payload.direction,
                from: payload.from,
                message: payload.message,
                messageSid: payload.messageSid,
                media: payload.media
                  ? { type: 'Buffer', data: payload.media }
                  : '',
                numMedia: payload.numMedia,
                status: payload.status,
                subresourceUri: payload.subresourceUri,
                time: new Date(),
                to: payload.to
              }
            ]
          }
          : chat
      );

    case DELETE_CHAT_MESSAGE:
      console.log('payload', payload);
      console.log('state', state);
      return state.map(chat =>
        chat.id === payload.chatId
          ? {
            id: chat.id,
            content: chat.content.filter(el => {
              return el.messageSid !== payload.messageSid;
            })
          }
          : chat
      );

    case DELETE_CHAT_CALL:
      console.log('payload', payload);
      console.log('state', state);
      return state.map(chat =>
        chat.id === payload.chatId
          ? {
            id: chat.id,
            content: chat.content.filter(el => {
              return el.callSid !== payload.callSid;
            })
          }
          : chat
      );

    case UPDATE_MESSAGE_STATUS:
      return state.map(chat => {
        if (
          chat.content.filter(el => {
            return el.to === payload.messageStatus.To;
          })[0]
        ) {
          return {
            id: chat.id,
            content: chat.content.map(content => {
              if (
                content.messageSid === '123' ||
                content.messageSid === payload.messageStatus.MessageSid
              ) {
                return {
                  ...content,
                  messageSid: payload.messageStatus.MessageSid,
                  status: payload.messageStatus.MessageStatus
                };
              } else {
                return content;
              }
            })
          };
        } else {
          return chat;
        }
      });

    default:
      return state;
  }
}
