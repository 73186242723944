import React, { useState, useEffect } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Overview from './Overview';
import classnames from 'classnames';
import DeliveredTable from './DeliveredTable';
import Sent from './Sent';
import Failed from './Failed';
import {
  listBulkSendMessages
} from '../../actions/bulkSendActions';
import { LIST_BULK_SEND_DELIVERED_MESSAGES_RESET, LIST_BULK_SEND_FAILED_MESSAGES_RESET } from 'actions/types';

const BulkTextDetailsTabs = ({ bulkDetails }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('1');

  const bulkTextDetails = useSelector(state => state.bulkTextDetails);
  const bulkTextDetailsDelivered = useSelector(
    state => state.bulkTextDetailsDelivered
  );
  const bulkTextDetailsSent = useSelector(state => state.bulkTextDetailsSent);
  const bulkTextDetailsFailed = useSelector(
    state => state.bulkTextDetailsFailed
  );

  console.log('bulkTextDetails', bulkTextDetails);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(listBulkSendMessages(bulkDetails));

    return () => {
      dispatch({ type: LIST_BULK_SEND_DELIVERED_MESSAGES_RESET });
      dispatch({ type: LIST_BULK_SEND_FAILED_MESSAGES_RESET });
    }
  }, []);

  return (
    <div>
      <Nav
        className="justify-content-start flex-column flex-md-row"
        tabs
      >
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            <span className="mx-1 fs-10">
              Overview
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <span className="mx-1 fs-10">
              Delivered
              <span className={`badge-subtle-success fs--2 rounded px-1 ms-2`}>
                {bulkTextDetailsDelivered?.totalMessages}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              toggle('3');
            }}
          >
            <span className="mx-1 fs-10">
              Sent
              <span className={`badge-subtle-primary fs--2 rounded px-1 ms-2`}>
                {bulkTextDetailsSent?.length}
              </span>
            </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => {
              toggle('4');
            }}
          >
            <span className="mx-1 fs-10">
              Failed
              <span className={`badge-subtle-danger fs--2 rounded px-1 ms-2`}>
                {bulkTextDetailsFailed?.totalMessages}
              </span>
            </span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <Overview bulkDetails={bulkDetails} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <DeliveredTable bulkDetails={bulkDetails} activeTab={activeTab} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <Sent bulkDetails={bulkDetails} activeTab={activeTab} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="4">
          <Row>
            <Col>
              <Failed bulkDetails={bulkDetails} activeTab={activeTab} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>

    </div>
  );
};

export default BulkTextDetailsTabs;
