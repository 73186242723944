import React, { useState, useEffect, Fragment } from 'react';
import {
    Card,
    CardBody,
    Input,
    Label,
    Col,
    Row,
    Button,
    FormGroup,
    FormFeedback,
    ListGroup,
    ListGroupItem,
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { useAcceptJs } from 'react-acceptjs';
import { getCustomerProfileFollowUp, getMerchantDetailsFollowUp } from 'actions/followUpBillingActions';
import usePlacesAutocomplete from 'use-places-autocomplete';
import useOnclickOutside from "react-cool-onclickoutside";
import { useAppContext } from 'providers/AppProvider';
import visaIcon from '../../../assets/img/icons/visa.png';
import mastercardIcon from '../../../assets/img/icons/master-card.png';
import amexIcon from '../../../assets/img/icons/amex.png';
import discoverIcon from '../../../assets/img/icons/discover.png';
import { faCreditCard as faSolidCreditCard } from '@fortawesome/free-solid-svg-icons';
import CardInput from 'components/accountCRM/pricing/CardInput';

const SelectPaymentMethod = ({
    currentPaymentMethod,
    setShowUpdatePaymentModal,
    setHasUpdatedPaymentMethod
}) => {

    const {
        config: { isDark }
    } = useAppContext();
    const dispatch = useDispatch();

    const merchantDetails = useSelector(state => state.merchantDetailsFollowUp);
    const customerProfile = useSelector(state => state.customerProfileFollowUp);
    const { loading: loadingCustomerProfile, paymentProfiles: paymentProfiles, success: successCustomerProfile, error: errorCustomerProfile } = customerProfile


    const [authData, setAuthData] = useState({});
    const [failedMessage, setFailedMessage] = useState('Maybe try a different card');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [nonceResponse, setNonceResponse] = useState();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [paymentProfileToDelete, setPaymentProfileToDelete] = useState(null);
    const [cardData, setCardData] = useState({
        cardNumber: '',
        month: '',
        year: '',
        cardCode: '',
    });
    const [cardToUse, setCardToUse] = useState('showCardForm');
    const [profileValues, setProfileValues] = useState({});
    const [failedCard, setFailedCard] = useState(false);
    const [errors, setErrors] = useState({});

    const environment = 'SANDBOX';

    const { dispatchData } = useAcceptJs({ environment, authData });

    const ProfileValueChange = e => {
        setProfileValues({ ...profileValues, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        dispatch(getMerchantDetailsFollowUp());
        dispatch(getCustomerProfileFollowUp());
    }, [dispatch]);

    useEffect(() => {
        if (merchantDetails?.data?.publicClientKey) {
            setAuthData({
                apiLoginID: process.env.REACT_APP_AUTHORIZE_API_LOGIN_ID_FOLLOW_UP, // 
                clientKey: merchantDetails.data.publicClientKey,
            });
        }
    }, [merchantDetails]);

    useEffect(() => {
        if (failedCard) {
            toast.error(failedMessage);
            setFailedCard(false);
            setFailedMessage('');
        }
    }, [failedCard, failedMessage]);


    const onSubmit = async e => {
        e.preventDefault();

        // Validate inputs
        const newErrors = {};
        if (!profileValues.street1 && cardToUse === "showCardForm") {
            newErrors.street1 = 'Street is required';
        }
        if (!profileValues.city && cardToUse === "showCardForm") {
            newErrors.city = 'City is required';
        }
        if (!profileValues.state && cardToUse === "showCardForm") {
            newErrors.state = 'State is required';
        }
        if (!profileValues.zip && cardToUse === "showCardForm") {
            newErrors.zip = 'ZIP Code is required';
        }
        if (!profileValues.country && cardToUse === "showCardForm") {
            newErrors.country = 'Country is required';
        }
        if (!cardData.cardNumber && cardToUse === "showCardForm") {
            newErrors.cardNumber = 'Card Number is required';
        }
        if ((!cardData.month || !cardData.year) && cardToUse === "showCardForm") {
            newErrors.expDate = 'Expiration Date is required';
        }
        if (!cardData.cardCode && cardToUse === "showCardForm") {
            newErrors.cardCode = 'CVC is required';
        }

        // If there are errors, set the errors state and return
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        // Clear errors
        setErrors({});

        setIsSubmitting(true); // Start form submission

        try {
            if (cardToUse === 'showCardForm') {
                const response = await dispatchData({ cardData, billingDetails: profileValues });
                setNonceResponse(response);
            } else {
                const defaultPaymentResponse = await axios.post(
                    `${process.env.REACT_APP_API_URL}/api/default-payment-fup`,
                    { newPayProfileId: cardToUse },
                    { withCredentials: true }
                );
                if (defaultPaymentResponse?.data?.response?.profile?.customerProfileId) {
                    toast.success('Payment method set as default successfully!');
                    setShowUpdatePaymentModal(false);
                    setHasUpdatedPaymentMethod(true);
                }
            }
        } catch (err) {
            console.error('Error in onSubmit:', err);
            setFailedMessage(
                err?.messages?.message[0]?.text || "Please check card details"
            );
            setFailedCard(true);
        } finally {
            setIsSubmitting(false); // End form submission
        }
    };




    useEffect(() => {
        if (nonceResponse) {
            const handlePaymentProfileCreation = async () => {
                try {
                    const response = await axios.post(
                        `${process.env.REACT_APP_API_URL}/create-cust-pay-profile-fup`,
                        { nonceResponse, billingDetails: profileValues },
                        { withCredentials: true }
                    );

                    if (
                        response.data.response.messages.message[0].code === 'I00001' &&
                        response.data.response.customerPaymentProfileId
                    ) {
                        const customerPaymentProfileId = response.data.response.customerPaymentProfileId;
                        await new Promise(resolve => setTimeout(resolve, 10000));
                        try {
                            const defaultPaymentResponse = await axios.post(
                                `${process.env.REACT_APP_API_URL}/api/default-payment-fup`,
                                { newPayProfileId: customerPaymentProfileId },
                                { withCredentials: true }
                            );
                            if (defaultPaymentResponse?.data?.response?.profile?.customerProfileId) {
                                setShowUpdatePaymentModal(false)
                                setHasUpdatedPaymentMethod(true)

                            }
                        } catch (err) {
                            setFailedCard(true);
                        }
                    } else {
                        setFailedCard(true);
                        setShowUpdatePaymentModal(false);
                    }
                } catch (error) {
                    console.error('Error creating payment profile:', error);
                    setFailedCard(true);
                    setShowUpdatePaymentModal(false);
                }
            };

            handlePaymentProfileCreation();
        }
    }, [nonceResponse, setShowUpdatePaymentModal]);



    const usStates = [
        'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA',
        'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD',
        'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ',
        'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC',
        'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'
    ];
    const canadaProvincesAndTerritories = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK', 'NT', 'NU', 'YT'];
    const statesAndProvinces = [...usStates, ...canadaProvincesAndTerritories];

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });
    const ref = useOnclickOutside(() => {
        // When user clicks outside of the component, we can dismiss
        // the searched suggestions by calling this method
        clearSuggestions();
    });



    const handleSelect = (suggestion) => async () => {
        clearSuggestions();

        // Extract main_text and secondary_text
        const main_text = suggestion.structured_formatting.main_text; // e.g., '123 Main St'
        const secondary_text = suggestion.structured_formatting.secondary_text; // e.g., 'Springfield, IL, USA'

        // Split secondary_text by commas
        const secondaryParts = secondary_text.split(',').map(part => part.trim());

        let city = '';
        let state = '';
        let country = '';

        if (secondaryParts.length >= 1) {
            city = secondaryParts[0]; // e.g., 'Springfield'
        }
        if (secondaryParts.length >= 2) {
            state = secondaryParts[1]; // e.g., 'IL'
        }
        if (secondaryParts.length >= 3) {
            country = secondaryParts[2]; // e.g., 'USA'
        }

        setProfileValues((prevValues) => ({
            ...prevValues,
            street1: main_text,
            city: city,
            state: state,
            country: country,
        }));

        // Set the value of the street1 input to main_text
        setValue(main_text, false);

        // Call the server-side endpoint to get the postal code using the place_id
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/getPostalCode`, {
                withCredentials: true,
                params: { placeId: suggestion.place_id }
            });

            const zip = response.data.postalCode;

            setProfileValues((prevValues) => ({
                ...prevValues,
                zip: zip,
            }));

        } catch (error) {
            console.error('Error fetching postal code:', error);
            toast.error('Failed to fetch postal code information');
        }
    };


    const renderSuggestions = () => (
        <div className={`autocomplete-dropdown ${isDark ? 'dark-mode' : ''}`}>
            <ListGroup variant="flush" className="suggestions-list" role="listbox" aria-labelledby="address-input">
                {data.map((suggestion) => {
                    const {
                        place_id,
                        structured_formatting: { main_text, secondary_text },
                    } = suggestion;

                    return (
                        <ListGroupItem
                            action
                            className="suggestion-item"
                            key={place_id}
                            onClick={handleSelect(suggestion)}
                            role="option"
                            aria-selected="false"
                        >
                            <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3 suggestion-icon" />
                            <div className="suggestion-text">
                                <span className="main-text">{main_text}</span>
                                <br />
                                <small className="secondary-text">{secondary_text}</small>
                            </div>
                        </ListGroupItem>
                    );
                })}
            </ListGroup>
            <div className="powered-by-google">
                <small>Powered by Google</small>
            </div>
        </div>
    );


    // Automatically select the current payment method if available
    useEffect(() => {
        if (currentPaymentMethod && paymentProfiles?.length) {
            const matchingProfile = paymentProfiles.find(
                profile => profile.customerPaymentProfileId === currentPaymentMethod.customerPaymentProfileId
            );
            if (matchingProfile) {
                setCardToUse(matchingProfile.customerPaymentProfileId);
            }
        }
    }, [currentPaymentMethod, paymentProfiles]);


    // Handle the deletion of a payment method
    const handleDeletePaymentMethod = async () => {
        setIsDeleting(true); // Start deletion process
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/api/delete-payment-method-fup`,
                { paymentProfileToDelete },
                { withCredentials: true }
            );
            if (response.data.deleteId) {
                // Simulate a delay if necessary
                await new Promise(resolve => setTimeout(resolve, 2000)); // Reduced delay for better UX
                toast.success('Payment method deleted successfully!');
                setHasUpdatedPaymentMethod(true);
                setShowDeleteModal(false);
                dispatch(getCustomerProfileFollowUp());
            } else {
                throw new Error('Deletion failed.');
            }
        } catch (error) {
            console.error('Error deleting payment method:', error.response.data.message);
            toast.error(error?.response?.data?.message || 'Failed to delete payment method.');
        } finally {
            setIsDeleting(false); // End deletion process
        }
    };

    const handleOpenDeleteModal = (profileId) => {
        if (profileId === currentPaymentMethod?.customerPaymentProfileId) {
            toast.warning('You cannot delete the current payment method.');
            return;
        }
        setPaymentProfileToDelete(profileId);
        setShowDeleteModal(true);
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255, 255, 255, 0.8)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        borderRadius: '0.5rem',
    };

    const spinnerStyle = {
        width: '3rem',
        height: '3rem',
        color: '#007bff',
    };



    return (
        <Fragment>
            {/* Loading Overlay */}
            <Card className="h-100">
                {(isSubmitting || loadingCustomerProfile) && (
                    <div style={overlayStyle}>
                        <Spinner animation="border" role="status" style={spinnerStyle}>
                            <span className="visually-hidden">
                                {isSubmitting ? "Submitting..." : "Loading..."}
                            </span>
                        </Spinner>
                    </div>
                )}
                <CardBody>
                    {paymentProfiles?.length >= 1 && (
                        <div>
                            <FalconCardHeader title="Choose Payment Method" />
                            {paymentProfiles.map(el => {
                                const cardType = el.payment?.creditCard?.cardType || '';
                                const cardNumber = el.payment?.creditCard?.cardNumber || '';
                                const last4 = cardNumber.slice(-4);
                                const cardTypeKey = cardType.toLowerCase();

                                let cardIconElement;
                                if (cardTypeKey.includes('visa')) {
                                    cardIconElement = <img src={visaIcon} alt="Visa" className="payform-card-icon me-2" />;
                                } else if (cardTypeKey.includes('mastercard')) {
                                    cardIconElement = <img src={mastercardIcon} alt="MasterCard" className="payform-card-icon me-2" />;
                                } else if (cardTypeKey.includes('american express') || cardTypeKey.includes('amex')) {
                                    cardIconElement = <img src={amexIcon} alt="American Express" className="payform-card-icon me-2" />;
                                } else if (cardTypeKey.includes('discover')) {
                                    cardIconElement = <img src={discoverIcon} alt="Discover" className="payform-card-icon me-2" />;
                                } else {
                                    cardIconElement = <FontAwesomeIcon icon={faSolidCreditCard} size="2x" className="me-2" />;
                                }

                                return (
                                    <Col xs={12} key={el.customerPaymentProfileId} className="d-flex align-items-center">
                                        <Card className="border my-2 w-100 pt-2 ps-2 d-flex flex-row justify-content-between">
                                            <FormGroup check>
                                                <Input
                                                    type="radio"
                                                    id={el.customerPaymentProfileId}
                                                    checked={cardToUse === el.customerPaymentProfileId}
                                                    onChange={() => setCardToUse(el.customerPaymentProfileId)}
                                                    value={el.customerPaymentProfileId}
                                                    name="paymentMethod"
                                                />
                                                <Label
                                                    for={el.customerPaymentProfileId}
                                                    check
                                                    className="d-flex align-items-center"
                                                >
                                                    {cardIconElement}
                                                    <span>
                                                        <strong>{cardType}</strong> •••• {last4}
                                                    </span>
                                                </Label>
                                            </FormGroup>
                                            <Card>
                                                <div
                                                    className={el.customerPaymentProfileId === currentPaymentMethod?.customerPaymentProfileId ? "d-none" : "danger p-3 bg-transparent cursor-pointer"}
                                                    size="sm"
                                                    onClick={(e) => { return e.stopPropagation(), handleOpenDeleteModal(el.customerPaymentProfileId) }}
                                                >
                                                    <FontAwesomeIcon size={"1x"} className="text-danger" icon={faTrashAlt} />
                                                </div>

                                            </Card>
                                        </Card>

                                    </Col>
                                );
                            })}


                            <Row className="mt-4 ms-1 mb-3">
                                <Col>
                                    <FormGroup check>
                                        <Input
                                            type="radio"
                                            id="showCardForm"
                                            checked={cardToUse === 'showCardForm'}
                                            onChange={() => setCardToUse('showCardForm')}
                                            value="showCardForm"
                                            name="paymentMethod"
                                        />
                                        <Label for="showCardForm" check>
                                            <strong>New Card</strong>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    )}
                    {cardToUse === 'showCardForm' && (
                        <Row className="gx-0 mb-2">
                            <Col sm={12}>
                                {/* Name on Card */}
                                <FormGroup floating>
                                    <Input
                                        type="text"
                                        id="nameOnCard"
                                        placeholder="Name on Card"
                                        value={profileValues.nameOnCard || ''}
                                        name="nameOnCard"
                                        onChange={ProfileValueChange}
                                        invalid={!!errors.nameOnCard}
                                    />
                                    <Label for="nameOnCard">Name on Card</Label>
                                    {errors.nameOnCard && (
                                        <FormFeedback>{errors.nameOnCard}</FormFeedback>
                                    )}
                                </FormGroup>

                                {/* Card Input Component */}
                                <CardInput
                                    cardData={cardData}
                                    setCardData={setCardData}
                                    errors={errors}
                                />

                                {/* Billing Details */}
                                <Row className="mt-3">
                                    <Col xs={12} lg={8}>
                                        <div ref={ref} style={{ position: 'relative' }}>
                                            <FormGroup floating>
                                                <Input
                                                    type="text"
                                                    id="billingStreet1"
                                                    placeholder="Street 1"
                                                    value={value}
                                                    name="street1"
                                                    onChange={(e) => {
                                                        setValue(e.target.value);
                                                        ProfileValueChange(e);
                                                    }}
                                                    invalid={!!errors.street1}
                                                />
                                                <Label for="billingStreet1">Street 1</Label>
                                                {errors.street1 && (
                                                    <FormFeedback>{errors.street1}</FormFeedback>
                                                )}
                                            </FormGroup>
                                            {status === 'OK' && renderSuggestions()}
                                        </div>
                                    </Col>
                                    <Col xs={12} lg={4}>

                                        <FormGroup floating>
                                            <Input
                                                type="text"
                                                id="billingStreet2"
                                                placeholder="Street 2"
                                                value={profileValues.street2 || ''}
                                                name="street2"
                                                onChange={ProfileValueChange}
                                                invalid={!!errors.street2}
                                            />
                                            <Label for="billingStreet2">Unit #</Label>
                                            {errors.street2 && (
                                                <FormFeedback>{errors.street2}</FormFeedback>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup floating>
                                            <Input
                                                type="text"
                                                id="billingCity"
                                                placeholder="City"
                                                value={profileValues.city || ''}
                                                name="city"
                                                onChange={ProfileValueChange}
                                                invalid={!!errors.city}
                                            />
                                            <Label for="billingCity">City</Label>
                                            {errors.city && (
                                                <FormFeedback>{errors.city}</FormFeedback>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup floating>
                                            <Input
                                                type="select"
                                                id="billingState"
                                                placeholder="State"
                                                value={profileValues.state || ''}
                                                name="state"
                                                onChange={ProfileValueChange}
                                                invalid={!!errors.state}
                                            >
                                                <option value="" disabled>Select State</option>
                                                {statesAndProvinces.map(stateCode => (
                                                    <option key={stateCode} value={stateCode}>{stateCode}</option>
                                                ))}
                                            </Input>
                                            <Label for="billingState">State</Label>
                                            {errors.state && (
                                                <FormFeedback>{errors.state}</FormFeedback>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup floating>
                                            <Input
                                                type="text"
                                                id="billingZip"
                                                placeholder="ZIP Code"
                                                value={profileValues.zip || ''}
                                                name="zip"
                                                onChange={ProfileValueChange}
                                                invalid={!!errors.zip}
                                            />
                                            <Label for="billingZip">ZIP Code</Label>
                                            {errors.zip && (
                                                <FormFeedback>{errors.zip}</FormFeedback>
                                            )}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup floating>
                                    <Input
                                        type="text"
                                        id="billingCountry"
                                        placeholder="Country"
                                        value={profileValues.country || ''}
                                        name="country"
                                        onChange={ProfileValueChange}
                                        invalid={!!errors.country}
                                    />
                                    <Label for="billingCountry">Country</Label>
                                    {errors.country && (
                                        <FormFeedback>{errors.country}</FormFeedback>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    <div className="border-dashed border-bottom mb-2"></div>
                    <Row className="mb-4">
                        <Col xs={12} >
                            <Button
                                onClick={(e) => { onSubmit(e) }}
                                block
                                color="primary"
                                className="mt-3 px-5"
                                disabled={
                                    isSubmitting ||
                                    (cardToUse === 'showCardForm' &&
                                        (!profileValues.nameOnCard ||
                                            !profileValues.street1 ||
                                            !profileValues.city ||
                                            !profileValues.state ||
                                            !profileValues.zip ||
                                            !profileValues.country ||
                                            !cardData.cardNumber ||
                                            !cardData.cardCode ||
                                            !cardData.month ||
                                            !cardData.year))
                                }
                            >
                                {isSubmitting ? (
                                    <>
                                        <Spinner size="sm" className="me-2" /> ...saving
                                    </>
                                ) : (
                                    'Set as default'
                                )}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {/* Delete Payment Method Modal */}
            <Modal
                zIndex={9999}
                isOpen={showDeleteModal}
                toggle={!isDeleting ? () => setShowDeleteModal(false) : undefined} // Disable toggle when deleting
                centered
                backdrop={isDeleting ? 'static' : true} // Prevent backdrop click during deletion
                keyboard={!isDeleting} // Disable ESC key to close during deletion
            >
                {(isDeleting) && (
                    <div style={overlayStyle}>
                        <Spinner animation="border" role="status" style={spinnerStyle}>
                            <span className="visually-hidden">
                                {isSubmitting ? "Submitting..." : "Loading..."}
                            </span>
                        </Spinner>
                    </div>
                )}
                <ModalHeader toggle={!isDeleting ? () => setShowDeleteModal(false) : undefined}>
                    Delete Payment Method
                </ModalHeader>
                <ModalBody>
                    Are you sure you want to delete this payment method?
                </ModalBody>
                <ModalFooter>
                    <Button
                        className="btn-tertiary border"
                        onClick={() => setShowDeleteModal(false)}
                        disabled={isDeleting} // Disable cancel button during deletion
                    >
                        Cancel
                    </Button>
                    <Button
                        className="btn-danger border"
                        onClick={handleDeletePaymentMethod}
                        disabled={isDeleting} // Disable delete button during deletion
                    >
                        {isDeleting ? (
                            <>
                                <Spinner size="sm" className="me-2" /> Deleting...
                            </>
                        ) : (
                            'Delete'
                        )}
                    </Button>
                </ModalFooter>
            </Modal>



        </Fragment>
    );
};

export default SelectPaymentMethod;
