import React, { Fragment } from 'react';
import { Card } from 'reactstrap';
import MessageCreateForm from '../compose/MessageCreateForm';

const ComposeMsg = () => {
  return (
    <Fragment>
      <Card>
        <MessageCreateForm />
      </Card>
    </Fragment>
  );
};

export default ComposeMsg;
