import React, { useEffect, Fragment, useContext, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import ButtonIcon from '../common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Flex from '../common/Flex';
import Divider from '../common/Divider';
import Moment from 'react-moment';
import Select from 'react-select';
import AdvanceContactsTable from '../common/contactsTable/AdvanceContactsTable';
import SingleOrListForm from '../contacts/SingleOrListForm';
import AdvanceContactsTableFooter from '../common/contactsTable/AdvanceContactsTableFooter';
import AdvanceContactsTableSearchBox from '../common/contactsTable/AdvanceContactsTableSearchBox';
import AdvanceContactsTableWrapper from '../common/contactsTable/AdvanceContactsTableWrapper';
import ContactEditModal from '../contacts/ContactEditModal';
import AppContext from '../../context/Context';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ActionPopover from 'components/common/ActionPopover';
import { listContacts, listContactsTotal, deleteMultipleContacts, updateContact, createBlockedContacts, unsubscribeContacts } from '../../actions/index';
import { CONTACT_CREATE_RESET, REMOVE_SELECTED_CONTACT_ROWS, RESET_SELECTED_CONTACT_ROWS, SET_CONTACTS } from '../../actions/types';

const AddContactToTable = ({ contactModal, setContactModal, fromCompose }) => {


  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const dispatch = useDispatch();
  const windowSize = useWindowDimensions();

  const { isDark } = useContext(AppContext)
  const contactsFollowUp = useSelector((state) => state.contactsFollowUp)
  const { contacts: contacts, loading: loadingContacts, totalItems: totalItems } = contactsFollowUp
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);
  const conversations = useSelector((state) => state.conversations)
  const pageSize = useSelector(state => state.paginationRedux.pageSize);
  const reduxSelectedRows = useSelector((state) => state.contactsTableRow)
  const createBulk = useSelector((state) => state.createBulk);

  const [activePopoverId, setActivePopoverId] = useState(null);


  const contactDelete = useSelector((state) => state.deleteContact)
  const { success: successDelete } = contactDelete

  const contactCreate = useSelector((state) => state.createContact)
  const { success: successCreate } = contactCreate

  const contactUpdate = useSelector((state) => state.updateContact)
  const { success: successUpdate } = contactUpdate

  const groupDelete = useSelector((state) => state.groupDelete)
  const { success: successGroupDelete } = groupDelete

  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success: successBlockedDelete } = blockedContactDelete

  const [showSingleOrListModal, setShowSingleOrListModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [tempContact, setTempContact] = useState()
  const [changeText, setChangeText] = useState();
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false)
  const [contact, setContact] = useState({});
  const [showContactUpdateModal, setShowContactUpdateModal] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [blockContact, setBlockContact] = useState()



  //compare rowIds with FlatRows to solve multi search choose contact issue
  const selectionCallback = useCallback((selectedFlatRows) => {
    return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setRowDelete([])
  },
    [setIsSelected]);

  const openGroups = []
  groups.map((g) => { if (!tempContact?.group.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })

  const contactFields = fields.map((field) => {
    return { title: field.title, body: tempContact?.fields[field.title] }
  })



  const customStyles = {
    control: (base, state) => ({
      ...base,
      minWidth: 150,  // set your desired minimum width
      backgroundColor: isDark ? 'dark' : 'light',
      borderColor: isDark ? 'dark' : 'light',
    }),
    input: (base) => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: (base) => ({
      ...base,
      color: '#2c7be5',
      fontWeight: "bold",
    }),
    option: (base, state) => ({
      ...base,
      color: '#2c7be5', // 
      fontWeight: "bold",
      backgroundColor: '#0b1727',
      pointerEvents: 'none', // This line disables the interactions
    }),
    singleValue: (base) => ({ // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: "bold",
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0,
      background: isDark ? 'dark' : 'light',

      zIndex: 1000  // Update this value to be higher than the z-index of your table footer
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option

      background: isDark ? 'dark' : 'light',
    })

  };

  const openContactUpdateModal = (rowData) => {
    setContact(rowData)
    setShowContactUpdateModal(!showContactUpdateModal);
  };

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, { avatar }) => {
    const { firstName } = rowData.row.original
    return (
      <Link to="#!" >
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9">{firstName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { lastName } = rowData.row.original
    return (

      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-9">{lastName}</h5>
      </Media>

    );
  };

  const phoneFormatter = (rowData) => {
    const { phone_number } = rowData.row.original
    return (

      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-9">{phone_number}</h5>
      </Media>

    );
  };

  const groupFormatter = (rowData) => {
    const { group } = rowData.row.original

    // Map the groups to the format react-select expects
    const groupOptions = group.map(({ _id, title }) => ({
      value: _id,
      label: title,
    }));

    const placeholder = groupOptions.length > 0 ? groupOptions[0].label : '';

    return (
      <Select
        isSearchable={false}
        options={groupOptions}
        placeholder={placeholder}
        styles={customStyles}
      />
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  // Inside ContactsTableCustom.js
  const actionFormatter = (rowData) => {
    const { firstName, lastName, phone_number, _id } = rowData.row.original;
    const composeContact = { firstName, lastName, phoneNumber: phone_number, _id };

    const items = [

      {
        label: 'Edit',
        icon: 'edit',
        action: () => {
          openContactUpdateModal(rowData.row.original);
        },
      },
      {
        label: 'Block',
        icon: 'ban',
        action: () => {
          setShowBlockModal(true);
          setBlockContact({ _id, phoneNumber: phone_number });
        },
      },
      {
        label: 'Unsubscribe',
        icon: 'minus',
        action: () => {
          setShowUnsubscribeModal(true);
          setBlockContact(composeContact);
        },
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([_id]);
          setShowDeleteModal(true);
        },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} activePopoverId={activePopoverId}
          setActivePopoverId={setActivePopoverId}/>
      </div>
    );
  };


  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      accessor: 'name',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
      accessor: (data) => {
        const groups = []
        data.group.map((g) => { groups.push(g.title) })
        return groups
      },
      Header: 'Group',
      width: 200, // replace with your desired width
      minWidth: 100,
      Cell: groupFormatter
    },
    {
      accessor: 'phone_type',
      Header: 'Type',
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',


    }
  ];


  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------


  const toggle = () => { setShowSingleOrListModal(!showSingleOrListModal) }

  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}>
    </button>
  );

  const addContactsToDiv = async () => {
    // Filter out any reduxSelectedRows that are already in toContacts
    const newContacts = reduxSelectedRows.filter((contact) =>
      !createBulk.messageDetails.contacts.some((id) => id === contact.original._id)
    );
    dispatch({ type: SET_CONTACTS, payload: [...newContacts] })
  }


  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------
  useEffect(() => {
    if (!successCreate) return;
    toast(
      <Fragment>
        <strong>Contact Created</strong>
      </Fragment>
    );
    dispatch({ type: CONTACT_CREATE_RESET })
  }, [successCreate]);


  useEffect(() => {

  }, [successDelete, successCreate, successUpdate, successGroupDelete, successBlockedCreate, successBlockedDelete])

  useEffect(() => {
    dispatch(listContactsTotal())
  }, [])



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listContacts({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])


  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };


  return (
    <Fragment>
      <Card >
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2" >
              <ButtonIcon
                onClick={() => { return addContactsToDiv(), setContactModal(!contactModal) }}
                className="btn-tertiary border-dark mx-1"
                icon="plus"
                transform="shrink-3 down-1"
                size="sm">
                {`Add ${reduxSelectedRows.length} Contacts`}
              </ButtonIcon>
              <ButtonIcon
                onClick={(() => setShowSingleOrListModal(true))}
                icon="plus"
                className="btn-tertiary border-dark mx-1"
                transform="shrink-3 down-1"
                size="sm">
                New Contact
              </ButtonIcon>
              <ButtonIcon
                icon="trash"
                onClick={() => { return setShowDeleteModal(!showDeleteModal) }}
                disabled={!isSelected.length}
                transform="shrink-3 down-1"
                className="btn-tertiary border-dark mx-1 text-danger"
                size="sm" >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>

        <CardBody className="p-0">
          {loadingContacts && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )
          }
          <AdvanceContactsTableWrapper
            columns={columns}
            data={contacts}
            fetchData={fetchData}
            pageCount={Math.ceil(totalItems / pageSize)}
            total={totalItems}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}

          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceContactsTableSearchBox table />
              </Col>
            </Row>

            <AdvanceContactsTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold overflow-hidden'
              }}
              isSelected={reduxSelectedRows}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceContactsTableFooter
                rowCount={totalItems}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceContactsTableWrapper>

        </CardBody>
      </Card>



      <Modal isOpen={showSingleOrListModal} centered toggle={() => setShowSingleOrListModal(!showSingleOrListModal)}>
        <ModalHeader toggle={toggle} className="d-flex flex-between-center border-bottom-0" close={closeBtn}>
          How would you like to Add?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <SingleOrListForm showSingleOrListModal={showSingleOrListModal} setShowSingleOrListModal={setShowSingleOrListModal} fromCompose={fromCompose} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>



      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(deleteMultipleContacts(rowDelete, conversations)),
                    dispatch({ type: REMOVE_SELECTED_CONTACT_ROWS, payload: rowDelete }),
                    setShowDeleteModal(!showDeleteModal)
                } else {
                  const deleteIds = reduxSelectedRows.map((el) => { return el.original._id })
                  return dispatch(deleteMultipleContacts(deleteIds, conversations)),
                    dispatch({ type: RESET_SELECTED_CONTACT_ROWS, payload: deleteIds }),
                    setShowDeleteModal(!showDeleteModal)
                }
              }}
                block
                className="text-danger btn-tertiary border-dark"
              >Delete</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="text-primary btn-tertiary border-dark my-2"  >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal size="lg" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
        <ModalHeader toggle={toggleSave} className="border-bottom-0">
          <ButtonIcon
            onClick={() => { return dispatch(updateContact(contact, tempContact._id)), setShowContactUpdateModal(!showContactUpdateModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <ContactEditModal
                contact={contact}
                ContactId={tempContact?._id}
                changeText={changeText}
                setContact={setContact}
                openGroups={openGroups}
                successCreate={successCreate}
                contactGroups={tempContact?.group}
                contactFields={contactFields}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Divider></Divider>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal) }} block className="btn-tertiary border-dark text-danger my-2">Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="btn-tertiary border-dark text-primary my-2" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Blocked Modal-----------------------------------------
      }
      <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">You will not recieve phone calls or messages from people on the block list.</Label>
              <Divider></Divider>
              <Button onClick={() => {
                return dispatch(createBlockedContacts({ contacts: [{ item: { _id: blockContact._id, phone_number: blockContact.phoneNumber } }] })), // data is in this format just to match for action, could just create a "singleBlockContact but didnt feel like it"
                  setShowBlockModal(!showBlockModal)
              }}
                block
                className="btn-tertiary border-dark text-danger my-2">Block Contact</Button>
              <Button onClick={() => { setShowBlockModal(!showBlockModal) }} block className="btn-tertiary border-dark text-primary my-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // Unsubscribe Modal---------------------------------------
      }
      <Modal isOpen={showUnsubscribeModal} centered toggle={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                return dispatch(unsubscribeContacts(blockContact)),
                  setShowUnsubscribeModal(false)
              }}
                block
                className="btn-tertiary border-dark text-danger my-2"
                color="light">Unsubscribe contact</Button>
              <Button onClick={() => { setShowUnsubscribeModal(!showUnsubscribeModal) }} block className="btn-tertiary border-dark text-primary my-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </Fragment>

  );
};


export default AddContactToTable;