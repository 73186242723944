import { v4 as uuidv4 } from 'uuid';
import momentt from 'moment-timezone';
import {
  RESET_CREATE_BULK,
  UPDATE_WITH_EVENT,
  UPDATE_DAY_COUNT,
  UPDATE_BULK_TITLE,
  UPDATE_DAY_TIMES,
  UPDATE_WEEK_COUNT,
  UPDATE_HOUR_CYCLES,
  UPDATE_CAMPAIGN_TYPE,
  UPDATE_MONTH_ON_DAY,
  UPDATE_MONTH_TIMES,
  UPDATE_SESSIONS,
  UPDATE_MONTH_ON_THE,
  UPDATE_END_TYPE,
  CREATE_BULK_INITIAL_STATE,
  SET_CONTACTS,
  SET_GROUPS,
  SET_BULK_NUMBER,
  REMOVE_GROUPS,
  REMOVE_CONTACTS,
  ADD_MESSAGE_CONTENTS,
  UPDATE_BULK_CLASSNAME,
  UPDATE_END_DATE,
  UPDATE_WEEK_DAYS,
  UPDATE_WEEK_TIMES,
  UPDATE_START_DATE,
  UPDATE_CAMPAIGN_REPEAT,
  UPDATE_RECIPIENT_COUNT
} from '../actions/types';

export default function (
  state = {
    // resetting this formObj back to original state, was showin undefined If i just empty the state
    className: 'bg-soft-success',
    repeatCampaign: 'noRepeat',
    repeatCampaignType: 'onDay',
    repeatCampaignEnd: 'Never',
    repeatCampaignWeekdays: {
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false
    },
    repeatCampaignWeekdayTimes: [],
    repeatCampaignWeekCount: 1,
    repeatCampaignMonthOnDay: '1',
    repeatCampaignMonthOnThe: { first_part: 'First', day: 'Sunday' },
    messageDetails: { contacts: [], groups: [] },
    recipientCount: 0
  },
  action
) {
  switch (action.type) {
    case CREATE_BULK_INITIAL_STATE:
      // ONLY USED TO CREATE CUSTOM DATE TIMES WITH USERS TIMEZONE, DOING THIS IN /dashboard/Dashboard useEffect
      return {
        ...state,
        startDate: momentt
          .tz(
            new Date().toISOString().replace('Z', ''),
            action.payload.timezone
          )
          .format(),
        repeatCampaignHourCycles: [
          {
            trigger_time: momentt
              .tz(
                new Date().toISOString().replace('Z', ''),
                action.payload.timezone
              )
              .format(),
            dpTime: new Date().toISOString(),
            uid: uuidv4()
          }
        ],
        repeatCampaignDayTimes: [
          {
            trigger_time: momentt
              .tz(
                new Date().toISOString().replace('Z', ''),
                action.payload.timezone
              )
              .format(),
            dpTime: new Date().toISOString(),
            uid: uuidv4()
          }
        ],
        repeatCampaignWeekdayTimes: {
          sunday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          monday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          tuesday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          wednesday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          thursday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          friday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ],
          saturday: [
            {
              trigger_time: momentt
                .tz(
                  new Date().toISOString().replace('Z', ''),
                  action.payload.timezone
                )
                .format(),
              dpTime: new Date().toISOString(),
              uid: uuidv4()
            }
          ]
        },
        repeatCampaignMonthTimes: [
          {
            trigger_time: momentt
              .tz(
                new Date().toISOString().replace('Z', ''),
                action.payload.timezone
              )
              .format(),
            dpTime: new Date().toISOString(),
            uid: uuidv4()
          }
        ],
        timezone: action.payload.timezone
      };

    case SET_CONTACTS:
      // Filter out contacts in payload that already exist in state.
      const uniqueContacts = action.payload.filter(
        payloadContact =>
          !state.messageDetails.contacts.some(
            stateContact => stateContact.id === payloadContact.id
          )
      );

      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          contacts: [...state.messageDetails.contacts, ...uniqueContacts]
        }
      };

    case REMOVE_CONTACTS:
      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          contacts: action.payload
        }
      };

    case SET_GROUPS:
      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          groups: [...state.messageDetails.groups, ...action.payload]
        }
      };

    case REMOVE_GROUPS:
      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          groups: action.payload
        }
      };

    case SET_BULK_NUMBER:
      return {
        ...state,
        messageDetails: {
          ...state.messageDetails,
          bulkNumber: action.payload
        }
      };

    case UPDATE_BULK_TITLE:
      return {
        ...state,
        title: action.payload
      };

    case UPDATE_START_DATE:
      return {
        ...state,
        start: action.payload.start,
        startDate: action.payload.startDate
      };

    case UPDATE_CAMPAIGN_REPEAT:
      return {
        ...state,
        repeatCampaign: action.payload
      };

    case UPDATE_END_TYPE:
      return {
        ...state,
        repeatCampaignEnd: action.payload
      };

    case UPDATE_END_DATE:
      return {
        ...state,
        endDate: action.payload
      };

    case ADD_MESSAGE_CONTENTS:
      return {
        ...state,
        messageContents: action.payload
      };

    case UPDATE_SESSIONS:
      return {
        ...state,
        repeatCampaignEndSessions: action.payload
      };

    case UPDATE_CAMPAIGN_TYPE:
      return {
        ...state,
        repeatCampaignType: action.payload
      };
    case UPDATE_MONTH_ON_DAY:
      return {
        ...state,
        repeatCampaignMonthOnDay: action.payload
      };
    case UPDATE_MONTH_ON_THE:
      return {
        ...state,
        repeatCampaignMonthOnThe: action.payload
      };

    case UPDATE_BULK_CLASSNAME:
      return {
        ...state,
        className: action.payload
      };

    case UPDATE_MONTH_TIMES:
      return {
        ...state,
        repeatCampaignMonthTimes: action.payload
      };
    case UPDATE_WEEK_COUNT:
      return {
        ...state,
        repeatCampaignWeekCount: action.payload
      };

    case UPDATE_WEEK_DAYS:
      return {
        ...state,
        repeatCampaignWeekdays: action.payload
      };

    case UPDATE_WEEK_TIMES:
      return {
        ...state,
        repeatCampaignWeekdayTimes: action.payload
      };
    case UPDATE_DAY_COUNT:
      return {
        ...state,
        repeatCampaignDayCount: action.payload
      };

    case UPDATE_DAY_TIMES:
      return {
        ...state,
        repeatCampaignDayTimes: action.payload
      };

    case UPDATE_HOUR_CYCLES:
      return {
        ...state,
        repeatCampaignHourCycles: action.payload
      };

    case UPDATE_WITH_EVENT:
      return action.payload;

    case UPDATE_RECIPIENT_COUNT:
      console.log('action.payload recip', action.payload);
      return {
        ...state,
        recipientCount: action.payload
      };

    case RESET_CREATE_BULK:
      return {
        className: 'bg-soft-success',
        repeatCampaign: 'noRepeat',
        repeatCampaignType: 'onDay',
        repeatCampaignEnd: 'Never',
        repeatCampaignWeekdays: {
          sunday: false,
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false
        },
        repeatCampaignWeekCount: 1,
        repeatCampaignMonthOnDay: '1',
        repeatCampaignMonthOnThe: { first_part: 'First', day: 'Sunday' },
        messageDetails: { contacts: [], groups: [] },
        recipientCount: 0
      };

    default:
      return state;
  }
}
