import {
  CREATE_COLLECT_DEPOSIT_REQUEST,
  CREATE_COLLECT_DEPOSIT_SUCCESS,
  CREATE_COLLECT_DEPOSIT_FAIL,
  CREATE_COLLECT_DEPOSIT_RESET,
  CREATE_COLLECT_PAYMENT_REQUEST,
  CREATE_COLLECT_PAYMENT_SUCCESS,
  CREATE_COLLECT_PAYMENT_FAIL,
  CREATE_COLLECT_PAYMENT_RESET
} from '../actions/types';

export default function collectDepositCreateReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_COLLECT_DEPOSIT_REQUEST:
      return { loading: true };
    case CREATE_COLLECT_DEPOSIT_SUCCESS:
      return { loading: false, success: true };
    case CREATE_COLLECT_DEPOSIT_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_COLLECT_DEPOSIT_RESET:
      return {};
    case CREATE_COLLECT_PAYMENT_REQUEST:
      return { loading: true };
    case CREATE_COLLECT_PAYMENT_SUCCESS:
      return { loading: false, success: true };
    case CREATE_COLLECT_PAYMENT_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_COLLECT_PAYMENT_RESET:
      return {};
    default:
      return state;
  }
}
