import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Input,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Collapse,
  Alert
} from 'reactstrap';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { TollFreeUploadContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StepThreeForm = () => {
  const { upload, setUpload, setStep, handleInputChange } = useContext(
    TollFreeUploadContext
  );
  const location = useLocation();
  const { tollFreeVerificationStatus, tollFreeVerificationRejectionReason } =
    location.state || {};
  const [collapseStep1, setCollapseStep1] = useState(true);
  const [stepOneStatus, setStepOneStatus] = useState(false);
  const [collapseStep2, setCollapseStep2] = useState(false);
  const [stepTwoStatus, setStepTwoStatus] = useState(false);
  const [collapseStep3, setCollapseStep3] = useState(false);
  const [stepThreeStatus, setStepThreeStatus] = useState(false);
  const [validateStep1Inputs, setValidateStep1Inputs] = useState(false);
  const [validateStep2Inputs, setValidateStep2Inputs] = useState(false);
  const [validateStep3Inputs, setValidateStep3Inputs] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  console.log('upload', upload);
  const handleSaveStep1 = () => {
    setValidateStep1Inputs(true);
    let valid = true;
    if (!upload?.useCaseCategories) valid = false;
    if (!upload?.useCaseSummary) valid = false;
    if (!upload?.messageVolume) valid = false;
    if (valid) {
      setStepOneStatus(true);
      setCollapseStep1(false);
    }
  };

  const handleSaveStep2 = () => {
    setValidateStep2Inputs(true);
    let valid = true;
    if (!upload?.optInType) valid = false;
    if (!upload?.optInImageUrls) valid = false;
    if (valid) {
      setStepTwoStatus(true);
      setCollapseStep2(false);
    }
  };

  const handleSaveStep3 = () => {
    setValidateStep3Inputs(true);
    let valid = true;
    if (upload?.sampleMessages[0]?.text?.length < 1) valid = false;
    if (valid) {
      setStepThreeStatus(true);
      setCollapseStep3(false);
    }
  };


  const handleNext = () => {
    handleSaveStep1();
    handleSaveStep2();
    handleSaveStep3();

    if (stepOneStatus && stepTwoStatus && stepThreeStatus) {
      setStep(4);
    }
  };

  const [sampleMessages, setSampleMessages] = useState([
    { id: 1, text: '[Enter your sample message] Reply STOP to opt out.' }
  ]);

  const handleSampleMessageChange = (id, value) => {
    setSampleMessages(prevMessages =>
      prevMessages.map(msg => (msg.id === id ? { ...msg, text: value } : msg))
    );
  };

  const addSampleMessage = () => {
    setSampleMessages(prevMessages => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        text: '[Enter your sample message] Reply STOP to opt out.'
      }
    ]);
  };

  const removeSampleMessage = id => {
    setSampleMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  };

  useEffect(() => {
    setUpload({ ...upload, sampleMessages: sampleMessages });
  }, [sampleMessages]);

  return (
    <Fragment>
      {tollFreeVerificationStatus === 'TWILIO_REJECTED' &&
        tollFreeVerificationRejectionReason && (
          <Alert color="warning">
            The campaign registry rejected this campaign due to the following
            reason: {<strong>{tollFreeVerificationRejectionReason}</strong>}.
            Please resubmit and try again.
          </Alert>
        )}
      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => setCollapseStep1(!collapseStep1)}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 1: Campaign details</h5>
          <div className="d-flex">
            {stepOneStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep1 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep1}>
          <p>
            Please describe your text messaging campaign, its purpose and
            recipients.
          </p>
          <FormGroup>
            <Label htmlFor="useCaseCategories">
              Choose the category that best represents your use case/content
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="select"
              name="useCaseCategories"
              placeholder="Select use case"
              id="useCaseCategories"
              onChange={handleInputChange}
              value={upload?.useCaseCategories}
              invalid={validateStep1Inputs && !upload?.useCaseCategories}
            >
              <option value="" disabled>
                Select use case
              </option>
              <option value="TWO_FACTOR_AUTHENTICATION">2FA</option>
              <option value="ACCOUNT_NOTIFICATIONS">Account notifications</option>
              <option value="CUSTOMER_CARE">Customer care</option>
              <option value="HIGHER_EDUCATION">Higher education</option>
              <option value="DELIVERY_NOTIFICATIONS">
                Delivery notifications
              </option>
              <option value="PUBLIC_SERVICE_ANNOUNCEMENT">
                Public service announcement
              </option>
              <option value="SECURITY_ALERT">Security alert</option>
              <option value="EVENTS">Events</option>
              <option value="FRAUD_ALERT_MESSAGING">Fraud Alerts</option>
              <option value="CHARITY_NONPROFIT">Charity - non profit</option>
              <option value="MARKETING">Marketing</option>
              <option value="K12">K - 12</option>
              <option value="POLITICAL_ELECTION_CAMPAIGNS">Political</option>
              <option value="POLLING_AND_VOTING_NON_POLITICAL">
                Voting Reminders
              </option>
            </Input>
            <FormFeedback>Please select your campaign use case.</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="useCaseSummary">
              Describe what the content is about (alert, 2-way conversation,
              link to URL, download, etc.)
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="textarea"
              name="useCaseSummary"
              id="useCaseSummary"
              onChange={handleInputChange}
              value={upload?.useCaseSummary}
              invalid={validateStep1Inputs && !upload?.useCaseSummary}
              placeholder="Describe in at least 10 words"
              rows="2"
            />
            <FormFeedback>
              Please describe your planned message content in at least 10 words.
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="messageVolume">
              Estimated monthly message volume
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="select"
              name="messageVolume"
              id="messageVolume"
              onChange={handleInputChange}
              value={upload?.messageVolume}
              invalid={validateStep1Inputs && !upload?.messageVolume}
            >
              <option value="">- Please select your estimated volume -</option>
              <option value="10">10 messages</option>
              <option value="100">100 messages</option>
              <option value="1,000">1000 messages</option>
              <option value="10,000">10,000 messages</option>
              <option value="100,000">100,000 messages</option>
              <option value="250,000">250,000 messages</option>
              <option value="500,000">500,000 messages</option>
              <option value="1,000,000">1,000,000 messages</option>
              <option value="5,000,000">5,000,000 messages</option>
              <option value="10,000,000+">10,000,000+ messages</option>
            </Input>
            <FormFeedback>
              Please select your estimated monthly message volume.
            </FormFeedback>
          </FormGroup>

          <Button onClick={handleSaveStep1} color="primary" className="my-3">
            Save and continue
          </Button>
        </Collapse>
      </div>

      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            return handleSaveStep1(), setCollapseStep2(!collapseStep2);
          }}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 2: Opt-in flow</h5>
          <div className="d-flex">
            {stepTwoStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep2 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep2}>
          <p>
            In this step, please describe in detail, how your subscribers opt in
            for the communication.
          </p>
          <FormGroup>
            <Label htmlFor="optInType">
              Opt-in type<strong className="text-danger">*</strong>
            </Label>
            <Input
              type="select"
              name="optInType"
              id="optInType"
              onChange={handleInputChange}
              value={upload?.optInType}
              invalid={validateStep1Inputs && !upload?.optInType}
            >
              <option value="">- Please select your opt in type -</option>
              <option value="VERBAL">Verbal</option>
              <option value="WEB_FORM">Web form</option>
              <option value="PAPER_FORM">Paper form</option>
              <option value="VIA_TEXT">Via-text</option>
              <option value="MOBILE_QR_CODE">Mobile QR-Code</option>
            </Input>
            <FormFeedback>Please select your opt in type.</FormFeedback>
          </FormGroup>
          <p>
            Briefly describe how consent is collected. For example: subscribe
            form on the website, keyword, IVR, paper form, point of sales, etc.{' '}
            <strong>
              Third-party opt-in is not supported and is ineligible for
              verification.
            </strong>
            <a href="#" style={{ marginLeft: '5px' }}>
              See detailed examples
            </a>
          </p>
          <FormGroup>
            <Label htmlFor="optInImageUrls">
              Opt-in screenshot<strong className="text-danger">*</strong>
            </Label>
            <Input
              type="file"
              name="optInImageUrls"
              id="optInImageUrls"
              accept=".jpg, .png, .gif, .pdf"
              onChange={async e => {
                const file = e.target.files[0];
                if (file) {
                  const validFileTypes = [
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'application/pdf'
                  ];
                  if (validFileTypes.includes(file.type)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                      const base64String = reader.result.split(',')[1]; // Extract the base64 string
                      const formData = {
                        imageName: file.name,
                        image: base64String,
                        type: file.type
                      };

                      try {
                        const response = await axios({
                          url: `${process.env.REACT_APP_API_URL}/api/upload-optin-screenshot`,
                          method: 'POST',
                          data: formData,
                          withCredentials: true,
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        });

                        if (response.data.fileUrl) {
                          setUpload({
                            ...upload,
                            optInImageUrls: response.data.fileUrl
                          });
                          setPreviewImage(reader.result); // Set the preview image
                        } else {
                          console.error(
                            'Error: File URL not returned from server.'
                          );
                        }
                      } catch (error) {
                        console.error('Error uploading file:', error);
                      }
                    };
                    reader.readAsDataURL(file); // Read the file as a data URL
                  } else {
                    alert(
                      'Invalid file type. Please upload a .jpg, .png, .gif, or .pdf file.'
                    );
                  }
                }
              }}
              invalid={validateStep2Inputs && !upload?.optInImageUrls}
            />

            <FormFeedback>
              Please upload your Opt-in screenshot. This field is required.
            </FormFeedback>
            <p>
              Please upload a screenshot of your opt-in form or another method
              that you used to obtain the opt-in. Supported file formats: .jpg,
              .png, .gif, .pdf
            </p>
          </FormGroup>
          {previewImage && (
            <div className="mt-3">
              <img
                src={previewImage}
                alt="Opt-in Screenshot Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
          )}
          <FormGroup>
            <Label htmlFor="additionalInformation">
              Additional supporting documentation
              <strong>{` -- (Optional)`}</strong>
            </Label>
            <Input
              type="textarea"
              name="additionalInformation"
              id="additionalInformation"
              onChange={handleInputChange}
              value={upload?.additionalInformation}
              placeholder="Please explain your opt-in process and prove that your traffic is legitimate"
              rows="2"
            />
            <FormFeedback>
              Please enter a valid Additional supporting documentation. Please
              include at least 10 words.
            </FormFeedback>
            <p>
              Any additional details that you want to add or that you believe
              will help with the verification such as privacy policies, AUPs,
              additional onboarding controls you have, links, etc.
            </p>
          </FormGroup>
          <Button onClick={handleSaveStep2} color="primary" className="my-3">
            Save and continue
          </Button>
        </Collapse>
      </div>

      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            return (
              handleSaveStep2(),
              handleSaveStep3(),
              setCollapseStep3(!collapseStep3)
            );
          }}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 3: Sample messages</h5>
          <div className="d-flex">
            {stepThreeStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep3 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep3}>
          <p>
            Provide up to five examples of the messages this campaign will send.
            Future message content must exactly match the examples provided in
            this step.
          </p>
          <Alert color="info">
            <FontAwesomeIcon icon="info-circle" className="me-2" />
            Templated messages are not advised as they can be rejected by the
            network operators: <br />
            <strong>Instead of:</strong> “Dear [customer name], your order
            [order number] has been received. Reply STOP to opt out.” <br />
            <strong>Write:</strong> “Dear Susan, your order 47755 has been
            received. Reply STOP to opt out.”
          </Alert>
          {sampleMessages.map((msg, index) => (
            <Row key={index}>
              <Col>
                <FormGroup>
                  <div>
                    <Label htmlFor={`sampleMessage${msg.id}`}>
                      Sample message {msg.id}{' '}
                      {msg.id === 1 && (
                        <strong className="text-danger">*</strong>
                      )}
                    </Label>
                  </div>
                  <div className="d-flex">
                    <Input
                      type="textarea"
                      style={{ minHeight: '150px' }}
                      name={`sampleMessage${msg.id}`}
                      id={`sampleMessage${msg.id}`}
                      invalid={
                        validateStep3Inputs && !upload?.sampleMessages[0]?.text
                      }
                      placeholder="[Enter your sample message] Reply STOP to opt out."
                      value={msg.text}
                      onChange={e => {
                        handleSampleMessageChange(msg.id, e.target.value);
                      }}
                      className="mb-3"
                    />
                    {msg.id !== 1 && (
                      <div>
                        <Button
                          size="sm"
                          className="ms-2 btn-danger"
                          onClick={() => removeSampleMessage(msg.id)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ))}
          <Row className="d-flex justify-content-start">
            <Col xs={12}>
              {sampleMessages.length < 5 && (
                <Button color="link" onClick={addSampleMessage}>
                  + Add another sample message
                </Button>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col xs={12}>
              <Button color="primary" className="ms-2 my-3">
                Save and continue
              </Button>
            </Col>
          </Row>
        </Collapse>
      </div>


      <Row>
        <div className="d-flex justify-content-between mt-3">
          <Button
            onClick={() => setStep(2)}
            className="px-4 bg-transparent text-primary border-0 shadow-none text-bold fw-bold"
          >
            Back
          </Button>
          <Button onClick={handleNext} className="px-4 fw-bold" color="primary">
            Next
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};

export default StepThreeForm;
