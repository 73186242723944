import React, { useEffect, useState, useContext } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import SentTable from './SentTable';
import ReceivedTable from './ReceivedTable';
import CallsTable from './CallsTable';
import { listChats } from 'actions/chatActions';
import { SocketContext } from 'providers/SocketProvider';

const HistoryTabs = () => {
  const [activeTab, setActiveTab] = useState('1');
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    dispatch(listChats());
  }, [dispatch]);

  useEffect(() => {
    if (socket) {
      socket.on('messageFromBackend', dataFromServer => {
        console.log(dataFromServer);
        socket.emit('messageToServer', { data: 'this is from client' });
      });

      socket.on('updateBulkSend', msgStatusData => {
        console.log(
          'this was triggered for messageStatusUpdate',
          msgStatusData
        );
      });

      // Cleanup on unmount
      return () => {
        socket.off('messageFromBackend');
        socket.off('updateBulkSend');
      };
    }
  }, [socket]);

  return (
    <div>
      <Nav className="justify-content-start flex-column flex-md-row" tabs>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => {
              toggle('1');
            }}
          >
            <span className={`mx-1 fs-10`}>Sent </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => {
              toggle('2');
            }}
          >
            <span className={`mx-1 fs-10`}>Received </span>
          </NavLink>
        </NavItem>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => {
              toggle('3');
            }}
          >
            <span className={`mx-1 fs-10`}>Calls</span>
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Row>
            <Col sm="12">
              <SentTable fromHistory={true} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <Col>
              <ReceivedTable fromHistory={true} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="3">
          <Row>
            <Col>
              <CallsTable />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default HistoryTabs;
