import React, { useEffect, Fragment, useState, useCallback, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Spinner,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import ResponderMessageDetails from './ResponderMessageDetails';
import ContactEditModal from 'components/contacts/ContactEditModal';
import { DateTime } from "luxon";
import DatePicker from 'react-datepicker';
import { contactById } from 'actions/index';
import { createChat } from 'actions/chatActions';
import { UPDATE_ACTIVE_CHAT, SET_CONTACTS, DELETE_RESPONDER_MESSAGES_RESET } from 'actions/types';
import CustomerForm from 'components/customers/CustomerForm';
import { deleteResponderMessages, listResponderMessages, listResponderMessagesDateRange, listResponderMessagesCustomDateRange } from 'actions/historyActions';
import SubtleBadge from 'components/common/SubtleBadge';
import ActionPopover from 'components/common/ActionPopover';
import { toast } from 'react-toastify';
import { updateContact } from 'actions/index';

const ResponderHistory = () => {

  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFirstRender = useRef(true);

    const pageSize = useSelector(state => state.paginationRedux.pageSize);
  



  const userResponderMessages = useSelector((state) => state.responderMessages)
  const { messages: responderMessages, loading: loadingResponderMessages, totalMessages: totalMessages } = userResponderMessages


  const chats = useSelector((state) => state.chats)

  const primaryNumber = useSelector((state) => state.primaryNumber)
  const numbers = useSelector((state) => state.numbers)

  const userFields = useSelector((state) => state.fields)
  const { fields, loading: fieldsLoading } = userFields

  const responderMessagesDelete = useSelector((state) => state.responderMessagesDelete)
  const { success: successDelete, loading: loadingDelete } = responderMessagesDelete

  const userGroups = useSelector((state) => state.groups)
  const { groups } = userGroups

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone)


  const [isOpen, setIsOpen] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showMessageDetailModal, setShowMessageDetailModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showUpdateContactModal, setShowUpdateContactModal] = useState(false)
  const [message, setMessage] = useState()
  const [rowDelete, setRowDelete] = useState()
  const [dateRange, setDateRange] = useState("All messages")
  const [customDateModal, setCustomDateModal] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [datesObj, setDatesObj] = useState({ start: "", end: "" })
  const [newContactInfo, setNewContactInfo] = useState()
  const [changeText, setChangeText] = useState();
  const [contact, setContact] = useState({});

  const openGroups = []
  groups?.map((g) => { if (!contact?.group?.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })



  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { if (fr.original.sid) { return fr.original.sid } else { return { messageIds: fr.original.messageIds, _id: fr.original._id } } })), setRowDelete([]) },
    [setIsSelected]);

  const openContactUpdateModal = async (contactId) => {
    if (!fieldsLoading) {
      const contactData = await dispatch(contactById(contactId));
      const contactFields = fields?.map((field) => ({
        _id: field._id,
        title: field.title,
        value: contactData?.fields?.find(f => f._id === field._id)?.value || "",
      }));

      setContact({ ...contactData, fields: contactFields });
      setShowUpdateContactModal(true);
    } else {
      toast.error('Please wait. Loading');
    }
  };


  const closeContactBtn = (
    <button className="btn-close bg-light" onClick={() => { return setShowContactModal(!showContactModal) }}>

    </button>
  );

  const closeMsgDetailsBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowMessageDetailModal(!showMessageDetailModal) }}>

    </button>
  );


  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }


  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const sentByFormatter = (rowData) => {
    const { to, contact } = rowData.row.original
    return (
      <div>
        <Media body>
          <SubtleBadge bg="warning" pill className="fs-9">{contact?.firstName ? contact?.firstName + " " + contact?.lastName : to}<FontAwesomeIcon icon="users" size="xs" className="text-warning ms-2" /></SubtleBadge>
        </Media>
      </div>
    );
  };

  const receivedByFormatter = (rowData) => {
    const { from } = rowData.row.original
    const ourNumber = numbers?.filter((el) => { return el.phoneNumber === from })[0]
    return (
      <div>
        <Media body className="ms-2 mt-1">
          <SubtleBadge bg="primary" pill className="fs-9"><FontAwesomeIcon icon="hashtag" size="xs" className="text-primary me-2" />{ourNumber?.nickName ? ourNumber?.nickName : ourNumber?.phoneNumber ? formatPhoneNumber(ourNumber.phoneNumber) : from}</SubtleBadge>
        </Media>
      </div>
    );
  };

  const responderFormatter = (rowData) => {
    const { responderTitle } = rowData.row.original
    return (
      <Link onClick={() => { }} tag={Link} to="/follow-up/responders">
        <Media tag={Flex} align="center">
          <Media body>
            <h5 className="mb-0 fs-9 mt-2">{responderTitle}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const statusFormatter = (rowData) => {
    const { status, errorMessage } = rowData.row.original
    return (
      <div>
        <Media body className="ms-2 mt-1">
          {status === "delivered" ?
            <SubtleBadge bg="success" pill className="fs-9">
              {status}
              <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
            </SubtleBadge> : status !== "delivered" && !errorMessage ?
              <SubtleBadge bg="info" pill className="fs-9">
                {status}
                <FontAwesomeIcon icon="times" transform="shrink-1" className="ms-1" />
              </SubtleBadge> : errorMessage ?
                <SubtleBadge bg="danger" pill className="fs-9">
                  {errorMessage}
                  <FontAwesomeIcon icon="times" transform="shrink-1" className="ms-1" />
                </SubtleBadge> : ""
          }
        </Media>

      </div>
    );
  };

  const dateSentFormatter = (rowData) => {
    const { dateSent } = rowData.row.original;

    return (
      <div>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9 mt-2">
              <Moment format="MM/DD/YY" date={dateSent} />
            </h5>
            <h6 className="mb-0 fs--1 mt-1 text-muted">
              <Moment format="hh:mm A" date={dateSent} />
            </h6>
          </Media>
        </Media>
      </div>
    );
  };


  const actionFormatter = (rowData) => {
    const { from, contact, sid, } = rowData.row.original;
    const composeContact = {
      id: contact ? contact._id : "",
      original: {
        firstName: contact ? contact.firstName : from,
        lastName: contact ? contact.lastName : "",
        phone_number: contact ? contact.phone_number : from,
        _id: contact ? contact._id : ""
      }
    };

    const items = [
      {
        label: 'Go to chat',
        icon: 'sms',
        action: async () => {
          const formatFrom = from.replace(/\D/g, '');
          const doesExist = chats?.filter((chat) => chat.phone === formatFrom)[0];

          if (doesExist) {
            dispatch({ type: UPDATE_ACTIVE_CHAT, payload: doesExist._id });
          } else {
            const contact = { phone_number: formatFrom };

            const newChatId = await dispatch(createChat(contact, primaryNumber));
            if (newChatId) {
              dispatch({ type: UPDATE_ACTIVE_CHAT, payload: newChatId });
            }
          }
          navigate('/follow-up/chat');
        }
      },
      {
        label: !contact ? 'Create contact' : 'Contact details',
        icon: !contact ? 'user-plus' : 'user',
        action: () => {
          if (!contact) {
            setNewContactInfo(from);
            setShowContactModal(!showContactModal);
          } else {
            openContactUpdateModal(contact?._id); // this is contact._id
          }
        },
        className: !contact ? 'text-success' : ''
      },
      {
        label: 'Send SMS to this contact',
        icon: 'reply',
        action: () => {
          dispatch({ type: SET_CONTACTS, payload: [composeContact] });
          navigate('/');
        }
      },
      {
        label: 'Message Details',
        icon: 'eye',
        color: 'info',
        action: () => {
          setMessage(rowData.row.original);
          setShowMessageDetailModal(!showMessageDetailModal);
        }
      },
      {
        label: 'Delete Message',
        icon: 'trash',
        color: 'danger',
        action: () => {
          setRowDelete([sid]);
          setShowDeleteModal(!showDeleteModal);
        },
        className: 'text-danger'
      }
    ].filter(Boolean);

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={sid}
          items={items}
        />
      </div>
    );
  };



  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'to',
      Header: 'Sent by',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: sentByFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'from',
      Header: 'Received by',
      headerProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
      Cell: receivedByFormatter,
      cellProps: {
        style: {
          width: 50,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'fullName',
      headerProps: {
        className: "d-none"
      },
      cellProps: {
        className: 'd-none',
      }
    },
    {
      accessor: 'responderTitle',
      Header: 'Responder',
      headerProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
      Cell: responderFormatter,
      cellProps: {
        style: {
          maxWidth: 160,
          overflow: 'hidden'
        }
      },
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: statusFormatter
    },
    {
      accessor: 'dateSent',
      Header: 'Sent time',
      Cell: dateSentFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',

    }
  ];

  //--------------------------------------------------------------------------Handlers------------------------------------------------------------------------------------



  const handleDateChange = target => {
    let name = target.name
    const selectedDateISO = DateTime.fromISO(target.value)
    const timeObj = { year: selectedDateISO.c.year, month: selectedDateISO.c.month, day: selectedDateISO.c.day, hour: selectedDateISO.c.hour, minute: selectedDateISO.c.minute }
    let TzdateTime = DateTime.fromObject(timeObj, { zone: timezone }).toISO()
    setDatesObj({ ...datesObj, [name]: TzdateTime })
  };




  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  useEffect(() => {
    setRowDelete([])
  }, [])


  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (dateRange !== "All messages" && dateRange !== "custom") {
      dispatch(listResponderMessagesDateRange({
        dateRange,
        page: 1,
        limit: 10,
        sortBy: 'dateSent',
        sortOrder: -1,
      }));
    } else if (dateRange === "All messages") {
      dispatch(listResponderMessages({
        search: "",
        limit: 10,
        sortBy: "dateSent",
        sortOrder: -1,
      }));
    }
  }, [dateRange, successDelete]);

  useEffect(() => {
    if (successDelete) {
      setShowDeleteModal(!showDeleteModal)
      dispatch({ type: DELETE_RESPONDER_MESSAGES_RESET })
    }

  }, [successDelete])



  const handleSearch = (value) => {

    dispatch(listResponderMessages({ search: value, page: 1, limit: pageSize }));

  };




  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'dateSent'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listResponderMessages({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowUpdateContactModal(!showUpdateContactModal)

  }



  const years = Array.from(
    new Array(101),
    (_, index) => new Date().getFullYear() - 100 + index
  );

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];


  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    const userAgent =
      typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = /iPhone|iPad|iPod|Android/i.test(userAgent);
    setIsMobileDevice(mobile);
  }, []);



  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };






  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon icon="plus" tag={Link} to="/follow-up/responder-upload" transform="shrink-3" className="btn-tertiary border-dark" size="sm">
                Responder
              </ButtonIcon>
              <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) }} disabled={!isSelected.length} transform="shrink-3" className="btn-tertiary border-dark text-danger mx-2" size="sm" >
                Delete
              </ButtonIcon>
            </ButtonGroup>
            <div>
              <Dropdown isOpen={isOpen} toggle={() => { setIsOpen(!isOpen) }}>
                <DropdownToggle className='btn-tertiary border-dark fs-10 mt-2' color="falcon-warning" > <FontAwesomeIcon icon="calendar-alt" transform="shrink-3" className="me-1" />Date Range</DropdownToggle>
                <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="All messages">{`All messages`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Today">{`Today`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Yesterday">{`Yesterday`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This week">{`This week`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This month">{`This month`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last month">{`Last month`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last 2 months">{`Last 2 months`}</DropdownItem>
                  <DropdownItem onClick={(e) => { return setDateRange("custom"), setCustomDateModal(!customDateModal) }}>{`Custom date range`}</DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingResponderMessages && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={responderMessages}
            fetchData={fetchData}
            total={totalMessages}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  onSearch={handleSearch}
                  placeholder="Search messages..."
                  table />
              </Col>
            </Row>
            <AdvanceTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalMessages}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 font-weight-normal p-4">
              {loadingDelete && (
                <div style={overlayStyle}>
                  <Spinner animation="border" role="status" style={spinnerStyle}>
                    <span className="visually-hidden">Deleting...</span>
                  </Spinner>
                </div>
              )}
              <Button
                onClick={() => { if (rowDelete?.length) { dispatch(deleteResponderMessages(rowDelete)) } else { dispatch(deleteResponderMessages(isSelected)) } }}
                block
                className="btn-tertiary border-dark text-danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => { return setShowDeleteModal(!showDeleteModal), setRowDelete([]) }}
                block
                className="btn-tertiary border mt-3"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // Message Details Modal
      }
      <Modal isOpen={showMessageDetailModal} centered toggle={() => setShowMessageDetailModal(!showMessageDetailModal)}>
        <ModalHeader className="d-flex justify-content-center" close={closeMsgDetailsBtn} >Message Details</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <ResponderMessageDetails message={message} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // create contact modal
      }
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={() => { return setShowContactModal(!showContactModal) }} className="d-flex flex-between-center border-bottom-0" close={closeContactBtn}>
          How would you like to Add?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} newContactInfo={newContactInfo} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // edit contact modal
      }
      <Modal isOpen={showUpdateContactModal} centered toggle={() => setShowUpdateContactModal(!showUpdateContactModal)}>
        <ModalHeader toggle={toggleSave} className="border-bottom-0">
          <ButtonIcon
            onClick={() => { return dispatch(updateContact(contact, contact?._id)), setShowUpdateContactModal(!showUpdateContactModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="p-2">
              <ContactEditModal
                contact={contact}
                changeText={changeText}
                setContact={setContact}
                openGroups={openGroups}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {
        // custom date range modal
      }

      {
        // custom date range modal
      }
      <Modal isOpen={customDateModal} centered toggle={() => setCustomDateModal(!customDateModal)}>
        <ModalHeader toggle={() => setCustomDateModal(!customDateModal)}>Set Date Range</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputGroup>
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                      handleDateChange({
                        name: 'start',
                        value: date.toISOString(),
                      });

                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select Start Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>


            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputGroup >
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  autocomplete="off"
                  selected={endDate}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                      handleDateChange({
                        name: 'end',
                        value: date.toISOString(),
                      });
                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select End Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>



            <div className="d-flex justify-content-end mt-4">
              <Button
                color="secondary"
                onClick={() => setCustomDateModal(!customDateModal)}
                className="me-2"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(listResponderMessagesCustomDateRange({
                    dateRange: { start: startDate, end: endDate },
                    page: 1,
                    limit: 10,
                    sortBy: 'dateSent',
                    sortOrder: -1,
                  }));
                  setCustomDateModal(!customDateModal);
                }}
                disabled={!datesObj.start || !datesObj.end}
              >
                Search
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>


    </Fragment>
  );
};


export default ResponderHistory;
