import {
  SUBSCRIPTION_CANCEL_REQUEST,
  SUBSCRIPTION_CANCEL_SUCCESS,
  SUBSCRIPTION_CANCEL_FAIL,
  SUBSCRIPTION_CANCEL_RESET
} from '../actions/types';

export default function subscribeCancelReducer(state = {}, action) {
  switch (action.type) {
    case SUBSCRIPTION_CANCEL_REQUEST:
      return { loading: true };
    case SUBSCRIPTION_CANCEL_SUCCESS:
      return { loading: false, success: true };
    case SUBSCRIPTION_CANCEL_FAIL:
      return { loading: false, error: action.payload };
    case SUBSCRIPTION_CANCEL_RESET:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
