export default [
  {
    question: 'What is a 10DLC number?',
    answer:
      "A 10DLC number is a 10-digit local phone number that businesses use for large-scale SMS marketing and communication. It's designed to send high volumes of messages and is approved by mobile network operators to comply with regulations and provide better deliverability."
  },
  {
    question: 'Is there an approval process for using 10DLC numbers?',
    answer:
      'To make calls no, To send SMS, yes. Mobile carriers require a vetting process where you must provide your business information and use case. The approval time can vary, and once approved, you can start using your 10DLC number for messaging.'
  },
  {
    question: 'Can I port my existing business number to a 10DLC?',
    answer:
      'In many cases, you can port your existing business number to a 10DLC. This process involves submitting a porting request and providing necessary documentation to prove ownership of the number.'
  },
  {
    question: 'What are the benefits of using a 10DLC number?',
    answer:
      '10DLC numbers are more trustworthy and offer higher messaging throughput than traditional long codes, better deliverability, lower messaging fees, and improved customer trust through local area codes.'
  },
  {
    question:
      'Are there any restrictions on what I can send with a 10DLC number?',
    answer:
      'Yes, all messages must comply with carrier guidelines, anti-spam regulations, and must not contain prohibited content such as SHAFT (sex, hate, alcohol, firearms, and tobacco) content. We can handle all of this for you with our agency plan.'
  },
  {
    question: 'How much does it cost to use a 10DLC number?',
    answer:
      "Costs for 10DLC numbers are $10/mo in the US and Canada. 0.04 to send an sms Check your pricing page for detailed information and volume discounts."
  },
  {
    question: 'What is the message throughput for a 10DLC number?',
    answer:
      'Throughput for 10DLC numbers can range from 10 to 100 messages per second, depending on carrier approval and the quality of your messaging program.'
  },
  {
    question: 'How do I track the performance of my 10DLC campaigns?',
    answer:
      'We provide analytics and reporting tools that allow you to track deliverability, read rates, response rates, and other key performance indicators for your campaigns.'
  },
  {
    question: 'Can I use a 10DLC number for two-way messaging?',
    answer:
      'Yes, 10DLC numbers support two-way messaging, allowing you to engage in conversations with your customers, provided that you comply with opt-in and opt-out requirements.'
  },
  {
    question: 'What happens if a carrier rejects my 10DLC registration?',
    answer:
      'If a carrier rejects your 10DLC registration, you should review the reasons for rejection, correct any issues, and resubmit your application. Contact support and we can help guide you through this process or we can handle it with our agency plans.',
    divider: false
  }
];
