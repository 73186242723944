import {
  UPDATE_CUSTOMER_REVIEW_REQUEST,
  UPDATE_CUSTOMER_REVIEW_SUCCESS,
  UPDATE_CUSTOMER_REVIEW_FAIL,
  UPDATE_CUSTOMER_REVIEW_RESET
} from '../actions/types';

export default function updateCustomerReviewReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_CUSTOMER_REVIEW_REQUEST:
      return { loading: true };
    case UPDATE_CUSTOMER_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case UPDATE_CUSTOMER_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_CUSTOMER_REVIEW_RESET:
      return {};
    default:
      return state;
  }
}
