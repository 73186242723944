import {
  TAX_ID_UPDATE_REQUEST,
  TAX_ID_UPDATE_SUCCESS,
  TAX_ID_UPDATE_FAIL,
  TAX_ID_UPDATE_RESET
} from '../actions/types';

export default function updateTaxIdReducer(state = {}, action) {
  switch (action.type) {
    case TAX_ID_UPDATE_REQUEST:
      return { loading: true };
    case TAX_ID_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TAX_ID_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TAX_ID_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
