/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdvanceContactsTableFooter = ({
  rowCount,
  navButtons,
  page,
  pageIndex,
  pageSize,
  rowInfo,
  rowsPerPageSelection,
  rowsPerPageOptions = [10, 25, 50, 100],
  className,
  setPageSize,
  gotoPage,
  canPreviousPage,
  canNextPage
}) => {

  return (
    <div>
      <Flex className={classNames(className, 'align-items-center justify-content-between')}>
        <Flex alignItems="center" className="fs--1">
          {rowInfo && (
            <p className="mb-0">
              <span className="d-none d-sm-inline-block me-2">
                {pageSize * pageIndex + 1} to {pageSize * pageIndex + page.length} of {rowCount}
              </span>
            </p>
          )}
          {rowsPerPageSelection && (
            <>
              <p className="mb-0 mx-2">Rows:</p>
              <Form.Select
                size="sm"
                className="w-auto"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {rowsPerPageOptions.map(value => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </Flex>
        {navButtons && (
          <Flex>
            <Button
              size="sm"
              disabled={!canPreviousPage}
              variant={canPreviousPage ? 'primary' : 'light'}
              onClick={() => gotoPage(pageIndex - 1)}
            >
              <FontAwesomeIcon icon="chevron-left" />
            </Button>
            <Button
              size="sm"
              disabled={!canNextPage}
              variant={canNextPage ? 'primary' : 'light'}
              className="ms-2"
              onClick={() => gotoPage(pageIndex + 1)}
            >
              <FontAwesomeIcon icon="chevron-right" />
            </Button>
          </Flex>
        )}
      </Flex>
    </div>
  );
};
export default AdvanceContactsTableFooter;
