import React, { useState, useEffect, useRef } from 'react';
import { Input, FormFeedback } from 'reactstrap';
import visaIcon from '../../../assets/img/icons/visa.png';
import mastercardIcon from '../../../assets/img/icons/master-card.png';
import amexIcon from '../../../assets/img/icons/amex.png';
import discoverIcon from '../../../assets/img/icons/discover.png';

const CardInput = ({ cardData, setCardData, errors }) => {
  const [cardType, setCardType] = useState('');
  const [showMaskedCard, setShowMaskedCard] = useState(false); // Controls card masking
  const cardNumberRef = useRef(null);
  const expiryDateRef = useRef(null);
  const cvcRef = useRef(null);

  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvc, setCvc] = useState('');

  // Detect card type based on the first digits of the card number
  const detectCardType = number => {
    if (/^3[47]/.test(number)) {
      return 'American Express';
    } else if (/^4/.test(number)) {
      return 'Visa';
    } else if (/^5[1-5]/.test(number)) {
      return 'MasterCard';
    } else if (/^6(?:011|5)/.test(number)) {
      return 'Discover';
    } else {
      return '';
    }
  };

  // Format the card number based on card type and masking rules
  const formatCardNumber = (number, type, maskCard) => {
    if (maskCard) {
      const lastFour = number.slice(-4);
      return `•••• ${lastFour}`;
    }

    let formatted = '';
    let index = 0;
    let blocks = [];

    if (type === 'American Express') {
      blocks = [4, 6, 5];
    } else {
      blocks = [4, 4, 4, 4];
    }

    for (let block of blocks) {
      let part = number.substring(index, index + block);
      if (part.length > 0) {
        formatted += part + ' ';
      }
      index += block;
    }
    return formatted.trim();
  };

  // Card number input handler
  const handleCardNumberChange = e => {
    let input = e.target.value.replace(/\s+/g, '').replace(/\D/g, '');
    const detectedCardType = detectCardType(input);

    const maxLength = detectedCardType === 'American Express' ? 15 : 16;
    input = input.substring(0, maxLength);

    // Update cardType state immediately
    setCardType(detectedCardType);

    // Determine whether to show masked card synchronously
    const willShowMaskedCard = input.length === maxLength;

    // Format the card number accordingly
    const formattedNumber = formatCardNumber(
      input,
      detectedCardType,
      willShowMaskedCard
    );

    setCardNumber(formattedNumber);
    setCardData({ ...cardData, cardNumber: input });

    // Update the masked card state
    setShowMaskedCard(willShowMaskedCard);

    if (willShowMaskedCard) {
      expiryDateRef.current.focus();
    }
  };

  // Format expiry date as MM/YY
  const formatExpiryDate = input => {
    let month = '';
    let year = '';
    let formattedExpiryDate = '';

    if (input.length === 0) {
      formattedExpiryDate = '';
    } else if (input.length === 1) {
      if (input !== '0') {
        // Single-digit month, prepend zero
        month = '0' + input;
        formattedExpiryDate = month;
      } else {
        // Input is '0', invalid month
        month = input;
        formattedExpiryDate = input;
      }
    } else if (input.length === 2) {
      month = input;
      formattedExpiryDate = month;
    } else if (input.length >= 3) {
      month = input.substring(0, 2);
      year = input.substring(2, 4);
      formattedExpiryDate = month + '/' + year;
    }

    return { formattedExpiryDate, month, year };
  };

  // Expiry date input handler
  const handleExpiryDateChange = e => {
    let input = e.target.value.replace(/\D/g, '').substring(0, 4);

    const { formattedExpiryDate, month, year } = formatExpiryDate(input);

    setExpiryDate(formattedExpiryDate);
    setCardData({ ...cardData, month: month, year: year ? '20' + year : '' });

    // Move to CVC automatically after expiry date is fully entered
    if (input.length === 4) {
      cvcRef.current.focus();
    }
  };
  // Handle key down in expiry date to manage backspacing through the slash
  const handleExpiryDateKeyDown = e => {
    if (e.key === 'Backspace') {
      let input = expiryDate.replace(/\D/g, '');
      if (input.length === 0) {
        // If expiry date is empty, move focus to card number field
        setShowMaskedCard(false);
        cardNumberRef.current.focus();
        e.preventDefault();
      }
    }
  };

  // CVC input handler
  const handleCvcChange = e => {
    let maxLength = cardType === 'American Express' ? 4 : 3;
    let input = e.target.value.replace(/\D/g, '').substring(0, maxLength);
    setCvc(input);
    setCardData({ ...cardData, cardCode: input });
  };

  // Handle backspace for moving between fields
  const handleCvcKeyDown = e => {
    if (e.key === 'Backspace' && cvc.length === 0) {
      expiryDateRef.current.focus();
      e.preventDefault();
    }
  };

  // Get card icon based on detected card type
  const getCardIcon = () => {
    switch (cardType) {
      case 'Visa':
        return visaIcon;
      case 'MasterCard':
        return mastercardIcon;
      case 'American Express':
        return amexIcon;
      case 'Discover':
        return discoverIcon;
      default:
        return null;
    }
  };

  // Focus card number input on mount
  useEffect(() => {
    cardNumberRef.current.focus();
  }, []);

  return (
    <div className="card-input-container">
      <div className="card-number-container">
        {showMaskedCard && getCardIcon() && (
          <img src={getCardIcon()} alt={cardType} className="card-type-icon" />
        )}
        <Input
          type="tel" // Changed from "text" to "tel"
                    inputMode="numeric" // Added inputMode
                    pattern="[0-9]*" // Added pattern for numeric input
                    autoCorrect="off"
                    autoCapitalize="none"
          placeholder="Card Number"
          value={cardNumber}
          onChange={handleCardNumberChange}
          invalid={!!errors.cardNumber}
          innerRef={cardNumberRef}
          className="card-input-field card-number-input"
          onFocus={() => {
            if (showMaskedCard) {
              setShowMaskedCard(false);
            }
          }}
        />
      </div>
      <Input
        type="tel" // Changed from "text" to "tel"
                    inputMode="numeric" // Added inputMode
                    pattern="[0-9]*" // Added pattern for numeric input
                    autoCorrect="off"
                    autoCapitalize="none"
        placeholder="MM/YY"
        value={expiryDate}
        onChange={handleExpiryDateChange}
        onKeyDown={handleExpiryDateKeyDown} // Backspace handling for the slash
        invalid={!!errors.expDate}
        innerRef={expiryDateRef}
        className="card-input-field expiry-date-input"
        maxLength={5}
      />
      <Input
        type="tel" // Changed from "text" to "tel"
                    inputMode="numeric" // Added inputMode
                    pattern="[0-9]*" // Added pattern for numeric input
                    autoCorrect="off"
                    autoCapitalize="none"
        placeholder="CVC"
        value={cvc}
        onChange={handleCvcChange}
        onKeyDown={handleCvcKeyDown} // Backspace logic for CVC field
        invalid={!!errors.cardCode}
        innerRef={cvcRef}
        className="card-input-field cvc-input"
        maxLength={cardType === 'American Express' ? 4 : 3}
      />
      {(errors.cardNumber || errors.expDate || errors.cardCode) && (
        <FormFeedback className="error-feedback d-block">
          {errors.cardNumber || errors.expDate || errors.cardCode}
        </FormFeedback>
      )}
    </div>
  );
};

export default CardInput;
