import React, { Fragment, useEffect, useContext, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Link } from 'react-router-dom';
import Divider from 'components/common/Divider';
import Lottie from 'lottie-react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import animationData from 'components/authentication/wizard/lottie/celebration.json';
import animationDanger from 'components/authentication/wizard/lottie/warning-light.json';
import { CsvUploadContext } from 'context/Context';
import { createCsv } from 'actions/index';
import { getPageName } from 'helpers/utils';
import { SocketContext } from 'providers/SocketProvider';


const ImportSuccess = () => {

  const dispatch = useDispatch()

  const { setStep, upload, setUpload, setUserFile } = useContext(CsvUploadContext);
  const [showCreditsModal, setShowCreditsModal] = useState()
  const [progress, setProgress] = useState(0); // Add progress state

  const socket = useContext(SocketContext);


  const csvUploadCreate = useSelector((state) => state.csvUploadCreate)
  const { loading: loadingCsv } = csvUploadCreate

  const auth = useSelector((state) => (state.auth))

  const isCustomerImport = getPageName('customer-import')



  useEffect(() => {
    console.log("createcsv useEffect triggered");
    if (upload?.landlineFilter === true && (auth?.credits < upload?.contacts?.length)) {
      setShowCreditsModal(!showCreditsModal);
    } else if (isCustomerImport) {
      console.log("isCustomerImport is true, creating csv");
      dispatch(createCsv({ ...upload, customer_crm: true, socketId: socket?.id }));
    } else {
      console.log("creating csv");
      dispatch(createCsv({ ...upload, socketId: socket?.id }));
    }

    // Listen for progress updates
    if (socket) {
      socket.on('progress', (data) => {
        setProgress(data.progress);
      });

      return () => {
        // Cleanup listener on unmount
        socket.off('progress');
      };

    }
  }, []); // Empty dependency array ensures this runs only once


  const emptyData = () => {
    setStep(1);
    setUserFile()
    setUpload({
      countryNumbers: 'sameCountries',
      uploadType: 'files',
      landlineFilter: 'false',
      selectHeader: [{ customHeader: 'Do not Import', HeaderIndex: 0 }]
    });
  };


  return (
    <div>
      <Col>
        <Row>
          <Card className="w-100">
            <CardBody>
              {loadingCsv < 100 && !(upload?.landlineFilter === true && (auth?.credits < upload?.contacts?.length)) ? (
                <Card className="mb-3">
                  <CardBody>
                    <Label>Importing...</Label>
                    <ProgressBar className="font-weight-bold" animated now={progress} label={`${progress}%`} />
                  </CardBody>
                </Card>
              ) : !(upload?.landlineFilter === true && (auth?.credits < upload?.contacts?.length)) ? (
                <Fragment>
                  <div className="wizard-lottie-wrapper">
                    <div className="wizard-lottie mx-auto">
                      <Lottie animationData={animationData}
                        loop={1}
                      />
                    </div>
                  </div>
                  <h4 className="mb-1 mt-3 text-center">your contacts are added!</h4>
                  {!isCustomerImport ? (
                    <p className="fs-0 text-center">Lets send some sms</p>
                  ) : (<div></div>)}
                  <Col className="text-center">
                    <Button color="primary" tag={Link} to={isCustomerImport ? "/customers" : "/follow-up/contacts"} className="my-3 text-white">
                      {isCustomerImport ? "Go to Customers" : "Go to Contacts"}
                    </Button>
                  </Col>

                </Fragment>
              ) : (<div></div>)}
              <Row>
                <Col sm="12">
                  <Row>
                    <Col className="text-center">
                      <Divider />
                      <Button color="falcon-primary" className={classNames("px-5 my-3", { 'd-none': loadingCsv != 100 })} onClick={emptyData}>
                        Start Over
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>




            </CardBody>
          </Card>
        </Row>
      </Col>


      <Modal isOpen={showCreditsModal} centered toggle={() => setShowCreditsModal(!showCreditsModal)}>
        <ModalHeader className="bg-light flex-between-center border-bottom-0" close>

        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Fragment>
                <Card className="theme-wizard">
                  <CardBody className="fs--1 font-weight-normal px-md-6 pt-4 pb-3">
                    <Row>
                      <Col className="text-center">
                        <div className="wizard-lottie-wrapper">
                          <div className="wizard-lottie mx-auto">
                            <Lottie
                              animationData={animationDanger}
                              loop={30}
                            />
                          </div>
                        </div>
                        <h4 className="mb-1">Sending ability low!</h4>
                        <p className="fs-0">{`Please add ${upload.contacts.length - auth?.credits} more credits to apply Landline filter`}</p>
                        <Button tag={Link} color="primary" to="/get-credits" className="px-5 my-3 text-white" >
                          {"add credits >"}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Fragment>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </div>
  );
};

export default ImportSuccess;
