import AreaCodes from './area-codes';

export default [
  {
    name: 'United States',
    countryCode: 'US',
    areaCodes: AreaCodes,
    type: {
      local: {
        price: '10.00',
        credits: '250'
      },
      toll_free: {
        price: '10.00',
        credits: '250'
      }
    }
  },

  {
    name: 'Canada',
    countryCode: 'CA',
    type: {
      local: {
        price: '10.00',
        credits: '250'
      },
      toll_free: {
        price: '10.00',
        credits: '250'
      }
    }
  }
];
