import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, Spinner } from 'reactstrap';

const DowngradeModal = ({ isOpen, toggle, onConfirm, isProcessing }) => {
  // Define styles for overlay and spinner
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)', // Optional: add a semi-transparent background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem',
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff',
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={isProcessing ? undefined : toggle}
      centered
      backdrop={isProcessing ? 'static' : true}
      keyboard={!isProcessing}
    >
      <ModalHeader toggle={isProcessing ? undefined : toggle}>
        Confirm Downgrade
      </ModalHeader>
      <ModalBody>
        <p>
          You are about to downgrade your subscription to a lower plan. This
          will take effect at the start of your next billing cycle.
        </p>
        <p>
          Your current features will be available until then. Do you want to
          proceed with the downgrade?
        </p>
        <div className="d-flex justify-content-end">
          <Button
            color="secondary"
            onClick={toggle}
            className="me-2"
            disabled={isProcessing}
          >
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={onConfirm}
            disabled={isProcessing}
          >
            Confirm Downgrade
          </Button>
        </div>
        {isProcessing && (
          <div style={overlayStyle}>
            <Spinner style={spinnerStyle} />
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DowngradeModal;
