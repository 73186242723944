import { LIST_ACTIVE_CHAT, UPDATE_ACTIVE_CHAT } from '../actions/types';

export default function (state = '', action) {
  switch (action.type) {
    case LIST_ACTIVE_CHAT: // could have issue with empty string may need to do action.payload || false
      return action.payload;

    case UPDATE_ACTIVE_CHAT:
      return action.payload;

    default:
      return state;
  }
}
