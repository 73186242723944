import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  Col,
  Media,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Label,
  Spinner,
  Form,
  FormGroup,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { useSelector, useDispatch } from 'react-redux';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import CallDetails from './CallDetails';
import { CALLS_DELETE_RESET, SET_CONTACTS } from 'actions/types';
import {
  listCalls,
  listCallsDateRange,
  deleteCalls,
  listCallsCustomDateRange
} from 'actions/callActions';
import ActionPopover from 'components/common/ActionPopover';

const CallsTable = () => {
  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const twilioCalls = useSelector(state => state.calls);
  const { calls: calls, loading: callsLoading, totalCalls: totalCalls } = twilioCalls;

  console.log('calls', twilioCalls);

  const callsDelete = useSelector(state => state.callsDelete);
  const { success: successDelete } = callsDelete;

  const selectedCallRows = useSelector((state) => state.selectedRows)
  console.log("selectedCallRows", selectedCallRows)


  const [isOpen, setIsOpen] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  console.log("isSelected", isSelected)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCallDetailsModal, setShowCallDetailsModal] = useState(false);
  const [callDetails, setCallDetails] = useState(false);
  const [dateRange, setDateRange] = useState('All calls');
  const [customDateModal, setCustomDateModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [datesObj, setDatesObj] = useState({ start: '', end: '' });
  const [rowDelete, setRowDelete] = useState();

  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original.sid;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  const closeDeleteBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setShowDeleteModal(!showDeleteModal);
      }}
    >
    </button>
  );

  const closeDateRangeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setCustomDateModal(!customDateModal);
      }}
    >
    </button>
  );

  const closeCallDetailsBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowCallDetailsModal(!showCallDetailsModal);
      }}
    >
    </button>
  );

  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const fromFormatter = rowData => {
    const { from } = rowData.row.original;
    return (
      <Media body className="mt-1">
        <h5 className="mb-0 fs-9">
          {from}
        </h5>
      </Media>
    );
  };

  const toFormatter = rowData => {
    const { to } = rowData.row.original;
    return (
      <Media body className="mt-1">
        <h5 className="mb-0 fs-9">
          {to}
        </h5>
      </Media>
    );
  };

  const directionFormatter = rowData => {
    const { direction } = rowData.row.original;
    return (
      <div>
        <Media body className="mt-1">
          {direction === 'outbound-dial' ? (
            <SubtleBadge bg="primary" pill className="fs--1">
              <FontAwesomeIcon icon="arrow-right" transform="shrink-1" />
              {`  Outgoing`}
            </SubtleBadge>
          ) : (
            <SubtleBadge bg="warning" pill className="fs--1">
              <FontAwesomeIcon icon="arrow-left" transform="shrink-1" />
              {`  Incoming`}
            </SubtleBadge>
          )}
        </Media>
      </div>
    );
  };

  const createdAtFormatter = rowData => {
    const { dateCreated } = rowData.row.original;
    return (
      <div>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9 mt-2">
              <Moment format="MM/DD/YY" date={dateCreated} />
            </h5>
            <h6 className="mb-0 fs--1 mt-1 text-muted">
              <Moment format="hh:mm A" date={dateCreated} />
            </h6>
          </Media>
        </Media>
      </div>
    );
  };

  const formatDuration = (seconds) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const callDurationFormatter = rowData => {
    const { duration } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2">
            {duration ? (
              <div>{formatDuration(duration)}</div>
            ) : (
              <div>00:00:00</div>
            )}
          </h5>
        </Media>
      </Media>
    );
  };





  const actionFormatter = (rowData) => {
    const { sid } = rowData.row.original;
    const items = [
      {
        label: 'Call details',
        icon: 'phone',
        action: () => {
          return (
            setCallDetails(rowData.row.original),
            setShowCallDetailsModal(!showCallDetailsModal)
          );
        }
      },
      {
        label: 'Delete Call',
        icon: 'trash',
        color: 'danger',
        action: () => {
          return (
            setRowDelete([sid]), setShowDeleteModal(!showDeleteModal)
          );
        },
      }
    ].filter(Boolean);

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={sid}
          items={items}
        />
      </div>
    );
  };




  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'dateCreated',
      Header: 'Call time',
      Cell: createdAtFormatter
    },
    {
      accessor: 'to',
      Header: 'To',
      Cell: toFormatter
    },
    {
      accessor: 'from',
      Header: 'From',
      Cell: fromFormatter
    },
    {
      accessor: 'direction',
      Header: 'Direction',
      Cell: directionFormatter
    },
    {
      accessor: 'duration',
      Header: 'Call Length',
      Cell: callDurationFormatter
    },
    {
      accessor: 'actions',
      Header: 'Actions', // Added Header
      Cell: actionFormatter,
      sticky: 'right',
    }
  ];

  //--------------------------------------------------------------------------Handlers------------------------------------------------------------------------------------

  const handleDateChange = target => {
    let name = target.name;
    const selectedDateISO = DateTime.fromISO(target.value);
    const timeObj = {
      year: selectedDateISO.c.year,
      month: selectedDateISO.c.month,
      day: selectedDateISO.c.day,
      hour: selectedDateISO.c.hour,
      minute: selectedDateISO.c.minute
    };
    let TzdateTime = DateTime.fromObject(timeObj, { zone: timezone }).toISO();
    setDatesObj({ ...datesObj, [name]: TzdateTime });
  };

  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  useEffect(() => {
    setRowDelete([]);
  }, []);

  useEffect(() => {
    console.log(' dateRange', dateRange);
    if (dateRange !== 'All calls' && dateRange !== 'custom') {
      dispatch(listCallsDateRange({ dateRange: dateRange, page: 1, limit: 10 }));
    } else if (dateRange === 'All calls') {
      dispatch(listCalls({ search: "", page: 1, limit: 10 }));
    }
  }, [dateRange, successDelete]);

  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'dateCreated'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listCalls({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: CALLS_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listCalls({ search: value, page: 1, limit: 25 })
    );
  };



  const years = Array.from(
    new Array(101),
    (_, index) => new Date().getFullYear() - 100 + index
  );

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };




  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon onClick={() => { return dispatch({ type: SET_CONTACTS, payload: selectedCallRows.map((el) => { return el.original }) }), navigate('/') }} icon="sms" disabled={true} transform="shrink-3" className="btn-tertiary border-dark" size="sm">
                Send SMS
              </ButtonIcon>
              <ButtonIcon icon="trash" onClick={() => { return setShowDeleteModal(!showDeleteModal) }} disabled={!isSelected.length} transform="shrink-1" className="btn-tertiary border-dark text-danger mx-2" size="sm" >
                Delete
              </ButtonIcon>

            </ButtonGroup>
            <Dropdown isOpen={isOpen} toggle={() => { setIsOpen(!isOpen) }}>
              <DropdownToggle className='btn-tertiary border-dark fs-10 mt-2' color="falcon-warning" > <FontAwesomeIcon icon="calendar-alt" transform="shrink-3" className="me-1" />Date Range</DropdownToggle>
              <DropdownMenu className="border py-2 mr-6 mr-xxl-8">
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="All calls">{`All calls`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Today">{`Today`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Yesterday">{`Yesterday`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This week">{`This week`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="This month">{`This month`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last month">{`Last month`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange(e.target.value) }} value="Last 2 months">{`Last 2 months`}</DropdownItem>
                <DropdownItem onClick={(e) => { return setDateRange("custom"), setCustomDateModal(!customDateModal) }}>{`Custom date range`}</DropdownItem>


              </DropdownMenu>
            </Dropdown>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {callsLoading && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={calls}
            fetchData={fetchData}
            total={totalCalls}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox table
                  placeholder="Search calls..."
                  onSearch={handleSearch}
                />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold overflow-hidden'
              }}
            />


            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalCalls}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>



      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center"
          close={closeDeleteBtn}
        >
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    dispatch(deleteCalls(rowDelete));
                  } else {
                    dispatch(deleteCalls(isSelected));
                  }
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="btn-tertiary border text-danger"
              >
                Delete Call
              </Button>
              <Button
                onClick={() => {
                  return (
                    setShowDeleteModal(!showDeleteModal),
                    setRowDelete([])
                  );
                }}
                block
                className="btn-tertiary mt-2"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal
        isOpen={showCallDetailsModal}
        centered
        toggle={() => setShowCallDetailsModal(!showCallDetailsModal)}
      >
        <ModalHeader
          className="d-flex justify-content-center"
          close={closeCallDetailsBtn}
        >
          Call details
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <CallDetails callDetails={callDetails} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>



      <Modal isOpen={customDateModal} centered toggle={() => setCustomDateModal(!customDateModal)}>
        <ModalHeader toggle={() => setCustomDateModal(!customDateModal)}>Set Date Range</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="startDate">Start Date</Label>
              <InputGroup>
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(date);
                      handleDateChange({
                        name: 'start',
                        value: date.toISOString(),
                      });

                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select Start Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>


            <FormGroup>
              <Label for="endDate">End Date</Label>
              <InputGroup >
                <InputGroupText>
                  <FontAwesomeIcon icon="calendar-alt" />
                </InputGroupText>
                <DatePicker
                  autocomplete="off"
                  selected={endDate}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(date);
                      handleDateChange({
                        name: 'end',
                        value: date.toISOString(),
                      });
                    }
                  }}
                  dateFormat="MM-dd-yyyy"
                  placeholderText="Select End Date"
                  className="form-control"
                  id="startDate"
                  renderDayContents={(day, date) => (
                    <span style={{ fontSize: '1.2em', padding: '0.5em' }}>{day}</span>
                  )}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <button
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'<'}
                      </button>
                      <select
                        value={date.getFullYear()}
                        onChange={({ target: { value } }) => changeYear(value)}
                        style={{ fontSize: '1em', marginLeft: 8, marginRight: 8 }}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <select
                        value={months[date.getMonth()]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                        style={{ fontSize: '1em', marginRight: 8 }}
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <button
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        style={{
                          fontSize: '1.5em',
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {'>'}
                      </button>
                    </div>
                  )}
                  withPortal
                  todayButton="Today"
                />
              </InputGroup>
            </FormGroup>



            <div className="d-flex justify-content-end mt-4">
              <Button
                color="secondary"
                onClick={() => setCustomDateModal(!customDateModal)}
                className="me-2"
              >
                Cancel
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  dispatch(listCallsCustomDateRange({
                    dateRange: { start: startDate, end: endDate },
                    page: 1,
                    limit: 10,
                    sortBy: 'dateCreated',
                    sortOrder: -1,
                  }));
                  setCustomDateModal(!customDateModal);
                }}
                disabled={!datesObj.start || !datesObj.end}
              >
                Search
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>










    </Fragment>
  );
};

export default CallsTable;
