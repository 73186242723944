import {
  LOAD_ESTIMATE,
  UPDATE_ESTIMATE,
  CLEAR_ESTIMATE,
  ESTIMATE_DETAILS_REQUEST,
  ESTIMATE_DETAILS_SUCCESS,
  ESTIMATE_DETAILS_FAIL
} from '../actions/types';

const initialState = {
  customer: null, // Customer details
  estimateNumber: null,
  lineItems: [
    { name: '', description: null, qty: null, unitPrice: null, total: null }
  ],
  clientMessage: '',
  subTotal: 0,
  discount: 0,
  taxName: '',
  taxRate: 0,
  total: 0,
  internalNotes: '',
  attachments: [],
  requiredDeposit: 0,
  discountValue: 0,
  discountType: 'percentage',
  depositValue: 0,
  depositType: 'percentage',
  status: 'Draft',
  notesAndAttachments: [],
  // ... [other fields from the estimate schema]
  isLoading: false, // To track loading state
  error: null // To track any errors
};

const estimateReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ESTIMATE:
      return {
        ...state,
        ...action.payload, // Spread the estimate details into the state
        isLoading: false
      };
    case UPDATE_ESTIMATE: // this is called when the user is actively creating or editing an estimate, we use this instad of setState.
      return {
        ...state,
        ...action.payload, // Spread the updated fields into the state
        isLoading: false,
        error: null
      };
    case CLEAR_ESTIMATE:
      return initialState; // Reset to the initial state
    case ESTIMATE_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case ESTIMATE_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: null,
      };
    case ESTIMATE_DETAILS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default estimateReducer;
