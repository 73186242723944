import {
  LIST_FIELDS_REQUEST,
  LIST_FIELDS_SUCCESS,
  LIST_FIELDS_FAIL
} from '../actions/types';

const initialState = {
  fields: [],
  totalItems: 0,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_FIELDS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_FIELDS_SUCCESS:
      return {
        ...state,
        fields: action.payload.fields,
        totalItems: action.payload.totalItems,
        loading: false
      };
    case LIST_FIELDS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
