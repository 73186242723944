import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DLCUploadContext } from 'context/Context';
import { listBrand } from 'actions';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const DLCWizardProvider = ({ children }) => {
  const [upload, setUpload] = useState({
    businessName: 'Test business name',
    businessType: "Corporation",
    companyType: "private",
    industry: 'ENERGY',
    businessWebsite: 'https://www.testwebsite.com',
    businessRegistrationNumber: '123456789',
    businessStreet: '17 Lynn rd',
    businessCity: 'Englewood',
    businessStateProvinceRegion: 'CO',
    businessPostalCode: '80110',
    businessCountry: 'US',
    businessContactFirstName: 'IBe',
    businessContactLastName: 'onOne',
    businessContactPhone: '+17209775847',
    businessContactEmail: 'this@here.com',
    usAppToPersonUseCase: "DELIVERY_NOTIFICATION",
    description: "This is a test description of what this sms campaign does. is it signing up a user for a service or sending them notifications?",
    messageFlow: 'message flow ==== this is how the consumer opts out and how you give consent and things like that 40 character minimum',
    canadianTraffic: 'No',
    optInType: 'VERBAL',
    messageSamples: [
      { id: 1, text: '[Enter your sample message] Reply STOP to opt out.' },
      { id: 2, text: '[Enter your sample message] Reply STOP to opt out.' }
    ],
    optInKeywords: 'JOIN',
    optInMessage:
      ' Hello, welcome to our campaign. Msg freq varies. Msg&data rates may apply. Reply STOP to end, HELP for help.',
    optOutKeywords: 'STOP',
    optOutMessage:
      'Your Company: You have successfully unsubscribed & will no longer receive any additional messages. Text HELP for assistance or JOIN to rejoin.',
    helpKeywords: 'HELP',
    helpMessage:
      'Your Company: For support, please visit https://www.yourWebsite.com, or contact us at JohnDoe@gmail.com or call 11234567890'
  });
  const [formattedEIN, setFormattedEIN] = useState('');

  const location = useLocation();
  const { fromDLCTable, DLCPhoneData } = location.state || {};

  const { brand, loading: brandLoading } = useSelector(state => state.brand);

  const formatEIN = value => {
    // Remove non-digit characters
    value = value.replace(/\D/g, '');

    // Format the EIN as 12-3454433
    if (value.length > 2) {
      value = value.slice(0, 2) + '-' + value.slice(2);
    }

    // Limit the input to 10 characters (9 digits + 1 hyphen)
    return value.slice(0, 10);
  };

  useEffect(() => {
    if (fromDLCTable && DLCPhoneData) {
      setUpload({
        ...upload,
        DLCPhoneNumberSid: DLCPhoneData?.sid,
        DLCPhoneNumber: DLCPhoneData?.phoneNumber
      });
    }
  }, []);

  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listBrand());
  }, []);

  useEffect(() => {
    if (!brandLoading && brand?.length) {
      if (brand[0]?.businessRegistrationNumber) setFormattedEIN(formatEIN(brand[0]?.businessRegistrationNumber));
      setUpload({
        ...upload,
        businessName: brand[0]?.businessName,
        businessType: brand[0]?.businessType,
        industry: brand[0]?.industry,
        businessWebsite: brand[0]?.businessWebsite,
        businessStreet: brand[0]?.address.businessStreet,
        businessCity: brand[0]?.address.businessCity,
        businessPostalCode: brand[0]?.address.businessPostalCode,
        businessStateProvinceRegion:
          brand[0]?.address.businessStateProvinceRegion,
        businessCountry: brand[0]?.address.businessCountry,
        businessContactFirstName:
          brand[0]?.contactDetails.businessContactFirstName,
        businessContactLastName:
          brand[0]?.contactDetails.businessContactLastName,
        businessContactPhone: brand[0]?.contactDetails.businessContactPhone,
        businessContactEmail: brand[0]?.contactDetails.businessContactEmail,
        businessRegistrationNumber: brand[0]?.businessRegistrationNumber,
        companyType: brand[0]?.companyType
      });
    }
  }, [brand?.length]);

  const handleInputChange = e => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      // Handle file upload logic here
      const formData = new FormData();
      formData.append(name, files[0]);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/upload-optin-screenshot`,
          formData
        )
        .then(response => {
          setUpload({ ...upload, [name]: response.data.url });
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
    } else {
      setUpload({ ...upload, [name]: value });
    }
  };

  const value = {
    upload,
    setUpload,
    formattedEIN,
    setFormattedEIN,
    step,
    setStep,
    upload,
    setUpload,
    handleInputChange
  };
  return (
    <DLCUploadContext.Provider value={value}>
      {children}
    </DLCUploadContext.Provider>
  );
};

export default DLCWizardProvider;
