import React, { useEffect, Fragment, useState, useCallback, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Divider from 'components/common/Divider';
import Loader from 'components/common/Loader';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { listNumbers, deleteMultipleNumbers, editNickname, makePrimaryNumber } from 'actions/index';
import { NUMBER_DELETE_RESET, PRIMARY_NUMBER_UPDATE_RESET } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';


const NumbersTable = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNicknameModal, setShowNicknameModal] = useState(false);
  const [nickName, setNickname] = useState({ title: "" })
  const [rowDelete, setRowDelete] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [tempId, setTempId] = useState()

  const closeNicknameBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowNicknameModal(!showNicknameModal) }}>
    </button>
  );

  const numbers = useSelector((state) => state.numbers)
  const primaryNumberUpdate = useSelector((state) => state.primaryNumberUpdate)
  const { loading: loadingPrimaryUpdate, success: successPrimaryUpdate } = primaryNumberUpdate

  const numberCreate = useSelector((state) => state.createNumber)
  const { success: successCreate } = numberCreate

  const numberDelete = useSelector((state) => state.deleteNumber)
  const { success: successDelete, loading: loadingDelete } = numberDelete

  const numberUpdate = useSelector((state) => state.updateNumber)
  const { success: successUpdate } = numberUpdate


  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setRowDelete([]) },
    [setIsSelected]);


  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }


  useEffect(() => {
    if (successPrimaryUpdate) {
      dispatch(listNumbers())
      dispatch({ type: PRIMARY_NUMBER_UPDATE_RESET })
    }
  }, [successPrimaryUpdate])


  const numberFormatter = (rowData) => {
    const { phoneNumber } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-9"><strong>{formatPhoneNumber(phoneNumber)}</strong></h5>
        </Media>
      </Media>
    );
  };

  const nickNameFormatter = (rowData) => {
    const { nickName } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-9">{nickName}</h5>
        </Media>
      </Media>
    );
  };

  const primaryFormatter = (rowData) => {
    const { _id, phoneNumber, primary } = rowData.row.original


    return (
      <Link to="#" onClick={() => { return setTempId({ tempId: _id, phoneNumber: phoneNumber }) }}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2 mt-1">
            {loadingPrimaryUpdate ? <Spinner /> :
              <div>
                {
                  primary.US ?
                    <div>
                      <SubtleBadge bg="success" pill className="fs-10">
                        <FontAwesomeIcon icon="check" transform="shrink-1" className="me-1" />
                        Chat number
                      </SubtleBadge>
                    </div> :
                    <div onClick={() => { dispatch(makePrimaryNumber(_id)) }} >
                      <SubtleBadge bg="danger" pill className="fs-10">
                        <FontAwesomeIcon icon="plus" transform="shrink-1" className="me-1" />
                        Make chat number
                      </SubtleBadge>
                    </div>
                }
              </div>
            }
          </Media>
        </Media>
      </Link>
    );
  };



  const priceFormatter = (rowData) => {
    const { creditCost } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-10">{`$${(creditCost * 0.04).toFixed()}/mo`}</h5>
        </Media>
      </Media>
    );
  };

  const renewalFormatter = (rowData) => {
    const { renewalDate } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-10 mt-2"><Moment format="MM/DD/YYYY" date={renewalDate} /></h5>
        </Media>
      </Media>
    );
  };



  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;
    const items = [
      {
        label: 'Edit',
        icon: 'edit',
        action: () => { return setShowNicknameModal(!showNicknameModal), setTempId({ tempId: _id }) }
      },
      {
        label: 'Cancel number',
        icon: 'trash',
        color: 'danger',
        action: () => { return setShowCancelModal(!showCancelModal), setRowDelete([_id]) },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };




  const columns = [
    {
      accessor: 'number',
      Header: 'Number',
      Cell: numberFormatter
    },
    {
      accessor: 'nickName',
      Header: 'Nickname',
      Cell: nickNameFormatter
    },
    {
      accessor: 'isPrimary',
      Header: 'Chat number',
      Cell: primaryFormatter
    },
    {
      accessor: 'creditCost',
      Header: 'Price',
      Cell: priceFormatter
    },
    {
      accessor: 'renewal-date',
      Header: 'Renewal date',
      Cell: renewalFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',

    }
  ];


  const handleNickname = (nickName, tempId) => {
    dispatch(editNickname(nickName, tempId))
    setShowNicknameModal(!showNicknameModal)

  }

  const handleCancelNumber = () => {
    if (window.confirm("Are you sure? Numbers will be permenantly deleted this cannot be undone")) {
      if (rowDelete?.length) {
        return dispatch(deleteMultipleNumbers(rowDelete))
      } else {
        return dispatch(deleteMultipleNumbers(isSelected))
      }
    }
  }

  useEffect(() => {
    if (successDelete) {
      setShowCancelModal(false)
      dispatch({ type: NUMBER_DELETE_RESET })
    }
  }, [successDelete])


  useEffect(() => {
    dispatch(listNumbers())
  }, [successCreate, successDelete, successUpdate])

  const mounted = useRef(true)

  useEffect(() => {

    if (!mounted.current && numbers.length === 0) {
      navigate('/follow-up/compliance/toll-free')
    }
    return () => { mounted.current = false }
  }, [numbers])







  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <Fragment>
              <ButtonGroup className="mt-2">
                <ButtonIcon icon="plus" tag={Link} to="/follow-up/virtual-number" transform="shrink-3" className="btn-tertiary border-dark" size="sm">
                  New number
                </ButtonIcon>
                <ButtonIcon icon="trash" onClick={() => { return setShowCancelModal(!showCancelModal) }} disabled={!isSelected.length} transform="shrink-3" className="btn-tertiary border-dark text-danger ms-2" size="sm" >
                  Delete number
                </ButtonIcon>
              </ButtonGroup>
            </Fragment>
          </div>
        </CardHeader>
        <CardBody className="p-0">

          {numbers.loading ? (
            <div>
              <Loader />
            </div>
          ) : (

            <Fragment>
              <Card>

                <AdvanceTableWrapper
                  columns={columns}
                  data={numbers}
                  sortable
                  pagination
                  perPage={12}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}

                >
                  <Row>
                    <Col md={8} lg={6}>
                      <AdvanceTableSearchBox table />
                    </Col>
                  </Row>


                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs--1 fw-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pe-2 ps-3 ">
                    <AdvanceTableFooter
                      rowCount={numbers?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>

              </Card>
            </Fragment>
          )
          }
        </CardBody>
      </Card>

      {//------------------------------FORWARD SETTINGS MODAL--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }

      {//------------------------------VOICEMAIL MODAL--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      {//------------------------------Edit Nickname Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal isOpen={showNicknameModal} centered toggle={() => { setShowNicknameModal(!showNicknameModal) }} >
        <ModalHeader className="text-center d-flex flex-between-center border-bottom-0" close={closeNicknameBtn}>
          Edit Nickname
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label for="title">Nickname:</Label>
              <Input placeholder="Enter nickname" value={nickName.title.value} onChange={(e) => { setNickname({ ...nickName, [e.target.name]: e.target.value }) }} className="mb-3" name="title" id="title" />
              <Button block disabled={!nickName.title} color="primary" className="mb-3" onClick={() => { handleNickname(nickName, tempId) }} type="submit">Save</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {//------------------------------Campaign Registration--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal centered >
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">{"This will Deactivate forwarding for this number"}</Label>
              <Divider></Divider>
              <Button block className="text-danger" color="light">{"Deactivate forwarding"}</Button>
              <Button block className="text-primary" color="light" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {//------------------------------Cancel Number Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal
        isOpen={showCancelModal}
        centered
        toggle={() => setShowCancelModal(!showCancelModal)}
      >
        <ModalHeader className="text-center">
          Confirm Number Deletion
        </ModalHeader>
        <ModalBody>
          <Card className="border-0">
            <CardBody className="text-center">
              <p>
                <strong>Warning:</strong> Deleting this number will permanently remove it from your account. This action cannot be undone.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  color="danger"
                  onClick={handleCancelNumber}
                  disabled={loadingDelete}
                  className="me-2"
                >
                  {loadingDelete ? <Spinner size="sm" /> : 'Delete Number'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setShowCancelModal(!showCancelModal)}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};


export default NumbersTable;
