import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardBody, CardTitle, CardText, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const PreviewMedia = ({ match }) => {
  const [file, setFile] = useState();
  const [fileType, setFileType] = useState();

  const downloadNames = {
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    pdf: 'PDF',
    text: 'Text File',
    download: 'File',
    unknown: 'File'
  };

  const downloadName = downloadNames[fileType] || 'File';

  useEffect(() => {
    try {
      axios({
        url: `${process.env.REACT_APP_API_URL}/api/media-fetch/${match.params.imageID}`,
        method: 'GET'
      }).then(res => {
        setFile(res.data.mediaUrl);
        const fileExtension = res.data.mediaUrl.split('.').pop().toLowerCase();
        if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
          setFileType('image');
        } else if (['mp4', 'mov', 'avi'].includes(fileExtension)) {
          setFileType('video');
        } else if (['mp3', 'wav'].includes(fileExtension)) {
          setFileType('audio');
        } else if (fileExtension === 'pdf') {
          setFileType('pdf');
        } else if (['txt', 'csv', 'vcf'].includes(fileExtension)) {
          setFileType('text');
        } else if (
          ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension)
        ) {
          setFileType('download');
        } else {
          setFileType('unknown');
        }
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Check this out</title>
        <meta name="description" content="Check this out" />
        <meta property="og:image" content={file} />
      </Helmet>

      <Card
        style={{
          maxWidth: '600px',
          margin: '30px auto',
          padding: '20px',
          boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
        }}
      >
        <CardBody className="text-center">
          <CardTitle tag="h2" className="mb-4">
            Your File is Ready for Download
          </CardTitle>
          {fileType === 'image' && (
            <img src={file} style={{ maxWidth: '100%', height: 'auto' }} />
          )}
          {fileType === 'video' && (
            <div
              style={{
                position: 'relative',
                paddingBottom: '56.25%',
                height: 0,
                overflow: 'hidden',
                maxWidth: '100%'
              }}
            >
              <video
                src={file}
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%'
                }}
                controls
              />
            </div>
          )}
          {fileType === 'audio' && <audio src={file} controls />}
          {fileType === 'pdf' && (
            <embed src={file} type="application/pdf" width="500" height="800" />
          )}
          {fileType === 'text' && (
            <a href={file} target="_blank" rel="noopener noreferrer">
              Open text file
            </a>
          )}
          {fileType === 'download' && (
            <p>This file needs to be downloaded to view</p>
          )}
          {fileType === 'unknown' && <p>Unsupported file type</p>}
          <CardText className="mt-4">
            Rest assured, your file is safe and ready to download.
          </CardText>
          <a href={file} download>
            <Button color="primary" size="lg" className="my-3 text-white">
              <FontAwesomeIcon icon={faDownload} className="mr-2" />
              Download {downloadName}
            </Button>
          </a>
        </CardBody>
      </Card>
    </div>
  );
};

export default PreviewMedia;
