import { UPDATE_TEAM_DETAILS } from '../actions/types';

// Initial state for updating team details
const initialTeamUpdateState = {
  success: false,
  loading: false,
  error: null
};

// Reducer for updating team details
export const teamUpdateReducer = (state = initialTeamUpdateState, action) => {
  switch (action.type) {
    case UPDATE_TEAM_DETAILS:
      return {
        ...state,
        success: true,
        loading: false
      };
    // Add cases for other actions like UPDATE_TEAM_DETAILS_REQUEST and UPDATE_TEAM_DETAILS_FAILURE if needed
    default:
      return state;
  }
};
