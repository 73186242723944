import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Label,
  Badge,
  Progress,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faUsers, faComment, faCreditCard, faList, faPhone, faHashtag } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Loader from '../common/Loader';

const CampaignPreview = ({ messageContents, messageDetails }) => {
  const [currentMessage, setCurrentMessage] = useState({ index: 0 });
  const [allGroupContacts, setAllGroupContacts] = useState(0);

  const plainText = messageContents?.plainText;
  const bulkNumber = messageDetails?.bulkNumber
    ? JSON.parse(messageDetails.bulkNumber)
    : null;

  useEffect(() => {
    if (messageDetails) {
      let totalContacts = messageDetails?.contacts?.length || 0;
      messageDetails?.groups.forEach((group) => {
        totalContacts += group.contactsCount || 0;
      });
      setAllGroupContacts(totalContacts);
    }
  }, [messageDetails]);

  const finalMessages = plainText ? [plainText] : [];
  const currentMessageText =
    finalMessages.length > 0
      ? finalMessages[currentMessage.index]?.slice(0, 153)
      : '';

  const totalSegments = Math.ceil(currentMessageText.length / 153);
  const creditCost = totalSegments * allGroupContacts;

  const nextMessage = () =>
    setCurrentMessage({
      index: (currentMessage.index + 1) % finalMessages.length,
    });

  const previousMessage = () =>
    setCurrentMessage({
      index:
        currentMessage.index === 0
          ? finalMessages.length - 1
          : currentMessage.index - 1,
    });

  return (
    <div>
      <Card className="mb-3">
        <CardBody>
          {/* Message Preview Section */}
          <div className="text-center mb-4">
            <Label className="text-uppercase text-muted mb-3 d-block">
              <FontAwesomeIcon icon={faComment} className="me-2 text-primary" />
              Message Preview
            </Label>
            {finalMessages.length === 0 ? (
              <Loader />
            ) : (
              <div
                className={classNames(
                  'border rounded p-3 bg-primary text-white shadow-sm',
                  { 'text-muted': finalMessages.length === 0 }
                )}
                style={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  minHeight: '100px',
                  textAlign: 'left', // Align text to the left
                  lineHeight: '1.5', // Adjust line height for readability
                  display: 'block',  // Ensure it behaves like a block element
                  whiteSpace: 'pre-wrap', // Preserve spaces and line breaks
                  wordBreak: 'break-word', // Break long words to fit within the container
                }}
              >
                {currentMessageText || 'No message content available.'}
              </div>

            )}

            {/* Navigation Arrows */}
            <div className="d-flex justify-content-center mt-3">
              <Button
                color="link"
                onClick={previousMessage}
                className="text-primary mx-3"
                disabled={finalMessages.length === 0}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </Button>
              <Button
                color="link"
                onClick={nextMessage}
                className="text-primary mx-3"
                disabled={finalMessages.length === 0}
              >
                <FontAwesomeIcon icon={faArrowRight} size="lg" />
              </Button>
            </div>
          </div>

          {/* Recipient Info */}
          <div className="mb-4">
            <Label className="text-uppercase text-muted">
              <FontAwesomeIcon icon={faUsers} className="me-2 text-success" />
              Recipients
            </Label>
            <div className="d-flex align-items-center mt-2">
              {messageDetails?.contacts?.length > 0 && (
                <Badge color="primary" className="me-2">
                  {messageDetails.contacts.length} Contact
                  {messageDetails.contacts.length > 1 ? 's' : ''}
                </Badge>
              )}
              {messageDetails?.groups.map((group) => (
                <Badge key={group._id} color="success" className="me-2">
                  {group.title} ({group.contactsCount})
                </Badge>
              ))}
            </div>
          </div>

          {/* Sender Info */}
          <div className="mb-4">
            <Label className="text-uppercase text-muted">
              <FontAwesomeIcon icon={faPhone} className="me-2 text-warning" />
              Sender
            </Label>
            <div className="d-flex align-items-center mt-2">
              <FontAwesomeIcon icon={faHashtag} className="me-2" />
              <div>
                <strong>{bulkNumber?.phoneNumber || 'Unknown Number'}</strong>
                <div className="text-muted">{bulkNumber?.nickName}</div>
              </div>
            </div>
          </div>

          {/* Message Stats */}
          <div className="p-4 rounded shadow-sm">
            <Row>
              <Col sm="4" className="text-center">
                <Label className="text-uppercase text-muted">
                  <FontAwesomeIcon icon={faComment} className="me-2" />
                  Length
                </Label>
                <h5>{currentMessageText.length} Characters</h5>
                <Progress
                  value={(currentMessageText.length / 160) * 100}
                  color="primary"
                  className="mt-2"
                />
              </Col>
              <Col sm="4" className="text-center">
                <Label className="text-uppercase text-muted">
                  <FontAwesomeIcon icon={faList} className="me-2" />
                  Segments
                </Label>
                <h5>
                  {totalSegments} Message
                  {totalSegments > 1 ? 's' : ''}
                </h5>
              </Col>
              <Col sm="4" className="text-center">
                <Label className="text-uppercase text-muted">
                  <FontAwesomeIcon icon={faCreditCard} className="me-2" />
                  Credit Cost
                </Label>
                <h5>{creditCost} Credits</h5>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CampaignPreview;
