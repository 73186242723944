export default [
  {
    question: 'What is a toll-free number?',
    answer:
      'A toll-free number is a phone number that allows callers to reach businesses without being charged for the call. Instead, the business incurs the charges. These numbers typically have prefixes like 800, 888, 877, 866, 855, 844, or 833.'
  },
  {
    question:
      'Are there any specific requirements to register a toll-free number?',
    answer:
      'Registration requirements for toll-free numbers include providing valid business information and sometimes undergoing a vetting process to ensure the number is used for legitimate business purposes.'
  },
  {
    question: 'How long does it take to get a toll-free number activated?',
    answer:
      'Activation times can vary depending on the provider, but most toll-free numbers can be activated within a few hours to a couple of business days after registration is complete.'
  },
  {
    question: 'Can I port my existing toll-free number to your app?',
    answer:
      'Yes, we allow you to port your existing toll-free number. You will need to complete a porting request and provide authorization to transfer the number from your current provider.'
  },
  {
    question:
      'Is it possible to have multiple toll-free numbers for different departments?',
    answer:
      'Yes, you can register multiple toll-free numbers for various departments or use cases within your business. Each number can have its own routing and handling rules.'
  },
  {
    question:
      'What are the costs associated with registering and using a toll-free number?',
    answer:
      '$10/mo for each toll-free number, 0.04 to send an SMS, 0.04 per minute on calls. Toll free campaigns are free to register.'
  },
  {
    question: 'Can toll-free numbers be used for text messaging?',
    answer:
      'Many toll-free numbers are enabled for SMS and can be used to send and receive text messages with customers, providing a unified voice and messaging solution for your business.'
  },
  {
    question: 'How do I ensure my toll-free number complies with regulations?',
    answer:
      'To comply with regulations, you should use the toll-free number for legitimate business communications, adhere to telemarketing rules, and provide opt-out mechanisms for marketing messages. We can also handle all this for you with our agency plan.'
  },
  {
    question: 'What happens if I no longer need my toll-free number?',
    answer:
      "If you no longer need your toll-free number, you can delete in the Numbers section. This action is not reversible, and the number will be released back to the available pool."
  },
  {
    question: 'Can I use a toll-free number for international calls?',
    answer:
      'Toll-free numbers are generally country-specific and may not be reachable from outside their home country. However, some services offer international toll-free numbers (ITFN) for global reach.'
  }
];
