import axios from 'axios';
import {
  LIST_CALLS_REQUEST,
  LIST_CALLS_SUCCESS,
  LIST_CALLS_FAIL,
  CREATE_TOKEN_REQUEST,
  CREATE_TOKEN_SUCCESS,
  CREATE_TOKEN_FAIL,
  CALLS_DELETE_REQUEST,
  CALLS_DELETE_SUCCESS,
  CALLS_DELETE_FAIL,
  LIST_VOICEMAIL_REQUEST,
  LIST_VOICEMAIL_SUCCESS,
  LIST_VOICEMAIL_FAIL,
  VOICEMAIL_CREATE_REQUEST,
  VOICEMAIL_CREATE_SUCCESS,
  VOICEMAIL_CREATE_FAIL,
  VOICEMAIL_DELETE_REQUEST,
  VOICEMAIL_DELETE_SUCCESS,
  VOICEMAIL_DELETE_FAIL,
  CALL_CREATE_REQUEST
} from './types';

export const generateVoiceToken = () => async dispatch => {
  try {
    dispatch({ type: CREATE_TOKEN_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/generate`,
      method: 'POST',
      data: {},
      withCredentials: true
    }).then(res => {
      dispatch({ type: CREATE_TOKEN_SUCCESS, payload: res.data });
    });
  } catch (error) {
    dispatch({
      type: CREATE_TOKEN_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};


export const listCalls = (options = {}) => {
  return async function (dispatch) {

    // Add the query parameters to the URL
    // Base URL for the request
    const {
      search = '',
      page = 1,
      limit = 10,
      // users,
      sortBy = 'dateCreated',
      sortOrder = -1,
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/history-calls?`;

    // Append 'users' to the URL only if it's defined and not empty
    // if (users) {
    //   url += `users=${encodeURIComponent(users)}&`; // URL encode the users parameter
    // }

    // Append 'search' to the URL, ensuring it's URL-encoded
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_CALLS_REQUEST })

    return axios({
      url,
      method: "GET",
      withCredentials: true
    }).then(res => {
      dispatch({ type: LIST_CALLS_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_CALLS_FAIL, payload: error })
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  }
}


export const listCallsDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 10,
      sortBy = 'dateCreated',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_CALLS_REQUEST })

    let url = `${process.env.REACT_APP_API_URL}/api/calls-dr`;


    return axios({
      url,
      method: "POST",
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: LIST_CALLS_SUCCESS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: LIST_CALLS_FAIL, payload: error })
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};




export const listCallsCustomDateRange = (options = {}) => {
  return async function (dispatch) {
    const {
      dateRange = '',
      page = 1,
      limit = 25,
      sortBy = 'dateCreated',
      sortOrder = -1,
    } = options;

    dispatch({ type: LIST_CALLS_REQUEST })

    const res = await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calls-custom-dr`,
      method: 'POST',
      data: {
        dateRange,
        page,
        limit,
        sortBy,
        sortOrder,
      },
      withCredentials: true,
    }).then(res => {
      dispatch({ type: LIST_CALLS_SUCCESS, payload: res.data });
      return res.data; // Resolve promise with data
    })
      .catch(error => {
        dispatch({ type: LIST_CALLS_FAIL, payload: error })
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};




export const deleteCalls = isSelected => async dispatch => {
  try {
    dispatch({ type: CALLS_DELETE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/calls-delete`,
      method: 'POST',
      data: { isSelected },
      withCredentials: true
    });

    dispatch({ type: CALLS_DELETE_SUCCESS });
  } catch (error) {
    dispatch({
      type: CALLS_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};





export const createVoicemail = voicemail => async dispatch => {
  try {
    dispatch({ type: VOICEMAIL_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/upload-voicemail`,
      method: 'POST',
      data: voicemail,
      withCredentials: true
    });
    // this dispatch was not running because there was not response being sent from backend
    dispatch({ type: VOICEMAIL_CREATE_SUCCESS });
  } catch (error) {
    dispatch({
      type: VOICEMAIL_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listVoicemails = () => {
  return async function (dispatch) {
    dispatch({ type: LIST_VOICEMAIL_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/voicemails`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: LIST_VOICEMAIL_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: LIST_VOICEMAIL_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

export const deleteVoicemail = id => async dispatch => {
  try {
    dispatch({
      type: VOICEMAIL_DELETE_REQUEST
    });

    await axios.delete(`${process.env.REACT_APP_API_URL}/api/voicemail/${id}`, {
      withCredentials: true
    });

    dispatch({
      type: VOICEMAIL_DELETE_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: VOICEMAIL_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};
