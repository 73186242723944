import {
  LIST_TEMPLATES_REQUEST,
  LIST_TEMPLATES_SUCCESS,
  LIST_TEMPLATES_FAIL
} from '../actions/types';

const initialState = {
  templates: [],
  totalItems: 0,
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_TEMPLATES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.payload.templates,
        totalTemplates: action.payload.totalTemplates,
        loading: false
      };
    case LIST_TEMPLATES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}