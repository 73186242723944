/* eslint-disable react/prop-types */
import classNames from 'classnames';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const AdvanceTableFooter = ({
  rowCount,
  setPageSize,
  navButtons,
  page,
  pageSize,
  canPreviousPage,
  canNextPage,
  nextPage,
  previousPage,
  rowInfo,
  rowsPerPageSelection,
  rowsPerPageOptions = [10, 25, 50, 100],
  className,
  pageIndex
}) => {


  return (
    <div style={{ zIndex: 0 }}>
      <Flex
        className={classNames(
          className,
          'align-items-center justify-content-between'
        )}
      >
        <Flex alignItems="center" className="fs-9">
          {rowInfo && (
            <p className="mb-0">
              <span className="d-none d-sm-inline-block me-2">
                {pageSize * pageIndex + 1} to{' '}
                {pageSize * pageIndex + page.length} of {rowCount}
              </span>
            </p>
          )}
          {rowsPerPageSelection && (
            <>
              <p className="mb-0 mx-2">Rows:</p>
              <Form.Select
                size="sm"
                className="w-auto"
                onChange={e => {
                  return setPageSize(e.target.value);
                }}
                defaultValue={pageSize}
              >
                {rowsPerPageOptions.map(value => (
                  <option value={value} key={value}>
                    {value}
                  </option>
                ))}
              </Form.Select>
            </>
          )}
        </Flex>
        {navButtons && (
          <Flex>
            <Button
              size="sm"
              variant={canPreviousPage ? 'primary' : 'light'}
              onClick={() => previousPage()}
              className={classNames({ disabled: !canPreviousPage })}
            >
              <FontAwesomeIcon icon="chevron-left"></FontAwesomeIcon>
            </Button>
            <Button
              size="sm"
              variant={canNextPage ? 'primary' : 'light'}
              className={classNames(' ms-2', {
                disabled: !canNextPage
              })}
              onClick={() => {
                nextPage();
              }}
            >
              <FontAwesomeIcon icon="chevron-right"></FontAwesomeIcon>
            </Button>
          </Flex>
        )}
      </Flex>
    </div>
  );
};

export default AdvanceTableFooter;
