import React, { useEffect, useState, useContext } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ChatProvider from './ChatProvider';
import ChatSidebar from './sidebar/ChatSidebar';
import ChatContent from './content/ChatContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UPDATE_MESSAGE_STATUS, ADD_INCOMING_CHAT_MESSAGE, UPDATE_USER_CREDIT } from '../../actions/types';
import Flex from '../common/Flex';
import { SocketContext } from 'providers/SocketProvider';
import CallTimer from './content/CallTimer';
import { listNumbers } from '../../actions/index';
import { useAppContext } from 'providers/AppProvider';

const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = (match[1] ? '+1 ' : '');
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

const Chat = () => {
  const dispatch = useDispatch();
  const [mute, setMute] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [callStatus, setCallStatus] = useState();
  const [contactToCall, setContactToCall] = useState();
  const [chatNumber, setChatNumber] = useState();

  const { isNavbarVisible, setIsNavbarVisible } = useAppContext();

  const socket = useContext(SocketContext);
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    // Hide navbar by default on mobile devices
    if (window.innerWidth <= 575) {
      setIsNavbarVisible(false);
    }

    return () => {
      setIsNavbarVisible(true)
    }
  }, [setIsNavbarVisible]);

  useEffect(() => {
    if (socket) {
      socket.on('messageFromBackend', (dataFromServer) => {
        console.log("messageFromBackend socket", dataFromServer);
        socket.emit('messageToServer', { data: "this is from client" });
      });

      socket.on('newMessage', (webhookData) => {
        dispatch({ type: ADD_INCOMING_CHAT_MESSAGE, payload: webhookData });
      });

      socket.on('messageStatusUpdate', (msgStatusData) => {
        dispatch({ type: UPDATE_MESSAGE_STATUS, payload: msgStatusData });
      });

      socket.on('updateUserCredits', (user) => {
        dispatch({ type: UPDATE_USER_CREDIT, payload: user });
      });

      // Cleanup on unmount
      return () => {
        socket.off('messageFromBackend');
        socket.off('newMessage');
        socket.off('messageStatusUpdate');
        socket.off('updateUserCredits');
      };
    }
  }, [socket, dispatch]);

  useEffect(() => {
    dispatch(listNumbers());
  }, [dispatch]);

  console.log("callStatus in Chat.js", callStatus);


  return (
    <div className={`${!isNavbarVisible ? "pt-4" : ""}`}>
      <ChatProvider>
        {showCall && contactToCall ? (
          <Card className="mb-1">
            <Row className="pt-2 pb-2 rounded">
              <Col xs={10}>
                <div className="ms-2">
                  <h6 className="mb-0 chat-contact-title bold"><strong>{contactToCall?.firstName + " " + contactToCall?.lastName}</strong></h6>
                  <h6 className="mb-0 chat-contact-title bold mt-1">{formatPhoneNumber(contactToCall?.phone_number)}</h6>
                  <span className="fs-10">
                    {callStatus === "calling" ? " Calling..." : callStatus === "connected" && <div><CallTimer callStatus={callStatus} /></div>}
                  </span>
                </div>
              </Col>
              <Col className="d-flex justify-content-end align-items-start" xs={2}>
                <Button
                  color="falcon-primary"
                  size="sm"
                  className="rounded"
                  id="call-tooltip"
                  onClick={() => { setMute(!mute) }}
                >
                  {mute ? <FontAwesomeIcon icon="microphone-slash" /> : <FontAwesomeIcon icon="microphone" />}
                </Button>
                <Button
                  color="falcon-danger"
                  size="sm"
                  className="rounded mx-2"
                  id="call-tooltip"
                  onClick={() => { setShowCall(!showCall) }}
                >
                  <FontAwesomeIcon icon="phone" />
                </Button>
              </Col>
            </Row>
          </Card>
        ) : (<div></div>)
        }
        <Card className="card-chat overflow-hidden">
          <CardBody tag={Flex} className="p-0 h-100">
            <ChatSidebar />
            <ChatContent
              chatNumber={chatNumber}
              setChatNumber={setChatNumber}
              showCall={showCall}
              setShowCall={setShowCall}
              contactToCall={contactToCall}
              setContactToCall={setContactToCall}
              callStatus={callStatus}
              setCallStatus={setCallStatus}
            />
          </CardBody>
        </Card>
      </ChatProvider>
    </div>
  );
};

export default Chat;
