// TeamFilter.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Offcanvas, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'components/common/Avatar';
import { FETCH_FILTERED_DATA, UPDATE_TEAM_FILTER } from 'actions/types';

const TeamFilter = ({ iconClassName = 'fs-5' }) => {
  const dispatch = useDispatch();
  const teamDetails = useSelector(state => state.teamDetails);
  const teamMemberPreferences = useSelector(
    state => state.teamFilter?.teamMemberPreferences || {}
  );

  const teamMembers = teamDetails?.team?.members || [];
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [localPreferences, setLocalPreferences] = useState({});

  useEffect(() => {
    setLocalPreferences(teamMemberPreferences);
  }, [teamMemberPreferences]);

  const handlePreferenceChange = (memberId, preferenceKey, value) => {
    const updatedPreferences = {
      ...localPreferences,
      [memberId]: {
        ...localPreferences[memberId],
        [preferenceKey]: value
      }
    };

    // Remove the member if all preferences are false
    const memberPrefs = updatedPreferences[memberId];
    if (
      !memberPrefs.viewCustomers &&
      !memberPrefs.viewEstimates &&
      !memberPrefs.viewInvoices
    ) {
      delete updatedPreferences[memberId];
    }

    setLocalPreferences(updatedPreferences);
  };

  const isAllSelected = teamMembers.every(member => {
    const memberId = member.userId._id;
    const preferences = localPreferences[memberId] || {};
    return (
      preferences.viewCustomers &&
      preferences.viewEstimates &&
      preferences.viewInvoices
    );
  });

  const handleSelectAll = () => {
    let updatedPreferences = {};
    if (isAllSelected) {
      // Deselect all: clear preferences
      updatedPreferences = {};
    } else {
      // Select all: set all preferences to true for all members
      teamMembers.forEach(member => {
        updatedPreferences[member.userId._id] = {
          viewCustomers: true,
          viewEstimates: true,
          viewInvoices: true
        };
      });
    }
    setLocalPreferences(updatedPreferences);
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;

    if (
      obj1 == null ||
      typeof obj1 !== 'object' ||
      obj2 == null ||
      typeof obj2 !== 'object'
    ) {
      return false;
    }

    const keysA = Object.keys(obj1);
    const keysB = Object.keys(obj2);

    if (keysA.length !== keysB.length) return false;

    for (let key of keysA) {
      if (!keysB.includes(key)) return false;

      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        if (!deepEqual(obj1[key], obj2[key])) return false;
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    return true;
  };

  const hasChanges = !deepEqual(localPreferences, teamMemberPreferences);

  const handleApply = () => {
    dispatch({ type: UPDATE_TEAM_FILTER, payload: localPreferences });
    dispatch({ type: FETCH_FILTERED_DATA });
    setShowOffcanvas(false);
  };

  return (
    <>
      <Button
        variant="link"
        className="text-decoration-none p-0"
        onClick={() => {
          return setShowOffcanvas(true);
        }}
      >
        <FontAwesomeIcon
          transform="shrink-5"
          icon="users-cog"
          className={iconClassName}
        />
      </Button>

      <Offcanvas
        show={showOffcanvas}
        onHide={() => {
          return (
            setLocalPreferences(teamMemberPreferences), setShowOffcanvas(false)
          );
        }}
        placement="end"
        backdrop={false}
        scroll={true}
        className="team-filter-offcanvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter Team Members</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex justify-content-between">
            <Form.Check
              type="checkbox"
              id="select-all"
              label="Select All"
              checked={isAllSelected}
              onChange={handleSelectAll}
              className="mb-3 fw-bold"
            />
            <Button
              disabled={!hasChanges}
              variant="primary"
              size="sm"
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
          <hr />
          {teamMembers?.length > 0 ? (
            <div className="team-members-list">
              {teamMembers?.map(member => {
                const memberId = member?.userId?._id;
                const preferences = localPreferences[memberId] || {
                  viewCustomers: false,
                  viewEstimates: false,
                  viewInvoices: false
                };

                return (
                  <div key={memberId} className="member-section">
                    <div className="member-header">
                      <Avatar
                        src={member?.avatarUrl}
                        name={member?.userId?.username}
                        size="m"
                        className="me-2"
                      />
                      <span className="member-name text-truncate">
                        {member?.name || member?.userId?.username}
                      </span>
                    </div>
                    <div className="preferences">
                      <Form.Check
                        type="switch"
                        id={`view-customers-${memberId}`}
                        label="Customers"
                        checked={preferences.viewCustomers}
                        onChange={e =>
                          handlePreferenceChange(
                            memberId,
                            'viewCustomers',
                            e.target.checked
                          )
                        }
                        className="mb-2"
                      />
                      <Form.Check
                        type="switch"
                        id={`view-estimates-${memberId}`}
                        label="Estimates"
                        checked={preferences.viewEstimates}
                        onChange={e =>
                          handlePreferenceChange(
                            memberId,
                            'viewEstimates',
                            e.target.checked
                          )
                        }
                        className="mb-2"
                      />
                      <Form.Check
                        type="switch"
                        id={`view-invoices-${memberId}`}
                        label="Invoices"
                        checked={preferences.viewInvoices}
                        onChange={e =>
                          handlePreferenceChange(
                            memberId,
                            'viewInvoices',
                            e.target.checked
                          )
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-muted">No team members found.</div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

TeamFilter.propTypes = {
  iconClassName: PropTypes.string
};

export default TeamFilter;
