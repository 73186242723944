import {
  PDF_UPDATE_REQUEST,
  PDF_UPDATE_SUCCESS,
  PDF_UPDATE_FAIL,
  PDF_UPDATE_RESET
} from '../actions/types';

export default function pdfUpdateReducer(state = { loading: false }, action) {
  switch (action.type) {
    case PDF_UPDATE_REQUEST:
      return { loading: true };
    case PDF_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case PDF_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case PDF_UPDATE_RESET:
      return { loading: false };
    default:
      return state;
  }
}
