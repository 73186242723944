import React, { useEffect, Fragment, useState, useCallback, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import ButtonIcon from 'components/common/ButtonIcon';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { listTollFreeNumbers, deleteMultipleNumbers, editNickname } from 'actions/index';
import { NUMBER_DELETE_RESET } from 'actions/types';
import FaqTollFree from './FaqTollFree';
import ActionPopover from 'components/common/ActionPopover';

const TollFreeRegistration = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showNicknameModal, setShowNicknameModal] = useState(false);
  const [showNotAllowedModal, setShowNotAllowedModal] = useState(false);
  const [rejectedReason, setRejectedReason] = useState("")
  const [nickName, setNickname] = useState({ title: "" })
  const [rowDelete, setRowDelete] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [isSelected, setIsSelected] = useState(false);
  const [tempId, setTempId] = useState()

  const closeNicknameBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowNicknameModal(!showNicknameModal) }}>
    </button>
  );

  const closeNotAllowedBtn = (
    <button className="btn-close bg-light" onClick={() => setShowNotAllowedModal(!showNotAllowedModal)}></button>
  );

  const { numbers, loading: numbersLoading } = useSelector((state) => state.tollFreeNumbers);

  const numberCreate = useSelector((state) => state.createNumber)
  const { success: successCreate } = numberCreate

  const numberDelete = useSelector((state) => state.deleteNumber)
  const { success: successDelete, loading: loadingDelete } = numberDelete

  const numberUpdate = useSelector((state) => state.updateNumber)
  const { success: successUpdate } = numberUpdate


  const selectionCallback = useCallback((selectedFlatRows) => { return setIsSelected(selectedFlatRows.map((fr) => { return fr.original._id })), setRowDelete([]) },
    [setIsSelected]);


  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }




  const numberFormatter = (rowData) => {
    const { phoneNumber } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-9"><strong>{formatPhoneNumber(phoneNumber)}</strong></h5>
        </Media>
      </Media>
    );
  };

  const nickNameFormatter = (rowData) => {
    const { _id, nickName } = rowData.row.original


    return (
      <Media className="cursor-pointer" tag={Flex} align="center">
        <Media body className="ms-2 mt-1">
          {nickName ?
            <div onClick={() => { return setShowNicknameModal(!showNicknameModal), setTempId({ tempId: _id }) }}>
              <SubtleBadge bg="success" pill className="fs-10">
                <FontAwesomeIcon icon="edit" transform="shrink-1" className="me-1" />
                {nickName}
              </SubtleBadge>
            </div> :
            <div onClick={() => { return setShowNicknameModal(!showNicknameModal), setTempId({ tempId: _id }) }}>
              <SubtleBadge bg="success" pill className="fs-10">
                <FontAwesomeIcon icon="plus" transform="shrink-1" className="me-1" />
                Add Nickname
              </SubtleBadge>
            </div>}
        </Media>
      </Media>

    );
  };




  const priceFormatter = (rowData) => {
    const { creditCost } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-10">{`$${(creditCost * 0.04).toFixed()}/mo`}</h5>
        </Media>
      </Media>
    );
  };

  const renewalFormatter = (rowData) => {
    const { renewalDate } = rowData.row.original

    return (

      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-10 mt-2"><Moment format="MM/DD/YYYY" date={renewalDate} /></h5>
        </Media>
      </Media>

    );
  };


  const registrationFormatter = (rowData) => {
    const { _id, tollFreeVerificationStatus, tollFreeVerificationRejectionReason } = rowData.row.original

    return (
      <Media className="cursor-pointer" tag={Flex} align="center">
        <Media body className="ms-2 mt-1">
          {tollFreeVerificationStatus === "NOT_STARTED" ? (
            <div>
              <Button onClick={() => { navigate('/follow-up/compliance/toll-free', { state: { fromTollFreeTable: true, tollFreePhoneData: rowData.row.original, step: 2 } }) }} className="btn-primary fs-10">
                Start
              </Button>
            </div>
          ) : (tollFreeVerificationStatus === "PENDING_REVIEW" || tollFreeVerificationStatus === "IN_REVIEW") ? (
            <div>
              <Button disabled={true} className="btn-warning fs-10">
                In review
              </Button>
            </div>
          ) : tollFreeVerificationStatus === "TWILIO_APPROVED" ? (
            <div>
              <Button disabled={true} className="btn-success fs-10">
                Approved
              </Button>
            </div>
          ) : tollFreeVerificationStatus === "TWILIO_REJECTED" ? (
            <div>
              <Button onClick={() => { navigate('/follow-up/compliance/toll-free', { state: { fromTollFreeTable: true, tollFreePhoneData: rowData.row.original, step: 2, tollFreeVerificationStatus: "TWILIO_REJECTED", tollFreeVerificationRejectionReason: tollFreeVerificationRejectionReason } }) }} className="btn-danger fs-10">
                Retry
              </Button>
            </div>
          ) : tollFreeVerificationStatus === "NOT_ALLOWED" ? (
            <div>
              <Button onClick={() => { return setShowNotAllowedModal(!showNotAllowedModal), setRejectedReason(tollFreeVerificationRejectionReason) }} className="btn-danger fs-10">
                Not allowed
              </Button>
            </div>
          ) : tollFreeVerificationStatus === "EXPIRED" ? (
            <div>
              <Button onClick={() => { navigate('/follow-up/compliance/toll-free', { state: { fromTollFreeTable: true, tollFreePhoneData: rowData.row.original, step: 2, tollFreeVerificationStatus: "EXPIRED", tollFreeVerificationRejectionReason: tollFreeVerificationRejectionReason } }) }} className="btn-danger fs-10">
                Retry
              </Button>
            </div>
          ) : (
            <div>

            </div>
          )}
        </Media>
      </Media>
    );
  };

  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;
    const items = [
      {
        label: 'Edit',
        icon: 'edit',
        action: () => { return setShowNicknameModal(!showNicknameModal), setTempId({ tempId: _id }) }
      },
      {
        label: 'Cancel number',
        icon: 'trash',
        color: 'danger',
        action: () => { return setShowCancelModal(!showCancelModal), setRowDelete([_id]) },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };


  const columns = [
    {
      accessor: 'number',
      Header: 'Number',
      Cell: numberFormatter
    },
    {
      accessor: 'tollFreeVerficationStatus',
      Header: 'Status',
      Cell: registrationFormatter,
    },
    {
      accessor: 'nickName',
      Header: 'Nickname',
      Cell: nickNameFormatter
    },
    {
      accessor: 'creditCost',
      Header: 'Price',
      Cell: priceFormatter
    },
    {
      accessor: 'renewal-date',
      Header: 'Renewal date',
      Cell: renewalFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right',

    }
  ];


  const handleNickname = (nickName, tempId) => {
    dispatch(editNickname(nickName, tempId))
    setShowNicknameModal(!showNicknameModal)

  }

  const handleCancelNumber = () => {
    if (window.confirm("Are you sure? Numbers will be permenantly deleted this cannot be undone")) {
      if (rowDelete?.length) {
        return dispatch(deleteMultipleNumbers(rowDelete))
      } else {
        return dispatch(deleteMultipleNumbers(isSelected))
      }
    }
  }

  useEffect(() => {
    if (successDelete) {
      setShowCancelModal(false)
      dispatch({ type: NUMBER_DELETE_RESET })
    }
  }, [successDelete])


  useEffect(() => {
    dispatch(listTollFreeNumbers())
  }, [successCreate, successDelete, successUpdate])

  const mounted = useRef(true)








  return (
    <div>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <Fragment>
              <ButtonGroup className="mt-2">
                <ButtonIcon icon="plus" tag={Link} to="/follow-up/virtual-number" transform="shrink-3" className="btn-tertiary border-dark" size="sm">
                  New number
                </ButtonIcon>
                <ButtonIcon icon="trash" onClick={() => { return setShowCancelModal(!showCancelModal) }} disabled={!isSelected.length} transform="shrink-3" className="btn-tertiary border-dark text-danger ms-2" size="sm" >
                  Delete number
                </ButtonIcon>
              </ButtonGroup>
            </Fragment>
          </div>
        </CardHeader>
        <CardBody className="p-0">

          {numbersLoading ? (
            <div>
              <Spinner />
            </div>
          ) : (

            <Fragment>
              <Card>

                <AdvanceTableWrapper
                  columns={columns}
                  data={numbers}
                  sortable
                  pagination
                  perPage={12}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}

                >
                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      className: 'fs--1 font-weight-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pe-2 ps-3 ">
                    <AdvanceTableFooter
                      rowCount={numbers?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>

              </Card>
            </Fragment>
          )
          }
        </CardBody>
      </Card>
      <Card style={{ border: '1px solid rgba(0,0,0,.125)', borderRadius: '0.25rem' }}>
        <CardBody style={{ textAlign: 'center' }}>
        </CardBody>
        <FaqTollFree />
      </Card>

      {//------------------------------Edit Nickname Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal isOpen={showNicknameModal} centered toggle={() => { setShowNicknameModal(!showNicknameModal) }} >
        <ModalHeader className="text-center d-flex flex-between-center border-bottom-0" close={closeNicknameBtn}>
          Edit Nickname
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label for="title">Nickname:</Label>
              <Input placeholder="Enter nickname" value={nickName.title.value} onChange={(e) => { setNickname({ ...nickName, [e.target.name]: e.target.value }) }} className="mb-3" name="title" id="title" />
              <Button block disabled={!nickName.title} color="primary" className="mb-3" onClick={() => { handleNickname(nickName, tempId) }} type="submit">Save</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      {//------------------------------Edit Not allowd Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal isOpen={showNotAllowedModal} centered toggle={() => setShowNotAllowedModal(!showNotAllowedModal)}>
        <ModalHeader className="text-center d-flex flex-between-center border-bottom-0" close={closeNotAllowedBtn}>
          Rejected by The Campaign Registry
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4 text-center">
              <h5 className="text-danger">Your Toll-Free Number Verification was Rejected</h5>
              <p>
                Unfortunately, your toll-free number verification request was rejected by The Campaign Registry.
              </p>
              <p>
                <strong>Reason for Rejection:</strong> {rejectedReason}
              </p>
              <p>
                Due to the regulations from The Campaign Registry, this phone number cannot be resubmitted for verification.
                We understand this is inconvenient, but this is beyond our control and is dictated by regulatory compliance.
              </p>
              <p>
                To continue using our services, please acquire a new phone number and try the verification process again.
              </p>
              <p>
                For more information, visit <a href="https://www.campaignregistry.com" target="_blank" rel="noopener noreferrer">The Campaign Registry</a>.
              </p>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {//------------------------------Cancel Number Modal--------------------------------------------------------------------------------------------------------------------------------------------------------------
      }
      <Modal
        isOpen={showCancelModal}
        centered
        toggle={() => setShowCancelModal(!showCancelModal)}
      >
        <ModalHeader className="text-center">
          Confirm Number Deletion
        </ModalHeader>
        <ModalBody>
          <Card className="border-0">
            <CardBody className="text-center">
              <p>
                <strong>Warning:</strong> Deleting this number will permanently remove it from your account. This action cannot be undone.
              </p>
              <div className="d-flex justify-content-center">
                <Button
                  color="danger"
                  onClick={handleCancelNumber}
                  disabled={loadingDelete}
                  className="me-2"
                >
                  {loadingDelete ? <Spinner size="sm" /> : 'Delete Number'}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => setShowCancelModal(!showCancelModal)}
                >
                  Cancel
                </Button>
              </div>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TollFreeRegistration;