import {
  RESPONDER_UPDATE_REQUEST,
  RESPONDER_UPDATE_SUCCESS,
  RESPONDER_UPDATE_FAIL} from '../actions/types';

export default function responderUpdateReducer(state = {}, action) {
  switch (action.type) {
    case RESPONDER_UPDATE_REQUEST:
      return { loading: true };
    case RESPONDER_UPDATE_SUCCESS:
      return { loading: false, success: true, flowInfo: action.payload };
    case RESPONDER_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
