import React, {
  Fragment,
  useState,
  useRef,
  createRef,
  useContext,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import {
  Card,
  CardBody,
  Col,
  Badge,
  Label,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Media,
  Row,
  Input,
  FormGroup
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import Divider from 'components/common/Divider';
import _ from 'lodash';
import {
  Modifier,
  convertFromRaw,
  convertToRaw,
  ContentState,
  EditorState
} from 'draft-js';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/mention/lib/plugin.css'; //MentionCSS
import '@draft-js-plugins/emoji/lib/plugin.css'; // Emoji CSS
import '@draft-js-plugins/linkify/lib/plugin.css'; // Linkify CSS
import editorStyles from 'components/compose/SimpleMentionEditor.module.scss';
import mentionsStyles from 'components/compose/MentionsStyles.module.scss';
import { ResponderUploadContext } from '../../context/Context';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import TemplateTableV3 from 'components/templates/TemplateTableV3';
import AppContext from 'context/Context';
import { toast } from 'react-toastify';
import {
  listKeywords,
  listFields,
  listNumbers,
  attachFile,
  createKeyword
} from 'actions/index';
import { RESET_COMPOSE_GROUP, KEYWORD_CREATE_RESET } from 'actions/types';
import EmojiStyles from 'components/chat/content/EmojiStyles.module.scss'
import EmojiIcon from 'components/icons/EmojiIcon';


const emojiTheme = {
  ...defaultTheme,
  emojiSelect: EmojiStyles.emojiSelect,
  emojiSelectButton: EmojiStyles.emojiSelectButton,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectPopover: EmojiStyles.emojiSelectPopover,
  emojiSelectPopoverGroupItem: EmojiStyles.emojiSelectPopoverGroupItem,

};

const StepOneForm = ({ errors, weekDays }) => {
  const messageFieldRef = createRef();

  const { upload, setUpload, handleInputChange } = useContext(
    ResponderUploadContext
  );


  const [highlighted, setHighlighted] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  });
  const [open, setOpen] = useState(false);
  const [fromFlow, setFromFlow] = useState(false);
  const [suggestions, setSuggestions] = useState();
  const [messageLength, setMessageLength] = useState('');
  const [showFileModal, setShowFileModal] = useState(false);
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [keyword, setKeyword] = useState({
    title: ''
  });
  const [keyErr, setKeyErr] = useState(false);
  const [userFile, setUserFile] = useState();
  const [uploadFile, setUploadFile] = useState();
  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const dispatch = useDispatch();

  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
  const numbers = useSelector(state => state.numbers);
  const compose = useSelector(state => state.compose);
  const usersKeywords = useSelector(state => state.keywords);
  const { keywords: keywords, loading: loadingKeywords, totalKeywords: totalKeywords } = usersKeywords;
  const responderDetails = useSelector(state => state.responderDetails);

  const keywordCreate = useSelector(state => state.keywordCreate);
  const { success: successCreate } = keywordCreate;

  const attachFileCreate = useSelector(state => state.attachFileCreate);
  const { success: successAttachFile } = attachFileCreate;

  const animatedComponents = makeAnimated();
  const InputFile = useRef(null);

  const contentState = editorState.getCurrentContent();
  const len = contentState.getPlainText(' ');
  const rawContentState = convertToRaw(editorState.getCurrentContent()); // Setting template body as this in DB

  const { isDark } = useContext(AppContext);

  const onButtonClick = e => {
    e.preventDefault();
    InputFile.current.click();
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      // match with the menu
      borderColor: isDark ? 'dark' : 'light'
      // Removes weird border around container
    }),
    input: base => ({
      ...base,
      color: isDark ? 'dark' : 'light'
    }),
    placeholder: base => ({
      ...base,
      color: '#2c7be5',
      fontWeight: 'bold'
    }),
    option: (base, state) => ({
      ...base,
      color: '#d8e2ef', //
      backgroundColor: isDark ? 'dark' : 'light',
      fontWeight: state.isSelected ? 'bold' : 'normal',
      backgroundColor: state.isFocused ? '#2c7be5' : '#0b1727'
    }),
    singleValue: base => ({
      // single value determines color of text after selection
      ...base,
      color: isDark ? 'dark' : 'light',
      fontWeight: 'bold'
    }),
    menu: base => ({
      ...base,
      // override border radius to match the box
      borderRadius: 0,
      background: isDark ? 'dark' : 'light',
      // kill the gap
      marginTop: 15,
      zIndex: 999
    }),
    menuList: base => ({
      ...base,
      // kill the white space on first and last option

      background: isDark ? 'dark' : 'light'
    })
  };

  const closeBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setShowTemplateModal(!showTemplateModal);
      }}
    ></button>
  );


  const closeFileBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setShowFileModal(!showFileModal);
      }}
    ></button>
  );

  const closeCreateBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        return setShowKeywordModal(!showKeywordModal);
      }}
    ></button>
  );

  const manualFileUpload = e => {
    e.preventDefault();
    if (e.target.files[0].name) {
      setUserFile(e.target.files[0].name);
      const viewFile = URL.createObjectURL(e.target.files[0]);
      handleInputFileChange({ value: viewFile, name: 'fileData' });
    }
  };

  useEffect(() => {
    dispatch(listKeywords());
    dispatch(listNumbers());
    dispatch(listFields());
  }, [successCreate]);

  useEffect(() => {
    const OurFields = fields.map(el => {
      return { name: `{${el.title}}`, id: el._id };
    });
    setSuggestions([
      { name: '{First name}', id: '700' },
      { name: '{Last name}', id: '701' },
      { name: '{Company name}', id: '702' },
      { name: '{Phone}', id: '703' },
      { name: '{Email}', id: '704' },
      ...OurFields
    ]);
  }, [fields]);

  useEffect(() => {
    // Inserts link into Message draft-js as text
    if (
      responderDetails[0]?.responder.responseType === 'textMessage' ||
      responderDetails[0]?.responder.responseType === 'textToSpeech'
    ) {
      let contentState;

      if (!responderDetails[0]?.responder?.messageBody?.entityMap) {
        contentState = {
          entityMap: {},
          blocks: responderDetails[0]?.responder?.messageBody?.blocks
        };
        setEditorState(
          EditorState.createWithContent(convertFromRaw(contentState))
        );
      } else {
        setEditorState(
          EditorState.createWithContent(
            convertFromRaw(responderDetails[0]?.responder?.messageBody)
          )
        );
      }
    }
  }, []);

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const insertText = textToInsert => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    let newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      textToInsert
    );
    const textToInsertSelection = currentSelection.set(
      'focusOffset',
      currentSelection.getFocusOffset() + textToInsert?.length
    );
    let inlineStyles = editorState.getCurrentInlineStyle();
    inlineStyles.forEach(
      inLineStyle =>
      (newContent = Modifier.applyInlineStyle(
        newContent,
        textToInsertSelection,
        inLineStyle
      ))
    );
    let newState = EditorState.push(
      editorState,
      newContent,
      'insert-characters'
    );
    newState = EditorState.forceSelection(
      newState,
      textToInsertSelection.set(
        'anchorOffset',
        textToInsertSelection.getAnchorOffset() + textToInsert.length
      )
    );

    return newState;
  };

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (!successCreate) {
      return;
    } else {
      toast(
        <Fragment>
          <strong>Keyword Created</strong>
        </Fragment>,
        {
          position: 'bottom-left',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light'
        }
      );
      setUpload({ ...upload, keyword: keywordCreate.newKeyword });
      dispatch({ type: KEYWORD_CREATE_RESET });
    }
  }, [successCreate]);

  useEffect(() => {
    // Inserts link into Message draft-js as text
    if (successAttachFile) {
      setEditorState(insertText(attachFileCreate?.payload?.tempUrl));
    }
  }, [successAttachFile]);

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    // <-------- setting message length
    if (len) {
      setMessageLength(len.length);
    } else {
      setMessageLength(0);
    }
    if (
      upload?.responseType === 'textMessage' ||
      upload?.responseType === 'textToSpeech'
    ) {
      setUpload(prevUpload => ({
        ...prevUpload,
        message: len,
        messageBody: rawContentState,
      }));
    }
  }, [editorState]);


  useEffect(() => {
    if (upload?.responseType === 'noResponse') {
      setUpload({ ...upload, message: '' });
    }
  }, [upload?.responseType]);


  useEffect(() => {
    if (responderDetails?.length) {
      if (responderDetails[0]?.responder?.weekDays) {
        const updateObj = {};
        for (let [key, value] of Object.entries(weekDays)) {
          if (responderDetails[0]?.responder?.weekDays[key]) {
            updateObj[key] = responderDetails[0]?.responder?.weekDays[key];
          } else {
            updateObj[key] = {};
          }
        }
        setUpload({
          ...upload,
          weekDays: updateObj
        });
      } else {
        setUpload({
          ...upload,
          weekDays: weekDays
        });
      }
    } else {
      return setUpload({
        ...upload,
        weekDays: weekDays
      });
    }
  }, []);


  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    // INSERTS TEMPLATE
    if (compose[0]?.composeTemplate) {
      let contentState;
      if (!compose[0]?.composeTemplate.body.entityMap) {
        contentState = {
          entityMap: {},
          blocks: compose[0]?.composeTemplate.body.blocks
        };
        setEditorState(
          EditorState.createWithContent(convertFromRaw(contentState))
        );
      } else {
        contentState = {
          entityMap: compose[0]?.composeTemplate?.body?.entityMap,
          blocks: compose[0]?.composeTemplate.body.blocks
        };
        setEditorState(
          EditorState.createWithContent(convertFromRaw(contentState))
        );
      }
    }
    dispatch({ type: RESET_COMPOSE_GROUP });
  }, [compose.length]);

  //---------------------------------------------------------------------------------------------------------------------------------------------------------

  function Entry(props) {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>

          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>

            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      //mentionPrefix: '@',
      supportWhitespace: true,
    });
    const emojiPlugin = createEmojiPlugin({
      selectButtonContent: <EmojiIcon width={30} height={30} />, // Set size as needed
      theme: emojiTheme,
      useNativeArt: true,
    });
    const linkifyPlugin = createLinkifyPlugin()

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin];
    return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
  }, []);
  //---------

  const onOpenChange = useCallback(_open => {
    console.log('onOpenChange value', _open);
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {
    console.log('onSearchChange value', value);
  }, []);

  const handleInputFileChange = ({ value, name }) =>
    setUploadFile({ ...uploadFile, [name]: value });

  const handleKeywordChange = e => {
    setKeyword({ ...keyword, title: e.target.value });

    if (
      e.target.value.toLowerCase() === 'stop' ||
      e.target.value.toLowerCase() === 'unsubscribe'
    ) {
      setKeyErr(true);
    } else {
      setKeyErr(false);
    }
  };

  console.log("upload in StepOneForm", upload);
  //--------------------------------------------------------------------------------------------------------------------------------------------------------

  if (!upload) {
    return null; // or a loading indicator
  }

  return (
    <Fragment>
      <Divider className="mt-4 fs-2">
        Responder Title<strong style={{ color: 'red' }}> *</strong>
      </Divider>
      <Input
        autoComplete="off"
        placeholder="SMS Autoresponder 1..."
        name="title"
        id="title"
        value={upload?.title}
        onChange={({ target }) => handleInputChange(target)}
      />

      <div className="pb-2 pt-2"></div>
      <Divider className="mt-4 fs-2">
        <FormGroup switch>
          <Input
            type="switch"
            role="switch"
            id="requireKeyword"
            style={{ transform: 'scale(1.5)', marginRight: '20px' }}
            onChange={() => {
              setUpload({ ...upload, requireKeyword: !upload.requireKeyword });
            }}
            checked={upload.requireKeyword}
            value={upload.requireKeyword}
            name="requireKeyword"
          />

          <Label for="requireKeyword">Require Keyword</Label>
        </FormGroup>
      </Divider>

      <div className="pb-2 pt-2"></div>

      {upload?.requireKeyword ? (
        <div>
          <Row>
            <Col className="d-flex justify-content-start">
              <h5 className="mb-1 text-center">Select Keywords</h5>
              <Button
                onClick={() => {
                  setShowKeywordModal(!showKeywordModal);
                }}
                className="btn-tertiary shadow-none fs--1 ms-3"
                transform="shrink-3"
                size="sm"
              >
                <FontAwesomeIcon icon="plus" transform=" left-2" />
                {`  New Keyword`}
              </Button>
            </Col>
          </Row>
          <div className="mt-4"></div>

          <Select
            name="keyword"
            required={true}
            className="mb-3"
            styles={customStyles}
            components={animatedComponents}
            closeMenuOnSelect={true}
            options={keywords}
            value={upload?.keyword}
            defaultValue={
              responderDetails?.length
                ? responderDetails[0]?.responder?.keyword?.map(el => {
                  return { title: el.title, _id: el._id };
                })
                : ''
            }
            getOptionLabel={({ title }) => title}
            getOptionValue={({ _id }) => _id}
            onChange={keyword => setUpload({ ...upload, keyword: keyword })}
            isMulti
            placeholder="select Keywords"
            isSearchable={true}
            errors={errors}
          />

          <Modal
            isOpen={showKeywordModal}
            centered
            toggle={() => setShowKeywordModal(!showKeywordModal)}
          >
            <ModalHeader
              close={closeCreateBtn}
              className="text-center d-flex flex-between-center border-bottom-0"
            >
              Add a new keyword
            </ModalHeader>
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Col>
                    <Row>
                      <Label for="keyword-title">Enter your keyword:</Label>
                      <Input
                        value={keyword.title.value}
                        onChange={handleKeywordChange}
                        className={!keyErr ? 'mb-3' : 'mb-3 border-danger'}
                        name="keywordTitle"
                        id="keywordTitle"
                      />

                      {keyErr ? (
                        <div>
                          <span className="text-danger d-inline-block">
                            This Keyword is reserved for Automatic Opt-out,
                            Anyone who sends this keyword will be added to
                            unsubscribers group
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                  </Col>
                  <Button
                    onClick={() => {
                      return (
                        dispatch(createKeyword(keyword)),
                        setShowKeywordModal(!showKeywordModal)
                      );
                    }}
                    block
                    color="primary"
                    className="mb-3"
                  >
                    Save
                  </Button>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <div>
          <h5 className="mb-1 text-start">
            Trigger responder without keywords{' '}
          </h5>
          <Divider className="mt-4 fs-2"></Divider>
        </div>
      )}

      <h5 className="mb-1 text-center">Phone numbers </h5>
      <Divider className="mt-4 fs-2">
        Select Numbers<strong style={{ color: 'red' }}> *</strong>
      </Divider>

      <Select
        name="numbers"
        //  required={true}
        className="mb-3"
        styles={customStyles}
        components={animatedComponents}
        closeMenuOnSelect={true}
        options={numbers}
        defaultValue={
          responderDetails?.length
            ? responderDetails[0]?.responder?.numbers?.map(el => {
              return el.nickName
                ? {
                  phoneNumber: el.phoneNumber + ' ' + el.nickName,
                  _id: el._id
                }
                : { phoneNumber: el.phoneNumber, _id: el._id };
            })
            : ''
        }
        getOptionLabel={({ phoneNumber, nickName }) =>
          nickName ? phoneNumber + ' ' + nickName : phoneNumber
        }
        getOptionValue={({ _id }) => _id}
        onChange={number => {
          return setUpload({ ...upload, numbers: number });
        }}
        isMulti
        placeholder="select Numbers"
        isSearchable={true}
        errors={errors}
      />

      <Divider className="mt-4 fs-2">
        Select Response<strong style={{ color: 'red' }}> *</strong>
      </Divider>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="textMessage"
            checked={upload?.responseType === 'textMessage'}
            onClick={({ target }) => handleInputChange(target)}
            value="textMessage"
            name="responseType"
          />
          <div>
            <strong>Text message</strong>
            <FontAwesomeIcon icon="sms" transform="down-1 right-4" />
            <p>Send an automated text message as a response</p>
          </div>
        </Label>
      </FormGroup>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="noResponse"
            checked={upload?.responseType === 'noResponse'}
            onClick={({ target }) => handleInputChange(target)}
            value="noResponse"
            name="responseType"
          />
          <div>
            <strong>No reply</strong>
            <FontAwesomeIcon icon="ban" transform="down-1 right-4" />
            <p>No response will be sent</p>
          </div>
        </Label>
      </FormGroup>

      {(upload?.responseType === 'textMessage' ||
        upload?.responseType === 'textToSpeech') && (
          <div>
            <Divider className="mt-4 fs-2">
              Create Message<strong style={{ color: 'red' }}> *</strong>
            </Divider>
            <div
              style={{ minHeight: '7em', maxHeight: '10em', overflow: 'auto' }}
              className={`border border-2x border-300 bg-light text-dark rounded-soft fs--9 ${editorStyles.editor}`}
              onClick={() => {
                messageFieldRef.current.focus();
              }}
            >
              <Editor
                editorKey={'editor'}
                currentContent={ContentState}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                ref={messageFieldRef}
              />
              <EmojiSuggestions />
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                entryComponent={Entry}
              />
            </div>
            <div>
              <EmojiSelect closeOnEmojiSelect />
              <span
                color="light"
                className="px-3 py-1 bg-soft-info rounded-capsule shadow-none fs--1 ml-3"
              >
                <FontAwesomeIcon icon="tags" transform="left-3" />
                Type <strong>@</strong> for custom fields
              </span>
            </div>
            <Col className="mt-2">
              <Badge
                color="soft-success"
                pill
                className="fs--1"
              >{`Characters: ${messageLength}/918`}</Badge>

              <Badge
                color="soft-info"
                pill
                className="fs--1 ml-2"
              >{`Parts ${Math.ceil(messageLength / 153)}/6`}</Badge>

              <Label className="ml-4">{`Credit cost: ${Math.ceil(
                messageLength / 153
              )}`}</Label>
              {
                //<Label className="ml-4" >{`Cost to send $${(Math.ceil(messageLength / 153) * 0.04).toFixed(2)}`}</Label>
              }
            </Col>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button
                onClick={() => {
                  return (
                    setFromFlow(true), setShowTemplateModal(!showTemplateModal)
                  );
                }}
                className="btn-tertiary border-dark fs--1 mb-0"
              >
                <FontAwesomeIcon icon={['fab', 'trello']} transform="left-3" />
                {` Insert Template`}
              </Button>
              <Modal
                isOpen={showTemplateModal}
                centered
                toggle={() => {
                  return setShowTemplateModal(!showTemplateModal);
                }}
              >
                <ModalHeader
                  toggle={() => {
                    return setShowTemplateModal(!showTemplateModal);
                  }}
                  close={closeBtn}
                >
                  Templates
                </ModalHeader>
                <ModalBody className="p-0">
                  <TemplateTableV3
                    fromFlow={fromFlow}
                    showTemplateModal={showTemplateModal}
                    setShowTemplateModal={setShowTemplateModal}
                  />
                </ModalBody>
              </Modal>
            </div>

            {upload?.responseType === 'textMessage' && (
              <div className="justify-content-between mt-1 px-1 pb-1">
                <Button
                  onClick={() => {
                    setShowFileModal(!showFileModal);
                  }}
                  className="btn-tertiary border-dark fs--1 ml- mb-0"
                >
                  <FontAwesomeIcon icon="paperclip" transform="left-3" />
                  {` Attach File`}
                </Button>
                <Modal
                  isOpen={showFileModal}
                  centered
                  toggle={() => {
                    setShowFileModal(!showFileModal);
                  }}
                >
                  <ModalHeader
                    toggle={() => {
                      setShowFileModal(!showFileModal);
                    }}
                    close={closeFileBtn}
                  >
                    Attach a file - 10MB limit
                  </ModalHeader>
                  <ModalBody className="p-0">
                    <Card>
                      <CardBody className="fs--1 font-weight-normal p-4">
                        <h4 className="mb-1 mt-3 text-center"></h4>
                        <div className="mb-2">
                          <input
                            type="file"
                            onChange={e => manualFileUpload(e)}
                            accept=".jpg, .gif, .png, .pdf, .txt, .csv, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .vcf"
                            ref={InputFile}
                            className="d-none"
                          />
                          <div
                            className={`dropzone-area ${highlighted ? ' border-800' : ''
                              }`}
                            onClick={e => onButtonClick(e)}
                            onDragEnter={() => {
                              setHighlighted(true);
                            }}
                            onDragLeave={() => {
                              setHighlighted(false);
                            }}
                            onDragOver={e => {
                              e.preventDefault();
                            }}
                            onDrop={e => {
                              e.preventDefault();
                            }}
                          >
                            <Fragment>
                              <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                                <img
                                  src={cloudUpload}
                                  alt=""
                                  width={25}
                                  className="mr-2"
                                />
                                <Media>
                                  <p className="fs-0 mb-0 text-700">
                                    {userFile ? userFile : 'Upload your File'}
                                  </p>
                                </Media>
                              </Media>
                              <p className="mb-0 w-75 mx-auto text-500">
                                Supported file types: .jpg, .gif, .png, .pdf,
                                .txt, .csv, .doc, .docx, .xls, .xlsx, .ppt, .pptx,
                                .vcf file formats
                              </p>
                            </Fragment>
                          </div>
                        </div>
                        <p className="fs-0 text-center">
                          A clickable hyperlink will be included in your SMS where
                          recipients can view / download your file.
                        </p>
                        <Col className="text-center">
                          <Button
                            disabled={!uploadFile}
                            color="primary"
                            onClick={() => {
                              return (
                                dispatch(attachFile(uploadFile)),
                                setShowFileModal(!showFileModal)
                              );
                            }}
                            className="my-3 text-white"
                          >
                            {attachFileCreate.loading
                              ? '...processing'
                              : 'Attach File'}
                          </Button>
                        </Col>
                      </CardBody>
                    </Card>
                  </ModalBody>
                </Modal>
              </div>
            )}
          </div>
        )}
    </Fragment>
  );
};

export default StepOneForm;
