/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { listContacts } from '../../../actions';
import { useAsyncDebounce } from 'react-table';

const AdvanceContactsTableSearchBox = ({ placeholder = 'Search...' }) => {
  const dispatch = useDispatch();

  const pageSize = useSelector(state => state.paginationRedux.pageSize);

  const [value, setValue] = useState();

  const onChange = useAsyncDebounce(value => {
    dispatch(listContacts({ search: value, page: 1, limit: pageSize }));
  }, 200);

  return (
    <InputGroup className="pe-3 ps-2 mb-2">
      <InputGroupText className="bg-soft-secondary border-0">
        <FontAwesomeIcon icon="search" />
      </InputGroupText>
      <Input
        value={value || ''}
        onChange={({ target: { value } }) => {
          setValue(value); // set state variable
          onChange(value); // then call onChange to with value
        }}
        size="md"
        id="search"
        placeholder={placeholder}
        type="search"
        className="shadow-none"
        autoComplete="off"
      />
    </InputGroup>
  );
};

export default AdvanceContactsTableSearchBox;
