import React from 'react';
import { Col, Row } from 'reactstrap';
import ResponderForm from './ResponderForm';
import Section from '../common/Section';
import ResponderProvider from './ResponderProvider';

const ResponderLayout = () => {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-4">
        <Col>
          <ResponderProvider>
            <ResponderForm />
          </ResponderProvider>
        </Col>
      </Row>
    </Section>
  );
};

export default ResponderLayout;
