import React, { useEffect, useContext } from 'react';
import {
  Row,
  Col,
  Button,
  Spinner,
  Alert
} from 'reactstrap';
import { TollFreeUploadContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { createTollFreeVerification } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { TOLL_FREE_VERIFICATION_CREATE_RESET } from 'actions/types';

const StepFourForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { upload, setUpload } = useContext(TollFreeUploadContext);

  console.log('upload', upload);

  const tollFreeVerificationCreate = useSelector(
    state => state.tollFreeVerificationCreate
  );
  const {
    loading: tollFreeVerificationCreateLoading,
    success: tollFreeVerificationCreateSuccess,
    error: tollFreeVerificationCreateError
  } = tollFreeVerificationCreate;

  useEffect(() => {
    if (tollFreeVerificationCreateSuccess) {
      navigate('/follow-up/numbers');
      dispatch({ type: TOLL_FREE_VERIFICATION_CREATE_RESET });
    }

    return () => {
      dispatch({ type: TOLL_FREE_VERIFICATION_CREATE_RESET });
    };
  }, [tollFreeVerificationCreateSuccess, navigate, dispatch]);

  useEffect(() => {
    setUpload({
      ...upload,
      notificationEmail: 'trustHubNotifications@TrustPro.io'
    });

  }, []);

  return (
    <div>
      <h4 className="mb-3">1. Summary</h4>
      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Selected number</strong>
          </h6>
          <p>{upload?.tollFreePhoneNumber}</p>
        </Col>
        <Col>
          <h6 className="fs-9">
            <strong>Brand name</strong>
          </h6>
          <p>{upload?.businessName}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Contact email address</strong>
          </h6>
          <p>{upload?.businessContactEmail}</p>
        </Col>
        <Col>
          <h6 className="fs-9">
            <strong>Contact phone</strong>
          </h6>
          <p>{upload?.businessContactPhone}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Use case</strong>
          </h6>
          <p>{upload?.useCaseCategories}</p>
        </Col>
        <Col>
          <h6 className="fs-9">
            <strong>Campaign description</strong>
          </h6>
          <p>{upload?.useCaseSummary}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Additional information</strong>
          </h6>
          <p>{upload?.additionalInformation}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Sample messages</strong>
          </h6>
          {upload?.sampleMessages?.map((msg, index) => (
            <p key={index} className="border rounded p-2">
              {msg?.text}
            </p>
          ))}
        </Col>
      </Row>

      <hr />

      <h4 className="mb-3">2. Costs and terms</h4>

      <Row>
        <Col>
          <h6>
            <strong>Toll free verification registration</strong>
          </h6>
          <p>FREE</p>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col>
          <h6 className="fs-9">
            <strong>Cost</strong>
          </h6>
          <p>$0.00</p>
        </Col>
      </Row>

      <hr />

      {tollFreeVerificationCreateError && (
        <Alert color="danger">
          There was an issue with creating the toll-free verification. Please
          try again later.
        </Alert>
      )}
      <div className="d-flex justify-content-end mt-3">
        <Button
          onClick={() => {
            dispatch(createTollFreeVerification(upload));
          }}
          color="primary"
          className="px-5"
        >
          {tollFreeVerificationCreateLoading ? <Spinner /> : 'Submit'}
        </Button>
      </div>
    </div>
  );
};

export default StepFourForm;
