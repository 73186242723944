import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { listPaySettings } from 'actions/crmActions';
import { useDispatch, useSelector } from 'react-redux';
import NavbarVertical from './navbar/NavbarVertical';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import AccountOverview from './billing/AccountOverview';
import { toast } from 'react-toastify';
import { PAY_SETTINGS_UPDATE_RESET } from 'actions/types';
import ManageSubscription from './billing/ManageSubscription';
import FaqBilling from './FaqBilling';
import BillingHistory from './billing/BillingHIstory';




const Billing = () => {

  const dispatch = useDispatch()
  const paySettingsUpdate = useSelector((state) => state.paySettingsUpdate)
  const { success: successUpdate } = paySettingsUpdate
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false)

  const windowWidth = useWindowDimensions()

  useEffect(() => {
    dispatch(listPaySettings())
  }, [])

  useEffect(() => {
    if (successUpdate) {
      toast(
        <Fragment>
          <strong>Settings Updated</strong>
        </Fragment>,
        {
          autoCLose: 1000
        }
      );
      dispatch({ type: PAY_SETTINGS_UPDATE_RESET })
    }
  }, [successUpdate]);



  return (
    <Fragment>
      <Row>
        {windowWidth.width >= 992 && (
          <Col lg={2}>
            <NavbarVertical isNavbarVerticalCollapsed={isNavbarVerticalCollapsed} setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed} />
          </Col>
        )}
        <Col lg={10}>
          <Row className="my-2 me-2 d-flex justify-content-between">
            {windowWidth.width <= 992 ? (
              <div>
                <UncontrolledDropdown>
                  <DropdownToggle size="md" className="fw-bold btn-reveal btn">{`Settings `}
                    <FontAwesomeIcon icon="chevron-down" className="fs--1" />
                  </DropdownToggle>
                  <DropdownMenu className="border py-2 me-6 me-xxl-8">
                    <DropdownItem tag={Link} to="/follow-up/account-settings" className="fw-bold">Settings</DropdownItem>
                    <DropdownItem tag={Link} to="/follow-up/billing" className="fw-bold">Billing</DropdownItem>
                    <DropdownItem tag={Link} to="/follow-up/monthly-plans" className="fw-bold" >Monthly plans</DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            ) : (
              <div>
                <h2 className="d-flex justify-content-start" >Billing</h2>
              </div>
            )}
          </Row>
          <Row>
            <Col lg={10}>
              <AccountOverview />
            </Col>
            <Col lg={5} >
              <ManageSubscription />
            </Col>
            <Col lg={5} >
              <BillingHistory />
            </Col>
          </Row>
          <Row>
            <FaqBilling />
          </Row>
        </Col>
      </Row>
    </Fragment>

  );
};

export default Billing;