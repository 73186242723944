import React, { useState, useEffect } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import classNames from 'classnames';
import FalconLightBoxGallery from 'components/common/FalconLightBoxGallery';
import ChatMessageOptions from './ChatMessageOptions';
import { faCheckDouble, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';

const Message = ({
  message,
  messageSid,
  callSid,
  startTime,
  endTime,
  mediaUri,
  contentType,
  status,
  time,
  from,
  to,
  numSegments,
  direction,
  chatId
}) => {
  const isLeft = direction === 'inbound' && direction !== 'outbound-api';

  const [mediaUrl, setMediaUrl] = useState();

  useEffect(() => {
    if (mediaUri) {
      setMediaUrl(`https://api.twilio.com${mediaUri}`);
    }
  }, [mediaUri]);

  return (
    <Flex className={classNames('p-3', { 'd-block': !isLeft })}>
      <div
        className={classNames('flex-1', {
          'd-flex justify-content-end': !isLeft
        })}
      >
        <div
          className={classNames('w-xxl-75', {
            'w-100': !isLeft
          })}
        >
          <Flex
            alignItems="center"
            className={classNames('hover-actions-trigger', {
              'flex-end-center': !isLeft,
              'align-items-center': isLeft
            })}
          >
            {!isLeft && (
              <ChatMessageOptions
                to={to}
                from={from}
                status={status}
                chatId={chatId}
                messageSid={messageSid}
                message={message}
                callSid={callSid}
                direction={direction}
                numSegments={numSegments}
                startTime={startTime}
                endTime={endTime}
              />
            )}

            {mediaUrl && (
              <div className="chat-message chat-gallery">
                {message && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{
                      __html: message ? message : ' '
                    }}
                  />
                )}
                {contentType?.startsWith('image') ? (
                  <FalconLightBoxGallery images={[mediaUrl]}>
                    {openImgIndex => (
                      <Row className="mx-n1">
                        <Col
                          xs={6}
                          md={4}
                          className="px-1"
                          style={{ minWidth: 50 }}
                          key={1}
                        >
                          <Image
                            fluid
                            rounded
                            className="rounded mb-2 cursor-pointer"
                            src={mediaUrl}
                            alt=""
                            onClick={() => openImgIndex(0)}
                          />
                        </Col>
                      </Row>
                    )}
                  </FalconLightBoxGallery>
                ) : (
                  <video
                    controls
                    className="rounded mb-2"
                    style={{ width: '100%' }}
                  >
                    <source src={mediaUrl} type={contentType} />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}

            {!mediaUrl && (
              <div
                className={classNames('p-2 rounded-2 chat-message', {
                  'bg-200': isLeft,
                  'bg-primary text-white': !isLeft
                })}
              >
                {message && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{ __html: message }}
                  />
                )}
                {callSid && (
                  <div>
                    <FontAwesomeIcon icon="phone" transform="shrink-1" />
                    <p
                      className="mb-0"
                      dangerouslySetInnerHTML={{ __html: message }}
                    />
                    <h5 className="mb-0 fs--1 mt-2">
                      <Moment
                        format="hh:mm:ss"
                        duration={startTime}
                        date={endTime}
                      />
                    </h5>
                  </div>
                )}
              </div>
            )}

            {isLeft && (
              <ChatMessageOptions
                to={to}
                from={from}
                status={status}
                chatId={chatId}
                messageSid={messageSid}
                message={message}
                callSid={callSid}
                direction={direction}
                numSegments={numSegments}
                startTime={startTime}
                endTime={endTime}
              />
            )}
          </Flex>
          <div
            className={classNames('text-400 fs-11', {
              'text-end': !isLeft
            })}
          >
            {<Moment format="LT" date={time} />}
            {!isLeft && (!!message || !!mediaUrl) && !!status && (
              <FontAwesomeIcon
                icon={classNames({
                  'check': status === 'sent',
                  'check-double': status === 'delivered',
                  'times': status === 'undelivered'
                })}
                transform="shrink-5 down-4"
                className={classNames('ms-2', {
                  'text-success': status === 'delivered',
                  'text-danger': status === 'undelivered'
                })}
              />
            )}
          </div>
        </div>
      </div>
    </Flex>
  );
};

export default Message;
