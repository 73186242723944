import React from 'react';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import Lottie from 'lottie-react';
import supportLottie from '../../assets/img/lottie/support.json';


const Support = () => {
    const styles = {
        container: {
            minHeight: '100vh',
            paddingTop: '50px',
            fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        },
        card: {
            maxWidth: '600px',
            margin: '0 auto',
            borderRadius: '10px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        },
        cardBody: {
            padding: '40px',
            textAlign: 'center',
        },
        header: {
            fontSize: '28px',
            fontWeight: 'bold',
            marginBottom: '20px',

        },
        subHeader: {
            fontSize: '18px',
            marginBottom: '30px',
        },
        button: {
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            color: '#fff',
            fontSize: '16px',
            padding: '12px 24px',
            borderRadius: '6px',
        },
        emailLink: {
            color: '#007bff',
            textDecoration: 'none',
            fontWeight: 'bold',
        },
        image: {
            width: '100%',
            maxWidth: '200px',
            marginBottom: '30px',
        },
    };

    return (
        <div style={styles.container}>
            <Container>
                <Row>
                    <Col>
                        <Card style={styles.card}>
                            <CardBody style={styles.cardBody}>
                                <div className="wizard-lottie-wrapper">
                                    <div className="wizard-lottie mx-auto">
                                        <Lottie animationData={supportLottie}
                                            loop={999}
                                        />
                                    </div>
                                </div>
                                <div style={styles.header}>We're Here to Help</div>
                                <div style={styles.subHeader}>
                                    Need assistance or have questions? Reach out to us anytime, and you'll get to talk
                                    to a real person.
                                </div>
                                <Button
                                    style={styles.button}
                                    onClick={() => (window.location.href = 'mailto:support@BulkText.com')}
                                >
                                    Contact Support
                                </Button>
                                <div style={{ marginTop: '20px' }}>
                                    Or email us directly at{' '}
                                    <a href="mailto:support@BulkText.com" style={styles.emailLink}>
                                        Support@BulkText.com
                                    </a>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Support;
