import React, { useEffect, useContext, useState } from 'react';
import { Row, Col, Button, Spinner, Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { DLCUploadContext } from 'context/Context';
import { useNavigate } from 'react-router-dom';
import { createBrand, createDLCRegistration } from 'actions';
import { useDispatch, useSelector } from 'react-redux';
import { DLC_CAMPAIGN_CREATE_RESET } from 'actions/types';
import Subscription from 'components/account-marketing/billing/Subscription';

const StepFourForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { upload } = useContext(DLCUploadContext);

  // If your Redux state holds brand data
  const { brand, loading: brandLoading } = useSelector(state => state.brand);

  const user = useSelector(state => state.auth.user);
  const [showChargeModal, setShowChargeModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [plan, setPlan] = useState({
    name: 'payg',
    type: 'payg',
    amount: 2000,
    value: "20",
    label: "$20 ",
    message: "$20",
    credits: "500",
    priceKey: "4G92AiBdl1ADYaNeD4ymOJob"
  });


  // If your Redux state holds DLC creation info
  const DLCCampaignCreate = useSelector(state => state.DLCCampaignCreate);
  const {
    loading: DLCCampaignCreateLoading,
    success: DLCCampaignCreateSuccess,
    error: DLCCampaignCreateError
  } = DLCCampaignCreate;

  useEffect(() => {
    if (DLCCampaignCreateSuccess) {
      navigate('/follow-up/numbers');
      dispatch({ type: DLC_CAMPAIGN_CREATE_RESET });
    }
  }, [DLCCampaignCreateSuccess, navigate, dispatch]);

  // Close button handler that respects the processing state
  const handleCloseModal = () => {
    if (!processing) {
      setShowChargeModal(false);
    }
  };

  const closeBtnA = (
    <button className="btn-close bg-light" onClick={handleCloseModal}>
    </button>
  );


  // Decide total cost based on brand existence
  const brandExists = Boolean(brand?.length);
  // brand cost is $20 if brand doesn't exist, $0 if brand does exist
  const brandCost = brandExists ? 0 : 20;
  // monthly campaign fee is $10
  const campaignCost = 10;
  const totalCost = brandCost + campaignCost; // either 30 or 10

  const handleSubmit = () => {
    // Each credit is $0.04 => 375 credits = $15
    if ((user?.credits ?? 0) < (totalCost * 0.04)) {
      setShowChargeModal(true);
      return;
    }

    // If brand not created, do brand + DLC registration
    if (!brandExists) {
      dispatch(createBrand(upload));
      dispatch(createDLCRegistration(upload));
    } else {
      // If brand exists, just do DLC registration
      dispatch(createDLCRegistration(upload));
    }
  };



  return (
    <div>
      {/* SECTION 1: Summary */}
      <h4 className="mb-3">1. Summary</h4>

      <Row>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Brand name</strong>
          </h6>
          <p>{upload?.businessName || '—'}</p>
        </Col>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Contact email address</strong>
          </h6>
          <p>{upload?.businessContactEmail || '—'}</p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Contact phone</strong>
          </h6>
          <p>{upload?.businessContactPhone || '—'}</p>
        </Col>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Use case</strong>
          </h6>
          <p>{upload?.usAppToPersonUseCase || '—'}</p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Campaign description</strong>
          </h6>
          <p>{upload?.description || '—'}</p>
        </Col>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Opt-in flow description</strong>
          </h6>
          <p>{upload?.messageFlow || '—'}</p>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Sample messages</strong>
          </h6>
          {Array.isArray(upload?.sampleMessages) && upload.sampleMessages.length > 0 ? (
            upload.sampleMessages.map((msg, index) => (
              <div
                key={index}
                className="p-2 rounded-2 chat-message bg-primary text-white mb-2"
              >
                {msg.text && (
                  <p
                    className="mb-0"
                    dangerouslySetInnerHTML={{ __html: msg.text }}
                  />
                )}
              </div>
            ))
          ) : (
            <p>—</p>
          )}
        </Col>
      </Row>


      <hr className="my-4" />

      {/* SECTION 2: Costs and terms */}
      <h4 className="mb-3">2. Costs and terms</h4>
      <Row>
        <Col xs={12} md={6}>
          {!brand?.length && (
            <div>
              <h6 className="fs-9">
                <strong>Brand registration</strong>
              </h6>
              <p style={{ color: '#28a745', fontWeight: 'bold' }} >$20</p>
            </div>
          )}
          <h6 className="fs-9">
            <strong>Campaign vetting</strong>
          </h6>
          <p style={{ color: '#28a745', fontWeight: 'bold' }} >FREE</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Campaign monthly fee</strong>
          </h6>
          <p style={{ color: '#28a745', fontWeight: 'bold' }} >$10</p>
        </Col>
      </Row>

      <hr className="my-4" />

      <Row>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Total</strong>
          </h6>
          <p>{`$${totalCost} (due now)`}</p>
        </Col>
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Current account balance</strong>
          </h6>
          <p style={{ color: '#28a745', fontWeight: 'bold' }}>
            ${((user?.credits ?? 0) * 0.04).toFixed(2)}
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={6}>
          <h6 className="fs-9">
            <strong>Minimum credit bundle</strong>
          </h6>
          <p>$20</p>
        </Col>
      </Row>

      {DLCCampaignCreateError && (
        <Alert color="danger" className="mt-4">
          There was an issue creating the DLC campaign. Please try again later.
        </Alert>
      )}

      <div className="d-flex justify-content-end mt-4">
        <Button
          onClick={handleSubmit}
          color="primary"
          className="px-5"
        >
          {DLCCampaignCreateLoading ? <Spinner size="sm" /> : 'Submit'}
        </Button>
      </div>

      <Modal
        size="md"
        isOpen={showChargeModal}
        centered
        toggle={handleCloseModal}
        backdrop={processing ? 'static' : true}
        keyboard={!processing}
      >
        <ModalHeader close={closeBtnA}>Review & Pay</ModalHeader>
        <ModalBody className="p-0">
          <Subscription
            togglePlan={() => { }}
            isYearly={false}
            showChargeModal={showChargeModal}
            setShowChargeModal={setShowChargeModal}
            plan={plan}
            setPlan={setPlan}
            processing={processing} // Pass processing state
            setProcessing={setProcessing} // Pass setProcessing function
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default StepFourForm;
