import {
  LIST_BRAND_REQUEST,
  LIST_BRAND_SUCCESS,
  LIST_BRAND_FAIL
} from '../actions/types';

const initialState = {
  brand: [],
  loading: false,
  error: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LIST_BRAND_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case LIST_BRAND_SUCCESS:
      return {
        ...state,
        brand: action.payload,
        loading: false
      };
    case LIST_BRAND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
