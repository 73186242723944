import {
  ADD_BULK_MESSAGE_CONTENT,
  ADD_TO_FIELD_HTML,
  RESET_BULK_TEXT_MESSAGE_CONTENT
} from '../actions/types';

export default function (state = { html: ' ' }, action) {
  switch (action.type) {
    // when we logout, this action.payload is an empty string so lets do || false
    case ADD_TO_FIELD_HTML:
      return { ...state, html: action.payload.html };
    case ADD_BULK_MESSAGE_CONTENT:
      console.log('this happened in bulkText reducer', action.payload);

      return { ...state, contentState: action.payload.contentState };
    case RESET_BULK_TEXT_MESSAGE_CONTENT:
      return { html: '' };
    default:
      return state;
  }
}
