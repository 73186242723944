import {
  FIELD_DELETE_REQUEST,
  FIELD_DELETE_SUCCESS,
  FIELD_DELETE_FAIL,
  FIELD_DELETE_RESET
} from '../actions/types';

export default function fieldDeleteReducer(state = {}, action) {
  switch (action.type) {
    case FIELD_DELETE_REQUEST:
      return { loading: true };
    case FIELD_DELETE_SUCCESS:
      return { loading: false, success: true };
    case FIELD_DELETE_FAIL:
      return { loading: false, error: action.payload };
    case FIELD_DELETE_RESET:
      return {};
    default:
      return state;
  }
}
