import { UPDATE_INVOICE_ACTION, INVOICE_ACTION_RESET } from '../actions/types';

export default function invoiceActionReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_INVOICE_ACTION:
      return action.payload;
    case INVOICE_ACTION_RESET:
      return {};
    default:
      return state;
  }
}
