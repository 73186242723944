import React, { useState, useMemo, useCallback, createRef, useEffect, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom'
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Input,
  Form,
  Button,
  Label,
  Modal,
  ModalBody,
  ModalHeader
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { useDispatch, useSelector } from 'react-redux';
import Divider from 'components/common/Divider';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddContactToTable from './AddContactToTable';
import TemplateTableV3 from 'components/templates/TemplateTableV3';
import AddGroupToTable from './AddGroupToTable';
import _ from 'lodash';
import { Modifier, convertFromRaw, convertToRaw, ContentState, EditorState } from 'draft-js';
import Calendar from 'components/calendar/Calendar';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import '@draft-js-plugins/mention/lib/plugin.css'; //MentionCSS
import '@draft-js-plugins/emoji/lib/plugin.css'; // Emoji CSS
import '@draft-js-plugins/linkify/lib/plugin.css'; // Linkify CSS
import editorStyles from 'components/compose/SimpleMentionEditor.module.scss';
import mentionsStyles from 'components/compose/MentionsStyles.module.scss';
import draftToHtml from 'draftjs-to-html';
import { createField, listNumbers, listGroupsExceptUnsub, listFields, listPrimaryNumbers } from 'actions/index';
import PreviewAndSend from './PreviewAndSend';
import BulkTextSending from './BulkTextSending';
import AttachFile from './AttachFile';
import animationDownArrow from 'components/authentication/wizard/lottie/downArrow.json';
import { SET_BULK_NUMBER, ADD_MESSAGE_CONTENTS, REMOVE_GROUPS, REMOVE_CONTACTS, RESET_COMPOSE_GROUP, ATTACH_FILE_CREATE_RESET, UPDATE_RECIPIENT_COUNT } from 'actions/types';
import useWindowDimensions from 'hooks/useWindowDimensions';
import EmojiStyles from 'components/chat/content/EmojiStyles.module.scss'
import EmojiIcon from 'components/icons/EmojiIcon';


const emojiTheme = {
  ...defaultTheme,
  emojiSelect: EmojiStyles.emojiSelect,
  emojiSelectButton: EmojiStyles.emojiSelectButton,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectButtonPressed: EmojiStyles.emojiSelectButtonPressed,
  emojiSelectPopover: EmojiStyles.emojiSelectPopover,
  emojiSelectPopoverGroupItem: EmojiStyles.emojiSelectPopoverGroupItem,

};




const MessageCreateForm = ({ fromCalendar, forwardState }) => {

  const dispatch = useDispatch()
  const navigate = useNavigate();
  //-----------------------------------------------REFS---------------------------------------------------------------------------------------------------------------  
  const messageFieldRef = createRef()
  //-----------------------------------------------LOCAL-STATE---------------------------------------------------------------------------------------------------------------
  const [contactModal, setContactModal] = useState(false);
  const [groupsModal, setGroupsModal] = useState(false);
  const [highlighted, setHighlighted] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [fromCompose, setFromCompose] = useState(true); // <--- state to pass to insert Template for template table to know we are coming from messageCreateForm.js
  const [showFileModal, setShowFileModal] = useState(false);
  const [showFieldModal, setShowFieldModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarView, setCalendarView] = useState('Month View')
  const [showSendModal, setShowSendModal] = useState(false);
  const [showBulkTextModal, setShowBulkTextModal] = useState(false);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [field, setField] = useState({ title: '' });
  const [messageLength, setMessageLength] = useState("");
  const [recipients, setRecipients] = useState(0);
  const [contactState, setContactState] = useState([]);
  const [editorState, setEditorState] = useState(() => { return EditorState.createEmpty() }); // check bookmark for updating html npm install html-to-draftjs
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  //-----------------------------------------------REDUX STATE---------------------------------------------------------------------------------------------------------------
  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);
  const numbers = useSelector((state) => state.numbers);
  const compose = useSelector((state) => state.compose);
  const campaignEvents = useSelector((state) => state.campaignEvents)
  const attachFileCreate = useSelector((state) => state.attachFileCreate);
  const { success: successAttachFile } = attachFileCreate
  const fieldCreate = useSelector((state) => state.fieldCreate);
  const { success: successFieldCreate } = fieldCreate
  const groupMoveCopy = useSelector((state) => state.groupMoveCopy)
  const { success: successMoveCopy } = groupMoveCopy
  const createBulk = useSelector((state) => state.createBulk)


  //-----------------------------------------------Generic---------------------------------------------------------------------------------------------------------------

  const windowWidth = useWindowDimensions();
  const rawContentState = convertToRaw(editorState.getCurrentContent());
  const markup = draftToHtml(rawContentState)
  const FinalMessageText = markup.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, ' ');
  const contentState = editorState.getCurrentContent();
  const len = contentState.getPlainText(' ')

  const handleFieldChange = e => { setField({ ...field, [e.target.name]: e.target.value }) }
  const toggleTemplate = () => { return setShowTemplateModal(!showTemplateModal), setFromCompose(false) }
  const onOpenChange = useCallback((_open) => { setOpen(_open); }, []);
  const onSearchChange = useCallback(({ value }) => { }, []);
  const closeBtn = (<button className="btn-close bg-light" onClick={toggleTemplate}></button>);
  const closeFileBtn = (<button className="btn-close bg-light" light onClick={() => { setShowFileModal(!showFileModal) }}></button>);
  const closeCntBtn = (<button className="btn-close bg-light" onClick={() => { setContactModal(!contactModal) }}></button>);
  const closeGrpBtn = (<button className="btn-close bg-light" onClick={() => { setGroupsModal(!groupsModal) }}></button>);
  const closeFieldBtn = (<button className="btn-close bg-light" onClick={() => { setShowFieldModal(!showFieldModal) }}></button>);
  const closeSendBtn = (<button className="btn-close bg-light" onClick={() => { setShowSendModal(!showSendModal) }}></button>);
  const closeBulkTextBtn = (<button className="btn-close bg-light" onClick={() => { setShowBulkTextModal(!showBulkTextModal) }}></button>);
  const closePreviewBtn = (<button className="btn-close bg-light" onClick={() => { setShowPreviewModal(!showPreviewModal) }}></button>);
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------    

  const handleSubmit = e => {
    if (e.target.id === "previewBtn") {
      setShowPreviewModal(!showPreviewModal)
    } else {
      setShowSendModal(!showSendModal)
    }
  };

  //-----------------------------------------------------------------DRAFT JS MENTION POPUP------------------------------------------------------------------------------------------

  function Entry(props) {
    const {
      mention,
      theme,
      searchValue,
      isFocused,
      ...parentProps
    } = props;

    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>

          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>

            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }

  //------TRIGGERS WHEN WE ATTACH FILE LINK FOR MESSAGE--------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const insertText = (textToInsert) => {
    const currentContent = editorState.getCurrentContent();
    const currentSelection = editorState.getSelection();
    let newContent = Modifier.replaceText(currentContent, currentSelection, textToInsert);
    const textToInsertSelection = currentSelection.set('focusOffset', currentSelection.getFocusOffset() + textToInsert?.length);
    let inlineStyles = editorState.getCurrentInlineStyle();
    inlineStyles.forEach(inLineStyle => newContent = Modifier.applyInlineStyle(newContent, textToInsertSelection, inLineStyle));
    let newState = EditorState.push(editorState, newContent, 'insert-characters');
    newState = EditorState.forceSelection(newState, textToInsertSelection.set('anchorOffset', textToInsertSelection.getAnchorOffset() + textToInsert.length));
    return newState;
  }

  //------CHAT COMPONENT FORWARD MESSAGE UPDATE STATE--------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (forwardState) {
      // const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
      // const decorator = new CompositeDecorator(decorators);
      setEditorState(EditorState.createWithContent(
        ContentState.createFromText(forwardState)))//, decorator))
    }
  }, [])

  //-----------------------------------------------------------------USE EFFECT------------------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(listNumbers())
    dispatch(listPrimaryNumbers())
  }, [])
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(listGroupsExceptUnsub())
  }, [successMoveCopy])
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    dispatch(listFields())
  }, [successFieldCreate])


  //-------------------------------COMPOSE TEMPLATE TO UPDATE EDITOR STATE-------------------------------------------------------------------------------------------------------------------------------------------

  // when redux state changes, trigger this useEffect
  useEffect(() => {

    if (compose[0]?.composeTemplate) {

      if (!compose[0]?.composeTemplate.body.entityMap) {
        const contentState = {
          entityMap: {},
          blocks: compose[0]?.composeTemplate.body.blocks
        }
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState)))
      } else {
        const contentState = {
          entityMap: compose[0]?.composeTemplate?.body?.entityMap,
          blocks: compose[0]?.composeTemplate.body.blocks
        }
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState)))
      }
    }
    dispatch({ type: RESET_COMPOSE_GROUP })
  }, [compose.length])

  //-------------------------------When first in createBulk trigger this to check for contentState in bulkMessageReducer-------------------------------------------------------------------------------------------------------------------------------------------


  //----------------------------------------SET SUGGESTIONS----------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    const OurFields = fields.map((el) => { return { name: `{${el.title}}`, id: el._id } })
    setSuggestions([{ name: "{First name}", id: "700" }, { name: "{Last name}", id: "701" }, { name: "{Company name}", id: "702" }, { name: "{Phone}", id: "703" }, { name: "{Email}", id: "704" }, ...OurFields])
  }, [fields])


  //-----------------------------------------TRIGGER INSERT TEXT ---------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => { // Inserts link into Message draft-js as text
    if (successAttachFile) {
      setEditorState(insertText(attachFileCreate?.payload?.tempUrl))
      dispatch({ type: ATTACH_FILE_CREATE_RESET })
    }

  }, [successAttachFile])
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (campaignEvents?.length) {
      let contentState
      if (!campaignEvents[0]?.event?.messageContents?.body?.entityMap) {
        contentState = {
          entityMap: {},
          blocks: campaignEvents[0]?.event?.messageContents?.body?.blocks
        }
        // const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
        // const decorator = new CompositeDecorator(decorators);
        setEditorState(EditorState.createWithContent(convertFromRaw(contentState)))
      } else {
        // const decorators = plugins.reduce((acc, p) => [...acc, ...p.decorators], []);
        // const decorator = new CompositeDecorator(decorators);
        setEditorState(EditorState.createWithContent(
          convertFromRaw(campaignEvents[0]?.event?.messageContents?.body)))
      }
    }

  }, [])
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => {
    const rawsContentState = convertToRaw(editorState.getCurrentContent());
    const obj = rawsContentState?.entityMap
    const mentionIds = []
    Object.keys(obj).forEach(key => {      // the name of the current key.
      if (obj[key].data.mention) { mentionIds.push({ key: key, mentionId: obj[key].data.mention.id }) }
    });
    dispatch({ type: ADD_MESSAGE_CONTENTS, payload: { plainText: FinalMessageText, mentions: mentionIds, body: rawsContentState } })

  }, [editorState])

  //  useEffect(() => {
  //    const rawsContentState = convertToRaw(editorState.getCurrentContent());
  //    const obj = rawsContentState?.entityMap
  //    const mentionIds = []
  //    Object.keys(obj).forEach(key => {      // the name of the current key.
  //      if (obj[key].data.mention) { mentionIds.push({ key: key, mentionId: obj[key].data.mention.id}) }
  //    });
  //    dispatch({type: ADD_MESSAGE_CONTENTS, payload: { plainText: FinalMessageText, mentions: mentionIds, mentionLocations: rawsContentState?.blocks?.map((el) => el.entityRanges) } })
  //  }, [editorState])

  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  useEffect(() => { // <-------- setting message length
    if (len) {
      setMessageLength(len.length)
    } else {
      setMessageLength(0)
    }
  }, [editorState])

  //--------------------------------------------ADD GROUPS to TO FIELD FOR EDIT CAMPAIGN------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    let recip = 0
    if (createBulk?.messageDetails?.groups?.length) {
      createBulk.messageDetails.groups.forEach((group) => { recip += group.contactsCount })
    }

    if (createBulk?.messageDetails?.contacts?.length) {
      recip += createBulk.messageDetails.contacts.length
    }

    setRecipients(recip)

  }, [createBulk?.messageDetails?.contacts?.length, createBulk?.messageDetails?.groups?.length])



  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      theme: mentionsStyles,
      //mentionPrefix: '@',
      supportWhitespace: true,
    });
    const emojiPlugin = createEmojiPlugin({
      selectButtonContent: <EmojiIcon width={30} height={30} />, // Set size as needed
      theme: emojiTheme,
      useNativeArt: true,
    });
    const linkifyPlugin = createLinkifyPlugin()

    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin];
    return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
  }, []);




  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------


  const handleContactRemove = id => {
    dispatch({ type: REMOVE_CONTACTS, payload: createBulk.messageDetails.contacts.filter(contact => contact.id !== id) })
    setRecipients(recipients - 1)
  };

  const handleGroupRemove = id => {
    dispatch({ type: REMOVE_GROUPS, payload: createBulk.messageDetails.groups.filter(group => group._id !== id) })
    setRecipients(recipients - createBulk.messageDetails.groups.filter(group => group._id === id)[0].contactsCount)
  };


  const ContactTag = ({ contact, onContactRemove }) => {

    let displayName = contact.original.phone_number;
    if (contact.original.firstName || contact.original.lastName) {
      displayName = `${contact.original.firstName || ''} ${contact.original.lastName || ''}`.trim();
    }

    return (
      <Badge color="primary" className="m-2 p-0" style={{ display: 'inline-flex', width: 'auto' }}>
        <Card className="m-1" style={{ width: 'fit-content' }}>
          <CardBody style={{ padding: '.25rem 1rem', display: 'flex', alignItems: 'center' }}>
            <Button close size="sm" className="me-2" onClick={() => onContactRemove(contact.id)}>
              <FontAwesomeIcon icon='times' style={{ color: '#ff0000' }} />
            </Button>
            <CardTitle tag="h5" className="m-0">{displayName}</CardTitle>
          </CardBody>
        </Card>
      </Badge>
    );
  };



  const GroupTag = ({ group, onGroupRemove }) => {


    return (
      <Badge color="success" className="m-2 p-0" style={{ display: 'inline-flex', width: 'auto' }}>
        <Card className="m-1" style={{ width: 'fit-content' }}>
          <CardBody style={{ padding: '.25rem 1rem', display: 'flex', alignItems: 'center' }}>
            <Button className="me-2" close size="sm" onClick={() => onGroupRemove(group._id)}>
              <FontAwesomeIcon icon='times' style={{ color: '#ff0000' }} />
            </Button>
            <CardTitle tag="h5" className="m-0">{group.title}</CardTitle>
            <SubtleBadge bg="secondary" className="ms-2 fs-10" >{group.contactsCount}</SubtleBadge>
          </CardBody>
        </Card>
      </Badge>
    );
  };


  const ContactsAndGroupsContainer = ({ contacts, groups, onContactRemove, onGroupRemove }) => (
    <div style={{ overflowY: 'auto', maxHeight: '33vh', minHeight: '20vh' }}>

      {contacts.map(contact => (
        <ContactTag key={contact.id} contact={contact} onContactRemove={onContactRemove} />
      ))}
      {groups.map(group => (
        <GroupTag key={group._id} group={group} onGroupRemove={onGroupRemove} />
      ))}
    </div>
  );

  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------MAIN RENDER BELOW---------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------
  //-----------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {

    if (createBulk?.messageDetails?.contacts?.length && !createBulk?.messageDetails?.groups?.length) {
      dispatch({ type: UPDATE_RECIPIENT_COUNT, payload: createBulk?.messageDetails?.contacts?.length })
    } else if (!createBulk?.messageDetails?.contacts?.length && createBulk?.messageDetails?.groups?.length) {
      let groupContactsTotal = 0
      const randy = createBulk?.messageDetails?.groups?.map((group) => { return groupContactsTotal += group.contactsCount })
      dispatch({ type: UPDATE_RECIPIENT_COUNT, payload: groupContactsTotal })
    } else {
      let groupContactsTotal = 0
      const randy = createBulk?.messageDetails?.groups?.map((group) => { return groupContactsTotal += group.contactsCount })
      dispatch({ type: UPDATE_RECIPIENT_COUNT, payload: createBulk?.messageDetails?.contacts?.length + groupContactsTotal })
    }


  }, [createBulk?.messageDetails?.contacts?.length, createBulk?.messageDetails?.groups?.length])



  return (
    <Fragment>

      <Card>
        <CardBody className="p-3">
          <Form onSubmit={handleSubmit}>

            <h5>To:</h5>
            <div>
              {createBulk?.messageDetails?.contacts?.length || createBulk?.messageDetails?.groups?.length ? (
                <ContactsAndGroupsContainer contacts={createBulk?.messageDetails?.contacts} groups={createBulk?.messageDetails?.groups} onContactRemove={handleContactRemove} onGroupRemove={handleGroupRemove} />
              ) : (
                <div style={{ overflowY: 'auto', maxHeight: '10vh' }}>
                  <Lottie animationData={animationDownArrow}
                    loop={999}
                    style={{ width: '100px', height: '100px' }}
                  />
                </div>
              )}
            </div>


            {createBulk?.recipientCount < 1 ?
              <SubtleBadge pill className="fs-9">
                {`Recipients: ${createBulk?.recipientCount}`}
              </SubtleBadge> :
              <SubtleBadge bg="success" pill className="fs-10">
                {`Recipients: ${createBulk?.recipientCount}`}
                <FontAwesomeIcon icon="users" transform="shrink-1" className="ms-1" />
              </SubtleBadge>
            }
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button onClick={() => { setContactModal(!contactModal) }} align="center" className="btn-tertiary border-dark rounded-capsule shadow-none fs--1 mb-0">
                <FontAwesomeIcon icon="user" > </FontAwesomeIcon>
                {` Contacts`}</Button>
            </div>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button onClick={() => { setGroupsModal(!groupsModal) }} className="btn-tertiary border-dark rounded-capsule shadow-none fs--1 mb-0" >
                <FontAwesomeIcon icon="users" />
                {` Groups`}
              </Button>
            </div>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button tag={Link} to="/follow-up/contacts-import" className="btn-tertiary border-1 border-dark rounded-capsule shadow-none fs--1 mb-0" >
                <FontAwesomeIcon icon="user-plus" />
                {` Import file (.csv)`}
              </Button>
            </div>

            <div>
              <Card className="mt-2">
                <CardBody>
                  <h5 className="mt-2">From:</h5>
                  {campaignEvents.length ? (
                    <Input
                      type="select"
                      id="from"
                      name="from"
                      className="mb-3"
                      onChange={({ target }) => {
                        if (target.value === 'new-number') {
                          navigate('/follow-up/numbers'); // Navigate to the new number page
                        } else if (target.value !== '') {
                          dispatch({ type: SET_BULK_NUMBER, payload: target.value });
                        }
                      }}
                    >

                      <>
                        <option value="">Select your number</option>
                        <optgroup className="fw-normal" color="grey" label="Numbers">
                          {numbers.map((el) => {
                            const truePrimary = Object.keys(el.primary).filter(
                              (k) => el.primary[k] === true
                            );
                            return (
                              <Fragment key={el.phoneNumber}>
                                <option
                                  selected={
                                    JSON.parse(
                                      campaignEvents[0]?.event?.messageDetails?.bulkNumber
                                    )?.phoneNumber === el.phoneNumber
                                      ? true
                                      : createBulk?.messageDetails?.bulkNumber === el.phoneNumber
                                        ? true
                                        : false
                                  }
                                  value={JSON.stringify(el)}
                                >
                                  {`${el.phoneNumber}${el.nickName ? ' (' + el.nickName + ')' : ''
                                    }${truePrimary.length
                                      ? ` Current Primary for ${truePrimary}`
                                      : ''
                                    }`}
                                </option>
                              </Fragment>
                            );
                          })}
                          {/* Add the '+ New Number' option at the end */}
                          <option value="new-number">+ New Number</option>
                        </optgroup>
                      </>

                    </Input>
                  ) : (
                    <Input
                      type="select"
                      id="from"
                      name="from"
                      className="mb-3"
                      onChange={({ target }) => {
                        if (target.value === 'new-number') {
                          navigate('/follow-up/numbers'); // Navigate to the new number page
                        } else if (target.value !== '') {
                          dispatch({ type: SET_BULK_NUMBER, payload: target.value });
                        }
                      }}
                    >

                      <>
                        <option value="">Select your number</option>
                        <optgroup className="fw-normal" color="grey" label="Bulk Text Numbers">
                          {numbers.map((el) => {
                            const truePrimary = Object.keys(el.primary).filter(
                              (k) => el.primary[k] === true
                            );
                            return (
                              <Fragment key={el.phoneNumber}>
                                <option
                                  selected={
                                    createBulk?.messageDetails?.bulkNumber === el.phoneNumber
                                      ? true
                                      : false
                                  }
                                  value={JSON.stringify(el)}
                                >
                                  {`${el.phoneNumber}${el.nickName ? ' (' + el.nickName + ')' : ''
                                    }${truePrimary.length
                                      ? ` Current Primary for ${truePrimary}`
                                      : ''
                                    }`}
                                </option>
                              </Fragment>
                            );
                          })}
                          {/* Add the '+ New Number' option at the end */}
                          <option value="new-number">+ New Number</option>
                        </optgroup>
                      </>

                    </Input>
                  )}
                </CardBody>
              </Card>
            </div>

            <Row className="d-flex align-items-center justify-content-between">
              <Col className="mt-2">
                <h5 className="mt-3">Message:</h5>
              </Col>
              <Col className="d-flex justify-content-end mt-2">
                <Button onClick={() => { setShowFieldModal(!showFieldModal) }} className="btn-tertiary border-dark shadow-none fs--1 me-3 mb-2 mt-1" style={{ whiteSpace: 'nowrap' }} >
                  <FontAwesomeIcon icon='plus' transform="down-1 left-2" />
                  {` Field`}
                </Button>
              </Col>
            </Row>
            <div
              style={{ minHeight: "7em", maxHeight: "10em", overflow: "auto" }}
              className={`border border-2x border-300 bg-light rounded-soft fs--1 ${editorStyles.editor}`}
              onClick={() => { messageFieldRef.current.focus(); }}
            >
              <Editor
                editorKey={'editor'}
                currentContent={ContentState}
                editorState={editorState}
                onChange={setEditorState}
                plugins={plugins}
                ref={messageFieldRef}
              />
              <EmojiSuggestions />
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
                onAddMention={(e) => {// get the mention object selected
                }}
                entryComponent={Entry}
              />
            </div>
            <div>
              <EmojiSelect closeOnEmojiSelect />
              <span color="light" className="px-3 py-1 bg-soft-info rounded-capsule shadow-none fs--1 ml-3" >

                Type <strong>@</strong> for custom fields
              </span>
            </div>
            <Col className="mt-2">
              <Badge color="soft-success" pill className="fs--1">{`Characters: ${messageLength}/918`}</Badge>

              <Badge color="soft-info" pill className="fs--1 ml-2">{`Parts ${Math.ceil(messageLength / 153)}/6`}</Badge>

              <Label className="ml-4" >{`Credit cost: ${(Math.ceil(messageLength / 153) * recipients)}`}</Label>

            </Col>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button onClick={() => { return setFromCompose(true), setShowTemplateModal(!showTemplateModal) }} className="btn-tertiary border-dark rounded-capsule shadow-none fs--1 mb-0" >
                <FontAwesomeIcon icon={['fab', 'trello']} transform="left-3" />
                {` Insert Template`}
              </Button>
            </div>
            <div className="justify-content-between mt-1 px-1 pb-1">
              <Button onClick={() => { setShowFileModal(!showFileModal) }} className="btn-tertiary border-dark rounded-capsule shadow-none fs--1 ml- mb-0" >
                <FontAwesomeIcon icon="paperclip" transform="left-3" />
                {` Attach File`}
              </Button>
            </div>

            {!fromCalendar ? (
              <div>
                <Divider className="mt-4" />

                {showCalendar && (
                  <Calendar
                    recipients={recipients}
                    calendarView={calendarView}
                    setCalendarView={setCalendarView}
                    showCalendar={showCalendar}
                    setShowCalendar={setShowCalendar}
                    showTimeModal={showTimeModal}
                    setShowTimeModal={setShowTimeModal}
                    isOpenScheduleModal={isOpenScheduleModal}
                    setIsOpenScheduleModal={setIsOpenScheduleModal}
                  />
                )}

                <Row className="mt-3">
                  <Col>
                    <div className="d-flex flex-column flex-sm-row justify-content-end align-items-center">
                      {!forwardState && (
                        <Button
                          onClick={() => setShowCalendar(!showCalendar)}
                          className={`d-flex btn-tertiary border align-items-center mb-2 mb-sm-0 me-sm-3 responsive-button`}
                          variant={!showCalendar ? "info" : "danger"}
                          size="sm"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          <FontAwesomeIcon
                            icon={!showCalendar ? "calendar-alt" : "caret-up"}
                            className={`me-2 ${!showCalendar ? "text-info" : "text-danger"}`}
                          />
                          {!showCalendar
                            ? 'Set for later'
                            : windowWidth.width < 500
                              ? 'Cancel'
                              : 'Cancel Scheduling'}
                        </Button>
                      )}
                      <Button
                        disabled={
                          (!createBulk?.messageDetails?.contacts?.length &&
                            !createBulk?.messageDetails?.groups.length) ||
                          !createBulk?.messageDetails?.bulkNumber
                        }
                        className={`d-flex btn-tertiary border align-items-center mb-2 mb-sm-0 me-sm-3 responsive-button ${!showCalendar ? "" : "d-none"
                          }`}
                        onClick={handleSubmit}
                        id="previewBtn"
                        variant="primary"
                        size="sm"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <FontAwesomeIcon icon="eye" className="me-2 text-info" />
                        Preview
                      </Button>
                      <Button
                        disabled={
                          (!createBulk?.messageDetails?.contacts?.length &&
                            !createBulk?.messageDetails?.groups.length) ||
                          !createBulk?.messageDetails?.bulkNumber
                        }
                        className={`d-flex btn-tertiary border align-items-center mb-2 mb-sm-0 responsive-button ${!showCalendar ? "me-sm-3" : "d-none"
                          }`}
                        onClick={handleSubmit}
                        variant="primary"
                        size="sm"
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        <FontAwesomeIcon icon="paper-plane" className="me-2 text-primary" />
                        Send
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            ) : null}

          </Form>
        </CardBody>
      </Card>

      {
        // Create Field Modal
      }
      <Modal isOpen={showFieldModal} centered toggle={() => { setShowFieldModal(!showFieldModal) }}>
        <ModalHeader toggle={() => { setShowFieldModal(!showFieldModal) }} close={closeFieldBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Create a new Field
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label for="title">Field Name:</Label>
              <Input value={field.title.value} onChange={handleFieldChange} className="mb-3" name="title" id="title" />
              <Button disabled={!field?.title} block onClick={() => { return dispatch(createField(field)), setShowFieldModal(false) }} color="primary" className="mb-3">Save</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>




      {
        // Initiate bulk text modal
      }
      <Modal isOpen={showSendModal} centered toggle={() => setShowSendModal(!showSendModal)}>
        <ModalHeader close={closeSendBtn} className="d-flex justify-content-center" >Initiate BulkText</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => { return setShowSendModal(!showSendModal), setShowBulkTextModal(!showBulkTextModal) }} block className="text-white mb-2" color="primary">
                <FontAwesomeIcon icon="paper-plane" transform="down-2 left-5" />
                Send now</Button>
              <Button onClick={() => { setShowPreviewModal(!showPreviewModal) }} block className="btn-tertiary border"  >Preview</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>







      {
        // sending bulk Text modal
      }
      <Modal size="lg" isOpen={showBulkTextModal} centered toggle={() => setShowBulkTextModal(!showBulkTextModal)}>
        <ModalHeader close={closeBulkTextBtn} className="d-flex justify-content-center" >BulkText Details</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <BulkTextSending recipients={recipients} fields={fields} createBulk={createBulk} />
              <hr />
              <Button onClick={() => { setShowBulkTextModal(!showBulkTextModal) }} block className="btn-tertiary border mt-2" >Run in background</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>





      {
        // show file modal
      }
      <Modal isOpen={showFileModal} centered toggle={() => { setShowFileModal(!showFileModal) }}>
        <ModalHeader toggle={() => { setShowFileModal(!showFileModal) }} close={closeFileBtn}>Attach a file - 10MB limit</ModalHeader>
        <ModalBody className="p-0">
          <AttachFile
            highlighted={highlighted}
            setHighlighted={setHighlighted}
            showFileModal={showFileModal}
            setShowFileModal={setShowFileModal}
          />
        </ModalBody>
      </Modal>






      {
        // message preview modal
      }
      <Modal isOpen={showPreviewModal} centered toggle={() => { return setShowPreviewModal(!showPreviewModal) }}>
        <ModalHeader close={closePreviewBtn}>Message Preview</ModalHeader>
        <ModalBody className="p-0">
          <PreviewAndSend messageContents={createBulk?.messageContents} messageDetails={createBulk?.messageDetails} />
        </ModalBody>
      </Modal>






      {
        // template modal
      }
      <Modal size="lg" isOpen={showTemplateModal} centered toggle={() => { return setFromCompose(false), setShowTemplateModal(!showTemplateModal) }}>
        <ModalHeader toggle={() => { return setFromCompose(false), setShowTemplateModal(!showTemplateModal) }} close={closeBtn}>Templates</ModalHeader>
        <ModalBody className="p-0">
          <TemplateTableV3
            fromCompose={fromCompose}
            fromCalendar={fromCalendar}
            showTemplateModal={showTemplateModal}
            setShowTemplateModal={setShowTemplateModal}
          />
        </ModalBody>
      </Modal>






      {
        // groups modal
      }
      <Modal size="lg" isOpen={groupsModal} centered toggle={() => setGroupsModal(!groupsModal)}>
        <ModalHeader close={closeGrpBtn}>Select Groups</ModalHeader>
        <ModalBody className="p-1">
          <AddGroupToTable
            groupsModal={groupsModal}
            setGroupsModal={setGroupsModal}
            recipients={recipients}
            setRecipients={setRecipients}
            contactState={contactState}
            setContactState={setContactState}
            fromCompose={fromCompose}
          />
        </ModalBody>
      </Modal>



      {
        // contacts modal
      }
      <Modal size="lg" isOpen={contactModal} centered toggle={() => setContactModal(!contactModal)}>
        <ModalHeader close={closeCntBtn}>Select contacts</ModalHeader>
        <ModalBody className="p-0">
          <AddContactToTable
            contactModal={contactModal}
            setContactModal={setContactModal}
            recipients={recipients}
            setRecipients={setRecipients}
            fromCompose={fromCompose}
            fromCalendar={fromCalendar}
          />
        </ModalBody>
      </Modal>



    </Fragment>
  );
};

export default MessageCreateForm;

