import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import EstimatesIcon from 'components/icons/EstimatesIcon';
import InvoicesIcon from 'components/icons/InvoicesIcon';
import CustomersIcon from 'components/icons/CustomersIcon';
import LineItemsIcon from 'components/icons/LineItemsIcon';
import DashboardIcon from 'components/icons/DashboardIcon';
import AccountIcon from 'components/icons/AccountIcon';
import ChatIcon from 'components/icons/ChatIcon';
import ContactsIcon from 'components/icons/ContactsIcon';
import CreateBulkIcon from 'components/icons/CreateBulkIcon';
import FollowUpIcon from 'components/icons/FollowUpIcon';
import HistoryIcon from 'components/icons/HistoryIcon';
import NumbersIcon from 'components/icons/NumbersIcon';
import RespondersIcon from 'components/icons/RespondersIcon';
import ScheduledIcon from 'components/icons/ScheduledIcon';
import TemplatesIcon from 'components/icons/TemplatesIcon';






const iconMap = {
  dashboard: DashboardIcon,
  estimates: EstimatesIcon,
  invoices: InvoicesIcon,
  customers: CustomersIcon,
  lineItems: LineItemsIcon,
  account: AccountIcon,
  chat: ChatIcon,
  contacts: ContactsIcon,
  createBulk: CreateBulkIcon,
  followUp: FollowUpIcon,
  history: HistoryIcon,
  numbers: NumbersIcon,
  responders: RespondersIcon,
  scheduled: ScheduledIcon,
  templates: TemplatesIcon

};

const NavbarVerticalMenuItem = ({ route }) => {
  const IconComponent = iconMap[route.icon];

  return (
    <>
      {IconComponent && <IconComponent width={25} height={25} style={{ marginRight: '12px' }} />}
      <span>{route.name}</span>
    </>
  );
};


// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired
};

export default React.memo(NavbarVerticalMenuItem);
