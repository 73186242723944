import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Input,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Row,
  Col,
  Collapse,
  Alert
} from 'reactstrap';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DLCUploadContext } from 'context/Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StepThreeForm = () => {
  const { upload, setUpload, setStep, handleInputChange } =
    useContext(DLCUploadContext);
  const [collapseStep1, setCollapseStep1] = useState(true);
  const [stepOneStatus, setStepOneStatus] = useState(false);
  const [collapseStep2, setCollapseStep2] = useState(false);
  const [stepTwoStatus, setStepTwoStatus] = useState(false);
  const [collapseStep3, setCollapseStep3] = useState(false);
  const [stepThreeStatus, setStepThreeStatus] = useState(false);
  const [collapseStep4, setCollapseStep4] = useState(false);
  const [stepFourStatus, setStepFourStatus] = useState(false);
  const [validateStep1Inputs, setValidateStep1Inputs] = useState(false);
  const [validateStep2Inputs, setValidateStep2Inputs] = useState(false);
  const [validateStep3Inputs, setValidateStep3Inputs] = useState(false);
  const [validateStep4Inputs, setValidateStep4Inputs] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  console.log('upload', upload);
  const handleSaveStep1 = () => {
    setValidateStep1Inputs(true);
    let valid = true;
    if (!upload?.usAppToPersonUseCase) valid = false;
    if (!upload?.description) valid = false;
    if (!upload?.campaignTitle) valid = false;
    if (!upload?.messageFlow) valid = false;
    if (!upload?.canadianTraffic);

    // If user chose LOW_VOLUME_MIXED, ensure 2-5 sub-usecases
    if (upload?.usAppToPersonUseCase === 'LOW_VOLUME_MIXED') {
      if (mixedUseCases.length < 2 || mixedUseCases.length > 5) {
        valid = false;
        alert(
          'Please select at least 2 and no more than 5 sub-use cases for Low Volume Mixed.'
        );
      }
    }

    if (valid) {
      // Merge the chosen sub-use cases into `upload`
      setUpload(prev => ({
        ...prev,
        mixedUseCases: mixedUseCases
      }));
      setStepOneStatus(true);
      setCollapseStep1(false);
    }
  };


  const handleSaveStep2 = () => {
    setValidateStep2Inputs(true);
    let valid = true;
    if (!upload?.optInType) valid = false;
    if (!upload?.optInScreenshot) valid = false;
    if (valid) {
      setStepTwoStatus(true);
      setCollapseStep2(false);
    }
  };

  const handleSaveStep3 = () => {
    setValidateStep3Inputs(true);
    let valid = true;
    if (upload?.messageSamples[0]?.text?.length < 1) valid = false;
    if (valid) {
      setStepThreeStatus(true);
      setCollapseStep3(false);
    }
  };

  const handleSaveStep4 = () => {
    setValidateStep4Inputs(true);
    let valid = true;
    if (!upload?.optInKeywords) valid = false;
    if (!upload?.optInMessage) valid = false;
    if (!upload?.optOutKeywords) valid = false;
    if (!upload?.optOutMessage) valid = false;
    if (!upload?.helpKeywords) valid = false;
    if (!upload?.helpMessage) valid = false;
    if (valid) {
      setStepFourStatus(true);
      setCollapseStep4(false);
    }
  };

  const handleNext = () => {
    handleSaveStep1();
    handleSaveStep2();
    handleSaveStep3();
    handleSaveStep4();

    if (stepOneStatus && stepTwoStatus && stepThreeStatus && stepFourStatus) {
      setStep(4);
    }
  };

  const [sampleMessages, setSampleMessages] = useState([
    { id: 1, text: '[Enter your sample message] Reply STOP to opt out.' },
    { id: 2, text: '[Enter your sample message] Reply STOP to opt out.' }
  ]);

  const handleSampleMessageChange = (id, value) => {
    setSampleMessages(prevMessages =>
      prevMessages.map(msg => (msg.id === id ? { ...msg, text: value } : msg))
    );
  };

  const addSampleMessage = () => {
    setSampleMessages(prevMessages => [
      ...prevMessages,
      {
        id: prevMessages.length + 1,
        text: '[Enter your sample message] Reply STOP to opt out.'
      }
    ]);
  };

  const removeSampleMessage = id => {
    setSampleMessages(prevMessages =>
      prevMessages.filter(msg => msg.id !== id)
    );
  };

  useEffect(() => {
    setUpload({ ...upload, sampleMessages: sampleMessages });
  }, [sampleMessages]);

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    const value = checked ? true : false;
    setUpload({ ...upload, [name]: value });
  };

  const potentialMixedUseCases = [
    '2FA',
    'ACCOUNT_NOTIFICATION',
    'CUSTOMER_CARE',
    'DELIVERY_NOTIFICATION',
    'FRAUD_ALERT',
    'HIGHER_EDUCATION',
    'MARKETING',
    'POLLING_AND_VOTING',
    'PUBLIC_SERVICE_ANNOUNCEMENT',
    'SECURITY_ALERT'
  ];

  const [mixedUseCases, setMixedUseCases] = useState(upload?.mixedUseCases || []);



  return (
    <Fragment>
      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => setCollapseStep1(!collapseStep1)}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 1: Campaign details</h5>
          <div className="d-flex">
            {stepOneStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep1 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep1}>
          <p>
            Please describe your text messaging campaign, its purpose and
            recipients.
          </p>
          <FormGroup>
            <Label htmlFor="campaignTitle">
              Campaign Title.
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="textarea"
              name="campaignTitle"
              id="campaignTitle"
              onChange={handleInputChange}
              value={upload?.campaignTitle}
              invalid={
                validateStep1Inputs &&
                (!upload?.campaignTitle)
              }
              placeholder="Enter here..."
              rows="1"
            />
            <FormFeedback>
              {!upload?.campaignTitle && 'Please provide a description for your campaign.'}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="usAppToPersonUseCase">
              Choose the category that best represents your use case/content
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="select"
              name="usAppToPersonUseCase"
              placeholder="Select use case"
              id="usAppToPersonUseCase"
              onChange={handleInputChange}
              value={upload?.usAppToPersonUseCase}
              invalid={validateStep1Inputs && !upload?.usAppToPersonUseCase}
            >
              <option value="" disabled selected>
                Select use case
              </option>
              <option value="2FA">2FA</option>
              <option value="ACCOUNT_NOTIFICATION">Account Notifications</option>
              <option value="CUSTOMER_CARE">Customer care</option>
              <option value="DELIVERY_NOTIFICATION">
                Delivery notifications
              </option>
              <option value="FRAUD_ALERT">Fraud alert</option>
              <option value="HIGHER_EDUCATION">Higher education</option>
              <option value="MARKETING">Marketing</option>
              <option value="POLLING_AND_VOTING">
                Polling and voting
              </option>
              <option value="PUBLIC_SERVICE_ANNOUNCEMENT">
                Public service announcement
              </option>
              <option value="SECURITY_ALERT">Security alert</option>
              <option value="LOW_VOLUME_MIXED">Low volume mixed</option>
            </Input>
            <FormFeedback>Please select your campaign use case.</FormFeedback>
          </FormGroup>

          {/* Conditionally render the sub-use-case checkboxes if LOW_VOLUME_MIXED is chosen */}
          {upload?.usAppToPersonUseCase === 'LOW_VOLUME_MIXED' && (
            <FormGroup>
              <Label>
                Please select 2–5 sub-use cases that will apply to your low volume mixed campaign:
              </Label>
              {potentialMixedUseCases.map(useCase => {
                // Disable unchecked checkboxes if user already selected 5
                const isDisabled =
                  !mixedUseCases.includes(useCase) && mixedUseCases.length === 5;

                return (
                  <FormGroup check inline key={useCase}>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="mixedUseCases"
                        value={useCase}
                        checked={mixedUseCases.includes(useCase)}
                        disabled={isDisabled}
                        onChange={e => {
                          const { checked, value } = e.target;
                          if (checked) {
                            // Add to array if not already at 5
                            if (mixedUseCases.length < 5) {
                              setMixedUseCases(prev => [...prev, value]);
                            }
                          } else {
                            // Remove from array
                            setMixedUseCases(prev => prev.filter(item => item !== value));
                          }
                        }}
                      />
                      {useCase}
                    </Label>
                  </FormGroup>
                );
              })}
            </FormGroup>
          )}

          <FormGroup>
            <Label htmlFor="description">
              A short description of what this SMS campaign does. Min length: 40
              characters. Max length: 4096 characters.
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="textarea"
              name="description"
              id="description"
              onChange={handleInputChange}
              value={upload?.description}
              invalid={
                validateStep1Inputs &&
                (!upload?.description ||
                  upload?.description?.length < 40 ||
                  upload?.description?.length > 4096)
              }
              placeholder="Example: This Campaign will send weekly marketing messages about sales and offers from Ace Plumbing to end customers who have opted in"
              rows="2"
            />
            <FormFeedback>
              {upload?.description?.length < 40
                ? 'Description is too short, minimum 40 characters needed.'
                : upload?.description?.length > 4096
                  ? 'Description is too long, maximum 4096 characters allowed.'
                  : 'Please provide a description for your campaign.'}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label htmlFor="messageFlow">
              Details around how a consumer opts-in to their campaign, therefore
              giving consent to receive their messages. If multiple opt-in
              methods can be used for the same campaign, they must all be
              listed. 40 character minimum. 2048 character maximum.
              <strong className="text-danger">*</strong>
            </Label>
            <Input
              type="textarea"
              name="messageFlow"
              id="messageFlow"
              style={{ minHeight: '100px' }}
              onChange={handleInputChange}
              value={upload?.messageFlow}
              invalid={
                validateStep1Inputs &&
                (!upload?.messageFlow ||
                  upload?.messageFlow?.length < 40 ||
                  upload?.messageFlow?.length > 4096)
              }
              placeholder="Example: End users opt-in by visiting www.AcePlumbing.com and adding their phone number. They then check a box agreeing to receive text messages from Ace Plumbing. Additionally, end users can also opt-in by texting START to (111) 222-3333 to opt in. Term and Conditions at www.AcePlumbing.com/tc. Privacy Policy at www.AcePlumbing.com/privacy"
              rows="2"
            />
            <FormFeedback>
              {upload?.messageFlow?.length < 40
                ? 'Description is too short, minimum 40 characters needed.'
                : upload?.messageFlow?.length > 4096
                  ? 'Description is too long, maximum 4096 characters allowed.'
                  : 'Please provide a description for your campaign.'}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label>
              Canadian traffic<strong className="text-danger">*</strong>
            </Label>
            <div>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="canadianTraffic"
                    value="Yes"
                    onChange={handleInputChange}
                    checked={upload?.canadianTraffic === 'Yes'}
                  />
                  Yes
                </Label>
              </FormGroup>
              <FormGroup check inline>
                <Label check>
                  <Input
                    type="radio"
                    name="canadianTraffic"
                    value="No"
                    onChange={handleInputChange}
                    checked={upload?.canadianTraffic === 'No'}
                  />
                  No
                </Label>
              </FormGroup>
            </div>
          </FormGroup>
          <Button onClick={handleSaveStep1} color="primary" className="my-3">
            Save and continue
          </Button>
        </Collapse>
      </div>

      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            return handleSaveStep1(), setCollapseStep2(!collapseStep2);
          }}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 2: Opt-in flow</h5>
          <div className="d-flex">
            {stepTwoStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep2 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep2}>
          <p>
            In this step, please describe in detail, how your subscribers opt in
            for the communication.
          </p>
          <FormGroup>
            <Label htmlFor="optInType">
              Opt-in type<strong className="text-danger">*</strong>
            </Label>
            <Input
              type="select"
              name="optInType"
              id="optInType"
              onChange={handleInputChange}
              value={upload?.optInType}
              invalid={validateStep1Inputs && !upload?.optInType}
            >
              <option value="" disabled selected>
                - Please select your opt in type -
              </option>
              <option value="VERBAL">Verbal</option>
              <option value="WEB_FORM">Web form</option>
              <option value="PAPER_FORM">Paper form</option>
              <option value="VIA_TEXT">Via-text</option>
              <option value="MOBILE_QR_CODE">Mobile QR-Code</option>
            </Input>
            <FormFeedback>Please select your opt in type.</FormFeedback>
          </FormGroup>
          <p>
            Briefly describe how consent is collected. For example: subscribe
            form on the website, keyword, IVR, paper form, point of sales, etc.{' '}
            <strong>
              Third-party opt-in is not supported and is ineligible for
              verification.
            </strong>
            <a href="#" style={{ marginLeft: '5px' }}>
              See detailed examples
            </a>
          </p>
          <FormGroup>
            <Label htmlFor="optInScreenshot">
              Opt-in screenshot<strong className="text-danger">*</strong>
            </Label>
            <Input
              type="file"
              name="optInScreenshot"
              id="optInScreenshot"
              accept=".jpg, .png, .gif, .pdf"
              onChange={async e => {
                const file = e.target.files[0];
                if (file) {
                  const validFileTypes = [
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'application/pdf'
                  ];
                  if (validFileTypes.includes(file.type)) {
                    const reader = new FileReader();
                    reader.onloadend = async () => {
                      const base64String = reader.result.split(',')[1]; // Extract the base64 string
                      const formData = {
                        imageName: file.name,
                        image: base64String,
                        type: file.type
                      };

                      try {
                        const response = await axios({
                          url: `${process.env.REACT_APP_API_URL}/api/upload-optin-screenshot`,
                          method: 'POST',
                          data: formData,
                          withCredentials: true,
                          headers: {
                            'Content-Type': 'application/json'
                          }
                        });

                        if (response.data.fileUrl) {
                          setUpload({
                            ...upload,
                            optInScreenshot: response.data.fileUrl
                          });
                          setPreviewImage(reader.result); // Set the preview image
                        } else {
                          console.error(
                            'Error: File URL not returned from server.'
                          );
                        }
                      } catch (error) {
                        console.error('Error uploading file:', error);
                      }
                    };
                    reader.readAsDataURL(file); // Read the file as a data URL
                  } else {
                    alert(
                      'Invalid file type. Please upload a .jpg, .png, .gif, or .pdf file.'
                    );
                  }
                }
              }}
              invalid={validateStep2Inputs && !upload?.optInScreenshot}
            />

            <FormFeedback>
              Please upload your Opt-in screenshot. This field is required.
            </FormFeedback>
            <p>
              Please upload a screenshot of your opt-in form or another method
              that you used to obtain the opt-in. Supported file formats: .jpg,
              .png, .gif, .pdf
            </p>
          </FormGroup>
          {previewImage && (
            <div className="mt-3">
              <img
                src={previewImage}
                alt="Opt-in Screenshot Preview"
                style={{ maxWidth: '100%', maxHeight: '200px' }}
              />
            </div>
          )}

          <Button onClick={handleSaveStep2} color="primary" className="my-3">
            Save and continue
          </Button>
        </Collapse>
      </div>

      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => {
            return (
              handleSaveStep2(),
              handleSaveStep3(),
              setCollapseStep3(!collapseStep3)
            );
          }}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 3: Sample messages</h5>
          <div className="d-flex">
            {stepThreeStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep3 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep3}>
          <p>
            Provide up to five examples of the messages this campaign will send.
            Future message content must exactly match the examples provided in
            this step.
          </p>
          <Alert color="info">
            <FontAwesomeIcon icon="info-circle" className="me-2" />
            Templated messages are not advised as they can be rejected by the
            network operators: <br />
            <strong>Instead of:</strong> “Dear [customer name], your order
            [order number] has been received. Reply STOP to opt out.” <br />
            <strong>Write:</strong> “Dear Susan, your order 47755 has been
            received. Reply STOP to opt out.”
          </Alert>
          {sampleMessages.map((msg, index) => (
            <Row key={index}>
              <Col>
                <FormGroup>
                  <div>
                    <Label htmlFor={`sampleMessage${msg.id}`}>
                      Sample message {msg.id}{' '}
                      {msg.id === 1 && (
                        <strong className="text-danger">*</strong>
                      )}
                    </Label>
                  </div>
                  <div className="d-flex">
                    <Input
                      type="textarea"
                      style={{ minHeight: '150px' }}
                      name={`sampleMessage${msg.id}`}
                      id={`sampleMessage${msg.id}`}
                      invalid={
                        validateStep3Inputs && !upload?.messageSamples[0]?.text
                      }
                      placeholder="[Enter your sample message] Reply STOP to opt out."
                      value={msg.text}
                      onChange={e => {
                        handleSampleMessageChange(msg.id, e.target.value);
                      }}
                      className="mb-3"
                    />
                    {(msg.id !== 1 && msg.id !== 2) && (
                      <div>
                        <Button
                          size="sm"
                          className="ms-2 btn-danger"
                          onClick={() => removeSampleMessage(msg.id)}
                        >
                          <FontAwesomeIcon icon="trash" />
                        </Button>
                      </div>
                    )}
                  </div>
                </FormGroup>
              </Col>
            </Row>
          ))}
          <Row className="d-flex justify-content-start">
            <Col xs={12}>
              {sampleMessages.length < 5 && (
                <Button color="link" onClick={addSampleMessage}>
                  + Add another sample message
                </Button>
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-start">
            <Col xs={12}>
              <Button color="primary" className="ms-2 my-3">
                Save and continue
              </Button>
            </Col>
          </Row>
        </Collapse>
      </div>

      <div className="border-bottom">
        <div
          className="d-flex justify-content-between align-items-center"
          onClick={() => setCollapseStep4(!collapseStep4)}
          style={{ cursor: 'pointer' }}
        >
          <h5 className="mb-3 mt-4">Step 4: Campaign and content attributes</h5>
          <div className="d-flex">
            {stepFourStatus ? (
              <FontAwesomeIcon icon="check" className="ms-2 text-success" />
            ) : (
              <FontAwesomeIcon
                icon="exclamation-triangle"
                className="ms-2 text-warning"
              />
            )}

            <FontAwesomeIcon
              icon={collapseStep4 ? 'chevron-up' : 'chevron-down'}
              className="ms-2"
            />
          </div>
        </div>
        <Collapse isOpen={collapseStep4}>
          <FormGroup>
            <h4 className="font-weight-bold" for="optInKeyword">
              Subscriber opt-in
            </h4>
            <div>
              <Label>
                Opt-in keyword <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="text"
                readOnly
                name="optInKeywords"
                id="optInKeywords"
                placeholder="JOIN"
                value={upload?.optInKeywords}
                invalid={validateStep4Inputs && !upload?.optInKeywords}
                onChange={handleInputChange}
              />
            </div>
            <div className="mt-3">
              <Label>
                Opt-in auto-reply message{' '}
                <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="textarea"
                name="optInMessage"
                id="optInMessage"
                placeholder="Your Company: Hello, welcome to our campaign. Msg freq varies. Msg&data rates may apply. Reply STOP to end, HELP for help."
                value={upload?.optInMessage}
                invalid={validateStep4Inputs && !upload?.optInMessage}
                onChange={handleInputChange}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <h4 className="font-weight-bold" for="optOutKeyword">
              Subscriber opt-out
            </h4>
            <div>
              <Label>
                Opt-out keyword <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="text"
                readOnly
                disabled={true}
                name="optOutKeywords"
                id="optOutKeywords"
                placeholder="STOP"
                value={upload?.optOutKeywords}
                invalid={validateStep4Inputs && !upload?.optOutKeywords}
              />
            </div>
            <div className="mt-3">
              <Label>
                Opt-out auto-reply message{' '}
                <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="textarea"
                name="optOutMessage"
                id="optOutMessage"
                placeholder="Your Company: You have successfully unsubscribed & will no longer receive any additional messages. Text HELP for assistance or JOIN to rejoin."
                value={upload?.optOutMessage}
                invalid={validateStep4Inputs && !upload?.optOutMessage}
              />
            </div>
          </FormGroup>

          <FormGroup>
            <h4 className="font-weight-bold" for="helpKeyword">
              Subscriber help
            </h4>
            <div>
              <Label>
                Help keyword <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="text"
                readOnly
                disabled={true}
                name="helpKeywords"
                id="helpKeywords"
                placeholder="HELP"
                value={upload?.helpKeywords}
                invalid={validateStep4Inputs && !upload?.helpKeywords}
              />
            </div>
            <div className="mt-3">
              <Label>
                Help auto-reply message{' '}
                <strong className="text-danger">*</strong>
              </Label>
              <Input
                type="textarea"
                name="helpMessage"
                id="helpMessage"
                placeholder="Your Company: For support, please visit https://www.yourwebsite.com, or contact us at John@yourWebsite.com or call (123) 456 - 7890"
                value={upload?.helpMessage}
                invalid={validateStep4Inputs && !upload?.helpMessage}
                onChange={handleInputChange}
              />
            </div>
          </FormGroup>

          <FormGroup check className="mt-3">
            <Label check>
              <Input
                type="checkbox"
                className="me-2"
                size="sm"
                name="hasEmbeddedLinks"
                onChange={handleCheckboxChange}
                checked={upload?.hasEmbeddedLinks}
              />{' '}
              Message content includes a link (URL)
            </Label>
            <div className="text-muted" style={{ marginLeft: '20px' }}>
              Will you include a URL link in your messages? If yes, please
              select the checkbox. Note: public URL shorteners (bit.ly, tinyURL)
              are not allowed.
            </div>
          </FormGroup>

          <FormGroup check className="mt-3">
            <Label check>
              <Input
                type="checkbox"
                className="me-2"
                size="sm"
                name="hasEmbeddedPhone"
                onChange={handleCheckboxChange}
                checked={upload?.hasEmbeddedPhone}
              />{' '}
              Message content includes a phone number
            </Label>
            <div className="text-muted" style={{ marginLeft: '20px' }}>
              Message content includes a phone number
            </div>
          </FormGroup>

          <Button onClick={handleSaveStep4} color="primary" className="my-3">
            Save and continue
          </Button>
        </Collapse>
      </div>
      <Row>
        <div className="d-flex justify-content-between mt-3">
          <Button
            onClick={() => setStep(2)}
            className="px-4 bg-transparent text-primary border-0 shadow-none text-bold fw-bold"
          >
            Back
          </Button>
          <Button onClick={handleNext} className="px-4 fw-bold" color="primary">
            Next
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};

export default StepThreeForm;
