import React, { useState, useEffect } from 'react';
import { ResponderUploadContext } from '../../context/Context';
import { useSelector } from 'react-redux';

const ResponderProvider = ({ children }) => {
  const [upload, setUpload] = useState({
    title: '',
    requireKeyword: false,
    responseType: 'textMessage',
    responseTime: 'alwaysOn',
    responderDay: [],
    weekDays: [],
    numbers: [],
    keyword: [],
    message: '',
    messageBody: null,
    group: [],
    groupRemove: [],
  });
  const responderDetails = useSelector(state => state.responderDetails);


  const [step, setStep] = useState(1);

  const [userFile, setUserFile] = useState();

  // ResponderProvider.js
  const handleInputChange = ({ value, name }) => {
    setUpload(prevUpload => ({ ...prevUpload, [name]: value }));
  };



  // Initialize state from responderDetails when available
  useEffect(() => {
    if (responderDetails?.length && responderDetails[0]?.responder) {
      const responder = responderDetails[0].responder;
      setUpload(prevUpload => ({
        ...prevUpload,
        title: responder.title ?? '',
        requireKeyword: responder.requireKeyword ?? false,
        responseType: responder.responseType ?? 'textMessage',
        responseTime: responder.responseTime ?? 'alwaysOn',
        responderDay: responder.responderDay ?? [],
        weekDays: responder.weekDays ?? [],
        numbers: responder.numbers ?? [],
        keyword: responder.keyword ?? [],
        message: responder.message ?? '',
        messageBody: responder.messageBody ?? null,
        group: responder.group ?? [],
        groupRemove: responder.groupRemove ?? [],
        responderDay: responder.responderDay ?? [],
        // ... initialize other properties as needed
      }));
    }
  }, [JSON.stringify(responderDetails)]);

  const value = {
    upload,
    setUpload,
    step,
    setStep,
    userFile,
    setUserFile,
    handleInputChange
  };
  return (
    <ResponderUploadContext.Provider value={value}>
      {children}
    </ResponderUploadContext.Provider>
  );
};

export default ResponderProvider;
