import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children }) => {
  const { isVerified, loading } = useSelector(state => state.auth);
  const location = useLocation();

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  if (!isVerified) {
    return (
      <Navigate
        to="/authentication/simple/login"
        state={{ from: location }}
        replace
      />
    );
  }

  return children ? children : <Outlet />;
};

export default PrivateRoute;
