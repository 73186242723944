import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import Divider from 'components/common/Divider';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import AdvanceContactsTable from 'components/common/contactsTable/AdvanceContactsTable';
import AdvanceContactsTableFooter from 'components/common/contactsTable/AdvanceContactsTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceContactsTableWrapper from 'components/common/contactsTable/AdvanceContactsTableWrapper';
import { listGroupContacts, unBlockContacts, createBlockedContacts, resubscribeContacts, deleteMultipleContacts } from 'actions/index';
import { RESET_SELECTED_CONTACT_ROWS } from 'actions/types';
import AddContactsToGroup from './AddContactsToGroup';
import ActionPopover from 'components/common/ActionPopover';

const Unsubscribers = () => {


  const { id } = useParams()

  const GroupId = id


  //--------------------------------------------------------------------------State-------------------------------------------------------------------------
  const [showBlockModal, setShowBlockModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showUnsubUserModal, setShowUnsubUserModal] = useState(false);
  const [showResubscribeModal, setShowResubscribeModal] = useState(false)
  const [blockContactInfo, setBlockContactInfo] = useState();
  const [rowDelete, setRowDelete] = useState()

  const dispatch = useDispatch();

  const groupDetails = useSelector((state) => state.groupDetails)
  const { contacts: contacts, loading: loadingContacts, success: successGet, totalContacts: totalContacts } = groupDetails


  const contactDelete = useSelector((state) => state.deleteContact)

  const selectedTableRows = useSelector((state) => state.contactsTableRow)


  const blockedContactCreate = useSelector((state) => state.blockedContactCreate)
  const { success: successBlockedCreate } = blockedContactCreate

  const blockedContactDelete = useSelector((state) => state.blockedContactDelete)
  const { success: successBlockedDelete } = blockedContactDelete

  const groupUpdate = useSelector((state) => state.groupUpdate)
  const { success: successUpdate } = groupUpdate

  useEffect(() => {
    if (successBlockedCreate) {
      dispatch({ type: RESET_SELECTED_CONTACT_ROWS })
    }
  }, [successBlockedCreate])

  useEffect(() => {
    if (successBlockedDelete) {
      dispatch({ type: RESET_SELECTED_CONTACT_ROWS })
    }

  }, [successBlockedDelete])



  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, { avatar }) => {
    const { _id, firstName } = rowData.row.original
    return (
      <Link to={`/follow-up/contact/${_id}`}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2">
            <h5 className="mb-0 fs-9">{firstName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { _id, lastName } = rowData.row.original
    return (
      <Link to={`/follow-up/contact/${_id}`}>
        <Media tag={Flex} align="center">
          <Media body className="ms-2 mt-2">
            <h5 className="mb-0 fs-9">{lastName}</h5>
          </Media>
        </Media>
      </Link>
    );
  };

  const phoneFormatter = (rowData) => {
    const { _id, phone_number } = rowData.row.original
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2 mt-2">
          <h5 className="mb-0 fs-9">{phone_number}</h5>
        </Media>
      </Media>
    );
  };

  const createdFormatter = (rowData) => {
    const { created } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 mt-2"><Moment format="MM/DD/YYYY" date={created} /></h5>
        </Media>
      </Media>
    )
  };

  const UnsubbedFormatter = (rowData) => {
    const { UnsubscribeDate } = rowData.row.original

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs--1 mt-2"><Moment format="MM/DD/YYYY" date={UnsubscribeDate} /></h5>
        </Media>
      </Media>
    )
  };

  const blockedFormatter = (rowData) => {
    const { blocked, _id } = rowData.row.original
    return (
      <Link to={`/contact/${_id}`}>
        <Media body className="ml-2 mt-1">
          {blocked ?
            <SubtleBadge bg="danger" pill className="fs--1">
              Blocked
              <FontAwesomeIcon icon="ban" transform="shrink-1" className="ms-1" />
            </SubtleBadge> :
            <SubtleBadge bg="success" pill className="fs--1">
              unBlocked
              <FontAwesomeIcon icon="check" transform="shrink-1" className="ms-1" />
            </SubtleBadge>
          }
        </Media>
      </Link>

    );
  };



  const actionFormatter = (rowData) => {
    const { _id, phone_number, blocked, specificStop } = rowData.row.original;

    if (specificStop) {
      // If `specificStop` is true, return a disabled item with a message
      return (
        <div className="d-flex justify-content-end">
          <ActionPopover
            id={_id}
            items={[
              {
                label: 'Contact manually unsubscribed. Contact support to resubscribe.',
                icon: 'exclamation-circle',
                disabled: true, // Prevent clicking
              },
            ]}
          />
        </div>
      );
    }

    // Default options when `specificStop` is false
    const items = [
      {
        label: 'Re-subscribe',
        icon: 'redo',
        color: 'success',
        action: () => {
          setRowDelete([_id]);
          setShowResubscribeModal(!showResubscribeModal);
        },
      },
      {
        label: blocked ? 'Unblock Contact' : 'Block messages',
        icon: 'ban',
        color: 'danger',
        action: () => {
          setBlockContactInfo({ _id, phoneNumber: phone_number, blocked });
          setShowBlockModal(!showBlockModal);
        },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover id={_id} items={items} />
      </div>
    );
  };


  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [

    {
      accessor: 'firstName',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'lastName',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      accessor: 'phone_number',
      Header: 'Phone',
      Cell: phoneFormatter
    },
    {
      accessor: 'blocked',
      Header: 'Blocked',
      Cell: blockedFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: "right",


    }
  ];

  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------


  const closeUnsubUserBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowUnsubUserModal(!showUnsubUserModal) }}>
    </button>
  );

  const closeResubscribeBtn = (
    <button className="btn-close bg-light" onClick={() => { setShowResubscribeModal(!showResubscribeModal) }}>
    </button>
  );

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------




  const handleSearch = (value) => {
    dispatch(
      listGroupContacts({ groupId: GroupId, search: value, page: 1, limit: 25 })
    );
  };



  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listGroupContacts({ groupId: GroupId, search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])



  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };


  console.log("blockContactInfo", blockContactInfo)



  return (
    <Fragment>
      <h2 className="d-flex justify-content-start my-2 mx-2" >Unsubscribers</h2>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-3">
              <ButtonIcon icon="plus" onClick={() => { setShowUnsubUserModal(!showUnsubUserModal) }} transform="shrink-3 down-1" className="btn-tertiary border-dark" size="sm" >
                Contacts
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>

        <CardBody className="p-0">
          {loadingContacts && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceContactsTableWrapper
            columns={columns}
            data={contacts}
            fetchData={fetchData}
            total={totalContacts}
            pageCount={1}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={() => { }}
          >

            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  placeholder="Search contacts..."
                  onSearch={handleSearch} table />
              </Col>
            </Row>


            <AdvanceContactsTable
              table
              headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 fw-bold overflow-hidden'
              }}
              isSelected={selectedTableRows}
              total={totalContacts}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceContactsTableFooter
                rowCount={totalContacts}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceContactsTableWrapper>
        </CardBody>
      </Card>


      <Modal size="lg" isOpen={showUnsubUserModal} centered toggle={() => setShowUnsubUserModal(!showUnsubUserModal)}>
        <ModalHeader toggle={() => { setShowUnsubUserModal(!showUnsubUserModal) }} close={closeUnsubUserBtn} className="text-center d-flex flex-between-center border-bottom-0">
          Choose from existing contacts
        </ModalHeader>
        <ModalBody className="p-1">
          <AddContactsToGroup showUnsubUserModal={showUnsubUserModal} setShowUnsubUserModal={setShowUnsubUserModal} GroupId={GroupId} />
        </ModalBody>
      </Modal>
      <Modal isOpen={showResubscribeModal} centered toggle={() => { setShowResubscribeModal(!showResubscribeModal) }}>
        <ModalHeader toggle={() => { setShowResubscribeModal(!showResubscribeModal) }} close={closeResubscribeBtn} className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="text-center">You will be able to send messages to this contact.</Label>
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(resubscribeContacts(rowDelete, GroupId)),
                    setShowResubscribeModal(false)
                } else {
                  return dispatch(resubscribeContacts(selectedTableRows, GroupId)),
                    setShowResubscribeModal(false)
                }
              }}
                block
                className="btn-tertiary border-dark">Resubscribe contacts</Button>
              <Button onClick={() => { setShowResubscribeModal(!showResubscribeModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


      <Modal isOpen={showDeleteModal} centered toggle={() => setShowDeleteModal(!showDeleteModal)}>
        <ModalHeader className="d-flex justify-content-center" >Are you sure?</ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Button onClick={() => {
                if (rowDelete?.length) {
                  return dispatch(deleteMultipleContacts(rowDelete)),
                    setShowDeleteModal(!showDeleteModal)
                } else {
                  const deleteIds = selectedTableRows.map((el) => { return el.original._id })
                  return dispatch(deleteMultipleContacts(deleteIds)),
                    setShowDeleteModal(!showDeleteModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger">Delete</Button>
              <Button onClick={() => { setShowDeleteModal(!showDeleteModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>





      <Modal isOpen={showBlockModal} centered toggle={() => setShowBlockModal(!showBlockModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Label className="center">{blockContactInfo?.blocked ? "You will be able to recieve phone calls and messages from this contact" : "You will not recieve phone calls or messages from people on the block list."}</Label>
              <Divider></Divider>
              <Button onClick={() => {
                if (blockContactInfo?.blocked) {
                  return dispatch(unBlockContacts([blockContactInfo?._id])), // added brackets to match type
                    setShowBlockModal(!showBlockModal)
                } else {
                  return dispatch(createBlockedContacts({ contactIds: [blockContactInfo?._id] })),
                    setShowBlockModal(!showBlockModal)
                }
              }}
                block
                className="btn-tertiary border-dark text-danger">{blockContactInfo?.blocked ? "Unblock Contact" : "Block Contact"}</Button>
              <Button onClick={() => { setShowBlockModal(!showBlockModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>


    </Fragment>
  );
};


export default Unsubscribers;