import {
  TOLL_FREE_VERIFICATION_UPDATE_REQUEST,
  TOLL_FREE_VERIFICATION_UPDATE_SUCCESS,
  TOLL_FREE_VERIFICATION_UPDATE_FAIL,
  TOLL_FREE_VERIFICATION_UPDATE_RESET
} from '../actions/types';

export default function tollFreeVerificationUpdateReducer(state = {}, action) {
  switch (action.type) {
    case TOLL_FREE_VERIFICATION_UPDATE_REQUEST:
      return { loading: true };
    case TOLL_FREE_VERIFICATION_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case TOLL_FREE_VERIFICATION_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case TOLL_FREE_VERIFICATION_UPDATE_RESET:
      return {};
    default:
      return state;
  }
}
