import React from 'react';
import ImporterForm from './ImporterForm';
import DLCWizardProvider from './DLCWizardProvider';

const DLCWizardLayout = () => {
  return (
    <DLCWizardProvider>
      <ImporterForm />
    </DLCWizardProvider>
  );
};

export default DLCWizardLayout;
