import {
  FETCH_TEAM_DETAILS_REQUEST,
  FETCH_TEAM_DETAILS_SUCCESS,
  FETCH_TEAM_DETAILS_FAIL
} from '../actions/types';

const initialState = {
  team: null,
  loading: false,
  error: null
};

export const teamDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAM_DETAILS_REQUEST:
      console.log('fetch team ran');
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_TEAM_DETAILS_SUCCESS:
      return {
        ...state,
        team: action.payload,
        loading: false
      };
    case FETCH_TEAM_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    default:
      return state;
  }
};
