import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Alert,
  Button,
  Col,
  Row,
  Label,
  Input,
  FormFeedback,
  Spinner
} from 'reactstrap';
import { TollFreeUploadContext } from '../../../context/Context';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const StepTwoForm = () => {
  const { upload, setUpload, step, setStep, handleInputChange } = useContext(
    TollFreeUploadContext
  );

  const location = useLocation();
  const { tollFreeVerificationStatus, tollFreeVerificationRejectionReason } =
    location.state || {};

  const dispatch = useDispatch();

  const [websiteError, setWebsiteError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [validateInputs, setValidateInputs] = useState(false);

  const validateWebsite = url => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(url);
  };

  const validatePhone = phone => {
    const pattern = new RegExp('^\\+?[1-9]\\d{1,14}$'); // E.164 format
    return pattern.test(phone);
  };

  const handleWebsiteChange = ({ target }) => {
    setUpload({ ...upload, businessWebsite: target.value });
    if (!validateWebsite(target.value)) {
      setWebsiteError('Please enter a valid website URL.');
    } else {
      setWebsiteError('');
    }
  };

  const handlePhoneChange = ({ target }) => {
    setUpload({ ...upload, businessContactPhone: target.value });
    if (!validatePhone(target.value)) {
      setPhoneError('Please enter a valid phone number.');
    } else {
      setPhoneError('');
    }
  };

  const handleNext = () => {
    setValidateInputs(true);
    let valid = true;
    if (!upload?.businessName) valid = false;
    if (!upload?.businessWebsite || !!websiteError) valid = false;
    if (!upload?.businessStreetAddress) valid = false;
    if (!upload?.businessCity) valid = false;
    if (!upload?.businessPostalCode) valid = false;
    if (!upload?.businessStateProvinceRegion) valid = false;
    if (!upload?.businessCountry) valid = false;
    if (!upload?.businessContactFirstName) valid = false;
    if (!upload?.businessContactLastName) valid = false;
    if (!upload?.businessContactPhone || !!phoneError) valid = false;
    if (!upload?.businessContactEmail) valid = false;

    if (valid) {

      setStep(step + 1);
    } else {
      // trigger validation errors
      setWebsiteError(
        !upload?.businessWebsite ? 'Please enter a valid website URL.' : ''
      );
      setPhoneError(
        !upload?.businessContactPhone ? 'Please enter a valid phone number.' : ''
      );
    }
  };




  return (
    <Fragment>
      {tollFreeVerificationStatus === 'TWILIO_REJECTED' &&
        tollFreeVerificationRejectionReason && (
          <Alert color="warning">
            The campaign registry rejected this campaign due to the following
            reason: {<strong>{tollFreeVerificationRejectionReason}</strong>}.
            Please resubmit and try again.
          </Alert>
        )}
      <h5 className=" mb-3">Organization details</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            Legal organization name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessName}
            invalid={validateInputs && !upload?.businessName}
            className="mb-3"
            name="businessName"
          ></Input>
        </Col>
        <Col xs={12} md={6}>
          <Label>
            Organization website<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleWebsiteChange}
            value={upload?.businessWebsite}
            className="mb-3"
            name="businessWebsite"
            invalid={
              validateInputs && (!!websiteError || !upload?.businessWebsite)
            }
          />
          <FormFeedback>{websiteError}</FormFeedback>
        </Col>
      </Row>
      <h5 className=" mb-3 mt-4">Organization address</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            Address / street<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessStreetAddress}
            invalid={validateInputs && !upload?.businessStreetAddress}
            className="mb-3"
            name="businessStreetAddress"
          ></Input>

          <Label>
            City<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessCity}
            invalid={validateInputs && !upload?.businessCity}
            className="mb-3"
            name="businessCity"
          ></Input>

          <Label>
            Postal code<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessPostalCode}
            invalid={validateInputs && !upload?.businessPostalCode}
            className="mb-3"
            name="businessPostalCode"
          ></Input>
        </Col>
        <Col xs={12} md={6}>
          <Label>
            State / Region<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessStateProvinceRegion}
            invalid={validateInputs && !upload?.businessStateProvinceRegion}
            className="mb-3"
            name="businessStateProvinceRegion"
          ></Input>

          <Label>
            Country<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessCountry}
            invalid={validateInputs && !upload?.businessCountry}
            name="businessCountry"
            type="select"
            className="mb-3"
          >
            <option value="" disabled selected>
              Select
            </option>
            <option key={1} value="US">
              United States
            </option>
            <option key={2} value="CA">
              Canada
            </option>
          </Input>
        </Col>
      </Row>


      <h5 className=" mb-3 mt-4">Organization contact details</h5>
      <Row>
        <Col xs={12} md={6}>
          <Label>
            First name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactFirstName}
            invalid={validateInputs && !upload?.businessContactFirstName}
            className="mb-3"
            name="businessContactFirstName"
          ></Input>

          <Label>
            Last name<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactLastName}
            invalid={validateInputs && !upload?.businessContactLastName}
            className="mb-3"
            name="businessContactLastName"
          ></Input>
        </Col>
        <Col xs={12} md={6}>
          <Label>
            Phone number<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handlePhoneChange}
            value={upload?.businessContactPhone}
            className="mb-3"
            name="businessContactPhone"
            invalid={
              validateInputs && (!!phoneError || !upload?.businessContactPhone)
            }
          />
          <FormFeedback>{phoneError}</FormFeedback>

          <Label>
            Email address<strong className="text-danger">*</strong>
          </Label>
          <Input
            onChange={handleInputChange}
            value={upload?.businessContactEmail}
            invalid={validateInputs && !upload?.businessContactEmail}
            className="mb-3"
            name="businessContactEmail"
          ></Input>
        </Col>
      </Row>
      <Row>
        <div className="d-flex justify-content-end mt-3">
          <Button onClick={handleNext} color="primary">
            {'Next'}
          </Button>
        </div>
      </Row>
    </Fragment>
  );
};

export default StepTwoForm;
