import axios from 'axios';
import { toast } from 'react-toastify';
import {
  REMOVE_TEAM_MEMBER_REQUEST,
  REMOVE_TEAM_MEMBER_SUCCESS,
  REMOVE_TEAM_MEMBER_FAILURE,
  FETCH_TEAM_DETAILS_FAIL,
  FETCH_TEAM_DETAILS_REQUEST,
  FETCH_TEAM_DETAILS_SUCCESS,
  UPDATE_TEAM_DETAILS,
  CREATE_TEAM_SUCCESS,
  CREATE_TEAM_FAILURE,
  INVITE_MEMBER_SUCCESS,
  INVITE_MEMBER_FAILURE,
  TEAM_MEMBER_DELETE_REQUEST,
  TEAM_MEMBER_DELETE_FAIL,
  TEAM_MEMBER_DELETE_SUCCESS,
  INVITE_MEMBER_REQUEST,
  CREATE_TEAM_REQUEST
} from './types';

// Action to fetch team details

export const fetchTeamDetails = () => {
  return async function (dispatch) {
    dispatch({ type: FETCH_TEAM_DETAILS_REQUEST });

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_API_URL}/api/team/details`,
        method: 'GET',
        withCredentials: true
      });
      dispatch({ type: FETCH_TEAM_DETAILS_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({
        type: FETCH_TEAM_DETAILS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
      });
    }
  };
};

// Action to update team details
export const updateTeamDetails = teamData => {
  return async function (dispatch) {
    const url = `${process.env.REACT_APP_API_URL}/api/team/update`;

    return axios({
      url,
      method: 'PUT',
      data: teamData,
      withCredentials: true
    })
      .then(res => {
        dispatch({ type: UPDATE_TEAM_DETAILS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        // Add error handling logic here if needed
        throw error; // If there is an error, the promise is rejected
      });
  };
};

export const createTeam = teamData => {
  return async function (dispatch) {
    dispatch({ type: CREATE_TEAM_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/api/team/create`;

    return axios({
      url,
      method: 'POST',
      withCredentials: true,
      data: teamData
      // Add any necessary data for team creation here
    })
      .then(res => {
        dispatch({ type: CREATE_TEAM_SUCCESS, payload: res.data });
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: CREATE_TEAM_FAILURE, payload: error.response.data });
        throw error; // If there is an error, the promise is rejected
      });
  };
};

export const inviteMember = teamData => {
  return async function (dispatch) {
    console.log('teamData', teamData);
    dispatch({ type: INVITE_MEMBER_REQUEST });
    const url = `${process.env.REACT_APP_API_URL}/api/teams/${teamData?.teamId}/invite`;

    return axios({
      url,
      method: 'POST',
      withCredentials: true,
      data: {
        email: teamData?.inviteEmail // The email address to which the invitation will be sent
      }
    })
      .then(res => {
        dispatch({ type: INVITE_MEMBER_SUCCESS, payload: res.data });
        toast.success('Invitation sent successfully!');
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({ type: INVITE_MEMBER_FAILURE, payload: error.response.data });
        toast.error('Failed to send invitation.');
        throw error; // If there is an error, the promise is rejected
      });
  };
};

export const removeMember = teamData => {
  return async function (dispatch) {
    dispatch({ type: TEAM_MEMBER_DELETE_REQUEST });

    console.log('teamData', teamData);
    const url = `${process.env.REACT_APP_API_URL}/api/team-member-remove/${teamData?.teamId}`;

    return axios({
      url,
      method: 'POST',
      withCredentials: true,
      data: teamData
    })
      .then(res => {
        dispatch({ type: TEAM_MEMBER_DELETE_SUCCESS, payload: res.data });
        toast.success('Team member deleted!');
        return res.data; // Resolve promise with data
      })
      .catch(error => {
        dispatch({
          type: TEAM_MEMBER_DELETE_FAIL,
          payload: error.response.data
        });
        toast.error('Failed to delete member.');
        throw error; // If there is an error, the promise is rejected
      });
  };
};
