import React, { useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Label } from 'reactstrap';
import SubtleBadge from 'components/common/SubtleBadge';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Loader from '../common/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import FalconLightBox from '../common/FalconLightBox';
import { previewMMS } from '../../actions/index';
import { Buffer } from 'buffer'; // Import Buffer from the buffer package

const MessageDetails = ({ message }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState();

  const previewMedia = useSelector(state => state.previewMedia);
  const { success: successPreviewMedia } = previewMedia;

  useEffect(() => {
    if (message?.numMedia === '1') {
      dispatch(previewMMS(message?.mediaSubresourceUri));
    }
  }, [dispatch, message]);

  function formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumberString;
  }

  useEffect(() => {
    if (previewMedia?.payload) {
      setImage(Buffer.from(previewMedia?.payload, 'binary').toString('base64'));
    }
  }, [successPreviewMedia, previewMedia]);

  return (
    <div>
      <Card>
        <CardBody>
          {/* Message Preview */}
          <Row className="mb-3">
            <Col xs="4">
              <Label className="fw-medium">Message Preview</Label>
            </Col>
            <Col xs="8">
              {message.numMedia === '1' ? (
                <div className={classNames('p-2 rounded-2 compose-preview-message', 'bg-primary text-white')}>
                  {!previewMedia?.loading ? (
                    <FalconLightBox imgSrc={`data:image/*;base64,${image}`}>
                      <img
                        src={`data:image/*;base64,${image}`}
                        className="img-fluid rounded ms-2 mb-2"
                        width={100}
                        height={100}
                        alt=""
                      />
                    </FalconLightBox>
                  ) : (
                    <Loader />
                  )}
                  {message?.body && (
                    <p
                      className="mb-0 fw-light"
                      dangerouslySetInnerHTML={{ __html: message?.body }}
                    />
                  )}
                </div>
              ) : (
                <div className={classNames('p-2 rounded-2 compose-preview-message', 'bg-primary text-white')}>
                  <p
                    className="mb-0 fw-light"
                    dangerouslySetInnerHTML={{ __html: message?.body }}
                  />
                </div>
              )}
            </Col>
          </Row>

          {/* To */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">To:</Label>
            </Col>
            <Col xs="8">
              {message?.name?.firstName ? (
                <h6 className="mb-0 fw-normal">
                  {message?.name?.firstName} {message?.name?.lastName}
                </h6>
              ) : (
                <h6 className="mb-0 fw-normal">
                  {formatPhoneNumber(message?.to)}
                </h6>
              )}
            </Col>
          </Row>

          {/* From */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">From:</Label>
            </Col>
            <Col xs="8">
              <h6 className="mb-0 fw-normal">
                {formatPhoneNumber(message?.from)}
              </h6>
            </Col>
          </Row>

          {/* Sent Time */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Sent time:</Label>
            </Col>
            <Col xs="8">
              <h6 className="mb-0 fw-normal">
                <Moment format="MM/DD/YY hh:mm A" date={message?.dateSent} />
              </h6>
            </Col>
          </Row>

          {/* Status */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Status:</Label>
            </Col>
            <Col xs="8">
              {message?.status === 'delivered' ? (
                <SubtleBadge bg="soft-success" pill className="fs-9">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-1"
                    className=" ms-1"
                  />
                </SubtleBadge>
              ) : message?.status === 'undelivered' ? (
                <SubtleBadge bg="danger" pill className="fs-9">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="times"
                    transform="shrink-1"
                    className="ms-1"
                  />
                </SubtleBadge>
              ) : message?.status === 'failed' ? (
                <SubtleBadge bg="danger" pill className="fs-9">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="times"
                    transform="shrink-1"
                    className="ms-1"
                  />
                </SubtleBadge>
              ) : message?.status === 'received' ? (
                <SubtleBadge bg="success" pill className="fs-9">
                  {message?.status}
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-1"
                    className=" ms-1"
                  />
                </SubtleBadge>
              ) : message?.errorMessage ? (
                <SubtleBadge bg="danger" pill className="fs-9">
                  {message?.errorMessage}
                  <FontAwesomeIcon
                    icon="times"
                    transform="shrink-1"
                    className=" ms-1"
                  />
                </SubtleBadge>
              ) : (
                ''
              )}
            </Col>
          </Row>

          {/* Segments */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Segments:</Label>
            </Col>
            <Col xs="8">
              <SubtleBadge bg="info" pill className="fs-9">
                {message?.numSegments}
              </SubtleBadge>
            </Col>
          </Row>

          {/* Total Cost */}
          <Row className="mb-2">
            <Col xs="4">
              <Label className="fw-medium">Total cost:</Label>
            </Col>
            <Col xs="8">
              <SubtleBadge bg="info" pill className="fs-9">
                {`${message?.numMedia === '1' && message?.numSegments === '1'
                  ? '1.5'
                  : message?.numMedia === '1' && parseInt(message?.numSegments) > 1
                    ? (1.5 + parseInt(message?.numSegments)).toString()
                    : message?.numSegments
                  } credits`}
              </SubtleBadge>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default MessageDetails;
