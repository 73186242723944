
export const FETCH_USER = 'fetch_user';
export const SET_USER = "set_user";
export const FETCH_K_USER = 'fetch_k_user';
export const FETCH_REG_USER = 'fetch_reg_user';
export const DELETE_USER = 'delete_user';
export const UPDATE_USER_CREDIT = 'update_user_credit';

export const GET_PROFILE_INFO = 'get_profile_info';
export const LOGOUT_USER = 'logout_user';

export const GET_TWILIO_NUMBER = 'get_twilio_number';

export const USER_UPDATE_REQUEST = 'user_update_request';
export const USER_UPDATE_SUCCESS = 'user_update_success';
export const USER_UPDATE_FAIL = 'user_update_fail';
export const USER_UPDATE_RESET = 'user_update_reset';

// contacts

export const ADD_SELECTED_ROWS = 'ADD_SELECTED_ROWS';
export const REMOVE_SELECTED_ROWS = 'REMOVE_SELECTED_ROWS';
export const RESET_SELECTED_ROWS = 'RESET_SELECTED_ROWS';

export const ADD_SELECTED_CONTACT_ROWS = 'add_selected_contact_rows';
export const REMOVE_SELECTED_CONTACT_ROWS = 'remove_selected_contact_rows';
export const RESET_SELECTED_CONTACT_ROWS = 'reset_selected_contact_rows';


export const LIST_CONTACTS_REQUEST = 'list_contacts_request';
export const LIST_CONTACTS_SUCCESS = 'list_contacts_success';
export const LIST_CONTACTS_FAILURE = 'list_contacts_failure';
export const LIST_CONTACTS_FAIL = 'list_contacts_fail';


export const LIST_CONTACTS = 'list_contacts';
export const LIST_CONTACTS_FOLLOW_UP_REQUEST = 'list_contacts_follow_up_request';
export const LIST_CONTACTS_FOLLOW_UP_SUCCESS = 'list_contacts_follow_up_success';
export const LIST_CONTACTS_FOLLOW_UP_FAILURE = 'list_contacts_follow_up_failure';
export const LIST_CONTACTS_TOTAL = 'list_contacts_total';
export const LIST_CONTACTS_TOTAL_CRM = 'list_contacts_total_crm';



export const ADD_COMPOSE_CONTACT = 'add_compose_contact';
export const LIST_BLOCKED = 'list_blocked';
export const LIST_BLOCKED_REQUEST = 'list_blocked_request';
export const LIST_BLOCKED_SUCCESS = 'list_blocked_success';
export const LIST_BLOCKED_FAIL = 'list_blocked_fail';

export const CONTACT_CREATE_REQUEST = 'contact_create_request';
export const CONTACT_CREATE_SUCCESS = 'contact_create_success';
export const CONTACT_CREATE_FAIL = 'contact_create_fail';
export const CONTACT_CREATE_RESET = 'contact_create_reset';

export const CONTACT_DELETE_REQUEST = 'contact_delete_request';
export const CONTACT_DELETE_SUCCESS = 'contact_delete_success';
export const CONTACT_DELETE_FAIL = 'contact_delete_fail';
export const CONTACT_DELETE_RESET = 'contact_delete_reset';

export const CONTACT_DETAILS_REQUEST = 'contact_details_request';
export const CONTACT_DETAILS_SUCCESS = 'contact_details_success';
export const CONTACT_DETAILS_FAIL = 'contact_details_fail';
export const CONTACT_DETAILS_RESET = 'contact_details_reset';


export const CONTACT_UPDATE_REQUEST = 'contact_update_request';
export const CONTACT_UPDATE_SUCCESS = 'contact_update_success';
export const CONTACT_UPDATE_FAIL = 'contact_update_fail';
export const CONTACT_UPDATE_RESET = 'contact_update_reset';

export const BLOCKED_CONTACT_CREATE_REQUEST = 'blocked_contact_create_request';
export const BLOCKED_CONTACT_CREATE_SUCCESS = 'blocked_contact_create_success';
export const BLOCKED_CONTACT_CREATE_FAIL = 'blocked_contact_create_fail';
export const BLOCKED_CONTACT_CREATE_RESET = 'blocked_contact_create_reset';

export const BLOCKED_CONTACT_DELETE_REQUEST = 'blocked_contact_delete_request';
export const BLOCKED_CONTACT_DELETE_SUCCESS = 'blocked_contact_delete_success';
export const BLOCKED_CONTACT_DELETE_FAIL = 'blocked_contact_delete_fail';
export const BLOCKED_CONTACT_DELETE_RESET = 'blocked_contact_delete_reset';



// groups
export const LIST_GROUPS = 'list_groups';
export const RESET_GROUP_DETAILS = 'reset_group_details';
export const LIST_GROUP_CONTACTS = 'list_group_contacts';
export const LIST_SHOW_GROUPS = 'list_show_groups';
export const LIST_SHOW_GROUPS_REQUEST = 'list_show_groups_request';
export const LIST_SHOW_GROUPS_SUCCESS = 'list_show_groups_success';
export const LIST_SHOW_GROUPS_FAIL = 'list_show_groups_fail';
export const LIST_SHOW_AND_HIDE_GROUPS = 'list_show_and_hide_groups';
export const LIST_UNSUBS = 'list_unsubs';
export const LIST_GROUPS_EXCEPT_UNSUB = "list_groups_except_unsub"
export const ADD_COMPOSE_GROUP = 'add_compose_group';


export const RESET_COMPOSE_GROUP = 'reset_compose_group';


export const GROUP_CREATE_REQUEST = 'group_create_request';
export const GROUP_CREATE_SUCCESS = 'group_create_success';
export const GROUP_CREATE_FAIL = 'group_create_fail';
export const GROUP_CREATE_RESET = 'group_create_reset';


export const GROUP_DELETE_REQUEST = 'group_delete_request';
export const GROUP_DELETE_SUCCESS = 'group_delete_success';
export const GROUP_DELETE_FAIL = 'group_delete_fail';
export const GROUP_DELETE_RESET = 'group_delete_reset';

export const GROUP_DETAILS_REQUEST = 'group_details_request';
export const GROUP_DETAILS_SUCCESS = 'group_details_success';
export const GROUP_DETAILS_FAIL = 'group_details_fail';

export const GROUP_DETAILS_TITLE_REQUEST = 'group_details_title_request';
export const GROUP_DETAILS_TITLE_SUCCESS = 'group_details_title_success';
export const GROUP_DETAILS_TITLE_FAIL = 'group_details_title_fail';

export const GROUP_UPDATE_REQUEST = 'group_update_request';
export const GROUP_UPDATE_SUCCESS = 'group_update_success';
export const GROUP_UPDATE_FAIL = 'group_update_fail';
export const GROUP_UPDATE_RESET = 'group_update_reset';

export const GROUP_SHOW_HIDE_REQUEST = 'group_show_hide_request';
export const GROUP_SHOW_HIDE_SUCCESS = 'group_show_hide_success';
export const GROUP_SHOW_HIDE_FAIL = 'group_show_hide_fail';
export const GROUP_SHOW_HIDE_RESET = 'group_show_hide_reset';

export const GROUP_MOVE_COPY_REQUEST = 'group_move_copy_request';
export const GROUP_MOVE_COPY_SUCCESS = 'group_move_copy_success';
export const GROUP_MOVE_COPY_FAIL = 'group_move_copy_fail';
export const GROUP_MOVE_COPY_RESET = 'group_move_copy_reset';


// templates
export const LIST_TEMPLATES_REQUEST = 'list_templates_request';
export const LIST_TEMPLATES_SUCCESS = 'list_templates_success';
export const LIST_TEMPLATES_FAIL = 'list_templates_fail';
export const ADD_COMPOSE_TEMPLATE = 'add_compose_template';
export const TEMPLATE_CREATE_REQUEST = 'template_create_request';
export const TEMPLATE_CREATE_SUCCESS = 'template_create_success';
export const TEMPLATE_CREATE_FAIL = 'template_create_fail';
export const TEMPLATE_CREATE_RESET = 'template_create_reset';

export const TEMPLATE_DELETE_REQUEST = 'template_delete_request';
export const TEMPLATE_DELETE_SUCCESS = 'template_delete_success';
export const TEMPLATE_DELETE_FAIL = 'template_delete_fail';
export const TEMPLATE_DELETE_RESET = 'template_delete_reset';

export const TEMPLATE_UPDATE_REQUEST = 'template_update_request';
export const TEMPLATE_UPDATE_SUCCESS = 'template_update_success';
export const TEMPLATE_UPDATE_FAIL = 'template_update_fail';
export const TEMPLATE_UPDATE_RESET = 'template_update_reset';

// fields
export const LIST_FIELDS_REQUEST = 'list_fields_request';
export const LIST_FIELDS_SUCCESS = 'list_fields_success';
export const LIST_FIELDS_FAIL = 'list_fields_fail';

export const FIELD_CREATE_REQUEST = 'field_create_request';
export const FIELD_CREATE_SUCCESS = 'field_create_success';
export const FIELD_CREATE_FAIL = 'field_create_fail';
export const FIELD_CREATE_RESET = 'field_create_reset';

export const FIELD_DELETE_REQUEST = 'field_delete_request';
export const FIELD_DELETE_SUCCESS = 'field_delete_success';
export const FIELD_DELETE_FAIL = 'field_delete_fail';
export const FIELD_DELETE_RESET = 'field_delete_reset';

export const FIELD_UPDATE_REQUEST = 'field_update_request';
export const FIELD_UPDATE_SUCCESS = 'field_update_success';
export const FIELD_UPDATE_FAIL = 'field_update_fail';
export const FIELD_UPDATE_RESET = 'field_update_reset';

export const FIELD_DETAILS_REQUEST = 'field_details_request';
export const FIELD_DETAILS_SUCCESS = 'field_details_success';
export const FIELD_DETAILS_FAIL = 'field_details_fail';

// keywords
export const LIST_KEYWORDS_REQUEST = 'list_keywords_request';
export const LIST_KEYWORDS_SUCCESS = 'list_keywords_success';
export const LIST_KEYWORDS_FAIL = 'list_keywords_fail';

export const KEYWORD_CREATE_REQUEST = 'keyword_create_request';
export const KEYWORD_CREATE_SUCCESS = 'keyword_create_success';
export const KEYWORD_CREATE_FAIL = 'keyword_create_fail';
export const KEYWORD_CREATE_RESET = 'keyword_create_reset';

export const KEYWORD_DELETE_REQUEST = 'keyword_delete_request';
export const KEYWORD_DELETE_SUCCESS = 'keyword_delete_success';
export const KEYWORD_DELETE_FAIL = 'keyword_delete_fail';
export const KEYWORD_DELETE_RESET = 'keyword_delete_reset';

export const KEYWORD_UPDATE_REQUEST = 'keyword_update_request';
export const KEYWORD_UPDATE_SUCCESS = 'keyword_update_success';
export const KEYWORD_UPDATE_FAIL = 'keyword_update_fail';
export const KEYWORD_UPDATE_RESET = 'keyword_update_reset';

export const KEYWORD_DETAILS_REQUEST = 'keyword_details_request';
export const KEYWORD_DETAILS_SUCCESS = 'keyword_details_success';
export const KEYWORD_DETAILS_FAIL = 'keyword_details_fail';

// flows
export const LIST_RESPONDERS_REQUEST = 'list_responders_request';
export const LIST_RESPONDERS_SUCCESS = 'list_responders_success';
export const LIST_RESPONDERS_FAILURE = 'list_responders_failure';


export const ADD_RESPONDER = 'add_responder';
export const RESET_RESPONDER = 'reset_responder';

export const DELETE_RESPONDER_MESSAGES_REQUEST = 'delete_responder_messages_request';
export const DELETE_RESPONDER_MESSAGES_SUCCESS = 'delete_responder_messages_success';
export const DELETE_RESPONDER_MESSAGES_FAIL = 'delete_responder_messages_fail';
export const DELETE_RESPONDER_MESSAGES_RESET = 'delete_responder_messages_reset';

export const RESPONDER_CREATE_REQUEST = 'responder_create_request';
export const RESPONDER_CREATE_SUCCESS = 'responder_create_success';
export const RESPONDER_CREATE_FAIL = 'responder_create_fail';

export const RESPONDER_DELETE_REQUEST = 'responder_delete_request';
export const RESPONDER_DELETE_SUCCESS = 'responder_delete_success';
export const RESPONDER_DELETE_FAIL = 'responder_delete_fail';

export const RESPONDER_UPDATE_REQUEST = 'responder_update_request';
export const RESPONDER_UPDATE_SUCCESS = 'responder_update_success';
export const RESPONDER_UPDATE_FAIL = 'responder_update_fail';
export const RESPONDER_UPDATE_RESET = 'responder_update_reset';

export const RESPONDER_DETAILS_REQUEST = 'responder_details_request';
export const RESPONDER_DETAILS_SUCCESS = 'responder_details_success';
export const RESPONDER_DETAILS_FAIL = 'responder_details_fail';

//--------------------------------------------------------------------------------------------------CSV Upload---------------------------------------------------------------------------------------------------

export const LIST_CSVUPLOAD = 'list_csvUpload';
export const LIST_CSVUPLOAD_REQUEST = 'list_csvUpload_request';
export const LIST_CSVUPLOAD_SUCCESS = 'list_csvUpload_success';
export const LIST_CSVUPLOAD_FAIL = 'list_csvUpload_fail';

export const CSVUPLOAD_CREATE_REQUEST = 'csvUpload_create_request';
export const CSVUPLOAD_CREATE_SUCCESS = 'csvUpload_create_success';
export const CSVUPLOAD_CREATE_FAIL = 'csvUpload_create_fail';
export const CSVUPLOAD_CREATE_RESET = 'csvUpload_create_reset';

export const CSVUPLOAD_DELETE_REQUEST = 'csvUpload_delete_request';
export const CSVUPLOAD_DELETE_SUCCESS = 'csvUpload_delete_success';
export const CSVUPLOAD_DELETE_FAIL = 'csvUpload_delete_fail';
export const CSVUPLOAD_DELETE_RESET = 'csvUpload_delete_reset';

//--------------------------------------------------------------------------------------------------History---------------------------------------------------------------------------------------------------

export const LIST_SENT_MESSAGES = "list_sent_messages";
export const LIST_SENT_MESSAGES_REQUEST = "list_sent_messages_request";
export const LIST_SENT_MESSAGES_SUCCESS = "list_sent_messages_success";
export const LIST_SENT_MESSAGES_FAIL = "list_sent_messages_fail";

export const LIST_SENT_MESSAGES_CREATE_REQUEST = "list_sent_messages_create_request";
export const LIST_SENT_MESSAGES_CREATE_SUCCESS = "list_sent_messages_create_success";
export const LIST_SENT_MESSAGES_CREATE_FAIL = "list_sent_messages_create_fail";

export const DELETE_SENT_MESSAGES_CREATE_REQUEST = "delete_sent_messages_create_request";
export const DELETE_SENT_MESSAGES_CREATE_SUCCESS = "delete_sent_messages_create_success";
export const DELETE_SENT_MESSAGES_CREATE_FAIL = "delete_sent_messages_create_fail";

export const LIST_RECEIVED_MESSAGES = "list_received_messages";

export const LIST_RECEIVED_MESSAGES_REQUEST = "list_received_messages_request";
export const LIST_RECEIVED_MESSAGES_SUCCESS = "list_received_messages_success";
export const LIST_RECEIVED_MESSAGES_FAIL = "list_received_messages_fail";


export const LIST_RECEIVED_MESSAGES_CREATE_REQUEST = "list_received_messages_create_request";
export const LIST_RECEIVED_MESSAGES_CREATE_SUCCESS = "list_received_messages_create_success";
export const LIST_RECEIVED_MESSAGES_CREATE_FAIL = "list_received_messages_create_fail";

export const DELETE_RECEIVED_MESSAGES_CREATE_REQUEST = "delete_received_messages_create_request";
export const DELETE_RECEIVED_MESSAGES_CREATE_SUCCESS = "delete_received_messages_create_success";
export const DELETE_RECEIVED_MESSAGES_CREATE_FAIL = "delete_received_messages_create_fail";


// messaging
export const MESSAGE_CREATE_REQUEST = "message_create_request";
export const MESSAGE_CREATE_SUCCESS = "message_create_success";
export const MESSAGE_CREATE_FAIL = "message_create_fail";


//--------------------------------------------------------------------------------------------------Chat---------------------------------------------------------------------------------------------------


export const LIST_ACTIVE_CHAT = "list_active_chat";
export const UPDATE_ACTIVE_CHAT = "update_active_chat";

export const LIST_CHATS = "list_chats";
export const ADD_CHAT = "add_chat";
export const CHAT_SWAP_INFO = 'chat_swap_info';
export const EDIT_CHAT = "edit_chat";
export const DELETE_CHAT = "delete_chat";
export const REMOVE_BLOCKED_CHAT = "remove_blocked_chat";
export const REMOVE_UNSUBSCRIBED_CHAT = "remove_unsubscribed_chat";

export const CHAT_CREATE_REQUEST = 'chat_create_request';
export const CHAT_CREATE_SUCCESS = 'chat_create_success';
export const CHAT_CREATE_FAIL = 'chat_create_fail';

export const CHAT_DELETE_REQUEST = 'chat_delete_request';
export const CHAT_DELETE_SUCCESS = 'chat_delete_success';
export const CHAT_DELETE_FAIL = 'chat_delete_fail';

export const CHAT_UPDATE_REQUEST = 'chat_update_request';
export const CHAT_UPDATE_SUCCESS = 'chat_update_success';
export const CHAT_UPDATE_FAIL = 'chat_update_fail';



//--------------------------------------------------------------------------------------------------Chat messages---------------------------------------------------------------------------------------------------

export const FETCH_CHAT_MESSAGES_REQUEST = 'fetch_chat_messages_request';
export const FETCH_CHAT_MESSAGES_SUCCESS = 'fetch_chat_messages_success';
export const LIST_CHAT_MESSAGES_REQUEST = "list_chat_messages_request";
export const LIST_CHAT_MESSAGES_SUCCESS = "list_chat_messages_success";
export const LIST_CHAT_MESSAGES_FAIL = "list_chat_messages_fail";
export const LIST_CHAT_MESSAGES = "list_chat_messages";
export const CREATE_LIST_EACH_CHAT_MESSAGES = "create_list_each_chat_messages";
export const LIST_CHAT_MESSAGES_CREATE = "list_chat_messages_create";

export const ADD_CHAT_MESSAGE = "add_chat_message";
export const ADD_INCOMING_CHAT_MESSAGE = "add_incoming_chat_message";
export const DELETE_CHAT_MESSAGE = "delete_chat_message";
export const DELETE_CHAT_CALL = "delete_chat_call";

export const UPDATE_MESSAGE_STATUS = "update_message_status";

export const CHAT_MESSAGE_UPDATE_SUCCESS = 'chat_message_update_success';

export const CHAT_MESSAGES_CREATE_REQUEST = "chat_messages_create_request";
export const CHAT_MESSAGES_CREATE_SUCCESS = "chat_messages_create_success";
export const CHAT_MESSAGES_CREATE_FAIL = "chat_messages_create_fail";

export const CHAT_MESSAGES_DELETE_REQUEST = "chat_messages_delete_request";
export const CHAT_MESSAGES_DELETE_SUCCESS = "chat_messages_delete_success";
export const CHAT_MESSAGES_DELETE_FAIL = "chat_messages_delete_fail";






//--------------------------------------------------------------------------------------------------Attach file---------------------------------------------------------------------------------------------------

export const ATTACH_FILE_CREATE_REQUEST = "attach_file_create_request";
export const ATTACH_FILE_CREATE_SUCCESS = "attach_file_create_success";
export const ATTACH_FILE_CREATE_FAIL = "attach_file_create_fail";
export const ATTACH_FILE_CREATE_RESET = "attach_file_create_reset";


//--------------------------------------------------------------------------------------------------Attach MMS---------------------------------------------------------------------------------------------------


export const ATTACH_MMS_CREATE_REQUEST = "attach_mms_create_request";
export const ATTACH_MMS_CREATE_SUCCESS = "attach_mms_create_success";
export const ATTACH_MMS_CREATE_FAIL = "attach_mms_create_fail";
export const ATTACH_MMS_RESET = "attach_mms_reset";

export const PREVIEW_MMS_CREATE_REQUEST = "preview_mms_create_request";
export const PREVIEW_MMS_CREATE_SUCCESS = "preview_mms_create_success";
export const PREVIEW_MMS_CREATE_FAIL = "preview_mms_create_fail";
export const PREVIEW_MMS_RESET = "preview_mms_reset";


//--------------------------------------------------------------------------------------------------Numbers---------------------------------------------------------------------------------------------------

export const LIST_NUMBERS = "list_numbers";

export const LIST_TOLL_FREE_NUMBERS_REQUEST = "list_toll_free_numbers_request";
export const LIST_TOLL_FREE_NUMBERS_SUCCESS = "list_toll_free_numbers_success";
export const LIST_TOLL_FREE_NUMBERS_FAIL = "list_toll_free_numbers_fail";

export const LIST_DLC_NUMBERS_REQUEST = "list_dlc_numbers_request";
export const LIST_DLC_NUMBERS_SUCCESS = "list_dlc_numbers_success";
export const LIST_DLC_NUMBERS_FAIL = "list_dlc_numbers_fail";

export const LIST_DLC_CAMPAIGNS_REQUEST = "list_dlc_campaigns_request";
export const LIST_DLC_CAMPAIGNS_SUCCESS = "list_dlc_campaigns_success";
export const LIST_DLC_CAMPAIGNS_FAIL = "list_dlc_campaigns_fail";

export const UPDATE_PRIMARY_NUMBER = "update_primary_number";

export const LIST_PRIMARY_NUMBER = "list_primary_number";

export const AVAIL_NUMBERS_REQUEST = "avail-numbers-request";
export const AVAIL_NUMBERS_SUCCESS = "avail-numbers-success";
export const AVAIL_NUMBERS_FAIL = "avail-numbers-fail";
export const AVAIL_NUMBERS_RESET = "avail-numbers-reset";

export const NUMBER_CREATE_REQUEST = "number-create-request";
export const NUMBER_CREATE_SUCCESS = "number-create-success";
export const NUMBER_CREATE_FAIL = "number-create-fail";
export const NUMBER_CREATE_RESET = "number-create-reset";

export const NUMBER_DETAILS_REQUEST = "number-details-request";
export const NUMBER_DETAILS_SUCCESS = "number-details-success";
export const NUMBER_DETAILS_FAIL = "number-details-fail";

export const NUMBER_DELETE_REQUEST = 'number_delete_request';
export const NUMBER_DELETE_SUCCESS = 'number_delete_success';
export const NUMBER_DELETE_FAIL = 'number_delete_fail';
export const NUMBER_DELETE_RESET = 'number_delete_reset';

export const NUMBER_UPDATE_REQUEST = 'number_update_request';
export const NUMBER_UPDATE_SUCCESS = 'number_update_success';
export const NUMBER_UPDATE_FAIL = 'number_update_fail';
export const NUMBER_UPDATE_RESET = 'number_update_reset';

export const PRIMARY_NUMBER_UPDATE_REQUEST = 'primary_number_update_request';
export const PRIMARY_NUMBER_UPDATE_SUCCESS = 'primary_number_update_success';
export const PRIMARY_NUMBER_UPDATE_FAIL = 'primary_number_update_fail';
export const PRIMARY_NUMBER_UPDATE_RESET = 'primary_number_update_reset';
//-------------------------------------------------------------------------------------------------Calendar------------------------------------------------------

export const UPDATE_MONTH_VIEW = "update_month_view";
export const LIST_CALENDAR = "list_calendar";
export const LIST_PAUSED_CALENDAR = "list_paused_calendar";
export const LIST_COMPLETED_CALENDAR = "list_completed_calendar";

export const CALENDAR_CREATE_REQUEST = "calendar_create_request";
export const CALENDAR_CREATE_SUCCESS = "calendar_create_success";
export const CALENDAR_CREATE_FAIL = "calendar_create_fail";
export const CALENDAR_CREATE_RESET = "calendar_create_reset";

export const CALENDAR_DETAILS_REQUEST = "calendar_details_request";
export const CALENDAR_DETAILS_SUCCESS = "calendar_details_success";
export const CALENDAR_DETAILS_FAIL = "calendar_details_fail";

export const CALENDAR_DELETE_REQUEST = 'calendar_delete_request';
export const CALENDAR_DELETE_SUCCESS = 'calendar_delete_success';
export const CALENDAR_DELETE_FAIL = 'calendar_delete_fail';

export const CALENDAR_UPDATE_REQUEST = 'calendar_update_request';
export const CALENDAR_UPDATE_SUCCESS = 'calendar_update_success';
export const CALENDAR_UPDATE_FAIL = 'calendar_update_fail';
export const CALENDAR_UPDATE_RESET = 'calendar_update_reset';

export const CALENDAR_RESUME_REQUEST = 'calendar_resume_request';
export const CALENDAR_RESUME_SUCCESS = 'calendar_resume_success';
export const CALENDAR_RESUME_FAIL = 'calendar_resume_fail';
export const CALENDAR_RESUME_RESET = 'calendar_resume_reset';

//-------------------------------------------------------------------------------------------------Credit create--------------------------------------

export const MERCHANT_DETAILS_REQUEST = 'merchant-details-request';
export const MERCHANT_DETAILS_SUCCESS = 'merchant-details-success';
export const MERCHANT_DETAILS_FAIL = 'merchant-details-fail';
export const MERCHANT_DETAILS_RESET = 'merchant-details-reset';

export const CREDIT_CREATE_REQUEST = 'credit_create_request';
export const CREDIT_CREATE_SUCCESS = 'credit_create_success';
export const CREDIT_CREATE_FAIL = 'credit_create_fail';
export const CREDIT_CREATE_RESET = 'credit_create_reset';

//-------------------------------------------------------------------------------------------------Call create--------------------------------------

export const LIST_CALLS_REQUEST = "list_calls_request";
export const LIST_CALLS_SUCCESS = "list_calls_success";
export const LIST_CALLS_FAIL = "list_calls_fail";
export const LIST_CALLS_CREATE_REQUEST = "list_calls_create_request";
export const LIST_CALLS_CREATE_SUCCESS = "list_calls_create_success";
export const LIST_CALLS_CREATE_FAIL = "list_calls_create_fail";

export const CALL_CREATE_REQUEST = 'call_create_request';
export const CALL_CREATE_SUCCESS = 'call_create_success';
export const CALL_CREATE_FAIL = 'call_create_fail';
export const CALL_CREATE_RESET = 'call_create_reset';

export const CALLS_DELETE_REQUEST = 'calls_delete_request';
export const CALLS_DELETE_SUCCESS = 'calls_delete_success';
export const CALLS_DELETE_FAIL = 'calls_delete_fail';
export const CALLS_DELETE_RESET = 'calls_delete_reset';

export const CREATE_TOKEN_REQUEST = 'create_token _request';
export const CREATE_TOKEN_SUCCESS = 'create_token_success';
export const CREATE_TOKEN_FAIL = 'create_token_fail';
export const CREATE_TOKEN_RESET = 'create_token_reset';




//--------------------------------------------------------------------------------------------------TABS---------------------------------------------------------------------------------------------------
export const ADD_TAB = 'add_tab';

export const ADD_EVENT = 'add_event';
export const ADD_EVENTS_PLUGIN = 'add_events_plugin';
export const RESET_EVENT = 'reset_event';

export const PLAN_UPDATE_STATE = 'plan_update_state';
export const PLAN_UPDATE_STATE_RESET = 'plan_update_state_reset';


export const ADD_CONTENT = 'add_content';
export const RESET_BULK_TEXT_MESSAGE_CONTENT = "reset_bulk_text_message_content"

export const SUBSCRIPTION_CANCEL_REQUEST = 'subscription_cancel_request';
export const SUBSCRIPTION_CANCEL_SUCCESS = 'subscription_cancel_success';
export const SUBSCRIPTION_CANCEL_FAIL = 'subscription_cancel_fail';
export const SUBSCRIPTION_CANCEL_RESET = 'subscription_cancel_reset';


export const CARD_SECRET_REQUEST = 'card_secret_request';
export const CARD_SECRET_SUCCESS = 'card_secret_success';
export const CARD_SECRET_FAIL = 'card_secret_fail';

export const CONFIRM_SETUP_INTENT_REQUEST = 'confirm_setup_intent_request';
export const CONFIRM_SETUP_INTENT_SUCCESS = 'confirm_setup_intent_success';
export const CONFIRM_SETUP_INTENT_FAIL = 'confirm_setup_intent_fail';

export const PAYMENT_METHOD_CREATE_REQUEST = 'payment_method_create_request';
export const PAYMENT_METHOD_CREATE_SUCCESS = 'payment_method_create_success';
export const PAYMENT_METHOD_CREATE_FAIL = 'payment_method_create_fail';
export const PAYMENT_METHOD_CREATE_RESET = 'payment_method_create_reset';

export const PAYMENT_METHOD_DELETE_REQUEST = 'payment_method_delete_request';
export const PAYMENT_METHOD_DELETE_SUCCESS = 'payment_method_delete_success';
export const PAYMENT_METHOD_DELETE_FAIL = 'payment_method_delete_fail';

export const DEFAULT_PAY_METHOD_CREATE_REQUEST = 'payment_method_create_request';
export const DEFAULT_PAY_METHOD_CREATE_SUCCESS = 'payment_method_create_success';
export const DEFAULT_PAY_METHOD_CREATE_FAIL = 'payment_method_create_fail';


//---------------------------------------------------------------------------------REDUX ROWS--------------------------------------------------------------------------------------------

export const UPDATE_SELECTED_ROWS = 'update_selected_rows';

export const UPDATE_SELECTED_GROUP_ROWS = 'update_selected_group_rows';
export const REMOVE_SELECTED_GROUP_ROWS = 'remove_selected_group_rows';
export const RESET_SELECTED_GROUP_ROWS = 'reset_selected_group_rows';

export const UPDATE_SELECTED_BLOCKED_ROWS = 'update_selected_blocked_rows';
export const REMOVE_SELECTED_BLOCKED_ROWS = 'remove_selected_blocked_rows';
export const RESET_SELECTED_BLOCKED_ROWS = 'reset_selected_blocked_rows';

export const UPDATE_SELECTED_SENT_ROWS = 'update_selected_sent_rows';
export const REMOVE_SELECTED_SENT_ROWS = 'remove_selected_sent_rows';
export const RESET_SELECTED_SENT_ROWS = 'reset_selected_sent_rows';

export const UPDATE_SELECTED_RESPONDER_HISTORY_ROWS = 'update_selected_responder_history_rows';
export const REMOVE_SELECTED_RESPONDER_HISTORY_ROWS = 'remove_selected_responder_history_rows';
export const RESET_SELECTED_RESPONDER_HISTORY_ROWS = 'reset_selected_responder_history_rows';

export const UPDATE_SELECTED_RECEIVED_ROWS = 'update_selected_received_rows';
export const REMOVE_SELECTED_RECEIVED_ROWS = 'remove_selected_received_rows';
export const RESET_SELECTED_RECEIVED_ROWS = 'reset_selected_received_rows';

export const UPDATE_SELECTED_CALLS_ROWS = 'update_selected_calls_rows';
export const REMOVE_SELECTED_CALLS_ROWS = 'remove_selected_calls_rows';
export const RESET_SELECTED_CALLS_ROWS = 'reset_selected_calls_rows';


//-----------------------------------------------------------------------BULKSEND------------------------------------------------------------------------------------------------------
export const LIST_BULK_SEND = 'list_bulk_send';
export const BULK_SEND_CREATE_REQUEST = "bulk_send_create_request";
export const BULK_SEND_CREATE_SUCCESS = "bulk_send_create_success";
export const BULK_SEND_CREATE_FAIL = "bulk_send_create_fail";
export const BULK_SEND_CREATE_RESET = "bulk_send_create_reset";

export const DELETE_BULK_SEND_REQUEST = "delete_bulk_send_request";
export const DELETE_BULK_SEND_SUCCESS = "delete_bulk_send_success";
export const DELETE_BULK_SEND_FAIL = "delete_bulk_send_fail";
export const DELETE_BULK_SEND_RESET = "delete_bulk_send_reset";


export const LIST_BULK_SEND_MESSAGES = "list_bulk_send_messages";
export const LIST_BULK_SEND_DELIVERED_MESSAGES = "list_bulk_send_delivered_messages";
export const LIST_BULK_SEND_SENT_MESSAGES = "list_bulk_send_sent_messages";
export const BULK_SEND_DETAILS_RESET = "bulk_send_details_reset";

export const LIST_BULK_SEND_FAILED_MESSAGES_REQUEST = "list_bulk_send_failed_messages_request";
export const LIST_BULK_SEND_FAILED_MESSAGES_SUCCESS = "list_bulk_send_failed_messages_success";
export const LIST_BULK_SEND_FAILED_MESSAGES_FAIL = "list_bulk_send_failed_messages_fail";
export const LIST_BULK_SEND_FAILED_MESSAGES_RESET = "list_bulk_send_failed_messages_reset";

export const LIST_BULK_SEND_DELIVERED_MESSAGES_REQUEST = "list_bulk_send_delivered_messages_request";
export const LIST_BULK_SEND_DELIVERED_MESSAGES_SUCCESS = "list_bulk_send_delivered_messages_success";
export const LIST_BULK_SEND_DELIVERED_MESSAGES_FAIL = "list_bulk_send_delivered_messages_fail";
export const LIST_BULK_SEND_DELIVERED_MESSAGES_RESET = "list_bulk_send_delivered_messages_reset";

//---------------------------------------------------------------------------------BULKMESSAGE CONTENT--------------------------------------------------------------------------------------------
export const ADD_BULK_MESSAGE_CONTENT = "add_bulk_message_content";
export const ADD_TO_FIELD_HTML = "add_to_field_html";
//---------------------------------------------------------------------------------ACCOUNT --------------------------------------------------------------------------------------------

export const ACCOUNT_DELETE_REQUEST = 'account_delete_request';
export const ACCOUNT_DELETE_SUCCESS = 'account_delete_success';
export const ACCOUNT_DELETE_FAIL = 'account_delete_fail';
export const ACCOUNT_DELETE_RESET = 'account_delete_reset';

export const FORGOT_PASSWORD_CREATE_REQUEST = 'forgot_password_create_request';
export const FORGOT_PASSWORD_CREATE_SUCCESS = 'forgot_password_create_success';
export const FORGOT_PASSWORD_CREATE_FAIL = 'forgot_password_create_fail';

export const UPDATE_PASSWORD_REQUEST = 'update_password_request';
export const UPDATE_PASSWORD_SUCCESS = 'update_password_success';
export const UPDATE_PASSWORD_FAIL = 'update_password_fail';
export const UPDATE_PASSWORD_RESET = 'update_password_reset';

export const LIST_RESPONDER_MESSAGES = 'list_responder_messages';
export const LIST_RESPONDER_MESSAGES_REQUEST = 'list_responder_messages_request';
export const LIST_RESPONDER_MESSAGES_SUCCESS = 'list_responder_messages_success';
export const LIST_RESPONDER_MESSAGES_FAIL = 'list_responder_messages_fail';

export const SET_EMAIL = 'set_email';
export const SET_PASS = 'set_pass';

export const CUSTOMER_PROFILE_REQUEST = 'customer_profile_request';
export const CUSTOMER_PROFILE_SUCCESS = 'customer_profile_success';
export const CUSTOMER_PROFILE_FAIL = 'customer_profile_fail';

export const SUBSCRIPTION_DETAILS_REQUEST = 'subscription_details_request';
export const SUBSCRIPTION_DETAILS_SUCCESS = 'subscription_details_success';
export const SUBSCRIPTION_DETAILS_FAIL = 'subscription_details_fail';

export const UPDATE_PAGE_SIZE = "update_page_size";
export const UPDATE_PAGE_INDEX = "update_page_index";

export const FETCH_CONTACTS_REQUEST = 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS';
export const FETCH_CONTACTS_FAILURE = 'FETCH_CONTACTS_FAILURE';

export const UPDATE_SELECTED_CONTACT_ROWS = "update_selected_contact_rows";

export const CREATE_BULK_INITIAL_STATE = "create_bulk_initial_state";
export const SET_CONTACTS = "set_contacts";
export const REMOVE_CONTACTS = "remove_contacts";
export const SET_GROUPS = "set_groups";
export const REMOVE_GROUPS = "remove_groups";
export const SET_BULK_NUMBER = "set_bulk_number";
export const ADD_MESSAGE_CONTENTS = "add_message_contents";
export const UPDATE_SESSIONS = "update_sessions";
export const UPDATE_START_DATE = "update_start_date";
export const UPDATE_END_DATE = "update_end_date";
export const UPDATE_HOUR_CYCLES = "update_hour_cycles";
export const UPDATE_DAY_TIMES = "update_day_times";
export const UPDATE_DAY_COUNT = "update_day_count";
export const UPDATE_WEEK_COUNT = "update_week_count";
export const UPDATE_WEEK_TIMES = "update_week_times";
export const UPDATE_MONTH_TIMES = "update_month_times";
export const UPDATE_WEEK_DAYS = "update_week_days";
export const UPDATE_BULK_TITLE = "update_bulk_title";
export const UPDATE_BULK_TIMEZONE = "update_bulk_timezone";
export const UPDATE_BULK_CLASSNAME = "update_bulk_classname";
export const UPDATE_CAMPAIGN_TYPE = "update_campaign_type";
export const UPDATE_END_TYPE = "update_end_type";
export const UPDATE_MONTH_ON_DAY = "update_month_on_day";
export const UPDATE_MONTH_ON_THE = "update_month_on_the";
export const UPDATE_CAMPAIGN_REPEAT = "update_campaign_repeat";
export const RESET_CREATE_BULK = "reset_create_bulk";
export const UPDATE_WITH_EVENT = "update_with_event";
export const UPDATE_RECIPIENT_COUNT = "update_recipient_count";

export const LIST_SERVICES = "list_services";

export const CREATE_SERVICE_REQUEST = "create_service_request";
export const CREATE_SERVICE_SUCCESS = "create_service_success";
export const CREATE_SERVICE_FAIL = "create_service_fail";
export const CREATE_SERVICE_RESET = "create_service_reset";

export const UPDATE_SERVICE_REQUEST = "create_service_request";
export const UPDATE_SERVICE_SUCCESS = "create_service_success";
export const UPDATE_SERVICE_FAIL = "create_service_fail";
export const UPDATE_SERVICE_RESET = "update_service_reset";

export const DELETE_SERVICE_REQUEST = "create_service_request";
export const DELETE_SERVICE_SUCCESS = "create_service_success";
export const DELETE_SERVICE_FAIL = "create_service_fail";


export const LIST_MATERIALS = "list_materials";

export const CREATE_MATERIAL_REQUEST = "create_material_request";
export const CREATE_MATERIAL_SUCCESS = "create_material_success";
export const CREATE_MATERIAL_FAIL = "create_material_fail";
export const CREATE_MATERIAL_RESET = "create_material_reset";

export const MATERIAL_DETAILS_REQUEST = "material_details_request";
export const MATERIAL_DETAILS_SUCCESS = "material_details_success";
export const MATERIAL_DETAILS_FAIL = "material_details_fail";
export const MATERIAL_DETAILS_RESET = "material_details_reset";

export const UPDATE_MATERIAL_REQUEST = "update_material_request";
export const UPDATE_MATERIAL_SUCCESS = "update_material_success";
export const UPDATE_MATERIAL_FAIL = "update_material_fail";
export const UPDATE_MATERIAL_RESET = "update_material_reset";

export const DELETE_MATERIAL_REQUEST = "delete_material_request";
export const DELETE_MATERIAL_SUCCESS = "delete_material_success";
export const DELETE_MATERIAL_FAIL = "delete_material_fail";

export const LIST_ITEMS = "list_items";
export const LIST_ITEMS_REQUEST = "list_items_request";
export const LIST_ITEMS_SUCCESS = "list_items_success";
export const LIST_ITEMS_FAIL = "list_items_fail";
export const LIST_ITEMS_TOTAL = "list_items_total";

export const INSERT_UPDATED_ITEM = "insert_updated_item";

export const CREATE_ITEM_REQUEST = "create_item_request";
export const CREATE_ITEM_SUCCESS = "create_item_success";
export const CREATE_ITEM_FAIL = "create_item_fail";
export const CREATE_ITEM_RESET = "create_item_reset";

export const ITEM_DETAILS_REQUEST = "item_details_request";
export const ITEM_DETAILS_SUCCESS = "item_details_success";
export const ITEM_DETAILS_FAIL = "item_details_fail";
export const ITEM_DETAILS_RESET = "item_details_reset";

export const UPDATE_ITEM_REQUEST = "update_item_request";
export const UPDATE_ITEM_SUCCESS = "update_item_success";
export const UPDATE_ITEM_FAIL = "update_item_fail";
export const UPDATE_ITEM_RESET = "update_item_reset";

export const DELETE_ITEM_REQUEST = "delete_item_request";
export const DELETE_ITEM_SUCCESS = "delete_item_success";
export const DELETE_ITEM_FAIL = "delete_item_fail";

export const LIST_PDF = "list_pdf";

export const PDF_UPDATE_REQUEST = "pdf_update_request";
export const PDF_UPDATE_SUCCESS = "pdf_update_success";
export const PDF_UPDATE_FAIL = "pdf_update_fail";
export const PDF_UPDATE_RESET = "pdf_update_reset";

export const LIST_COMPANY_DETAILS = "list_company_details";

export const COMPANY_DETAILS_UPDATE_REQUEST = "company_details_update_request";
export const COMPANY_DETAILS_UPDATE_SUCCESS = "company_details_update_success";
export const COMPANY_DETAILS_UPDATE_FAIL = "company_details_update_fail";
export const COMPANY_DETAILS_UPDATE_RESET = "company_details_update_reset";

export const LIST_COMPANY_LOGO = "list_company_logo";

export const ATTACH_LOGO_CREATE_REQUEST = "attach_logo_create_request";
export const ATTACH_LOGO_CREATE_SUCCESS = "attach_logo_create_success";
export const ATTACH_LOGO_CREATE_FAIL = "attach_logo_create_fail";
export const ATTACH_LOGO_CREATE_RESET = "attach_logo_create_reset";

export const LIST_PAY_SETTINGS = "list_pay_settings";
export const LIST_BANK_PAYOUTS_REQUEST = "list_bank_payouts_request";
export const LIST_BANK_PAYOUTS_SUCCESS = "list_bank_payouts_success";
export const LIST_BANK_PAYOUTS_FAIL = "list_bank_payouts_fail";
export const LIST_TRANSACTIONS_REQUEST = "list_transactions_request";
export const LIST_TRANSACTIONS_SUCCESS = "list_transactions_success";
export const LIST_TRANSACTIONS_FAIL = "list_transactions_fail";


export const PAY_SETTINGS_UPDATE_REQUEST = "pay_settings_update_request";
export const PAY_SETTINGS_UPDATE_SUCCESS = "pay_settings_update_success";
export const PAY_SETTINGS_UPDATE_FAIL = "pay_settings_update_fail";
export const PAY_SETTINGS_UPDATE_RESET = "pay_settings_update_reset";

export const LOAD_ESTIMATE = "load_estimate";
export const UPDATE_ESTIMATE = "update_estimate";
export const CLEAR_ESTIMATE = "clear_estimate";

export const CREATE_ESTIMATE_REQUEST = "create_estimate_request";
export const CREATE_ESTIMATE_SUCCESS = "create_estimate_success";
export const CREATE_ESTIMATE_FAIL = "create_estimate_fail";
export const CREATE_ESTIMATE_RESET = "create_estimate_reset";

export const ESTIMATE_DETAILS_REQUEST = "estimate_details_request";
export const ESTIMATE_DETAILS_SUCCESS = "estimate_details_success";
export const ESTIMATE_DETAILS_FAIL = "estimate_details_fail";

export const TAX_ID_UPDATE_REQUEST = "tax_id_update_request";
export const TAX_ID_UPDATE_SUCCESS = "tax_id_update_success";
export const TAX_ID_UPDATE_FAIL = "tax_id_update_fail";
export const TAX_ID_UPDATE_RESET = "tax_id_update_reset";

export const LIST_TAX_RATES = "list_tax_rates";

export const TAX_RATES_CREATE_REQUEST = "tax_rates_create_request";
export const TAX_RATES_CREATE_SUCCESS = "tax_rates_create_success";
export const TAX_RATES_CREATE_FAIL = "tax_rates_create_fail";
export const TAX_RATES_CREATE_RESET = "tax_rates_create_reset";

export const TAX_RATES_DELETE_REQUEST = "tax_rates_delete_request";
export const TAX_RATES_DELETE_SUCCESS = "tax_rates_delete_success";
export const TAX_RATES_DELETE_FAIL = "tax_rates_delete_fail";
export const TAX_RATES_DELETE_RESET = "tax_rates_delete_reset";

export const TAX_RATES_UPDATE_REQUEST = "tax_rates_update_request";
export const TAX_RATES_UPDATE_SUCCESS = "tax_rates_update_success";
export const TAX_RATES_UPDATE_FAIL = "tax_rates_update_fail";
export const TAX_RATES_UPDATE_RESET = "tax_rates_update_reset";

export const UPDATE_ESTIMATE_ACTION = "update_estimate_action";
export const ESTIMATE_ACTION_RESET = "estimate_action_reset";

export const LIST_ESTIMATES = "list_estimates";
export const LIST_ESTIMATES_TOTAL = "list_estimates_total";
export const LIST_ESTIMATES_FAILED = "list_estimates_failed";

export const ESTIMATE_STATUS_UPDATE_REQUEST = "estimate_status_update_request";
export const ESTIMATE_STATUS_UPDATE_SUCCESS = "estimate_status_update_success";
export const ESTIMATE_STATUS_UPDATE_FAIL = "estimate_status_update_fail";
export const ESTIMATE_STATUS_UPDATE_RESET = "estimate_status_update_reset";

export const ESTIMATE_UPDATE_REQUEST = "estimate_update_request";
export const ESTIMATE_UPDATE_SUCCESS = "estimate_update_success";
export const ESTIMATE_UPDATE_FAIL = "estimate_update_fail";
export const ESTIMATE_UPDATE_RESET = "estimate_update_reset";

export const ESTIMATE_DELETE_REQUEST = "estimate_delete_request";
export const ESTIMATE_DELETE_SUCCESS = "estimate_delete_success";
export const ESTIMATE_DELETE_FAIL = "estimate_delete_fail";
export const ESTIMATE_DELETE_RESET = "estimate_delete_reset";

export const EMAIL_ESTIMATE_REQUEST = "email_estimate_request";
export const EMAIL_ESTIMATE_SUCCESS = "email_estimate_success";
export const EMAIL_ESTIMATE_FAIL = "email_estimate_fail";
export const EMAIL_ESTIMATE_RESET = "email_estimate_reset";

export const LIST_CUSTOMERS = "list_customers";


// tags
export const LIST_TAGS = 'list_tags';

export const TAG_CREATE_REQUEST = 'tag_create_request';
export const TAG_CREATE_SUCCESS = 'tag_create_success';
export const TAG_CREATE_FAIL = 'tag_create_fail';
export const TAG_CREATE_RESET = 'tag_create_reset';

export const TAG_DELETE_REQUEST = 'tag_delete_request';
export const TAG_DELETE_SUCCESS = 'tag_delete_success';
export const TAG_DELETE_FAIL = 'tag_delete_fail';
export const TAG_DELETE_RESET = 'tag_delete_reset';

export const TAG_UPDATE_REQUEST = 'tag_update_request';
export const TAG_UPDATE_SUCCESS = 'tag_update_success';
export const TAG_UPDATE_FAIL = 'tag_update_fail';
export const TAG_UPDATE_RESET = 'tag_update_reset';

export const TAG_DETAILS_REQUEST = 'tag_details_request';
export const TAG_DETAILS_SUCCESS = 'tag_details_success';
export const TAG_DETAILS_FAIL = 'tag_details_fail';


// customer payment methods
export const LIST_CUSTOMER_PAY_METHODS = 'list_customer_pay_methods';

export const CUSTOMER_PAY_METHOD_CREATE_REQUEST = 'customer_pay_method_create_request';
export const CUSTOMER_PAY_METHOD_CREATE_SUCCESS = 'customer_pay_method_create_success';
export const CUSTOMER_PAY_METHOD_CREATE_FAIL = 'customer_pay_method_create_fail';
export const CUSTOMER_PAY_METHOD_CREATE_RESET = 'customer_pay_method_create_reset';

export const CUSTOMER_PAY_METHOD_DELETE_REQUEST = 'customer_pay_method_delete_request';
export const CUSTOMER_PAY_METHOD_DELETE_SUCCESS = 'customer_pay_method_delete_success';
export const CUSTOMER_PAY_METHOD_DELETE_FAIL = 'customer_pay_method_delete_fail';
export const CUSTOMER_PAY_METHOD_DELETE_RESET = 'customer_pay_method_delete_reset';


{/* Property Details */ }
export const PROPERTY_DETAILS_REQUEST = "property_details_request";
export const PROPERTY_DETAILS_SUCCESS = "property_details_success";
export const PROPERTY_DETAILS_FAIL = "property_details_fail";

export const PROPERTY_DETAILS_UPDATE_REQUEST = "property_details_update_request";
export const PROPERTY_DETAILS_UPDATE_SUCCESS = "property_details_update_success";
export const PROPERTY_DETAILS_UPDATE_FAIL = "property_details_update_fail";
export const PROPERTY_DETAILS_UPDATE_RESET = "property_details_update_reset";


{/* Customer Estimates */ }
export const LIST_CUSTOMER_ESTIMATES = 'list_customer_estimates';

{/* Invoices */ }

export const UPDATE_INVOICE_ACTION = "update_invoice_action";
export const INVOICE_ACTION_RESET = "invoice_action_reset";

export const LIST_CUSTOMER_INVOICES = 'list_customer_invoices';
export const LIST_INVOICES = "list_invoices";
export const LIST_INVOICES_TOTAL = "list_invoices_total";
export const LIST_INVOICES_FAILED = "list_invoices_failed";

export const INVOICE_STATUS_UPDATE_REQUEST = "invoice_status_update_request";
export const INVOICE_STATUS_UPDATE_SUCCESS = "invoice_status_update_success";
export const INVOICE_STATUS_UPDATE_FAIL = "invoice_status_update_fail";
export const INVOICE_STATUS_UPDATE_RESET = "invoice_status_update_reset";

export const INVOICE_UPDATE_REQUEST = "invoice_update_request";
export const INVOICE_UPDATE_SUCCESS = "invoice_update_success";
export const INVOICE_UPDATE_FAIL = "invoice_update_fail";
export const INVOICE_UPDATE_RESET = "invoice_update_reset";

export const INVOICE_DELETE_REQUEST = "invoice_delete_request";
export const INVOICE_DELETE_SUCCESS = "invoice_delete_success";
export const INVOICE_DELETE_FAIL = "invoice_delete_fail";
export const INVOICE_DELETE_RESET = "invoice_delete_reset";

export const EMAIL_INVOICE_REQUEST = "email_invoice_request";
export const EMAIL_INVOICE_SUCCESS = "email_invoice_success";
export const EMAIL_INVOICE_FAIL = "email_invoice_fail";
export const EMAIL_INVOICE_RESET = "email_invoice_reset";


export const LOAD_INVOICE = "load_invoice";
export const UPDATE_INVOICE = "update_invoice";
export const CLEAR_INVOICE = "clear_invoice";

export const CREATE_INVOICE_REQUEST = "create_invoice_request";
export const CREATE_INVOICE_SUCCESS = "create_invoice_success";
export const CREATE_INVOICE_FAIL = "create_invoice_fail";
export const CREATE_INVOICE_RESET = "create_invoice_reset";

export const INVOICE_DETAILS_REQUEST = "invoice_details_request";
export const INVOICE_DETAILS_SUCCESS = "invoice_details_success";
export const INVOICE_DETAILS_FAIL = "invoice_details_fail";

export const FETCH_TEAM_DETAILS_REQUEST = "fetch_team_details_request";
export const FETCH_TEAM_DETAILS_SUCCESS = "fetch_team_details_success";
export const FETCH_TEAM_DETAILS_FAIL = "fetch_team_details_fail";

export const UPDATE_TEAM_DETAILS = "update_team_details";
export const CREATE_TEAM_REQUEST = "create_team_request";
export const CREATE_TEAM_SUCCESS = "create_team_success";
export const CREATE_TEAM_FAILURE = "create_team_failure";

export const INVITE_MEMBER_SUCCESS = "invite_member_success";
export const INVITE_MEMBER_FAILURE = "invite_member_failure";
export const INVITE_MEMBER_REQUEST = "invite_member_request";
export const INVITE_MEMBER_RESET = "invite_member_reset";



export const TEAM_MEMBER_DELETE_REQUEST = "team_member_delete_request"
export const TEAM_MEMBER_DELETE_SUCCESS = "team_member_delete_success"
export const TEAM_MEMBER_DELETE_FAIL = "team_member_delete_fail"
export const TEAM_MEMBER_DELETE_RESET = "team_member_delete_reset"

export const UPDATE_TEAM_FILTER = "update_team_filter";
export const FETCH_FILTERED_DATA = "fetch_filtered_data";
export const CREATE_TEAM_RESET = "create_team_reset";
export const RESET_FETCH_FILTERED_DATA = "reset_fetch_filtered_data";
export const UPDATE_USER_WITH_TEAM = "update_user_with_team";
export const CLEAR_USER = "clear_user";

export const LOGO_ERROR = "logo_error";


//----------------------------------------------------------------------------------------------------Crm subscription-----------------------------------------------------------
export const CRM_SUBSCRIPTION_DETAILS_REQUEST = 'crm_subscription_details_request';
export const CRM_SUBSCRIPTION_DETAILS_SUCCESS = 'crm_subscription_details_success';
export const CRM_SUBSCRIPTION_DETAILS_FAIL = 'crm_subscription_details_fail';

export const CRM_SUBSCRIPTION_CREATE_REQUEST = 'crm_subscription_create_request';
export const CRM_SUBSCRIPTION_CREATE_SUCCESS = 'crm_subscription_create_success';
export const CRM_SUBSCRIPTION_CREATE_FAIL = 'crm_subscription_create_fail';


// voicemails
export const LIST_VOICEMAIL_REQUEST = 'list_voicemail_request';
export const LIST_VOICEMAIL_SUCCESS = 'list_voicemail_success';
export const LIST_VOICEMAIL_FAIL = 'list_voicemail_fail';

export const VOICEMAIL_CREATE_REQUEST = "voicemail_create_request";
export const VOICEMAIL_CREATE_SUCCESS = "voicemail_create_success";
export const VOICEMAIL_CREATE_FAIL = "voicemail_create_fail";
export const VOICEMAIL_CREATE_RESET = "voicemail_create_reset";

export const VOICEMAIL_DELETE_REQUEST = "voicemail_delete_request";
export const VOICEMAIL_DELETE_SUCCESS = "voicemail_delete_success";
export const VOICEMAIL_DELETE_FAIL = "voicemail_delete_fail";


// brand
export const LIST_BRAND_REQUEST = 'list_brand_request';
export const LIST_BRAND_SUCCESS = 'list_brand_success';
export const LIST_BRAND_FAIL = 'list_brand_fail';

export const BRAND_CREATE_REQUEST = "brand_create_request";
export const BRAND_CREATE_SUCCESS = "brand_create_success";
export const BRAND_CREATE_FAIL = "brand_create_fail";
export const BRAND_CREATE_RESET = "brand_create_reset";

export const BRAND_DELETE_REQUEST = "brand_delete_request";
export const BRAND_DELETE_SUCCESS = "brand_delete_success";
export const BRAND_DELETE_FAIL = "brand_delete_fail";

export const BRAND_UPDATE_REQUEST = "brand_update_request";
export const BRAND_UPDATE_SUCCESS = "brand_update_success";
export const BRAND_UPDATE_FAIL = "brand_update_fail";
export const BRAND_UPDATE_RESET = "brand_update_reset";



// dlc campaign
export const LIST_DLC_CAMPAIGN_REQUEST = 'list_dlc_campaign_request';
export const LIST_DLC_CAMPAIGN_SUCCESS = 'list_dlc_campaign_success';
export const LIST_DLC_CAMPAIGN_FAIL = 'list_dlc_campaign_fail';

export const DLC_CAMPAIGN_CREATE_REQUEST = "dlc_campaign_create_request";
export const DLC_CAMPAIGN_CREATE_SUCCESS = "dlc_campaign_create_success";
export const DLC_CAMPAIGN_CREATE_FAIL = "dlc_campaign_create_fail";
export const DLC_CAMPAIGN_CREATE_RESET = "dlc_campaign_create_reset";

export const DLC_CAMPAIGN_UPDATE_REQUEST = "dlc_campaign_update_request";
export const DLC_CAMPAIGN_UPDATE_SUCCESS = "dlc_campaign_update_success";
export const DLC_CAMPAIGN_UPDATE_FAIL = "dlc_campaign_update_fail";
export const DLC_CAMPAIGN_UPDATE_RESET = "dlc_campaign_update_reset";

export const DLC_CAMPAIGN_DELETE_REQUEST = "dlc_campaign_delete_request";
export const DLC_CAMPAIGN_DELETE_SUCCESS = "dlc_campaign_delete_success";
export const DLC_CAMPAIGN_DELETE_FAIL = "dlc_campaign_delete_fail";
export const DLC_CAMPAIGN_DELETE_RESET = "dlc_campaign_delete_reset";



// toll free
export const LIST_TOLL_FREE_VERIFICATION_REQUEST = 'list_toll_free_verification_request';
export const LIST_TOLL_FREE_VERIFICATION_SUCCESS = 'list_toll_free_verification_success';
export const LIST_TOLL_FREE_VERIFICATION_FAIL = 'list_toll_free_verification_fail';

export const TOLL_FREE_VERIFICATION_CREATE_REQUEST = "toll_free_verification_create_request";
export const TOLL_FREE_VERIFICATION_CREATE_SUCCESS = "toll_free_verification_create_success";
export const TOLL_FREE_VERIFICATION_CREATE_FAIL = "toll_free_verification_create_fail";
export const TOLL_FREE_VERIFICATION_CREATE_RESET = "toll_free_verification_create_reset";

export const TOLL_FREE_VERIFICATION_UPDATE_REQUEST = "toll_free_verification_update_request";
export const TOLL_FREE_VERIFICATION_UPDATE_SUCCESS = "toll_free_verification_update_success";
export const TOLL_FREE_VERIFICATION_UPDATE_FAIL = "toll_free_verification_update_fail";
export const TOLL_FREE_VERIFICATION_UPDATE_RESET = "toll_free_verification_update_reset";

export const TOLL_FREE_VERIFICATION_DELETE_REQUEST = "toll_free_verification_delete_request";
export const TOLL_FREE_VERIFICATION_DELETE_SUCCESS = "toll_free_verification_delete_success";
export const TOLL_FREE_VERIFICATION_DELETE_FAIL = "toll_free_verification_delete_fail";



// billing details follow up
export const LIST_BILLING_DETAILS_FOLLOW_UP_REQUEST = "list_billing_details_follow_up_request";
export const LIST_BILLING_DETAILS_FOLLOW_UP_SUCCESS = "list_billing_details_follow_up_success";
export const LIST_BILLING_DETAILS_FOLLOW_UP_FAIL = "list_billing_details_follow_up_fail";

export const BILLING_DETAILS_FOLLOW_UP_UPDATE_REQUEST = "billing_details_follow_up_update_request";
export const BILLING_DETAILS_FOLLOW_UP_UPDATE_SUCCESS = "billing_details_follow_up_update_success";
export const BILLING_DETAILS_FOLLOW_UP_UPDATE_FAIL = "billing_details_follow_up_update_fail";
export const BILLING_DETAILS_FOLLOW_UP_UPDATE_RESET = "billing_details_follow_up_update_resetß";



export const CREATE_COLLECT_DEPOSIT_REQUEST = "create_collect_deposit_request";
export const CREATE_COLLECT_DEPOSIT_FAIL = "create_collect_deposit_fail";
export const CREATE_COLLECT_DEPOSIT_SUCCESS = "create_collect_deposit_success";
export const CREATE_COLLECT_DEPOSIT_RESET = "create_collect_deposit_reset";

export const CREATE_COLLECT_PAYMENT_REQUEST = "create_collect_payment_request";
export const CREATE_COLLECT_PAYMENT_FAIL = "create_collect_payment_fail";
export const CREATE_COLLECT_PAYMENT_SUCCESS = "create_collect_payment_success";
export const CREATE_COLLECT_PAYMENT_RESET = "create_collect_payment_reset";

export const CREATE_RECEIPT_OTHER_REQUEST = "create_receipt_other_request";
export const CREATE_RECEIPT_OTHER_FAIL = "create_receipt_other_fail";
export const CREATE_RECEIPT_OTHER_SUCCESS = "create_receipt_other_success";
export const CREATE_RECEIPT_OTHER_RESET = "create_receipt_other_reset";

export const DELETE_RECEIPT_OTHER_REQUEST = "delete_receipt_other_request";
export const DELETE_RECEIPT_OTHER_FAIL = "delete_receipt_other_fail";
export const DELETE_RECEIPT_OTHER_SUCCESS = "delete_receipt_other_success";
export const DELETE_RECEIPT_OTHER_RESET = "delete_receipt_other_reset";

export const UPDATE_RECEIPT_OTHER_REQUEST = "update_receipt_other_request";
export const UPDATE_RECEIPT_OTHER_FAIL = "update_receipt_other_fail";
export const UPDATE_RECEIPT_OTHER_SUCCESS = "update_receipt_other_success";
export const UPDATE_RECEIPT_OTHER_RESET = "update_receipt_other_reset";

export const EMAIL_RECEIPT_REQUEST = "email_receipt_request";
export const EMAIL_RECEIPT_FAIL = "email_receipt_fail";
export const EMAIL_RECEIPT_SUCCESS = "email_receipt_success";
export const EMAIL_RECEIPT_RESET = "email_receipt_reset";


export const LIST_ESTIMATE_RECEIPTS = "list_estimate_receipts";
export const LIST_ESTIMATE_RECEIPTS_FAIL = "list_estimate_receipts_fail";
export const LIST_ESTIMATE_RECEIPTS_RESET = "list_estimate_receipts_reset";

export const LIST_CUSTOMER_RECEIPTS_REQUEST = "list_customer_receipts_request";
export const LIST_CUSTOMER_RECEIPTS_FAIL = "list_customer_receipts_fail";
export const LIST_CUSTOMER_RECEIPTS_SUCCESS = "list_customer_receipts_success";
export const LIST_CUSTOMER_RECEIPTS_RESET = "list_customer_receipts_reset";
export const UPDATE_RECEIPTS = "update_receipts";


export const EMAIL_REQUEST = "email_request";
export const EMAIL_SUCCESS = "email_success";
export const EMAIL_FAIL = "email_fail";
export const EMAIL_RESET = "email_reset";

export const EMAIL_TYPE_INVOICE = 'invoice';
export const EMAIL_TYPE_ESTIMATE = 'estimate';
export const EMAIL_TYPE_RECEIPT = 'receipt';
export const EMAIL_TYPE_CUSTOMER_PORTAL_LOGIN = "customer_portal_login";
export const EMAIL_TYPE_CUSTOMER = "customer";


export const CREATE_RECEIPT_UPLOAD_REQUEST = "create_receipt_upload_request";
export const CREATE_RECEIPT_UPLOAD_SUCCESS = "create_receipt_upload_success";
export const CREATE_RECEIPT_UPLOAD_FAIL = "create_receipt_upload_fail";
export const CREATE_RECEIPT_UPLOAD_RESET = "create_receipt_upload_reset";

export const FETCH_CUSTOMER_REVIEW_STATUS = "fetch_customer_review_status";


export const LIST_TRUSTPRO_BILLING_DETAILS_REQUEST = "list_trustpro_billing_details_request";
export const LIST_TRUSTPRO_BILLING_DETAILS_SUCCESS = "list_trustpro_billing_details_success";
export const LIST_TRUSTPRO_BILLING_DETAILS_FAIL = "list_trustpro_billing_details_fail";
export const LIST_TRUSTPRO_BILLING_DETAILS_RESET = "list_trustpro_billing_details_reset";

export const LIST_USER_SCORE_CREDITS_REQUEST = "list_user_score_credits_request";
export const LIST_USER_SCORE_CREDITS_SUCCESS = "list_user_score_credits_success";
export const LIST_USER_SCORE_CREDITS_FAIL = "list_user_score_credits_fail";
export const LIST_USER_SCORE_CREDITS_RESET = "list_user_score_credits_reset";

export const LIST_CUSTOMER_REVIEWS_REQUEST = "list_customer_reviews_request";
export const LIST_CUSTOMER_REVIEWS_SUCCESS = "list_customer_reviews_success";
export const LIST_CUSTOMER_REVIEWS_FAIL = "list_customer_reviews_fail";
export const LIST_CUSTOMER_REVIEWS_RESET = "list_customer_reviews_reset";

export const CREATE_CUSTOMER_REVIEW_REQUEST = "create_customer_review_request";
export const CREATE_CUSTOMER_REVIEW_SUCCESS = "create_customer_review_success";
export const CREATE_CUSTOMER_REVIEW_FAIL = "create_customer_review_fail";
export const CREATE_CUSTOMER_REVIEW_RESET = "create_customer_review_reset";

export const UPDATE_CUSTOMER_REVIEW_REQUEST = "update_customer_review_request";
export const UPDATE_CUSTOMER_REVIEW_SUCCESS = "update_customer_review_success";
export const UPDATE_CUSTOMER_REVIEW_FAIL = "update_customer_review_fail";
export const UPDATE_CUSTOMER_REVIEW_RESET = "update_customer_review_reset";

export const DELETE_CUSTOMER_REVIEW_REQUEST = "delete_customer_review_request";
export const DELETE_CUSTOMER_REVIEW_SUCCESS = "delete_customer_review_success";
export const DELETE_CUSTOMER_REVIEW_FAIL = "delete_customer_review_fail";
export const DELETE_CUSTOMER_REVIEW_RESET = "delete_customer_review_reset";

export const CUSTOMER_REVIEW_DETAILS_REQUEST = "customer_review_details_request";
export const CUSTOMER_REVIEW_DETAILS_SUCCESS = "customer_review_details_success";
export const CUSTOMER_REVIEW_DETAILS_FAIL = "customer_review_details_fail";
export const CUSTOMER_REVIEW_DETAILS_RESET = "customer_review_details_reset";

export const LIST_FOLLOW_UP_BILLING_DETAILS_REQUEST = "list_follow_up_billing_details_request";
export const LIST_FOLLOW_UP_BILLING_DETAILS_SUCCESS = "list_follow_up_billing_details_success";
export const LIST_FOLLOW_UP_BILLING_DETAILS_FAIL = "list_follow_up_billing_details_fail";
export const LIST_FOLLOW_UP_BILLING_DETAILS_RESET = "list_follow_up_billing_details_reset";

export const MERCHANT_DETAILS_FOLLOW_UP_REQUEST = "merchant_details_follow_up_request";
export const MERCHANT_DETAILS_FOLLOW_UP_SUCCESS = "merchant_details_follow_up_success";
export const MERCHANT_DETAILS_FOLLOW_UP_FAIL = "merchant_details_follow_up_fail";
export const MERCHANT_DETAILS_FOLLOW_UP_RESET = "merchant_details_follow_up_reset";

export const CUSTOMER_PROFILE_FOLLOW_UP_REQUEST = "customer_profile_follow_up_request";
export const CUSTOMER_PROFILE_FOLLOW_UP_SUCCESS = "customer_profile_follow_up_success";
export const CUSTOMER_PROFILE_FOLLOW_UP_FAIL = "customer_profile_follow_up_fail";
export const CUSTOMER_PROFILE_FOLLOW_UP_RESET = "customer_profile_follow_up_reset";

export const UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP = 'UPDATE_USER_WITH_CUSTOMER_PROFILE_FOLLOW_UP';

export const FOLLOW_UP_SUBSCRIPTION_CANCEL_REQUEST = "follow_up_subscription_cancel_request";
export const FOLLOW_UP_SUBSCRIPTION_CANCEL_SUCCESS = "follow_up_subscription_cancel_success";
export const FOLLOW_UP_SUBSCRIPTION_CANCEL_FAIL = "follow_up_subscription_cancel_fail";
export const FOLLOW_UP_SUBSCRIPTION_CANCEL_RESET = "follow_up_subscription_cancel_reset";


export const TOGGLE_NAVBAR_VISIBILITY = 'TOGGLE_NAVBAR_VISIBILITY';
export const SET_NAVBAR_VISIBILITY = 'SET_NAVBAR_VISIBILITY';

export const UPDATE_SELECTED_ADD_BLOCKED_ROWS = "update_selected_add_blocked_rows";
export const REMOVE_SELECTED_ADD_BLOCKED_ROWS = "remove_selected_add_blocked_rows";
export const RESET_SELECTED_ADD_BLOCKED_ROWS = "reset_selected_add_blocked_rows";

