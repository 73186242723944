import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { TollFreeUploadContext } from 'context/Context';
import { listTollFreeVerification } from 'actions';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

const TollFreeWizardProvider = ({ children }) => {
  const [upload, setUpload] = useState({
    sampleMessages: [
      { id: 1, text: '[Enter your sample message] Reply STOP to opt out.' }
    ]
  });

  const location = useLocation();
  const { fromTollFreeTable, tollFreePhoneData } = location.state || {};

  useEffect(() => {
    if (fromTollFreeTable && tollFreePhoneData) {
      setUpload({
        ...upload,
        tollFreePhoneNumberSid: tollFreePhoneData?.sid,
        tollFreePhoneNumber: tollFreePhoneData?.phoneNumber
      });
    }
  }, []);

  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listTollFreeVerification());
  }, []);

  const handleInputChange = e => {
    const { name, value, files } = e.target;
    if (files && files.length > 0) {
      // Handle file upload logic here
      const formData = new FormData();
      formData.append(name, files[0]);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/upload-optin-screenshot`,
          formData
        )
        .then(response => {
          setUpload({ ...upload, [name]: response.data.url });
        })
        .catch(error => {
          console.error('Error uploading file:', error);
        });
    } else {
      setUpload({ ...upload, [name]: value });
    }
  };

  const value = {
    upload,
    setUpload,
    step,
    setStep,
    upload,
    setUpload,
    handleInputChange
  };
  return (
    <TollFreeUploadContext.Provider value={value}>
      {children}
    </TollFreeUploadContext.Provider>
  );
};

export default TollFreeWizardProvider;
