import {
  UPDATE_ITEM_REQUEST,
  UPDATE_ITEM_SUCCESS,
  UPDATE_ITEM_FAIL,
  UPDATE_ITEM_RESET
} from '../actions/types';

export default function updateItemReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_ITEM_REQUEST:
      return { loading: true };
    case UPDATE_ITEM_SUCCESS:
      return { loading: false, success: true, updatedItem: action.payload };
    case UPDATE_ITEM_FAIL:
      return { loading: false, error: action.payload };
    case UPDATE_ITEM_RESET:
      return {};
    default:
      return state;
  }
}
