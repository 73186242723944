import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Button, Label, Col, Row, Modal, ModalHeader, ModalBody } from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import ContactEditModal from 'components/contacts/ContactEditModal';
import { ChatContext } from 'context/Context';
import { updateContact, contactById } from 'actions';
import Flex from 'components/common/Flex';
import CustomerForm from 'components/customers/CustomerForm';
import axios from 'axios';
import { chargeUserForCall } from 'actions';
import { Device } from '@twilio/voice-sdk';

const ChatContentHeader = ({ chat, showCall, setShowCall, setContactToCall, callStatus, setCallStatus }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const { fields, loading: fieldsLoading } = useSelector((state) => state.fields);


  const [twilioToken, setTwilioToken] = useState();
  const [newContactInfo, setNewContactInfo] = useState();
  const { selContact, setSelContact } = useContext(ChatContext);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false)
  const [showNoNumberModal, setShowNoNumberModal] = useState(false);
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false);
  const [showEndCallModal, setShowEndCallModal] = useState(false);
  const [device, setDevice] = useState(null);
  const [changeText, setChangeText] = useState();
  const [contact, setContact] = useState({});
  const [tempRowData, setTempRowData] = useState(null); // Add this state
  const [openGroups, setOpenGroups] = useState([])

  const [isBlocking, setIsBlocking] = useState(false);

  const primaryNumber = useSelector((state) => state.primaryNumber);
  const activeChatId = useSelector((state) => state.activeChatId);
  const chats = useSelector((state) => state.chats);

  useEffect(() => {
    const fetchData = async () => {
      if (tempRowData) {
        const contactData = await dispatch(contactById(tempRowData));
        const contactFields = fields.map((field) => ({
          _id: field._id,
          title: field.title,
          value: contactData?.fields?.find(f => f._id === field._id)?.value || "",
        }));

        setContact({ ...contactData, fields: contactFields });
        setShowContactUpdateModal(true);
        setTempRowData(null);
      }
    };

    // Only run fetchData if tempRowData is available and fields are loaded
    if (tempRowData && !fieldsLoading) {
      fetchData();
    }
  }, [tempRowData, fields, fieldsLoading, setTempRowData]);


  useEffect(() => {
    let gs = []
    groups.map((g) => { if (!contact?.group?.filter((cg) => cg.title === g.title)[0]) { openGroups.push(g) } })
    setOpenGroups(gs)
  }, [contact])


  const toggleSave = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal)
    }
    return setChangeText(false),
      setShowContactUpdateModal(!showContactUpdateModal)

  }


  // Function to handle opening the modal with the correct contact
  const openContactUpdateModal = (contactId) => {
    setTempRowData(contactId);
  };


  const closeBtn = (
    <button className="btn-close bg-light" onClick={() => setShowContactModal(!showContactModal)}>
    </button>
  );

  const handleVoiceToken = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate`, {}, { withCredentials: true });
      setTwilioToken(response.data.token);
    } catch (error) {
      console.error('Failed to fetch Twilio token', error);
    }
  };

  const connectTwilioVoiceClient = async () => {
    try {
      const newDevice = new Device(twilioToken, { logLevel: 'debug' });
      setDevice(newDevice);

      newDevice.on('error', (error) => {
        console.error('Twilio.Device error:', error);
        setCallStatus('failed');
        setShowCall(false);
      });

      newDevice.on('tokenWillExpire', handleVoiceToken);

      newDevice.on('tokenExpired', () => {
        console.warn('Twilio.Device token expired');
        handleVoiceToken();
      });

      // Make the outgoing call and get the Call object
      const call = await newDevice.connect({
        params: {
          To: chat?.phone,
          From: primaryNumber[0]?.phoneNumber.slice(1),
        },
      });
      setCallStatus('calling');
      setIsBlocking(true);

      // Set up event listeners on the Call object
      call.on('accept', () => {
        console.log('Call accepted');
        setCallStatus('connected');
        dispatch(chargeUserForCall());
      });

      call.on('disconnect', () => {
        console.log('Call disconnected');
        setContactToCall(null);
        setCallStatus('disconnected');
        setShowCall(false);
        setIsBlocking(false);
        newDevice.destroy();
      });

      call.on('error', (error) => {
        console.error('Call error:', error);
        setCallStatus('failed');
        setShowCall(false);
        setIsBlocking(false);
        newDevice.destroy();
      });
    } catch (error) {
      console.error('Failed to connect Twilio Device', error);
      setCallStatus('failed');
      setShowCall(false);
    }
  };


  useEffect(() => {
    if (!showCall && (callStatus === 'calling' || callStatus === 'connected')) {
      device?.disconnectAll();
    }
  }, [showCall, callStatus, device]);


  useEffect(() => {
    if (callStatus === 'connected') {
      const myInterval = setInterval(() => {
        dispatch(chargeUserForCall());
      }, 60000);
      return () => clearInterval(myInterval);
    }
  }, [callStatus, dispatch]);

  useEffect(() => {
    handleVoiceToken();
  }, []);

  useEffect(() => {
    setSelContact(chat?.contact ? chat?.contact : { phone_number: chat?.phone });
  }, [activeChatId, chats, setSelContact, chat]);

  useEffect(() => {
    if (!showCall) {
      if (device) {
        device.disconnectAll();
        device.destroy();
      }
    }
    return () => {
      if (device) {
        device.disconnectAll();
        device.destroy();
      }
    };
  }, [showCall, device]);

  const formatPhoneNumber = (phoneNumberString) => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  };

  return (
    <div className="chat-content-header">
      <Row className="flex-between-center">
        <Col md={8} xs={7} tag={Flex} align="center">
          <div
            className="pe-3 text-700 d-md-none contacts-list-show cursor-pointer mt-1"
            onClick={() => (document.getElementsByClassName('chat-sidebar')[0].style.left = 0)}
          >
            <FontAwesomeIcon transform="grow-3" icon="chevron-left" />
          </div>
          <div >
            <Label
              tag={Link}
              className="fs-10 fw-light ms-2"
              onClick={() => { if (selContact?._id) { openContactUpdateModal(selContact?._id) } else if (chat?.contact?.firstName === undefined) { (document.getElementsByClassName('chat-sidebar')[0].style.left = 0) } }} color="link">
              {selContact?._id && selContact?.lastName ? selContact?.firstName + " " + selContact?.lastName : selContact?._id ? selContact?.firstName : selContact.phone_number === undefined ? "Create chat" : formatPhoneNumber(selContact?.phone_number)}
            </Label>
            <div className={chat?.contact?.firstname === undefined ? "d-none" : "fs--2 text-400"}></div>
          </div>
        </Col>
        <Col md={4} xs={5} className='d-flex justify-content-end'>
          {(selContact.phone_number && !selContact._id) && (
            <div>
              <Button
                size="sm"
                id="createContact"
                className="me-4 btn-tertiary"
                onClick={() => {
                  setNewContactInfo(selContact.phone_number);
                  setShowContactModal(!showContactModal);
                }}
              >
                <FontAwesomeIcon className="me-2 text-primary" icon="user-plus" />
                Add contact
              </Button>
            </div>
          )}
          {!showCall && (
            <div>
              <Button
                color="falcon-primary"
                size="sm"
                className={chat?.contact?.firstName === undefined ? "d-none" : "me-2"}
                id="call-tooltip"
                onClick={() => {
                  if (primaryNumber.length < 1) {
                    setShowNoNumberModal(!showNoNumberModal);
                  } else {
                    setShowCall(!showCall);
                    setContactToCall(chat.contact);
                    connectTwilioVoiceClient();
                  }
                }}
              >
                <FontAwesomeIcon icon="phone" />
              </Button>
            </div>
          )}
        </Col>

      </Row>

      {/* Create contact modal */}
      <Modal isOpen={showNoNumberModal} centered toggle={() => setShowNoNumberModal(!showNoNumberModal)}>
        <ModalHeader toggle={() => setShowNoNumberModal(!showNoNumberModal)} className="d-flex flex-between-center border-bottom-0">
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label for="title">Make sure you have chat number selected</Label>
              <Button block tag={Link} to="/services/buy-new-numbers" color="primary" className="mb-3">Go to numbers</Button>
              <Button onClick={() => setShowNoNumberModal(!showNoNumberModal)} block className="text-primary" color="light">Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {/* Create show */}
      <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
        <ModalHeader toggle={() => setShowContactModal(!showContactModal)} className="d-flex flex-between-center border-bottom-0" close={closeBtn}>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-2">
              <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} newContactInfo={newContactInfo} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      {/* Create contact modal */}
      <Modal isOpen={showEndCallModal} centered toggle={() => setShowEndCallModal(!showEndCallModal)}>
        <ModalHeader toggle={() => setShowEndCallModal(!showEndCallModal)} className="d-flex flex-between-center border-bottom-0">
          Leaving page will end call
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Button onClick={() => {
                device.disconnectAll();
                setIsBlocking(false);
                setShowEndCallModal(!showEndCallModal);
              }} block className="text-danger" color="light">End call</Button>
              <Button onClick={() => setShowEndCallModal(!showEndCallModal)} block className="text-primary" color="light">Cancel</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={showContactUpdateModal} centered toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}>
        <ModalHeader toggle={toggleSave} className=" border-bottom-0">
          <ButtonIcon
            onClick={() => { return dispatch(updateContact(contact, contact?._id)), setShowContactUpdateModal(!showContactUpdateModal) }}
            disabled={!changeText}
            transform="shrink-3 down-2"
            color="falcon-primary"
            icon=""
            size="md">done</ButtonIcon>
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <ContactEditModal
                contact={contact}
                changeText={changeText}
                setContact={setContact}
                openGroups={openGroups}
                setChangeText={setChangeText} />
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      {
        // Save Modal
      }
      <Modal isOpen={showSaveModal} centered toggle={() => setShowSaveModal(!showSaveModal)}>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Label className="center">Are you sure you want to discard changes?</Label>
              <Button onClick={() => { return setChangeText(false), setShowSaveModal(!showSaveModal), setShowContactUpdateModal(!showContactUpdateModal) }} block className="btn-tertiary border-dark text-danger" >Discard Changes</Button>
              <Button onClick={() => { setShowSaveModal(!showSaveModal) }} block className="btn-tertiary border-dark text-primary mt-2" >Keep editing</Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChatContentHeader;
