import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Col, Button, Media, Card, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { attachMMS } from '../../../actions/index';
import cloudUpload from '../../../assets/img/icons/cloud-upload.svg';

const InsertMMS = ({
  setShowModal,
  setShowFileModal,
  uploadMMS,
  setUploadMMS
}) => {
  const InputFile = useRef(null);
  const [userMMS, setUserMMS] = useState();
  const [highlighted, setHighlighted] = useState(false);
  const [tooLarge, setTooLarge] = useState(false);
  const dispatch = useDispatch();

  const isFirstRender = useRef(true);

  const attachMMSCreate = useSelector(state => state.attachMMSCreate);

  // After attachFileCreate.loading changes, this will run.
  useEffect(() => {
    // On first render, don't do anything
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    // On subsequent renders, close the modal when attachFileCreate.loading is false
    if (!attachMMSCreate.loading) {
      setShowModal(false);
      setShowFileModal(false);
      setUploadMMS(null);
    }
  }, [attachMMSCreate.loading]);

  const convertToBase64 = file => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const manualMMSUpload = async e => {
    e.preventDefault();
    setTooLarge(false);
    if (e.target.files[0].name) {
      if (e.target.files[0].size > 3670000) {
        setTooLarge(true);
      } else {
        const file = e.target.files[0];
        const convertedFile = await convertToBase64(file);
        setUploadMMS({
          image: convertedFile,
          imageName: file.name,
          type: file.type
        });
        setUserMMS(file.name);
      }
    }
  };

  const onButtonClick = e => {
    e.preventDefault();
    InputFile.current.click();
  };

  return (
    <Fragment>
      <h4 className="mb-1 mt-3 text-center"></h4>
      <div className="mb-2">
        <input
          type="file"
          onChange={e => manualMMSUpload(e)}
          accept=".gif, .png, .jpeg"
          ref={InputFile}
          className="d-none"
        />
        <div
          className={`dropzone-area ${highlighted ? ' border-800' : ''}`}
          onClick={e => onButtonClick(e)}
          onDragEnter={() => {
            setHighlighted(true);
          }}
          onDragLeave={() => {
            setHighlighted(false);
          }}
          onDragOver={e => {
            e.preventDefault();
          }}
          onDrop={e => {
            e.preventDefault();
          }}
        >
          <Fragment>
            <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
              <img src={cloudUpload} alt="" width={25} className="mr-2" />
              <Media>
                <p className="fs-0 mb-0 text-700">
                  {userMMS ? userMMS : 'Upload your File'}
                </p>
              </Media>
            </Media>
            <p className="mb-0 w-75 mx-auto text-500">
              Supports: .gif, .png, .jpeg{' '}
            </p>
          </Fragment>
        </div>
      </div>
      <p className="fs-0 text-center">multi-media message</p>
      <Col className="text-center">
        <Button
          disabled={!uploadMMS || tooLarge}
          color="primary"
          onClick={() => {
            if (!tooLarge) {
              return dispatch(attachMMS(uploadMMS));
            }
          }}
          className="my-3 text-white"
        >
          {attachMMSCreate.loading
            ? '...processing'
            : tooLarge
            ? 'File is too large must be under 3.5 MB'
            : 'Attach MMS'}
        </Button>
      </Col>
    </Fragment>
  );
};

export default InsertMMS;
