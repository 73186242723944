import React from 'react';
import App from 'App';
import paths, { rootPaths } from './paths';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import AuthSimpleLayout from '../layouts/AuthSimpleLayout';
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import WizardAuth from 'components/authentication/wizard/WizardAuth';


import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';

import SimpleLogin from 'components/authentication/simple/Login';
import SimpleLogout from 'components/authentication/simple/Logout';
import SimpleRegistration from 'components/authentication/simple/Registration';
import SimpleForgetPassword from 'components/authentication/simple/ForgetPassword';
import SimplePasswordReset from 'components/authentication/simple/PasswordReset';
import SimpleLockScreen from 'components/authentication/simple/LockScreen';

import Dashboard from 'components/dashboard';
import Support from 'components/help-and-support/Support';

// ========================================================================================================================================================================================
import PhoneVerification from 'components/authentication/phone/PhoneNumberVerification'
import PreviewMedia from 'components/compose/PreviewMedia';
import ConfirmLinkContent from 'components/authentication/ConfirmLinkContent';
// ========================================================================================================================================================================================

import AccountSettingsFollowUp from 'components/account-marketing/AccountSettingsFollowUp';

import CsvWizardLayout from 'components/csv-upload/CsvWizardLayout';

import HelpModal from 'components/dashboard/HelpModal';
import MonthlyPlans from 'components/account-marketing/MonthlyPlans';
import ContactEdit from 'components/contacts/ContactEdit';
import GroupEdit from 'components/contacts/GroupEdit';
import Unsubscribers from 'components/contacts/Unsubscribers';
import ContactsComp from 'components/contacts/Contacts';
import HistoryTabs from 'components/history/HistoryTabs';
import TemplateTableV3 from 'components/templates/TemplateTableV3';
import Numbers from 'components/services/Numbers';
import InstaResponders from 'components/insta-responders/InstaResponders';
import ResponderLayout from 'components/responder-upload/ResponderLayout';
import CalendarTabs from 'components/calendar/CalendarTabs';
import Chat from 'components/chat/Chat';
import VirtualNumberSelection from 'components/services/VirtualNumberSelection';
import DLCWizardLayout from 'components/services/DLCWizard/DLCWizardLayout';
import TollFreeWizardLayout from 'components/services/TollFreeWizard/TollFreeWizardLayout';
import PrivateRoute from 'components/authentication/PrivateRoute';
import Billing from 'components/account-marketing/Billing';
import ModalManager from 'components/hoc/ModalManager';


const routes = [
  {
    element: <App />,
    children: [
      {
        path: rootPaths.errorsRoot, //"errors"
        element: <ErrorLayout />,
        children: [
          {
            path: paths.error404,
            element: <Error404 />
          },
          {
            path: paths.error500,
            element: <Error500 />
          }
        ]
      },
      {
        path: "/phone-verification",
        element: <PhoneVerification />
      },
      {
        path: "/media/:imageID",
        element: <PreviewMedia />
      },
      {
        path: "/confirm-mail",
        element: <ConfirmLinkContent />
      },
      {
        path: rootPaths.authRoot, //"authentication"
        children: [
          {
            path: rootPaths.authSimpleRoot,
            element: <AuthSimpleLayout />,
            children: [
              {
                path: paths.simpleLogin,
                element: <SimpleLogin />
              },
              {
                path: paths.simpleRegister,
                element: <SimpleRegistration />
              },
              {
                path: paths.simpleLogout,
                element: <SimpleLogout />
              },
              {
                path: paths.simpleForgotPassword,
                element: <SimpleForgetPassword />
              },
              {
                path: paths.simpleResetPassword,
                element: <SimplePasswordReset />
              },
              {
                path: paths.simpleLockScreen,
                element: <SimpleLockScreen />
              }
            ]
          },
          {
            path: paths.authWizard,
            element: <WizardAuth />
          }
        ]
      },






      // these are all our dashboard routes... these will all be behind a private route protected by auth

      {
        path: '/',
        element:
          <PrivateRoute>
            <ModalManager>
              <MainLayout />
            </ModalManager>
          </PrivateRoute>
        ,
        children: [
          {
            index: true,
            element: <Dashboard />
          },

          {
            path: "/follow-up/account-settings",
            element: <AccountSettingsFollowUp />
          },

          {
            path: "/follow-up/billing",
            element: <Billing />
          },


          {
            path: '/follow-up/support',
            element: <HelpModal />
          },

          /*Follow up credits*/
          {
            path: '/follow-up/monthly-plans',
            element: <MonthlyPlans />
          },

          /*Pages*/
          {
            path: '/follow-up/media/:imageID',
            element: <PreviewMedia />
          },
          {
            path: '/follow-up/contact/:id',
            element: <ContactEdit />
          },
          {
            path: '/follow-up/group/:id',
            element: <GroupEdit />
          },
          {
            path: '/follow-up/unsubscribers/:id',
            element: <Unsubscribers />
          },
          {
            path: '/follow-up/contacts-import',
            element: <CsvWizardLayout />
          },
          {
            path: '/follow-up/contacts',
            element: <ContactsComp />
          },
          {
            path: '/follow-up/history',
            element: <HistoryTabs />
          },

          {
            path: '/follow-up/templates',
            element: <TemplateTableV3 />
          },
          {
            path: '/follow-up/compliance/10dlc',
            element: <DLCWizardLayout />
          },

          {
            path: '/follow-up/compliance/toll-free',
            element: <TollFreeWizardLayout />
          },

          {
            path: '/follow-up/virtual-number',
            element: <VirtualNumberSelection />
          },

          {
            path: '/follow-up/numbers',
            element: <Numbers />
          },

          {
            path: '/vetting/10dlc',
            element: <DLCWizardLayout />
          },

          {
            path: '/follow-up/responders',
            element: <InstaResponders />
          },
          {
            path: '/follow-up/responder-upload',
            element: <ResponderLayout />
          },

          {
            path: '/follow-up/chat',
            element: <Chat />
          },

          {
            path: '/follow-up/campaign',
            element: <CalendarTabs />
          },
          /*Support*/
          {
            path: '/support',
            element: <Support />
          },
        ]
      },
      {
        path: '*',
        element: <Navigate to={paths.error404} replace />
      }
    ]
  }
];

export const router = createBrowserRouter(routes, {
  basename: process.env.PUBLIC_URL
});

export default routes;
