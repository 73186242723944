import React, { useContext } from 'react';
import { Media, NavLink } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '../../common/Avatar';
import Flex from '../../common/Flex';
import ChatSidebarDropdownAction from './ChatSidebarDropdownAction';
import { ChatContext } from '../../../context/Context';
import { createChat } from '../../../actions/chatActions';
import { ADD_CHAT, UPDATE_ACTIVE_CHAT } from '../../../actions/types';
import avatarImage from 'assets/img/team/avatar.png'


const ChatContact = ({
  showMessageModal,
  setShowMessageModal,
  contact,
  chatCreate
}) => {
  //add conversations into this as prop
  const { setSearchTerm, setSelContact, activeChatId } =
    useContext(ChatContext);
  const chats = useSelector(state => state.chats);
  const primaryNumber = useSelector(state => state.primaryNumber);
  const dispatch = useDispatch();

  const handleChatClick = async () => {
    setSelContact(contact);
    setSearchTerm('');
    setShowMessageModal(!showMessageModal);
    document.getElementsByClassName('chat-sidebar')[0].style.left = '-100%';

    const MatchedConvo = chats.find(
      chat => chat?.contact?._id === contact?._id
    );
    if (MatchedConvo) {
      dispatch({ type: UPDATE_ACTIVE_CHAT, payload: MatchedConvo?._id });
    } else {
      const newChatId = await dispatch(createChat(contact, primaryNumber));
      if (newChatId) {
        dispatch({ type: UPDATE_ACTIVE_CHAT, payload: newChatId });
      }
    }
  };

  return (
    <NavLink
      className="chat-contact hover-actions-trigger w-100"
      onClick={handleChatClick}
    >
      <div className="d-md-none d-lg-block">
        <ChatSidebarDropdownAction />
      </div>
      <Flex>
        <Avatar
          src={avatarImage}
          className={contact?.firstName}
          name={contact?.firstName}
          size="xl"
        />
        <Media
          body
          className="flex-1 chat-contact-body ms-2 d-md-none d-lg-block"
        >
          <Flex justifyContent="between">
            <h6 className="mt-2 chat-contact-title">
              {contact?.firstName + ` ${contact?.lastName}`}
            </h6>
            <span className=" mt-2 message-time fs-11">
              {' '}
              {contact?.phone_number}{' '}
            </span>
          </Flex>
        </Media>
      </Flex>
    </NavLink>
  );
};

export default ChatContact;

//{
//  "_id": "62e9b7575e61070c9849630a",
//  "phone": "17209792696",
//  "dateUpdated": "2022-08-02T23:46:31.728Z",
//  "dateCreated": "2022-08-02T23:46:31.728Z",
//  "read": "true",
//  "state": "active",
//  "mute": "false",
//  "contact": {
//      "_id": "62e9af768649500c80e61927",
//      "firstName": "Kevin",
//      "lastName": "Parker",
//      "company": "",
//      "fields": "",
//      "blocked": false,
//      "phone_type": "mobile",
//      "phone_number": "17209792696",
//      "created": "2022-08-02T23:12:54.447Z",
//      "email": ""
//  }
//}
