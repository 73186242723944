import React, { useState, useEffect } from 'react';

const CallTimer = ({ callStatus }) => {
  const [time, setTime] = useState(0);

  function incrementTime() {
    setTime(prevTime => prevTime + 10);
  }

  useEffect(() => {
    if (callStatus === 'connected') {
      const myInterval = setInterval(incrementTime, 10);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [callStatus]);

  return (
    <div>
      <span>{('0' + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
      <span>{('0' + Math.floor((time / 1000) % 60)).slice(-2)}</span>
    </div>
  );
};

export default CallTimer;
