import {
  CONFIRM_SETUP_INTENT_REQUEST,
  CONFIRM_SETUP_INTENT_SUCCESS,
  CONFIRM_SETUP_INTENT_FAIL
} from '../actions/types';

export default function confirmIntentReducer(state = {}, action) {
  switch (action.type) {
    case CONFIRM_SETUP_INTENT_REQUEST:
      return { loading: true };
    case CONFIRM_SETUP_INTENT_SUCCESS:
      return { loading: false, data: action.payload };
    case CONFIRM_SETUP_INTENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
