import { UPDATE_SELECTED_ROWS } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case UPDATE_SELECTED_ROWS: // could have issue with empty string may need to do action.payload || false
      if (!state.length) {
        return action.payload.selectedFlatRows.map(el => {
          return {
            id: el.id,
            original: {
              _id: el.original._id,
              firstName: el.original.firstName,
              lastName: el.original.lastName,
              phone_number: el.original.phone_number
            }
          };
        });
      } else {
        const matched = Object.keys(action.payload.selectedRowIds).map(
          selRow => {
            const foundRow = state.filter(el => {
              return el.id === selRow;
            })[0];
            if (foundRow) {
              return foundRow;
            } else {
              const newRow = action.payload.selectedFlatRows.filter(el => {
                return el.id === selRow;
              })[0];

              return {
                id: newRow.id,
                original: {
                  _id: newRow.original._id,
                  firstName: newRow.original.firstName,
                  lastName: newRow.original.lastName,
                  phone_number: newRow.original.phone_number
                }
              };
            }
          }
        );
        return matched;
      }

    default:
      return state;
  }
}
