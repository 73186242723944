// src/reducers/uiReducer.js
import { TOGGLE_NAVBAR_VISIBILITY, SET_NAVBAR_VISIBILITY } from '../actions/types';

const initialState = {
    isNavbarVisible: true,
    // ... other UI states
};

const uiReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_NAVBAR_VISIBILITY:
            return {
                ...state,
                isNavbarVisible: !state.isNavbarVisible,
            };
        case SET_NAVBAR_VISIBILITY:
            return {
                ...state,
                isNavbarVisible: action.payload,
            };
        // ... other cases
        default:
            return state;
    }
};

export default uiReducer;
