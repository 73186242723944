import axios from 'axios';

import {
  //-------------------------------------------------Bulk send---------------------------------------------------------------------------------------------
  LIST_BULK_SEND,
  BULK_SEND_CREATE_REQUEST,
  BULK_SEND_CREATE_SUCCESS,
  BULK_SEND_CREATE_FAIL,
  LIST_BULK_SEND_MESSAGES,
  LIST_BULK_SEND_DELIVERED_MESSAGES_REQUEST,
  LIST_BULK_SEND_DELIVERED_MESSAGES_SUCCESS,
  LIST_BULK_SEND_SENT_MESSAGES,
  LIST_BULK_SEND_FAILED_MESSAGES_REQUEST,
  LIST_BULK_SEND_FAILED_MESSAGES_SUCCESS,
  DELETE_BULK_SEND_REQUEST,
  DELETE_BULK_SEND_SUCCESS,
  DELETE_BULK_SEND_FAIL
} from './types';

export const listBulkSend = () => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/bulk-send`,
      method: 'GET',
      withCredentials: true
    }).then(res => dispatch({ type: LIST_BULK_SEND, payload: res.data }));
  };
};

export const createBulkSendMessage = newMessage => async dispatch => {
  console.log(newMessage);
  try {
    dispatch({ type: BULK_SEND_CREATE_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/bulk-send`,
      method: 'POST',
      data: { newMessage },
      withCredentials: true
    }).then(res =>
      dispatch({ type: BULK_SEND_CREATE_SUCCESS, payload: res.data })
    );
  } catch (error) {
    dispatch({
      type: BULK_SEND_CREATE_FAIL,
      payload:
        error.message && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};

export const listBulkSendMessages = bulkDetails => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/bulk-send-messages`,
      method: 'POST',
      data: bulkDetails,
      withCredentials: true
    }).then(res =>
      dispatch({ type: LIST_BULK_SEND_MESSAGES, payload: res.data })
    );
  };
};

export const listBulkSendDeliveredMessages = (options = {}) => {
  return async function (dispatch) {
    const {
      search = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
      bulkDetails
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/bulk-send-delivered?`;

    // Append query parameters
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_BULK_SEND_DELIVERED_MESSAGES_REQUEST });

    const res = await axios({
      url: url,
      method: 'POST',
      data: bulkDetails,
      withCredentials: true,
    });

    dispatch({ type: LIST_BULK_SEND_DELIVERED_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};

export const listBulkSendSentMessages = bulkDetails => {
  return async function (dispatch) {
    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/bulk-send-sent`,
      method: 'POST',
      data: bulkDetails,
      withCredentials: true
    }).then(res =>
      dispatch({ type: LIST_BULK_SEND_SENT_MESSAGES, payload: res.data })
    );
  };
};


export const listBulkSendFailedMessages = (options = {}) => {
  return async function (dispatch) {
    const {
      search = '',
      page = 1,
      limit = 25,
      sortBy = 'dateSent',
      sortOrder = -1,
      bulkDetails
    } = options;

    let url = `${process.env.REACT_APP_API_URL}/api/bulk-send-failed?`;

    // Append query parameters
    if (search && search.trim() !== '') {
      url += `search=${encodeURIComponent(search)}&`;
    }

    // Append 'page' and 'limit' to the URL
    url += `page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`;

    dispatch({ type: LIST_BULK_SEND_FAILED_MESSAGES_REQUEST });

    const res = await axios({
      url: url,
      method: 'POST',
      data: bulkDetails,
      withCredentials: true,
    });

    dispatch({ type: LIST_BULK_SEND_FAILED_MESSAGES_SUCCESS, payload: res.data });

    return res.data;
  };
};

export const deleteBulkSend = (isSelected, _id) => async dispatch => {
  try {
    dispatch({ type: DELETE_BULK_SEND_REQUEST });

    await axios({
      url: `${process.env.REACT_APP_API_URL}/api/bulk-send-delete`,
      method: 'POST',
      data: { isSelected, _id },
      withCredentials: true
    }).then(res => {
      dispatch({ type: DELETE_BULK_SEND_SUCCESS });
    });
  } catch (error) {
    dispatch({
      type: DELETE_BULK_SEND_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
    });
  }
};
