import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, Card, CardBody, Modal, ModalBody, ModalHeader, Label } from 'reactstrap';
import CustomerForm from 'components/customers/CustomerForm';


const SingleOrListForm = ({ fromCompose }) => {

  // State
  const [showContactModal, setShowContactModal] = useState(false);

  // Handler

  const toggle = () => { setShowContactModal(!showContactModal) }

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}>
    </button>
  );

  return (
    <Fragment>
    <div className="d-flex justify-content-center">
      <ButtonGroup className="btn-group-toggle btn-block">
        <Button onClick={() => { setShowContactModal(!showContactModal) }} className="btn-tertiary border-dark">
          <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="user-plus" className="mt-3 fs-4" />
            <h6 className="mt-2">Create a new contact</h6>
          </Label>
        </Button>

        <Button tag={Link} to="/follow-up/contacts-import" className="btn-tertiary border-dark">
          <Label className="cursor-pointer hover-overlay">
            <FontAwesomeIcon icon="file-alt" className="fs-4 mt-3" />
            <h6 className="mt-2" >Import from CSV</h6>
          </Label>
        </Button>
      </ButtonGroup>

    </div>

        <Modal isOpen={showContactModal} centered toggle={() => setShowContactModal(!showContactModal)}>
          <ModalHeader toggle={toggle} className="d-flex flex-between-center border-bottom-0" close={closeBtn}>Add New Contact</ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className=" p-2">
                <CustomerForm showContactModal={showContactModal} setShowContactModal={setShowContactModal} fromCompose={fromCompose} />
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
    </Fragment>

  );
};

SingleOrListForm.propTypes = {

  hasLabel: PropTypes.bool
};

SingleOrListForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default SingleOrListForm;