import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { router } from 'routes';
import 'helpers/initFA';
import { Provider } from 'react-redux';
// eslint-disable-next-line no-unused-vars
import { configureStore } from '@reduxjs/toolkit'; // keep defaultMiddleware even though its not being used. we need it
import reducers from 'reducers';
import { thunk as reduxThunk } from 'redux-thunk';
import { fetchUser } from 'actions';

// Create store with configureStore
const store = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable immutableCheck
      serializableCheck: false // Optional: Disable serializableCheck if needed
    }).concat(reduxThunk)
});
const container = document.getElementById('main');
const root = createRoot(container);

// Fetch the user when the app initializes
store.dispatch(fetchUser());

// remove strictMode when deploying
root.render(
  <Provider store={store}>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </Provider>
);
