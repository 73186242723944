import {
  DLC_CAMPAIGN_UPDATE_REQUEST,
  DLC_CAMPAIGN_UPDATE_SUCCESS,
  DLC_CAMPAIGN_UPDATE_FAIL,
  DLC_CAMPAIGN_UPDATE_RESET
} from '../actions/types';

export default function DLCCampaignUpdateReducer(state = {}, action) {
  switch (action.type) {
    case DLC_CAMPAIGN_UPDATE_REQUEST:
      return { loading: true };
    case DLC_CAMPAIGN_UPDATE_SUCCESS:
      return { loading: false, success: true };
    case DLC_CAMPAIGN_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
}
