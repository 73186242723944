import {
  UPDATE_ESTIMATE_ACTION,
  ESTIMATE_ACTION_RESET
} from '../actions/types';

export default function estimateActionReducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_ESTIMATE_ACTION:
      return action.payload;
    case ESTIMATE_ACTION_RESET:
      return {};
    default:
      return state;
  }
}
