import {
    UPDATE_PASSWORD_REQUEST,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_RESET
  } from '../actions/types';
  
  export default function passwordResetReducer(state = {}, action) {
    switch (action.type) {
      case UPDATE_PASSWORD_REQUEST:
        return { loading: true };
      case UPDATE_PASSWORD_SUCCESS:
        return { loading: false, success: true };
      case UPDATE_PASSWORD_FAIL:
        return { loading: false, error: action.payload };
      case UPDATE_PASSWORD_RESET:
        return { };
      default:
        return state;
    }
  }
  