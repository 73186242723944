import React, {
  createRef,
  useEffect,
  useState,
  useMemo,
  useCallback
} from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap';
import { Badge } from 'reactstrap';
import Divider from '../common/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import {
  convertFromRaw,
  convertToRaw,
  ContentState,
  EditorState
} from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin, { defaultTheme } from '@draft-js-plugins/emoji';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import editorStyles from '../compose/SimpleMentionEditor.module.scss';
import mentionsStyles from '../compose/MentionsStyles.module.scss';
import {
  createTemplate,
  listFields,
  updateTemplate
} from '../../actions/index';

const TemplateCreator = ({
  hasLabel,
  showTemplateModal,
  setShowTemplateModal,
  showEditTemplateModal,
  setShowEditTemplateModal,
  temporaryId,
  fromHistory
}) => {
  const dispatch = useDispatch();
  const messageFieldRef = createRef();
  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(fields);
  const [messageLength, setMessageLength] = useState('');
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createEmpty();
  }); // check bookmark for updating html npm install html-to-draftjs
  const [template, setTemplate] = useState({
    title: temporaryId?.title ? temporaryId?.title : '',
    body: temporaryId?.body ? temporaryId?.body : '',
    error: '',
    open: false
  });

  const handleSubmit = e => {
    e.preventDefault();
    if (fromHistory) {
      dispatch(createTemplate(template));
      setShowTemplateModal(!showTemplateModal);
    } else if (temporaryId) {
      dispatch(updateTemplate(template, temporaryId._id));
      setShowEditTemplateModal(!showEditTemplateModal);
    } else {
      dispatch(createTemplate(template));
      setShowEditTemplateModal(!showEditTemplateModal);
    }
  };

  const handleChange = e => {
    setTemplate({ ...template, [e.target.name]: e.target.value });
  };

  const contentState = editorState.getCurrentContent();
  const len = contentState.getPlainText(' '); // using this to get content length
  const rawContentState = convertToRaw(editorState.getCurrentContent()); // Setting template body as this in DB

  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } =
    useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        theme: mentionsStyles,
        //mentionPrefix: '@',
        supportWhitespace: true
      });
      const emojiPlugin = createEmojiPlugin({
        selectButtonContent: (
          <FontAwesomeIcon icon={['far', 'laugh-beam']} transform="shrink-4" />
        ),
        theme: defaultTheme,
        useNativeArt: true
      });

      const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin, emojiPlugin];
      return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
    }, []);

  useEffect(() => {
    let contentState;
    if (temporaryId && temporaryId?.body?.entityMap) {
      setEditorState(
        EditorState.createWithContent(convertFromRaw(temporaryId?.body))
      );
    } else if (fromHistory) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromText(temporaryId?.body)
        )
      );
    } else if (temporaryId && !temporaryId?.body?.entityMap) {
      contentState = {
        entityMap: {},
        blocks: temporaryId.body.blocks
      };
      setEditorState(
        EditorState.createWithContent(convertFromRaw(contentState))
      );
    }
  }, []);

  function Entry(props) {
    const { mention, theme, searchValue, isFocused, ...parentProps } = props;

    return (
      <div {...parentProps}>
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerLeft}>
            <img
              src={mention.avatar}
              className={theme?.mentionSuggestionsEntryAvatar}
              role="presentation"
            />
          </div>

          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div className={theme?.mentionSuggestionsEntryText}>
              {mention.name}
            </div>

            <div className={theme?.mentionSuggestionsEntryTitle}>
              {mention.title}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onOpenChange = useCallback(_open => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(({ value }) => {}, []);

  useEffect(() => {
    dispatch(listFields());
  }, []);

  useEffect(() => {
    const OurFields = fields.map(el => {
      return { name: `{${el.title}}`, id: el._id };
    });
    setSuggestions([
      { name: '{First name}', id: '700' },
      { name: '{Last name}', id: '701' },
      { name: '{Company name}', id: '702' },
      { name: '{Phone}', id: '703' },
      { name: '{Email}', id: '704' },
      ...OurFields
    ]);
  }, [fields]);

  useEffect(() => {
    // <-------- setting message length
    if (len) {
      setMessageLength(len.length);
    } else {
      setMessageLength(0);
    }

    setTemplate({ ...template, body: rawContentState });
  }, [editorState]);

  return (
    <Card>
      <CardBody className="fs--1 font-weight-normal p-4">
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>Template Name</Label>
            {temporaryId ? (
              <Input
                placeholder={!hasLabel ? 'Name your Template' : ''}
                required={true}
                name="title" // name is being used to label data being passed
                defaultValue={temporaryId.title}
                value={template.title.value}
                onChange={handleChange}
                type="input"
              />
            ) : (
              <Input
                placeholder={!hasLabel ? 'Name your Template' : ''}
                required={true}
                name="title" // name is being used to label data being passed
                value={template?.title?.value}
                onChange={handleChange}
                type="input"
              />
            )}
          </FormGroup>
          <div>
            <FormGroup>
              <Label>Message</Label>
              <div
                style={{
                  minHeight: '7em',
                  maxHeight: '10em',
                  overflow: 'auto'
                }}
                className={`border border-2x border-300 bg-light rounded-soft fs--1 ${editorStyles.editor}`}
                onClick={() => {
                  messageFieldRef.current.focus();
                }}
              >
                <Editor
                  editorKey={'editor'}
                  currentContent={ContentState}
                  editorState={editorState}
                  onChange={setEditorState}
                  plugins={plugins}
                  ref={messageFieldRef}
                />
                <EmojiSuggestions />
                <MentionSuggestions
                  open={open}
                  onOpenChange={onOpenChange}
                  suggestions={suggestions}
                  onSearchChange={onSearchChange}
                  onAddMention={e => {
                    // get the mention object selected
                  }}
                  entryComponent={Entry}
                />
              </div>
              <div>
                <EmojiSelect closeOnEmojiSelect />
                <span
                  color="light"
                  className="px-3 py-1 bg-soft-info rounded-capsule shadow-none fs--1 ml-3"
                >
                  <FontAwesomeIcon icon="tags" transform="left-3" />
                  Type <strong>@</strong> for custom fields
                </span>
              </div>

              <p>
                To add image or file, Attach it to your message when creating a
                new campaign or sending bulkText.
              </p>
              <p>This ensures better accuracy when sending files or photos.</p>
            </FormGroup>
            <Col className="mt-2">
              <Badge
                color="soft-success"
                pill
                className="fs--1"
              >{`Characters: ${messageLength}/918`}</Badge>
              <Badge
                color="soft-info"
                pill
                className="fs--1 ml-2"
              >{`Parts ${Math.ceil(messageLength / 153)}/6`}</Badge>
            </Col>
          </div>
          <Divider className="mt-4">save this template</Divider>
          <FormGroup>
            <Button
              disabled={messageLength < 1 || !template.title}
              color="primary"
              onClick={e => {
                return handleSubmit(e);
              }}
              block
              className="mt-3"
            >
              Save
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default TemplateCreator;
