import React, { Fragment, useState, useEffect } from 'react';
import { Row, Col, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { listPaySettings } from 'actions/crmActions';
import { useDispatch, useSelector } from 'react-redux';
import NavbarVertical from './navbar/NavbarVertical';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import SettingsProfile from './SettingsProfile';
import { toast } from 'react-toastify';
import { PAY_SETTINGS_UPDATE_RESET } from 'actions/types';
//import AccountDelete from 'components/accountCRM/AccountDelete';




const AccountSettingsFollowUp = () => {

    const dispatch = useDispatch()
    const paySettingsUpdate = useSelector((state) => state.paySettingsUpdate)
    const { success: successUpdate } = paySettingsUpdate
    const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(false)

    const windowWidth = useWindowDimensions()

    useEffect(() => {
        if (successUpdate) {
            toast(
                <Fragment>
                    <strong>Settings Updated</strong>
                </Fragment>,
                {
                    autoCLose: 1000
                }
            );
            dispatch({ type: PAY_SETTINGS_UPDATE_RESET })
        }
    }, [successUpdate]);



    return (
        <Fragment>
            <Row>
                {windowWidth.width >= 992 && (
                    <Col lg={2}>
                        <NavbarVertical isNavbarVerticalCollapsed={isNavbarVerticalCollapsed} setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed} />
                    </Col>
                )}
                <Col lg={10}>
                    <Row className="my-2 me-2 d-flex justify-content-between">
                        {windowWidth.width <= 992 ? (
                            <div>
                                <UncontrolledDropdown>
                                    <DropdownToggle size="md" className="fw-bold btn-reveal btn">{`Settings `}
                                        <FontAwesomeIcon icon="chevron-down" className="fs-9" />
                                    </DropdownToggle>
                                    <DropdownMenu className="border py-2 me-6 me-xxl-8">
                                        <DropdownItem tag={Link} to="/follow-up/account-settings" className="fw-bold py-2">Settings</DropdownItem>
                                        <DropdownItem tag={Link} to="/follow-up/billing" className="fw-bold py-2">Billing</DropdownItem>
                                        <DropdownItem tag={Link} to="/follow-up/monthly-plans" className="fw-bold py-2" >Monthly plans</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        ) : (
                            <div>
                                <h2 className="d-flex justify-content-start" >Settings</h2>
                            </div>
                        )}
                    </Row>
                    <Row>
                        <Col className="mb-4">
                            <SettingsProfile />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Fragment>

    );
};

export default AccountSettingsFollowUp;