import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardBody,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import CampaignPreview from './CampaignPreview';
import Divider from 'components/common/Divider';
import Flex from 'components/common/Flex';
import moment from 'moment';
import momentt from 'moment-timezone';
import { calendarDelete, calendarUpdate } from 'actions/index';
import { ADD_EVENT } from 'actions/types';

const getCircleStackIcon = (icon, transform) => (
  <span className="fa-stack ml-n1 mr-3">
    <FontAwesomeIcon icon="circle" className="text-200 fa-stack-2x" />
    <FontAwesomeIcon
      icon={icon}
      transform={transform ?? ''}
      className="text-primary fa-stack-1x"
      inverse
    />
  </span>
);

const EventModalMediaContent = ({ icon, heading, content, children }) => (
  <Media className="mt-3">
    {getCircleStackIcon(icon)}
    <Media body>
      <>
        <h6>{heading}</h6>
        {children || <p className="mb-0 text-justify">{content}</p>}
      </>
    </Media>
  </Media>
);

const PauseEventModal = ({
  eventId,
  setEventId,
  showDetailModal,
  setShowDetailModal,
  isOpenScheduleModal,
  setIsOpenScheduleModal,
  modalEventContent,
  fields
}) => {
  const toggle = () => setShowDetailModal(!showDetailModal);
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);

  const {
    title,
    description,
    end,
    start,
    endDate,
    startDate,
    timezone,
    repeatCampaign,
    repeatCampaignHourCycles,
    repeatCampaignEnd,
    repeatCampaignEndSessions,
    repeatCampaignDayTimes,
    repeatCampaignDayCount,
    repeatCampaignWeekCount,
    repeatCampaignWeekdays,
    repeatCampaignWeekdayTimes,
    repeatCampaignType,
    repeatCampaignMonthOnThe,
    repeatCampaignMonthOnDay,
    repeatCampaignMonthTimes,
    organizer,
    messageDetails,
    messageContents,
    cost,
    contactLength,
    _id
  } = showDetailModal && modalEventContent;

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}></button>
  );

  return (
    <Modal
      isOpen={showDetailModal}
      toggle={toggle}
      modalClassName="theme-modal"
      contentClassName="border"
      centered
    >
      <ModalHeader
        toggle={toggle}
        tag="div"
        className="px-card border-0 flex-between-center"
        close={closeBtn}
      >
        <h5 className="mb-0">{title}</h5>
        {organizer && (
          <p className="mb-0 fs--1 mt-1">
            by <a href="#!">{organizer}</a>
          </p>
        )}
      </ModalHeader>
      <ModalBody className="px-card pb-card pt-1 fs--1">
        {
          startDate &&
          (() => {
            const ttDate = new Date(startDate); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
            const toTz = momentt
              .tz(ttDate.toISOString().replace('Z', ''), timezone)
              .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
            const applyOffset = ttDate.setTime(
              ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000
            ); //1667397600000
            const ttISO = new Date(applyOffset)
              .toISOString()
              .replace('Z', ''); //2022-11-02T14:00:00.000

            return (
              <EventModalMediaContent
                icon="calendar-check"
                heading="Start Date"
              >
                <span>{moment(ttISO).format('LLLL')}</span>
              </EventModalMediaContent>
            );
          })() // without these () it does not render....
        }
        {
          endDate &&
          repeatCampaignEnd !== 'Never' &&
          (() => {
            const ttDate = new Date(endDate); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
            const toTz = momentt
              .tz(ttDate.toISOString().replace('Z', ''), timezone)
              .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
            const applyOffset = ttDate.setTime(
              ttDate.getTime() + moment.parseZone(toTz).utcOffset() * 60_000
            ); //1667397600000
            const ttISO = new Date(applyOffset)
              .toISOString()
              .replace('Z', ''); //2022-11-02T14:00:00.000

            return (
              <EventModalMediaContent
                icon="calendar-check"
                heading="End date"
              >
                <span>{moment(ttISO).format('LLLL')}</span>
              </EventModalMediaContent>
            );
          })() // without these () it does not render....
        }

        {repeatCampaign !== 'noRepeat' &&
          !endDate &&
          repeatCampaignEnd === 'Never' && (
            <EventModalMediaContent icon="calendar-check" heading="End Date">
              <span>Never end</span>
            </EventModalMediaContent>
          )}
        {repeatCampaignEnd === 'After' && (
          <EventModalMediaContent icon="calendar-check" heading="End Date">
            <span>
              Campaign will stop after {repeatCampaignEndSessions} sessions
            </span>
          </EventModalMediaContent>
        )}
        {timezone && (
          <EventModalMediaContent icon="calendar-check" heading="Timezone">
            <span>{timezone}</span>
          </EventModalMediaContent>
        )}

        {description && (
          <EventModalMediaContent
            icon="align-left"
            heading="Description"
            content={description}
          />
        )}
        {(end || start) && (
          <EventModalMediaContent
            icon="calendar-check"
            heading="Start Date and Time"
          >
            <span>{moment(start).format('LLLL')}</span>
            {end && (
              <>
                {' '}
                – <br /> <span>{moment(end).format('LLLL')}</span>
              </>
            )}
          </EventModalMediaContent>
        )}

        {repeatCampaign && (
          <EventModalMediaContent icon="redo" heading="Repeat Campaign">
            <span>{repeatCampaign}</span>

            {
              // HOUR VIEW
              repeatCampaignHourCycles && repeatCampaign === 'Hourly' && (
                <div>
                  {' '}
                  {repeatCampaignHourCycles.map(el => {
                    const ttDate = new Date(el.trigger_time); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                    const toTz = momentt
                      .tz(el.trigger_time.replace('Z', ''), timezone)
                      .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                    const applyOffset = ttDate.setTime(
                      ttDate.getTime() +
                      moment.parseZone(toTz).utcOffset() * 60_000
                    ); //1667397600000
                    const ttISO = new Date(applyOffset)
                      .toISOString()
                      .replace('Z', ''); //2022-11-02T14:00:00.000
                    return (
                      <div>
                        <span>{moment(ttISO).format('h: mm a')}</span>
                      </div>
                    );
                  })}
                </div>
              )
            }

            {
              // DAY VIEW
              repeatCampaignDayTimes && repeatCampaign === 'Daily' && (
                <div>
                  <span>
                    Repeat every{' '}
                    {repeatCampaignDayCount > 1
                      ? repeatCampaignDayCount + ' days'
                      : 'day'}
                  </span>{' '}
                  {repeatCampaignDayTimes.map(el => {
                    const ttDate = new Date(el.trigger_time); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                    const toTz = momentt
                      .tz(el.trigger_time.replace('Z', ''), timezone)
                      .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                    const applyOffset = ttDate.setTime(
                      ttDate.getTime() +
                      moment.parseZone(toTz).utcOffset() * 60_000
                    ); //1667397600000
                    const ttISO = new Date(applyOffset)
                      .toISOString()
                      .replace('Z', ''); //2022-11-02T14:00:00.000
                    return (
                      <div>
                        <span>{moment(ttISO).format('h: mm a')}</span>
                      </div>
                    );
                  })}
                </div>
              )
            }

            {
              // WEEK VIEW
              repeatCampaignWeekdays && repeatCampaign === 'Weekly' && (
                <div>
                  <span>
                    Repeat every{' '}
                    {repeatCampaignWeekCount > 1
                      ? repeatCampaignWeekCount + ' weeks'
                      : 'week'}
                  </span>{' '}
                  {Object.entries(repeatCampaignWeekdays).map(
                    ([key, value]) => {
                      if (value === true) {
                        return (
                          <div>
                            <Divider>
                              <h6>
                                {key.replace(/(^\w|\s\w)/g, m =>
                                  m.toUpperCase()
                                )}
                              </h6>
                            </Divider>
                            {repeatCampaignWeekdayTimes[key]?.map(el => {
                              // el.trigger_time 2022-11-02T20:00:00Z --- from server in UTC
                              const ttDate = new Date(el.trigger_time); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                              const toTz = momentt
                                .tz(el.trigger_time.replace('Z', ''), timezone)
                                .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                              const applyOffset = ttDate.setTime(
                                ttDate.getTime() +
                                moment.parseZone(toTz).utcOffset() * 60_000
                              ); //1667397600000
                              const ttISO = new Date(applyOffset)
                                .toISOString()
                                .replace('Z', ''); //2022-11-02T14:00:00.000
                              return (
                                <div>
                                  <span className="ml-3">
                                    {moment(ttISO).format('h: mm a')}
                                  </span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              )
            }

            {
              // MONTH VIEW
              repeatCampaign === 'Monthly' && (
                <div>
                  {repeatCampaignType === 'onDay' ? (
                    <div>
                      <span>
                        Repeat on the {repeatCampaignMonthOnDay} of the month
                      </span>
                    </div>
                  ) : repeatCampaignType === 'onThe' ? (
                    <div>
                      <span>
                        Repeat on the{' '}
                        {repeatCampaignMonthOnThe?.first_part +
                          ' ' +
                          repeatCampaignMonthOnThe?.day}{' '}
                        of the month
                      </span>
                    </div>
                  ) : (
                    <div></div>
                  )}{' '}
                  {repeatCampaignMonthTimes.map(el => {
                    const ttDate = new Date(el.trigger_time); //Wed Nov 02 2022 14:00:00 GMT-0600 (Mountain Daylight Time)
                    const toTz = momentt
                      .tz(el.trigger_time.replace('Z', ''), timezone)
                      .format(); //2022-11-02T20:00:00-06:00 if denver or 2022-11-02T20:00:00-04:00 if new york
                    const applyOffset = ttDate.setTime(
                      ttDate.getTime() +
                      moment.parseZone(toTz).utcOffset() * 60_000
                    ); //1667397600000
                    const ttISO = new Date(applyOffset)
                      .toISOString()
                      .replace('Z', ''); //2022-11-02T14:00:00.000
                    return (
                      <div>
                        <span>{moment(ttISO).format('h: mm a')}</span>
                      </div>
                    );
                  })}
                </div>
              )
            }
          </EventModalMediaContent>
        )}
        <CampaignPreview
          messageDetails={messageDetails}
          messageContents={messageContents}
          contactLength={contactLength}
          cost={cost}
        />
      </ModalBody>
      <ModalFooter tag={Flex} justify="end" className="px-card border-top-0">
        <Button
          onClick={() => {
            return (
              dispatch({
                type: ADD_EVENT,
                payload: { event: modalEventContent }
              }),
              setEventId(_id),
              setIsOpenScheduleModal(!isOpenScheduleModal)
            );
          }}
          color="falcon-default"
          size="sm"
        >
          <FontAwesomeIcon icon="pencil-alt" className="fs--2 me-2" />
          <span>Edit</span>
        </Button>
        <Button
          onClick={() => {
            setShowResumeModal(!showResumeModal);
          }}
          color="falcon-success"
          size="sm"
        >
          <FontAwesomeIcon icon="play" className="fs--2 me-2" />
          <span>Resume</span>
        </Button>
        <Modal
          isOpen={showResumeModal}
          centered
          toggle={() => setShowResumeModal(!showResumeModal)}
        >
          <ModalHeader className="d-flex justify-content-center">
            Are you sure?
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Label className="d-flex justify-content-center">
                  This campaign will added back into your calendar
                </Label>
                <Divider></Divider>
                <Button
                  onClick={() => {
                    return (
                      dispatch(
                        calendarUpdate({ _id: _id, isActive: 'active' })
                      ),
                      setShowResumeModal(!showResumeModal),
                      setShowDetailModal(!showDetailModal)
                    );
                  }}
                  block
                  className="btn-tertiary border-dark mt-2"
                >
                  Resume Scheduled Campaign
                </Button>
                <Button
                  onClick={() => {
                    setShowResumeModal(!showResumeModal);
                  }}
                  block
                  className="btn-tertiary border-dark text-primary mt-2"
                >
                  Cancel
                </Button>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
        <Button
          onClick={() => {
            setShowDeleteModal(!showDeleteModal);
          }}
          color="falcon-danger"
          size="sm"
        >
          <FontAwesomeIcon icon="ban" className="fs--2 me-2" />
          <span>Delete</span>
        </Button>
        <Modal
          isOpen={showDeleteModal}
          centered
          toggle={() => setShowDeleteModal(!showDeleteModal)}
        >
          <ModalHeader className="d-flex justify-content-center">
            Are you sure?
          </ModalHeader>
          <ModalBody className="p-0">
            <Card>
              <CardBody className="fs--1 font-weight-normal p-4">
                <Label className="text-center">
                  This campaign will be permenantly deleted and taken off
                  schedule
                </Label>
                <Divider></Divider>
                <Button
                  onClick={() => {
                    return (
                      dispatch(calendarDelete(_id)),
                      setShowDeleteModal(!showDeleteModal),
                      setShowDetailModal(!showDetailModal)
                    );
                  }}
                  block
                  className="btn-tertiary border-dark text-danger"
                >
                  Delete Campaign
                </Button>
                <Button
                  onClick={() => {
                    setShowDeleteModal(!showDeleteModal);
                  }}
                  block
                  className="btn-tertiary border-dark text-primary mt-2"
                >
                  Cancel
                </Button>
              </CardBody>
            </Card>
          </ModalBody>
        </Modal>
      </ModalFooter>
    </Modal>
  );
};

export default PauseEventModal;
