import {
  CREATE_RECEIPT_UPLOAD_REQUEST,
  CREATE_RECEIPT_UPLOAD_SUCCESS,
  CREATE_RECEIPT_UPLOAD_FAIL,
  CREATE_RECEIPT_UPLOAD_RESET
} from '../actions/types';

export default function receiptUploadReducer(state = {}, action) {
  switch (action.type) {
    case CREATE_RECEIPT_UPLOAD_REQUEST:
      return { loading: true };
    case CREATE_RECEIPT_UPLOAD_SUCCESS:
      return { loading: false, success: true, pdfUrl: action.payload?.url };
    case CREATE_RECEIPT_UPLOAD_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_RECEIPT_UPLOAD_RESET:
      return {};
    default:
      return state;
  }
}
