import React, { useEffect, Fragment, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap';
import Divider from '../common/Divider';
import Avatar from '../common/Avatar';
import ButtonIcon from '../common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import ContactEditModal from './ContactEditModal';
import { getPageName } from '../../helpers/utils';
import {
  contactById,
  updateContact,
  deleteContact,
  unBlockContacts,
  listFields,
  listGroups
} from '../../actions/index';
import { ADD_COMPOSE_CONTACT } from '../../actions/types';

const ContactEdit = ({ sendingSelContactId }) => {
  const { id } = useParams(); // Use useParams hook to get the ID from the URL
  const isChat = getPageName('chat');
  const ContactId = sendingSelContactId || id;

  const navigate = useNavigate();

  const fields = useSelector(state => state.fields);
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const contactDetails = useSelector(state => state.contactDetails);
  const groupCreate = useSelector(state => state.groupCreate);
  const { success: successCreate } = groupCreate;

  const contactUpdate = useSelector(state => state.updateContact);
  const { success: successUpdate } = contactUpdate;

  const blockedContactDelete = useSelector(state => state.blockedContactDelete);
  const { success: successBlockedDelete } = blockedContactDelete;

  const contactGroups = contactDetails?.contact?.data?.group;
  const [changeText, setChangeText] = useState(false);
  const [showContactUpdateModal, setShowContactUpdateModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [contact, setContact] = useState({});

  const dispatch = useDispatch();

  const toggle = () => {
    if (changeText === true) {
      return setShowSaveModal(!showSaveModal);
    }
    return (
      setChangeText(false), setShowContactUpdateModal(!showContactUpdateModal)
    );
  };

  const formatPhoneNumber = phoneNumberString => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  };

  useEffect(() => {
    dispatch(listFields());
    dispatch(contactById(ContactId));
  }, [successUpdate, successBlockedDelete]); // had successGet as dependency

  useEffect(() => {
    dispatch(listGroups());
  }, [successCreate]);

  const contactFields = fields.map(field => {
    return {
      title: field.title,
      body: contactDetails?.contact?.data?.fields[field.title]
    };
  });

  const openGroups = [];
  groups.map(g => {
    if (
      !contactDetails?.contact?.data?.group.filter(
        cg => cg.title === g.title
      )[0]
    ) {
      openGroups.push(g);
    }
  });

  return (
    <Card className="mb-3">
      <CardBody className="bg-light border-top sticky-top">
        <div>
          <Row className="justify-content-center mt-2">
            <Avatar name={contactDetails?.contact?.data?.firstName} />
          </Row>
          <Row className="justify-content-center mt-2">
            <h2 className="mt-1 ml-3 fs-1">
              {contactDetails?.contact?.data?.firstName}
            </h2>
            <h2 className="mt-1 ml-1 fs-1">
              {contactDetails?.contact?.data?.lastName}
            </h2>
          </Row>
        </div>
        <Fragment>
          <div className="d-flex justify-content-end">
            <ButtonGroup>
              {!isChat && (
                <ButtonIcon
                  icon="chevron-left"
                  onClick={() => navigate(-1)}
                  transform="shrink-3 down-2"
                  color="falcon-primary"
                  size="sm"
                >
                  Go Back
                </ButtonIcon>
              )}
              {contactDetails?.contact?.data?.blocked === false ? (
                <div>
                  <ButtonIcon
                    icon="sms"
                    tag={Link}
                    onClick={() => {
                      return dispatch({
                        type: ADD_COMPOSE_CONTACT,
                        payload: {
                          contactCompose: {
                            firstName: contactDetails?.contact?.data?.firstName,
                            lastName: contactDetails?.contact?.data?.lastName,
                            _id: contactDetails?.contact?.data?._id,
                            phoneNumber:
                              contactDetails?.contact?.data?.phone_number
                          }
                        }
                      });
                    }}
                    to={'/'}
                    transform="shrink-3 down-2"
                    color="falcon-info"
                    size="sm"
                  >
                    Send SMS
                  </ButtonIcon>
                </div>
              ) : (
                <div>
                  <ButtonIcon
                    icon="ban"
                    onClick={() => {
                      setShowUnblockModal(!showUnblockModal);
                    }}
                    transform="shrink-3 down-2"
                    color="falcon-success"
                    size="sm"
                  >
                    Unblock
                  </ButtonIcon>
                </div>
              )}
              <ButtonIcon
                icon="trash"
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal);
                }}
                transform="shrink-3 down-2"
                color="falcon-danger"
                size="sm"
              >
                Delete
              </ButtonIcon>
              <ButtonIcon
                icon="edit"
                onClick={() => {
                  setShowContactUpdateModal(true);
                }}
                transform="shrink-3 down-2"
                color="falcon-primary"
                size="sm"
              >
                Edit
              </ButtonIcon>
            </ButtonGroup>
          </div>

          {
            // unBlock modal
          }
          <Modal
            isOpen={showUnblockModal}
            centered
            toggle={() => setShowUnblockModal(!showUnblockModal)}
          >
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Label className="center">
                    You will be able to recieve phone calls and messages from
                    this contact
                  </Label>
                  <Divider></Divider>
                  <Button
                    onClick={() => {
                      return (
                        dispatch(
                          unBlockContacts([contactDetails?.contact?.data?._id])
                        ), // added arr brackets to match type
                        setShowUnblockModal(!showUnblockModal)
                      );
                    }}
                    block
                    className="text-danger"
                    color="light"
                  >
                    Unblock Contact
                  </Button>
                  <Button
                    onClick={() => {
                      setShowUnblockModal(!showUnblockModal);
                    }}
                    block
                    className="text-primary"
                    color="light"
                  >
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>

          {
            // delete modal
          }
          <Modal
            isOpen={showDeleteModal}
            centered
            toggle={() => setShowDeleteModal(!showDeleteModal)}
          >
            <ModalHeader className="d-flex justify-content-center">
              Delete Contact
            </ModalHeader>
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Label className="d-flex justify-content-center">
                    Contact will be permenantly deleted.
                  </Label>
                  <Button
                    onClick={() => {
                      return dispatch(deleteContact(ContactId)), navigate(-1);
                    }}
                    block
                    className="text-danger"
                    color="light"
                  >
                    Delete contact
                  </Button>
                  <Button
                    onClick={() => {
                      setShowDeleteModal(!showDeleteModal);
                    }}
                    block
                    className="text-primary"
                    color="light"
                  >
                    Cancel
                  </Button>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>

          {
            // contact edit modal
          }
          <Modal
            size="lg"
            isOpen={showContactUpdateModal}
            centered
            toggle={() => setShowContactUpdateModal(!showContactUpdateModal)}
          >
            <ModalHeader toggle={toggle} className="bg-light border-bottom-0">
              <ButtonIcon
                onClick={() => {
                  return (
                    dispatch(updateContact(contact, ContactId)),
                    setShowContactUpdateModal(!showContactUpdateModal)
                  );
                }}
                disabled={!changeText}
                transform="shrink-3 down-2"
                icon=""
                color="falcon-primary"
                size="md"
              >
                done
              </ButtonIcon>
            </ModalHeader>
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <ContactEditModal
                    contact={contact}
                    ContactId={ContactId}
                    changeText={changeText}
                    setContact={setContact}
                    openGroups={openGroups}
                    successCreate={successCreate}
                    contactGroups={contactGroups}
                    contactFields={contactFields}
                    setChangeText={setChangeText}
                  />
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
          {
            // show save modal
          }
          <Modal
            isOpen={showSaveModal}
            centered
            toggle={() => setShowSaveModal(!showSaveModal)}
          >
            <ModalBody className="p-0">
              <Card>
                <CardBody className="fs--1 font-weight-normal p-4">
                  <Label className="center">
                    Are you sure you want to discard changes?
                  </Label>
                  <Divider></Divider>
                  <Button
                    onClick={() => {
                      return (
                        setChangeText(false),
                        setShowSaveModal(!showSaveModal),
                        setShowContactUpdateModal(!showContactUpdateModal)
                      );
                    }}
                    block
                    className="text-danger"
                  >
                    Discard Changes
                  </Button>
                  <Button
                    onClick={() => {
                      setShowSaveModal(!showSaveModal);
                    }}
                    block
                    className="text-primary"
                  >
                    Keep editing
                  </Button>
                </CardBody>
              </Card>
            </ModalBody>
          </Modal>
        </Fragment>
      </CardBody>

      <Card className="mb-3">
        <CardBody className="bg-light border-top">
          <Row>
            <Col lg className="mt-4 mt-lg-0">
              <Card className="mb-3">
                <Label className="ml-4 mt-2 mb-2">Phone</Label>
                <Label className="fs-1 ml-4 mb-2 text-primary">
                  {formatPhoneNumber(
                    contactDetails?.contact?.data?.phone_number
                  )}
                </Label>
              </Card>
              <Card className="mb-3">
                <Label className="ml-4 mt-2 mb-2">Notes</Label>
                <h6 className="fs-1 ml-4 mb-2">
                  {contactDetails?.contact?.data?.notes}
                </h6>
              </Card>
              {contactDetails?.contact?.data?.email ? (
                <Card className="mb-3">
                  <Label className="ml-4 mt-2 mb-2">Email</Label>
                  <Label className="fs-1 ml-4 mb-2">
                    {contactDetails?.contact?.data?.email}
                  </Label>
                </Card>
              ) : (
                <div></div>
              )}

              <Card className="mb-3">
                <Label className="ml-4 mt-2 mb-2">Groups</Label>
                {contactDetails?.contact?.data?.group.map((group, index) => {
                  return (
                    <div key={index}>
                      <h5 className="ml-4 mb-2">
                        {group?.title +
                          ' ' +
                          `(${group?.contacts?.length + ' contacts'})`}
                      </h5>
                    </div>
                  );
                })}
              </Card>

              <Card className="mb-3">
                <Label className="ml-4 mt-2 mb-2">Company Name</Label>
                <h6 className="fs-1 ml-4 mb-2">
                  {contactDetails?.contact?.data?.company}
                </h6>
              </Card>

              <h6 className="font-weight-semi-bold ls mb-3 text-uppercase">
                custom tags
              </h6>
              {contactFields.map((field, index) => {
                return (
                  <div key={index}>
                    <Card className="mb-1">
                      <Label className="ml-4 mt-2 mb-2">{field?.title}</Label>
                      <h5 className="ml-5 mb-2">{field?.body}</h5>
                    </Card>
                  </div>
                );
              })}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Card>
  );
};

export default ContactEdit;
