import React, { Fragment } from 'react';
import {
  Card,
  Media,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';

const FailedImports = ({ failed, duplicates }) => {

  //-------------------------------------------------------------------Formatters for columns-------------------------------------------------------------

  const firstNameFormatter = (rowData, { avatar }) => {
    const { Firstname } = rowData.row.original
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{Firstname}</h5>
        </Media>
      </Media>
    );
  };

  const lastNameFormatter = (rowData) => {
    const { Lastname } = rowData.row.original
    return (

      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-9">{Lastname}</h5>
      </Media>

    );
  };

  const reasonFormatter = (rowData) => {
    return (

      <Media body className="ml-2 mt-1">
        <SubtleBadge bg="danger" pill className="fs-10">
          <FontAwesomeIcon icon="times" transform="shrink-1" className="ml-1" />
          {` Invalid Number`}
        </SubtleBadge>
      </Media>

    );
  };

  const phoneFormatter = (rowData) => {
    const { Phone } = rowData.row.original
    return (
      <Media body className="ms-2" tag={Flex} align="center">
        <h5 className="mt-2 fs-10">{Phone}</h5>
      </Media>
    );
  };

  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [

    {
      accessor: 'Firstname',
      Header: 'First Name',
      Cell: firstNameFormatter
    },
    {
      accessor: 'Lastname',
      Header: 'Last Name',
      Cell: lastNameFormatter
    },
    {
      Header: 'Reason',
      Cell: reasonFormatter
    },
    {
      accessor: 'Phone',
      Header: 'Phone',
      Cell: phoneFormatter
    }
  ];


  //-------------------------------------------------------------------Handlers----------------------------------------------------------------------------

  return (

    <Fragment>
      <Card>
        <AdvanceTableWrapper
          columns={columns}
          data={failed}
          sortable
          pagination
          perPage={10}
          selectionCallback={() => { }}
        >


          <AdvanceTable
            table
            headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
            rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
            tableProps={{
              striped: true,
              className: 'fs--1 font-weight-bold overflow-hidden'
            }}
          />
          <div className="mt-3 mb-2 pe-2 ps-3 ">
            <AdvanceTableFooter
              rowCount={failed.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </div>
        </AdvanceTableWrapper>
      </Card>
    </Fragment>
  );
};


export default FailedImports;