import React, { Fragment, useState, useEffect, useContext } from 'react';
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'reactstrap';
import Divider from 'components/common/Divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import { CsvUploadContext } from 'context/Context';
import { useDispatch, useSelector } from 'react-redux';
import makeAnimated from 'react-select/animated';
import { listGroups, createGroup } from 'actions/index';
import { GROUP_CREATE_RESET } from 'actions/types';
import Select from 'react-select';
import { getPageName } from 'helpers/utils';

const StepTwoForm = ({ register, errors, checked, setChecked }) => {
  const { upload, setUpload, step, setStep, userFile, handleInputChange } =
    useContext(CsvUploadContext);

  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [newGroup, setNewGroup] = useState({
    title: ''
  });

  const dispatch = useDispatch();
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;

  const groupCreate = useSelector(state => state.groupCreate);
  const { success: successCreate } = groupCreate;
  const [showAskNumbersModal, setShowAskNumbersModal] = useState(false);

  const isCustomerImport = getPageName('customer-import');

  const animatedComponents = makeAnimated();

  console.log('upload object', upload);

  useEffect(() => {
    setUpload({ ...upload, fileTitle: userFile });
    dispatch(listGroups());
  }, [successCreate]);

  useEffect(() => {
    if (successCreate) {
      toast.success('Group created', {
        className: 'bg-dark',
        position: 'bottom-left',
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 2000
      });
      const newGroup = {
        _id: groupCreate.createdGroup.createdGroup._id,
        title: groupCreate.createdGroup.createdGroup.title,
        hide: groupCreate.createdGroup.createdGroup.hide,
        created: groupCreate.createdGroup.createdGroup.created,
        contactsCount: groupCreate.createdGroup.createdGroup.contactsCount
      };
      const addCreatedGroup = [...upload.group, newGroup];

      setUpload({ ...upload, group: addCreatedGroup });
    }

    return () => {
      dispatch({ type: GROUP_CREATE_RESET });
    };
  }, [successCreate]);

  const toggle = () => {
    setCreateGroupModal(!createGroupModal);
  };

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggle}></button>
  );

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(createGroup(newGroup));
    setCreateGroupModal(false);
  };

  const handleChange = e => {
    setNewGroup({ ...newGroup, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    if (isCustomerImport) {
      setShowAskNumbersModal(true);
    }
  }, []);

  const countries = [
    'US',
    'CA',
    'AU',
    'BE',
    'BR',
    'CL',
    'HR',
    'CZ',
    'DK',
    'EE',
    'DE',
    'HK',
    'HU',
    'IL',
    'IT',
    'LT',
    'MY',
    'MX',
    'NL',
    'NO',
    'PL',
    'PR',
    'RO',
    'SK',
    'ES',
    'SE',
    'GB',
    'CH'
  ];

  return (
    <Fragment>
      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="same-country-numbers"
            checked={upload?.countryNumbers === 'sameCountries'}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            value="sameCountries"
            name="countryNumbers"
            errors={errors}
          />
          <div>
            <strong>All of my numbers are from the same country</strong>
          </div>
        </Label>
      </FormGroup>

      <FormGroup select>
        <Label select>
          <Input
            type="select"
            placeholder="Select your country"
            id="selectCountry"
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            name="selectCountry"
            errors={errors}
            value={upload?.selectCountry}
          >
            {countries.map(country => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </Input>
          <div>
            <p>Please select the country that your numbers are from.</p>
          </div>
        </Label>
      </FormGroup>

      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="diff-country-numbers"
            checked={upload?.countryNumbers === 'diffCountries'}
            onChange={({ target }) => {
              handleInputChange(target);
            }}
            value={'diffCountries'}
            name="countryNumbers"
            errors={errors}
          />
          <div>
            <strong>My numbers are from multiple countries</strong>
          </div>
        </Label>
      </FormGroup>


      {upload?.countryNumbers === 'diffCountries' && (
        <div>
          <Label className="fs--1 text-danger fw-normal d-block">
            You need to manually format your numbers. Numbers must be in
            international format with the country code and no leading zeroes,
            e.g. 17209908977 for US.
          </Label>
        </div>
      )}
      <Divider className="mt-4">
        {isCustomerImport
          ? 'Add contacts to Follow up group after import?'
          : 'Add contacts to group after import '}
        <strong style={{ color: 'red' }}>*</strong>
      </Divider>

      <Select
        name="group"
        required={true}
        className="mb-3"
        components={animatedComponents}
        closeMenuOnSelect={true}
        options={groups}
        value={upload?.group}
        getOptionLabel={({ title }) => title}
        getOptionValue={({ _id }) => _id}
        onChange={group => setUpload({ ...upload, group: group ? group : [] })}
        isMulti
        placeholder="select group"
        isSearchable={true}
        errors={errors}
      />
      <Button
        onClick={() => setCreateGroupModal(true)}
        className="btn-tertiary border-dark mb-0"
      >
        <FontAwesomeIcon icon="user-plus" />
        {` or create a new group`}
      </Button>
      <Modal
        isOpen={createGroupModal}
        centered
        toggle={() => setCreateGroupModal(!createGroupModal)}
      >
        <ModalHeader
          toggle={toggle}
          className="d-flex flex-between-center border-bottom-0"
          close={closeBtn}
        >
          Let's give the group a name
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 fw-normal p-4">
              <Form onSubmit={handleSubmit}>
                <Label for="title">Group Name:</Label>
                <Input
                  value={newGroup.title.value}
                  onChange={handleChange}
                  className="mb-3"
                  name="title"
                  id="title"
                />
                <Button
                  block
                  onClick={handleSubmit}
                  color="primary"
                  className="mb-3"
                >
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
      <Divider className="mt-4">Landline Filter</Divider>

      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            id="landlineFilter"
            checked={upload?.landlineFilter === true}
            onClick={({ target }) => {
              setChecked(!checked);
              setUpload({ ...upload, landlineFilter: !upload.landlineFilter });
            }}
            {...register('landlineFilter')}
            name="landlineFilter"
            errors={errors}
          />
          <div>Exclude landline numbers for 1 credit per number</div>
        </Label>
      </FormGroup>

      <p>
        If your list contains landline numbers, they will be ignored and will
        not be imported.
      </p>

      {checked === true && (
        <div>
          <div className="mb-2 p-3 border rounded text-center cursor-pointer">
            {`Your list contains `}
            <strong>{upload?.contacts?.length}</strong>{' '}
            {`row(s). It will cost you `}
            <strong>{`$${(upload?.contacts?.length * 0.04).toFixed(
              2
            )}`}</strong>
            {` to check them for landlines using `}
            <a href="#!">Carrier Lookup</a>
            {` service.`}
          </div>
        </div>
      )}

      {step === 3 && checked === true(<div></div>)}

      <Modal
        size="md"
        isOpen={showAskNumbersModal}
        centered
        toggle={() => setShowAskNumbersModal(!showAskNumbersModal)}
      >
        <ModalHeader>
          Does your list contain phone numbers for your customers?
        </ModalHeader>

        <ModalFooter>
          <Button
            onClick={() => {
              return (
                setUpload({ ...upload, hasNumbers: true }),
                setShowAskNumbersModal(false)
              );
            }}
            className="btn btn-sm btn-falcon-success fw-bold"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              return (
                setUpload({ ...upload, hasNumbers: false }),
                setStep(3),
                setShowAskNumbersModal(false)
              );
            }}
            className="btn btn-sm btn-falcon-danger fw-bold"
          >
            No
          </Button>
        </ModalFooter>
      </Modal>

    </Fragment>
  );
};

export default StepTwoForm;
