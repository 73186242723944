import React, { Fragment, useState } from 'react';
import axios from 'axios';
import Lottie from 'lottie-react';
import InputMask from 'react-input-mask'; // Import the library
import { Button, Card, Form, Alert, Col } from 'react-bootstrap';
import verifyLottie from '../../../assets/img/lottie/verifyEmail.json';

const PhoneNumberVerification = ({
  bankOrCard,
  setShowBankAccountModal,
  setShowDebitCardModal
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationStatus, setVerificationStatus] = useState('');
  const [showLottie, setShowLottie] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const formatPhoneNumber = input => {
    // Strip out non-numeric characters
    const numericOnly = input.replace(/\D/g, '');

    // Prepend +1 for US and Canada
    return `+1${numericOnly}`;
  };

  const sendVerificationCode = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      await axios.post(`${process.env.REACT_APP_API_URL}/api/verify/send`, {
        phoneNumber: formattedPhoneNumber
      });
      setVerificationStatus('Verification code sent. Please check your phone.');
    } catch (error) {
      setError('Error sending verification code. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPhoneNumber = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/verify/check`,
        { phoneNumber: formattedPhoneNumber, verificationCode },
        { withCredentials: true }
      );

      if (response.data.status === 'approved') {
        setShowLottie(true);
      } else {
        setError('The verification code is incorrect or expired.');
      }
    } catch (error) {
      setError('Error verifying phone number. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log('bankOrCard', bankOrCard);
  return (
    <Fragment>
      {!showLottie ? (
        <Fragment>
          <Form.Control // Updated to use React Bootstrap
            as={InputMask}
            mask="(999) 999-9999"
            value={phoneNumber}
            onChange={e => setPhoneNumber(e.target.value)}
            disabled={isLoading}
            className="my-3"
            placeholder="(123) 456-7890"
          />
          <Button
            variant="primary"
            onClick={sendVerificationCode}
            disabled={isLoading || phoneNumber.length !== 14}
          >
            Send Verification Code
          </Button>

          <Form.Control // Updated
            type="text"
            placeholder="Verification code"
            value={verificationCode}
            onChange={e => setVerificationCode(e.target.value)}
            disabled={isLoading}
            className="my-3"
          />
          <Button
            variant="primary"
            onClick={verifyPhoneNumber}
            disabled={isLoading}
          >
            Verify Phone Number
          </Button>

          {error && (
            <Alert variant="danger" className="my-3">
              {error}
            </Alert>
          )}
          {verificationStatus && (
            <Alert variant="success" className="my-3">
              {verificationStatus}
            </Alert>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <Col xs={12}>
            <Card>
              <Card.Body className="fs--1 fw-normal p-2">
                <p className="lead mt-2 text-800 text-sans-serif fw-semibold">
                  Verification Successful.
                </p>
                <hr />
                <div className="wizard-lottie-wrapper">
                  <div className="wizard-lottie mx-auto">
                    <Lottie
                      animationData={verifyLottie}
                      onLoopComplete={() => {
                        if (bankOrCard === 'bank') {
                          return setShowBankAccountModal(false);
                        } else if (bankOrCard === 'card') {
                          return setShowDebitCardModal(false);
                        } else {
                          return;
                        }
                      }}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PhoneNumberVerification;
