import React, { useContext, useState, Fragment } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm } from 'react-hook-form';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import ResponderSuccess from './ResponderSuccess';
import AppContext, { ResponderUploadContext } from 'context/Context';
import WizardModal from 'components/wizard/WizardModal';
import ButtonIcon from 'components/common/ButtonIcon';

const ResponderForm = () => {
  const { isRTL } = useContext(AppContext);
  const { upload, setUpload, step, setStep, userFile } = useContext(
    ResponderUploadContext
  ); // this is basically creating these props to be
  const { register, handleSubmit, errors, watch } = useForm();
  const [checked, setChecked] = useState(false);
  const [weekDays, setWeekDays] = useState({
    sunday: {},
    monday: {},
    tuesday: {},
    wednesday: {},
    thursday: {},
    friday: {},
    saturday: {}
  });

  const onSubmitData = data => {
    // setUpload({ ...upload, ...data });
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card
        tag={Form}
        onSubmit={handleSubmit(onSubmitData)}
        className="theme-wizard"
      >
        <CardHeader>
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done cursor-pointer': step > 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="cog" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">
                  Choose keywords
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step > 2,
                  active: step === 2
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="users" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">
                  Choose groups
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step > 3,
                  active: step === 3
                })}
              >
                <span className="nav-item-circle-parent">
                  <span className="nav-item-circle">
                    <FontAwesomeIcon icon="thumbs-up" />
                  </span>
                </span>
                <span className="d-none d-md-block mt-1 fs--1">Success</span>
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 fw-normal px-md-6 pt-4 pb-3">
          {step === 1 && (
            <StepOneForm
              weekDays={weekDays}
              register={register}
              errors={errors}
            />
          )}
          {step === 2 && (
            <StepTwoForm
              weekDays={weekDays}
              register={register}
              errors={errors}
              userFile={userFile}
              checked={checked}
              setChecked={setChecked}
            />
          )}
          {step === 3 && (
            <ResponderSuccess checked={checked} userFile={userFile} />
          )}
        </CardBody>
        <CardFooter className={classNames('px-md-6 d-flex')}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-3 fw-semibold', {
              'd-none': step === 1 || step === 3
            })}
            onClick={() => {
              setStep(step - 1);
            }}
          >

          </ButtonIcon>

          <ButtonIcon
            disabled={
              !upload.title ||
              !upload.responseType ||
              (!upload.keyword && upload.requireKeyword) ||
              !upload.numbers ||
              (!upload.responseTime && step === 2) ||
              (upload.responseType === 'textMessage' && !upload.message.length) ||
              (upload.responseType === 'textToSpeech' && !upload.message.length)
            }
            color="primary"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            className={classNames('ms-auto px-3', { 'd-none': step === 3 })}
          >

          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default ResponderForm;
