import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Input,
  Label,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner
} from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { useSelector, useDispatch } from 'react-redux';
import SubtleBadge from 'components/common/SubtleBadge';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import { useForm } from 'react-hook-form';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  listKeywords,
  createKeyword,
  deleteMultipleKeywords
} from 'actions/index';
import { KEYWORD_CREATE_RESET, KEYWORD_DELETE_RESET, KEYWORD_UPDATE_RESET } from 'actions/types';
import ActionPopover from 'components/common/ActionPopover';

const Keywords = () => {
  const dispatch = useDispatch();
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [rowDelete, setRowDelete] = useState();
  const [keyword, setKeyword] = useState({
    title: ''
  });

  const { register, errors, handleSubmit } = useForm();

  const usersKeywords = useSelector(state => state.keywords);
  const { keywords: keywords, loading: loadingKeywords, totalKeywords: totalKeywords } = usersKeywords;

  const keywordCreate = useSelector(state => state.keywordCreate);
  const { success: successCreate } = keywordCreate;

  const keywordDelete = useSelector(state => state.keywordDelete);
  const { success: successDelete } = keywordDelete;

  const keywordUpdate = useSelector(state => state.keywordUpdate);
  const { success: successUpdate } = keywordUpdate;

  const closeCreateBtn = (
    <button
      className="btn-close bg-light"
      onClick={() => {
        setShowKeywordModal(!showKeywordModal);
      }}
    ></button>
  );

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original._id;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  const nameFormatter = rowData => {
    const { title } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9">{title}</h5>
        </Media>
      </Media>
    );
  };

  const statusFormatter = rowData => {
    const { status } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <SubtleBadge bg="success" pill className="fs-9">
            {status}
          </SubtleBadge>
        </Media>
      </Media>
    );
  };

  const createdFormatter = rowData => {
    const { created } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <h5 className="mb-0 fs-9 ">
            <Moment format="MM/DD/YYYY" date={created} />
          </h5>
        </Media>
      </Media>
    );
  };

  const responderFormatter = rowData => {
    const { responder, _id } = rowData.row.original;
    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <SubtleBadge bg="primary" pill className="fs-9">
            {`${responder?.length} responders`}
          </SubtleBadge>
        </Media>
      </Media>
    );
  };

  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;
    const items = [
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          return (
            setRowDelete([_id]), setShowDeleteModal(!showDeleteModal)
          );
        }
      }
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
        />
      </div>
    );
  };



  const columns = [
    {
      accessor: 'title',
      Header: 'Keyword',
      Cell: nameFormatter
    },
    {
      accessor: 'status',
      Header: 'Status',
      Cell: statusFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'responder',
      Header: 'Responders',
      Cell: responderFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right'
    }
  ];

  const onSubmit = data => {
    // e.preventDefault()
    dispatch(createKeyword(keyword));
    setShowKeywordModal(!showKeywordModal);
  };

  const handleChange = e => {
    setKeyword({ ...keyword, [e.target.name]: e.target.value });
  };


  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listKeywords({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  useEffect(() => {
    if (successCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: KEYWORD_CREATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: KEYWORD_UPDATE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successUpdate, fetchData, dispatch]);

  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: KEYWORD_DELETE_RESET }); // Replace with your actual action to reset successUpdate
    }
  }, [successDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listKeywords({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };



  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon
                icon="plus"
                onClick={() => {
                  setShowKeywordModal(!showKeywordModal);
                }}
                transform="shrink-3"
                className="btn-tertiary border-dark"
                size="sm"
              >
                Keyword
              </ButtonIcon>
              <ButtonIcon
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                }}
                icon="trash"
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark text-danger ms-2"
                size="sm"
              >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingKeywords && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={keywords}
            fetchData={fetchData}
            total={totalKeywords}
            sortable
            pagination
            perPage={10}
            selection
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  onSearch={handleSearch}
                  placeholder="Search Keywords..."

                  table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs-9 font-weight-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalKeywords}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>

      <Modal
        isOpen={showKeywordModal}
        centered
        toggle={() => setShowKeywordModal(!showKeywordModal)}
      >
        <ModalHeader
          close={closeCreateBtn}
          className="text-center d-flex flex-between-center border-bottom-0"
        >
          Add a new keyword
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 font-weight-normal p-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Col>
                  <Row>
                    <Label for="title">Enter your keyword:</Label>
                    <Input
                      value={keyword?.title?.value}
                      onChange={handleChange}
                      className={!errors?.title ? 'mb-3' : 'mb-3 border-danger'}
                      name="title"
                      id="title"
                      {...register('title', {
                        required:
                          'This Keyword is reserved for Automatic Opt-out, Anyone who sends this keyword will be added to unsubscribers group',
                        validate: value => {
                          return (
                            value.toLowerCase() !== 'stop' &&
                            value.toLowerCase() !== 'unsubscribe'
                          );
                        }
                      })}
                      errors={errors}
                    />

                    {errors?.title ? (
                      <div>
                        <span className="text-danger d-inline-block">
                          This Keyword is reserved for Automatic Opt-out, Anyone
                          who sends this keyword will be added to unsubscribers
                          group
                        </span>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </Row>
                </Col>
                <Button block color="primary" className="mb-3" type="submit">
                  Save
                </Button>
              </Form>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 font-weight-normal p-4">
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    dispatch(deleteMultipleKeywords(rowDelete));
                  } else {
                    dispatch(deleteMultipleKeywords(isSelected));
                  }
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="btn-tertiary border-dark text-danger"
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="btn-tertiary border mt-3"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default Keywords;
