export default [
  { value: '719', label: '(719) - Colorado (CO)' },
  { value: '720', label: '(720) - Colorado (CO)' },
  { value: '303', label: '(303) - Colorado (CO)' },
  { value: '970', label: '(970) - Colorado (CO)' },
  { value: '907', label: '(907) - Alaska (AK) ' },
  { value: '256', label: '(256) - Alabama (AL)' },
  { value: '334', label: '(334) - Alabama (AL)' },
  { value: '251', label: '(251) - Alabama (AL)' },
  { value: '205', label: '(205) - Alabama (AL)' },
  { value: '870', label: '(870) - Arkansas (AR)' },
  { value: '501', label: '(501) - Arkansas (AR)' },
  { value: '479', label: '(479) - Arkansas (AR)' },
  { value: '602', label: '(602) - Arizona (AZ) ' },
  { value: '520', label: '(520) - Arizona (AZ) ' },
  { value: '928', label: '(928) - Arizona (AZ) ' },
  { value: '623', label: '(623) - Arizona (AZ) ' },
  { value: '480', label: '(480) - Arizona (AZ) ' },
  { value: '213', label: '(213) - California (CA)' },
  { value: '442', label: '(442) - California (CA)' },
  { value: '916', label: '(916) - California (CA)' },
  { value: '707', label: '(707) - California (CA)' },
  { value: '627', label: '(627) - California (CA)' },
  { value: '714', label: '(714) - California (CA)' },
  { value: '310', label: '(310) - California (CA)' },
  { value: '323', label: '(323) - California (CA)' },
  { value: '805', label: '(805) - California (CA)' },
  { value: '424', label: '(424) - California (CA)' },
  { value: '747', label: '(747) - California (CA)' },
  { value: '818', label: '(818) - California (CA) ' },
  { value: '858', label: '(858) - California (CA) ' },
  { value: '935', label: '(935) - California (CA) ' },
  { value: '619', label: '(619) - California (CA) ' },
  { value: '559', label: '(559) - California (CA) ' },
  { value: '369', label: '(369) - California (CA) ' },
  { value: '626', label: '(626) - California (CA) ' },
  { value: '530', label: '(530) - California (CA) ' },
  { value: '408', label: '(408) - California (CA) ' },
  { value: '510', label: '(510) - California (CA) ' },
  { value: '341', label: '(341) - California (CA) ' },
  { value: '764', label: '(764) - California (CA) ' },
  { value: '925', label: '(925) - California (CA) ' },
  { value: '909', label: '(909) - California (CA) ' },
  { value: '562', label: '(562) - California (CA) ' },
  { value: '661', label: '(661) - California (CA) ' },
  { value: '657', label: '(657) - California (CA) ' },
  { value: '650', label: '(650) - California (CA) ' },
  { value: '949', label: '(949) - California (CA) ' },
  { value: '760', label: '(760) - California (CA) ' },
  { value: '415', label: '(415) - California (CA) ' },
  { value: '951', label: '(951) - California (CA) ' },
  { value: '752', label: '(752) - California (CA) ' },
  { value: '831', label: '(831) - California (CA) ' },
  { value: '628', label: '(628) - California (CA) ' },
  { value: '209', label: '(209) - California (CA) ' },
  { value: '669', label: '(669) - California (CA) ' },
  { value: '203', label: '(203) - Connecticut (CT) ' },
  { value: '959', label: '(959) - Connecticut (CT) ' },
  { value: '475', label: '(475) - Connecticut (CT) ' },
  { value: '860', label: '(860) - Connecticut (CT) ' },
  { value: '202', label: '(202) - District Of Columbia (DC) ' },
  { value: '302', label: '(302) - Delaware (DE) ' },
  { value: '786', label: '(786) - Florida (FL)' },
  { value: '561', label: '(561) - Florida (FL)' },
  { value: '772', label: '(772) - Florida (FL)' },
  { value: '904', label: '(904) - Florida (FL)' },
  { value: '305', label: '(305) - Florida (FL)' },
  { value: '941', label: '(941) - Florida (FL)' },
  { value: '813', label: '(813) - Florida (FL)' },
  { value: '850', label: '(850) - Florida (FL)' },
  { value: '386', label: '(386) - Florida (FL)' },
  { value: '861', label: '(861) - Florida (FL)' },
  { value: '863', label: '(863) - Florida (FL)' },
  { value: '689', label: '(689) - Florida (FL)' },
  { value: '407', label: '(407) - Florida (FL)' },
  { value: '239', label: '(239) - Florida (FL)' },
  { value: '836', label: '(836) - Florida (FL)' },
  { value: '352', label: '(352) - Florida (FL)' },
  { value: '727', label: '(727) - Florida (FL)' },
  { value: '321', label: '(321) - Florida (FL)' },
  { value: '754', label: '(754) - Florida (FL)' },
  { value: '954', label: '(954) - Florida (FL)' },
  { value: '770', label: '(770) - Georgia (GA)' },
  { value: '470', label: '(470) - Georgia (GA)' },
  { value: '404', label: '(404) - Georgia (GA)' },
  { value: '706', label: '(706) - Georgia (GA)' },
  { value: '678', label: '(678) - Georgia (GA)' },
  { value: '912', label: '(912) - Georgia (GA)' },
  { value: '229', label: '(229) - Georgia (GA)' },
  { value: '478', label: '(478) - Georgia (GA)' },
  { value: '808', label: '(808) - Hawaii (HI)' },
  { value: '641', label: '(641) - Iowa (IA)' },
  { value: '712', label: '(712) - Iowa (IA)' },
  { value: '319', label: '(319) - Iowa (IA)' },
  { value: '563', label: '(563) - Iowa (IA)' },
  { value: '515', label: '(515) - Iowa (IA)' },
  { value: '208', label: '(208) - Idaho (ID)' },
  { value: '630', label: '(630) - Illinois (IL)' },
  { value: '331', label: '(331) - Illinois (IL)' },
  { value: '309', label: '(309) - Illinois (IL)' },
  { value: '618', label: '(618) - Illinois (IL)' },
  { value: '847', label: '(847) - Illinois (IL)' },
  { value: '224', label: '(224) - Illinois (IL)' },
  { value: '708', label: '(708) - Illinois (IL)' },
  { value: '815', label: '(815) - Illinois (IL)' },
  { value: '464', label: '(464) - Illinois (IL)' },
  { value: '773', label: '(773) - Illinois (IL)' },
  { value: '312', label: '(312) - Illinois (IL)' },
  { value: '872', label: '(872) - Illinois (IL)' },
  { value: '282', label: '(282) - Illinois (IL)' },
  { value: '217', label: '(217) - Illinois (IL)' },
  { value: '812', label: '(812) - Indiana (IN)' },
  { value: '317', label: '(317) - Indiana (IN)' },
  { value: '260', label: '(260) - Indiana (IN)' },
  { value: '219', label: '(219) - Indiana (IN)' },
  { value: '574', label: '(574) - Indiana (IN)' },
  { value: '765', label: '(765) - Indiana (IN)' },
  { value: '785', label: '(785) - Kansas (KS)' },
  { value: '316', label: '(316) - Kansas (KS)' },
  { value: '620', label: '(620) - Kansas (KS)' },
  { value: '913', label: '(913) - Kansas (KS)' },
  { value: '859', label: '(859) - Kentucky (KY)' },
  { value: '270', label: '(270) - Kentucky (KY)' },
  { value: '502', label: '(502) - Kentucky (KY)' },
  { value: '327', label: '(327) - Kentucky (KY)' },
  { value: '606', label: '(606) - Kentucky (KY)' },
  { value: '504', label: '(504) - Louisiana (LA)' },
  { value: '985', label: '(985) - Louisiana (LA)' },
  { value: '225', label: '(225) - Louisiana (LA)' },
  { value: '318', label: '(318) - Louisiana (LA)' },
  { value: '337', label: '(337) - Louisiana (LA)' },
  { value: '617', label: '(617) - Massachusetts (MA)' },
  { value: '413', label: '(413) - Massachusetts (MA)' },
  { value: '351', label: '(351) - Massachusetts (MA)' },
  { value: '978', label: '(978) - Massachusetts (MA)' },
  { value: '339', label: '(339) - Massachusetts (MA)' },
  { value: '857', label: '(857) - Massachusetts (MA)' },
  { value: '508', label: '(508) - Massachusetts (MA)' },
  { value: '774', label: '(774) - Massachusetts (MA)' },
  { value: '781', label: '(781) - Massachusetts (MA)' },
  { value: '969', label: '(969) - Maryland (MD)' },
  { value: '301', label: '(301) - Maryland (MD)' },
  { value: '240', label: '(240) - Maryland (MD)' },
  { value: '249', label: '(249) - Maryland (MD)' },
  { value: '280', label: '(280) - Maryland (MD)' },
  { value: '410', label: '(410) - Maryland (MD)' },
  { value: '443', label: '(443) - Maryland (MD)' },
  { value: '207', label: '(207) - Maine (ME)' },
  { value: '383', label: '(383) - Maine (ME)' },
  { value: '947', label: '(947) - Michigan (MI)' },
  { value: '679', label: '(679) - Michigan (MI)' },
  { value: '231', label: '(231) - Michigan (MI)' },
  { value: '616', label: '(616) - Michigan (MI)' },
  { value: '269', label: '(269) - Michigan (MI)' },
  { value: '906', label: '(906) - Michigan (MI)' },
  { value: '989', label: '(989) - Michigan (MI)' },
  { value: '734', label: '(734) - Michigan (MI)' },
  { value: '586', label: '(586) - Michigan (MI)' },
  { value: '313', label: '(313) - Michigan (MI)' },
  { value: '278', label: '(278) - Michigan (MI)' },
  { value: '810', label: '(810) - Michigan (MI)' },
  { value: '546', label: '(546) - Michigan (MI)' },
  { value: '517', label: '(517) - Michigan (MI)' },
  { value: '248', label: '(248) - Michigan (MI)' },
  { value: '952', label: '(952) - Minnesota (MN)' },
  { value: '507', label: '(507) - Minnesota (MN)' },
  { value: '218', label: '(218) - Minnesota (MN)' },
  { value: '320', label: '(320) - Minnesota (MN)' },
  { value: '763', label: '(763) - Minnesota (MN)' },
  { value: '651', label: '(651) - Minnesota (MN)' },
  { value: '612', label: '(612) - Minnesota (MN)' },
  { value: '314', label: '(314) - Missouri (MO)' },
  { value: '417', label: '(417) - Missouri (MO)' },
  { value: '557', label: '(557) - Missouri (MO)' },
  { value: '573', label: '(573) - Missouri (MO)' },
  { value: '816', label: '(816) - Missouri (MO)' },
  { value: '975', label: '(975) - Missouri (MO)' },
  { value: '636', label: '(636) - Missouri (MO)' },
  { value: '660', label: '(660) - Missouri (MO)' },
  { value: '601', label: '(601) - Mississippi (MS)' },
  { value: '662', label: '(662) - Mississippi (MS)' },
  { value: '228', label: '(228) - Mississippi (MS)' },
  { value: '406', label: '(406) - Montana (MT)' },
  { value: '704', label: '(704) - North Carolina (NC)' },
  { value: '828', label: '(828) - North Carolina (NC)' },
  { value: '910', label: '(910) - North Carolina (NC)' },
  { value: '980', label: '(980) - North Carolina (NC)' },
  { value: '919', label: '(919) - North Carolina (NC)' },
  { value: '984', label: '(984) - North Carolina (NC)' },
  { value: '252', label: '(252) - North Carolina (NC)' },
  { value: '336', label: '(336) - North Carolina (NC)' },
  { value: '701', label: '(701) - North Dakota (ND)' },
  { value: '402', label: '(402) - Nebraska (NE)' },
  { value: '308', label: '(308) - Nebraska (NE)' },
  { value: '603', label: '(603) - New Hampshire (NH)' },
  { value: '862', label: '(862) - New Jersey (NJ)' },
  { value: '856', label: '(856) - New Jersey (NJ)' },
  { value: '609', label: '(609) - New Jersey (NJ)' },
  { value: '973', label: '(973) - New Jersey (NJ)' },
  { value: '908', label: '(908) - New Jersey (NJ)' },
  { value: '201', label: '(201) - New Jersey (NJ)' },
  { value: '732', label: '(732) - New Jersey (NJ)' },
  { value: '848', label: '(848) - New Jersey (NJ)' },
  { value: '551', label: '(551) - New Jersey (NJ)' },
  { value: '505', label: '(505) - New Mexico (NM)' },
  { value: '957', label: '(957) - New Mexico (NM)' },
  { value: '702', label: '(702) - Nevada (NV)' },
  { value: '775', label: '(775) - Nevada (NV)' },
  { value: '212', label: '(212) - New York (NY)' },
  { value: '914', label: '(914) - New York (NY)' },
  { value: '607', label: '(607) - New York (NY)' },
  { value: '631', label: '(631) - New York (NY)' },
  { value: '845', label: '(845) - New York (NY)' },
  { value: '516', label: '(516) - New York (NY)' },
  { value: '917', label: '(917) - New York (NY)' },
  { value: '718', label: '(718) - New York (NY)' },
  { value: '646', label: '(646) - New York (NY)' },
  { value: '585', label: '(585) - New York (NY)' },
  { value: '716', label: '(716) - New York (NY)' },
  { value: '518', label: '(518) - New York (NY)' },
  { value: '315', label: '(315) - New York (NY)' },
  { value: '347', label: '(347) - New York (NY)' },
  { value: '440', label: '(440) - Ohio (OH)' },
  { value: '513', label: '(513) - Ohio (OH)' },
  { value: '283', label: '(283) - Ohio (OH)' },
  { value: '614', label: '(614) - Ohio (OH)' },
  { value: '740', label: '(740) - Ohio (OH)' },
  { value: '234', label: '(234) - Ohio (OH)' },
  { value: '380', label: '(380) - Ohio (OH)' },
  { value: '419', label: '(419) - Ohio (OH)' },
  { value: '567', label: '(567) - Ohio (OH)' },
  { value: '330', label: '(330) - Ohio (OH)' },
  { value: '216', label: '(216) - Ohio (OH)' },
  { value: '937', label: '(937) - Ohio (OH)' },
  { value: '405', label: '(405) - Oklahoma (OK)' },
  { value: '918', label: '(918) - Oklahoma (OK)' },
  { value: '580', label: '(580) - Oklahoma (OK)' },
  { value: '503', label: '(503) - Oregon (OR)' },
  { value: '971', label: '(971) - Oregon (OR)' },
  { value: '541', label: '(541) - Oregon (OR)' },
  { value: '610', label: '(610) - Pennsylvania (PA)' },
  { value: '412', label: '(412) - Pennsylvania (PA)' },
  { value: '724', label: '(724) - Pennsylvania (PA)' },
  { value: '215', label: '(215) - Pennsylvania (PA)' },
  { value: '267', label: '(267) - Pennsylvania (PA)' },
  { value: '445', label: '(445) - Pennsylvania (PA)' },
  { value: '835', label: '(835) - Pennsylvania (PA)' },
  { value: '484', label: '(484) - Pennsylvania (PA)' },
  { value: '878', label: '(878) - Pennsylvania (PA)' },
  { value: '358', label: '(358) - Pennsylvania (PA)' },
  { value: '570', label: '(570) - Pennsylvania (PA)' },
  { value: '717', label: '(717) - Pennsylvania (PA)' },
  { value: '814', label: '(814) - Pennsylvania (PA)' },
  { value: '401', label: '(401) - Rhode Island (RI)' },
  { value: '803', label: '(803) - South Carolina (SC)' },
  { value: '864', label: '(864) - South Carolina (SC)' },
  { value: '843', label: '(843) - South Carolina (SC)' },
  { value: '605', label: '(605) - South Dakota (SD)' },
  { value: '423', label: '(423) - Tennessee (TN)' },
  { value: '865', label: '(865) - Tennessee (TN)' },
  { value: '931', label: '(931) - Tennessee (TN)' },
  { value: '615', label: '(615) - Tennessee (TN)' },
  { value: '901', label: '(901) - Tennessee (TN)' },
  { value: '731', label: '(731) - Tennessee (TN)' },
  { value: '682', label: '(682) - Texas (TX)' },
  { value: '979', label: '(979) - Texas (TX)' },
  { value: '972', label: '(972) - Texas (TX)' },
  { value: '469', label: '(469) - Texas (TX)' },
  { value: '214', label: '(214) - Texas (TX)' },
  { value: '956', label: '(956) - Texas (TX)' },
  { value: '832', label: '(832) - Texas (TX)' },
  { value: '281', label: '(281) - Texas (TX)' },
  { value: '830', label: '(830) - Texas (TX)' },
  { value: '432', label: '(432) - Texas (TX)' },
  { value: '915', label: '(915) - Texas (TX)' },
  { value: '409', label: '(409) - Texas (TX)' },
  { value: '936', label: '(936) - Texas (TX)' },
  { value: '210', label: '(210) - Texas (TX)' },
  { value: '817', label: '(817) - Texas (TX)' },
  { value: '254', label: '(254) - Texas (TX)' },
  { value: '325', label: '(325) - Texas (TX)' },
  { value: '361', label: '(361) - Texas (TX)' },
  { value: '940', label: '(940) - Texas (TX)' },
  { value: '713', label: '(713) - Texas (TX)' },
  { value: '430', label: '(430) - Texas (TX)' },
  { value: '903', label: '(903) - Texas (TX)' },
  { value: '806', label: '(806) - Texas (TX)' },
  { value: '737', label: '(737) - Texas (TX)' },
  { value: '512', label: '(512) - Texas (TX)' },
  { value: '435', label: '(435) - Utah (UT)' },
  { value: '801', label: '(801) - Utah (UT)' },
  { value: '385', label: '(385) - Utah (UT)' },
  { value: '236', label: '(236) - Virginia (VA)' },
  { value: '381', label: '(381) - Virginia (VA)' },
  { value: '276', label: '(276) - Virginia (VA)' },
  { value: '540', label: '(540) - Virginia (VA)' },
  { value: '571', label: '(571) - Virginia (VA)' },
  { value: '757', label: '(757) - Virginia (VA)' },
  { value: '804', label: '(804) - Virginia (VA)' },
  { value: '434', label: '(434) - Virginia (VA)' },
  { value: '703', label: '(703) - Virginia (VA)' },
  { value: '802', label: '(802) - Vermont (VT)' },
  { value: '206', label: '(206) - Washington (WA)' },
  { value: '253', label: '(253) - Washington (WA)' },
  { value: '425', label: '(425) - Washington (WA)' },
  { value: '564', label: '(564) - Washington (WA)' },
  { value: '360', label: '(360) - Washington (WA)' },
  { value: '509', label: '(509) - Washington (WA)' },
  { value: '715', label: '(715) - Wisconsin (WI)' },
  { value: '920', label: '(920) - Wisconsin (WI)' },
  { value: '414', label: '(414) - Wisconsin (WI)' },
  { value: '262', label: '(262) - Wisconsin (WI)' },
  { value: '608', label: '(608) - Wisconsin (WI)' },
  { value: '353', label: '(353) - Wisconsin (WI)' },
  { value: '420', label: '(420) - Wisconsin (WI)' },
  { value: '304', label: '(304) - West Virginia (WV)' },
  { value: '307', label: '(307) - Wyoming (WY)' }
];
