import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE
} from '../actions/types';

const initialState = {
  loading: false,
  contacts: [],
  error: null,
  pageCount: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_CONTACTS_REQUEST:
      return { ...state, loading: true };
    case FETCH_CONTACTS_SUCCESS:
      return {
        ...state,
        loading: false,
        contacts: action.payload.contacts,
        pageCount: action.payload.pageCount
      };
    case FETCH_CONTACTS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };
    default:
      return state;
  }
}
