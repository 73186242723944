import React, {
  useMemo,
  useEffect,
  Fragment,
  useState,
  useCallback
} from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Row,
  Spinner
} from 'reactstrap';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import { Badge } from 'reactstrap';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import _ from 'lodash';
import SubtleBadge from 'components/common/SubtleBadge';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  CompositeDecorator,
  convertFromRaw,
  ContentState,
  EditorState
} from 'draft-js';
import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import Editor from '@draft-js-plugins/editor';
import '@draft-js-plugins/mention/lib/plugin.css';
import '@draft-js-plugins/emoji/lib/plugin.css';
import editorStyles from '../compose/SimpleMentionEditor.module.scss';
import mentionsStyles from '../compose/MentionsStyles.module.scss';
import {
  deleteTemplate,
  deleteMultipleTemplates,
  listTemplates
} from 'actions/index';
import TemplateCreator from './TemplateCreator';
import ActionPopover from 'components/common/ActionPopover';
import { ADD_COMPOSE_TEMPLATE, RESET_COMPOSE_GROUP, TEMPLATE_CREATE_RESET, TEMPLATE_UPDATE_RESET, TEMPLATE_DELETE_RESET } from 'actions/types';
import { useNavigate } from 'react-router-dom';

const TemplateTableV3 = ({
  hasLabel,
  fromCompose,
  fromChat,
  fromFlow,
  showTemplateModal,
  setShowTemplateModal
}) => {
  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showEditTemplateModal, setShowEditTemplateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [temporaryId, setTemporaryId] = useState();
  const [isSelected, setIsSelected] = useState(false);

  //-------------------------LOCAL STATE------------------------------------------------------------------------------------------------------------------------------
  const userTemplates = useSelector(state => state.templates);
  const { templates: templates, loading: loadingTemplates, totalTemplates: totalTemplates } = userTemplates;
  const templateDelete = useSelector(state => state.deleteTemplate);
  const { success: successDelete } = templateDelete;
  const templateCreate = useSelector(state => state.createTemplate);
  const { success: successCreate } = templateCreate;
  const templateUpdate = useSelector(state => state.updateTemplate);
  const { success: successUpdate } = templateUpdate;
  //--------------------------REDUX STATE------------------------------------------------------------------------------------------------------------------------------

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return setIsSelected(
        selectedFlatRows.map(fr => {
          return fr.original._id;
        })
      );
    },
    [setIsSelected]
  );

  const toggleTemplate = () => {
    return setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId('');
  };

  const closeBtn = (
    <button className="btn-close bg-light" onClick={toggleTemplate}></button>
  );

  //----------------------------------------------------------------------

  const { MentionSuggestions, EmojiSuggestions, EmojiSelect, plugins } =
    useMemo(() => {
      const mentionPlugin = createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        theme: mentionsStyles,
        //mentionPrefix: '@',
        supportWhitespace: true
      });
      const emojiPlugin = createEmojiPlugin({
        useNativeArt: true
      });

      const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
      // eslint-disable-next-line no-shadow
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin, emojiPlugin];

      return { plugins, MentionSuggestions, EmojiSuggestions, EmojiSelect };
    }, [templates]);



  const handleDelete = (id) => {
    setIsSelected([id]) // is array because we are using same functionas multiple delte which accepts array
    setShowDeleteModal(!showDeleteModal)
  }

  //-------------------------------Formatters for columns-------------------------------------------------------------------------------------------------
  // <------------------ Link onClick needs to set editorState for compose message message div
  const nameFormatter = (rowData) => {
    const { title, body } = rowData.row.original;
    const composeTemplate = { body: body };
    return (
      <Media
        className="cursor-pointer"
        onClick={() => {
          return fromCompose
            ? (dispatch({
              type: ADD_COMPOSE_TEMPLATE,
              payload: { composeTemplate: composeTemplate }
            }),
              setShowTemplateModal(!showTemplateModal))
            : fromChat
              ? (dispatch({
                type: ADD_COMPOSE_TEMPLATE,
                payload: { composeTemplate: composeTemplate }
              }),
                setShowTemplateModal(!showTemplateModal))
              : fromFlow
                ? (dispatch({
                  type: ADD_COMPOSE_TEMPLATE,
                  payload: { composeTemplate: composeTemplate }
                }),
                  setShowTemplateModal(!showTemplateModal))
                : ' ';
        }}
        tag={Flex}
        align="center"
      >
        <Media body className="ms-2">
          <h5 className="mb-0 fs--1">{title}</h5>
          {fromCompose || fromChat || fromFlow ? (
            <SubtleBadge bg="success" pill className="fs-9 mt-2">
              <FontAwesomeIcon
                icon="plus"
                transform="shrink-1 down-1"
                className=" me-1"
              />
              Click to insert
            </SubtleBadge>
          ) : (
            ' '
          )}
        </Media>
      </Media>
    );
  };

  const bodyFormatter = rowData => {
    const { _id, body } = rowData.row.original;
    let editorState;
    let contentState;
    if (!body.entityMap) {
      contentState = {
        entityMap: {},
        blocks: body.blocks
      };
      editorState = EditorState.createWithContent(convertFromRaw(contentState));
    } else {
      const decorators = _.flattenDeep(
        plugins.map(plugin => plugin.decorators)
      );
      const decorator = new CompositeDecorator(
        decorators.filter((decorator, index) => index !== 1)
      );
      editorState = EditorState.createWithContent(
        convertFromRaw(body),
        decorator
      );
    }

    return (
      <Media body className="ms-2 mt-2" tag={Flex} align="center">
        <div
          style={{
            minHeight: '5em',
            minWidth: '15em',
            maxWidth: '15em',
            maxHeight: '10em',
            overflow: 'auto'
          }}
          className={`border border-2x border-300 rounded fs-9 ${editorStyles.editor}`}
        >
          <Editor
            editorKey={'editor'}
            currentContent={ContentState}
            editorState={editorState}
            readOnly={true}
            onChange={() => { }}
          />
        </div>
      </Media>
    );
  };

  const createdFormatter = rowData => {
    const { created } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ms-2">
          <Badge color="soft-success" pill className="fs-9">
            <Moment format="MM/DD/YYYY" date={created} />
          </Badge>
        </Media>
      </Media>
    );
  };



  const actionFormatter = (rowData) => {
    const { body, _id } = rowData.row.original;
    const composeTemplate = { body: body };

    const items = [
      {
        label: fromCompose || fromFlow ? 'Insert template' : 'Send SMS with this template',
        icon: 'sms',
        color: 'info',
        action: () => {
          if (fromCompose || fromChat || fromFlow) {
            dispatch({
              type: ADD_COMPOSE_TEMPLATE,
              payload: { composeTemplate },
            });
            setShowTemplateModal(!showTemplateModal);
          } else {
            dispatch({
              type: ADD_COMPOSE_TEMPLATE,
              payload: { composeTemplate },
            });
            navigate('/');
          }
        },
      },
      {
        label: 'Edit',
        icon: 'edit',
        action: () => {
          setTemporaryId(rowData.row.original);
          setShowEditTemplateModal(!showEditTemplateModal);
        },
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        className: 'text-danger',
        action: () => { handleDelete(rowData?.row?.original?._id) },
      },
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
        />
      </div>
    );
  };



  //-------------------------------------------------------------------Columns----------------------------------------------------------------------------
  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      Cell: nameFormatter
    },
    {
      accessor: 'body',
      Header: 'Body',
      Cell: bodyFormatter
    },
    {
      accessor: 'created',
      Header: 'Created',
      Cell: createdFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right'
    }
  ];

  //-------------------------------------------------------------------useEffect----------------------------------------------------------------------------


  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'created'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render

    dispatch(listTemplates({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  useEffect(() => {
    if (successCreate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: TEMPLATE_CREATE_RESET }); // Replace with your actual action to reset successUpdate
      dispatch({ type: RESET_COMPOSE_GROUP });
    }
  }, [successCreate, fetchData, dispatch]);

  useEffect(() => {
    if (successUpdate) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: TEMPLATE_UPDATE_RESET }); // Replace with your actual action to reset successUpdate
      dispatch({ type: RESET_COMPOSE_GROUP });

    }
  }, [successUpdate, fetchData, dispatch]);

  useEffect(() => {
    if (successDelete) {
      fetchData({ pageSize: 10, pageIndex: 0, sortBy: [] });
      dispatch({ type: TEMPLATE_DELETE_RESET }); // Replace with your actual action to reset successUpdate
      dispatch({ type: RESET_COMPOSE_GROUP });

    }
  }, [successDelete, fetchData, dispatch]);


  const handleSearch = (value) => {
    dispatch(
      listTemplates({ search: value, page: 1, limit: 25 })
    );
  };




  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: '0.5rem'
  };

  const spinnerStyle = {
    width: '3rem',
    height: '3rem',
    color: '#007bff'
  };



  return (
    <Fragment>
      <Card>
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <div>
                <ButtonIcon
                  onClick={() =>
                    setShowEditTemplateModal(!showEditTemplateModal)
                  }
                  icon="plus"
                  transform="shrink-3"
                  className="btn-tertiary border-dark"
                  size="sm"
                >
                  New Template
                </ButtonIcon>

                <ButtonIcon
                  onClick={() => {
                    setShowDeleteModal(!showDeleteModal);
                  }}
                  icon="trash"
                  disabled={!isSelected.length}
                  transform="shrink-3"
                  className="btn-tertiary border-dark text-danger ms-2"
                  size="sm"
                >
                  Delete
                </ButtonIcon>
              </div>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {loadingTemplates && (
            <div style={overlayStyle}>
              <Spinner animation="border" role="status" style={spinnerStyle}>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          )}
          <AdvanceTableWrapper
            columns={columns}
            data={templates}
            fetchData={fetchData}
            total={totalTemplates}
            sortable
            pagination
            perPage={10}
            selection={fromCompose ? false : true} // shows checkbox
            selectionColumnWidth={25}
            selectionCallback={selectionCallback}
          >
            <Row>
              <Col md={8} lg={6}>
                <AdvanceTableSearchBox
                  onSearch={handleSearch}
                  placeholder="Search templates"
                  table />
              </Col>
            </Row>

            <AdvanceTable
              table
              headerClassName="bg-200 text-900 font-weight-bold text-nowrap align-middle"
              rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
              tableProps={{
                striped: true,
                className: 'fs--1 font-weight-bold overflow-hidden'
              }}
            />
            <div className="mt-3 mb-2 pe-2 ps-3 ">
              <AdvanceTableFooter
                rowCount={totalTemplates}
                table
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </div>
          </AdvanceTableWrapper>
        </CardBody>
      </Card>

      <Modal
        isOpen={showEditTemplateModal}
        centered
        toggle={() => {
          return (
            setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId('')
          );
        }}
      >
        <ModalHeader close={closeBtn}>Design your template</ModalHeader>
        <ModalBody className="p-0">
          <TemplateCreator
            fromCompose={fromCompose}
            temporaryId={temporaryId}
            setTemporaryId={setTemporaryId}
            hasLabel={hasLabel}
            showEditTemplateModal={showEditTemplateModal}
            setShowEditTemplateModal={setShowEditTemplateModal}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs--1 font-weight-normal p-4">
              <Button
                onClick={() => {
                  return (
                    temporaryId
                      ? dispatch(deleteTemplate(temporaryId._id))
                      : dispatch(deleteMultipleTemplates(isSelected)),
                    setTemporaryId(''),
                    setShowDeleteModal(!showDeleteModal)
                  );
                }}
                block
                className="text-danger"
                color="light"
              >
                Delete templates
              </Button>
              <Button
                onClick={() => {
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="text-primary"
                color="light"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showEditTemplateModal}
        centered
        toggle={() => {
          return (
            setShowEditTemplateModal(!showEditTemplateModal), setTemporaryId('')
          );
        }}
      >
        <ModalHeader close={closeBtn}>Design your template</ModalHeader>
        <ModalBody className="p-0">
          <TemplateCreator
            temporaryId={temporaryId}
            setTemporaryId={setTemporaryId}
            hasLabel={hasLabel}
            showTemplateModal={showTemplateModal}
            setShowTemplateModal={setShowTemplateModal}
            showEditTemplateModal={showEditTemplateModal}
            setShowEditTemplateModal={setShowEditTemplateModal}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader toggle={() => setShowDeleteModal(!showDeleteModal)}>
          Confirm Deletion
        </ModalHeader>
        <ModalBody>
          <div className="text-center">
            <h5>Are you sure?</h5>
            <p className="text-muted fs--1">
              This action cannot be undone. All selected templates will be permanently removed.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              return (
                dispatch(deleteMultipleTemplates(isSelected)),
                setShowDeleteModal(!showDeleteModal)
              );
            }}
          >
            Delete
          </Button>
          <Button
            color="secondary"
            onClick={() => setShowDeleteModal(!showDeleteModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

    </Fragment>
  );
};

export default TemplateTableV3;
