import React, { useContext, useState, Fragment } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Form, Nav, NavItem, NavLink } from 'reactstrap';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import StepOneForm from './StepOneForm';
import StepTwoForm from './StepTwoForm';
import ImportSuccess from './ImportSuccess';
import AppContext, { NewUserUploadContext } from '../../../context/Context';
import WizardModal from '../../authentication/wizard/WizardModal';
import ButtonIcon from '../../common/ButtonIcon';

const ImporterForm = ({ setShowFirstLoginModal }) => {

  const { isRTL } = useContext(AppContext);
  const { upload, setUpload, step, setStep, userFile, setUserFile } = useContext(NewUserUploadContext); // this is basically creating these props to be
  const { register, handleSubmit, errors, watch } = useForm();
  const [checked, setChecked] = useState(false)

  const onSubmitData = data => {
    setUpload({ ...upload, ...data });
    setStep(step + 1);
  };

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleBackStep = targetStep => {
    if (step !== 3) {
      if (targetStep < step) {
        setStep(targetStep);
      }
    } else {
      toggle();
    }
  };

  return (
    <Fragment>
      <WizardModal toggle={toggle} modal={modal} setModal={setModal} />
      <Card tag={Form} onSubmit={handleSubmit(onSubmitData)} className="theme-wizard">
        <CardHeader>
          <Nav className="justify-content-center">
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done cursor-pointer': step >= 1,
                  active: step === 1
                })}
                onClick={() => handleBackStep(1)}
              >
                <span className="nav-item-circle-parent">

                </span>

              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step >= 2,
                  active: step === 2
                })}
                onClick={() => handleBackStep(2)}
              >
                <span className="nav-item-circle-parent">

                </span>

              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames('fw-semibold', {
                  'done  cursor-pointer': step >= 3
                })}
              >
                <span className="nav-item-circle-parent">

                </span>

              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
        <CardBody className="fs--1 fw-normal">
          {step === 1 && <StepOneForm register={register} errors={errors} watch={watch} userFile={userFile} setUserFile={setUserFile} />}
          {step === 2 && <StepTwoForm register={register} errors={errors} watch={watch} userFile={userFile} checked={checked} setChecked={setChecked} />}
          {step === 3 && <ImportSuccess setShowFirstLoginModal={setShowFirstLoginModal} />}
        </CardBody>
        <CardFooter className={classNames('px-md-6 d-flex')}>
          <ButtonIcon
            color="link"
            icon={isRTL ? 'chevron-right' : 'chevron-left'}
            iconAlign="left"
            transform="down-1 shrink-4"
            className={classNames('px-0 fw-semibold px-3', { 'd-none': step === 1 || step === 3 })}
            onClick={() => {
              setStep(step - 1);
            }}
          >
          </ButtonIcon>

          <ButtonIcon
            disabled={step === 1 && !upload?.companyName || step === 1 && !upload?.phoneNumber || step === 2 && !upload?.companyName}
            color="primary"
            type="submit"
            icon={isRTL ? 'chevron-left' : 'chevron-right'}
            iconAlign="right"
            transform="down-1 shrink-4"
            className={classNames("ms-auto px-3", { 'd-none': step === 3 })}
          >
          </ButtonIcon>
        </CardFooter>
      </Card>
    </Fragment>
  );
};

export default ImporterForm;