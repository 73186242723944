import React, { Fragment, useState, useRef, useContext } from 'react';
import { parse } from 'papaparse';
import { Media, Input, FormGroup, Label } from 'reactstrap';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { getPageName } from 'helpers/utils';
import { CsvUploadContext } from 'context/Context';

const StepOneForm = ({ register, errors, userFile, setUserFile }) => {
  const { upload } = useContext(CsvUploadContext) || {
    upload: {},
    setUpload: () => { }
  };
  const [highlighted, setHighlighted] = useState(false);
  const { handleInputChange } = useContext(CsvUploadContext) || {
    handleInputChange: () => { }
  };
  const InputFile = useRef(null);
  const isCustomerImport = getPageName('customer-import');

  const onButtonClick = e => {
    e.preventDefault();
    InputFile.current.click();
  };

  const manualFileUpload = e => {
    e.preventDefault();
    if (e.target.files.length) {
      setUserFile(e.target.files[0].name);
      Array.from(e.target.files)
        .filter(file => file.type === 'text/csv' || 'text/xls' || 'text/xlxs')
        .forEach(async file => {
          const text = await file.text();
          const result = parse(text);
          handleInputChange({ value: result.data, name: 'contacts' });
        });
    }
  };

  const copyPasteHandler = e => {
    e.preventDefault();

    const result = parse(e.target.value);
    handleInputChange({ value: result.data.flat(), name: 'contacts' });
  };

  return (
    <Fragment>
      <h4 className="mb-1 text-center">Import type</h4>

      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="files"
            checked={upload?.uploadType === 'files'}
            onClick={({ target }) => {
              handleInputChange(target);
            }}
            {...register('uploadType', {
              required: 'Add your numbers silly goose'
            })}
            value="files"
            name="uploadType"
            errors={errors}
          />
          <div>
            <strong>
              {isCustomerImport
                ? 'Upload a file with customer info'
                : 'Upload a file with phone numbers'}
            </strong>
          </div>
        </Label>
      </FormGroup>
      <p>Supported file types: CSV (.csv).</p>
      {upload?.uploadType === 'files' && (
        <div>
          <input
            type="file"
            onChange={e => manualFileUpload(e)}
            accept=".csv, .xls, .xlsx"
            ref={InputFile}
            className="d-none"
          />
          <div
            style={{ borderWidth: '5px', borderStyle: "dashed", padding: "1rem", borderRadius: "1rem" }}
            className={`mb-2 p-3 text-center cursor-pointer  ${highlighted ? ' border-800' : ''
              }`}
            onClick={e => onButtonClick(e)}
            onDragEnter={() => {
              setHighlighted(true);
            }}
            onDragLeave={() => {
              setHighlighted(false);
            }}
            onDragOver={e => {
              e.preventDefault();
            }}
            onDrop={e => {
              e.preventDefault();
              setUserFile(e.dataTransfer.files[0].name);
              Array.from(e.dataTransfer.files)
                .filter(
                  file => file.type === 'text/csv' || 'text/xls' || 'text/xlxs'
                )
                .forEach(async file => {
                  const text = await file.text();
                  const result = parse(text);
                  handleInputChange({ value: result.data, name: 'contacts' });
                });
            }}
          >
            <Fragment>
              <Media className="fs-0 mx-auto d-inline-flex align-items-center">
                <img src={cloudUpload} alt="" width={25} className="mr-2" />
                <Media>
                  <p
                    style={{ width: '60%' }}
                    className="fs-0 mb-0 text-700 text-wrap"
                  >
                    {userFile ? userFile : 'Upload your list'}
                  </p>
                </Media>
              </Media>
              <p className="mb-0 w-75 mx-auto text-500">
                Supported file types: CSV (.csv)
              </p>
            </Fragment>
          </div>
        </div>
      )}

      <FormGroup check>
        <Label check>
          <Input
            type="radio"
            id="copy-paste-numbers"
            checked={upload?.uploadType === 'copy-paste-numbers'}
            onClick={({ target }) => {
              handleInputChange(target);
            }}
            {...register('uploadType')}
            value="copy-paste-numbers"
            name="uploadType"
            errors={errors}
          />
          <div>
            <strong>Copy and paste phone numbers</strong>
          </div>
        </Label>
      </FormGroup>
      <p>Paste numbers and separate with commas.</p>

      {upload?.uploadType === 'copy-paste-numbers' && (
        <div>
          <Input
            type="textarea"
            placeholder="+17209908576, +18165009878, +19138683784"
            name="manual-add"
            rows="4"
            id="manual-add"
            onChange={e => {
              copyPasteHandler(e);
            }}
            {...register('manual-add', { required: 'Add your numbers' })}
            errors={errors}
          />
        </div>
      )}
    </Fragment>
  );
};

export default StepOneForm;
