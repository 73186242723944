import React, { useEffect, Fragment, useState, useCallback } from 'react';
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Media,
  Row,
  Modal,
  Label,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import ButtonIcon from 'components/common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import Moment from 'react-moment';
import Loader from 'components/common/Loader';
import CompletedEventModal from './CompletedEventModal';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import {
  listCompletedCalendar,
  resumeMultipleCalendars,
  deleteMultipleCalendars
} from 'actions/index';
import ActionPopover from 'components/common/ActionPopover';
import { CALENDAR_UPDATE_RESET } from 'actions/types';

const CompletedTable = () => {
  //--------------------------------------------------------------------------State------------------------------------------------------------------------------------
  const dispatch = useDispatch();

  const completedCalendar = useSelector(state => state.completedCalendar);
  const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
  const allGroups = useSelector(state => state.groups);
  const { groups: groups } = allGroups;
  const pageSize = useSelector(state => state.paginationRedux.pageSize);


  const updateCalendar = useSelector(state => state.calendarUpdate);
  const { success: successUpdate } = updateCalendar;

  const deleteCalendar = useSelector(state => state.calendarDelete);
  const { success: successDelete } = deleteCalendar;

  const [isSelected, setIsSelected] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showResumeModal, setShowResumeModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [modalEventContent, setModalEventContent] = useState();
  const [rowDelete, setRowDelete] = useState();

  const selectionCallback = useCallback(
    selectedFlatRows => {
      return (
        setIsSelected(
          selectedFlatRows.map(fr => {
            return fr.original._id;
          })
        ),
        setRowDelete([])
      );
    },
    [setIsSelected]
  );

  //--------------------------------------------------------------------------Formatters for columns------------------------------------------------------------------------------------

  const titleFormatter = rowData => {
    const { title, _id } = rowData.row.original;

    return (
      <Media
        tag={Flex}
        align="center"
        className="cursor-pointer"
        onClick={() => {
          return (
            setModalEventContent(rowData.row.original),
            setShowDetailModal(!showDetailModal)
          );
        }}
      >
        <Media body className="ml-2 mt-2">
          <h5 className="mb-0 fs-9">{title}</h5>
        </Media>
      </Media>
    );
  };

  const toFormatter = rowData => {
    const { messageDetails, contactLength } = rowData.row.original;

    console.log("messageDetails", messageDetails)

    return (
      <Row className="mt-2">
        <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 1 }}>
          {contactLength?.length ? (
            <Badge
              color="primary"
              className="m-2 p-0"
              style={{ display: 'inline-flex', width: 'auto' }}
            >
              <Card className="m-1" style={{ width: 'fit-content' }}>
                <CardBody
                  style={{
                    padding: '.15rem .8rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <FontAwesomeIcon icon="user" className="mx-2" size="sm" />
                  <CardTitle
                    tag="h6"
                    className="m-0"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Contacts
                  </CardTitle>
                  <Badge
                    color="secondary"
                    className="ms-2"
                    style={{ fontSize: '0.8rem' }}
                  >
                    {contactLength?.length}
                  </Badge>
                </CardBody>
              </Card>
            </Badge>
          ) : (
            ''
          )}
          {messageDetails.groups.map(group => {
            return (
              <div>
                <Badge
                  color="success"
                  className="m-2 p-0"
                  style={{ display: 'inline-flex', width: 'auto' }}
                >
                  <Card className="m-1" style={{ width: 'fit-content' }}>
                    <CardBody
                      style={{
                        padding: '.15rem .8rem',
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <FontAwesomeIcon
                        icon="users"
                        className="mx-2"
                        size="sm"
                      />
                      <CardTitle
                        tag="h6"
                        className="m-0"
                        style={{ fontSize: '0.8rem' }}
                      >
                        {group.title}
                      </CardTitle>
                      <Badge
                        color="secondary"
                        className="ms-2"
                        style={{ fontSize: '0.8rem' }}
                      >
                        {group.contactsCount}
                      </Badge>
                    </CardBody>
                  </Card>
                </Badge>
              </div>
            );
          })}
        </Col>
      </Row>
    );
  };

  const repeatCampaignFormatter = rowData => {
    const { repeatCampaign } = rowData.row.original;
    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mt-2">{repeatCampaign}</h5>
        </Media>
      </Media>
    );
  };

  const startDateFormatter = rowData => {
    const { start } = rowData.row.original;

    return (
      <Media tag={Flex} align="center">
        <Media body className="ml-2">
          <h5 className="mb-0 fs-9 mt-2">
            <Moment format="MM/DD/YYYY" date={start} />
          </h5>
        </Media>
      </Media>
    );
  };


  const actionFormatter = (rowData) => {
    const { _id } = rowData.row.original;

    const items = [
      {
        label: 'View Details',
        icon: 'eye',
        action: () => {
          return (
            setModalEventContent(rowData.row.original),
            setShowDetailModal(!showDetailModal)
          );
        }
      },
      {
        label: 'Delete',
        icon: 'trash',
        color: 'danger',
        action: () => {
          return (
            setRowDelete([rowData?.row?.original?._id]),
            setShowDeleteModal(!showDeleteModal)
          );
        }
      }
    ];

    return (
      <div className="d-flex justify-content-end">
        <ActionPopover
          id={_id}
          items={items}
        />
      </div>
    );
  };



  //--------------------------------------------------------------------------Columns------------------------------------------------------------------------------------

  const columns = [
    {
      accessor: 'title',
      Header: 'Title',
      Cell: titleFormatter
    },

    {
      accessor: 'messageDetails',
      Header: 'To',
      Cell: toFormatter
    },
    {
      accessor: 'repeatCampaign',
      Header: 'Type',
      Cell: repeatCampaignFormatter
    },
    {
      accessor: 'start',
      Header: 'Start date',
      Cell: startDateFormatter
    },
    {
      accessor: 'actions',
      Cell: actionFormatter,
      sticky: 'right'
    }
  ];

  //--------------------------------------------------------------------------useEffect------------------------------------------------------------------------------------

  const fetchData = useCallback(({ pageSize, pageIndex, sortBy }) => {
    const sortColumn = sortBy && sortBy.length > 0 ? sortBy[0].id : 'createdAt'; // Default to 'createdAt' if no sort
    const sortDirection = sortBy && sortBy.length > 0 ? (sortBy[0].desc ? -1 : 1) : -1; // Default to descending on first render
    dispatch(listCompletedCalendar({ search: "", page: pageIndex + 1, limit: pageSize, sortBy: sortColumn, sortOrder: sortDirection }));

  }, [dispatch])

  const handleSearch = (value) => {
    dispatch(listCompletedCalendar({ search: value, page: 1, limit: pageSize }));
  };



  useEffect(() => {
    dispatch(listCompletedCalendar({ search: "", page: 1, limit: pageSize }));
    dispatch({ type: CALENDAR_UPDATE_RESET });
  }, [successUpdate, successDelete]);


  useEffect(() => {
    setRowDelete([]);
  }, []);

  //--------------------------------------------------------------------------Render------------------------------------------------------------------------------------

  return (
    <Fragment>
      <Card className="mb-3">
        <CardHeader>
          <div className="d-flex justify-content-end">
            <ButtonGroup className="mt-2">
              <ButtonIcon
                icon="play"
                onClick={() => {
                  return setShowResumeModal(!showResumeModal);
                }}
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark"
                size="sm"
              >
                Resume
              </ButtonIcon>
              <ButtonIcon
                icon="trash"
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal);
                }}
                disabled={!isSelected.length}
                transform="shrink-3"
                className="btn-tertiary border-dark text-danger ms-2"
                size="sm"
              >
                Delete
              </ButtonIcon>
            </ButtonGroup>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          {!completedCalendar ? (
            <div>
              <Loader />
            </div>
          ) : (
            <Fragment>
              <Card>
                <AdvanceTableWrapper
                  columns={columns}
                  fetchData={fetchData}
                  data={completedCalendar}
                  sortable
                  pagination
                  perPage={12}
                  selection
                  selectionColumnWidth={25}
                  selectionCallback={selectionCallback}
                >
                  <Row>
                    <Col md={8} lg={6}>
                      <AdvanceTableSearchBox table
                        placeholder="Search Campaigns..."
                        onSearch={handleSearch}
                      />
                    </Col>
                  </Row>

                  <AdvanceTable
                    table
                    headerClassName="bg-200 text-900 fw-bold text-nowrap align-middle"
                    rowClassName="btn-reveal-trigger border-top border-200 align-middle white-space-nowrap"
                    tableProps={{
                      striped: true,
                      className: 'fs-9 fw-bold overflow-hidden'
                    }}
                  />
                  <div className="mt-3 mb-2 pe-2 ps-3 ">
                    <AdvanceTableFooter
                      rowCount={completedCalendar?.length}
                      table
                      rowInfo
                      navButtons
                      rowsPerPageSelection
                    />
                  </div>
                </AdvanceTableWrapper>
              </Card>
            </Fragment>
          )}
        </CardBody>
      </Card>

      <Modal
        isOpen={showResumeModal}
        centered
        toggle={() => setShowResumeModal(!showResumeModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 fw-normal p-4">
              <Label className="text-center">
                Selected Campaigns will be set to active and placed back in
                Calendar
              </Label>
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    dispatch(resumeMultipleCalendars(rowDelete));
                  } else {
                    dispatch(resumeMultipleCalendars(isSelected));
                  }
                  setShowResumeModal(!showResumeModal);
                }}
                block
                className="btn-tertiary border-dark text-success"
              >
                Resume Selected Campaigns
              </Button>
              <Button
                onClick={() => {
                  return setShowResumeModal(!showResumeModal), setRowDelete([]);
                }}
                block
                className="btn-tertiary border mt-2"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={showDeleteModal}
        centered
        toggle={() => setShowDeleteModal(!showDeleteModal)}
      >
        <ModalHeader className="d-flex justify-content-center">
          Are you sure?
        </ModalHeader>
        <ModalBody className="p-0">
          <Card>
            <CardBody className="fs-9 fw-normal p-4">
              <Button
                onClick={() => {
                  if (rowDelete?.length) {
                    dispatch(deleteMultipleCalendars(rowDelete));
                  } else {
                    dispatch(deleteMultipleCalendars(isSelected));
                  }
                  setShowDeleteModal(!showDeleteModal);
                }}
                block
                className="btn-tertiary border-dark text-danger"
              >
                Delete Selected Campaigns
              </Button>
              <Button
                onClick={() => {
                  return setShowDeleteModal(!showDeleteModal), setRowDelete([]);
                }}
                block
                className="btn-tertiary border mt-2"
              >
                Cancel
              </Button>
            </CardBody>
          </Card>
        </ModalBody>
      </Modal>

      <CompletedEventModal
        fields={fields}
        groups={groups}
        showDetailModal={showDetailModal}
        setShowDetailModal={setShowDetailModal}
        modalEventContent={modalEventContent}
      />
    </Fragment>
  );
};

export default CompletedTable;
