import React, {
    useState,
    useEffect,
    useContext,
    forwardRef,
    useCallback
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Button,
    ButtonGroup,
    Col,
    Card,
    CardBody,
    CardFooter,
    Modal,
    ModalHeader,
    ModalBody,
    FormGroup,
    Label,
    Input,
    Row,
    UncontrolledDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Spinner
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import Divider from 'components/common/Divider';
import Select from 'react-select';
import Moment from 'react-moment';
import moment from 'moment';
import momentt from 'moment-timezone';
import DatePicker from 'react-datepicker';
import { calendarCreate } from 'actions';
import makeAnimated from 'react-select/animated';
import AppContext from 'context/Context';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { v4 as uuidv4 } from 'uuid';
import {
    RESET_EVENT,
    CALENDAR_CREATE_RESET,
    RESET_CREATE_BULK,
    CREATE_BULK_INITIAL_STATE,
    UPDATE_DAY_COUNT,
    UPDATE_WEEK_COUNT,
    UPDATE_WITH_EVENT,
    UPDATE_START_DATE,
    UPDATE_CAMPAIGN_REPEAT,
    UPDATE_END_TYPE,
    UPDATE_DAY_TIMES,
    UPDATE_SESSIONS,
    UPDATE_BULK_TITLE,
    UPDATE_BULK_CLASSNAME,
    UPDATE_BULK_TIMEZONE,
    UPDATE_END_DATE,
    UPDATE_WEEK_DAYS,
    UPDATE_WEEK_TIMES,
    UPDATE_MONTH_ON_THE,
    UPDATE_MONTH_TIMES,
    UPDATE_MONTH_ON_DAY,
    UPDATE_CAMPAIGN_TYPE,
    UPDATE_HOUR_CYCLES
} from 'actions/types';
import PreviewAndSend from './PreviewAndSend';
import SuccessAdd from './SuccessAdd';

const ScheduleTimev2 = ({
    showTimeModal,
    setShowTimeModal,
    setShowCalendar,
    recipients
}) => {

    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const { isDark } = useContext(AppContext);



    const auth = useSelector(state => state.auth);
    const { fields, loading: fieldsLoading } = useSelector(state => state.fields);
    const createBulk = useSelector(state => state.createBulk);

    const userCalendarCreate = useSelector(state => state.calendarCreate);
    const { success: successCreateCalendar, loading: loadingCalendarCreate } = userCalendarCreate;

    const weekDaysArr = [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ];
    const [weeksToTrigger, setWeeksToTrigger] = useState(1);
    const [daysToTrigger, setDaysToTrigger] = useState(1);
    const [monthDayState, setMonthDayState] = useState(1);
    const [textSession, setTextSession] = useState(1);
    const [endDate, setEndDate] = useState();
    const [startDate, setStartDate] = useState();
    const [timezone, setTimezone] = useState();
    const [initialTimezone, setInitialTimezone] = useState();
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [timezones, setTimezones] = useState();
    const windowSize = useWindowDimensions();



    // Centralized cleanup function
    const handleCleanup = () => {
        // Dispatch necessary Redux actions
        dispatch({ type: RESET_CREATE_BULK });
        dispatch({
            type: CREATE_BULK_INITIAL_STATE,
            payload: { timezone: auth?.user?.timezone || 'America/Denver' },
        });
        dispatch({ type: CALENDAR_CREATE_RESET });
        // Reset local state
        setStartDate(new Date());
        setShowCalendar(false);
        setShowTimeModal(false);
        setShowSuccessModal(false);
    };


    const closePreviewBtn = (
        <button
            className="btn-close bg-light"
            onClick={() => {
                setShowPreviewModal(!showPreviewModal);
            }}
        >
        </button>
    );

    const closeSuccessBtn = (
        <button
            className="btn-close bg-light"
            onClick={handleCleanup}
            aria-label="Close"
        ></button>
    );

    useEffect(() => {
        if (successCreateCalendar) {
            dispatch({ type: CALENDAR_CREATE_RESET });
            setShowSuccessModal(!showSuccessModal);
        }
    }, [successCreateCalendar, dispatch]);

    useEffect(() => {
        setTimezone(auth?.user?.timezone);
        setInitialTimezone(auth?.user?.timezone);
        dispatch({ type: UPDATE_BULK_TIMEZONE, payload: auth?.user?.timezone });
    }, [auth?.user?.timezone, createBulk?.timezone, dispatch]);

    useEffect(() => {
        const timeZones = momentt.tz.names();
        const offsetTmz = [];

        for (var i in timeZones) {
            offsetTmz.push({
                UTC:
                    ' (GMT' + momentt.tz(timeZones[i]).format('Z') + ') ' + timeZones[i],
                zoneTitle: timeZones[i]
            });
        }
        setTimezones(offsetTmz);
    }, []);

    //-------------------------------------------------------------------------Change handlers------------------------------------------------------------------------

    const handleChange = target => {
        dispatch({ type: UPDATE_BULK_TITLE, payload: target.value });
    };

    const handleMonthDayChange = e => {
        const value = e.target.value.replace(/\D/g, '');
        if (value < 31 && value) {
            setMonthDayState(parseInt(value));
        }
    };

    //---------Month view On x day of month
    const incrementMonthDay = () => {
        const value = monthDayState + 1;
        if (value < 32 && value > 0) {
            setMonthDayState(value);
        }
    };

    const decrementMonthDay = () => {
        const value = monthDayState - 1;
        if (value < 32 && value > 0) {
            setMonthDayState(value);
        }
    };

    useEffect(() => {
        dispatch({ type: UPDATE_MONTH_ON_DAY, payload: monthDayState });
    }, [monthDayState]);

    //----------Days to trigger
    const incrementDays = () => {
        const value = daysToTrigger + 1;
        if (value < 32 && value > 0) {
            setDaysToTrigger(value);
        }
    };

    const decrementDays = () => {
        const value = daysToTrigger - 1;
        if (value < 52 && value > 0) {
            setDaysToTrigger(value);
        }
    };

    useEffect(() => {
        dispatch({ type: UPDATE_DAY_COUNT, payload: daysToTrigger });
    }, [daysToTrigger]);

    //----------Weeks to trigger
    const incrementWeeks = () => {
        const value = weeksToTrigger + 1;
        if (value < 32 && value > 0) {
            setWeeksToTrigger(value);
        }
    };

    const decrementWeeks = () => {
        const value = weeksToTrigger - 1;
        if (value < 52 && value > 0) {
            setWeeksToTrigger(value);
        }
    };

    useEffect(() => {
        dispatch({ type: UPDATE_WEEK_COUNT, payload: weeksToTrigger });
    }, [weeksToTrigger]);

    //----------SMS sessions

    const handleSessionChange = e => {
        const value = e.target.value.replace(/\D/g, '');
        if (value < 99 && value) {
            setTextSession(parseInt(value));
        }
    };

    const incrementSession = () => {
        const value = textSession + 1;
        if (value < 99 && value > 0) {
            setTextSession(value);
        }
    };

    const decrementSession = () => {
        const value = textSession - 1;
        if (value < 99 && value > 0) {
            setTextSession(value);
        }
    };

    useEffect(() => {
        dispatch({ type: UPDATE_SESSIONS, payload: textSession });
    }, [textSession]);

    //-------------------------------------------------------------------------React-select-styles------------------------------------------------------------------------

    const customStyles = {
        control: (base, state) => ({
            ...base,
            backgroundColor: isDark ? 'dark' : 'light',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            // match with the menu
            borderColor: isDark ? 'dark' : 'light'
            // Removes weird border around container
        }),
        input: base => ({
            ...base,
            color: isDark ? 'dark' : 'light'
        }),
        placeholder: base => ({
            ...base,
            color: '#2c7be5',
            fontWeight: 'bold'
        }),
        option: (base, state) => ({
            ...base,
            color: '#d8e2ef', //
            backgroundColor: isDark ? 'dark' : 'light',
            fontWeight: state.isSelected ? 'bold' : 'normal',
            backgroundColor: state.isFocused ? '#2c7be5' : '#0b1727'
        }),
        singleValue: base => ({
            // single value determines color of text after selection
            ...base,
            color: isDark ? 'dark' : 'light',
            fontWeight: 'bold'
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            background: isDark ? 'dark' : 'light',
            // kill the gap
            marginTop: 15
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option

            background: isDark ? 'dark' : 'light'
        })
    };

    //-------------------------------------------------------------------------Use Effects------------------------------------------------------------------------

    useEffect(() => {
        const newD = new Date(startDate);
        setEndDate(newD.setHours(newD.getHours() + 1));
    }, [startDate]);



    const convertTriggerTime = useCallback((triggerTime, fromTz, toTz) => {
        const ttDate = new Date(triggerTime);
        const getISO = ttDate.toISOString();
        const getAuthTimezone = moment.tz(getISO.replace('Z', ''), fromTz).format();
        const removeInitialTimezoneOffset = ttDate.setTime(
            ttDate.getTime() + moment.parseZone(getAuthTimezone).utcOffset() * 60_000
        );
        const timeWithoutInitialOffset = new Date(removeInitialTimezoneOffset)
            .toISOString()
            .replace('Z', '');
        const toTzTime = moment.tz(timeWithoutInitialOffset, toTz).format();
        return moment.utc(toTzTime).format();
    }, []);

    useEffect(() => {
        if (timezone !== initialTimezone) {
            const getNewStartUTCTime = convertTriggerTime(
                createBulk.startDate,
                initialTimezone,
                timezone
            );
            dispatch({
                type: UPDATE_START_DATE,
                payload: { start: createBulk.start, startDate: getNewStartUTCTime }
            });

            const updateTriggerTimes = triggerTimes => {
                return triggerTimes.map(el => {
                    const getNewUTCTime = convertTriggerTime(
                        el.trigger_time,
                        initialTimezone,
                        timezone
                    );
                    return { ...el, trigger_time: getNewUTCTime };
                });
            };

            if (createBulk?.repeatCampaign === 'Hourly') {
                dispatch({
                    type: UPDATE_HOUR_CYCLES,
                    payload: updateTriggerTimes(createBulk?.repeatCampaignHourCycles)
                });
            }

            if (createBulk?.repeatCampaign === 'Daily') {
                dispatch({
                    type: UPDATE_DAY_TIMES,
                    payload: updateTriggerTimes(createBulk?.repeatCampaignDayTimes)
                });
            }

            if (createBulk?.repeatCampaign === 'Weekly') {
                let newWeekdayTimes = {};
                for (let [key, value] of Object.entries(
                    createBulk.repeatCampaignWeekdayTimes
                )) {
                    newWeekdayTimes = {
                        ...newWeekdayTimes,
                        [key]: updateTriggerTimes(value)
                    };
                }
                dispatch({ type: UPDATE_WEEK_TIMES, payload: newWeekdayTimes });
            }

            if (createBulk?.repeatCampaign === 'Monthly') {
                dispatch({
                    type: UPDATE_MONTH_TIMES,
                    payload: updateTriggerTimes(createBulk?.repeatCampaignMonthTimes)
                });
            }

            setInitialTimezone(timezone);
        }
    }, [
        timezone,
        createBulk.repeatCampaign,
        initialTimezone,
        convertTriggerTime,
        dispatch
    ]); //createBulk

    // Input for Select Start time=============
    const ExampleCustomInputStart = forwardRef(({ value, onClick }, ref) => {
        return (
            <Button
                className={'rounded-capsule btn-tertiary border-dark w-100 '}
                onClick={onClick}
                ref={ref}
            >
                <Moment format="MM-DD-YYYY hh:mm a" trim date={startDate}></Moment>
            </Button>
        );
    });

    const ExampleCustomInputEnd = forwardRef(({ value, onClick }, ref) => (
        <Button
            className={'rounded-capsule btn-tertiary border-dark w-100 '}
            onClick={onClick}
            ref={ref}
        >
            <Moment format="MM-DD-YYYY hh:mm a" trim date={endDate}></Moment>
        </Button>
    ));

    const filterTime = date => {
        const isPastTime = new Date().getTime() > date.getTime();
        return !isPastTime;
    };

    const filterEndTime = date => {
        //    const newD = new Date(startDate)
        // setEndDate(newD.setHours(newD.getHours() + 1))
        const isPastTime = new Date(startDate).getTime() > date.getTime();
        return !isPastTime;
    };

    const handleTriggerDayInputChange = e => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            setDaysToTrigger(value);
        }
    };

    const handleTriggerWeekInputChange = e => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value)) {
            setWeeksToTrigger(value);
        }
    };


    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        borderRadius: '0.5rem'
    };

    const spinnerStyle = {
        width: '3rem',
        height: '3rem',
        color: '#007bff'
    };


    //-------------------------------------------------------------------------HTML------------------------------------------------------------------------

    return (
        <div>
            <Card>
                <CardBody>
                    {loadingCalendarCreate || fieldsLoading && (
                        <div style={overlayStyle}>
                            <Spinner animation="border" role="status" style={spinnerStyle}>
                                <span className="visually-hidden">loading...</span>
                            </Spinner>
                        </div>
                    )}



                    <FormGroup floating>
                        <Input
                            name="title"
                            id="eventTitle"
                            required
                            onChange={({ target }) => handleChange(target)}
                        />
                        <Label for="eventTitle">Campaign Title</Label>
                    </FormGroup>

                    <FormGroup>
                        <Col className="col-xs-4">
                            <Divider className="mt-4 fs-2">
                                <strong>
                                    <Label className="fs-0" for="eventStart">
                                        First Send Date
                                    </Label>
                                </strong>
                            </Divider>

                            <DatePicker
                                showPopperArrow={false}
                                selected={startDate || new Date()} // Ensure there's a default value
                                onChange={(date) => {
                                    const newD = new Date(date);
                                    const applyOffset = newD.setTime(
                                        newD.getTime() - newD.getTimezoneOffset() * 60_000
                                    );
                                    const actualTime = new Date(applyOffset)
                                        .toISOString()
                                        .replace("Z", "");
                                    const toTz = momentt.tz(actualTime, timezone).format();
                                    const getUTCTime = momentt.utc(toTz).format();

                                    // Update the `startDate` state and dispatch it to the reducer
                                    setStartDate(date);
                                    dispatch({
                                        type: UPDATE_START_DATE,
                                        payload: {
                                            start: getUTCTime,
                                            startDate: getUTCTime,
                                        },
                                    });
                                }}
                                minDate={moment().toDate()}
                                customInput={<ExampleCustomInputStart />}
                                timeIntervals={5}
                                showTimeSelect
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                filterTime={filterTime}
                            />
                        </Col>
                    </FormGroup>


                    <FormGroup>
                        <Divider className="mt-4 fs-2">
                            <strong>
                                {' '}
                                <Label className="fs-0">Repeat Campaign</Label>{' '}
                            </strong>
                        </Divider>
                        <Row>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    size="md"
                                    type="button"
                                    className="fs-9 btn-tertiary border-dark"
                                    id="noRepeat"
                                    value={createBulk?.repeatCampaign}
                                    name="repeatCampaign"
                                >
                                    {createBulk?.repeatCampaign === 'noRepeat'
                                        ? " Don't Repeat (Trigger only once) "
                                        : createBulk?.repeatCampaign === 'Hourly'
                                            ? ' Hourly '
                                            : createBulk?.repeatCampaign === 'Daily'
                                                ? ' Daily '
                                                : createBulk?.repeatCampaign === 'Weekly'
                                                    ? ' Weekly '
                                                    : createBulk?.repeatCampaign === 'Monthly'
                                                        ? ' Monthly '
                                                        : ''}
                                    <FontAwesomeIcon
                                        icon="chevron-down"
                                        transform="shrink-1 down-1"
                                    />
                                </DropdownToggle>
                                <DropdownMenu className="border py-2 ms-3">
                                    <DropdownItem
                                        onClick={() => {
                                            return dispatch({
                                                type: UPDATE_CAMPAIGN_REPEAT,
                                                payload: 'noRepeat'
                                            });
                                        }}
                                        id="noRepeat"
                                        value="noRepeat"
                                        name="repeatCampaign"
                                    >{` None (Trigger only once) `}</DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            return dispatch({
                                                type: UPDATE_CAMPAIGN_REPEAT,
                                                payload: 'Hourly'
                                            });
                                        }}
                                        id="Hourly"
                                        value="Hourly"
                                        name="repeatCampaign"
                                    >{` Hourly `}</DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            return dispatch({
                                                type: UPDATE_CAMPAIGN_REPEAT,
                                                payload: 'Daily'
                                            });
                                        }}
                                        id="Daily"
                                        value="Daily"
                                        name="repeatCampaign"
                                    >{` Daily `}</DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            return dispatch({
                                                type: UPDATE_CAMPAIGN_REPEAT,
                                                payload: 'Weekly'
                                            });
                                        }}
                                        id="Weekly"
                                        value="Weekly"
                                        name="repeatCampaign"
                                    >{` Weekly `}</DropdownItem>
                                    <DropdownItem
                                        onClick={() => {
                                            return dispatch({
                                                type: UPDATE_CAMPAIGN_REPEAT,
                                                payload: 'Monthly'
                                            });
                                        }}
                                        id="Monthly"
                                        value="Monthly"
                                        name="repeatCampaign"
                                    >{` Monthly `}</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Row>
                    </FormGroup>

                    {
                        //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------HOUR VIEW-----------------------------------------------------------------------------------------------------------

                        createBulk?.repeatCampaign === 'Hourly' && (
                            <div>
                                {createBulk?.repeatCampaignHourCycles?.map((el, i) => {
                                    const CustomInputHourStart = forwardRef(
                                        ({ value, onClick }, ref) => (
                                            <Button
                                                className={
                                                    isDark
                                                        ? 'btn-tertiary border-dark w-100'
                                                        : 'rounded-capsule btn-tertiary border-dark w-100'
                                                }
                                                onClick={onClick}
                                                ref={ref}
                                            >
                                                <Moment
                                                    format="hh:mm a"
                                                    trim
                                                    date={new Date(el.dpTime)}
                                                ></Moment>
                                            </Button>
                                        )
                                    );

                                    return (
                                        <div key={i}>
                                            <Row className="mt-2">
                                                <Col className="col-3">
                                                    <Label>Run</Label>
                                                </Col>
                                                <Col className="col-6">
                                                    <DatePicker
                                                        key={i}
                                                        selected={new Date(el.dpTime)}
                                                        showPopperArrow={false}
                                                        onChange={date => {
                                                            const ttDate = new Date(date);
                                                            const applyOffset = ttDate.setTime(
                                                                ttDate.getTime() -
                                                                ttDate.getTimezoneOffset() * 60_000
                                                            );
                                                            const actualTime = new Date(applyOffset)
                                                                .toISOString()
                                                                .replace('Z', '');
                                                            const toTz = momentt
                                                                .tz(actualTime, timezone)
                                                                .format();
                                                            const getUTCTime = momentt.utc(toTz).format();

                                                            return dispatch({
                                                                type: UPDATE_HOUR_CYCLES,
                                                                payload: createBulk?.repeatCampaignHourCycles.map(
                                                                    obj => {
                                                                        if (obj.uid === el.uid) {
                                                                            return {
                                                                                trigger_time: getUTCTime,
                                                                                dpTime: date,
                                                                                uid: obj.uid,
                                                                                triggered: false
                                                                            };
                                                                        } else {
                                                                            return obj;
                                                                        }
                                                                    }
                                                                )
                                                            });
                                                        }}
                                                        customInput={<CustomInputHourStart />}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                    />
                                                </Col>
                                                <Col className="col-3">
                                                    <Button
                                                        onClick={() => {
                                                            dispatch({
                                                                type: UPDATE_HOUR_CYCLES,
                                                                payload:
                                                                    createBulk?.repeatCampaignHourCycles.filter(
                                                                        obj => {
                                                                            return obj.uid !== el.uid;
                                                                        }
                                                                    )
                                                            });
                                                        }}
                                                        className={
                                                            'btn-tertiary border-dark text-danger w-100'
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="times"
                                                            transform="shrink-1 down-1"
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}

                                <Row className="mt-2">
                                    <Col>
                                        <Button
                                            onClick={() => {
                                                const hourCycles =
                                                    createBulk?.repeatCampaignHourCycles ?? [];
                                                if (hourCycles?.length < 24) {
                                                    dispatch({
                                                        type: UPDATE_HOUR_CYCLES,
                                                        payload: [
                                                            ...hourCycles,
                                                            {
                                                                trigger_time: new Date(),
                                                                dpTime: new Date().toISOString(),
                                                                uid: uuidv4(),
                                                                triggered: false
                                                            }
                                                        ]
                                                    });
                                                }
                                            }}
                                            className="btn-tertiary border-dark w-20"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                transform="shrink-2"
                                                className="me-2 text-success"
                                            />
                                            Time
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }

                    {
                        //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------
                        //---------------------------------------------------------------DAY VIEW-----------------------------------------------------------------------------------------------------------

                        createBulk?.repeatCampaign === 'Daily' && (
                            <div>
                                <ButtonGroup>
                                    <Label className="pt-2 pe-2">Trigger Every</Label>
                                    <Input
                                        type="number"
                                        min="0"
                                        value={daysToTrigger}
                                        onChange={handleTriggerDayInputChange}
                                        className="border-dark"
                                        style={{
                                            textAlign: 'center',
                                            maxWidth: '60px',
                                            display: 'inline-block'
                                        }}
                                    />
                                    <Button
                                        onClick={decrementDays}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon icon="chevron-down" transform="shrink-1" />
                                    </Button>
                                    <Button
                                        onClick={incrementDays}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon icon="chevron-up" transform="shrink-1" />
                                    </Button>
                                    <Label className="pt-2 ps-2">Days</Label>
                                </ButtonGroup>

                                {createBulk?.repeatCampaignDayTimes?.map((el, i) => {
                                    const CustomInputDayTimes = forwardRef(
                                        ({ value, onClick }, ref) => (
                                            <Button
                                                className="btn-tertiary border-dark w-100"
                                                onClick={onClick}
                                                ref={ref}
                                            >
                                                <Moment
                                                    format="hh:mm a"
                                                    trim
                                                    date={new Date(el.dpTime)}
                                                ></Moment>
                                            </Button>
                                        )
                                    );

                                    return (
                                        <div key={i}>
                                            <Row className="mt-2">
                                                <Col className="col-3">
                                                    <Label>Run</Label>
                                                </Col>
                                                <Col className="col-6">
                                                    <DatePicker
                                                        key={i}
                                                        selected={new Date(el.dpTime)}
                                                        showPopperArrow={false}
                                                        onChange={date => {
                                                            const ttDate = new Date(date);
                                                            const applyOffset = ttDate.setTime(
                                                                ttDate.getTime() -
                                                                ttDate.getTimezoneOffset() * 60_000
                                                            );
                                                            const actualTime = new Date(applyOffset)
                                                                .toISOString()
                                                                .replace('Z', '');
                                                            const toTz = momentt
                                                                .tz(actualTime, timezone)
                                                                .format();
                                                            const getUTCTime = momentt.utc(toTz).format();

                                                            return dispatch({
                                                                type: UPDATE_DAY_TIMES,
                                                                payload: createBulk?.repeatCampaignDayTimes.map(
                                                                    obj => {
                                                                        if (obj.uid === el.uid) {
                                                                            return {
                                                                                trigger_time: getUTCTime,
                                                                                dpTime: date,
                                                                                uid: obj.uid,
                                                                                triggered: false
                                                                            };
                                                                        } else {
                                                                            return obj;
                                                                        }
                                                                    }
                                                                )
                                                            });
                                                        }}
                                                        customInput={<CustomInputDayTimes />}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                    />
                                                </Col>
                                                <Col className="col-3">
                                                    <Button
                                                        onClick={() => {
                                                            dispatch({
                                                                type: UPDATE_DAY_TIMES,
                                                                payload:
                                                                    createBulk?.repeatCampaignDayTimes.filter(
                                                                        obj => {
                                                                            return obj.uid !== el.uid;
                                                                        }
                                                                    )
                                                            });
                                                        }}
                                                        className="btn-tertiary border-dark text-danger"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="times"
                                                            transform="shrink-1 down-1"
                                                        />
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    );
                                })}

                                <Row className="mt-2">
                                    <Col>
                                        <Button
                                            onClick={() => {
                                                const dayTimes = createBulk?.repeatCampaignDayTimes || [];
                                                if (dayTimes?.length < 24) {
                                                    dispatch({
                                                        type: UPDATE_DAY_TIMES,
                                                        payload: [
                                                            ...dayTimes,
                                                            {
                                                                trigger_time: startDate,
                                                                dpTime: new Date().toISOString(),
                                                                uid: uuidv4(),
                                                                triggered: false
                                                            }
                                                        ]
                                                    });
                                                }
                                            }}
                                            className="btn-tertiary border-dark w-20"
                                        >
                                            <FontAwesomeIcon
                                                icon="plus"
                                                transform="shrink-2"
                                                className="me-2 text-success"
                                            />
                                            Time
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }

                    {
                        //----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------WEEKLY VIEW------------------------------------------------------------------------------------------

                        createBulk?.repeatCampaign === 'Weekly' && (
                            <div>
                                <ButtonGroup>
                                    <Label className="pt-2 pe-2">Trigger Every</Label>
                                    <Input
                                        type="number"
                                        min="0"
                                        value={weeksToTrigger}
                                        onChange={handleTriggerWeekInputChange}
                                        className="border-dark"
                                        style={{
                                            textAlign: 'center',
                                            maxWidth: '60px',
                                            display: 'inline-block'
                                        }}
                                    />
                                    <Button
                                        onClick={() => {
                                            return incrementWeeks();
                                        }}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon
                                            icon="chevron-up"
                                            transform="shrink-1 down-1"
                                        />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            return decrementWeeks();
                                        }}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon
                                            icon="chevron-down"
                                            transform="shrink-1 down-1"
                                        />
                                    </Button>
                                    <Label className="pt-2 ps-2">
                                        {weeksToTrigger > 1 ? 'Weeks' : 'Week'}
                                    </Label>
                                </ButtonGroup>
                            </div>
                        )
                    }

                    {createBulk?.repeatCampaign === 'Weekly' &&
                        weekDaysArr.map(el => (
                            <div>
                                <Divider className="py-3 fs-2">
                                    <FormGroup className="cursor-pointer" switch>
                                        <Input
                                            type="switch"
                                            role="switch"
                                            id={el}
                                            value={el}
                                            style={{ transform: 'scale(1.2)', marginRight: '10px' }} // Adjust scale and margin as needed
                                            onChange={({ target }) => {
                                                if (createBulk?.repeatCampaignWeekdays[target.value]) {
                                                    dispatch({
                                                        type: UPDATE_WEEK_DAYS,
                                                        payload: {
                                                            ...createBulk?.repeatCampaignWeekdays,
                                                            [el]: false
                                                        }
                                                    });
                                                } else {
                                                    dispatch({
                                                        type: UPDATE_WEEK_DAYS,
                                                        payload: {
                                                            ...createBulk?.repeatCampaignWeekdays,
                                                            [el]: true
                                                        }
                                                    });
                                                }
                                            }}
                                            className="cursor-pointer"
                                            checked={createBulk?.repeatCampaignWeekdays[el] === true}
                                            errors={''}
                                        />
                                        <strong>{el}</strong>
                                    </FormGroup>
                                </Divider>

                                {createBulk?.repeatCampaignWeekdayTimes[el]?.map(
                                    (wkday, index) => {
                                        const CustomInputWeekdayTimes = forwardRef(
                                            ({ value, onClick }, ref) => (
                                                <Button
                                                    className="btn-tertiary border-dark w-100"
                                                    onClick={onClick}
                                                    ref={ref}
                                                >
                                                    <Moment
                                                        format="hh:mm a"
                                                        trim
                                                        date={wkday?.dpTime}
                                                    ></Moment>
                                                </Button>
                                            )
                                        );

                                        return (
                                            <div
                                                className={
                                                    createBulk?.repeatCampaignWeekdays[el] === false
                                                        ? 'd-none'
                                                        : ' mb-2'
                                                }
                                                key={index}
                                            >
                                                <ButtonGroup>
                                                    <Label className="mt-2 pe-2">Run</Label>
                                                    <DatePicker
                                                        key={index}
                                                        showPopperArrow={false}
                                                        onChange={date => {
                                                            const ttDate = new Date(date);
                                                            const applyOffset = ttDate.setTime(
                                                                ttDate.getTime() -
                                                                ttDate.getTimezoneOffset() * 60_000
                                                            );
                                                            const actualTime = new Date(applyOffset)
                                                                .toISOString()
                                                                .replace('Z', '');
                                                            const toTz = momentt
                                                                .tz(actualTime, timezone)
                                                                .format();
                                                            const getUTCTime = momentt.utc(toTz).format();

                                                            return dispatch({
                                                                type: UPDATE_WEEK_TIMES,
                                                                payload: {
                                                                    ...createBulk?.repeatCampaignWeekdayTimes,
                                                                    [el]: createBulk?.repeatCampaignWeekdayTimes[
                                                                        el
                                                                    ].map(obj => {
                                                                        if (obj.uid === wkday.uid) {
                                                                            return {
                                                                                trigger_time: getUTCTime,
                                                                                dpTime: date,
                                                                                uid: obj.uid,
                                                                                triggered: false
                                                                            };
                                                                        } else {
                                                                            return obj;
                                                                        }
                                                                    })
                                                                }
                                                            });
                                                        }}
                                                        selected={new Date(wkday?.dpTime)}
                                                        customInput={<CustomInputWeekdayTimes />}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={1}
                                                        timeCaption="Time"
                                                    />

                                                    <Button
                                                        onClick={() => {
                                                            dispatch({
                                                                type: UPDATE_WEEK_TIMES,
                                                                payload: {
                                                                    ...createBulk?.repeatCampaignWeekdayTimes,
                                                                    [el]: createBulk?.repeatCampaignWeekdayTimes[
                                                                        el
                                                                    ].filter(times => {
                                                                        return times.uid !== wkday.uid;
                                                                    })
                                                                }
                                                            });
                                                        }}
                                                        className="btn-tertiary border-dark text-danger ms-2"
                                                    >
                                                        <FontAwesomeIcon
                                                            icon="times"
                                                            transform="shrink-1 down-1"
                                                        />
                                                    </Button>
                                                </ButtonGroup>
                                            </div>
                                        );
                                    }
                                )}

                                <div
                                    className={
                                        createBulk?.repeatCampaignWeekdays[el] === false
                                            ? 'd-none'
                                            : ''
                                    }
                                >
                                    <Row className="mt-2">
                                        <Col>
                                            <Button
                                                onClick={() => {
                                                    const weekdayTimes =
                                                        createBulk?.repeatCampaignWeekdayTimes || [];
                                                    dispatch({
                                                        type: UPDATE_WEEK_TIMES,
                                                        payload: {
                                                            ...weekdayTimes,
                                                            [el]: [
                                                                ...weekdayTimes[el],
                                                                {
                                                                    trigger_time: startDate,
                                                                    dpTime: new Date().toISOString(),
                                                                    uid: uuidv4(),
                                                                    triggered: false
                                                                }
                                                            ]
                                                        }
                                                    });
                                                }}
                                                className="btn-tertiary border-dark w-20"
                                            >
                                                <FontAwesomeIcon
                                                    icon="plus"
                                                    transform="shrink-2"
                                                    className="me-2 text-success"
                                                />
                                                Time
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}

                    {
                        //---------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        //----------------------------------------------------------------------------------------MONTHLY VIEW------------------------------------------------------------------------------------------
                        createBulk?.repeatCampaign === 'Monthly' && (
                            <div>
                                <ButtonGroup className="mt-3">
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                style={{ width: 17, height: 17 }}
                                                checked={createBulk?.repeatCampaignType === 'onDay'}
                                                onClick={() => {
                                                    dispatch({
                                                        type: UPDATE_CAMPAIGN_TYPE,
                                                        payload: 'onDay'
                                                    }); // repeatCampaignType
                                                    dispatch({
                                                        type: UPDATE_MONTH_ON_DAY,
                                                        payload: monthDayState
                                                    });
                                                }}
                                            />
                                            <div>
                                                <h5 className="mx-2 mt-1">On day</h5>
                                            </div>
                                        </Label>
                                    </FormGroup>
                                    <Input
                                        onChange={handleMonthDayChange}
                                        value={monthDayState}
                                        style={{ maxWidth: 80 }}
                                    />
                                    <Button
                                        onClick={() => {
                                            return incrementMonthDay();
                                        }}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon
                                            icon="chevron-up"
                                            transform="shrink-1 down-1"
                                        />
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            return decrementMonthDay();
                                        }}
                                        className="btn-sm btn-tertiary border-dark text-primary"
                                    >
                                        <FontAwesomeIcon
                                            icon="chevron-down"
                                            transform="shrink-1 down-1"
                                        />
                                    </Button>
                                </ButtonGroup>

                                <Row className="mt-3 flex-column flex-sm-row">
                                    <ButtonGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    className="mt-2 ml-1"
                                                    style={{ width: 17, height: 17 }}
                                                    type="radio"
                                                    checked={createBulk?.repeatCampaignType === 'onThe'}
                                                    onClick={() => {
                                                        return (
                                                            dispatch({
                                                                type: UPDATE_CAMPAIGN_TYPE,
                                                                payload: 'onThe'
                                                            }),
                                                            dispatch({ type: UPDATE_MONTH_ON_DAY, payload: 0 })
                                                        );
                                                    }}
                                                />
                                                <div>
                                                    <h5 className="ms-2 mt-1">On the</h5>
                                                </div>
                                            </Label>
                                        </FormGroup>
                                        <UncontrolledDropdown>
                                            {
                                                //on the first x
                                            }
                                            <DropdownToggle
                                                disabled={createBulk.repeatCampaignType !== 'onThe'}
                                                size="md"
                                                type="button"
                                                className="btn-tertiary border-dark mx-2 mt-1"
                                                id="noRepeat"
                                                value={createBulk?.repeatCampaignMonthOnThe?.first_part}
                                            >
                                                {createBulk?.repeatCampaignMonthOnThe?.first_part + ' '}
                                                <FontAwesomeIcon
                                                    icon="chevron-down"
                                                    transform="shrink-1 down-1"
                                                />
                                            </DropdownToggle>
                                            {
                                                // on the x sunday
                                            }
                                            <DropdownMenu className="border py-2 ml-3">
                                                <DropdownItem
                                                    onClick={e => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                first_part: 'First'
                                                            }
                                                        });
                                                    }}
                                                    name="repeatCampaign"
                                                >{`First`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                first_part: 'Second'
                                                            }
                                                        });
                                                    }}
                                                    name="repeatCampaign"
                                                >{`Second`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                first_part: 'Third'
                                                            }
                                                        });
                                                    }}
                                                    name="repeatCampaign"
                                                >{`Third`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                first_part: 'Fourth'
                                                            }
                                                        });
                                                    }}
                                                    name="repeatCampaign"
                                                >{`Fourth`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={e => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                first_part: 'Last'
                                                            }
                                                        });
                                                    }}
                                                    name="repeatCampaign"
                                                >{`Last`}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        <UncontrolledDropdown>
                                            <DropdownToggle
                                                disabled={createBulk.repeatCampaignType !== 'onThe'}
                                                size="md"
                                                type="button"
                                                className="btn-tertiary border-dark mt-1"
                                                id="noRepeat"
                                                value={createBulk?.repeatCampaign}
                                                name="repeatCampaign"
                                            >
                                                {createBulk?.repeatCampaignMonthOnThe?.day + ' '}
                                                <FontAwesomeIcon
                                                    icon="chevron-down"
                                                    transform="shrink-1 down-1"
                                                />
                                            </DropdownToggle>
                                            <DropdownMenu className="border py-2 ml-3">
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Sunday'
                                                            }
                                                        });
                                                    }}
                                                >{`Sunday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Monday'
                                                            }
                                                        });
                                                    }}
                                                >{`Monday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Tuesday'
                                                            }
                                                        });
                                                    }}
                                                >{`Tuesday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Wednesday'
                                                            }
                                                        });
                                                    }}
                                                >{`Wednesday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Thursday'
                                                            }
                                                        });
                                                    }}
                                                >{`Thursday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Friday'
                                                            }
                                                        });
                                                    }}
                                                >{`Friday`}</DropdownItem>
                                                <DropdownItem
                                                    onClick={() => {
                                                        return dispatch({
                                                            type: UPDATE_MONTH_ON_THE,
                                                            payload: {
                                                                ...createBulk.repeatCampaignMonthOnThe,
                                                                day: 'Saturday'
                                                            }
                                                        });
                                                    }}
                                                >{`Saturday`}</DropdownItem>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </ButtonGroup>
                                </Row>

                                {createBulk?.repeatCampaignMonthTimes?.map(el => {
                                    const CustomInputMonthDayTimes = forwardRef(
                                        ({ value, onClick }, ref) => (
                                            <Button
                                                className="btn-tertiary border-dark w-100 "
                                                onClick={onClick}
                                                ref={ref}
                                            >
                                                <Moment
                                                    format="hh:mm a"
                                                    trim
                                                    date={new Date(el?.dpTime)}
                                                ></Moment>
                                            </Button>
                                        )
                                    );

                                    return (
                                        <div className="my-2">
                                            <ButtonGroup>
                                                <Label className="mt-2 pe-2">Run</Label>
                                                <DatePicker
                                                    showPopperArrow={false}
                                                    selected={new Date(el?.dpTime)}
                                                    onChange={date => {
                                                        const ttDate = new Date(date);
                                                        const applyOffset = ttDate.setTime(
                                                            ttDate.getTime() -
                                                            ttDate.getTimezoneOffset() * 60_000
                                                        );
                                                        const actualTime = new Date(applyOffset)
                                                            .toISOString()
                                                            .replace('Z', '');
                                                        const toTz = momentt
                                                            .tz(actualTime, timezone)
                                                            .format();
                                                        const getUTCTime = momentt.utc(toTz).format();

                                                        return dispatch({
                                                            type: UPDATE_MONTH_TIMES,
                                                            payload: createBulk?.repeatCampaignMonthTimes.map(
                                                                obj => {
                                                                    if (obj.uid === el.uid) {
                                                                        return {
                                                                            trigger_time: getUTCTime,
                                                                            dpTime: date,
                                                                            uid: obj.uid,
                                                                            triggered: false
                                                                        };
                                                                    } else {
                                                                        return obj;
                                                                    }
                                                                }
                                                            )
                                                        });
                                                    }}
                                                    customInput={<CustomInputMonthDayTimes />}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={1}
                                                    timeCaption="Time"
                                                />
                                                <Button
                                                    onClick={() => {
                                                        dispatch({
                                                            type: UPDATE_MONTH_TIMES,
                                                            payload:
                                                                createBulk?.repeatCampaignMonthTimes.filter(
                                                                    obj => {
                                                                        return obj.uid !== el.uid;
                                                                    }
                                                                )
                                                        });
                                                    }}
                                                    className="btn-tertiary border-dark text-danger ms-2"
                                                >
                                                    <FontAwesomeIcon
                                                        icon="times"
                                                        transform="shrink-1 down-1"
                                                    />
                                                </Button>
                                            </ButtonGroup>
                                        </div>
                                    );
                                })}

                                <div>
                                    <Row className="mt-2">
                                        <Col>
                                            <Button
                                                onClick={() => {
                                                    if (createBulk?.repeatCampaignMonthTimes?.length < 24) {
                                                        dispatch({
                                                            type: UPDATE_MONTH_TIMES,
                                                            payload: [
                                                                ...createBulk?.repeatCampaignMonthTimes,
                                                                {
                                                                    trigger_time: startDate,
                                                                    dpTime: new Date(),
                                                                    uid: uuidv4(),
                                                                    triggered: false
                                                                }
                                                            ]
                                                        });
                                                    }
                                                }}
                                                className="btn-tertiary border-dark w-20"
                                            >
                                                <FontAwesomeIcon
                                                    icon="plus"
                                                    transform="shrink-2"
                                                    className="me-2 text-success"
                                                />
                                                Time
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        )
                    }

                    {createBulk?.repeatCampaign !== 'noRepeat' && (
                        <div>
                            <Divider className="mt-4 fs-2">
                                <strong>
                                    {' '}
                                    <Label className="fs-0">End Date</Label>{' '}
                                </strong>
                            </Divider>
                            <Row>
                                <UncontrolledDropdown>
                                    <DropdownToggle
                                        size="md"
                                        type="button"
                                        className="btn-tertiary border-dark"
                                        id="never"
                                        value={createBulk?.repeatCampaignEnd}
                                        name="repeatCampaignEnd"
                                    >
                                        {createBulk?.repeatCampaignEnd + ' '}
                                        <FontAwesomeIcon
                                            icon="chevron-down"
                                            transform="shrink-1 down-1"
                                        />
                                    </DropdownToggle>
                                    <DropdownMenu className="border py-2 ms-3">
                                        <DropdownItem
                                            onClick={e => {
                                                return dispatch({
                                                    type: UPDATE_END_TYPE,
                                                    payload: 'Never'
                                                });
                                            }}
                                            id="never"
                                            value="never"
                                            name="repeatCampaignEnd"
                                        >{`Never `}</DropdownItem>
                                        <DropdownItem
                                            onClick={e => {
                                                return dispatch({
                                                    type: UPDATE_END_TYPE,
                                                    payload: 'After'
                                                });
                                            }}
                                            id="After"
                                            value="After"
                                            name="repeatCampaignEnd"
                                        >{`After `}</DropdownItem>
                                        <DropdownItem
                                            onClick={e => {
                                                return dispatch({
                                                    type: UPDATE_END_TYPE,
                                                    payload: 'OnDate'
                                                });
                                            }}
                                            id="OnDate"
                                            value="OnDate"
                                            name="repeatCampaignEnd"
                                        >{`On Date `}</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Row>

                            {createBulk?.repeatCampaignEnd === 'After' && (
                                <div className="mt-2">
                                    <ButtonGroup>
                                        <Input
                                            onChange={handleSessionChange}
                                            value={textSession}
                                            style={{ maxWidth: 80 }}
                                        />
                                        <Button
                                            onClick={() => {
                                                return incrementSession();
                                            }}
                                            className="btn-sm btn-tertiary border-dark text-primary"
                                            type="button"
                                        >
                                            <FontAwesomeIcon
                                                icon="chevron-up"
                                                transform="shrink-1 down-1"
                                            />
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                return decrementSession();
                                            }}
                                            className="btn-sm btn-tertiary border-dark text-primary"
                                            type="button"
                                        >
                                            <FontAwesomeIcon
                                                icon="chevron-down"
                                                transform="shrink-1 down-1"
                                            />
                                        </Button>
                                        <h5 className="mx-2 mt-1">SMS sessions</h5>
                                    </ButtonGroup>
                                </div>
                            )}

                            {createBulk?.repeatCampaignEnd === 'OnDate' && (
                                <div>
                                    <Row className="mt-4">
                                        <DatePicker
                                            minDate={startDate}
                                            showPopperArrow={false}
                                            selected={endDate}
                                            onChange={date => {
                                                return (
                                                    setEndDate(date),
                                                    dispatch({ type: UPDATE_END_DATE, payload: date })
                                                );
                                            }}
                                            customInput={<ExampleCustomInputEnd />}
                                            timeIntervals={1}
                                            showTimeSelect
                                            timeCaption="Time"
                                            dateFormat="h:mm aa"
                                            filterTime={filterEndTime}
                                        />
                                    </Row>
                                </div>
                            )}
                        </div>
                    )}

                    <Divider className="mt-4 fs-2">
                        <strong>
                            {' '}
                            <Label className="fs-0" for="timezone">
                                Timezone
                            </Label>{' '}
                        </strong>
                    </Divider>
                    <Select
                        name="timezone"
                        required={true}
                        className="mb-3"
                        styles={customStyles}
                        components={animatedComponents}
                        closeMenuOnSelect={true}
                        options={timezones}
                        defaultValue={{ UTC: auth?.user?.timezone }}
                        getOptionLabel={({ UTC }) => UTC}
                        getOptionValue={({ zoneTitle }) => zoneTitle}
                        onChange={timezone => {
                            return (
                                setTimezone(timezone.zoneTitle),
                                dispatch({
                                    type: UPDATE_BULK_TIMEZONE,
                                    payload: timezone.zoneTitle
                                })
                            );
                        }}
                        placeholder="select Timezone"
                        isSearchable={true}
                    />

                    <Divider className="mt-4 fs-2">
                        <strong>
                            {' '}
                            <Label className="fs-0" for="timezone">
                                Tag color on calendar
                            </Label>{' '}
                        </strong>
                    </Divider>
                    <Row className="justify-content-between">
                        <ButtonGroup>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-success"
                                id="soft-success"
                                value="soft-success"
                                name="soft-success"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-success'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-success'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />
                                {windowSize?.width > 540 ? ` Green` : ''}
                            </Button>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-info"
                                id="soft-info"
                                name="soft-info"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-info'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-info'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />
                                {windowSize?.width > 540 ? ` Light-blue` : ''}
                            </Button>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-primary"
                                id="soft-primary"
                                name="soft-primary"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-primary'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-primary'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />
                                {windowSize?.width > 540 ? ` Dark-blue` : ''}
                            </Button>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-danger"
                                id="soft-danger"
                                name="soft-danger"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-danger'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-danger'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />
                                {windowSize?.width > 540 ? ` Red` : ''}
                            </Button>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-warning"
                                id="soft-warning"
                                name="soft-warning"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-warning'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-warning'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />{' '}
                                {windowSize?.width > 540 ? ` Orange` : ''}
                            </Button>
                            <Button
                                type="button"
                                className=" border-dark btn-sm btn-tertiary text-secondary"
                                id="soft-secondary"
                                name="soft-secondary"
                                onClick={e => {
                                    return dispatch({
                                        type: UPDATE_BULK_CLASSNAME,
                                        payload: 'event-secondary'
                                    });
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={
                                        createBulk?.className === 'event-secondary'
                                            ? 'check-circle'
                                            : 'circle'
                                    }
                                    transform="grow-1"
                                    className=" me-1"
                                />{' '}
                                {windowSize?.width > 540 ? ` Gray` : ''}
                            </Button>
                        </ButtonGroup>
                    </Row>
                </CardBody>
                <CardFooter>

                    <Row className="mt-3">
                        <Col className="d-flex flex-column flex-sm-row justify-content-end align-items-center">
                            <Button
                                disabled={
                                    !createBulk?.title ||
                                    !createBulk?.start ||
                                    (!createBulk?.messageDetails?.groups.length && !createBulk?.messageDetails?.contacts?.length) ||
                                    createBulk?.messageDetails?.bulkNumber ===
                                    'Select your number' ||
                                    !createBulk?.messageDetails?.bulkNumber ||
                                    createBulk?.messageContents?.plainText.length < 4 ||
                                    !createBulk?.messageContents ||
                                    !createBulk?.timezone
                                }
                                className={`d-flex btn-tertiary border align-items-center mb-2 mb-sm-0 me-sm-3`}
                                onClick={e => {
                                    setShowPreviewModal(!showPreviewModal);
                                }}
                                color="primary"
                                size="sm"
                            >
                                <FontAwesomeIcon icon="eye" className="me-2 text-info" />
                                Preview
                            </Button>

                            <Button
                                disabled={
                                    !createBulk?.title ||
                                    !createBulk?.start ||
                                    (!createBulk?.messageDetails?.groups.length && !createBulk?.messageDetails?.contacts?.length) ||
                                    createBulk?.messageDetails?.bulkNumber ===
                                    'Select your number' ||
                                    !createBulk?.messageDetails?.bulkNumber ||
                                    createBulk?.messageContents?.plainText.length < 4 ||
                                    !createBulk?.messageContents ||
                                    !createBulk?.timezone
                                }
                                className={`d-flex btn-tertiary border align-items-center mb-2 mb-sm-0 me-sm-3`}
                                onClick={() => {
                                    return dispatch(calendarCreate(createBulk));
                                }}
                                color="primary"
                                size="sm"
                            >
                                <FontAwesomeIcon icon="paper-plane" className="me-2 text-primary" />
                                Schedule
                            </Button>
                        </Col>
                    </Row>
                </CardFooter>

            </Card>





            <Modal
                isOpen={showPreviewModal}
                centered
                toggle={() => {
                    return setShowPreviewModal(!showPreviewModal);
                }}
            >
                <ModalHeader close={closePreviewBtn}>Preview</ModalHeader>
                <ModalBody className="p-0">
                    <PreviewAndSend recipients={recipients} fields={fields} />
                </ModalBody>
            </Modal>

            <Modal
                isOpen={showSuccessModal}
                centered
                toggle={handleCleanup}
            >
                <ModalHeader close={closeSuccessBtn}></ModalHeader>
                <ModalBody className="p-0">
                    <SuccessAdd
                        handleCleanup={handleCleanup}
                    />
                </ModalBody>
            </Modal>



        </div>


    );
};

export default ScheduleTimev2;
